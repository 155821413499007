import { importService } from '../_services'

const state = {
  upload: {}
}

const actions = {
  uploadMenuImportFile ({ dispatch, commit }, data) {
    const stateName = 'uploadMenuImportFile'
    commit('request', { stateName: stateName })

    const restGroupId = data.restGroupId
    const restId = data.restId
    const formData = data.formData

    importService.uploadImportFile({ restGroupId, restId, formData })
      .then(
        upload => {
          commit('uploadMenuImportFileSuccess', upload.data)
          dispatch('alert/success', 'Import File Uploaded', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          // dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
    state.upload = payload
  },
  uploadMenuImportFileSuccess (state, upload) {
    state.upload = upload
  }
}

export const importModule = {
  namespaced: true,
  state,
  actions,
  mutations
}
