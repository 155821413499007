<template>
    <div class="page menuitems-page">
    <h4>Menu Items
      <button v-if="unAssignedItems.length > 0" v-on:click="addExistingItemsButton" class="btn btn-sm btn-primary btn-sm">Add Existing Item +</button> <button v-on:click="addItemButton" class="btn btn-sm btn-primary btn-sm">Add +</button>
    </h4>
    <div class="item-form" v-if="itemform">
        <p>{{formtitle}}</p>
        <div class="container-fluid">
            <div class="row" v-if="addExistingItems">
              <div class="col-sm-12 col-md-6 mb-3">
                  <p>Select an item not already assigned to this menu.</p>
                  <v-select placeholder="Search..." :options="unAssignedItems" @input="setExistingItem" v-model="itemName"></v-select>
              </div>
            </div>
            <div class="row" v-if="showItemTypes">
                <div class="col-sm-12 col-md-6 mb-3">
                    <p>Item Type</p>
                    <v-select placeholder="Search..." :options="itemtypes" @input="setItemType" v-model="itemTypeName"></v-select>
                    <div class="input-error" v-if="msg.errors.itemTypeId">
                        Valid Item Type is required.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 mb-3">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" id="name" placeholder="" v-model="name">
                    <div class="input-error" v-if="msg.errors.name">
                        Valid name is required.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 mb-3">
                    <label for="description">Description</label>
                    <input type="text" class="form-control" id="description" placeholder="" v-model="description">
                    <div class="input-error" v-if="msg.errors.description">
                        Valid description is required.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 mb-3">
                    <label for="ticketName">Ticket Name</label>
                    <input type="text" class="form-control" id="ticketName" placeholder="" v-model="ticketName">
                    <div class="input-error" v-if="msg.errors.ticketName">
                        Valid ticket name is required.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 mb-3">
                    <label for="chit">Chit</label>
                    <input type="text" class="form-control" id="chit" placeholder="" v-model="chit">
                    <div class="input-error" v-if="msg.errors.chit">
                        Valid chit is required.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 mb-3">
                    <label for="baseQty">Base Qty</label>
                    <input type="text" class="form-control" id="baseQty" placeholder="" v-model="baseQty">
                    <div class="input-error" v-if="msg.errors.baseQty">
                        Valid Base Qty is required.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 mb-3">
                    <label for="price">Price</label>
                    <input type="text" class="form-control" id="price" placeholder="" v-model="price">
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 mb-3">
                    <label for="priceDescription">Price Description</label>
                    <input type="text" class="form-control" id="priceDescription" placeholder="" v-model="priceDescription">
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 mb-3">
                    <p>Pricing Model</p>
                    <v-select placeholder="Search..." :options="pricingModelsIdNamesSelect" @input="setPricingModel" v-model="pricingModelName"></v-select>
                    <div class="input-error" v-if="msg.errors.pricingModelId">
                        Valid Pricing Model is required.
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 mb-3">
                    <p>Unit of Measure</p>
                    <v-select placeholder="Search..." :options="unitsOfMeasureIdNamesSelect" @input="setUnitOfMeasure" v-model="unitOfMeasureName"></v-select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 mb-3">
                    <p>Sales Tax</p>
                    <v-select placeholder="Search..." :options="restSalesTaxRateIdNamesSelect" @input="setRestSalesTax" v-model="restSalesTaxName"></v-select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 mb-3">
                    <label for="calories">Calories</label>
                    <input type="text" class="form-control" id="calories" placeholder="" v-model="calories">
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 mb-3">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="isActive" placeholder="" v-model="isActive" :checked="isActive == 1">
                    <label class="form-check-label" for="isActive">
                      Is Active?
                    </label>
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 mb-3">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="isMeasured" placeholder="" v-model="isMeasured" :checked="isMeasured == 1">
                    <label class="form-check-label" for="isMeasured">
                      Is Measured?
                    </label>
                  </div>
                </div>
            </div>
            <div class="row" v-if="editingitem">
              <div class="col-sm-12 mb-3 deletecontrol text-right">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="deleteMenuItem" id="deleteMenuItem">
                  <label class="form-check-label" for="deleteMenuItem">
                    Delete this menu Item?
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-sm-12 mb-3 text-left form-controls">
                    <button v-on:click="saveItemFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                    <button v-on:click="closeItemFormButton" class="btn btn-sm btn-danger">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="allmenuitems.length" class="items-list">
        <table class="table table-striped">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Edit</th>
              <th scope="col">Name</th>
              <th scope="col">Description</th>
              <th scope="col">Ticket Name</th>
              <th scope="col">Chit</th>
              <th scope="col">Price</th>
              <th scope="col">Price Desc</th>
              <th scope="col">Pricing Model</th>
              <th scope="col">Unit</th>
              <th scope="col">Sales Tax</th>
              <th scope="col">Base Qty</th>
              <th scope="col">Calories</th>
              <th scope="col">Active</th>
              <th scope="col">Measured</th>
            </tr>
          </thead>
          <tbody>
                <tr v-for="item in allmenuitems" :key="item.menu_item_id">
                  <td>
                    <button v-on:click="editItemButton(item.menu_item_id)" class="btn btn-sm btn-primary">Edit</button>
                  </td>
                  <td>
                    {{item.item_name}}
                    <span class="hidden">
                      Menu Item ID: {{item.menu_item_id}}<br />
                      Item ID: {{item.item_id}}
                    </span>
                  </td>
                  <td>{{item.description}}</td>
                  <td>{{item.ticket_name}}</td>
                  <td>{{item.chit}}</td>
                  <td>{{item.price}}</td>
                  <td>{{item.price_description}}</td>
                  <td>
                    <div v-if="item.pricing_model">
                      {{item.pricing_model.property}} ({{item.pricing_model.description}})
                    </div>
                  </td>
                  <td>
                    <div v-if="item.item_unit">
                      {{item.item_unit.name}} ({{item.item_unit.description}})
                    </div>
                  </td>
                  <td>
                    <div v-if="item.restaurant_sales_tax">
                      {{item.restaurant_sales_tax.tax_rate}} ({{item.restaurant_sales_tax.description}})
                    </div>
                  </td>
                  <td>{{item.base_qty}}</td>
                  <td>{{item.calories}}</td>
                  <td>{{item.is_active}}</td>
                  <td>{{item.is_measured}}</td>
                </tr>
          </tbody>
        </table>
    </div>

    </div>
</template>
<style lang="scss" scoped>
  .hidden {
    display: none;
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'MenuItems',
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
      itemform: false,
      formtitle: 'Add New Item',
      formsavetext: 'Save',
      itemTypeId: '',
      itemTypeName: '',
      itemId: '',
      itemName: '',
      name: '',
      association: '',
      description: '',
      ticketName: '',
      chit: '',
      baseQty: 1,
      price: '',
      priceDescription: '',
      pricingModelId: '',
      pricingModelName: '',
      itemUnitId: '',
      unitOfMeasureId: '',
      unitOfMeasureName: '',
      restSalesTaxId: '',
      restSalesTaxName: '',
      calories: '',
      isActive: 0,
      isMeasured: 0,
      editingitem: '',
      showItemTypes: false,
      addExistingItems: false,
      unAssignedItems: [],
      deleteMenuItem: false,
      msg: {
        errors: {
          name: false,
          description: false,
          pricingModelId: false
        }
      }
    }
  },
  computed: {
    ...mapState({
      allmenuitems: state => state.menuitems.all,
      activemenuitem: state => state.menuitems.activemenuitem,
      addmenuitem: state => state.menuitems.addmenuitem,
      updatemenuitem: state => state.menuitems.updatemenuitem,
      unitsOfMeasure: state => state.menuitems.unitsOfMeasure,
      unitsOfMeasureIdNamesSelect: state => state.menuitems.unitsOfMeasureIdNamesSelect,
      pricingModels: state => state.menuitems.pricingModels,
      pricingModelsIdNamesSelect: state => state.menuitems.pricingModelsIdNamesSelect,
      pricingModelDefault: state => state.menuitems.pricingModelDefault,
      restSalesTaxRates: state => state.menuitems.restSalesTaxRates,
      restSalesTaxRateDefault: state => state.menuitems.restSalesTaxRateDefault,
      restSalesTaxRateIdNamesSelect: state => state.menuitems.restSalesTaxRateIdNamesSelect,
      itemtypes: state => state.items.itemtypes,
      groupitems: state => state.items.all,
      activeitem: state => state.items.activeitem,
      activemenu: state => state.menus.activemenu
    })
  },
  created () {
    const { restGroupId, restId, menuId } = this.componentProps
    this.getMenuItems({ restGroupId, restId, menuId })
    this.getItems(restGroupId)
    this.getItemTypes(restGroupId)
    this.getUnitsOfMeasure(restGroupId)
  },
  watch: {
    activemenuitem: {
      handler (val, oldVal) {
        this.itemId = val.item_id
        this.name = val.item_name
        this.association = val.association
        this.description = val.description
        this.ticketName = val.ticket_name
        this.chit = val.chit
        this.baseQty = val.base_qty
        this.price = val.price
        this.priceDescription = val.price_description
        this.pricingModelId = val.pricing_model_id
        if ('pricing_model' in val) {
          this.pricingModelName = val.pricing_model.property + ' (' + val.pricing_model.description + ')'
        }
        this.itemUnitId = val.item_unit_id
        if ('item_unit' in val) {
          this.unitOfMeasureId = val.item_unit.unit_of_measure_id
          this.unitOfMeasureName = val.item_unit.name + ' (' + val.item_unit.description + ')'
        }
        this.restSalesTaxId = val.restaurant_sales_tax_id
        if ('restaurant_sales_tax' in val) {
          this.restSalesTaxId = val.restaurant_sales_tax.sales_tax_rate_id
          this.restSalesTaxName = val.restaurant_sales_tax.tax_rate + ' (' + val.restaurant_sales_tax.description + ')'
        }
        this.calories = val.calories
        this.isActive = val.is_active
        this.isMeasured = val.is_measured
      }
    },
    activeitem: {
      handler (val, oldVal) {
        this.itemId = val.item_id
        this.name = val.item_name
        this.itemTypeId = val.item_type_id
        this.itemTypeName = val.item_type
        this.description = val.description
        this.ticketName = val.ticket_name
        this.chit = val.chit
        this.baseQty = val.base_qty
        this.price = val.price
        this.priceDescription = val.price_description
        this.isMeasured = val.is_measured
        this.isActive = true
      }
    },
    name: {
      handler (val, oldVal) {
        this.validateItemForm()
      }
    },
    description: {
      handler (val, oldVal) {
        this.validateItemForm()
      }
    },
    addmenuitem: {
      handler (val, oldVal) {
        if (val.menu_item_id) {
          const { restGroupId, restId, menuId } = this.componentProps
          this.getMenuItems({ restGroupId, restId, menuId })
          this.getItems(restGroupId)
          this.itemFormReset()
          this.closeItemFormButton()
        }
      }
    },
    updatemenuitem: {
      handler (val, oldVal) {
        if (val.menu_item_id) {
          const { restGroupId, restId, menuId } = this.componentProps
          this.getMenuItems({ restGroupId, restId, menuId })
          this.getItems(restGroupId)
          this.itemFormReset()
          this.closeItemFormButton()
        }
      }
    },
    groupitems: {
      handler (val, oldVal) {
        if ('items' in val) {
          if (val.items.length > 0) {
            this.getAvailableItems(val.items)
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('items', {
      getItems: 'getAll',
      getItem: 'getById',
      getItemTypes: 'getTypes'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    ...mapActions('menuitems', {
      getMenuItems: 'getAll',
      getMenuItem: 'getById',
      addMenuItem: 'add',
      updateMenuItem: 'update',
      getUnitsOfMeasure: 'getUnitsOfMeasure'
    }),
    validateItemForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    itemFormReset (event) {
      this.formtitle = 'Add new Item'
      this.itemTypeId = ''
      this.itemTypeName = ''
      this.itemId = ''
      this.name = ''
      this.itemName = ''
      this.association = ''
      this.description = ''
      this.ticketName = ''
      this.chit = ''
      this.baseQty = ''
      this.price = ''
      this.priceDescription = ''
      this.pricingModelId = ''
      this.pricingModelName = ''
      this.itemUnitId = ''
      this.unitOfMeasureId = ''
      this.unitOfMeasureName = ''
      this.restSalesTaxId = ''
      this.restSalesTaxName = ''
      this.calories = ''
      this.isActive = 0
      this.isMeasured = 0
      this.editingitem = ''
      this.deleteMenuItem = false
    },
    closeItemFormButton (event) {
      this.itemform = false
      this.showItemTypes = false
      this.scrollToTop()
    },
    addItemButton () {
      this.clearAlert()
      this.itemFormReset()
      this.addExistingItems = false
      if (this.itemform) {
        this.closeItemFormButton()
        return
      }
      this.showItemTypes = true
      this.itemform = true
    },
    addExistingItemsButton (event) {
      this.itemFormReset()
      if (this.addExistingItems) {
        this.addExistingItems = false
        this.itemform = false
        return
      }
      this.getAvailableItems(this.groupitems.items)
      this.formtitle = 'Add Existing Item'
      this.showItemTypes = false
      this.itemform = true
      this.addExistingItems = true
    },
    editItemButton (id) {
      const { restGroupId, restId } = this.componentProps
      this.getMenuItem({ id, restGroupId, restId })
      this.editingitem = id
      this.showItemTypes = false
      this.itemform = true
      this.formtitle = 'Edit Item'
      this.addExistingItems = false
      this.scrollToTop()
    },
    saveItemFormButton (event) {
      this.clearAlert()
      if (this.validateItemForm() === false) {
        return
      }
      this.scrollToTop()
      const { restGroupId, restId, menuId } = this.componentProps
      if (this.editingitem) {
        const {
          itemId,
          name,
          association,
          description,
          ticketName,
          chit,
          baseQty,
          price,
          priceDescription,
          pricingModelId,
          itemUnitId,
          unitOfMeasureId,
          restSalesTaxId,
          calories,
          isActive,
          isMeasured,
          editingitem,
          deleteMenuItem
        } = this
        this.updateMenuItem({
          restGroupId,
          restId,
          menuId,
          itemId,
          name,
          association,
          description,
          ticketName,
          chit,
          baseQty,
          price,
          priceDescription,
          pricingModelId,
          itemUnitId,
          unitOfMeasureId,
          restSalesTaxId,
          calories,
          isActive,
          isMeasured,
          editingitem,
          deleteMenuItem
        })
      } else {
        this.association = this.activemenu.name + '-' + this.name
        const {
          itemTypeId,
          itemId,
          name,
          association,
          description,
          ticketName,
          chit,
          baseQty,
          price,
          priceDescription,
          pricingModelId,
          unitOfMeasureId,
          restSalesTaxId,
          calories,
          isActive,
          isMeasured
        } = this
        this.addMenuItem({
          restGroupId,
          restId,
          menuId,
          itemTypeId,
          itemId,
          name,
          association,
          description,
          ticketName,
          chit,
          baseQty,
          price,
          priceDescription,
          pricingModelId,
          unitOfMeasureId,
          restSalesTaxId,
          calories,
          isActive,
          isMeasured
        })
      }
    },
    setUnitOfMeasure (value) {
      if (value !== null) {
        this.unitOfMeasureId = value.code
        return
      }
      this.unitOfMeasureId = ''
    },
    setPricingModel (value) {
      if (value !== null) {
        this.pricingModelId = value.code
        return
      }
      this.pricingModelId = ''
    },
    setRestSalesTax (value) {
      if (value !== null) {
        this.restSalesTaxId = value.code
        return
      }
      this.restSalesTaxId = ''
    },
    setItemType (value) {
      if (value !== null) {
        this.itemTypeId = value.code
        this.itemTypeName = value.label
        return
      }
      this.itemTypeId = ''
      this.itemTypeName = ''
    },
    setExistingItem (value) {
      if (value !== null) {
        this.getItem(value.code)
      }
    },
    getPricingModel (id) {
      const typeValue = this.getTypeValueById(id)
      if (typeof (typeValue) !== 'undefined' && typeValue !== null) {
        return typeValue.property + ': ' + typeValue.description
      }
    },
    scrollToTop (elementId = null) {
      var top = 0
      if (elementId) {
        var element = document.getElementById(elementId)
        top = element.offsetTop
      }
      window.scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    },
    getAvailableItems (items) {
      var availableItems = []
      Object.keys(items).forEach(key => {
        const item = items[key]
        if (this.itemAlreadyAssigned(item.item_id) === false) {
          availableItems.push({ code: item.item_id, label: item.item_name + ' (' + item.description + ')' })
        }
      })
      this.unAssignedItems = availableItems
    },
    itemAlreadyAssigned (itemId) {
      if (this.allmenuitems) {
        for (var i = 0; i < this.allmenuitems.length; i++) {
          const inUseItemId = this.allmenuitems[i].item_id
          if (inUseItemId === itemId) {
            return true
          }
        }
      }
      return false
    }
  }
}
</script>
