export function singleRestaurant () {
  const data = {
    restaurant: [
      {
        restId: 1,
        name: 'Taco Restaurant',
        description: 'The Best Tacos',
        address: '123 Test St.',
        address2: 'Suite #2',
        city: 'Chico',
        stateProvince: 'CA',
        postalCode: '95928',
        country: 'United States',
        taxRate: '7.4',
        type: 'single'
      }
    ],
    activeRestaurant: 1,
    menu: [
      {
        menuId: 1,
        restId: 1,
        name: 'Daily Menu',
        description: 'Our daily menu offerings.'
      }
    ],
    activeMenu: 1,
    menu_section: [
      {
        menuSectionId: 1,
        menuId: 1,
        name: 'Tacos',
        description: 'Daily Taco Offerings',
        display_order: ''
      },
      {
        menuSectionId: 2,
        menuId: 1,
        name: 'Burritos',
        description: 'Daily Burrito Offerings',
        display_order: ''
      },
      {
        menuSectionId: 3,
        menuId: 1,
        name: 'Deserts',
        description: 'Daily Desserts',
        display_order: ''
      }
    ],
    item: [
      {
        itemId: 1,
        name: 'Chicken Taco',
        description: 'The chicken taco.',
        price: '5.99',
        menuId: 1,
        menuSectionId: 1,
        display_order: '',
        image: false
      },
      {
        itemId: 2,
        name: 'Beef Taco',
        description: 'Our beef taco.',
        price: '6.99',
        menuId: 1,
        menuSectionId: 1,
        display_order: '',
        image: false
      },
      {
        itemId: 3,
        name: 'Chicken Burrito',
        description: 'Our chicken burrito.',
        price: '8.99',
        menuId: 1,
        menuSectionId: 2,
        display_order: '',
        image: false
      },
      {
        itemId: 4,
        name: 'Fried Ice Cream',
        description: 'Our fried ice cream.',
        price: '3.00',
        menuId: 1,
        menuSectionId: 3,
        display_order: '',
        image: false
      }
    ],
    modifier_set: [
      {
        modifierSetId: 1,
        name: 'Burrito Addons',
        description: 'Our Burrito Addons'
      },
      {
        modifierSetId: 2,
        name: 'Taco Addons',
        description: 'Our Taco Addons'
      }
    ],
    modifier: [
      {
        modifierId: 1,
        modifierSetId: 1,
        name: 'Extra Meat',
        description: ''
      },
      {
        modifierId: 2,
        modifierSetId: 1,
        name: 'Add Cheese',
        description: ''
      },
      {
        modifierId: 3,
        modifierSetId: 1,
        name: 'Add Guacamole',
        description: ''
      },
      {
        modifierId: 4,
        modifierSetId: 2,
        name: 'Extra Meat',
        description: ''
      },
      {
        modifierId: 5,
        modifierSetId: 2,
        name: 'Extra Cheese',
        description: ''
      },
      {
        modifierId: 6,
        modifierSetId: 2,
        name: 'No lettuce',
        description: ''
      }
    ],
    restOperationType: 'single',
    display: {
      restOperationType: false,
      brandInformation: false,
      restInfo: true
    }
  }
  return data
}
