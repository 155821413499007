<template>
    <div class="restaurants-page page">
      <h6 class="page-title">Restaurants <button v-on:click="addRestaurantButton" class="btn btn-sm btn-primary">Add +</button></h6>
      <div class="restaurant-form" v-if="restaurantform">
          <p>{{formtitle}}</p>
          <div class="container-fluid">
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="name">Name</label>
                      <input type="text" class="form-control" id="name" placeholder="" v-model="restaurantname">
                      <div class="input-error" v-if="msg.errors.restaurantname">
                          Valid restaurant name is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="description">Description</label>
                      <input type="text" class="form-control" id="description" placeholder="" v-model="restaurantdescription">
                      <div class="input-error" v-if="msg.errors.restaurantdescription">
                          Valid restaurant Description is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="address1">Address 1</label>
                      <input type="text" class="form-control" id="address1" placeholder="" v-model="restaurantaddress1">
                      <div class="input-error" v-if="msg.errors.restaurantaddress1">
                          Valid restaurant address is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="address2">Address 2</label>
                      <input type="text" class="form-control" id="address2" placeholder="" v-model="restaurantaddress2">
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="city">City</label>
                      <input type="text" class="form-control" id="city" placeholder="" v-model="restaurantcity">
                      <div class="input-error" v-if="msg.errors.restaurantcity">
                          Valid restaurant city is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="province">Province</label>
                      <input type="text" class="form-control" id="province" placeholder="" v-model="restaurantprovince">
                      <div class="input-error" v-if="msg.errors.restaurantprovince">
                          Valid restaurant province is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="postal_code">Postal Code</label>
                      <input type="text" class="form-control" id="postal_code" placeholder="" v-model="restaurantpostalcode">
                      <div class="input-error" v-if="msg.errors.restaurantpostalcode">
                          Valid restaurant postal code is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="country">Country</label>
                      <input type="text" class="form-control" id="country" placeholder="" v-model="restaurantcountry">
                      <div class="input-error" v-if="msg.errors.restaurantcountry">
                          Valid restaurant country is required.
                      </div>
                  </div>
              </div>
              <div class="row" v-if="editingrestaurant">
                <div class="col-sm-12 col-md-6 mb-3 deletecontrol text-right">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="deleterestaurant" id="deleterestaurant">
                    <label class="form-check-label" for="deleterestaurant">
                      Delete this Restaurant?
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3 text-left form-controls">
                      <button v-on:click="saveRestaurantFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                      <button v-on:click="closeRestaurantFormButton" class="btn btn-sm btn-danger">Close</button>
                  </div>
              </div>
          </div>
      </div>
      <div v-if="grouprestaurants.length > 0" class="restaurants-list">
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Manage</th>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Address</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>
                  <tr v-for="restaurant in grouprestaurants" :key="restaurant.id">
                    <td>
                      <router-link class="btn btn-block btn-sm btn-success" :to="{ name: 'managerestgrouprestaurant',
                      params: {restGroupId: restaurantrestgroupid, restId: restaurant.restaurant_id } }">Menus</router-link>
                    </td>
                    <td>{{restaurant.restaurant_id}}</td>
                    <td>{{restaurant.name}}</td>
                    <td>{{restaurant.description}}</td>
                    <td>
                      <span v-if="restaurant.address1">{{restaurant.address1}}<br /></span>
                      <span v-if="restaurant.address2">{{restaurant.address2}}<br /></span>
                      {{restaurant.city}} {{restaurant.province}} {{restaurant.postal_code}} {{restaurant.country}}
                    </td>
                    <td>
                      <button v-on:click="editRestaurantButton(restaurant.restaurant_id)" class="btn btn-sm btn-primary">Edit Details</button>
                    </td>
                  </tr>
            </tbody>
          </table>
      </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Restaurants',
  data () {
    return {
      formtitle: 'Add new Restaurant',
      formsavetext: 'Save',
      restaurantform: false,
      restaurantrestgroupid: this.$route.params.id,
      restaurantname: '',
      restaurantdescription: '',
      restaurantaddress1: '',
      restaurantaddress2: '',
      restaurantcity: '',
      restaurantprovince: '',
      restaurantpostalcode: '',
      restaurantcountry: '',
      editingrestaurant: '',
      deleterestaurant: false,
      msg: {
        errors: {
          restaurantname: false,
          restaurantdescription: false,
          restaurantaddress1: false,
          restaurantcity: false,
          restaurantprovince: false,
          restaurantpostalcode: false,
          restaurantcountry: false
        }
      }
    }
  },
  computed: {
    ...mapState({
      grouprestaurants: state => state.restgroups.restaurants,
      activerestaurant: state => state.restaurants.activerestaurant,
      addrestaurant: state => state.restaurants.addrestaurant,
      updaterestaurant: state => state.restaurants.updaterestaurant
    })
  },
  created () {
    this.getRestaurants(this.restaurantrestgroupid)
  },
  watch: {
    activerestaurant: {
      handler (val, oldVal) {
        this.restaurantname = val.name
        this.restaurantdescription = val.description
        this.restaurantaddress1 = val.address1
        this.restaurantaddress2 = val.address2
        this.restaurantcity = val.city
        this.restaurantprovince = val.province
        this.restaurantpostalcode = val.postal_code
        this.restaurantcountry = val.country
        this.editingrestaurant = val.restaurant_id
      }
    },
    restaurantname: {
      handler (val, oldVal) {
        this.validateRestaurantForm()
      }
    },
    restaurantdescription: {
      handler (val, oldVal) {
        this.validateRestaurantForm()
      }
    },
    restaurantaddress1: {
      handler (val, oldVal) {
        this.validateRestaurantForm()
      }
    },
    restaurantcity: {
      handler (val, oldVal) {
        this.validateRestaurantForm()
      }
    },
    restaurantprovince: {
      handler (val, oldVal) {
        this.validateRestaurantForm()
      }
    },
    restaurantpostalcode: {
      handler (val, oldVal) {
        this.validateRestaurantForm()
      }
    },
    restaurantcountry: {
      handler (val, oldVal) {
        this.validateRestaurantForm()
      }
    },
    addrestaurant: {
      handler (val, oldVal) {
        if (val.restaurant_id) {
          this.getRestaurants(this.restaurantrestgroupid)
          this.RestaurantFormReset()
          this.closeRestaurantFormButton()
        }
      }
    },
    updaterestaurant: {
      handler (val, oldVal) {
        if (val.restaurant_id) {
          this.getRestaurants(this.restaurantrestgroupid)
          this.RestaurantFormReset()
          this.closeRestaurantFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('restaurants', {
      getRestaurant: 'getById',
      updateRestaurant: 'update',
      addRestaurant: 'add'
    }),
    ...mapActions('restgroups', {
      getRestaurants: 'getRestaurants'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    RestaurantFormReset (event) {
      this.formtitle = 'Add new Restaurant'
      this.restaurantname = ''
      this.restaurantdescription = ''
      this.restaurantaddress1 = ''
      this.restaurantaddress2 = ''
      this.restaurantcity = ''
      this.restaurantprovince = ''
      this.restaurantpostalcode = ''
      this.restaurantcountry = ''
      this.editingrestaurant = ''
      this.deleterestaurant = false
    },
    closeRestaurantFormButton (event) {
      this.restaurantform = false
    },
    addRestaurantButton (event) {
      this.clearAlert()
      this.RestaurantFormReset()
      if (this.restaurantform && this.editRestaurantData === '') {
        this.closeRestaurantFormButton()
        return
      }
      this.editRestaurantData = ''
      this.restaurantform = true
    },
    editRestaurantButton (id) {
      this.clearAlert()
      this.getRestaurant(id)
      this.restaurantform = true
      this.formtitle = 'Edit Restaurant'
    },
    validateRestaurantForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    saveRestaurantFormButton (event) {
      this.clearAlert()
      if (this.validateRestaurantForm() === false) {
        return
      }

      if (this.editingrestaurant) {
        const {
          editingrestaurant,
          restaurantname,
          restaurantdescription,
          restaurantrestgroupid,
          restaurantaddress1,
          restaurantaddress2,
          restaurantcity,
          restaurantprovince,
          restaurantpostalcode,
          restaurantcountry,
          deleterestaurant
        } = this
        this.updateRestaurant({
          editingrestaurant,
          restaurantname,
          restaurantdescription,
          restaurantrestgroupid,
          restaurantaddress1,
          restaurantaddress2,
          restaurantcity,
          restaurantprovince,
          restaurantpostalcode,
          restaurantcountry,
          deleterestaurant
        })
      } else {
        const {
          restaurantname,
          restaurantdescription,
          restaurantrestgroupid,
          restaurantaddress1,
          restaurantaddress2,
          restaurantcity,
          restaurantprovince,
          restaurantpostalcode,
          restaurantcountry
        } = this
        this.addRestaurant({
          restaurantname,
          restaurantdescription,
          restaurantrestgroupid,
          restaurantaddress1,
          restaurantaddress2,
          restaurantcity,
          restaurantprovince,
          restaurantpostalcode,
          restaurantcountry
        })
      }
    }
  }
}
</script>
