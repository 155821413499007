<template>
  <div class="component-container items">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 middle-divider">
          <h1 class="mb-3">What items are on your menu?</h1>
          <ul>
            <li>Add your menu items.</li>
            <li>You can assign an item to any section you have created (optional).</li>
            <li>You can always "Go Back" to add and order your sections (optional).</li>
            <li>Once you have finished entering your items click, "Done."</li>
          </ul>
        </div>
        <div class="col-md-8">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <mdb-input class="mt-0 mb-3" placeholder="Fill in the name of a new item to add." v-model="newItemName" ariaDescribedBy="button-addon2">
                  <mdb-btn icon="plus-circle" color="success" size="md" group slot="append" id="button-addon2" v-on:click="addItem()">Add</mdb-btn>
                </mdb-input>
              </div>
            </div>
            <h3 class="text-center pb-3">{{$parent.setup.menuName}}</h3>
            <div class="row mb-3" v-for="(section, sectionIndex) of availableSections($parent.selectedMenuId)" v-bind:key="sectionIndex + refreshToken">
              <div class="col">
                <div class="card" style="width: 100%">
                  <div class="card-body">
                    <h5 class="card-title menu-title">{{section.name}}</h5>
                    <p class="card-text" v-if="availableSectionItems(section.id).length === 0">No Items</p>
                    <div class="container-fluid p-0 m-0">
                      <div class="row d-flex align-items-center mb-3" v-for="(item, itemIndex) of availableSectionItems(section.id)" v-bind:key="item.id + item.displayOrder">
                        <div class="col-1 align-self-start p-0 m-0">
                            <div class="btn-group-vertical" role="group" aria-label="itemOrder">
                              <button type="button" class="btn btn-primary btn-sm mb-0 mt-0 p-0" v-on:click="moveMenuSectionItem(item.id, section.id, -1)" :disabled="item.displayOrder === 1"><b-icon-chevron-up></b-icon-chevron-up></button>
                              <button type="button" class="btn btn-primary btn-sm mb-0 mt-0 p-0" v-on:click="moveMenuSectionItem(item.id, section.id, 1)" :disabled="item.displayOrder === availableSectionItems(section.id).length"><b-icon-chevron-down></b-icon-chevron-down></button>
                            </div>
                        </div>
                        <div class="col-11 p-0 m-0">
                          <div class="d-flex align-items-center" style="width: 100%;">
                            <span class="font-bold">
                              {{item.name}} <span v-if="item.price"> ... {{item.price}}</span><br />
                              <span class="small">{{item.description}}</span>
                            </span>
                            <div class="btn-group ml-auto" role="group">
                              <button class="btn btn-sm btn-primary p-2 m-0" v-on:click="editItem(item)">Edit</button>
                              <button class="btn btn-sm btn-danger p-2 m-0" v-on:click="deleteItemConfirm(itemIndex, item)">Remove</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3" v-if="availableNoSectionItems($parent.selectedMenuId).length">
              <div class="col">
                <div class="card" style="width: 100%">
                  <div class="card-body">
                    <div class="container-fluid p-0 m-0">
                      <div class="row d-flex align-items-center mb-3" v-for="(nsitem, nsitemIndex) of availableNoSectionItems($parent.selectedMenuId)" v-bind:key="nsitem.id">
                        <div class="col-1 align-self-start p-0 m-0">
                          <div class="btn-group-vertical" role="group" aria-label="itemOrder">
                            <button type="button" class="btn btn-primary btn-sm mb-0 mt-0 p-0" v-on:click="moveMenuItem(nsitem.id, $parent.selectedMenuId, -1)" :disabled="nsitem.displayOrder === 1"><b-icon-chevron-up></b-icon-chevron-up></button>
                            <button type="button" class="btn btn-primary btn-sm mb-0 mt-0 p-0" v-on:click="moveMenuItem(nsitem.id, $parent.selectedMenuId, 1)" :disabled="nsitem.displayOrder === availableNoSectionItems($parent.selectedMenuId).length"><b-icon-chevron-down></b-icon-chevron-down></button>
                          </div>
                        </div>
                        <div class="col-11 p-0 m-0">
                          <div class="d-flex align-items-center" style="width: 100%;">
                            <span class="font-bold">
                              {{nsitem.name}} <span v-if="nsitem.price"> ... {{nsitem.price}}</span><br />
                              <span class="small">{{nsitem.description}}</span>
                            </span>
                            <div class="btn-group ml-auto" role="group">
                              <button class="btn btn-sm btn-primary p-2 m-0" v-on:click="editItem(nsitem)">Edit</button>
                              <button class="btn btn-sm btn-danger p-2 m-0" v-on:click="deleteItemConfirm(nsitemIndex, nsitem)">Remove</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 text-left">
          <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.goToPrevComponent()"><mdb-icon icon="arrow-alt-circle-left" size='lg' /> Go Back</button>        </div>
        <div class="col-md-6 text-right" v-if="availableItems($parent.selectedMenuId).length">
           <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.completeComponent()">Done <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
        </div>
      </div>
    </div>
    <div class="modal fade" id="itemEditor" tabindex="-1" aria-labelledby="itemEditorLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Edit the item details below and click "Save" when you are done.</p>
            <div class="grey-text">
              <mdb-input label="Edit the name of this item." v-model="editingItemName"  icon="edit" type="text" class="p-0" />
            </div>
            <div class="grey-text">
              <mdb-input label="Edit the description of this item." v-model="editingItemDescription"  icon="edit" type="text" class="p-0" />
            </div>
            <div class="grey-text">
              <mdb-input label="Edit the price of this item." v-model="editingItemPrice"  icon="edit" type="text" class="p-0" />
            </div>
            <label>Assign this item to a menu section? (not required)</label>
            <select class="form-control" :value="selectedSectionId" @change="sectionSelectOnChange($event)">
              <option value="">-- No Section Assignment --</option>
              <option v-for="(section, sectionIndex) of availableSections($parent.selectedMenuId)" v-bind:key="sectionIndex" :value="section.id">
                {{section.name}}
              </option>
            </select>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger float-left" v-on:click="closeItemEdit()">Cancel</button>
            <button type="button" class="btn btn-success" v-on:click="saveItem()">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="addNewItem" tabindex="-1" aria-labelledby="addNewItemLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Fill in the item details below and click "Save" when you are done.</p>
            <div class="grey-text">
              <mdb-input label="The name of this item." v-model="newItemName"  icon="edit" type="text" class="p-0" />
            </div>
            <div class="grey-text">
              <mdb-input label="The description of this item. (not required)" v-model="newItemDescription"  icon="edit" type="text" class="p-0" />
            </div>
            <div class="grey-text">
              <mdb-input label="The price of this item. (not required)" v-model="newItemPrice"  icon="edit" type="text" class="p-0" />
            </div>
            <label>Assign this item to a menu section? (not required)</label>
            <select class="form-control" :value="selectedSectionId" @change="sectionSelectOnChange($event)">
              <option value="">-- No Section Assignment --</option>
              <option v-for="(section, sectionIndex) of availableSections($parent.selectedMenuId)" v-bind:key="sectionIndex" :value="section.id">
                {{section.name}}
              </option>
            </select>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger float-left" v-on:click="closeAddNewItem()">Cancel</button>
            <button type="button" class="btn btn-success" v-on:click="saveNewItem()">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="itemDelete" tabindex="-1" aria-labelledby="itemDeleteLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Are you sure you want to delete this item? This is not reversable.</p>
            <p>Item Name: <b>{{deleteItem.name}}</b></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger float-left" v-on:click="closeItemDelete()">No, I want to keep this item.</button>
            <button type="button" class="btn btn-success" v-on:click="removeItem()">Yes, I'm sure.</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .menu-title {
    display: flex;
    flex-direction: row;
  }
  .menu-title:before, .menu-title:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #ced4da;
    margin: auto;
  }
  .menu-title:before {
    margin-right: 10px
  }
  .menu-title:after {
    margin-left: 10px
  }
</style>
<script>
import { mdbInput, mdbIcon, mdbBtn } from 'mdbvue'
export default {
  name: 'items',
  components: { mdbInput, mdbIcon, mdbBtn },
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
      refreshToken: 1,
      newItemName: '',
      newItemDescription: '',
      newItemPrice: '',
      editingItemName: '',
      editingItemDescription: '',
      editingItemPrice: '',
      editingItemId: '',
      editingItemDisplayOrder: 1,
      editingItemSectionId: '',
      deleteItem: '',
      deleteItemIndex: '',
      selectedSectionId: ''
    }
  },
  watch: {},
  methods: {
    sectionSelectOnChange (event) {
      this.selectedSectionId = event.target.value
    },
    addItem () {
      if (this.newItemName === '') {
        return
      }
      this.$parent.openModal('addNewItem')
    },
    saveNewItem () {
      let displayOrder
      if (this.selectedSectionId) {
        displayOrder = this.availableSectionItems(this.selectedSectionId).length + 1
      } else {
        displayOrder = this.availableNoSectionItems(this.$parent.selectedMenuId).length + 1
      }
      const item = {
        id: this.$parent.uuid(),
        menuId: this.$parent.selectedMenuId,
        sectionId: this.selectedSectionId,
        name: this.newItemName,
        description: this.newItemDescription,
        price: this.newItemPrice,
        displayOrder: displayOrder
      }
      this.$parent.setup.items.push(item)
      this.closeAddNewItem()
    },
    closeAddNewItem () {
      this.$parent.closeModal('addNewItem')
      this.newItemName = ''
      this.newItemDescription = ''
      this.newItemPrice = ''
      this.selectedSectionId = ''
    },
    availableSections (menuId) {
      return this.$parent.setup.sections
        .filter(s => s.menuId === menuId)
        .sort((a, b) => a.displayOrder - b.displayOrder)
    },
    availableItems (menuId) {
      return this.$parent.setup.items
        .filter(s => s.menuId === menuId)
        .sort((a, b) => a.displayOrder - b.displayOrder)
    },
    availableSectionItems (sectionId) {
      return this.$parent.setup.items
        .filter(s => s.sectionId === sectionId)
        .sort((a, b) => a.displayOrder - b.displayOrder)
    },
    availableNoSectionItems (menuId) {
      return this.$parent.setup.items
        .filter(s => s.menuId === menuId && s.sectionId === '')
        .sort((a, b) => a.displayOrder - b.displayOrder)
    },
    moveMenuSectionItem (itemId, sectionId, delta) {
      const items = JSON.parse(JSON.stringify(this.availableSectionItems(sectionId)))
      const itemLength = items.length
      for (var index = 0; index < itemLength; index++) {
        if (items[index].id === itemId) {
          break
        }
      }
      var newIndex = index + delta
      if (newIndex < 0 || newIndex === itemLength) return

      var indexes = [index, newIndex].sort((a, b) => a - b)
      items.splice(indexes[0], 2, items[indexes[1]], items[indexes[0]])
      var displayOrder = 1
      for (var sIndex = 0; sIndex < itemLength; sIndex++) {
        if (items[sIndex] !== undefined) {
          const data = {
            name: 'items',
            keyName: 'id',
            keyValue: items[sIndex].id,
            updateKey: 'displayOrder',
            updateKeyValue: displayOrder
          }
          this.$parent.updateSetupStateArrayItem(data)
          displayOrder = displayOrder + 1
        }
      }
      this.refreshToken++
    },
    moveMenuItem (itemId, menuId, delta) {
      const items = JSON.parse(JSON.stringify(this.availableNoSectionItems(menuId)))
      const itemLength = items.length
      for (var index = 0; index < itemLength; index++) {
        if (items[index].id === itemId) {
          break
        }
      }
      var newIndex = index + delta
      if (newIndex < 0 || newIndex === itemLength) return

      var indexes = [index, newIndex].sort((a, b) => a - b)
      items.splice(indexes[0], 2, items[indexes[1]], items[indexes[0]])
      var displayOrder = 1
      for (var sIndex = 0; sIndex < itemLength; sIndex++) {
        if (items[sIndex] !== undefined) {
          const data = {
            name: 'items',
            keyName: 'id',
            keyValue: items[sIndex].id,
            updateKey: 'displayOrder',
            updateKeyValue: displayOrder
          }
          this.$parent.updateSetupStateArrayItem(data)
          displayOrder = displayOrder + 1
        }
      }
    },
    updateItemOrdering () {
      this.updateMenuItemOrder()
      const sections = JSON.parse(JSON.stringify(this.availableSections(this.$parent.selectedMenuId)))
      for (var sIndex = 0; sIndex < sections.length; sIndex++) {
        if (sections[sIndex] !== undefined) {
          this.updateSectionItemOrder(sections[sIndex].id)
        }
      }
    },
    updateMenuItemOrder () {
      const items = JSON.parse(JSON.stringify(this.availableNoSectionItems(this.$parent.selectedMenuId)))
      var displayOrder = 1
      for (var sIndex = 0; sIndex < items.length; sIndex++) {
        if (items[sIndex] !== undefined) {
          const data = {
            name: 'items',
            keyName: 'id',
            keyValue: items[sIndex].id,
            updateKey: 'displayOrder',
            updateKeyValue: displayOrder
          }
          this.$parent.updateSetupStateArrayItem(data)
          displayOrder = displayOrder + 1
        }
      }
    },
    updateSectionItemOrder (sectionId) {
      const sectionItems = JSON.parse(JSON.stringify(this.availableSectionItems(sectionId)))
      var displayOrder = 1
      for (var sIndex = 0; sIndex < sectionItems.length; sIndex++) {
        if (sectionItems[sIndex] !== undefined) {
          const data = {
            name: 'items',
            keyName: 'id',
            keyValue: sectionItems[sIndex].id,
            updateKey: 'displayOrder',
            updateKeyValue: displayOrder
          }
          this.$parent.updateSetupStateArrayItem(data)
          displayOrder = displayOrder + 1
        }
      }
    },
    editItem (item) {
      this.editingItemName = item.name
      this.editingItemDescription = item.description
      this.editingItemPrice = item.price
      this.editingItemId = item.id
      this.editingItemDisplayOrder = item.displayOrder
      this.editingItemSectionId = item.sectionId
      this.selectedSectionId = item.sectionId
      this.$parent.openModal('itemEditor')
    },
    closeItemEdit () {
      this.$parent.closeModal('itemEditor')
      this.editingItemName = ''
      this.editingItemDescription = ''
      this.editingItemPrice = ''
      this.editingItemId = ''
      this.editingItemSectionId = ''
      this.selectedSectionId = ''
      this.editingItemDisplayOrder = 1
    },
    saveItem () {
      let displayOrder = this.editingItemDisplayOrder
      if (this.editingItemSectionId !== this.selectedSectionId) {
        if (this.selectedSectionId) {
          displayOrder = this.availableSectionItems(this.selectedSectionId).length + 1
        } else {
          displayOrder = this.availableNoSectionItems(this.$parent.selectedMenuId).length + 1
        }
      }
      const itemData = {
        id: this.editingItemId,
        menuId: this.$parent.selectedMenuId,
        sectionId: this.selectedSectionId,
        name: this.editingItemName,
        description: this.editingItemDescription,
        price: this.editingItemPrice,
        displayOrder: displayOrder
      }
      const data = {
        name: 'items',
        keyName: 'id',
        keyValue: this.editingItemId,
        updateKey: 'element',
        updateKeyValue: itemData
      }
      this.$parent.updateSetupStateArrayItem(data)
      this.updateItemOrdering()
      this.closeItemEdit()
    },
    removeItem () {
      const data = JSON.parse(JSON.stringify(this.deleteItem))
      const trashItem = {
        id: this.$parent.uuid(),
        location: ['setup', 'items'],
        value: data
      }
      this.$parent.moveToTrash(trashItem)
      const deleteItem = {
        name: 'items',
        keyName: 'id',
        keyValue: this.deleteItem.id
      }
      this.$parent.deleteSetupStateArrayItem(deleteItem)
      this.updateItemOrdering()
      this.closeItemDelete()
    },
    deleteItemConfirm (index, item) {
      this.deleteItem = item
      this.deleteItemIndex = index
      this.$parent.openModal('itemDelete')
    },
    closeItemDelete () {
      this.deleteItem = ''
      this.deleteItemIndex = ''
      this.$parent.closeModal('itemDelete')
    },
    getItems () {
      return this.$parent.setup.items
    }
  },
  computed: {}
}
</script>
