import { menuSectionsService } from '../_services'

const state = {
  all: {},
  active: {},
  activemenusection: {},
  addmenusection: {},
  updatemenusection: {},
  idsNamesSelect: [],
  idsNamesSelectActive: []
}

const actions = {
  getAll ({ commit }, restGroupId, restId, menuId) {
    const stateName = 'all'
    commit('request', { stateName: stateName })

    menuSectionsService.getAll(restGroupId, restId, menuId)
      .then(
        sections => commit('getAllSuccess', sections),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getActive ({ commit }, restGroupId, restId, menuId) {
    const stateName = 'active'
    commit('request', { stateName: stateName })

    menuSectionsService.getActive(restGroupId, restId, menuId)
      .then(
        sections => commit('getActiveSuccess', sections),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getById ({ commit }, id) {
    const stateName = 'activemenuitem'
    commit('request', { stateName: stateName })

    menuSectionsService.getById(id)
      .then(
        seciton => commit('getByIdSuccess', seciton.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  add ({ dispatch, commit }, menuId, name, description, displayOrder, isActive, isTemporary, hideName) {
    const stateName = 'addmenuitem'
    commit('request', { stateName: stateName })

    menuSectionsService.add(menuId, name, description, displayOrder, isActive, isTemporary, hideName)
      .then(
        section => {
          commit('addSuccess', section)
          dispatch('alert/success', 'Added Menu Item.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  update ({ dispatch, commit }, menuId, name, description, displayOrder, isActive, isTemporary, hideName, sectionId) {
    const stateName = 'updatemenuitem'
    commit('request', { stateName: stateName })

    menuSectionsService.update(menuId, name, description, displayOrder, isActive, isTemporary, hideName, sectionId)
      .then(
        section => {
          commit('updateSuccess', section)
          dispatch('alert/success', 'Edited Menu Item.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  getAllSuccess (state, sections) {
    const selectData = []
    if (sections) {
      state.all = sections
      Array.from(sections).forEach(section => {
        selectData.push({ code: section.menu_section_id, label: section.name })
      })
    }
    state.idsNamesSelect = selectData
  },
  getActiveSuccess (state, sections) {
    /* Get all active Menu Sections */
    const selectData = []
    if (sections) {
      state.active = sections
      Array.from(sections).forEach(section => {
        selectData.push({ code: section.menu_section_id, label: section.name })
      })
    }
    state.idsNamesSelectActive = selectData
  },
  getByIdSuccess (state, section) {
    state.activemenusection = section
  },
  addSuccess (state, section) {
    state.addmenusection = section
  },
  updateSuccess (state, section) {
    state.updatemenusection = section
  }
}

export const menusections = {
  namespaced: true,
  state,
  actions,
  mutations
}
