<template>
    <div class="menusetup-child-component modifiers">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="jumbotron p-4">
                <h1 class="display-6">Modifiers Sets <a class="btn btn-primary btn-sm" href="#" role="button" v-on:click="addModifierSet()">Add Modifier Set</a> <a class="btn btn-primary btn-sm disabled" href="#" role="button" v-on:click="importDataComponent()">Import Modifiers</a></h1>
                <p>Create item modifier sets and modifiers to assign to menu items</p>
                <hr class="my-4">
                <div class="container-fluid">
                  <div class="row" v-if="Object.keys(modifierSet).length !== 0">
                    <table class="table table-sm">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">Name</th>
                          <th scope="col">Description</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="modifierSets in modifierSet" :key="modifierSets.modifierSetId">
                          <td><button class="btn btn-sm btn-primary" v-on:click="showModifiers(modifierSets.modifierSetId)">Modifiers</button></td>
                          <td><input type="text" class="form-control" placeholder="Modifier Set Name" v-model="modifierSets.name"></td>
                          <td><input type="text" class="form-control" placeholder="Modifier Set Description" v-model="modifierSets.description"></td>
                          <td><button class='btn btn-sm btn-danger' v-on:click="deleteModifierSet(modifierSets.modifierSetId)">Delete</button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row" v-else>
                    <p class="text-center">No modifier sets available. <button class="btn btn-sm btn-primary" v-on:click="addModifierSet()">Add Modifier Set</button></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="activeModifierSet && activeModifierSet !== 0">
            <div class="col-auto">
              <button class="btn btn-sm btn-danger" v-on:click="closeModiferSet()">Close (x)</button>
            </div>
            <div class="col">
              <div class="jumbotron p-4">
                <h1 class="display-6">Modifiers <a class="btn btn-primary btn-sm" role="button" v-on:click="addModifier(activeModifierSet)">Add New Modifier</a></h1>
                <hr class="my-4">
                <div class="container-fluid">
                  <div v-for="(item, key) in modifier" :key="key + '-' + item.modifier">
                    <div class="form-row align-items-center mb-2" v-if="item.modifierSetId === activeModifierSet">
                        <div class="col">
                          <input type="text" class="form-control" placeholder="Modifier Name" v-model="item.name">
                        </div>
                        <div class="col">
                          <input type="text" class="form-control" placeholder="Modifier Description" v-model="item.description">
                        </div>
                        <div class="col-auto">
                          <button class='btn btn-sm btn-danger' v-on:click="deleteModifier(item.modifierId)">Delete</button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="Object.keys(modifier).length !== 0">
            <div class="col">
              <p class="lead text-center">
                <a class="btn btn-primary btn-lg" href="#" role="button" v-on:click="applicationOfModifiersComponent">Next: Apply Modifiers</a>
              </p>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'modifiers',
  data () {
    return {
    }
  },
  methods: {
    showModifiers (modifierSetId) {
      this.$parent.setActiveModifierSet(modifierSetId)
    },
    closeModiferSet () {
      this.$parent.setActiveModifierSet(0)
    },
    buildModifierSetData (modifierSetId) {
      const modifierSetData = {
        modifierSetId: modifierSetId,
        name: '',
        description: ''
      }
      const data = { modifierSetData: modifierSetData }
      this.$parent.addModifierSet(data)
    },
    addModifierSet () {
      this.closeModiferSet()
      // Grab the next Id
      let nextId
      nextId = 0
      const storeRestData = JSON.parse(JSON.stringify(this.modifierSet))
      for (var modifierSet in storeRestData) {
        if (nextId <= storeRestData[modifierSet].modifierSetId) {
          nextId = storeRestData[modifierSet].modifierSetId
        }
      }
      nextId = nextId + 1
      this.buildModifierSetData(nextId)
    },
    buildModifierData (modifierId, modifierSetId) {
      const modifierData = {
        modifierId: modifierId,
        modifierSetId: modifierSetId,
        name: '',
        description: ''
      }
      const data = { modifierData: modifierData }
      this.$parent.addModifier(data)
    },
    addModifier (modifierSetId) {
      // Grab the next Id
      let nextId
      nextId = 0
      const storeRestData = JSON.parse(JSON.stringify(this.modifier))
      for (var modifier in storeRestData) {
        if (nextId <= storeRestData[modifier].modifierId) {
          nextId = storeRestData[modifier].modifierId
        }
      }
      nextId = nextId + 1
      this.buildModifierData(nextId, modifierSetId)
    },
    deleteModifierSet (modifierSetId) {
      this.$parent.deleteModifierSet(modifierSetId)
      this.$parent.setActiveModifierSet(0)
    },
    deleteModifier (modifierId) {
      this.$parent.deleteModifier(modifierId)
    },
    applicationOfModifiersComponent () {
      this.$parent.applicationOfModifiersComponent()
    },
    importDataComponent () {
      this.$parent.importDataComponent('modifiers')
    }
  },
  computed: {
    ...mapState({
      restaurant: state => state.menussetup.restaurant,
      activeRestaurant: state => state.menussetup.activeRestaurant,
      brand: state => state.menussetup.brand,
      restOperationType: state => state.menussetup.restOperationType,
      menus: state => state.menussetup.menu,
      activeMenu: state => state.menussetup.activeMenu,
      menuSections: state => state.menussetup.menu_section,
      activeMenuSection: state => state.menussetup.activeMenuSection,
      items: state => state.menussetup.item,
      modifier: state => state.menussetup.modifier,
      activeModifier: state => state.menussetup.activeModifier,
      modifierSet: state => state.menussetup.modifier_set,
      activeModifierSet: state => state.menussetup.activeModifierSet
    })
  }
}
</script>
