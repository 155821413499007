<template>
 <div class="import-page page">
        <h4 class="page-title">Import Menus</h4>
        <p>Upload a properly formatted excel spreadsheet of menu data to import menus. <a href="/downloads/menuImport-v1-template.xlsx">Download this template to get started.</a>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <form enctype="multipart/form-data" v-if="isInitial || isSaving">
                <div class="dropbox">
                  <input type="file"
                  :name="uploadFieldName"
                  :disabled="isSaving"
                  @change="filesChange($event.target.accept, $event.target.files)"
                   class="input-file">
                    <p v-if="isInitial">
                      Drag your file here to begin<br> or click to browse your files.
                    </p>
                    <p v-if="isSaving">
                      Uploading ...
                    </p>
                </div>
              </form>
              <!--SUCCESS-->
              <div v-if="isSuccess" class='upload upload-success'>
                <h6>Uploaded successfully.</h6>
                Rows Imported: {{ Object.keys(processedRows).length }} Skipped: {{ Object.keys(skippedRows).length }}
                <p>
                  <a href="javascript:void(0)" @click="reset()">Upload again</a>
                </p>
              </div>
              <!--FAILED-->
              <div v-if="isFailed" class='upload upload-failed'>
                <h6>Upload failed.</h6>
                <p>{{ uploadError }}</p>
                <p>
                  <a href="javascript:void(0)" @click="reset()">Try again</a>
                </p>
              </div>
            </div>
          </div>
        </div>
  </div>
</template>
<style lang="scss" scoped>
  .import-page {
    margin-bottom: .5rem;
    .page-title {
      border-bottom: 1px solid #ccc;
      border-top: 1px solid #ccc;
      padding-top: .5rem;
      padding-bottom: .5rem;
    }
  }
  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;

    p {
      font-size: 1.2em;
      text-align: center;
      padding: 50px 0;
    }

    &:hover {
      background: lightblue;
    }
  }

  .upload {
    @extend .dropbox;
    text-align: center;
    p, h5 {
      padding: 1rem;
    }
    &.upload-failed {
      background: lightcoral;
    }
    &.upload-success {
      background: lightgreen;
    }
  }

  .input-file {
    opacity: 0;
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'MenuImport',
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
      uploadError: '',
      skippedRows: {},
      processedRows: {},
      uploadFieldName: 'importMenu',
      allowedExtensions: ['xls', 'xlsx'],
      allowedMimeTypes: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'],
      isSuccess: false,
      isFailed: false,
      isInitial: true,
      isSaving: false
    }
  },
  computed: {
    ...mapState({
      upload: state => state.importModule.upload
    })
  },
  created () {
  },
  watch: {
    upload: {
      handler (val, oldVal) {
        if ('menuData' in val) {
          // console.log(val.menuData)
          this.isInitial = false
          this.isSaving = false
          this.isSuccess = true
          this.skippedRows = val.menuData.skipped
          this.processedRows = val.menuData.processed
        }
        if ('error' in val) {
          this.isInitial = false
          this.isSaving = false
          this.isFailed = true
          this.uploadError = val.error
        }
      }
    }
  },
  methods: {
    ...mapActions('importModule', {
      uploadMenuImportFile: 'uploadMenuImportFile'
    }),
    reset () {
      // reset form to initial state
      this.uploadError = ''
      this.skippedRows = {}
      this.processedRows = {}
      this.isSuccess = false
      this.isFailed = false
      this.isInitial = true
      this.isSaving = false
    },
    save (formData) {
      // upload data to the server
      const { restGroupId, restId } = this.componentProps
      this.uploadMenuImportFile({ restGroupId, restId, formData })
    },
    filesChange (accept, fileList) {
      this.isSaving = true
      this.isInitial = false

      if (fileList.length !== 1) return
      const verifyFileUpload = this.verifyFileUpload(fileList[0])
      if (verifyFileUpload === false || this.uploadError.length) {
        this.isFailed = true
        this.isSaving = false
        return
      }

      // handle file changes
      const formData = new FormData()
      const fieldName = this.uploadFieldName
      formData.append(fieldName, fileList[0], fileList[0].name.toLowerCase())

      // save it
      this.save(formData)
    },
    verifyFileUpload (file) {
      let validExtension, validMimeType
      validExtension = false
      validMimeType = false

      // Validate File Extension
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
      for (var i = 0; i < this.allowedExtensions.length; i++) {
        if (extension === this.allowedExtensions[i]) {
          validExtension = true
        }
      }
      if (!validExtension) {
        this.uploadError = 'Invalid File Extension: ' + extension
        return false
      }

      // Validate Mimetype
      const mimeType = file.type
      for (var j = 0; j < this.allowedMimeTypes.length; j++) {
        if (mimeType === this.allowedMimeTypes[j]) {
          validMimeType = true
        }
      }
      if (!validMimeType) {
        this.uploadError = 'Invalid File Mime Type: ' + mimeType
        return false
      }
    }
  }
}
</script>
