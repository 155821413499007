<template>
    <div class="page modifiers-page">
      <div class="container-fluid">
        <div v-if="editmodifiersetmembers" class="edit-modifer-set-members">
          <div class="row">
            <div class="col-12">
              <h6 class="header">{{activemodifierset.name}} - Set Modifiers
                <button v-on:click="closeModifierSetMembers(editmodifiersetmembers)" class="btn btn-sm btn-primary">Click Here When Done Editing</button>
              </h6>
            </div>
          </div>
          <div v-if="'modifiers' in groupmodifiers" class="row">
            <div class="modifiers-list col-6">
              <h6 class="mt-3">Available Modifiers</h6>
                    <table class="table table-striped">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Description</th>
                          <th scope="col">Add</th>
                        </tr>
                      </thead>
                      <tbody>
                            <tr v-for="modifier in GetAvailableModifiers(groupmodifiers.modifiers)" :key="modifier.modifer_id">
                              <td>{{modifier.modifier_id}}</td>
                              <td>{{modifier.name}}</td>
                              <td>{{modifier.description}}</td>
                              <td><button class="btn btn-sm btn-success" v-on:click="addModifierSetMemberButton(modifier.modifier_id, modifier.name)" >Add</button></td>
                            </tr>
                      </tbody>
                    </table>
              </div>
              <div class="col-6">
                <ModifierSetMembers v-bind:editmodifiersetmembers="editmodifiersetmembers" />
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <ModifierSets/>
          </div>
          <div class="col-md-6 col-sm-12" id="modifier-list">
            <h6>Modifiers <button v-on:click="addModifierButton"  class="btn btn-sm btn-primary btn-sm">Add +</button></h6>
            <div class="modifier-form" v-if="modifierform">
                <p>{{formtitle}}</p>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 mb-3">
                            <label for="name">Name</label>
                            <input type="text" class="form-control" id="name" placeholder="" v-model="modifiername">
                            <div class="input-error" v-if="msg.errors.modifiername">
                                Valid modifier name is required.
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 mb-3">
                            <label for="description">Description</label>
                            <input type="text" class="form-control" id="description" placeholder="" v-model="modifierdescription">
                            <div class="input-error" v-if="msg.errors.modifierdescription">
                                Valid modifier Description is required.
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="editingmodifier">
                      <div class="col-sm-12 mb-3 deletecontrol text-right">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" v-model="deletemodifier" id="deletemodifier">
                          <label class="form-check-label" for="deletemodifier">
                            Delete this Modifier?
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 mb-3 text-left form-controls">
                            <button v-on:click="saveModifierFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                            <button v-on:click="closeModifierFormButton" class="btn btn-sm btn-danger">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="'modifiers' in groupmodifiers" class="modifiers-list">
                <table class="table table-striped">
                  <thead class="thead-dark">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                        <tr v-for="modifier in groupmodifiers.modifiers" :key="modifier.id">
                          <td>{{modifier.modifier_id}}</td>
                          <td>{{modifier.name}}</td>
                          <td>{{modifier.description}}</td>
                          <td>
                            <button v-on:click="editModifierButton(modifier.modifier_id)" class="btn btn-sm btn-primary">Details</button>
                          </td>
                        </tr>
                  </tbody>
                </table>
            </div>
            <div v-else>
              No modifiers available.
            </div>
          </div>
        </div>
        <ModifierSpecSets/>
      </div>
    </div>
</template>
<style lang="scss" scoped>
  .edit-modifer-set-members {
    background-color: #6c757d14;
    padding: 1rem;
    border: 2px solid #93b793;
    margin-bottom: 1rem;
    .header {
      border-bottom: 1px solid black;
      padding-bottom: 1rem;
    }
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'
import ModifierSets from './child-components/ModifierSets'
import ModifierSetMembers from './child-components/ModifierSetMembers'
import ModifierSpecSets from './child-components/ModifierSpecSets'

export default {
  name: 'Modifiers',
  data () {
    return {
      formtitle: 'Add new Modifier',
      formsavetext: 'Save',
      modifierform: false,
      restgroupid: this.$route.params.id,
      modifiername: '',
      modifierdescription: '',
      editingmodifier: '',
      deletemodifier: false,
      availablesetmodifiers: null,
      msg: {
        errors: {
          modifiername: false,
          modifierdescription: false
        }
      }
    }
  },
  components: {
    ModifierSets,
    ModifierSetMembers,
    ModifierSpecSets
  },
  computed: {
    ...mapState({
      groupmodifiers: state => state.modifiers.all,
      activemodifier: state => state.modifiers.activemodifier,
      addmodifier: state => state.modifiers.addmodifier,
      updatemodifier: state => state.modifiers.updatemodifier,
      editmodifiersetmembers: state => state.modifiers.editmodifiersetmembers,
      activemodifierset: state => state.modifiers.activemodifierset,
      groupmodifiersetmembers: state => state.modifiers.allsetmembers
    })
  },
  created () {
    this.getModifiers(this.restgroupid)
  },
  watch: {
    activemodifier: {
      handler (val, oldVal) {
        this.modifiername = val.name
        this.modifierdescription = val.description
        this.editingmodifier = val.modifier_id
      }
    },
    modifiername: {
      handler (val, oldVal) {
        this.validateModifierForm()
      }
    },
    modifierdescription: {
      handler (val, oldVal) {
        this.validateModifierForm()
      }
    },
    addmodifier: {
      handler (val, oldVal) {
        if (val.modifier_id) {
          this.getModifiers(this.restgroupid)
          this.ModifierFormReset()
          this.closeModifierFormButton()
        }
      }
    },
    updatemodifier: {
      handler (val, oldVal) {
        if (val.modifier_id) {
          this.getModifiers(this.restgroupid)
          this.ModifierFormReset()
          this.closeModifierFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('modifiers', {
      getModifiers: 'getAll',
      getModifier: 'getById',
      updateModifier: 'update',
      addModifier: 'add',
      editModifierSetMembers: 'editModifierSetMembers',
      addModifierSetMember: 'addSetMember'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    GetAvailableModifiers (modifiers) {
      var availableModifiers = {}
      Object.keys(modifiers).forEach(key => {
        const modifier = modifiers[key]
        if (this.ModifierAlreadyAssigned(modifier.modifier_id) === false) {
          availableModifiers[key] = modifier
        }
      })
      return availableModifiers
    },
    ModifierAlreadyAssigned (modifierId) {
      if (this.groupmodifiersetmembers.sets) {
        for (var i = 0; i < this.groupmodifiersetmembers.sets.length; i++) {
          const inUseModifierId = this.groupmodifiersetmembers.sets[i].modifier_id
          if (inUseModifierId === modifierId) {
            return true
          }
        }
      }
      return false
    },
    ModifierFormReset (event) {
      this.formtitle = 'Add new Modifier'
      this.modifiername = ''
      this.modifierdescription = ''
      this.editingmodifier = ''
      this.deletemodifier = false
    },
    closeModifierFormButton (event) {
      this.modifierform = false
    },
    closeModifierSetMembers (setId) {
      const data = { setId: setId, action: 'close' }
      this.editModifierSetMembers(data)
    },
    addModifierSetMemberButton (modifierId, modifierName) {
      const association = this.activemodifierset.name + '-' + modifierName
      const data = {
        editmodifiersetmembers: this.editmodifiersetmembers,
        modifierid: modifierId,
        modifiersetmemberassociation: association
      }
      this.addModifierSetMember(data)
    },
    addModifierButton (event) {
      this.clearAlert()
      this.ModifierFormReset()
      if (this.modifierform) {
        this.closeModifierFormButton()
        return
      }
      this.modifierform = true
    },
    editModifierButton (id) {
      this.clearAlert()
      this.getModifier(id)
      this.modifierform = true
      this.formtitle = 'Edit Modifier'
      this.scrollToTop('modifier-list')
    },
    scrollToTop (elementId = null) {
      var top = 0
      if (elementId) {
        var element = document.getElementById(elementId)
        top = element.offsetTop
      }
      window.scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    },
    validateModifierForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    saveModifierFormButton (event) {
      this.clearAlert()
      if (this.validateModifierForm() === false) {
        return
      }

      if (this.editingmodifier) {
        const {
          restgroupid,
          editingmodifier,
          modifiername,
          modifierdescription,
          deletemodifier
        } = this
        this.updateModifier({
          editingmodifier,
          modifiername,
          modifierdescription,
          restgroupid,
          deletemodifier
        })
      } else {
        const {
          modifiername,
          modifierdescription,
          restgroupid
        } = this
        this.addModifier({
          modifiername,
          modifierdescription,
          restgroupid
        })
      }
    }
  }
}
</script>
