import { unitsOfMeasureService } from '../_services'

const state = {
  all: {},
  unittypes: [],
  idsNamesSelect: [],
  activeunitofmeasure: {},
  addunitofmeasure: {},
  updateunitofmeasure: {}
}

const actions = {
  getAll ({ commit }, restGroupId) {
    const stateName = 'all'
    commit('request', { stateName: stateName })

    unitsOfMeasureService.getAll(restGroupId)
      .then(
        unitsofmeasure => commit('getAllSuccess', unitsofmeasure.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getTypes ({ commit }, restGroupId) {
    const stateName = 'unittypes'
    commit('request', { stateName: stateName })

    unitsOfMeasureService.getTypes(restGroupId)
      .then(
        types => commit('getTypesSuccess', types.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getById ({ commit }, id) {
    const stateName = 'activeunitofmeasure'
    commit('request', { stateName: stateName })

    unitsOfMeasureService.getById(id)
      .then(
        unitofmeasure => commit('getByIdSuccess', unitofmeasure.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  add ({ dispatch, commit }, restGroupId, unitTypeId, name, description) {
    const stateName = 'addunitofmeasure'
    commit('request', { stateName: stateName })

    unitsOfMeasureService.add(restGroupId, unitTypeId, name, description)
      .then(
        unitofmeasure => {
          commit('addSuccess', unitofmeasure.data)
          dispatch('alert/success', 'Added unit of measure.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  update ({ dispatch, commit }, unitId, unitTypeId, restGroupId, name, description) {
    const stateName = 'updateunitofmeasure'
    commit('request', { stateName: stateName })

    unitsOfMeasureService.update(unitId, unitTypeId, restGroupId, name, description)
      .then(
        unitofmeasure => {
          commit('updateSuccess', unitofmeasure.data)
          dispatch('alert/success', 'Edited unit of measure.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  getAllSuccess (state, unitsofmeasure) {
    state.all = { unitsofmeasure }
    const selectData = []
    if (unitsofmeasure) {
      Array.from(unitsofmeasure).forEach(unitofmeasure => {
        selectData.push({ code: unitofmeasure.unit_of_measure_id, label: unitofmeasure.name })
      })
    }
    state.idsNamesSelect = selectData
  },
  getByIdSuccess (state, unitofmeasure) {
    state.activeunitofmeasure = unitofmeasure
  },
  addSuccess (state, unitofmeasure) {
    state.addunitofmeasure = unitofmeasure
  },
  updateSuccess (state, unitofmeasure) {
    state.updateunitofmeasure = unitofmeasure
  },
  getTypesSuccess (state, types) {
    const selectData = []
    if (types) {
      Array.from(types).forEach(type => {
        selectData.push({ code: type.property_value_id, label: type.property })
      })
    }
    state.unittypes = selectData
  }
}

export const unitsofmeasure = {
  namespaced: true,
  state,
  actions,
  mutations
}
