<template>
    <div class="page menus-page">
      <div class="csmData-test" v-if="csmData">
        <p><b>Client Site Menu API Call Test</b> - <button v-on:click="closeCsmDataTest" class='btn btn-sm btn-danger'>Close X</button></p>
        <p>RestGroup: {{csmRestGroupName}} RestName: {{csmRestName}} MenuName: {{csmMenuName}}</p>
        <p><b>{{csmCall}}</b></p>
        <pre>
          {{csmData.data}}
        </pre>
      </div>
      <h6 class="page-title">Menus
        <button v-on:click="addMenuButton" class="btn btn-sm btn-primary">Add Menu</button> <button v-on:click="menuImportComponent" :class="[importButtonDefaultClasses, importButtonTypeClass]">{{ importButtonText }}</button></h6>
      <div class="menu-import" v-if="menuimport">
        <component v-bind:is="component" :componentProps="childComponentProps" />
      </div>
      <div class="menu-form" v-if="menuform">
          <p>{{formtitle}}</p>
          <div class="container-fluid">
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <p>Menu Type</p>
                      <v-select placeholder="Search..." :options="menutypes" @input="setMenuType" v-model="menuTypeName"></v-select>
                      <div class="input-error" v-if="msg.errors.menuTypeId">
                          Valid Menu Type is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="name">Name</label>
                      <input type="text" class="form-control" id="name" placeholder="" v-model="name">
                      <div class="input-error" v-if="msg.errors.name">
                          Valid name is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="description">Description</label>
                      <input type="text" class="form-control" id="description" placeholder="" v-model="description">
                      <div class="input-error" v-if="msg.errors.description">
                          Valid description is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="isActive" placeholder="" v-model="isActive" :checked="isActive == 1">
                      <label class="form-check-label" for="isActive">
                        Is Active
                      </label>
                    </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="isTemporary" placeholder="" v-model="isTemporary" :checked="isTemporary == 1">
                      <label class="form-check-label" for="isTemporary">
                        Is Temporary
                      </label>
                    </div>
                  </div>
              </div>
              <div class="row" v-if="editingmenu">
                <div class="col-sm-12 col-md-6 mb-3 deletecontrol text-right">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="deleteMenu" id="deleteMenu">
                    <label class="form-check-label" for="deleteMenu">
                      Delete this Menu?
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3 text-left form-controls">
                      <button v-on:click="saveMenuFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                      <button v-on:click="closeMenuFormButton" class="btn btn-sm btn-danger">Close</button>
                  </div>
              </div>
          </div>
      </div>
      <div v-if="'menus' in restmenus" class="menus-list">
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Manage</th>
                <th scope="col">Client Site Links</th>
                <th scope="col">ID</th>
                <th scope="col">Menu Type</th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Is Active</th>
                <th scope="col">Is Temporary</th>
                <th scope="col">Edit Details</th>
              </tr>
            </thead>
            <tbody>
                  <tr v-for="menu in restmenus.menus" :key="menu.menu_id">
                    <td>
                      <router-link
                        class="btn btn-sm btn-block btn-success"
                        :to="{ name: 'managerestgrouprestaurantmenucomponent',
                             params: {
                              restGroupId: restGroupId,
                              restId: restId,
                              menuId: menu.menu_id,
                              componentName: 'MenuDisplay'
                             }
                            }">Display
                      </router-link>
                      <router-link
                        class="btn btn-sm btn-block btn-success"
                        :to="{ name: 'managerestgrouprestaurantmenucomponent',
                             params: {
                              restGroupId: restGroupId,
                              restId: restId,
                              menuId: menu.menu_id,
                              componentName: 'MenuItems'
                             }
                            }">Items
                      </router-link>
                      <router-link
                        class="btn btn-sm btn-block btn-success"
                        :to="{ name: 'managerestgrouprestaurantmenucomponent',
                             params: {
                              restGroupId: restGroupId,
                              restId: restId,
                              menuId: menu.menu_id,
                              componentName: 'MenuSections'
                             }
                            }">Sections
                      </router-link>
                      <router-link
                        class="btn btn-sm btn-block btn-success"
                        :to="{ name: 'managerestgrouprestaurantmenucomponent',
                             params: {
                              restGroupId: restGroupId,
                              restId: restId,
                              menuId: menu.menu_id,
                              componentName: 'MenuAddendums'
                             }
                            }">Addendums
                      </router-link>
                    </td>
                    <td>
                        <button type="button" class="btn btn-secondary btn-sm" v-on:click="csmLink(menu.name, 'displayMenu')">Display</button><br />
                        <button type="button" class="btn btn-secondary btn-sm" v-on:click="csmLink(menu.name, 'transactionalMenu')">Transactional</button><br />
                        <button type="button" class="btn btn-secondary btn-sm" v-on:click="csmLink(menu.name, 'menuaddendums')">Addendums</button><br />
                        <!--<button type="button" class="btn btn-secondary btn-sm" v-on:click="csmLink(menu.name, 'menuitemimages')">Item Images</button><br />-->
                        <button type="button" class="btn btn-secondary btn-sm" v-on:click="csmLink(menu.name, 'menusubitemmodifiersets')">Sub Item Modifier Sets</button>
                    </td>
                    <td>{{menu.menu_id}}</td>
                    <td>{{menu.menu_type}}</td>
                    <td>{{menu.name}}</td>
                    <td>{{menu.description}}</td>
                    <td>{{menu.is_active}}</td>
                    <td>{{menu.is_temporary}}</td>
                    <td>
                      <button v-on:click="editMenuButton(menu.menu_id)" class="btn btn-sm btn-primary">Edit Details</button>
                    </td>
                  </tr>
            </tbody>
          </table>
      </div>
      <div v-else>
        No menus available.
      </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import MenuImport from './child-components/MenuImport'

export default {
  name: 'Menus',
  components: {
    MenuImport
  },
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
      restGroupId: '',
      restId: '',
      formtitle: 'Add new Menu',
      formsavetext: 'Save',
      importButtonDefaultClasses: 'btn btn-sm',
      importButtonTypeClass: 'btn-primary',
      importButtonText: 'Import Menus',
      menuform: false,
      name: '',
      description: '',
      isActive: 0,
      isTemporary: 0,
      menuTypeId: '',
      menuTypeName: '',
      editingmenu: '',
      deleteMenu: false,
      component: '',
      childComponentProps: {},
      menuimport: false,
      csmData: '',
      csmMenuName: '',
      csmRestName: '',
      csmRestGroupName: '',
      csmCall: '',
      msg: {
        errors: {
          menuTypeId: false,
          name: false,
          description: false
        }
      }
    }
  },
  computed: {
    ...mapState({
      restmenus: state => state.menus.all,
      activemenu: state => state.menus.activemenu,
      addmenu: state => state.menus.addmenu,
      updatemenu: state => state.menus.updatemenu,
      menutypes: state => state.menus.menutypes,
      csmrequest: state => state.menus.csmRequest,
      activerestaurant: state => state.restaurants.activerestaurant,
      activerestgroup: state => state.restgroups.activerestgroup,
      upload: state => state.importModule.upload
    })
  },
  created () {
    const { restGroupId, restId } = this.componentProps
    this.restGroupId = restGroupId
    this.restId = restId
    this.getMenus({ restGroupId, restId })
    this.getMenuTypes(this.restGroupId)
  },
  watch: {
    upload: {
      handler (val, oldVal) {
        if ('menuData' in val) {
          const { restGroupId, restId } = this.componentProps
          this.getMenus({ restGroupId, restId })
        }
      }
    },
    csmrequest: {
      handler (val, oldVal) {
        if (val) {
          this.csmData = val
        } else {
          this.csmData = ''
        }
      }
    },
    activemenu: {
      handler (val, oldVal) {
        this.name = val.name
        this.description = val.description
        this.menuTypeId = val.menu_type_id
        this.menuTypeName = val.menu_type
        this.isActive = val.is_active
        this.isTemporary = val.is_temporary
        this.editingmenu = val.menu_id
      }
    },
    name: {
      handler (val, oldVal) {
        this.validatemenuForm()
      }
    },
    description: {
      handler (val, oldVal) {
        this.validatemenuForm()
      }
    },
    menuTypeId: {
      handler (val, oldVal) {
        this.validatemenuForm()
      }
    },
    addmenu: {
      handler (val, oldVal) {
        if (val.menu_id) {
          const { restGroupId, restId } = this.componentProps
          this.getMenus({ restGroupId, restId })
          this.menuFormReset()
          this.closeMenuFormButton()
        }
      }
    },
    updatemenu: {
      handler (val, oldVal) {
        if (val.menu_id) {
          const { restGroupId, restId } = this.componentProps
          this.getMenus({ restGroupId, restId })
          this.menuFormReset()
          this.closeMenuFormButton()
        }
      }
    }
  },
  methods: {
    menuImportComponent (event) {
      this.clearAlert()
      if (this.menuimport) {
        this.menuimport = false
        this.importButtonTypeClass = 'btn-primary'
        this.importButtonText = 'Import Menus'
        return
      }

      this.importButtonTypeClass = 'btn-danger'
      this.importButtonText = 'Close Import'
      const { restGroupId, restId } = this.componentProps
      this.childComponentProps = { restGroupId, restId }
      this.component = MenuImport
      this.menuimport = true
    },
    closeCsmDataTest () {
      this.csmData = ''
    },
    ...mapActions('menus', {
      getMenus: 'getAll',
      getMenu: 'getById',
      updateMenu: 'update',
      addMenu: 'add',
      getMenuTypes: 'getTypes',
      csmRequest: 'getClientSideMenu'
    }),
    ...mapActions('restgroups', {
      getRestGroup: 'getById'
    }),
    ...mapActions('restaurants', {
      getRestaurant: 'getById'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    csmLink (menuName, call) {
      this.menuimport = false
      this.csmMenuName = menuName
      this.csmCall = call
      this.csmRestName = this.activerestaurant.name
      this.csmRestGroupName = this.activerestgroup.name
      const { csmMenuName, csmRestName, csmRestGroupName, csmCall } = this
      this.csmRequest({ csmMenuName, csmRestName, csmRestGroupName, csmCall })
    },
    setMenuType (value) {
      if (value !== null) {
        this.menuTypeId = value.code
        this.menuTypeName = value.label
        return
      }
      this.menuTypeId = ''
      this.menuTypeName = ''
    },
    menuFormReset (event) {
      this.formtitle = 'Add new Menu'
      this.name = ''
      this.description = ''
      this.menuTypeId = ''
      this.menuTypeName = ''
      this.isActive = 0
      this.isTemporary = 0
      this.editingmenu = ''
      this.deleteMenu = false
      this.menuimport = false
    },
    closeMenuFormButton (event) {
      this.menuform = false
    },
    addMenuButton (event) {
      this.clearAlert()
      this.menuFormReset()
      if (this.menuform && this.editmenuData === '') {
        this.closeMenuFormButton()
        return
      }
      this.editmenuData = ''
      this.menuform = true
    },
    editMenuButton (id) {
      this.clearAlert()
      this.menuimport = false
      this.getMenu(id)
      this.editingmenu = id
      this.menuform = true
      this.formtitle = 'Edit Menu'
    },
    validatemenuForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    saveMenuFormButton (event) {
      this.clearAlert()
      if (this.validatemenuForm() === false) {
        return
      }

      if (this.editingmenu) {
        const { restGroupId, restId } = this.componentProps
        const {
          editingmenu,
          menuTypeId,
          name,
          description,
          isActive,
          isTemporary,
          deleteMenu
        } = this
        this.updateMenu({
          editingmenu,
          restGroupId,
          restId,
          menuTypeId,
          name,
          description,
          isActive,
          isTemporary,
          deleteMenu
        })
      } else {
        const { restId } = this.componentProps
        const {
          menuTypeId,
          name,
          description,
          isActive,
          isTemporary
        } = this
        this.addMenu({
          restId,
          menuTypeId,
          name,
          description,
          isActive,
          isTemporary
        })
      }
    }
  }
}
</script>
