import { authHeader, apiUrl, handleResponse } from '../_helpers'

export const operatorsService = {
  getAll,
  getById,
  add,
  update
}

function getAll () {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'operator',
      data: {
        action: 'getAll'
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'operator',
      data: {
        action: 'getOperator',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function add (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'operator',
      data: {
        action: 'addOperator',
        name: data.operatorname,
        description: data.operatordescription
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function update (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'operator',
      data: {
        action: 'updateOperator',
        operator_id: data.editingoperator,
        name: data.operatorname,
        description: data.operatordescription,
        deleteoperator: data.deleteoperator
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}
