<template>
    <div class="brands-page page">
        <h6 class="page-title">Brands <button v-on:click="addbrandButton" class="btn btn-sm btn-primary">Add +</button></h6>
        <div class="brand-form" v-if="brandform">
            <p>{{formtitle}}</p>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 col-md-6 mb-3">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" id="name" placeholder="" v-model="brandname">
                        <div class="input-error" v-if="msg.errors.name">
                            Valid brand name is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 mb-3">
                        <label for="description">Description</label>
                        <input type="text" class="form-control" id="description" placeholder="" v-model="branddescription">
                        <div class="input-error" v-if="msg.errors.description">
                            Valid brand Description is required.
                        </div>
                    </div>
                </div>
                <div class="row" v-if="editingbrand">
                  <div class="col-sm-12 col-md-6 mb-3 deletecontrol text-right">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="deletebrand" id="deletebrand">
                      <label class="form-check-label" for="deletebrand">
                        Delete this brand?
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 mb-3 text-left form-controls">
                        <button v-on:click="savebrandFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                        <button v-on:click="closebrandFormButton" class="btn btn-sm btn-danger">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="brands.brands" class="brands-list">
            <table class="table table-striped">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Actions</th>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody>
                    <tr v-for="brand in brands.brands" :key="brand.name">
                      <td>
                        <button v-on:click="editbrandButton(brand.brand_id)" class="btn btn-sm btn-primary">Edit</button>
                      </td>
                      <td>{{brand.brand_id}}</td>
                      <td>{{brand.name}}</td>
                      <td>{{brand.description}}</td>
                    </tr>
              </tbody>
            </table>
        </div>
    </div>

</template>
<style lang="scss" scoped>
    .page {
        margin-top: 2vh;
        .deletebrand {
          color: red;
        }
    }
    .form-controls {
        button {
            margin-right: 2vw;
        }
    }

</style>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      formtitle: 'Add new Brand',
      formsavetext: 'Save',
      brandform: false,
      brandname: '',
      branddescription: '',
      editingbrand: '',
      deletebrand: false,
      msg: { errors: { name: false, description: false } }
    }
  },
  computed: {
    ...mapState({
      brands: state => state.brands.all,
      activebrand: state => state.brands.activebrand,
      addbrand: state => state.brands.addbrand,
      updatebrand: state => state.brands.updatebrand,
      thestate: state => state
    })
  },
  created () {
    this.getAllBrands()
  },
  watch: {
    activebrand: {
      handler (val, oldVal) {
        this.brandname = val.name
        this.branddescription = val.description
        this.editingbrand = val.brand_id
      }
    },
    brandname: {
      handler (val, oldVal) {
        this.validatebrandForm()
      }
    },
    branddescription: {
      handler (val, oldVal) {
        this.validatebrandForm()
      }
    },
    addbrand: {
      handler (val, oldVal) {
        if (val.brand_id) {
          this.getAllBrands()
          this.brandFormReset()
          this.closebrandFormButton()
        }
      }
    },
    updatebrand: {
      handler (val, oldVal) {
        if (val.brand_id) {
          this.getAllBrands()
          this.brandFormReset()
          this.closebrandFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('brands', {
      getAllBrands: 'getAll',
      getBrand: 'getById',
      updateBrand: 'update',
      addBrand: 'add'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    brandFormReset (event) {
      this.formtitle = 'Add new Brand'
      this.brandname = ''
      this.branddescription = ''
      this.editingbrand = ''
      this.deletebrand = false
    },
    closebrandFormButton (event) {
      this.brandform = false
    },
    addbrandButton (event) {
      this.clearAlert()
      this.brandFormReset()
      if (this.brandform && this.editbrandData === '') {
        this.closebrandFormButton()
        return
      }
      this.editbrandData = ''
      this.brandform = true
    },
    editbrandButton (id) {
      this.clearAlert()
      this.getBrand(id)
      this.brandform = true
      this.formtitle = 'Edit Brand'
    },
    validatebrandForm (event) {
      this.msg.errors.name = false
      this.msg.errors.description = false

      if (this.brandname === '') {
        this.msg.errors.name = true
        return false
      }

      if (this.branddescription === '') {
        this.msg.errors.description = true
        return false
      }
    },
    savebrandFormButton (event) {
      this.clearAlert()
      if (this.validatebrandForm() === false) {
        return
      }

      if (this.editingbrand) {
        const { editingbrand, brandname, branddescription, deletebrand } = this
        this.updateBrand({ editingbrand, brandname, branddescription, deletebrand })
      } else {
        const { brandname, branddescription } = this
        this.addBrand({ brandname, branddescription })
      }
    }
  }
}
</script>
