import { authHeader, apiUrl, handleResponse } from '../_helpers'

export const itemsService = {
  getAll,
  getTypes,
  getById,
  add,
  update
}

function getAll (restGroupId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'item',
      data: {
        action: 'getAll',
        restGroupId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getTypes (restGroupId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'item',
      data: {
        action: 'getItemTypes',
        restGroupId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'item',
      data: {
        action: 'getItem',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function add (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'item',
      data: {
        action: 'addItem',
        restGroupId: data.restgroupid,
        itemTypeId: data.itemTypeId,
        name: data.name,
        chit: data.chit,
        ticketName: data.ticketName,
        description: data.description,
        baseQty: data.baseQty,
        price: data.price,
        priceDescription: data.priceDescription,
        isMeasured: data.isMeasured
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function update (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'item',
      data: {
        action: 'updateItem',
        itemId: data.editingitem,
        restGroupId: data.restgroupid,
        itemTypeId: data.itemTypeId,
        name: data.name,
        chit: data.chit,
        ticketName: data.ticketName,
        description: data.description,
        baseQty: data.baseQty,
        price: data.price,
        priceDescription: data.priceDescription,
        isMeasured: data.isMeasured,
        deleteItem: data.deleteItem
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}
