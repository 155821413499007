<template>
    <div class="landing">
      <div class="container-fluid">
        <div class="row">
            Landing owner Portal
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: 'landing',
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {}
  },
  watch: {},
  methods: {},
  computed: {}
}
</script>
