export const menusSetupService = {
  addRestaurant,
  addMenu,
  addMenuSection,
  addMenuItem,
  addModifierSet,
  addModifier
}

function addRestaurant (data) {
  return data.restData
}

function addMenu (data) {
  return data.menuData
}

function addMenuSection (data) {
  return data.menuSectionData
}

function addMenuItem (data) {
  return data.menuItemData
}

function addModifierSet (data) {
  return data.modifierSetData
}

function addModifier (data) {
  return data.modifierData
}
