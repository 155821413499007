<template>
    <div class="menusetup-page" :class="{ 'page' : !fullScreen }">
      <div class="container-fluid">
        <div class="row">
          <nav id="sidebarMenu" class="col-md-2 bg-light">
            <div class="sidebar-sticky pt-3">
              <ul class="nav flex-column">
                <li class="nav-item mb-2">
                  <a class="nav-link btn btn-block btn-primary text-right" :class="{ 'active' : activeNav === 'ladning' }" v-on:click="landingComponent">Landing</a>
                </li>
              </ul>
            </div>
          </nav>
          <main role="main" class="col-md-10" :key="renderer">
            <component v-bind:is="component" :componentProps="childComponentProps" />
          </main>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <hr>
            <a class="btn btn-sm btn-primary" v-if="!showDevOptions" v-on:click="toggleDevOptions(true)">Show Dev Options</a>
            <a class="btn btn-sm btn-danger" v-if="showDevOptions" v-on:click="toggleDevOptions(false)">Close Dev Options</a>
            <a class="btn btn-sm btn-danger float-right" v-if="fullScreen" v-on:click="setFullScreen(false)">Close Full Screen Mode</a>
            <a class="btn btn-sm btn-primary float-right" v-if="!fullScreen" v-on:click="setFullScreen(true)">Enable Full Screen Mode</a>
            <hr>
          </div>
        </div>
        <div class="row" v-if="showDevOptions">
          <div class="col">
            <p>Load Test Data <button class="btn btn-primary" v-on:click="loadTestData('single')">Single Restaurant</button> <button class="btn btn-primary" v-on:click="loadTestData('multiple')">Multiple Restaurants</button> <button class="btn btn-danger" v-on:click="loadTestData('reset')">Reset Data</button></p>
            <p>Data Store</p>
            <pre>
              {{menussetupv2}}
            </pre>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import $ from 'jquery'
import landing from './components/landing'

export default {
  name: 'OwnerPortalManager',
  components: { landing },
  data () {
    return {
      showDevOptions: false,
      component: landing,
      childComponentProps: {},
      activeNav: 'landing',
      renderer: 1
    }
  },
  methods: {
    toggleDevOptions (show) {
      if (show === true) {
        this.showDevOptions = true
        return
      }
      this.showDevOptions = false
    },
    loadTestData (type) {
      this.importTestData(type)
      this.showDevOptions = false
      this.renderer++
    },
    openModal (modalId) {
      $('#' + modalId).modal('show')
    },
    closeModal (modalId) {
      $('#' + modalId).modal('hide')
    },
    uuid () {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      )
    },
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    ...mapActions('menussetupv2', {
      setFullScreen: 'setFullScreen',
      importTestData: 'importTestData',
      addItemImportDataToStore: 'addItemImportDataToStore',
      updateState: 'updateState',
      deleteStateArrayItem: 'deleteStateArrayItem',
      updateStateArrayItem: 'updateStateArrayItem'
    }),

    landingComponent (event) {
      this.clearAlert()
      this.childComponentProps = { }
      this.component = landing
      this.activeNav = 'landing'
    }
  },
  created () {
    this.setFullScreen(true)
  },
  destroyed () {
    this.setFullScreen(false)
  },
  computed: {
    ...mapState({
      menussetupv2: state => state.menussetupv2,
      fullScreen: state => state.menussetupv2.fullScreen,
      menus: state => state.menussetupv2.menu,
      restaurantsOperated: state => state.menussetupv2.restaurantsOperated,
      holdingCompany: state => state.menussetupv2.holdingCompany
    })
  }
}
</script>
