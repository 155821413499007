export function handleResponse (response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // remove user from local storage to log user out
        localStorage.removeItem('user')
        location.reload(true)
      }

      const error = (data && data.error) || response.message
      return Promise.reject(error)
    }
    if (data.error === true) {
      return Promise.reject(data.message)
    }
    return data
  })
}
