import { propertyService } from '../_services'

const state = {
  typesIdsNamesSelect: {},
  typevaluesIdsNamesSelect: {},
  allvalues: {},
  activevalue: {},
  addvalue: {},
  updatevalue: {},
  alltypes: {},
  activetype: {},
  addtype: {},
  updatetype: {},
  managetypevalues: {}
}

const actions = {
  manageTypeValues ({ commit }, typeId) {
    commit('manageTypeValues', typeId)
  },
  getAllTypes ({ commit }, restGroupId) {
    const stateName = 'alltypes'
    commit('request', { stateName: stateName })

    propertyService.getAllTypes(restGroupId)
      .then(
        types => commit('getAllTypesSuccess', types.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getTypeById ({ commit }, id) {
    const stateName = 'activetype'
    commit('request', { stateName: stateName })

    propertyService.getTypeById(id)
      .then(
        type => commit('getTypeByIdSuccess', type.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  addType ({ dispatch, commit }, restGroupId, name, description) {
    const stateName = 'addtype'
    commit('request', { stateName: stateName })

    propertyService.addType(restGroupId, name, description)
      .then(
        type => {
          commit('addTypeSuccess', type.data)
          dispatch('alert/success', 'Added Property Type.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  updateType ({ dispatch, commit }, propertyTypeId, restGroupId, name, description) {
    const stateName = 'updatetype'
    commit('request', { stateName: stateName })

    propertyService.updateType(propertyTypeId, restGroupId, name, description)
      .then(
        type => {
          commit('updateTypeSuccess', type.data)
          dispatch('alert/success', 'Edited Property Type.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getAllTypeValues ({ commit }, propertyTypeId) {
    const stateName = 'allvalues'
    commit('request', { stateName: stateName })

    propertyService.getAllTypeValues(propertyTypeId)
      .then(
        values => commit('getAllTypeValuesSuccess', values.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getValueById ({ commit }, id) {
    const stateName = 'activevalue'
    commit('request', { stateName: stateName })

    propertyService.getValueById(id)
      .then(
        value => commit('getValueByIdSuccess', value.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  addValue ({ dispatch, commit }, propertyTypeId, property, description) {
    const stateName = 'addvalue'
    commit('request', { stateName: stateName })

    propertyService.addValue(propertyTypeId, property, description)
      .then(
        value => {
          commit('addValueSuccess', value.data)
          dispatch('alert/success', 'Added Property Type Value.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  updateValue ({ dispatch, commit }, propertyValueId, propertyTypeId, property, description) {
    const stateName = 'updatevalue'
    commit('request', { stateName: stateName })

    propertyService.updateValue(propertyValueId, propertyTypeId, property, description)
      .then(
        value => {
          commit('updateValueSuccess', value.data)
          dispatch('alert/success', 'Edited Property Type Value.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  manageTypeValues (state, typeId) {
    state.managetypevalues = typeId
  },
  getAllTypesSuccess (state, types) {
    state.alltypes = { types }
    const selectData = []
    if (types) {
      Array.from(types).forEach(type => {
        selectData.push({ code: type.property_type_id, label: type.name })
      })
    }
    state.typesIdsNamesSelect = selectData
  },
  getTypeByIdSuccess (state, type) {
    state.activetype = type
  },
  addTypeSuccess (state, type) {
    state.addtype = type
  },
  updateTypeSuccess (state, type) {
    state.updatetype = type
  },
  getAllTypeValuesSuccess (state, values) {
    state.allvalues = { values }
    const selectData = []
    if (values) {
      Array.from(values).forEach(value => {
        selectData.push({ code: value.property_value_id, label: value.property })
      })
    }
    state.typevaluesIdsNamesSelect = selectData
  },
  getValueByIdSuccess (state, value) {
    state.activevalue = value
  },
  addValueSuccess (state, value) {
    state.addvalue = value
  },
  updateValueSuccess (state, value) {
    state.updatevalue = value
  }
}

const getters = {
  getTypeIdByName: (state) => (typeName) => {
    if ('types' in state.alltypes) {
      return state.alltypes.types.find(type => type.name === typeName)
    }
  },
  getTypeValueById: (state) => (id) => {
    if ('values' in state.allvalues) {
      return state.allvalues.values.find(value => value.property_value_id === id)
    }
  }
}

export const properties = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
