<template>
  <div class="component-container holding-company">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 middle-divider">
          <h1 class="mb-3">Do you use a holding company for your restaurants?</h1>
          <p>We want to make sure we have things organized properly for you.</p>
        </div>
        <div class="col-md-6 pt-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <button type="button"
                  class="btn btn-lg btn-block px-3 text-left mb-3"
                  :class="{ 'btn-success' : getHoldingCo() === 1, 'btn-primary' : getHoldingCo() !== 1 }"
                  v-on:click="setHoldingCompany(1)">
                  <mdb-icon v-if="getHoldingCo() === 1" far icon="check-square" size='lg' />
                  <mdb-icon v-else far icon="square" size='lg' /> Yes
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button type="button"
                  class="btn btn-lg btn-block px-3 text-left mb-3"
                  :class="{ 'btn-success' : getHoldingCo() === 2, 'btn-primary' : getHoldingCo() !== 2 }"
                  v-on:click="setHoldingCompany(2)">
                  <mdb-icon v-if="getHoldingCo() === 2" far icon="check-square" size='lg' />
                  <mdb-icon v-else far icon="square" size='lg' /> No
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button type="button"
                  class="btn btn-lg btn-block px-3 text-left mb-3"
                  :class="{ 'btn-success' : getHoldingCo() === 3, 'btn-primary' : getHoldingCo() !== 3 }"
                  v-on:click="setHoldingCompany(3)">
                  <mdb-icon v-if="getHoldingCo() === 3" far icon="check-square" size='lg' />
                  <mdb-icon v-else far icon="square" size='lg' /> Prefer not to answer at this time.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 text-left">
          <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.goToPrevComponent()"><mdb-icon icon="arrow-alt-circle-left" size='lg' /> Go Back</button>
        </div>
        <div class="col-md-6 text-right" v-if="getHoldingCo() !== 0">
           <button v-if="getHoldingCo() === 1" type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.holdingCompanyNameComponent()">Next Question <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
           <button v-if="getHoldingCo() !== 1 && $parent.setup.restaurantsOperated === 2" type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.brandsOperatedComponent()">Next Question <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
           <button v-if="getHoldingCo() !== 1 && $parent.setup.restaurantsOperated === 1" type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.restaurantsComponent()">Next Question <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mdbIcon } from 'mdbvue'
export default {
  name: 'holdingCompany',
  components: { mdbIcon },
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {}
  },
  watch: {},
  methods: {
    setHoldingCompany (value) {
      if (value === this.getHoldingCo()) {
        value = 0
      }
      const data = {
        objectName: 'setup',
        property: 'holdingCompany',
        value: value
      }
      this.$parent.updateStateObject(data)
    },
    getHoldingCo () {
      return this.$parent.setup.holdingCompany
    }
  },
  computed: {}
}
</script>
