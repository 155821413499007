import { authHeader, apiUrl, handleResponse } from '../_helpers'

export const searchService = {
  search
}

function search (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'search',
      data: {
        action: 'search',
        searchText: data.searchText,
        searchOption: data.searchOption
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}
