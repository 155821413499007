<template>
  <div class="component-container landing text-center">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
            <h1>Welcome Restaurateur!</h1>
            <p>Help us get to know you by answering a few simple questions about you operation.</p>
            <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.restaurantsOperatedComponent()">I'm ready to get started <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mdbIcon } from 'mdbvue'
export default {
  name: 'landing',
  components: { mdbIcon },
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {}
  },
  watch: {},
  methods: {},
  computed: {}
}
</script>
