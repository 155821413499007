<template>
<div class="property-types-page">
  <h6>Property Types <button v-on:click="addPropertyTypeButton"  class="btn btn-sm btn-primary btn-sm">Add +</button></h6>
  <div class="property-form" v-if="propertytypeform">
      <p>{{formtitle}}</p>
      <div class="container-fluid">
          <div class="row">
              <div class="col-sm-12 mb-3">
                  <label for="name">Name</label>
                  <input type="text" class="form-control" id="name" placeholder="" v-model="name">
                  <div class="input-error" v-if="msg.errors.name">
                      Valid property type name is required.
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12 mb-3">
                  <label for="description">Description</label>
                  <input type="text" class="form-control" id="description" placeholder="" v-model="description">
                  <div class="input-error" v-if="msg.errors.description">
                      Valid property type description is required.
                  </div>
              </div>
          </div>
          <div class="row" v-if="editingpropertytype">
            <div class="col-sm-12 mb-3 deletecontrol text-right">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="deletepropertytype" id="deletepropertytype">
                <label class="form-check-label" for="deletepropertytype">
                  Delete this Property Type?
                </label>
              </div>
            </div>
          </div>
          <div class="row">
              <div class="col-sm-12 mb-3 text-left form-controls">
                  <button v-on:click="savePropertyTypeFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                  <button v-on:click="closePropertyTypeFormButton" class="btn btn-sm btn-danger">Close</button>
              </div>
          </div>
      </div>
  </div>
  <div v-if="'types' in propertytypes" class="property-types-list">
      <table class="table table-striped">
        <thead class="thead-dark">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col">Edit</th>
          </tr>
        </thead>
        <tbody>
              <tr v-for="type in propertytypes.types" :key="type.id">
                <td>{{type.property_type_id}}</td>
                <td>{{type.name}}</td>
                <td>{{type.description}}</td>
                <td>
                  <p><button v-on:click="editPropertyTypeValuesButton(type.property_type_id)" class="btn btn-block btn-sm btn-primary">Values</button></p>
                  <p><button v-on:click="editPropertyTypeButton(type.property_type_id)" class="btn btn-block btn-sm btn-primary">Edit</button></p>
                </td>
              </tr>
        </tbody>
      </table>
  </div>
  <div v-else>
    No property types available.
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'PropertyTypes',
  data () {
    return {
      formtitle: 'Add new Property Type',
      formsavetext: 'Save',
      propertytypeform: false,
      restgroupid: this.$route.params.id,
      name: '',
      description: '',
      editingpropertytype: '',
      deletepropertytype: false,
      msg: {
        errors: {
          name: false,
          description: false
        }
      }
    }
  },
  computed: {
    ...mapState({
      propertytypes: state => state.properties.alltypes,
      activepropertytype: state => state.properties.activetype,
      addpropertytype: state => state.properties.addtype,
      updatepropertytype: state => state.properties.updatetype,
      activepropertytypevalue: state => state.properties.activevalue
    })
  },
  created () {
    this.getPropertyTypes(this.restgroupid)
  },
  watch: {
    activepropertytype: {
      handler (val, oldVal) {
        this.name = val.name
        this.description = val.description
        this.editingpropertytype = val.property_type_id
      }
    },
    name: {
      handler (val, oldVal) {
        this.validatePropertyTypeForm()
      }
    },
    description: {
      handler (val, oldVal) {
        this.validatePropertyTypeForm()
      }
    },
    addpropertytype: {
      handler (val, oldVal) {
        if (val.property_type_id) {
          this.getPropertyTypes(this.restgroupid)
          this.PropertyTypeFormReset()
          this.closePropertyTypeFormButton()
        }
      }
    },
    updatepropertytype: {
      handler (val, oldVal) {
        if (val.property_type_id) {
          this.getPropertyTypes(this.restgroupid)
          this.PropertyTypeFormReset()
          this.closePropertyTypeFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('properties', {
      getPropertyTypes: 'getAllTypes',
      getPropertyType: 'getTypeById',
      updatePropertyType: 'updateType',
      addPropertyType: 'addType',
      managePropertyTypeValues: 'manageTypeValues'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    PropertyTypeFormReset (event) {
      this.formtitle = 'Add new Property Type'
      this.name = ''
      this.description = ''
      this.editingpropertytype = ''
      this.deletepropertytype = false
    },
    closePropertyTypeFormButton (event) {
      this.propertytypeform = false
    },
    addPropertyTypeButton (event) {
      this.clearAlert()
      this.PropertyTypeFormReset()
      if (this.propertytypeform) {
        this.closePropertyTypeFormButton()
        return
      }
      this.propertytypeform = true
    },
    editPropertyTypeButton (id) {
      this.clearAlert()
      this.getPropertyType(id)
      this.propertytypeform = true
      this.formtitle = 'Edit Property Type'
    },
    editPropertyTypeValuesButton (id) {
      this.managePropertyTypeValues(id)
    },
    validatePropertyTypeForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    savePropertyTypeFormButton (event) {
      this.clearAlert()
      if (this.validatePropertyTypeForm() === false) {
        return
      }

      if (this.editingpropertytype) {
        const {
          restgroupid,
          editingpropertytype,
          name,
          description,
          deletepropertytype
        } = this
        this.updatePropertyType({
          restgroupid,
          editingpropertytype,
          name,
          description,
          deletepropertytype
        })
      } else {
        const {
          restgroupid,
          name,
          description
        } = this
        this.addPropertyType({
          restgroupid,
          name,
          description
        })
      }
    }
  }
}
</script>
