<template>
    <div class="login-form">
        <form @submit.prevent="handleSubmit" class="form-signin">

            <h4 class="h4 mb-3 font-weight-normal">Please sign in</h4>

            <label for="inputEmail" class="sr-only">Email address</label>
            <input type="text" v-model="username" name="username" id="inputEmail" class="form-control" placeholder="Email address" autocomplete="username" required autofocus>
            <div v-show="submitted && !username" class="invalid-feedback">Username is required.</div>

            <label for="inputPassword" class="sr-only">Password</label>
            <input type="password" v-model="password" name="password" id="inputPassword" class="form-control" placeholder="Password" autocomplete="current-password" required>
            <div v-show="submitted && !password" class="invalid-feedback">Password is required.</div>

            <button class="btn btn-lg btn-primary btn-block" :disabled="status.loggingIn">Login</button>
        </form>
    </div>
</template>
<style lang="scss" scoped>
  .login-form {
    .logo {
      max-width: 50%;
      height: auto;
    }
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'LoginForm',
  data () {
    return {
      username: '',
      password: '',
      submitted: ''
    }
  },
  computed: {
    ...mapState('account', ['status'])
  },
  created () {
    this.logout()
  },
  methods: {
    ...mapActions('account', ['login', 'logout']),
    handleSubmit (e) {
      this.submitted = true
      const { username, password } = this
      if (username && password) {
        this.login({ username, password })
      }
    }
  }
}
</script>
