<template>
  <div class="component-wrapper">
    <div class="container-fluid mb-sm-5">
      <div class="row">
        <div class="col-12">
          <div class="container mt-sm-5 my-1 bg-light">
              <div class="question ml-sm-5 pt-2">
                  <div class="py-2 h5"><b>How many restaurants do you operate?</b></div>
                  <div class="ml-md-3 ml-sm-3 pl-md-5 pt-sm-0 pt-3" id="optionsContainer">
                    <label class="options">One Restaurant <input type="radio" name="numRestaurants" value="1" :checked="restaurantsOperated === '1'" @change="$parent.updateState({ name: 'restaurantsOperated', value: '1' })"> <span class="checkmark"></span> </label>
                    <label class="options">More than One Restaurant<input type="radio" name="numRestaurants" value="2" :checked="restaurantsOperated === '2'" @change="$parent.updateState({ name: 'restaurantsOperated', value: '2' })"> <span class="checkmark"></span> </label>
                  </div>
                  <div v-if="restaurantsOperated === '1' && restaurants.length">
                    <hr>
                    <p>Please specify information about your restaurant (only a name is required).</p>
                    <div class="form-group">
                      <label for="restName">Name</label>
                      <input type="text" class="form-control" placeholder="Name" v-model="restaurants[0].name">
                    </div>
                    <div class="form-group">
                      <label for="restDescription">Description*</label>
                      <input type="text" class="form-control" placeholder="Description" v-model="restaurants[0].description">
                    </div>
                    <div class="form-group">
                      <label for="restAddress">Address*</label>
                      <input type="text" class="form-control" placeholder="Address" v-model="restaurants[0].address">
                    </div>
                    <div class="form-group">
                      <label for="restAddress2">Address 2*</label>
                      <input type="text" class="form-control" placeholder="Address 2" v-model="restaurants[0].address2">
                    </div>
                    <div class="form-group">
                      <label for="restCity">City*</label>
                      <input type="text" class="form-control" placeholder="City" v-model="restaurants[0].city">
                    </div>
                    <div class="form-group">
                      <label for="restProvince">State or Province*</label>
                      <input type="text" class="form-control" placeholder="State or Province" v-model="restaurants[0].stateProvince">
                    </div>
                    <div class="form-group">
                      <label for="restPostalCode">Postal Code*</label>
                      <input type="text" class="form-control" placeholder="Postal Code" v-model="restaurants[0].postalCode">
                    </div>
                    <div class="form-group">
                      <label for="restCountry">Country*</label>
                      <input type="text" class="form-control" placeholder="Country" v-model="restaurants[0].country">
                    </div>
                    <p class="text-right">* Optional</p>
                  </div>
              </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="restaurantsOperated === '2'">
        <div class="col-12">
          <div class="container mt-sm-5 my-1 bg-light">
              <div class="question ml-sm-5 pt-2">
                  <div class="py-2 h5"><b>How many brands do your restaurants operate under?</b></div>
                  <div class="ml-md-3 ml-sm-3 pl-md-5 pt-sm-0 pt-3" id="optionsContainer">
                    <label class="options">Just One<input type="radio" name="numBrands" value='1' :checked="brandsOperated === '1'" @change="$parent.updateState({ name: 'brandsOperated', value: '1' })"> <span class="checkmark"></span> </label>
                    <label class="options">More than one<input type="radio" name="numBrands" value='2' :checked="brandsOperated === '2'" @change="$parent.updateState({ name: 'brandsOperated', value: '2' })"> <span class="checkmark"></span> </label>
                  </div>
                  <div v-if="brandsOperated === '1'">
                    <hr>
                    <p>Please specify the brand name.</p>
                    <div class="form-row align-items-center">
                      <div class="col-6">
                        <label class="sr-only" for="inlineFormInput">What is the brand name?</label>
                        <input type="text" class="form-control mb-2" placeholder="Brand Name (required)" v-model="brands[0].name">
                      </div>
                      <div class="col-auto">
                        <div class="btn-group" role="group" aria-label="Brand Functions">
                          <button type="submit" class="btn btn-sm btn-primary mb-2" :disabled="brands[0].name === ''" v-on:click="addRestaurants(brands[0].id)">Add Restaurants</button>
                        </div>
                      </div>
                      <div class="col-12" v-if="brandRestaurants(brands[0].id).length">
                        <hr class="mx-5">
                        <p class="px-5"><b>Restaurants</b></p>
                        <div class="form-row align-items-center px-5" v-for="(restaurant, index) of brandRestaurants(brands[0].id).reverse" v-bind:key="index">
                          <div class="col-6">
                            <label class="sr-only" for="inlineFormInput">Restaurant Name</label>
                            {{restaurant.name}}
                          </div>
                          <div class="col-auto">
                            <div class="btn-group" role="group" aria-label="Restaurant Functions">
                              <button type="submit" class="btn btn-sm btn-primary mb-2" v-on:click="editRestaurant(restaurant)">Edit Restaurant</button>
                              <button type="submit" class="btn btn-sm btn-danger mb-2" v-on:click="deleteRestaurant(restaurant.id)">Delete</button>
                            </div>
                          </div>
                        </div>
                        <hr>
                      </div>
                    </div>
                  </div>
                  <div v-if="brandsOperated === '2'">
                    <hr>
                    <p>Please specify the brand names you operate.</p>
                    <div class="form-row">
                      <div class="col-6">
                        <label class="sr-only" for="inlineFormInput">Name</label>
                        <input type="text" class="form-control mb-2" placeholder="Brand Name (required)" v-model="newBrand">
                      </div>
                      <div class="col-auto">
                        <button class="btn btn-success" v-on:click="addNewBrand()" :disabled="newBrand === ''">Add New Brand</button>
                      </div>
                    </div>
                    <hr>
                    <p v-if="brands.length">
                      Manage each of your Brands to add restaurants or edit the brand name.
                    </p>
                    <div v-for="(brand, brandIndex) of brands" v-bind:key="brandIndex">
                      <div class="form-row align-items-center py-1">
                        <div class="col-auto">
                          <a v-if="manageBrandId !== brand.id" class="btn-sm btn-success" v-on:click="manageBrand(brand.id)">Manage</a>
                          <a v-if="manageBrandId === brand.id" class="btn-sm btn-danger" v-on:click="closeManageBrand()">Close</a>
                        </div>
                        <div class="col-auto">
                          <a v-on:click="manageBrand(brand.id)">{{brand.name}}</a>
                        </div>
                      </div>
                      <div class="form-row align-items-center" v-show="manageBrandId === brand.id">
                        <div class="col-6">
                          <label for="inlineFormInput">Brand Name</label>
                          <input type="text" class="form-control mb-2" placeholder="Brand Name (required)" v-model="brand.name">
                        </div>
                        <div class="col-auto">
                          <div class="btn-group" role="group" aria-label="Brand Functions">
                            <button type="submit" class="btn btn-sm btn-primary mb-2" :disabled="brand.name === ''" v-on:click="addRestaurants(brand.id)">Add Restaurants</button>
                            <button type="submit" class="btn btn-sm btn-danger mb-2" v-on:click="deleteBrand(brand.id)">Delete</button>
                          </div>
                        </div>
                        <div class="col-12" v-if="brandRestaurants(brand.id).length">
                          <hr class="mx-5">
                          <p class="px-5"><b>Restaurants</b></p>
                          <div class="form-row align-items-center px-5" v-for="(restaurant, restIndex) of brandRestaurants(brand.id)" v-bind:key="restIndex">
                            <div class="col-6">
                              <label class="sr-only" for="inlineFormInput">Restaurant Name</label>
                              {{restaurant.name}}
                            </div>
                            <div class="col-auto">
                              <div class="btn-group" role="group" aria-label="Restaurant Functions">
                                <button type="submit" class="btn btn-sm btn-primary mb-2" v-on:click="editRestaurant(restaurant)">Edit Restaurant</button>
                                <button type="submit" class="btn btn-sm btn-danger mb-2" v-on:click="deleteRestaurant(restaurant.id)">Delete</button>
                              </div>
                            </div>
                          </div>
                          <hr>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="container mt-sm-5 my-1 bg-light">
              <div class="question ml-sm-5 pt-2">
                  <div class="py-2 h5"><b>Do you use a holding company to own your restaurant(s)?</b></div>
                  <div class="ml-md-3 ml-sm-3 pl-md-5 pt-sm-0 pt-3" id="optionsContainer">
                    <label class="options">Yes<input type="radio" name="holdingCompany" value='1' :checked="holdingCompany === '1'" @change="$parent.updateState({ name: 'holdingCompany', value: '1' })"> <span class="checkmark"></span> </label>
                    <label class="options">No<input type="radio" name="holdingCompany" value='0' :checked="holdingCompany === '0'" @change="$parent.updateState({ name: 'holdingCompany', value: '0' })"> <span class="checkmark"></span> </label>
                  </div>
                  <div v-if="holdingCompany === '1'">
                    <hr>
                    <div class="form-group">
                      <label for="holdingCompanyName">What is the name of the holding company?</label>
                      <input type="text" class="form-control" id="holdingCompanyName" placeholder="Company Name" v-model="operatorName">
                    </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
           <a class="btn btn-primary mt-sm-5" :class="{ 'disabled' : holdingCompany === '' || restaurantsOperated === '' }" v-on:click="$parent.menuStructureComponent('')">Next: Menu Structure</a>
        </div>
      </div>
    </div>
    <div class="modal fade" id="addRestaurant" tabindex="-1" aria-labelledby="createRestaurantLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add {{activeRestaurant.brand}} Restaurant</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Please specify information about your restaurant (only a name is required).</p>
            <div class="form-group">
              <label for="restName">Name</label>
              <input type="text" class="form-control" placeholder="Name" v-model="activeRestaurant.name">
            </div>
            <div class="form-group">
              <label for="restDescription">Description*</label>
              <input type="text" class="form-control" placeholder="Description" v-model="activeRestaurant.description">
            </div>
            <div class="form-group">
              <label for="restAddress">Address*</label>
              <input type="text" class="form-control" placeholder="Address" v-model="activeRestaurant.address">
            </div>
            <div class="form-group">
              <label for="restAddress2">Address 2*</label>
              <input type="text" class="form-control" placeholder="Address 2" v-model="activeRestaurant.address2">
            </div>
            <div class="form-group">
              <label for="restCity">City*</label>
              <input type="text" class="form-control" placeholder="City" v-model="activeRestaurant.city">
            </div>
            <div class="form-group">
              <label for="restProvince">State or Province*</label>
              <input type="text" class="form-control" placeholder="State or Province" v-model="activeRestaurant.stateProvince">
            </div>
            <div class="form-group">
              <label for="restPostalCode">Postal Code*</label>
              <input type="text" class="form-control" placeholder="Postal Code" v-model="activeRestaurant.postalCode">
            </div>
            <div class="form-group">
              <label for="restCountry">Country*</label>
              <input type="text" class="form-control" placeholder="Country" v-model="activeRestaurant.country">
            </div>
            <p class="text-right">* Optional</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$parent.closeModal('addRestaurant')">Close</button>
            <button type="button" class="btn btn-primary" v-on:click="saveRestaurant()" :disabled="activeRestaurant.name === ''" >Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'restaurantAdmin',
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
      operatorName: '',
      setOperator: true,
      newBrand: '',
      manageBrandId: ''
    }
  },
  watch: {
    brandsOperated: {
      handler (val, oldVal) {
        this.$parent.updateState({ name: 'brand', value: [] })
        if (val === '1') {
          this.addBrand()
        }
      }
    },
    restaurantsOperated: {
      handler (val, oldVal) {
        this.$parent.updateState({ name: 'restaurant', value: [] })
        this.$parent.updateState({ name: 'brandsOperated', value: '' })
        this.$parent.updateState({ name: 'brand', value: [] })
        if (val === '1') {
          const brand = {
            id: this.$parent.uuid(),
            name: 'singleRestaurant',
            description: ''
          }
          this.brands.push(brand)
          this.restDataInit(brand.id)
          this.saveRestaurant()
        }
      }
    },
    holdingCompany: {
      handler (val, oldVal) {
        this.operatorName = ''
      }
    },
    operatorName: {
      handler (val, oldVal) {
        this.setOperatorName(val)
      }
    }
  },
  methods: {
    manageBrand (brandId) {
      this.manageBrandId = brandId
      this.$parent.restaurantBrandComponent(brandId)
    },
    closeManageBrand (brandId) {
      this.manageBrandId = ''
    },
    brandRestaurants (brandId) {
      return this.restaurants.filter(function (restaurant) {
        if (restaurant.brandId === brandId) {
          return restaurant
        }
      })
    },
    restDataInit (brandId) {
      const restInit = {
        id: this.$parent.uuid(),
        brandId: brandId,
        name: '',
        description: '',
        address: '',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      }
      this.$parent.updateState({ name: 'selectedRestaurantId', value: restInit.id })
      this.$parent.updateState({ name: 'activeRestaurant', value: restInit })
    },
    addNewBrand () {
      const brand = {
        id: this.$parent.uuid(),
        name: this.newBrand,
        description: ''
      }
      this.brands.unshift(brand)
      this.newBrand = ''
    },
    addBrand () {
      const brand = {
        id: this.$parent.uuid(),
        name: '',
        description: ''
      }
      this.brands.unshift(brand)
    },
    deleteBrand (brandId) {
      const data = {
        name: 'brand',
        keyName: 'id',
        keyValue: brandId
      }
      this.$parent.deleteStateArrayItem(data)
      const restData = {
        name: 'restaurant',
        keyName: 'brandId',
        keyValue: brandId
      }
      this.$parent.deleteStateArrayItem(restData)
    },
    deleteRestaurant (restId) {
      const data = {
        name: 'restaurant',
        keyName: 'id',
        keyValue: restId
      }
      this.$parent.deleteStateArrayItem(data)
    },
    addRestaurants (brandName) {
      this.restDataInit(brandName)
      this.$parent.openModal('addRestaurant')
    },
    editRestaurant (restaurant) {
      this.$parent.updateState({ name: 'activeRestaurant', value: restaurant })
      this.$parent.updateState({ name: 'editingRestaurant', value: true })
      this.$parent.openModal('addRestaurant')
    },
    saveRestaurant () {
      if (this.editingRestaurant) {
        this.$parent.updateState({ name: 'editingRestaurant', value: false })
        this.$parent.closeModal('addRestaurant')
        return
      }
      const restaurant = JSON.parse(JSON.stringify(this.activeRestaurant))
      this.restaurants.unshift(restaurant)
      this.$parent.closeModal('addRestaurant')
    },
    setOperatorName (operatorName) {
      const data = JSON.parse(JSON.stringify(this.operators))
      var id = this.$parent.uuid()
      if (data.length) {
        const name = data[0].name
        id = data[0].id
        if (this.setOperator) {
          this.setOperator = false
          return name
        }
      }
      const operator = {
        id: id,
        name: operatorName,
        description: ''
      }
      this.$parent.updateState({ name: 'operator', value: [operator] })
      return operatorName
    }
  },
  created () {
    this.operatorName = this.setOperatorName('')
  },
  computed: {
    ...mapState({
      restaurants: state => state.menussetupv2.restaurant,
      restaurantsOperated: state => state.menussetupv2.restaurantsOperated,
      holdingCompany: state => state.menussetupv2.holdingCompany,
      operators: state => state.menussetupv2.operator,
      brandsOperated: state => state.menussetupv2.brandsOperated,
      brands: state => state.menussetupv2.brand,
      activeRestaurant: state => state.menussetupv2.activeRestaurant,
      editingRestaurant: state => state.menussetupv2.editingRestaurant
    })
  }
}
</script>
