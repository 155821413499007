export function apiUrl () {
  if (process.env.NODE_ENV === 'production') {
    return '/api-request'
  } else {
    return 'http://localhost:5000/api-request'
  }
}

export function apiUrlLogin () {
  if (process.env.NODE_ENV === 'production') {
    return '/api/login'
  } else {
    return 'http://localhost:5000/api/login'
  }
}

export function apiClientSiteUrl (route) {
  if (process.env.NODE_ENV === 'production') {
    return '/api' + route
  } else {
    return 'http://localhost:5000/api' + route
  }
}
