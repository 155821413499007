import { brandsService } from '../_services'

const state = {
  all: {},
  activebrand: {},
  editbrand: {},
  addbrand: {},
  updatebrand: {},
  idsNamesSelect: []
}

const actions = {
  getAll ({ dispatch, commit }) {
    const stateName = 'all'
    commit('request', { stateName: stateName })

    brandsService.getAll()
      .then(
        brands => {
          commit('getAllSuccess', brands.data)
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getById ({ dispatch, commit }, id) {
    const stateName = 'activebrand'
    commit('request', { stateName: stateName })

    brandsService.getById(id)
      .then(
        brand => commit('getByIdSuccess', brand.data),
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  add ({ dispatch, commit }, name, description) {
    const stateName = 'addbrand'
    commit('request', { stateName: stateName })

    brandsService.add(name, description)
      .then(
        brand => {
          commit('addSuccess', brand.data)
          dispatch('alert/success', 'Added brand', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  update ({ dispatch, commit }, brandId, name, description) {
    const stateName = 'updatebrand'
    commit('request', { stateName: stateName })

    brandsService.update(brandId, name, description)
      .then(
        brand => {
          commit('updateSuccess', brand.data)
          dispatch('alert/success', 'Edited brand', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  getAllSuccess (state, brands) {
    state.all = { brands }
    const selectData = []
    Array.from(brands).forEach(brand => {
      selectData.push({ code: brand.brand_id, label: brand.name })
    })
    state.idsNamesSelect = selectData
  },
  getByIdSuccess (state, brand) {
    state.activebrand = brand
  },
  addSuccess (state, brand) {
    state.addbrand = brand
  },
  updateSuccess (state, brand) {
    state.updatebrand = brand
  }
}

export const brands = {
  namespaced: true,
  state,
  actions,
  mutations
}
