import Vue from 'vue'
import 'bootstrap'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@/styles/base.scss'
import 'vue-select/src/scss/vue-select.scss'
import 'jquery'
import 'popper.js'

import { store } from './_store'
import { router } from './_helpers'
import App from './app/App'
import vSelect from 'vue-select'

import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
Vue.component('v-select', vSelect)

Vue.config.productionTip = false
Vue.use(BootstrapVueIcons)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
