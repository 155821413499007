<template>
    <div class="page menusections-page">
      <h4>Menu Sections
        <button v-on:click="addSectionButton" class="btn btn-sm btn-primary btn-sm">Add +</button>
      </h4>
      <div class="section-form" v-if="sectionform">
          <p>{{formtitle}}</p>
          <div class="container-fluid">
              <div class="row">
                  <div class="col-sm-12 mb-3">
                      <label for="name">Name</label>
                      <input type="text" class="form-control" id="name" placeholder="" v-model="name">
                      <div class="input-error" v-if="msg.errors.name">
                          Valid name is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 mb-3">
                      <label for="description">Description</label>
                      <input type="text" class="form-control" id="description" placeholder="" v-model="description">
                      <div class="input-error" v-if="msg.errors.description">
                          Valid description is required.
                      </div>
                  </div>
              </div>
              <div class="row" style="display: none;">
                  <div class="col-sm-12 mb-3">
                      <label for="displayOrder">Display Order</label>
                      <input type="text" class="form-control" id="displayOrder" placeholder="" v-model="displayOrder">
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="isActive" placeholder="" v-model="isActive" :checked="isActive == 1">
                      <label class="form-check-label" for="isActive">
                        Is Active?
                      </label>
                    </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="isTemporary" placeholder="" v-model="isTemporary" :checked="isTemporary == 1">
                      <label class="form-check-label" for="isTemporary">
                        Is Temporary?
                      </label>
                    </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="hideName" placeholder="" v-model="hideName" :checked="hideName == 1">
                      <label class="form-check-label" for="hideName">
                        Hide Name?
                      </label>
                    </div>
                  </div>
              </div>
              <div class="row" v-if="editingsection">
                <div class="col-sm-12 mb-3 deletecontrol text-right">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="deleteMenuSection" id="deleteMenuSection">
                    <label class="form-check-label" for="deleteMenuSection">
                      Delete this section?
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 mb-3 text-left form-controls">
                      <button v-on:click="saveSectionFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                      <button v-on:click="closeSectionFormButton" class="btn btn-sm btn-danger">Close</button>
                  </div>
              </div>
          </div>
      </div>
      <div v-if="'data' in allsections" class="section-list">
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Active</th>
                <th scope="col">Temporary</th>
                <th scope="col">Hide Name</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="section in allsections.data" :key="section.menu_section_id">
                  <td class="sortable">{{section.name}}</td>
                  <td class="sortable">{{section.description}}</td>
                  <td class="sortable">{{section.is_active}}</td>
                  <td class="sortable">{{section.is_temporary}}</td>
                  <td class="sortable">{{section.hide_name}}</td>
                  <td>
                    <button v-on:click="editSectionButton(section.menu_section_id)" class="btn btn-sm btn-primary">Edit</button>
                  </td>
                </tr>
            </tbody>
          </table>
      </div>
    </div>
</template>
<style lang="scss" scoped>
  td {
    &.sortable {
      cursor: move;
    }
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'MenuSections',
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
      sectionform: false,
      formtitle: 'Add New Section',
      formsavetext: 'Save',
      sectionId: '',
      name: '',
      description: '',
      displayOrder: '',
      isActive: '',
      isTemporary: '',
      hideName: '',
      deleteMenuSection: false,
      msg: {
        errors: {
          name: false,
          description: false
        }
      }
    }
  },
  computed: {
    ...mapState({
      allsections: state => state.menusections.all,
      activemenusection: state => state.menusections.activemenusection,
      addmenusection: state => state.menusections.addmenusection,
      updatemenusection: state => state.menusections.updatemenusection,
      idsNamesSelect: state => state.menusections.idsNamesSelect
    })
  },
  created () {
    const { restGroupId, restId, menuId } = this.componentProps
    this.getMenuSections({ restGroupId, restId, menuId })
  },
  watch: {
    activemenusection: {
      handler (val, oldVal) {
        this.sectionId = val.menu_section_id
        this.name = val.name
        this.description = val.description
        this.displayOrder = val.display_order
        this.isActive = val.is_active
        this.isTemporary = val.is_temporary
        this.hideName = val.hide_name
      }
    },
    name: {
      handler (val, oldVal) {
        this.validateSectionForm()
      }
    },
    description: {
      handler (val, oldVal) {
        this.validateSectionForm()
      }
    },
    addmenusection: {
      handler (val, oldVal) {
        if (val.menu_section_id) {
          const { restGroupId, restId, menuId } = this.componentProps
          this.getMenuSections({ restGroupId, restId, menuId })
          this.closeSectionFormButton()
        }
      }
    },
    updatemenusection: {
      handler (val, oldVal) {
        if (val.menu_section_id) {
          const { restGroupId, restId, menuId } = this.componentProps
          this.getMenuSections({ restGroupId, restId, menuId })
          this.closeSectionFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('menusections', {
      getMenuSections: 'getAll',
      getMenuSection: 'getById',
      addMenuSection: 'add',
      updateMenuSection: 'update'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    sectionFormReset (event) {
      this.formtitle = 'Add new Section'
      this.sectionId = ''
      this.name = ''
      this.description = ''
      this.displayOrder = ''
      this.isActive = ''
      this.isTemporary = ''
      this.hideName = ''
      this.editingsection = ''
      this.deleteMenuSection = false
    },
    closeSectionFormButton (event) {
      this.sectionform = false
      this.sectionFormReset()
      this.scrollToTop()
    },
    addSectionButton () {
      this.clearAlert()
      this.sectionFormReset()
      if (this.sectionform) {
        this.closeSectionFormButton()
        return
      }
      this.isActive = 1
      this.sectionform = true
    },
    editSectionButton (id) {
      this.getMenuSection(id)
      this.editingsection = id
      this.sectionform = true
      this.formtitle = 'Edit Section'
      this.scrollToTop()
    },
    saveSectionFormButton (event) {
      this.clearAlert()
      if (this.validateSectionForm() === false) {
        return
      }
      this.scrollToTop()
      const { menuId } = this.componentProps
      if (this.editingsection) {
        const {
          sectionId,
          name,
          description,
          displayOrder,
          isActive,
          isTemporary,
          hideName,
          deleteMenuSection
        } = this
        this.updateMenuSection({
          menuId,
          sectionId,
          name,
          description,
          displayOrder,
          isActive,
          isTemporary,
          hideName,
          deleteMenuSection
        })
      } else {
        const {
          name,
          description,
          displayOrder,
          isActive,
          isTemporary,
          hideName
        } = this
        this.addMenuSection({
          menuId,
          name,
          description,
          displayOrder,
          isActive,
          isTemporary,
          hideName
        })
      }
    },
    validateSectionForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    scrollToTop (elementId = null) {
      var top = 0
      if (elementId) {
        var element = document.getElementById(elementId)
        top = element.offsetTop
      }
      window.scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
