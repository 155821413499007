import { menuitemsService } from '../_services'

const state = {
  all: {},
  activemenuitem: {},
  addmenuitem: {},
  updatemenuitem: {},
  idsNamesSelect: {},
  unitsOfMeasure: {},
  unitsOfMeasureIdNamesSelect: [],
  itemUnits: {},
  pricingModels: {},
  pricingModelsIdNamesSelect: [],
  pricingModelDefault: {},
  restSalesTaxRates: {},
  restSalesTaxRateDefault: {},
  restSalesTaxRateIdNamesSelect: []
}

const actions = {
  getAll ({ commit }, restGroupId, restId, menuId) {
    const stateName = 'all'
    commit('request', { stateName: stateName })

    menuitemsService.getAll(restGroupId, restId, menuId)
      .then(
        menuitems => commit('getAllSuccess', menuitems),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getUnitsOfMeasure ({ commit }, restGroupId) {
    const stateName = 'unitsOfMeasure'
    commit('request', { stateName: stateName })

    menuitemsService.getUnitsOfMeasure(restGroupId)
      .then(
        unitsofmeasure => commit('getUnitsOfMeasureSuccess', unitsofmeasure.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getById ({ commit }, data) {
    const stateName = 'activemenuitem'
    commit('request', { stateName: stateName })

    menuitemsService.getById(data)
      .then(
        item => commit('getByIdSuccess', item.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  add ({ dispatch, commit }, restGroupId, menuId, itemTypeId, itemId, name, association, description, ticketName, chit, baseQty, price, priceDescription, pricingModelId,
    pricingModelName, unitOfMeasureId, unitOfMeasureName, restSalesTaxId, salesTaxId,
    salesTaxRate, calories, isActive, isMeasured) {
    const stateName = 'addmenuitem'
    commit('request', { stateName: stateName })

    menuitemsService.add(restGroupId, menuId, itemTypeId, itemId, name, association, description, ticketName, chit, baseQty, price, priceDescription, pricingModelId,
      pricingModelName, unitOfMeasureId, unitOfMeasureName, restSalesTaxId, salesTaxId,
      salesTaxRate, calories, isActive, isMeasured)
      .then(
        menuitem => {
          commit('addSuccess', menuitem.data)
          dispatch('alert/success', 'Added Menu Item.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  update ({ dispatch, commit }, editingitem, restGroupId, menuItemId, menuId, itemId, name, association, description, ticketName, chit, baseQty, price,
    priceDescription, pricingModelId, pricingModelName, itemUnitId, unitOfMeasureId, unitOfMeasureName, restSalesTaxId, calories,
    isActive, isMeasured, deleteMenuItem) {
    const stateName = 'updatemenuitem'
    commit('request', { stateName: stateName })

    menuitemsService.update(editingitem, restGroupId, menuItemId, menuId, itemId, name, association, description, ticketName, chit, baseQty, price,
      priceDescription, pricingModelId, pricingModelName, itemUnitId, unitOfMeasureId, unitOfMeasureName, restSalesTaxId, calories,
      isActive, isMeasured, deleteMenuItem)
      .then(
        menuitem => {
          commit('updateSuccess', menuitem.data)
          dispatch('alert/success', 'Edited Menu Item.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  getAllSuccess (state, menuitems) {
    /* Get all Menu Items */
    const selectData = []
    if ('data' in menuitems) {
      state.all = menuitems.data
      Array.from(menuitems.data).forEach(item => {
        selectData.push({ code: item.menu_item_id, label: item.item_name })
      })
    }
    state.idsNamesSelect = selectData

    var code = ''
    var label = ''

    /* Available Pricing Models */
    const pricingModelSelectData = []
    if ('pricingModels' in menuitems) {
      state.pricingModels = menuitems.pricingModels

      if ('default' in menuitems.pricingModels) {
        state.pricingModelDefault = menuitems.pricingModels.default
      }
      const pmKeys = Object.keys(menuitems.pricingModels)
      for (const key of pmKeys) {
        code = ''
        label = ''
        if (key !== 'default') {
          code = menuitems.pricingModels[key].property_value_id
          label = menuitems.pricingModels[key].property + ' (' + menuitems.pricingModels[key].description + ')'
          pricingModelSelectData.push({ code: code, label: label })
        }
      }
    }
    state.pricingModelsIdNamesSelect = pricingModelSelectData

    /* Available Rest Sales Tax Rates */
    const restSalesTaxRateSelectData = []
    if ('restSalesTaxRates' in menuitems) {
      state.restSalesTaxRates = menuitems.restSalesTaxRates
      if ('default' in menuitems.restSalesTaxRates) {
        state.restSalesTaxRateDefault = menuitems.restSalesTaxRates.default
      }
      const rstKeys = Object.keys(menuitems.restSalesTaxRates)
      for (const key of rstKeys) {
        code = ''
        label = ''
        if (key !== 'default') {
          code = menuitems.restSalesTaxRates[key].restaurant_sales_tax_id
          label = menuitems.restSalesTaxRates[key].tax_rate + ' (' + menuitems.restSalesTaxRates[key].description + ')'
          restSalesTaxRateSelectData.push({ code: code, label: label })
        }
      }
    }
    state.restSalesTaxRateIdNamesSelect = restSalesTaxRateSelectData

    /* Available itemUnits of measure */
    if ('itemUnits' in menuitems) {
      state.itemUnits = menuitems.itemUnits
    }
  },
  getUnitsOfMeasureSuccess (state, unitsofmeasure) {
    state.unitsOfMeasure = { unitsofmeasure }
    const selectData = []
    if (unitsofmeasure) {
      Array.from(unitsofmeasure).forEach(uom => {
        selectData.push({ code: uom.unit_of_measure_id, label: uom.name + ' (' + uom.description + ')' })
      })
    }
    state.unitsOfMeasureIdNamesSelect = selectData
  },
  getByIdSuccess (state, menuitem) {
    state.activemenuitem = menuitem
  },
  addSuccess (state, menuitem) {
    state.addmenuitem = menuitem
  },
  updateSuccess (state, menuitem) {
    state.updatemenuitem = menuitem
  }
}

export const menuitems = {
  namespaced: true,
  state,
  actions,
  mutations
}
