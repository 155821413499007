<template>
    <div class="page unitsofmeasure-page">
      <h6 class="page-title">Units of Measure <button v-on:click="addUnitOfMeasureButton" class="btn btn-sm btn-primary">Add +</button></h6>
      <div class="unitofmeasure-form" v-if="unitofmeasureform">
          <p>{{formtitle}}</p>
          <div class="container-fluid">
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <p>Unit Type</p>
                      <v-select placeholder="Search..." :options="unittypes" @input="setUnitType" v-model="unitTypeName"></v-select>
                      <div class="input-error" v-if="msg.errors.unitTypeId">
                          Valid Unit Type is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="name">Name</label>
                      <input type="text" class="form-control" id="name" placeholder="" v-model="name">
                      <div class="input-error" v-if="msg.errors.name">
                          Valid name is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="description">Description</label>
                      <input type="text" class="form-control" id="description" placeholder="" v-model="description">
                      <div class="input-error" v-if="msg.errors.description">
                          Valid description is required.
                      </div>
                  </div>
              </div>
              <div class="row" v-if="editingunitofmeasure">
                <div class="col-sm-12 col-md-6 mb-3 deletecontrol text-right">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="deleteUnitOfMeasure" id="deleteUnitOfMeasure">
                    <label class="form-check-label" for="deleteUnitOfMeasure">
                      Delete this Unit of Measure?
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3 text-left form-controls">
                      <button v-on:click="saveUnitOfMeasureFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                      <button v-on:click="closeUnitOfMeasureFormButton" class="btn btn-sm btn-danger">Close</button>
                  </div>
              </div>
          </div>
      </div>
      <div v-if="'unitsofmeasure' in groupunitsofmeasure" class="unitsofmeasure-list">
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Unit Type</th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>
                  <tr v-for="unitofmeasure in groupunitsofmeasure.unitsofmeasure" :key="unitofmeasure.id">
                    <td>{{unitofmeasure.unit_of_measure_id}}</td>
                    <td>{{unitofmeasure.unit_type}}</td>
                    <td>{{unitofmeasure.name}}</td>
                    <td>{{unitofmeasure.description}}</td>
                    <td>
                      <button v-on:click="editUnitOfMeasureButton(unitofmeasure.unit_of_measure_id)" class="btn btn-sm btn-primary">Edit</button>
                    </td>
                  </tr>
            </tbody>
          </table>
      </div>
      <div v-else>
        No units of measure available.
      </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'UnitsOfMeasure',
  data () {
    return {
      formtitle: 'Add new Unit Of Measure',
      formsavetext: 'Save',
      unitofmeasureform: false,
      restGroupId: this.$route.params.id,
      name: '',
      description: '',
      unitTypeId: '',
      unitTypeName: '',
      editingunitofmeasure: '',
      deleteUnitOfMeasure: false,
      msg: {
        errors: {
          name: false,
          description: false,
          unitTypeId: false
        }
      }
    }
  },
  computed: {
    ...mapState({
      groupunitsofmeasure: state => state.unitsofmeasure.all,
      activeunitofmeasure: state => state.unitsofmeasure.activeunitofmeasure,
      addunitofmeasure: state => state.unitsofmeasure.addunitofmeasure,
      updateunitofmeasure: state => state.unitsofmeasure.updateunitofmeasure,
      unittypes: state => state.unitsofmeasure.unittypes
    })
  },
  created () {
    this.getUnitsOfMeasure(this.restGroupId)
    this.getUnitTypes(this.restGroupId)
  },
  watch: {
    activeunitofmeasure: {
      handler (val, oldVal) {
        this.name = val.name
        this.description = val.description
        this.unitTypeId = val.unit_type_id
        this.unitTypeName = val.unit_type
        this.editingunitofmeasure = val.unit_of_measure_id
      }
    },
    name: {
      handler (val, oldVal) {
        this.validateUnitOfMeasureForm()
      }
    },
    description: {
      handler (val, oldVal) {
        this.validateUnitOfMeasureForm()
      }
    },
    unitTypeId: {
      handler (val, oldVal) {
        this.validateUnitOfMeasureForm()
      }
    },
    addunitofmeasure: {
      handler (val, oldVal) {
        if (val.unit_of_measure_id) {
          this.getUnitsOfMeasure(this.restGroupId)
          this.UnitOfMeasureFormReset()
          this.closeUnitOfMeasureFormButton()
        }
      }
    },
    updateunitofmeasure: {
      handler (val, oldVal) {
        if (val.unit_of_measure_id) {
          this.getUnitsOfMeasure(this.restGroupId)
          this.UnitOfMeasureFormReset()
          this.closeUnitOfMeasureFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('unitsofmeasure', {
      getUnitsOfMeasure: 'getAll',
      getUnitOfMeasure: 'getById',
      updateUnitOfMeasure: 'update',
      addUnitOfMeasure: 'add',
      getUnitTypes: 'getTypes'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    setUnitType (value) {
      if (value !== null) {
        this.unitTypeId = value.code
        this.unitTypeName = value.label
        return
      }
      this.unitTypeId = ''
      this.unitTypeName = ''
    },
    UnitOfMeasureFormReset (event) {
      this.formtitle = 'Add new Unit of Measure'
      this.name = ''
      this.description = ''
      this.unitTypeId = ''
      this.unitTypeName = ''
      this.editingunitofmeasure = ''
      this.deleteUnitOfMeasure = false
    },
    closeUnitOfMeasureFormButton (event) {
      this.unitofmeasureform = false
    },
    addUnitOfMeasureButton (event) {
      this.clearAlert()
      this.UnitOfMeasureFormReset()
      if (this.unitofmeasureform && this.editUnitOfMeasureData === '') {
        this.closeUnitOfMeasureFormButton()
        return
      }
      this.editUnitOfMeasureData = ''
      this.unitofmeasureform = true
    },
    editUnitOfMeasureButton (id) {
      this.clearAlert()
      this.getUnitOfMeasure(id)
      this.editingunitofmeasure = id
      this.unitofmeasureform = true
      this.formtitle = 'Edit Unit of Measure'
    },
    validateUnitOfMeasureForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    saveUnitOfMeasureFormButton (event) {
      this.clearAlert()
      if (this.validateUnitOfMeasureForm() === false) {
        return
      }

      if (this.editingunitofmeasure) {
        const {
          editingunitofmeasure,
          restGroupId,
          unitTypeId,
          name,
          description,
          deleteUnitOfMeasure
        } = this
        this.updateUnitOfMeasure({
          editingunitofmeasure,
          restGroupId,
          unitTypeId,
          name,
          description,
          deleteUnitOfMeasure
        })
      } else {
        const {
          restGroupId,
          unitTypeId,
          name,
          description
        } = this
        this.addUnitOfMeasure({
          restGroupId,
          unitTypeId,
          name,
          description
        })
      }
    }
  }
}
</script>
