import { salesTaxRatesService } from '../_services'

const state = {
  all: {},
  idsNamesSelect: [],
  activesalestaxrate: {},
  addsalestaxrate: {},
  updatesalestaxrate: {}
}

const actions = {
  getAll ({ commit }, restGroupId) {
    const stateName = 'all'
    commit('request', { stateName: stateName })

    salesTaxRatesService.getAll(restGroupId)
      .then(
        salestaxrates => commit('getAllSuccess', salestaxrates.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getById ({ commit }, id) {
    const stateName = 'activesalestaxrate'
    commit('request', { stateName: stateName })

    salesTaxRatesService.getById(id)
      .then(
        salestaxrate => commit('getByIdSuccess', salestaxrate.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  add ({ dispatch, commit }, restGroupId, name, description, taxRate, city, subregion, province, postalCode, country) {
    const stateName = 'addsalestaxrate'
    commit('request', { stateName: stateName })

    salesTaxRatesService.add(restGroupId, name, description, taxRate, city, subregion, province, postalCode, country)
      .then(
        salestaxrate => {
          commit('addSuccess', salestaxrate.data)
          dispatch('alert/success', 'Added sales tax rate.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  update ({ dispatch, commit }, salesTaxRateId, restGroupId, name, description, taxRate, city, subregion, province, postalCode, country) {
    const stateName = 'updatesalestaxrate'
    commit('request', { stateName: stateName })

    salesTaxRatesService.update(salesTaxRateId, restGroupId, name, description, taxRate, city, subregion, province, postalCode, country)
      .then(
        salestaxrate => {
          commit('updateSuccess', salestaxrate.data)
          dispatch('alert/success', 'Edited sales tax rate.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  getAllSuccess (state, salestaxrates) {
    state.all = { salestaxrates }
    const selectData = []
    if (salestaxrates) {
      Array.from(salestaxrates).forEach(salestaxrate => {
        selectData.push({ code: salestaxrate.sales_tax_rate_id, label: salestaxrate.tax_rate + ' (' + salestaxrate.name + ')' })
      })
    }
    state.idsNamesSelect = selectData
  },
  getByIdSuccess (state, salestaxrate) {
    state.activesalestaxrate = salestaxrate
  },
  addSuccess (state, salestaxrate) {
    state.addsalestaxrate = salestaxrate
  },
  updateSuccess (state, salestaxrate) {
    state.updatesalestaxrate = salestaxrate
  }
}

export const salestaxrates = {
  namespaced: true,
  state,
  actions,
  mutations
}
