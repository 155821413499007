<template>
    <div class="page menu-addendums-page">
      <h4>
        Menu Addendums
        <button v-on:click="addMenuAddendumButton" class="btn btn-sm btn-primary btn-sm">Add +</button> <button v-if="unAssignedAddendums.length > 0" v-on:click="addExistingAddendumButton" class="btn btn-sm btn-primary btn-sm">Add Existing Addendum</button>
      </h4>
      <div class="menu-addendum-form" v-if="menuaddendumform">
          <p>{{formtitle}}</p>
          <div class="container-fluid">
            <div class="row" v-if="addExistingAddendums">
              <div class="col-sm-12 col-md-6 mb-3">
                  <p>Select an addendum not already assigned to this menu.</p>
                  <v-select placeholder="Search..." :options="unAssignedAddendums" @input="setExistingAddendum" v-model="addendumName"></v-select>
              </div>
            </div>
              <div class="row">
                  <div class="col-sm-12 mb-3">
                      <label for="label">Label</label>
                      <input type="text" class="form-control" id="label" placeholder="" v-model="label">
                      <div class="input-error" v-if="msg.errors.label">
                          Valid label is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 mb-3">
                      <label for="lineText">Line Text</label>
                      <input type="text" class="form-control" id="lineText" placeholder="" v-model="lineText">
                      <div class="input-error" v-if="msg.errors.lineText">
                          Valid lineText is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="isFooter" placeholder="" v-model="isFooter" :checked="isFooter == 1">
                      <label class="form-check-label" for="isFooter">
                        Is Footer?
                      </label>
                    </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="isHeader" placeholder="" v-model="isHeader" :checked="isHeader == 1">
                      <label class="form-check-label" for="isHeader">
                        Is Header?
                      </label>
                    </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="printOnly" placeholder="" v-model="printOnly" :checked="printOnly == 1">
                      <label class="form-check-label" for="printOnly">
                        Print Only?
                      </label>
                    </div>
                  </div>
              </div>
              <div class="row" v-if="menuAddendumId">
                <div class="col-sm-12 mb-3 deletecontrol text-right">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="deleteAddendum" id="deleteAddendum">
                    <label class="form-check-label" for="deleteAddendum">
                      Delete this addendum?
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 mb-3 text-left form-controls">
                      <button v-on:click="saveMenuAddendumFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                      <button v-on:click="closeMenuAddendumFormButton" class="btn btn-sm btn-danger">Close</button>
                  </div>
              </div>
          </div>
      </div>
      <div v-if="'addendums' in menuaddendums" class="addendum-list">
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Label</th>
                <th scope="col">Line Text</th>
                <th scope="col">Is Header?</th>
                <th scope="col">Is Footer</th>
                <th scope="col">Print Only?</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>
                  <tr v-for="addendum in menuaddendums.addendums" :key="addendum.addendum_id">
                    <td>{{addendum.label}}</td>
                    <td>{{addendum.line_text}}</td>
                    <td>{{addendum.is_header}}</td>
                    <td>{{addendum.is_footer}}</td>
                    <td>{{addendum.print_only}}</td>
                    <td>
                      <button v-on:click="editAddendumButton(addendum.menu_addendum_id)" class="btn btn-sm btn-primary">Edit</button>
                    </td>
                  </tr>
            </tbody>
          </table>
      </div>
    </div>
</template>
<style lang="scss" scoped>
</style>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  label: 'MenuAddendums',
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
      menuaddendumform: false,
      formtitle: 'Add Menu Addendum',
      formsavetext: 'Save',
      label: '',
      lineText: '',
      displayOrder: '',
      isFooter: 0,
      isHeader: 0,
      printOnly: 0,
      addendumId: '',
      addendumName: '',
      addendumTypeId: '',
      menuAddendumId: '',
      addExistingAddendums: false,
      unAssignedAddendums: [],
      deleteAddendum: false,
      msg: {
        errors: {
          label: false,
          lineText: false
        }
      }
    }
  },
  components: {
  },
  computed: {
    ...mapState({
      groupaddendums: state => state.addendums.all,
      activeaddendum: state => state.addendums.activeaddendum,
      activemenu: state => state.menus.activemenu,
      menuaddendums: state => state.menuaddendums.allmenuaddendums,
      activemenuaddendum: state => state.menuaddendums.activemenuaddendum,
      addmenuaddendum: state => state.menuaddendums.addmenuaddendum,
      updatemenuaddendum: state => state.menuaddendums.updatemenuaddendum
    })
  },
  created () {
    const restGroupId = this.componentProps.restGroupId
    const addendumType = 'menu'
    const addendumTypeId = this.componentProps.menuId
    this.getAddendums(restGroupId)
    this.getAll({ restGroupId, addendumType, addendumTypeId })
  },
  watch: {
    label: {
      handler (val, oldVal) {
        this.validateAddendumForm()
      }
    },
    lineText: {
      handler (val, oldVal) {
        this.validateAddendumForm()
      }
    },
    activeaddendum: {
      handler (val, oldVal) {
        this.addendumId = val.addendum_id
        this.addendumTypeId = this.componentProps.menuId
        this.addendumName = val.label + '(' + val.line_text + ')'
        this.label = val.label
        this.lineText = val.line_text
        this.menuaddendumform = true
      }
    },
    activemenuaddendum: {
      handler (val, oldVal) {
        this.addendumFormReset()
        this.formtitle = 'Edit Menu Addendum'
        this.menuAddendumId = val.menu_addendum_id
        this.addendumId = val.addendum_id
        this.addendumTypeId = val.menu_id
        this.label = val.label
        this.lineText = val.line_text
        this.isFooter = val.is_footer
        this.isHeader = val.is_header
        this.printOnly = val.print_only
        this.displayOrder = val.display_order
        this.menuaddendumform = true
      }
    },
    addmenuaddendum: {
      handler (val, oldVal) {
        if (val.menu_addendum_id) {
          const restGroupId = this.componentProps.restGroupId
          const addendumType = 'menu'
          const addendumTypeId = this.componentProps.menuId
          this.getAll({ restGroupId, addendumType, addendumTypeId })
          this.closeMenuAddendumFormButton()
        }
      }
    },
    updatemenuaddendum: {
      handler (val, oldVal) {
        if (val.menu_addendum_id) {
          const restGroupId = this.componentProps.restGroupId
          const addendumType = 'menu'
          const addendumTypeId = this.componentProps.menuId
          this.getAll({ restGroupId, addendumType, addendumTypeId })
          this.closeMenuAddendumFormButton()
        }
      }
    },
    groupaddendums: {
      handler (val, oldVal) {
        if ('addendums' in val) {
          if (val.addendums.length > 0) {
            this.getAvailableAddendums()
          }
        }
      }
    },
    menuaddendums: {
      handler (val, oldVal) {
        if ('addendums' in val) {
          if (val.addendums.length > 0) {
            this.getAvailableAddendums()
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('menuaddendums', {
      getAll: 'getAll',
      getById: 'getById',
      add: 'add',
      update: 'update'
    }),
    ...mapActions('addendums', {
      getAddendums: 'getAll',
      getAddendum: 'getById'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    addendumFormReset () {
      this.formtitle = 'Add Menu Addendum'
      this.formsavetext = 'Save'
      this.label = ''
      this.lineText = ''
      this.displayOrder = ''
      this.isFooter = 0
      this.isHeader = 0
      this.printOnly = 0
      this.addendumId = ''
      this.addendumName = ''
      this.menuAddendumId = ''
      this.addendumTypeId = ''
      this.deleteAddendum = false
    },
    validateAddendumForm () {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    editAddendumButton (id) {
      this.addendumFormReset()
      this.clearAlert()
      this.scrollToTop()
      const restGroupId = this.componentProps.restGroupId
      const addendumType = 'menu'
      const addendumTypeId = id
      this.getById({ restGroupId, addendumType, addendumTypeId })
    },
    addMenuAddendumButton () {
      this.clearAlert()
      this.addendumFormReset()
      if (this.menuaddendumform) {
        this.menuaddendumform = false
        this.addExistingAddendums = false
        return
      }
      this.addExistingAddendums = false
      this.menuaddendumform = true
      this.addendumTypeId = this.activemenu.menu_id
    },
    addExistingAddendumButton () {
      this.addendumFormReset()
      if (this.addExistingAddendums) {
        this.addExistingAddendums = false
        this.menuaddendumform = false
        return
      }
      this.formtitle = 'Add Existing Addendum'
      this.menuaddendumform = true
      this.addExistingAddendums = true
    },
    setExistingAddendum (value) {
      if (value !== null) {
        this.getAddendum(value.code)
      }
    },
    getAvailableAddendums () {
      if ('addendums' in this.groupaddendums) {
        var availableAddendums = []
        Object.keys(this.groupaddendums.addendums).forEach(key => {
          const addendum = this.groupaddendums.addendums[key]
          if (this.addendumAlreadyAssigned(addendum.addendum_id) === false) {
            availableAddendums.push({ code: addendum.addendum_id, label: addendum.label + ' (' + addendum.line_text + ')' })
          }
        })
        this.unAssignedAddendums = availableAddendums
      }
    },
    addendumAlreadyAssigned (addendumId) {
      if ('addendums' in this.menuaddendums) {
        for (var i = 0; i < this.menuaddendums.addendums.length; i++) {
          const inUseAddendumId = this.menuaddendums.addendums[i].addendum_id
          if (inUseAddendumId === addendumId) {
            return true
          }
        }
      }
      return false
    },
    saveMenuAddendumFormButton () {
      this.clearAlert()
      if (this.validateAddendumForm() === false) {
        return
      }
      this.scrollToTop()
      const addendumId = this.addendumId
      const menuAddendumId = this.menuAddendumId
      const restGroupId = this.componentProps.restGroupId
      const addendumType = 'menu'
      const addendumTypeId = this.addendumTypeId
      const label = this.label
      const lineText = this.lineText
      const displayOrder = this.displayOrder
      const association = this.activemenu.name + '-' + label
      const isHeader = this.isHeader
      const isFooter = this.isFooter
      const printOnly = this.printOnly
      const deleteAddendum = this.deleteAddendum

      if (menuAddendumId) {
        this.update({
          addendumId,
          menuAddendumId,
          restGroupId,
          addendumType,
          addendumTypeId,
          label,
          lineText,
          displayOrder,
          association,
          isHeader,
          isFooter,
          printOnly,
          deleteAddendum
        })
      } else {
        this.add({
          addendumId,
          restGroupId,
          addendumType,
          addendumTypeId,
          label,
          lineText,
          displayOrder,
          association,
          isHeader,
          isFooter,
          printOnly
        })
      }
    },
    closeMenuAddendumFormButton () {
      this.menuaddendumform = false
      this.addExistingAddendums = false
      this.addendumFormReset()
    },
    scrollToTop (elementId = null) {
      var top = 0
      if (elementId) {
        var element = document.getElementById(elementId)
        top = element.offsetTop
      }
      window.scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
