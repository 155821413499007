<template>
    <div class="menusetup-child-component importData">
      <div class="child-component-header mb-4">
          <h6>Upload a properly formatted excel spreadsheet of item data to import items. <a href="/downloads/item-import-v1.xlsx">Download this template to get started.</a></h6>
          <input type="file" @change="onChange" />
      </div>
      <div class="hotTableWrapper">
        <hot-table ref="importDataItems" :settings="hotSettings" v-show="importData">
          <hot-column title="Name" data="name"></hot-column>
          <hot-column title="Price" data="price"></hot-column>
          <hot-column title="Description" data="description"></hot-column>
          <hot-column title="Menu Select" :settings="menuColumnSettings" data="menu"></hot-column>
          <hot-column title="Menu Section Select" :settings="sectionColumnSettings" data="section"></hot-column>
        </hot-table>
        <button class="btn btn-sm btn-primary mt-4" v-on:click="addItemImportRow()" v-show="importData">Add Row</button> <button class="btn btn-sm btn-success mt-4" v-on:click="addImportedItems()" v-show="importData">Save</button>
      </div>
    </div>
</template>
<style src="../../../node_modules/handsontable/dist/handsontable.full.css"></style>
<script>
import XLSX from '../../../node_modules/xlsx/dist/xlsx.full.min.js'
import { HotTable, HotColumn } from '@handsontable/vue'

import { mapState } from 'vuex'
export default {
  name: 'importData',
  components: {
    HotTable,
    HotColumn
  },
  props: {
    componentProps: {
      required: true
    }
  },
  watch: {
    importData: {
      handler (val, oldVal) {
        this.hotSettings.colHeaders = true
      }
    }
  },
  data () {
    return {
      file: null,
      importData: null,
      hotSettings: {
        licenseKey: 'non-commercial-and-evaluation',
        colHeaders: false,
        dropdownMenu: true,
        rowHeaders: true,
        manualRowMove: true,
        manualColumnMove: true,
        columnSorting: true,
        autoRowSize: true,
        autoColumnSize: true,
        contextMenu: true,
        manualColumnResize: true,
        manualRowResize: true,
        afterChange: () => {
          const tableData = this.$refs.importDataItems.hotInstance.getSourceData()
          this.$parent.updateImportData(tableData)
          const lastSelected = this.$refs.importDataItems.hotInstance.getSelected()
          if (lastSelected !== undefined) {
            const cellRowIndex = lastSelected[0][0]
            const cellIndex = lastSelected[0][1]
            const cellValue = this.$refs.importDataItems.hotInstance.getDataAtCell(cellRowIndex, cellIndex)
            if (cellIndex === 3) {
              this.$refs.importDataItems.hotInstance.setCellMeta(cellRowIndex, 4, 'source', this.sectionList(cellValue))
              const sectionCellValue = this.$refs.importDataItems.hotInstance.getDataAtCell(cellRowIndex, 4)
              if (sectionCellValue) {
                this.$refs.importDataItems.hotInstance.setDataAtCell(cellRowIndex, 4, '')
              }
            }
          }
          this.$refs.importDataItems.hotInstance.render()
        }
      },
      menuColumnSettings: {
        type: 'dropdown',
        source: this.menuList()
      },
      sectionColumnSettings: {
        type: 'dropdown',
        source: ['You must select a menu.']
      }
    }
  },
  methods: {
    menuList () {
      var menus = []
      const menuState = this.$store.state.menussetup.menu
      const storeRestData = JSON.parse(JSON.stringify(menuState))
      for (var index in storeRestData) {
        const menuName = storeRestData[index].name
        menus.push(menuName)
      }
      return menus
    },
    sectionList (menuName) {
      const menuId = this.getMenuId(menuName)
      var sections = []
      const menuSectionState = this.$store.state.menussetup.menu_section
      const storeRestData = JSON.parse(JSON.stringify(menuSectionState))
      for (var index in storeRestData) {
        if (storeRestData[index].menuId === menuId) {
          const sectionName = storeRestData[index].name
          sections.push(sectionName)
        }
      }
      return sections
    },
    getMenuId (menuName) {
      var menuId = null
      const menuState = this.$store.state.menussetup.menu
      const storeRestData = JSON.parse(JSON.stringify(menuState))
      for (var index in storeRestData) {
        if (storeRestData[index].name === menuName) {
          menuId = storeRestData[index].menuId
        }
      }
      return menuId
    },
    getSectionId (menuId, sectionName) {
      var sectionId = null
      const menuSectionState = this.$store.state.menussetup.menu_section
      const storeRestData = JSON.parse(JSON.stringify(menuSectionState))
      for (var index in storeRestData) {
        if (storeRestData[index].menuId === menuId && storeRestData[index].name === sectionName) {
          sectionId = storeRestData[index].menuSectionId
        }
      }
      return sectionId
    },
    addItemImportRow () {
      const col = this.$refs.importDataItems.hotInstance.countRows()
      this.$refs.importDataItems.hotInstance.alter('insert_row', col, 1)
    },
    onChange (event) {
      this.file = event.target.files ? event.target.files[0] : null
      if (this.file) {
        const reader = new FileReader()

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result
          const wb = XLSX.read(bstr, { type: 'binary' })
          /* Get first worksheet */
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]
          /* Convert array of arrays */
          const dataJson = XLSX.utils.sheet_to_json(ws)
          this.$refs.importDataItems.hotInstance.loadData(dataJson)
          this.importData = dataJson
        }
        reader.readAsBinaryString(this.file)
      }
    },
    addImportedItems () {
      const items = this.importDataStore
      const storeKey = this.componentProps.component
      var itemId = this.nextItemId()
      if (storeKey === 'item') {
        for (var i = 0; i < items.length; i++) {
          const menuId = this.getMenuId(items[i].menu)
          const sectionId = this.getSectionId(menuId, items[i].section)
          this.addItemToStore(itemId, items[i].name, items[i].description, items[i].price, menuId, sectionId)
          itemId++
        }
        this.$parent.updateImportData(this.importData)
        this.$parent.itemsComponent()
      }
    },
    addItemToStore (itemId, name, description, price, menuId, sectionId) {
      const menuItemData = {
        itemId: itemId,
        name: name,
        description: description,
        price: price,
        menuId: menuId,
        menuSectionId: sectionId,
        display_order: '',
        image: false
      }
      const data = { menuItemData: menuItemData }
      this.$parent.addMenuItem(data)
    },
    nextItemId () {
      // Grab the next Id
      let nextId
      nextId = 0
      const storeRestData = JSON.parse(JSON.stringify(this.items))
      for (var item in storeRestData) {
        if (nextId <= storeRestData[item].itemId) {
          nextId = storeRestData[item].itemId
        }
      }
      nextId = nextId + 1
      return nextId
    }
  },
  computed: {
    ...mapState({
      restaurants: state => state.menussetup.restaurant,
      items: state => state.menussetup.item,
      importDataStore: state => state.menussetup.importData,
      menus: state => state.menussetup.menu,
      menuSections: state => state.menussetup.menu_section
    })
  }
}
</script>
