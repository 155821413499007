import Vue from 'vue'
import Vuex from 'vuex'

import { alert } from './alert.module'
import { account } from './account.module'
import { restaurants } from './restaurants.module'
import { operators } from './operators.module'
import { brands } from './brands.module'
import { restgroups } from './restgroups.module'
import { modifiers } from './modifiers.module'
import { properties } from './properties.module'
import { items } from './items.module'
import { salestaxrates } from './salestaxrates.module'
import { unitsofmeasure } from './unitsofmeasure.module'
import { menus } from './menus.module'
import { menuitems } from './menuitems.module'
import { menusections } from './menusections.module'
import { addendums } from './addendums.module'
import { menuaddendums } from './menuaddendums.module'
import { search } from './search.module'
import { importModule } from './import.module'
import { menussetup } from './menussetup.module'
import { menussetupv2 } from './menussetupv2.module'
import { getsetupv1 } from './getsetupv1.module'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    alert,
    account,
    operators,
    brands,
    restgroups,
    modifiers,
    properties,
    restaurants,
    items,
    salestaxrates,
    unitsofmeasure,
    menus,
    menuitems,
    menusections,
    menuaddendums,
    addendums,
    search,
    importModule,
    menussetup,
    menussetupv2,
    getsetupv1
  }
})
