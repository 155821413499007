<template>
    <div class="menu-page page">
        <div class="menu-details">
           <h6><router-link :to="{ name: 'managerestgroup', params: {id: restGroupId } }">{{activerestgroup.name}}</router-link> >
            <router-link :to="{ name: 'managerestgrouprestaurant',
              params: {restGroupId: restGroupId, restId: restId } }">{{activerestaurant.name}}</router-link> > Menu: {{activemenu.name}}</h6>
        </div>
        <ul class="nav nav-tabs">
          <li>
            <a class="nav-link" :class="{ 'active' : activeNav === 'MenuDisplay' }" v-on:click="menuDisplayComponent">Display</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'MenuItems' }" v-on:click="menuItemsComponent">Items</a>
          </li>
          <li>
            <a class="nav-link" :class="{ 'active' : activeNav === 'MenuSections' }" v-on:click="menuSectionsComponent">Sections</a>
          </li>
          <li>
            <a class="nav-link" :class="{ 'active' : activeNav === 'MenuAddendums' }"  v-on:click="menuAddendumsComponent">Addendums</a>
          </li>
        </ul>
        <component v-bind:is="component" :componentProps="childComponentProps" />
    </div>
</template>
<style lang="scss" scoped>
  .page {
      margin-top: 1rem;
      .menu-details {
        border-bottom: 1px solid #ccc;
        margin-bottom: 1rem;
      }
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'
import MenuItems from './child-components/MenuItems'
import MenuSections from './child-components/MenuSections'
import MenuAddendums from './child-components/MenuAddendums'
import MenuDisplay from './child-components/MenuDisplay'

export default {
  name: 'Menu',
  components: {
    MenuItems, MenuSections, MenuAddendums, MenuDisplay
  },
  data () {
    return {
      menuId: this.$route.params.menuId,
      component: '',
      childComponentProps: {},
      activeNav: '',
      restId: this.$route.params.restId,
      restGroupId: this.$route.params.restGroupId,
      msg: {
        errors: {}
      }
    }
  },
  computed: {
    ...mapState({
      activemenu: state => state.menus.activemenu,
      activerestaurant: state => state.restaurants.activerestaurant,
      activerestgroup: state => state.restgroups.activerestgroup
    })
  },
  created () {
    const { restGroupId, restId, menuId } = this
    this.restGroupId = restGroupId
    this.restId = restId
    this.menuId = menuId
    this.getRestGroup(this.restGroupId)
    this.getRestaurant(this.restId)
    this.getMenu(this.menuId)
    this.childComponentProps = { restGroupId, restId, menuId }
    this.component = this.$route.params.componentName ? this.$route.params.componentName : 'MenuItems'
    this.activeNav = this.$route.params.componentName ? this.$route.params.componentName : 'MenuItems'
  },
  methods: {
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    ...mapActions('menus', {
      getMenu: 'getById'
    }),
    ...mapActions('restaurants', {
      getRestaurant: 'getById'
    }),
    ...mapActions('restgroups', {
      getRestGroup: 'getById'
    }),
    menuItemsComponent (event) {
      this.clearAlert()
      const { restGroupId, restId, menuId } = this
      this.childComponentProps = { restGroupId, restId, menuId }
      this.component = MenuItems
      this.activeNav = 'MenuItems'
    },
    menuSectionsComponent (event) {
      this.clearAlert()
      const { restGroupId, restId, menuId } = this
      this.childComponentProps = { restGroupId, restId, menuId }
      this.component = MenuSections
      this.activeNav = 'MenuSections'
    },
    menuAddendumsComponent (event) {
      this.clearAlert()
      const { restGroupId, restId, menuId } = this
      this.childComponentProps = { restGroupId, restId, menuId }
      this.component = MenuAddendums
      this.activeNav = 'MenuAddendums'
    },
    menuDisplayComponent (event) {
      this.clearAlert()
      const { restGroupId, restId, menuId } = this
      this.childComponentProps = { restGroupId, restId, menuId }
      this.component = MenuDisplay
      this.activeNav = 'MenuDisplay'
    }
  }
}
</script>
