<template>
    <div class="page manage-restaurant">
        <div class="rest-details">
           <h6><router-link :to="{ name: 'managerestgroup', params: {id: restGroupId } }">{{activerestgroup.name}}</router-link> >
            {{activerestaurant.name}}</h6>
        </div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'Menus' }" v-on:click="menusComponent">Menus</a>
          </li>
          <li>
            <a class="nav-link" :class="{ 'active' : activeNav === 'SalesTaxRates' }" v-on:click="salestaxratesComponent">Sales Tax Rates</a>
          </li>
        </ul>
        <div class="container-fluid pt-2">
            <div class="row">
                <div class="col">
                    <component v-bind:is="component" :componentProps="componentProps" />
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
  .page {
    margin-top: 1rem;
    .rest-details {
      border-bottom: 1px solid #ccc;
      margin-bottom: 1rem;
    }
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'
import Menus from './menus/Menus'
import SalesTaxRates from './salesTaxRates/SalesTaxRates'

export default {
  data () {
    return {
      restGroupId: this.$route.params.restGroupId,
      restId: this.$route.params.restId,
      component: '',
      componentProps: {},
      activeNav: '',
      msg: {
        errors: {}
      }
    }
  },
  components: {
    Menus, SalesTaxRates
  },
  computed: {
    ...mapState({
      activerestaurant: state => state.restaurants.activerestaurant,
      activerestgroup: state => state.restgroups.activerestgroup
    })
  },
  created () {
    this.getRestaurant(this.restId)
    const { restGroupId, restId } = this
    this.componentProps = { restGroupId, restId }
    this.component = this.$route.params.componentName ? this.$route.params.componentName : 'Menus'
    this.activeNav = this.$route.params.componentName ? this.$route.params.componentName : 'Menus'
  },
  methods: {
    ...mapActions('restaurants', {
      getRestaurant: 'getById'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    menusComponent (event) {
      this.clearAlert()
      const { restGroupId, restId } = this
      this.componentProps = { restGroupId, restId }
      this.component = Menus
      this.activeNav = 'Menus'
    },
    salestaxratesComponent (event) {
      this.clearAlert()
      const { restGroupId, restId } = this
      this.componentProps = { restGroupId, restId }
      this.component = SalesTaxRates
      this.activeNav = 'SalesTaxRates'
    }
  }
}
</script>
