<template>
  <div id="app">
    <TopNav v-show="!fullScreen" />
    <div class="container-fluid p-0">
      <div class="row no-gutters">
        <div v-if="account.status.loggedIn" class="col-sm-2 bg-light side-bar" v-show="!fullScreen">
          <SideNav/>
        </div>
        <div :class="account.status.loggedIn && !fullScreen ? 'col-sm-10' : 'col-sm-12 fullpage'">
          <div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
          <router-view/>
        </div>
      </div>
    </div>
    <FooterNav v-show="!fullScreen" />
  </div>
</template>
<style>
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
  .fullpage {
    height: 100vh;
  }
</style>
<script>
import TopNav from '../_template-parts/TopNav'
import SideNav from '../_template-parts/SideNav'
import FooterNav from '../_template-parts/FooterNav'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'app',
  components: {
    TopNav,
    SideNav,
    FooterNav
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      account: state => state.account,
      fullScreen: state => state.getsetupv1.fullScreen
    })
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear'
    })

  },
  watch: {
    $route (to, from) {
      this.clearAlert()
    }
  }
}
</script>
