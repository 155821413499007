import { menusSetupService } from '../_services'
import { singleRestaurant } from './testData/singleRestaurant.js'
import { multipleRestaurants } from './testData/multipleRestaurants.js'
import { resetData } from './testData/resetData.js'

const state = {
  operator: {},
  brand: {
    name: '',
    description: ''
  },
  restaurant_group: {},
  restaurant: [],
  activeRestaurant: '',
  restaurant_sales_tax: {},
  menu: [],
  activeMenu: '',
  menu_section: [],
  activeMenuSection: '',
  item: [],
  activeMenuItem: '',
  menu_item: {},
  menu_section_item: {},
  modifier_set: [],
  activeModifierSet: '',
  modifier: [],
  activeModifier: '',
  modifier_set_member: {},
  item_modifier_set: {},
  item_modifier: {},
  menu_item_modifier_set: {},
  menu_item_modifier: {},
  restOperationType: false,
  display: {
    restOperationType: true,
    brandInformation: false,
    restInfo: false
  },
  importData: [],
  sortItemsBy: 'name',
  sortItemsAsc: true
}

const actions = {
  importTestData ({ commit }, type) {
    const stateName = 'importTestData'
    commit('request', { stateName: stateName })
    var data = {}
    if (type === 'single') {
      data = singleRestaurant()
    }
    if (type === 'multiple') {
      data = multipleRestaurants()
    }
    if (type === 'reset') {
      data = resetData()
    }
    commit('importTestData', data)
  },
  setDisplay ({ commit }, data) {
    const stateName = 'setDisplay'
    commit('request', { stateName: stateName })
    commit('setDisplay', data)
  },
  setActiveRestaurant ({ commit }, restId) {
    const stateName = 'setActiveRestaurant'
    commit('request', { stateName: stateName })
    commit('setActiveRestaurant', restId)
  },
  setRestOperationType ({ commit }, type) {
    const stateName = 'setRestOperationType'
    commit('request', { stateName: stateName })
    commit('setRestOperationType', type)
  },
  updateBrand ({ commit }, data) {
    const stateName = 'updateBrand'
    commit('request', { stateName: stateName })
    commit('updateBrand', data)
  },
  addRestaurant ({ commit }, data) {
    const stateName = 'addRestaurant'
    commit('request', { stateName: stateName })
    var restaurant = menusSetupService.addRestaurant(data)
    commit('addRestaurant', restaurant)
  },
  deleteRestaurant ({ commit }, restId) {
    const stateName = 'deleteRestaurant'
    commit('request', { stateName: stateName })
    commit('deleteRestaurant', restId)
  },
  deleteAllRestaurants ({ commit }) {
    const stateName = 'deleteAllRestaurants'
    commit('request', { stateName: stateName })
    commit('deleteAllRestaurants')
  },
  setActiveMenu ({ commit }, menuId) {
    const stateName = 'setActiveMenu'
    commit('request', { stateName: stateName })
    commit('setActiveMenu', menuId)
  },
  addMenu ({ commit }, data) {
    const stateName = 'addMenu'
    commit('request', { stateName: stateName })
    var menu = menusSetupService.addMenu(data)
    commit('addMenu', menu)
  },
  deleteMenu ({ commit }, menuId) {
    const stateName = 'deleteMenu'
    commit('request', { stateName: stateName })
    commit('deleteMenu', menuId)
  },
  setActiveMenuSection ({ commit }, menuSectionId) {
    const stateName = 'setActiveMenuSection'
    commit('request', { stateName: stateName })
    commit('setActiveMenuSection', menuSectionId)
  },
  addMenuSection ({ commit }, data) {
    const stateName = 'addMenuSection'
    commit('request', { stateName: stateName })
    var menuSection = menusSetupService.addMenuSection(data)
    commit('addMenuSection', menuSection)
  },
  deleteMenuSection ({ commit }, menuSectionId) {
    const stateName = 'deleteMenuSection'
    commit('request', { stateName: stateName })
    commit('deleteMenuSection', menuSectionId)
  },
  moveMenuSection ({ commit }, sectionMoveData) {
    const stateName = 'moveMenuSection'
    commit('request', { stateName: stateName })
    commit('moveMenuSection', sectionMoveData)
  },
  setActiveMenuItem ({ commit }, menuItemId) {
    const stateName = 'setActiveMenuItem'
    commit('request', { stateName: stateName })
    commit('setActiveMenuItem', menuItemId)
  },
  sortMenuItems ({ commit }, data) {
    const stateName = 'sortMenuItems'
    commit('request', { stateName: stateName })
    commit('sortMenuItems', data)
  },
  addMenuItem ({ commit }, data) {
    const stateName = 'addMenuItem'
    commit('request', { stateName: stateName })
    var menuItem = menusSetupService.addMenuItem(data)
    commit('addMenuItem', menuItem)
  },
  deleteMenuItem ({ commit }, menuItemId) {
    const stateName = 'deleteMenuItem'
    commit('request', { stateName: stateName })
    commit('deleteMenuItem', menuItemId)
  },
  setActiveModifierSet ({ commit }, modifierSetId) {
    const stateName = 'setActiveModifierSet'
    commit('request', { stateName: stateName })
    commit('setActiveModifierSet', modifierSetId)
  },
  addModifierSet ({ commit }, data) {
    const stateName = 'addModifierSet'
    commit('request', { stateName: stateName })
    var modifierSet = menusSetupService.addModifierSet(data)
    commit('addModifierSet', modifierSet)
  },
  deleteModifierSet ({ commit }, modifierSetId) {
    const stateName = 'deleteModifierSet'
    commit('request', { stateName: stateName })
    commit('deleteModifierSet', modifierSetId)
  },
  setActiveModifier ({ commit }, modifierId) {
    const stateName = 'setActiveModifier'
    commit('request', { stateName: stateName })
    commit('setActiveModifier', modifierId)
  },
  addModifier ({ commit }, data) {
    const stateName = 'addModifier'
    commit('request', { stateName: stateName })
    var modifier = menusSetupService.addModifier(data)
    commit('addModifier', modifier)
  },
  deleteModifier ({ commit }, modifierId) {
    const stateName = 'deleteModifier'
    commit('request', { stateName: stateName })
    commit('deleteModifier', modifierId)
  },
  addItemImportDataToStore ({ commit }, items) {
    const stateName = 'addItemImportDataToStore'
    commit('request', { stateName: stateName })
    commit('addItemImportDataToStore', items)
  },
  updateImportData ({ commit }, data) {
    const stateName = 'updateImportData'
    commit('request', { stateName: stateName })
    commit('updateImportData', data)
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  updateImportData (state, data) {
    state.importData = data
  },
  importTestData (state, data) {
    Object.keys(data).forEach(function (key) {
      // console.table('Key : ' + key + ', Value : ' + data[key])
      state[key] = data[key]
    })
    state.importTestData = false
  },
  setDisplay (state, data) {
    const component = data.component
    const value = data.value
    state.display[component] = value
  },
  setActiveRestaurant (state, restId) {
    state.activeRestaurant = restId
  },
  setRestOperationType (state, type) {
    state.restOperationType = type
  },
  updateBrand (state, data) {
    state.brand.name = data.name
    state.brand.description = data.description
  },
  addRestaurant (state, restaurant) {
    for (var i = 0; i < state.restaurant.length; i++) {
      if (state.restaurant[i].restId === restaurant.restId) {
        return
      }
    }
    state.restaurant.push(restaurant)
  },
  deleteRestaurant (state, restId) {
    for (var i = 0; i < state.restaurant.length; i++) {
      if (state.restaurant[i].restId === restId) {
        state.restaurant.splice(i, 1)
      }
    }
  },
  setActiveMenu (state, menuId) {
    state.activeMenu = menuId
  },
  addMenu (state, menu) {
    for (var i = 0; i < state.menu.length; i++) {
      if (state.menu[i].menuId === menu.menuId) {
        return
      }
    }
    state.menu.push(menu)
  },
  deleteMenu (state, menuId) {
    let sections, menus

    // Delete menu sections
    sections = state.menu_section.length
    for (var s = 0; s < sections; s++) {
      if (state.menu_section[s].menuId === menuId) {
        state.menu_section.splice(s, 1)
        sections = state.menu_section.length
        s = -1
      }
    }

    // Delete menu
    menus = state.menu.length
    for (var i = 0; i < menus; i++) {
      if (state.menu[i].menuId === menuId) {
        state.menu.splice(i, 1)
        menus = state.menu.length
        i = -1
      }
    }
  },
  setActiveMenuSection (state, menuSectionId) {
    state.activeMenuSection = menuSectionId
  },
  addMenuSection (state, menuSection) {
    for (var i = 0; i < state.menu_section.length; i++) {
      if (state.menu_section[i].menuSectionId === menuSection.menuSectionId) {
        return
      }
    }
    state.menu_section.push(menuSection)
  },
  deleteMenuSection (state, menuSectionId) {
    for (var i = 0; i < state.menu_section.length; i++) {
      if (state.menu_section[i].menuSectionId === menuSectionId) {
        state.menu_section.splice(i, 1)
      }
    }
  },
  moveMenuSection (state, sectionMoveData) {
    for (var index = 0; index < state.menu_section.length; index++) {
      if (state.menu_section[index].menuSectionId === sectionMoveData.menuSectionId) {
        break
      }
    }
    // console.log('move', state.menu_section, index, sectionMoveData.delta)
    var newIndex = index + sectionMoveData.delta
    if (newIndex < 0 || newIndex === state.menu_section.length) return

    var indexes = [index, newIndex].sort((a, b) => a - b)
    state.menu_section.splice(indexes[0], 2, state.menu_section[indexes[1]], state.menu_section[indexes[0]])
  },
  setActiveMenuItem (state, menuItemId) {
    state.activeMenuItem = menuItemId
  },
  addMenuItem (state, menuItem) {
    for (var i = 0; i < state.item.length; i++) {
      if (state.item[i].itemId === menuItem.itemId) {
        return
      }
    }
    state.item.push(menuItem)
  },
  deleteMenuItem (state, menuItemId) {
    for (var i = 0; i < state.item.length; i++) {
      if (state.item[i].itemId === menuItemId) {
        state.item.splice(i, 1)
      }
    }
  },
  setActiveModifierSet (state, modifierSetId) {
    state.activeModifierSet = modifierSetId
  },
  addModifierSet (state, modifierSet) {
    for (var i = 0; i < state.modifier_set.length; i++) {
      if (state.modifier_set[i].modifierSetId === modifierSet.modifierSetId) {
        return
      }
    }
    state.modifier_set.push(modifierSet)
  },
  deleteModifierSet (state, modifierSetId) {
    let modifierSets

    // Delete from modifier_set
    modifierSets = state.modifier_set.length
    for (var i = 0; i < modifierSets; i++) {
      if (state.modifier_set[i].modifierSetId === modifierSetId) {
        state.modifier_set.splice(i, 1)
        modifierSets = state.modifier_set.length
        i = -1
      }
    }
  },
  setActiveModifier (state, modifierId) {
    state.activeModifier = modifierId
  },
  addModifier (state, modifier) {
    for (var i = 0; i < state.modifier.length; i++) {
      if (state.modifier[i].modifierId === modifier.modifierId) {
        return
      }
    }
    state.modifier.push(modifier)
  },
  deleteModifier (state, modifierId) {
    let modifiers

    // Delete from modifier
    modifiers = state.modifier.length
    for (var i = 0; i < modifiers; i++) {
      if (state.modifier[i].modifierId === modifierId) {
        state.modifier.splice(i, 1)
        modifiers = state.modifier.length
        i = -1
      }
    }
  },
  addItemImportDataToStore (state, items) {
    for (var i = 0; i < items.length; i++) {
      state.item.push(items[i])
    }
  },
  sortMenuItems (state, data) {
    state.sortItemsBy = data.sortItemsBy
    state.sortItemsAsc = data.sortItemsAsc
  }
}

const getters = {
  getMenuSections: (state) => (menuId) => {
    const sections = state.menu_section.filter(section => section.menuId === menuId)
    if (sections.length) {
      return sections
    }
    return false
  },
  getSortedMenuItems: (state) => () => {
    const items = state.item.sort((a, b) => {
      if (a[state.sortItemsBy] > b[state.sortItemsBy]) { return 1 }
      if (a[state.sortItemsBy] < b[state.sortItemsBy]) { return -1 }
      return 0
    })

    if (!state.sortItemsAsc) {
      items.reverse()
    }
    return items
  }
}

export const menussetup = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
