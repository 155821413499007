<template>
    <div class="menusetup-child-component restaurant-admin">
      <div class="container-fluid">
        <div class="row">
          <div class="col" v-if="restOperationType && !display.restOperationType">
            <p>Restaurant operation type: <b>{{ restOperationType  }}</b> | <a href="#" v-on:click="changeRestOperationType()">Change</a></p>
          </div>
          <div class="col" v-if="display.restOperationType">
            <div class="jumbotron">
              <h3 class="display-6">Do you operate more than one restaurant location under the same brand?</h3>
              <hr class="my-4">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-auto">
                    <p class="lead text-center">
                      <a class="btn btn-primary btn-lg" href="#" role="button" v-on:click="setRestOperationType('single')">No</a>
                    </p>
                  </div>
                  <div class="col-auto">
                    <p class="lead text-center">
                      <a class="btn btn-primary btn-lg" href="#" role="button" v-on:click="setRestOperationType('multiple')">Yes</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col" v-if="restOperationType === 'multiple' && !display.brandInformation">
            <p>Brand Name <b>{{ brand.name }}</b> Description <b>{{ brand.description }}</b> | <a href="#" v-on:click="changeBrandInfo()">Change</a></p>
          </div>
          <div class="col" v-if="display.brandInformation">
            <div class="jumbotron">
              <h1 class="display-6">Brand Information</h1>
              <hr class="my-4">
              <div class="container-fluid">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="brandName">Name</label>
                      <input type="text" class="form-control" id="brandName" v-model="brand.name" placeholder="Name">
                    </div>
                    <div class="form-group">
                      <label for="brandDescription">Description</label>
                      <input type="text" class="form-control" id="brandDescription" v-model="brand.description" placeholder="Description">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="lead text-center">
                      <a class="btn btn-primary btn-lg" role="button" v-on:click="showRestInfo(true)">Next: Restaurant Information</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="display.restInfo">
          <div class="col">
            <div class="jumbotron p-4">
              <h1 class="display-6">Restaurant Information</h1>
              <div class="selectLocation" v-if="restOperationType === 'multiple'">
                <hr class="my-4">
                <p><button class='btn btn-sm btn-primary' v-on:click="addRestaurant()">Add Restaurant</button></p>
                <div class="btn-group" role="group" aria-label="editingRestaurant">
                  <button type="button" class="btn btn-secondary" :class="{ 'btn-success' : activeRestaurant === restaurants.restId }" v-for="restaurants in restaurant" :key="restaurants.restId" v-on:click="editRestaurant(restaurants.restId)">
                    <span v-if="restaurants.name.length">{{restaurants.name}}</span>
                    <span v-else>{{restaurants.restId}}</span>
                  </button>
                </div>
              </div>
              <hr class="my-4">
              <div class="container-fluid" v-if="Object.keys(editingRestaurant).length != 0">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="restName">Name</label>
                      <input type="text" class="form-control" id="restName" placeholder="Name" v-model="editingRestaurant.name">
                    </div>
                    <div class="form-group">
                      <label for="restDescription">Description</label>
                      <input type="text" class="form-control" id="restDescription" placeholder="Description" v-model="editingRestaurant.description">
                    </div>
                    <div class="form-group">
                      <label for="restAddress">Address</label>
                      <input type="text" class="form-control" placeholder="Address"  v-model="editingRestaurant.address">
                    </div>
                    <div class="form-group">
                      <label for="restAddress2">Address 2 </label>
                      <input type="text" class="form-control" placeholder="Address 2" v-model="editingRestaurant.address2">
                    </div>
                    <div class="form-group">
                      <label for="restCity">City</label>
                      <input type="text" class="form-control" id="restCity" placeholder="City" v-model="editingRestaurant.city">
                    </div>
                    <div class="form-group">
                      <label for="restProvince">State or Province</label>
                      <input type="text" class="form-control" id="restProvince" placeholder="State or Province" v-model="editingRestaurant.stateProvince">
                    </div>
                    <div class="form-group">
                      <label for="restPostalCode">Postal Code</label>
                      <input type="text" class="form-control" id="restPostalCode" placeholder="Postal Code" v-model="editingRestaurant.postalCode">
                    </div>
                    <div class="form-group">
                      <label for="restCountry">Country</label>
                      <input type="text" class="form-control" id="restCountry" placeholder="Country" v-model="editingRestaurant.country">
                    </div>
                    <div class="form-group">
                      <label for="restTaxRate">Tax Rate %</label>
                      <input type="text" class="form-control" id="restTaxRate" placeholder="7.5" v-model="editingRestaurant.taxRate">
                    </div>
                    <p class='text-right'><button class="btn btn-sm btn-danger" v-if="restOperationType === 'multiple' && Object.keys(restaurant).length > 1" v-on:click="deleteRestaurant(editingRestaurant.restId)">Delete</button></p>
                  </div>
                </div>
              </div>
              <div class="container-fluid">
                <div class="row">
                  <div class="col">
                    <p class="lead text-center">
                      <a class="btn btn-primary btn-lg" :class="{ 'disabled' : !activeRestaurant }" href="#" role="button" v-on:click="menusComponent">Next: Create Menus</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'restaurantAdmin',
  data () {
    return {
      editingRestaurant: {}
    }
  },
  created () {
    if (this.restOperationType) {
      this.setDisplay('restOperationType', false)
      this.setDisplay('restInfo', true)
    }
    if (this.activeRestaurant) {
      this.editRestaurant(this.activeRestaurant)
    }
  },
  watch: {
    activeRestaurant: {
      handler (val, oldVal) {
        this.editRestaurant(val)
      }
    },
    restaurant: {
      handler (val, oldVal) {
        if ('0' in val) {
          if ('restId' in val[0] && !this.activeRestaurant) {
            this.editRestaurant(val[0].restId)
          }
        }
      }
    }
  },
  methods: {
    addRestaurant () {
      let nextId
      const storeRestData = JSON.parse(JSON.stringify(this.restaurant))
      const latestEntry = storeRestData.slice(-1).pop()
      nextId = 1
      if (latestEntry !== undefined && this.restOperationType === 'multiple') {
        nextId = latestEntry.restId + 1
      }
      this.buildRestData(nextId)
    },
    deleteRestaurant (restId) {
      this.$parent.deleteRestaurant(restId)
      if (this.restaurant) {
        this.editingRestaurant = this.restaurant[0]
      }
    },
    buildRestData (restId) {
      const restType = this.restOperationType
      const restData = {
        restId: restId,
        name: '',
        description: '',
        address: '',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: '',
        taxRate: '',
        type: restType
      }
      const data = { restData: restData }
      this.$parent.addRestaurant(data)
    },
    editRestaurant (restId) {
      this.$parent.setActiveRestaurant(restId)
      for (const [key, value] of Object.entries(this.restaurant)) {
        if (value.restId === restId) {
          this.editingRestaurant = this.restaurant[key]
        }
      }
    },
    menusComponent () {
      this.$parent.menusComponent()
    },
    setRestOperationType (type) {
      this.$parent.setRestOperationType(type)
      if (type === 'single') {
        this.addRestaurant()
        this.showRestInfo(true)
      }
      if (type === 'multiple') {
        this.setDisplay('brandInformation', true)
      }
      this.setDisplay('restOperationType', false)
    },
    changeRestOperationType () {
      this.setDisplay('restOperationType', true)
      this.showRestInfo(false)
      this.setDisplay('brandInformation', false)
      this.$parent.setActiveRestaurant(1)
    },
    changeBrandInfo () {
      this.setDisplay('brandInformation', true)
      this.setDisplay('restInfo', false)
    },
    showRestInfo (value) {
      this.setDisplay('brandInformation', false)
      if (value) {
        this.setDisplay('restInfo', true)
        return
      }
      this.setDisplay('restInfo', false)
    },
    setDisplay (component, value) {
      const data = {
        component: component,
        value: value
      }
      this.$parent.setDisplay(data)
    }
  },
  computed: {
    ...mapState({
      restaurant: state => state.menussetup.restaurant,
      activeRestaurant: state => state.menussetup.activeRestaurant,
      brand: state => state.menussetup.brand,
      restOperationType: state => state.menussetup.restOperationType,
      display: state => state.menussetup.display
    })
  }
}
</script>
