<template>
    <div class="page items-page">
      <h6 class="page-title">Items <button v-on:click="addItemButton" class="btn btn-sm btn-primary">Add +</button></h6>
      <div class="item-form" v-if="itemform">
          <p>{{formtitle}}</p>
          <div class="container-fluid">
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <p>Item Type</p>
                      <v-select placeholder="Search..." :options="itemtypes" @input="setItemType" v-model="itemTypeName"></v-select>
                      <div class="input-error" v-if="msg.errors.itemTypeId">
                          Valid Item Type is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="name">Name</label>
                      <input type="text" class="form-control" id="name" placeholder="" v-model="name">
                      <div class="input-error" v-if="msg.errors.name">
                          Valid item name is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="chit">Chit</label>
                      <input type="text" class="form-control" id="chit" placeholder="" v-model="chit">
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="ticketName">Ticket Name</label>
                      <input type="text" class="form-control" id="ticketName" placeholder="" v-model="ticketName">
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="description">Description</label>
                      <input type="text" class="form-control" id="description" placeholder="" v-model="description">
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="baseQty">Base Qty</label>
                      <input type="text" class="form-control" id="baseQty" placeholder="" v-model="baseQty">
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="price">Price</label>
                      <input type="text" class="form-control" id="price" placeholder="" v-model="price">
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="priceDescription">Price Description</label>
                      <input type="text" class="form-control" id="priceDescription" placeholder="" v-model="priceDescription">
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="isMeasured" placeholder="" v-model="isMeasured" :checked="isMeasured == 1">
                      <label class="form-check-label" for="isMeasured">
                        Is Measured
                      </label>
                    </div>
                  </div>
              </div>
              <div class="row" v-if="editingitem">
                <div class="col-sm-12 col-md-6 mb-3 deletecontrol text-right">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="deleteItem" id="deleteItem">
                    <label class="form-check-label" for="deleteItem">
                      Delete this Item?
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3 text-left form-controls">
                      <button v-on:click="saveItemFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                      <button v-on:click="closeItemFormButton" class="btn btn-sm btn-danger">Close</button>
                  </div>
              </div>
          </div>
      </div>
      <div v-if="'items' in groupitems" class="items-list">
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Type</th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Chit</th>
                <th scope="col">Ticket Name</th>
                <th scope="col">Base Qty</th>
                <th scope="col">Price</th>
                <th scope="col">Price Desc</th>
                <th scope="col">Measured?</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
                  <tr v-for="item in groupitems.items" :key="item.id">
                    <td>{{item.item_id}}</td>
                    <td>{{item.item_type}}</td>
                    <td>{{item.item_name}}</td>
                    <td>{{item.description}}</td>
                    <td>{{item.chit}}</td>
                    <td>{{item.ticket_name}}</td>
                    <td>{{item.base_qty}}</td>
                    <td>{{item.price}}</td>
                    <td>{{item.price_description}}</td>
                    <td>{{item.is_measured}}</td>
                    <td>
                      <button v-on:click="editItemButton(item.item_id)" class="btn btn-sm btn-primary">Edit</button>
                    </td>
                  </tr>
            </tbody>
          </table>
      </div>
      <div v-else>
        No items available.
      </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Items',
  data () {
    return {
      formtitle: 'Add new Item',
      formsavetext: 'Save',
      itemform: false,
      restgroupid: this.$route.params.id,
      itemTypeId: '',
      itemTypeName: '',
      name: '',
      chit: '',
      ticketName: '',
      description: '',
      baseQty: '',
      price: '',
      priceDescription: '',
      isMeasured: 0,
      editingitem: '',
      deleteItem: false,
      msg: {
        errors: {
          itemTypeId: false,
          name: false
        }
      }
    }
  },
  computed: {
    ...mapState({
      groupitems: state => state.items.all,
      activeitem: state => state.items.activeitem,
      additem: state => state.items.additem,
      updateitem: state => state.items.updateitem,
      itemtypes: state => state.items.itemtypes
    })
  },
  created () {
    this.getItems(this.restgroupid)
    this.getItemTypes(this.restgroupid)
  },
  watch: {
    activeitem: {
      handler (val, oldVal) {
        this.itemTypeId = val.item_type_id
        this.itemTypeName = val.item_type
        this.name = val.item_name
        this.chit = val.chit
        this.ticketName = val.ticket_name
        this.description = val.description
        this.baseQty = val.base_qty
        this.price = val.price
        this.priceDescription = val.price_description
        this.isMeasured = val.is_measured
      }
    },
    itemname: {
      handler (val, oldVal) {
        this.validateItemForm()
      }
    },
    itemTypeId: {
      handler (val, oldVal) {
        this.validateItemForm()
      }
    },
    name: {
      handler (val, oldVal) {
        this.validateItemForm()
      }
    },
    additem: {
      handler (val, oldVal) {
        if (val.item_id) {
          this.getItems(this.restgroupid)
          this.ItemFormReset()
          this.closeItemFormButton()
        }
      }
    },
    updateitem: {
      handler (val, oldVal) {
        if (val.item_id) {
          this.getItems(this.restgroupid)
          this.ItemFormReset()
          this.closeItemFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('items', {
      getItems: 'getAll',
      getItem: 'getById',
      updateItem: 'update',
      addItem: 'add',
      getItemTypes: 'getTypes'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    ItemFormReset (event) {
      this.formtitle = 'Add new Item'
      this.itemTypeId = ''
      this.itemTypeName = ''
      this.name = ''
      this.chit = ''
      this.ticketName = ''
      this.description = ''
      this.baseQty = ''
      this.price = ''
      this.priceDescription = ''
      this.isMeasured = 0
      this.editingitem = ''
      this.deleteItem = false
    },
    closeItemFormButton (event) {
      this.itemform = false
    },
    setItemType (value) {
      if (value !== null) {
        this.itemTypeId = value.code
        this.itemTypeName = value.label
        return
      }
      this.itemTypeId = ''
      this.itemTypeName = ''
    },
    addItemButton (event) {
      this.clearAlert()
      this.ItemFormReset()
      if (this.itemform && this.editItemData === '') {
        this.closeItemFormButton()
        return
      }
      this.editItemData = ''
      this.itemform = true
    },
    editItemButton (id) {
      this.clearAlert()
      this.getItem(id)
      this.editingitem = id
      this.itemform = true
      this.formtitle = 'Edit Item'
      this.scrollToTop()
    },
    validateItemForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    scrollToTop () {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    saveItemFormButton (event) {
      this.clearAlert()
      if (this.validateItemForm() === false) {
        return
      }

      if (this.editingitem) {
        const {
          editingitem,
          restgroupid,
          itemTypeId,
          name,
          chit,
          ticketName,
          description,
          baseQty,
          price,
          priceDescription,
          isMeasured,
          deleteItem
        } = this
        this.updateItem({
          editingitem,
          restgroupid,
          itemTypeId,
          name,
          chit,
          ticketName,
          description,
          baseQty,
          price,
          priceDescription,
          isMeasured,
          deleteItem
        })
      } else {
        const {
          restgroupid,
          itemTypeId,
          name,
          chit,
          ticketName,
          description,
          baseQty,
          price,
          priceDescription,
          isMeasured
        } = this
        this.addItem({
          restgroupid,
          itemTypeId,
          name,
          chit,
          ticketName,
          description,
          baseQty,
          price,
          priceDescription,
          isMeasured
        })
      }
    }
  }
}
</script>
