import { menusService } from '../_services'

const state = {
  all: {},
  menutypes: [],
  idsNamesSelect: [],
  activemenu: {},
  addmenu: {},
  updatemenu: {},
  csmRequest: {}
}

const actions = {
  getAll ({ commit }, restGroupId, restId) {
    const stateName = 'all'
    commit('request', { stateName: stateName })

    menusService.getAll(restGroupId, restId)
      .then(
        menus => commit('getAllSuccess', menus.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getTypes ({ commit }, restGroupId) {
    const stateName = 'menutypes'
    commit('request', { stateName: stateName })

    menusService.getTypes(restGroupId)
      .then(
        types => commit('getTypesSuccess', types.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getById ({ commit }, id) {
    const stateName = 'activemenu'
    commit('request', { stateName: stateName })

    menusService.getById(id)
      .then(
        menu => commit('getByIdSuccess', menu.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  add ({ dispatch, commit }, restId, menuTypeId, name, description, isActive, isTemporary) {
    const stateName = 'addmenu'
    commit('request', { stateName: stateName })

    menusService.add(restId, menuTypeId, name, description, isActive, isTemporary)
      .then(
        menu => {
          commit('addSuccess', menu.data)
          dispatch('alert/success', 'Added Menu.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  update ({ dispatch, commit }, menuId, restId, menuTypeId, name, description, isActive, isTemporary) {
    const stateName = 'updateitem'
    commit('request', { stateName: stateName })

    menusService.update(menuId, restId, menuTypeId, name, description, isActive, isTemporary)
      .then(
        menu => {
          commit('updateSuccess', menu.data)
          dispatch('alert/success', 'Edited Menu.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getClientSideMenu ({ commit }, csmdata) {
    const stateName = 'csmRequest'
    commit('request', { stateName: stateName })

    if (csmdata.csmCall === 'displayMenu') {
      menusService.displayMenu(csmdata)
        .then(
          data => commit('csmenuSuccess', data),
          error => commit('failure', { stateName: stateName, error: error })
        )
    }
    if (csmdata.csmCall === 'transactionalMenu') {
      menusService.transactionalMenu(csmdata)
        .then(
          data => commit('csmenuSuccess', data),
          error => commit('failure', { stateName: stateName, error: error })
        )
    }
    if (csmdata.csmCall === 'menusubitemmodifiersets') {
      menusService.menusubitemmodifiersets(csmdata)
        .then(
          data => commit('csmenuSuccess', data),
          error => commit('failure', { stateName: stateName, error: error })
        )
    }
    if (csmdata.csmCall === 'menuitemimages') {
      menusService.menuitemimages(csmdata)
        .then(
          data => commit('csmenuSuccess', data),
          error => commit('failure', { stateName: stateName, error: error })
        )
    }
    if (csmdata.csmCall === 'menuaddendums') {
      menusService.menuaddendums(csmdata)
        .then(
          data => commit('csmenuSuccess', data),
          error => commit('failure', { stateName: stateName, error: error })
        )
    }
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  csmenuSuccess (state, data) {
    state.csmRequest = { data }
  },
  getAllSuccess (state, menus) {
    state.all = { menus }
    const selectData = []
    if (menus) {
      Array.from(menus).forEach(menu => {
        selectData.push({ code: menu.menu_id, label: menu.name })
      })
    }
    state.idsNamesSelect = selectData
  },
  getByIdSuccess (state, menu) {
    state.activemenu = menu
  },
  addSuccess (state, menu) {
    state.addmenu = menu
  },
  updateSuccess (state, menu) {
    state.updatemenu = menu
  },
  getTypesSuccess (state, types) {
    const selectData = []
    if (types) {
      Array.from(types).forEach(type => {
        selectData.push({ code: type.property_value_id, label: type.property })
      })
    }
    state.menutypes = selectData
  }
}

export const menus = {
  namespaced: true,
  state,
  actions,
  mutations
}
