import { modifierService } from '../_services'

const state = {
  all: {},
  modifiersIdsNamesSelect: {},
  activemodifier: {},
  addmodifier: {},
  updatemodifier: {},
  allsets: {},
  modifierSetsIdsNamesSelect: {},
  activemodifierset: {},
  addmodifierset: {},
  updatemodifierset: {},
  allsetmembers: {},
  activemodifiersetmember: {},
  addmodifiersetmember: {},
  updatemodifiersetmember: {},
  editmodifiersetmembers: '',
  allspecsets: {},
  activespecset: {},
  addspecset: {},
  updatespecset: {},
  allspecs: {},
  activespec: {},
  addspec: {},
  updatespec: {}
}

const actions = {
  getAll ({ commit }, restGroupId) {
    const stateName = 'all'
    commit('request', { stateName: stateName })

    modifierService.getAll(restGroupId)
      .then(
        modifiers => commit('getAllSuccess', modifiers.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getById ({ commit }, id) {
    const stateName = 'activemodifier'
    commit('request', { stateName: stateName })

    modifierService.getById(id)
      .then(
        modifier => commit('getByIdSuccess', modifier.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  add ({ dispatch, commit }, restGroupId, name, description) {
    const stateName = 'addmodifier'
    commit('request', { stateName: stateName })

    modifierService.add(restGroupId, name, description)
      .then(
        modifier => {
          commit('addSuccess', modifier.data)
          dispatch('alert/success', 'Added Modifer.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  update ({ dispatch, commit }, modifierId, restGroupId, name, description) {
    const stateName = 'updatemodifier'
    commit('request', { stateName: stateName })

    modifierService.update(modifierId, restGroupId, name, description)
      .then(
        modifier => {
          commit('updateSuccess', modifier.data)
          dispatch('alert/success', 'Edited Modifier.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getAllSets ({ commit }, restGroupId) {
    const stateName = 'allsets'
    commit('request', { stateName: stateName })

    modifierService.getAllSets(restGroupId)
      .then(
        sets => commit('getAllSetsSuccess', sets.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getSetById ({ commit }, id) {
    const stateName = 'activemodifierset'
    commit('request', { stateName: stateName })

    modifierService.getSetById(id)
      .then(
        set => commit('getSetByIdSuccess', set.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  addSet ({ dispatch, commit }, restGroupId, name, description) {
    const stateName = 'addmodifierset'
    commit('request', { stateName: stateName })

    modifierService.addSet(restGroupId, name, description)
      .then(
        set => {
          commit('addSetSuccess', set.data)
          dispatch('alert/success', 'Added Modifer Set.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  updateSet ({ dispatch, commit }, setId, restGroupId, name, description) {
    const stateName = 'updatemodifierset'
    commit('request', { stateName: stateName })

    modifierService.updateSet(setId, restGroupId, name, description)
      .then(
        set => {
          commit('updateSetSuccess', set.data)
          dispatch('alert/success', 'Edited Modifier Set.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getAllSetMembers ({ commit }, modifierSetId) {
    const stateName = 'allsetmembers'
    commit('request', { stateName: stateName })

    modifierService.getAllSetMembers(modifierSetId)
      .then(
        setmembers => commit('getAllSetMembersSuccess', setmembers.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getSetMemberById ({ commit }, id) {
    const stateName = 'activemodifiersetmember'
    commit('request', { stateName: stateName })

    modifierService.getSetMember(id)
      .then(
        setmember => commit('getSetMemberByIdSuccess', setmember.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  addSetMember ({ dispatch, commit }, modifierSetId, modifierId, association) {
    const stateName = 'addmodifiersetmember'
    commit('request', { stateName: stateName })

    modifierService.addSetMember(modifierSetId, modifierId, association)
      .then(
        setmember => {
          commit('addSetMemberSuccess', setmember.data)
          dispatch('alert/success', 'Added Modifer Set Member.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  updateSetMember ({ dispatch, commit }, setMemberId, modifierSetId, modifierId, association) {
    const stateName = 'updatemodifiersetmember'
    commit('request', { stateName: stateName })

    modifierService.updateSetMember(setMemberId, modifierSetId, modifierId, association)
      .then(
        setmember => {
          commit('updateSetMemberSuccess', setmember.data)
          dispatch('alert/success', 'Edited Modifier Set Member.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  editModifierSetMembers ({ dispatch, commit }, data) {
    commit('editModifierSetMembersRequest')
    if (!data.setId) {
      commit('editModifierSetMembersFail')
      dispatch('alert/error', 'Invalid Set Id.', { root: true })
    }
    if (!data.action) {
      commit('editModifierSetMembersFail')
      dispatch('alert/error', 'Invalid Action.', { root: true })
    }
    commit('editModifierSetMembers', data)
  },
  getAllSpecSets ({ commit }, restGroupId) {
    const stateName = 'allspecsets'
    commit('request', { stateName: stateName })

    modifierService.getAllSpecSets(restGroupId)
      .then(
        specsets => commit('getAllSpecSetsSuccess', specsets.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getSpecSetById ({ commit }, id) {
    const stateName = 'activespecset'
    commit('request', { stateName: stateName })

    modifierService.getSpecSetById(id)
      .then(
        specset => commit('getSpecSetByIdSuccess', specset.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  addSpecSet ({ dispatch, commit }, restGroupId, name, description, displayOrder) {
    const stateName = 'addspecset'
    commit('request', { stateName: stateName })

    modifierService.addSpecSet(restGroupId, name, description, displayOrder)
      .then(
        specset => {
          commit('addSpecSetSuccess', specset.data)
          dispatch('alert/success', 'Added Modifer Spec Set.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  updateSpecSet ({ dispatch, commit }, editingmodifierspecset, restGroupId, name, description, displayOrder, deleteModifierSpecSet) {
    const stateName = 'updatespecset'
    commit('request', { stateName: stateName })

    modifierService.updateSpecSet(editingmodifierspecset, restGroupId, name, description, displayOrder, deleteModifierSpecSet)
      .then(
        specset => {
          commit('updateSpecSetSuccess', specset.data)
          dispatch('alert/success', 'Edited Modifier Spec Set.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getAllSpecs ({ commit }, modifierSpecSetId) {
    const stateName = 'allspecs'
    commit('request', { stateName: stateName })

    modifierService.getAllSpecs(modifierSpecSetId)
      .then(
        specs => commit('getAllSpecsSuccess', specs.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getSpecById ({ commit }, id) {
    const stateName = 'activespec'
    commit('request', { stateName: stateName })

    modifierService.getSpecById(id)
      .then(
        spec => commit('getSpecByIdSuccess', spec.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  addSpec ({ dispatch, commit }, modifierSpecSetId, name, description, displayOrder, isDefault, isMeasured) {
    const stateName = 'addspec'
    commit('request', { stateName: stateName })

    modifierService.addSpec(modifierSpecSetId, name, description, displayOrder, isDefault, isMeasured)
      .then(
        spec => {
          commit('addSpecSuccess', spec.data)
          dispatch('alert/success', 'Added Modifer Spec.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  updateSpec ({ dispatch, commit }, editingmodifierspec, modifierSpecSetId, name, description, displayOrder, isDefault, isMeasured, deleteModifierSpec) {
    const stateName = 'updatespec'
    commit('request', { stateName: stateName })

    modifierService.updateSpec(editingmodifierspec, modifierSpecSetId, name, description, displayOrder, isDefault, isMeasured, deleteModifierSpec)
      .then(
        spec => {
          commit('updateSpecSuccess', spec.data)
          dispatch('alert/success', 'Edited Modifier Spec.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  editModifierSetMembers (state, data) {
    state.editmodifiersetmembers = ''
    if (data.action === 'view') {
      state.editmodifiersetmembers = data.setId
    }
  },
  editModifierSetMembersRequest (state) {
    state.editmodifiersetmembers = ''
  },
  editModifierSetMembersFail (state) {
    state.editmodifiersetmembers = ''
  },
  getAllSuccess (state, modifiers) {
    state.all = { modifiers }
    const selectData = []
    Array.from(modifiers).forEach(modifier => {
      selectData.push({ code: modifier.modifier_id, label: modifier.name })
    })
    state.modifiersIdsNamesSelect = selectData
  },
  getByIdSuccess (state, modifier) {
    state.activemodifier = modifier
  },
  addSuccess (state, modifier) {
    state.addmodifier = modifier
  },
  updateSuccess (state, modifier) {
    state.updatemodifier = modifier
  },
  getAllSetsSuccess (state, sets) {
    state.allsets = { sets }
    const selectData = []
    Array.from(sets).forEach(set => {
      selectData.push({ code: set.modifier_set_id, label: set.name })
    })
    state.modifierSetsIdsNamesSelect = selectData
  },
  getSetByIdSuccess (state, set) {
    state.activemodifierset = set
  },
  addSetSuccess (state, set) {
    state.addmodifierset = set
  },
  updateSetSuccess (state, set) {
    state.updatemodifierset = set
  },
  getAllSetMembersSuccess (state, sets) {
    state.allsetmembers = { sets }
  },
  getSetMemberByIdSuccess (state, setmember) {
    state.activemodifiersetmember = setmember
  },
  addSetMemberSuccess (state, setmember) {
    state.addmodifiersetmember = setmember
  },
  updateSetMemberSuccess (state, setmember) {
    state.updatemodifiersetmember = setmember
  },
  getAllSpecSetsSuccess (state, specsets) {
    state.allspecsets = { specsets }
  },
  getSpecSetByIdSuccess (state, specset) {
    state.activespecset = specset
  },
  addSpecSetSuccess (state, specset) {
    state.addspecset = specset
  },
  updateSpecSetSuccess (state, specset) {
    state.updatespecset = specset
  },
  getAllSpecsSuccess (state, specs) {
    state.allspecs = { specs }
  },
  getSpecByIdSuccess (state, spec) {
    state.activespec = spec
  },
  addSpecSuccess (state, spec) {
    state.addspec = spec
  },
  updateSpecSuccess (state, spec) {
    state.updatespec = spec
  }
}

export const modifiers = {
  namespaced: true,
  state,
  actions,
  mutations
}
