export function resetData () {
  const data = {
    operator: {},
    brand: {
      name: '',
      description: ''
    },
    restaurant_group: {},
    restaurant: [],
    activeRestaurant: '',
    restaurant_sales_tax: {},
    menu: [],
    activeMenu: '',
    menu_section: [],
    activeMenuSection: '',
    item: [],
    activeMenuItem: '',
    menu_item: {},
    menu_section_item: {},
    modifier_set: [],
    activeModifierSet: '',
    modifier: [],
    activeModifier: '',
    modifier_set_member: {},
    item_modifier_set: {},
    item_modifier: {},
    menu_item_modifier_set: {},
    menu_item_modifier: {},
    restOperationType: false,
    display: {
      restOperationType: true,
      brandInformation: false,
      restInfo: false
    }
  }
  return data
}
