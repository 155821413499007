<template>
  <div class="component-container restaurants-operated">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 middle-divider">
          <h1 class="mb-3">How many Restaurants do you operate?</h1>
          <p>You might have one or multiple locations you need manage menus for.</p>
        </div>
        <div class="col-md-6">
          <div class="container-fluid">
            <div class="row">
              <div class="col pt-3">
                <button type="button"
                  class="btn btn-lg btn-block px-3 text-left mb-3"
                  :class="{ 'btn-success' : getRestOp() === 1, 'btn-primary' : getRestOp() !== 1 }"
                  v-on:click="setRestaurantsOperated(1)">
                  <mdb-icon v-if="getRestOp() === 1" far icon="check-square" size='lg' />
                  <mdb-icon v-else far icon="square" size='lg' /> One Restaurant
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button type="button"
                  class="btn btn-lg btn-block px-3 text-left mb-3"
                  :class="{ 'btn-success' : getRestOp() === 2, 'btn-primary' : getRestOp() !== 2 }"
                  v-on:click="setRestaurantsOperated(2)">
                  <mdb-icon v-if="getRestOp() === 2" far icon="check-square" size='lg' />
                  <mdb-icon v-else far icon="square" size='lg' /> Multiple Restaurants
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 text-left">
          <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.goToPrevComponent()"><mdb-icon icon="arrow-alt-circle-left" size='lg' /> Go Back</button>
        </div>
        <div class="col-md-6 text-right">
           <button v-if="$parent.setup.restaurantsOperated !== 0" type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.holdingCompanyComponent()">Next Question <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mdbIcon } from 'mdbvue'
export default {
  name: 'restaurantsOperated',
  components: { mdbIcon },
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {}
  },
  watch: {},
  methods: {
    setRestaurantsOperated (value) {
      if (value === this.getRestOp()) {
        value = 0
      }
      const data = {
        objectName: 'setup',
        property: 'restaurantsOperated',
        value: value
      }
      this.$parent.updateStateObject(data)
    },
    getRestOp () {
      return this.$parent.setup.restaurantsOperated
    }
  },
  computed: {}
}
</script>
