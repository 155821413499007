import Vue from 'vue'
import Router from 'vue-router'

import LoginPage from '../login/LoginPage'
// import HomePage from '../home/HomePage'
import OperatorsPage from '../operators/OperatorsPage'
import BrandsPage from '../brands/BrandsPage'
import RestaurantGroupRestaurantPage from '../restaurantgroups/restaurants/Restaurant'
import RestaurantGroupRestaurantMenuPage from '../restaurantgroups/restaurants/menus/Menu'
import RestaurantGroupsPage from '../restaurantgroups/RestaurantGroupsPage'
import ManageRestaurantGroupPage from '../restaurantgroups/ManageRestaurantGroupPage'
import RestaurantsPage from '../restaurants/RestaurantsPage'
import MenuSetupPage from '../menusetup/MenuSetupPage'
import MenuSetupPagev2 from '../menusetupv2/MenuSetupPage'
import GetSetupManager from '../getsetupv1/GetSetupManager.vue'
import OwnerPortalManager from '../ownerportalv1/OwnerPortalManager.vue'

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', component: RestaurantGroupsPage },
    { path: '/login', component: LoginPage },
    { path: '/operators', component: OperatorsPage },
    { path: '/brands', component: BrandsPage },
    { path: '/restaurantgroups', component: RestaurantGroupsPage },
    { path: '/menusetup', component: MenuSetupPage },
    { path: '/menusetupv2', component: MenuSetupPagev2 },
    { path: '/getsetupv1', component: GetSetupManager },
    { path: '/ownerportalv1', component: OwnerPortalManager },
    { path: '/restaurantgroups/manage/:id', component: ManageRestaurantGroupPage, name: 'managerestgroup' },
    { path: '/restaurantgroups/manage/:id/component/:componentName', component: ManageRestaurantGroupPage, name: 'managerestgroupcomponent' },
    { path: '/restaurantgroups/manage/:restGroupId/restaurant/:restId', component: RestaurantGroupRestaurantPage, name: 'managerestgrouprestaurant' },
    { path: '/restaurantgroups/manage/:restGroupId/restaurant/:restId/menu/:menuId', component: RestaurantGroupRestaurantMenuPage, name: 'managerestgrouprestaurantmenu' },
    { path: '/restaurantgroups/manage/:restGroupId/restaurant/:restId/menu/:menuId/component/:componentName', component: RestaurantGroupRestaurantMenuPage, name: 'managerestgrouprestaurantmenucomponent' }, { path: '/restaurants', component: RestaurantsPage },
    { path: '*', redirect: '/' }
  ]
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  if (authRequired && !loggedIn) {
    return next('/login')
  }

  next()
})
