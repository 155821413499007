<template>
    <div class="page menu-display-page">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <div class="btn-group btn-group-sm mb-2 ml-2" role="group">
              <button v-if="editMenu" type="button" class="btn btn-primary" v-on:click="addSection(activemenu.menu_id, false)">Add Section</button>
              <button v-if="editMenu" type="button" class="btn btn-primary" v-on:click="orderSections()">Order Sections</button>
              <button v-if="!editMenu" type="button" class="btn btn-primary" v-on:click="menuEditor()"><b-icon-pencil-square></b-icon-pencil-square> Edit Menu</button>
              <button v-if="editMenu" type="button" class="btn btn-danger" v-on:click="menuEditor()"><b-icon-x-circle-fill></b-icon-x-circle-fill> Close Editor</button>
            </div>
          </div>
          <div class="col text-right">
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="show">Show</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="showItems" value="showItems" v-on:click="showMenuComponents('items')" :checked="showItems">
              <label class="form-check-label" for="showItems">Items</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="showModifiers" value="showModifiers" v-on:click="showMenuComponents('modifiers')" :checked="showModifiers">
              <label class="form-check-label" for="showModifiers">Modifiers</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="showAddendums" value="showAddendums" v-on:click="showMenuComponents('addendums')" :checked="showAddendums">
              <label class="form-check-label" for="showAddendums">Addendums</label>
            </div>
          </div>
        </div>
      </div>
      <div class="shadow p-3 m-2">
        <h5>{{ activemenu.name }}</h5>
        <div v-if="showAddendums && activemenu.addendums" class="menu-header-addendums">
          <div v-if="Object.keys(activemenu.addendums.header).length != 0">
            <p v-for="headerAddendum in activemenu.addendums.header" :key="headerAddendum.menu_addendum_id">
              {{ headerAddendum.line_text}}
            </p>
          </div>
        </div>
        <div v-if="activemenusections">
          <div v-for="section in activemenusections" :key="section.menu_section_id">
            <div class="container-fluid section-header">
              <div class="row">
                <div class="col-1" v-show="changeSectionOrder">
                  <span class='section-links'>
                    <a v-on:click="sectionUp(section.menu_section_id)"><b-icon-caret-up-square-fill></b-icon-caret-up-square-fill></a>
                    <a v-on:click="sectionDown(section.menu_section_id)"><b-icon-caret-down-square-fill></b-icon-caret-down-square-fill></a>
                  </span>
                </div>
                <div class="col">
                  <h5>{{ section.name }}</h5>
                  <div v-if="showAddendums" class="menu-section-header-addendums">
                    <div v-if="Object.keys(section.addendums.header).length != 0">
                      <p v-for="sectionHeaderAddendum in section.addendums.header" :key="sectionHeaderAddendum.menu_section_addendum_id">
                        {{ sectionHeaderAddendum.line_text}}
                      </p>
                    </div>
                  </div>
                  <div v-if="editMenu" class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-primary" v-on:click="addItems(section.menu_section_id )" v-if="showItems">Add Items</button>
                    <button type="button" class="btn btn-primary" v-on:click="addSection(section.menu_section_id, true)">Add Section</button>
                    <button type="button" class="btn btn-primary" v-if="showItems" v-on:click="orderSectionItems(section.menu_section_id)">Order Items</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="menuItems" v-if="Object.keys(section.menuItems).length != 0 && showItems">
              <div class="container-fluid">
                <div class="row item-list-item" v-for="item in section.menuItems" :key="item.menu_section_item_id">
                  <div v-if="showAddendums" class="menu-item-header-addendums col-12">
                    <div v-if="Object.keys(item.addendums.header).length != 0">
                      <p v-for="itemHeaderAddendum in item.addendums.footer" :key="itemHeaderAddendum.menu_section_item_addendum_id">
                        {{ itemHeaderAddendum.line_text}}
                      </p>
                    </div>
                  </div>
                  <div class="col-1" v-if="changeSectionItemsOrder === section.menu_section_id">
                    <a v-on:click="sectionItemUp(item.menu_section_item_id)"><b-icon-caret-up-square-fill></b-icon-caret-up-square-fill></a>
                    <a v-on:click="sectionItemDown(item.menu_section_item_id)"><b-icon-caret-down-square-fill></b-icon-caret-down-square-fill></a>
                  </div>
                  <div class="col-4">{{ item.item_name }}</div>
                  <div class="col-4">{{ item.description }}</div>
                  <div class="col-3">{{ item.price | toCurrency }}</div>
                  <div v-if="showAddendums" class="menu-item-footer-addendums col-12">
                    <div v-if="Object.keys(item.addendums.footer).length != 0">
                      <p v-for="itemFooterAddendum in item.addendums.footer" :key="itemFooterAddendum.menu_section_item_addendum_id">
                        {{ itemFooterAddendum.line_text}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="subitems" v-if="Object.keys(section.subItems).length != 0">
              <div v-for="subSection in section.subItems" :key="subSection.menu_section_id">
                <div class="container-fluid section-header">
                  <div class="row">
                    <div class="col-1" v-show="changeSectionOrder">
                      <span class='section-links'>
                        <a v-on:click="sectionUp(subSection.menu_section_id)"><b-icon-caret-up-square-fill></b-icon-caret-up-square-fill></a>
                        <a v-on:click="sectionDown(subSection.menu_section_id)"><b-icon-caret-down-square-fill></b-icon-caret-down-square-fill></a>
                      </span>
                    </div>
                    <div class="col">
                      <h5>
                        {{ subSection.name }}
                      </h5>
                      <div v-if="showAddendums" class="menu-subsection-header-addendums">
                        <div v-if="Object.keys(subSection.addendums.header).length != 0">
                          <p v-for="subSectionHeaderAddendum in subSection.addendums.footer" :key="subSectionHeaderAddendum.menu_section_addendum_id">
                            {{ subSectionHeaderAddendum.line_text}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="menuItems"  v-if="Object.keys(subSection.menuItems).length != 0 && showItems">
                  <div class="container-fluid">
                    <div class="row item-list-item" v-for="item in subSection.menuItems" :key="item.menu_section_item_id">
                      <div v-if="showAddendums" class="menu-item-header-addendums col-12">
                        <div v-if="Object.keys(item.addendums.header).length != 0">
                          <p v-for="itemHeaderAddendum in item.addendums.footer" :key="itemHeaderAddendum.menu_section_item_addendum_id">
                            {{ itemHeaderAddendum.line_text}}
                          </p>
                        </div>
                      </div>
                      <div class="col-1" v-if="changeSectionItemsOrder === section.menu_section_id">
                        <a v-on:click="sectionItemUp(item.menu_section_item_id)"><b-icon-caret-up-square-fill></b-icon-caret-up-square-fill></a>
                        <a v-on:click="sectionItemDown(item.menu_section_item_id)"><b-icon-caret-down-square-fill></b-icon-caret-down-square-fill></a>
                      </div>
                      <div class="col-4">{{ item.item_name }}</div>
                      <div class="col-4">{{ item.description }}</div>
                      <div class="col-3">{{ toCurrency(item.price) }}</div>
                      <div v-if="showAddendums" class="menu-item-footer-addendums col-12">
                        <div v-if="Object.keys(item.addendums.footer).length != 0">
                          <p v-for="itemFooterAddendum in item.addendums.footer" :key="itemFooterAddendum.menu_section_item_addendum_id">
                            {{ itemFooterAddendum.line_text}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showAddendums" class="menu-subsection-footer-addendums">
                  <div v-if="Object.keys(subSection.addendums.footer).length != 0">
                    <p v-for="subSectionFooterAddendum in subSection.addendums.footer" :key="subSectionFooterAddendum.menu_section_addendum_id">
                      {{ subSectionFooterAddendum.line_text}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showAddendums" class="menu-section-footer-addendums">
              <div v-if="Object.keys(section.addendums.footer).length != 0">
                <p v-for="sectionFooterAddendum in section.addendums.footer" :key="sectionFooterAddendum.menu_section_addendum_id">
                  {{ sectionFooterAddendum.line_text}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showAddendums && activemenu.addendums" class="menu-footer-addendums">
          <div v-if="Object.keys(activemenu.addendums.footer).length != 0">
            <p v-for="footerAddendum in activemenu.addendums.footer" :key="footerAddendum.menu_addendum_id">
              {{ footerAddendum.line_text}}
            </p>
          </div>
        </div>
      </div>
    </div>
</template>
<style lang="scss" scoped>
  .menu-display-page {
    .item-list-header {
      color: #fff;
      background-color: #343a40;
      border-color: #454d55;
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
      padding: .75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
    }
    .item-list-item {
      padding: .75rem;
    }
    .section-header {
      border-bottom: 1px solid #ccc;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
    .section-links a {
      padding-left: 1rem;
      color: #007bff;
      text-decoration: none;
      background-color: transparent;
      font-size: 1rem;
      &:hover {
      color: #0056b3;
      text-decoration: underline;
      }
    }
    .subitems {
      padding-left: 3rem;
      .section-header {
        padding: 0;
        h5 {
          font-size: 1rem;
        }
      }
    }
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'MenuDisplay',
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
      editMenu: false,
      unsavedChanges: false,
      menuName: '',
      sections: {},
      activeSection: '',
      activeSectionLinks: '',
      activeSubSectionLinks: '',
      showItems: true,
      showModifiers: true,
      showAddendums: true,
      changeSectionOrder: false,
      changeSectionItemsOrder: false
    }
  },
  watch: {
  },
  computed: {
    ...mapState({
      menuitems: state => state.menuitems.all,
      restgroupitems: state => state.items.all,
      menusections: state => state.menusections.all,
      activemenusections: state => state.menusections.active,
      activerestaurant: state => state.restaurants.activerestaurant,
      activerestgroup: state => state.restgroups.activerestgroup,
      activemenu: state => state.menus.activemenu
    })
  },
  created () {
    const { restGroupId, restId, menuId } = this.componentProps
    this.getMenuSections({ restGroupId, restId, menuId })
    this.getActiveMenuSections({ restGroupId, restId, menuId })
  },
  mounted () {
  },
  methods: {
    ...mapActions('menusections', {
      getMenuSections: 'getAll',
      getActiveMenuSections: 'getActive'
    }),
    ...mapActions('menus', {
      getMenu: 'getById'
    }),
    toCurrency (value) {
      if (isNaN(parseFloat(value))) {
        return value
      }
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      return formatter.format(value)
    },
    addItems (id) {
      this.reset()
      console.log(id)
    },
    addSection (id, subSection = false) {
      this.reset()
      console.log(id)
      if (subSection) {
        console.log('This is a subsection.')
      }
    },
    reset (except) {
      if (except !== 'changeSectionOrder') {
        this.changeSectionOrder = false
      }
      if (except !== 'changeSectionItemsOrder') {
        this.changeSectionItemsOrder = false
      }
    },
    showMenuComponents (component) {
      if (component === 'items') {
        if (this.showItems) {
          this.showItems = false
        } else {
          this.showItems = true
        }
      }
      if (component === 'modifiers') {
        if (this.showModifiers) {
          this.showModifiers = false
        } else {
          this.showModifiers = true
        }
      }
      if (component === 'addendums') {
        if (this.showAddendums) {
          this.showAddendums = false
        } else {
          this.showAddendums = true
        }
      }
      if (component === 'all') {
        this.showItems = true
        this.showModifiers = true
        this.showAddendums = true
      }
      if (component === 'none') {
        this.showItems = false
        this.showModifiers = false
        this.showAddendums = false
      }
    },
    menuEditor () {
      this.reset()
      this.showMenuComponents('all')
      if (this.editMenu) {
        this.editMenu = false
        return
      }
      this.editMenu = true
    },
    orderSections () {
      this.changeSectionItemsOrder = false
      this.activeSection = ''
      if (this.changeSectionOrder) {
        this.showMenuComponents('all')
        this.changeSectionOrder = false
        return
      }
      this.showMenuComponents('none')
      this.changeSectionOrder = true
    },
    sectionUp (sectionId) {
      console.log(sectionId)
    },
    sectionDown (sectionId) {
      console.log(sectionId)
    },
    orderSectionItems (sectionId) {
      this.changeSectionOrder = false
      if (this.changeSectionItemsOrder === sectionId) {
        this.changeSectionItemsOrder = false
        return
      }
      this.changeSectionItemsOrder = sectionId
    },
    sectionItemUp (menuSectionItemId) {
      console.log(menuSectionItemId)
    },
    sectionItemDown (menuSectionItemId) {
      console.log(menuSectionItemId)
    },
    scrollToTop (elementId = null) {
      var top = 0
      if (elementId) {
        var element = document.getElementById(elementId)
        top = element.offsetTop
      }
      window.scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
