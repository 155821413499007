<template>
    <div class="modifier-set-members page">
        <h6 class="mt-3">Active Set Modifiers</h6>
        <div v-if="groupmodifiersetmembers.sets" class="modifiersetmembers-list">
            <table class="table table-striped">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Remove</th>
                  <th scope="col">Modifier</th>
                  <th scope="col">Descripiton</th>
                </tr>
              </thead>
              <tbody>
                    <tr v-for="modifiersetmember in groupmodifiersetmembers.sets" :key="modifiersetmember.id">
                      <td class="text-center">
                        <button class="btn btn-sm btn-danger" v-on:click="removeModifierSetMemberButton(modifiersetmember)">Remove</button>
                      </td>
                      <td>{{modifiersetmember.modifier_name}}</td>
                      <td>{{modifiersetmember.modifier_description}}</td>
                    </tr>
              </tbody>
            </table>
        </div>
        <div v-else >
          <p>No active modifiers.</p>
        </div>
    </div>
</template>
<style lang="scss" scoped>
  .modifier-set-members {
    margin-top: 0;
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ModifierSetMembers',
  props: {
    editmodifiersetmembers: {
      required: true
    }
  },
  computed: {
    ...mapState({
      groupmodifiersetmembers: state => state.modifiers.allsetmembers,
      addmodifiersetmember: state => state.modifiers.addmodifiersetmember,
      updatemodifiersetmember: state => state.modifiers.updatemodifiersetmember,
      modifiersIdsNamesSelect: state => state.modifiers.modifiersIdsNamesSelect,
      activemodifierset: state => state.modifiers.activemodifierset
    })
  },
  created () {
    this.getModifierSetMembers(this.editmodifiersetmembers)
    this.getModifierSet(this.editmodifiersetmembers)
  },
  watch: {
    editmodifiersetmembers: {
      handler (val, oldVal) {
        this.getModifierSetMembers(this.editmodifiersetmembers)
      }
    },
    addmodifiersetmember: {
      handler (val, oldVal) {
        if (val.modifier_set_member_id) {
          this.getModifierSetMembers(this.editmodifiersetmembers)
        }
      }
    },
    updatemodifiersetmember: {
      handler (val, oldVal) {
        if (val.modifier_set_member_id) {
          this.getModifierSetMembers(this.editmodifiersetmembers)
        }
      }
    }
  },
  methods: {
    ...mapActions('modifiers', {
      getModifierSetMembers: 'getAllSetMembers',
      updateModifierSetMember: 'updateSetMember',
      getModifierSet: 'getSetById'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    removeModifierSetMemberButton (setmember) {
      const data = {
        editingmodifiersetmember: setmember.modifier_set_member_id,
        modifiersetid: setmember.modifier_set_id,
        modifierid: setmember.modifier_id,
        modifiersetmemberassociation: setmember.association,
        deletemodifiersetmember: true
      }
      this.updateModifierSetMember(data)
    }
  }
}
</script>
