<template>
    <div class="menusetup-page" :class="{ 'page' : !fullScreen }">
      <div class="container-fluid">
        <div class="row">
          <nav id="sidebarMenu" class="col-md-2 bg-light">
            <div class="sidebar-sticky pt-3">
              <ul class="nav flex-column">
                <li class="nav-item mb-2">
                  <a class="nav-link btn btn-block btn-primary text-right" :class="{ 'active' : activeNav === 'restaurantAdmin' }" v-on:click="restaurantAdminComponent">Restaurant Admin</a>
                </li>
                <li class="nav-item mb-2">
                  <a class="nav-link btn btn-block btn-primary text-right" :class="{ 'active' : activeNav === 'menuStructure' }" v-on:click="menuStructureComponent('')">Menu Structure</a>
                </li>
                <li class="nav-item mb-2">
                  <a class="nav-link btn btn-block btn-primary text-right" :class="{ 'active' : activeNav === 'menuItems'}" v-on:click="menuItemsComponent">Menu Items</a>
                </li>
              </ul>
            </div>
          </nav>
          <main role="main" class="col-md-10" :key="renderer">
            <component v-bind:is="component" :componentProps="childComponentProps" />
          </main>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <hr>
            <a class="btn btn-sm btn-primary" v-if="!showDevOptions" v-on:click="toggleDevOptions(true)">Show Dev Options</a>
            <a class="btn btn-sm btn-danger" v-if="showDevOptions" v-on:click="toggleDevOptions(false)">Close Dev Options</a>
            <a class="btn btn-sm btn-danger float-right" v-if="fullScreen" v-on:click="setFullScreen(false)">Close Full Screen Mode</a>
            <a class="btn btn-sm btn-primary float-right" v-if="!fullScreen" v-on:click="setFullScreen(true)">Enable Full Screen Mode</a>
            <hr>
          </div>
        </div>
        <div class="row" v-if="showDevOptions">
          <div class="col">
            <p>Load Test Data <button class="btn btn-primary" v-on:click="loadTestData('single')">Single Restaurant</button> <button class="btn btn-primary" v-on:click="loadTestData('multiple')">Multiple Restaurants</button> <button class="btn btn-danger" v-on:click="loadTestData('reset')">Reset Data</button></p>
            <p>Data Store</p>
            <pre>
              {{menussetupv2}}
            </pre>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import $ from 'jquery'
import restaurantAdmin from './child-components/restaurantAdmin'
import restaurantBrand from './child-components/restaurantBrand'
import menuStructure from './child-components/menuStructure'
import menuItems from './child-components/menuItems'

export default {
  name: 'MenuSetupPagev2',
  components: { restaurantAdmin, menuStructure, menuItems, restaurantBrand },
  data () {
    return {
      showDevOptions: false,
      component: restaurantAdmin,
      childComponentProps: {},
      activeNav: 'restaurantAdmin',
      renderer: 1
    }
  },
  methods: {
    toggleDevOptions (show) {
      if (show === true) {
        this.showDevOptions = true
        return
      }
      this.showDevOptions = false
    },
    loadTestData (type) {
      this.importTestData(type)
      this.showDevOptions = false
      this.renderer++
    },
    openModal (modalId) {
      $('#' + modalId).modal('show')
    },
    closeModal (modalId) {
      $('#' + modalId).modal('hide')
    },
    uuid () {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      )
    },
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    ...mapActions('menussetupv2', {
      setFullScreen: 'setFullScreen',
      importTestData: 'importTestData',
      addItemImportDataToStore: 'addItemImportDataToStore',
      updateState: 'updateState',
      deleteStateArrayItem: 'deleteStateArrayItem',
      updateStateArrayItem: 'updateStateArrayItem'
    }),
    restaurantAdminComponent (event) {
      this.clearAlert()
      this.component = restaurantAdmin
      this.activeNav = 'restaurantAdmin'
    },
    restaurantBrandComponent (brandId) {
      this.clearAlert()
      this.childComponentProps = { brandId: brandId }
      this.component = restaurantBrand
      this.activeNav = 'restaurantBrand'
    },
    menuStructureComponent (menuId) {
      this.clearAlert()
      this.childComponentProps = { menuId: menuId }
      this.component = menuStructure
      this.activeNav = 'menuStructure'
    },
    menuItemsComponent (event) {
      this.clearAlert()
      this.component = menuItems
      this.activeNav = 'menuItems'
    }
  },
  created () {
    this.setFullScreen(true)
  },
  destroyed () {
    this.setFullScreen(false)
  },
  computed: {
    ...mapState({
      menussetupv2: state => state.menussetupv2,
      fullScreen: state => state.menussetupv2.fullScreen,
      menus: state => state.menussetupv2.menu,
      restaurantsOperated: state => state.menussetupv2.restaurantsOperated,
      holdingCompany: state => state.menussetupv2.holdingCompany
    })
  }
}
</script>
<style lang="scss">
  .container {
      border-radius: 10px;
      padding: 20px;
  }

  .container>p {
      font-size: 32px
  }

  .question {
      width: 75%
  }

  .options {
      position: relative;
      padding-left: 40px
  }

  #optionsContainer label {
      display: block;
      margin-bottom: 15px;
      font-size: 14px;
      cursor: pointer
  }

  .options input {
      opacity: 0
  }

  .checkmark {
      position: absolute;
      top: -1px;
      left: 0;
      height: 25px;
      width: 25px;
      border: 1px solid #212529;
      border-radius: 50%
  }

  .options input:checked~.checkmark:after {
      display: block
  }

  .options .checkmark:after {
      content: "";
      width: 10px;
      height: 10px;
      display: block;
      background: white;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: 300ms ease-in-out 0s
  }

  .options input[type="radio"]:checked~.checkmark {
      background: #e74c3c;
      transition: 300ms ease-in-out 0s
  }

  .options input[type="radio"]:checked~.checkmark:after {
      transform: translate(-50%, -50%) scale(1)
  }

  /*
  .btn-primary {
      background-color: #2c3e50;
      color: #ddd;
      border: 1px solid #ddd
  }

  .btn-primary:hover {
      background-color: #e74c3c;
      border: 1px solid #e74c3c
  }

  .btn-success {
      padding: 5px 25px;
      background-color: #e74c3c
  }
  */

  @media(max-width:576px) {
      .question {
          width: 100%;
          word-spacing: 2px
      }
  }
</style>
