<template>
    <div class="modifier-spec" id="modifier-spec-list">
        <h6>Modifier Sets <button v-on:click="addModifierSpecButton"  class="btn btn-sm btn-primary btn-sm">Add +</button></h6>
        <div class="modifierspec-form" v-if="modifierspecform">
            <p>{{formtitle}}</p>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 mb-3">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" id="name" placeholder="" v-model="name">
                        <div class="input-error" v-if="msg.errors.name">
                            Valid modifier spec set name is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mb-3">
                        <label for="description">Description</label>
                        <input type="text" class="form-control" id="description" placeholder="" v-model="description">
                        <div class="input-error" v-if="msg.errors.description">
                            Valid modifier spec set Description is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mb-3">
                        <label for="displayOrder">Display Order</label>
                        <input type="text" class="form-control" id="displayOrder" placeholder="" v-model="displayOrder">
                        <div class="input-error" v-if="msg.errors.displayOrder">
                            Valid modifier spec set displayOrder is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 mb-3 text-right">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="isMeasured" id="isMeasured">
                      <label class="form-check-label" for="isMeasured">
                        Is measured?
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 mb-3 text-right">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="isDefault" id="isDefault">
                      <label class="form-check-label" for="isDefault">
                        Is default?
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="editingmodifierspec">
                  <div class="col-sm-12 mb-3 deletecontrol text-right">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="deleteModifierSpec" id="deleteModifierSpec">
                      <label class="form-check-label" for="deleteModifierSpec">
                        Delete this Modifier Spec?
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mb-3 text-left form-controls">
                        <button v-on:click="saveModifierSpecFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                        <button v-on:click="closeModifierSpecFormButton" class="btn btn-sm btn-danger">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="'specs' in groupmodifierspecs" class="specs-list">
            <table class="table table-striped">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Display Order</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Is Measured</th>
                  <th scope="col">Is Default</th>
                  <th scope="col">Edit</th>
                </tr>
              </thead>
              <tbody>
                    <tr v-for="spec in groupmodifierspecs.specs"
                        :key="spec.modifier_spec_id"
                        :class="spec.modifier_spec_id">
                      <td>{{spec.modifier_spec_id}}</td>
                      <td>{{spec.display_order}}</td>
                      <td>{{spec.name}}</td>
                      <td>{{spec.description}}</td>
                      <td>{{spec.is_measured}}</td>
                      <td>{{spec.is_default}}</td>
                      <td>
                        <p><button v-on:click="editModifierSpecButton(spec.modifier_spec_id)" class="btn btn-block btn-sm btn-primary">Edit</button></p>
                      </td>
                    </tr>
              </tbody>
            </table>
        </div>
        <div v-else>
            No spec sets available.
        </div>
      </div>
</template>
<style lang="scss" scoped>
  .specs-list {
    .green-border {
      border: 3px solid green;
    }
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ModifierSpecs',
  props: {
    modifierSpecSetId: {
      required: true
    }
  },
  data () {
    return {
      formtitle: 'Add new Modifier Spec',
      formsavetext: 'Save',
      modifierspecform: false,
      name: '',
      description: '',
      displayOrder: 0,
      isMeasured: 0,
      isDefault: 0,
      editingmodifierspec: '',
      deleteModifierSpec: false,
      msg: {
        errors: {
          name: false,
          description: false,
          displayOrder: false
        }
      }
    }
  },
  computed: {
    ...mapState({
      groupmodifierspecs: state => state.modifiers.allspecs,
      activespec: state => state.modifiers.activespec,
      addspec: state => state.modifiers.addspec,
      updatespec: state => state.modifiers.updatespec
    })
  },
  created () {
    this.getSpecs(this.modifierSpecSetId)
  },
  watch: {
    activespec: {
      handler (val, oldVal) {
        this.name = val.name
        this.description = val.description
        this.displayOrder = val.display_order
        this.isMeasured = val.is_measured
        this.isDefault = val.is_default
        this.editingmodifierspec = val.modifier_spec_id
      }
    },
    name: {
      handler (val, oldVal) {
        this.validateModifierSpecForm()
      }
    },
    description: {
      handler (val, oldVal) {
        this.validateModifierSpecForm()
      }
    },
    displayOrder: {
      handler (val, oldVal) {
        this.validateModifierSpecForm()
      }
    },
    addspec: {
      handler (val, oldVal) {
        if (val.modifier_spec_id) {
          this.getSpecs(this.modifierSpecSetId)
          this.ModifierSpecFormReset()
          this.closeModifierSpecFormButton()
        }
      }
    },
    updatespec: {
      handler (val, oldVal) {
        if (val.modifier_spec_id) {
          this.getSpecs(this.modifierSpecSetId)
          this.ModifierSpecFormReset()
          this.closeModifierSpecFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('modifiers', {
      getSpecs: 'getAllSpecs',
      getSpec: 'getSpecById',
      updateSpec: 'updateSpec',
      addSpec: 'addSpec'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    scrollToTop (elementId = null) {
      var top = 0
      if (elementId) {
        var element = document.getElementById(elementId)
        top = element.offsetTop
      }
      window.scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    },
    ModifierSpecFormReset (event) {
      this.formtitle = 'Add new Modifier Spec'
      this.name = ''
      this.description = ''
      this.editingmodifierspec = ''
      this.displayOrder = 0
      this.isMeasured = 0
      this.isDefault = 0
      this.deleteModifierSpec = false
    },
    closeModifierSpecFormButton (event) {
      this.modifierspecform = false
    },
    addModifierSpecButton (event) {
      this.clearAlert()
      this.ModifierSpecFormReset()
      if (this.modifierspecform) {
        this.closeModifierSpecFormButton()
        return
      }
      this.modifierspecform = true
    },
    editModifierSpecButton (id) {
      this.clearAlert()
      this.getSpec(id)
      this.modifierspecform = true
      this.formtitle = 'Edit Modifier Spec'
      this.scrollToTop('modifier-spec-list')
    },
    validateModifierSpecForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    saveModifierSpecFormButton (event) {
      this.clearAlert()
      if (this.validateModifierSpecForm() === false) {
        return
      }

      if (this.editingmodifierspec) {
        const {
          modifierSpecSetId,
          editingmodifierspec,
          name,
          description,
          displayOrder,
          isMeasured,
          isDefault,
          deleteModifierSpec
        } = this
        this.updateSpec({
          editingmodifierspec,
          name,
          description,
          modifierSpecSetId,
          displayOrder,
          isMeasured,
          isDefault,
          deleteModifierSpec
        })
      } else {
        const {
          name,
          description,
          modifierSpecSetId,
          isMeasured,
          isDefault,
          displayOrder
        } = this
        this.addSpec({
          name,
          description,
          displayOrder,
          isMeasured,
          isDefault,
          modifierSpecSetId
        })
      }
    }
  }
}
</script>
