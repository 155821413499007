import { menuaddendumsservice } from '../_services'

const state = {
  allmenuaddendums: {},
  allmenusectionaddendums: {},
  allmenusectionitemaddendums: {},
  menuIdsNamesSelect: [],
  menuSectionIdsNamesSelect: [],
  menuSectionItemIdsNamesSelect: [],
  activemenuaddendum: {},
  activemenusectionaddendum: {},
  activemenusectionitemaddendum: {},
  addmenuaddendum: {},
  addmenusectionaddendum: {},
  addmenusectionitemaddendum: {},
  updatemenuaddendum: {},
  updatemenusectionaddendum: {},
  updatemenusectionitemaddendum: {}
}

const actions = {
  getAll ({ commit }, data) {
    const restGroupId = data.restGroupId
    const addendumType = data.addendumType
    const addendumTypeId = data.addendumTypeId

    // Menu Addendums
    if (addendumType === 'menu') {
      const stateName = 'allmenuaddendums'
      commit('request', { stateName: stateName })
      menuaddendumsservice.getAll({ restGroupId, addendumType, addendumTypeId })
        .then(
          addendums => commit('getAllMenuAddendumsSuccess', addendums.data),
          error => commit('failure', { stateName: stateName, error: error })
        )
    }

    // Menu Section Addendums
    if (addendumType === 'menu_section') {
      const stateName = 'allmenusectionaddendums'
      commit('request', { stateName: stateName })
      menuaddendumsservice.getAll({ restGroupId, addendumType, addendumTypeId })
        .then(
          addendums => commit('getAllMenuSectionAddendumsSuccess', addendums.data),
          error => commit('failure', { stateName: stateName, error: error })
        )
    }

    // Menu Section Item Addendums
    if (addendumType === 'menu_section_item') {
      const stateName = 'allmenusectionitemaddendums'
      commit('request', { stateName: stateName })
      menuaddendumsservice.getAll({ restGroupId, addendumType, addendumTypeId })
        .then(
          addendums => commit('getAllMenuSectionItemAddendumsSuccess', addendums.data),
          error => commit('failure', { stateName: stateName, error: error })
        )
    }
  },
  getById ({ commit }, data) {
    const restGroupId = data.restGroupId
    const addendumType = data.addendumType
    const addendumTypeId = data.addendumTypeId

    // Menu Addendums
    if (addendumType === 'menu') {
      const stateName = 'getmenuaddendum'
      commit('request', { stateName: stateName })
      menuaddendumsservice.getById({ restGroupId, addendumType, addendumTypeId })
        .then(
          addendum => commit('getMenuAddendumByIdSuccess', addendum.data),
          error => commit('failure', { stateName: stateName, error: error })
        )
    }

    // Menu Section Addendums
    if (addendumType === 'menu_section') {
      const stateName = 'getmenusectionaddendum'
      commit('request', { stateName: stateName })
      menuaddendumsservice.getById({ restGroupId, addendumType, addendumTypeId })
        .then(
          addendum => commit('getMenuSectionAddendumByIdSuccess', addendum.data),
          error => commit('failure', { stateName: stateName, error: error })
        )
    }

    // Menu Section Item Addendums
    if (addendumType === 'menu_section_item') {
      const stateName = 'getmenusectionitemaddendum'
      commit('request', { stateName: stateName })
      menuaddendumsservice.getById({ restGroupId, addendumType, addendumTypeId })
        .then(
          addendum => commit('getMenuSectionItemByIdSuccess', addendum.data),
          error => commit('failure', { stateName: stateName, error: error })
        )
    }
  },
  add ({ dispatch, commit }, data) {
    const restGroupId = data.restGroupId
    const addendumType = data.addendumType
    const addendumTypeId = data.addendumTypeId
    const addendumId = data.addendumId
    const label = data.label
    const lineText = data.lineText
    const displayOrder = data.displayOrder
    const association = data.association
    const isHeader = data.isHeader
    const isFooter = data.isFooter
    const printOnly = data.printOnly

    // Menu Addendums
    if (addendumType === 'menu') {
      const stateName = 'addmenuaddendum'
      commit('request', { stateName: stateName })
      menuaddendumsservice.add({ restGroupId, addendumType, addendumTypeId, addendumId, label, lineText, displayOrder, association, isHeader, isFooter, printOnly })
        .then(
          addendum => {
            commit('addMenuAddendumSuccess', addendum.data)
            dispatch('alert/success', 'Added addendum.', { root: true })
          },
          error => {
            commit('failure', { stateName: stateName, error: error })
            dispatch('alert/error', error, { root: true })
          }
        )
    }

    // Menu Section Addendums
    if (addendumType === 'menu_section') {
      const stateName = 'addmenusectionaddendum'
      commit('request', { stateName: stateName })
      menuaddendumsservice.add({ restGroupId, addendumType, addendumTypeId, addendumId, label, lineText, displayOrder, association, isHeader, isFooter, printOnly })
        .then(
          addendum => {
            commit('addMenuSectionAddendumSuccess', addendum.data)
            dispatch('alert/success', 'Added addendum.', { root: true })
          },
          error => {
            commit('failure', { stateName: stateName, error: error })
            dispatch('alert/error', error, { root: true })
          }
        )
    }

    // Menu Section Item Addendums
    if (addendumType === 'menu_section_item') {
      const stateName = 'addmenusectionitemaddendum'
      commit('request', { stateName: stateName })
      menuaddendumsservice.add({ restGroupId, addendumType, addendumTypeId, label, addendumId, lineText, displayOrder, association, isHeader, isFooter, printOnly })
        .then(
          addendum => {
            commit('addMenuSectionItemAddendumSuccess', addendum.data)
            dispatch('alert/success', 'Added addendum.', { root: true })
          },
          error => {
            commit('failure', { stateName: stateName, error: error })
            dispatch('alert/error', error, { root: true })
          }
        )
    }
  },
  update ({ dispatch, commit }, data) {
    const addendumId = data.addendumId
    const menuAddendumId = data.menuAddendumId
    const restGroupId = data.restGroupId
    const addendumType = data.addendumType
    const addendumTypeId = data.addendumTypeId
    const label = data.label
    const lineText = data.lineText
    const displayOrder = data.displayOrder
    const association = data.association
    const isHeader = data.isHeader
    const isFooter = data.isFooter
    const printOnly = data.printOnly
    const deleteAddendum = data.deleteAddendum

    // Menu Addendums
    if (addendumType === 'menu') {
      const stateName = 'updatemenuaddendum'
      commit('request', { stateName: stateName })

      menuaddendumsservice.update({
        addendumId,
        menuAddendumId,
        restGroupId,
        addendumType,
        addendumTypeId,
        label,
        lineText,
        displayOrder,
        association,
        isHeader,
        isFooter,
        printOnly,
        deleteAddendum
      })
        .then(
          addendum => {
            commit('updateMenuAddendumSuccess', addendum.data)
            dispatch('alert/success', 'Edited addendum.', { root: true })
          },
          error => {
            commit('failure', { stateName: stateName, error: error })
            dispatch('alert/error', error, { root: true })
          }
        )
    }

    // Menu Section Addendums
    if (addendumType === 'menu_section') {
      const stateName = 'updatemenusectionaddendum'
      commit('request', { stateName: stateName })

      menuaddendumsservice.update({
        addendumId,
        menuAddendumId,
        restGroupId,
        addendumType,
        addendumTypeId,
        label,
        lineText,
        displayOrder,
        association,
        isHeader,
        isFooter,
        printOnly,
        deleteAddendum
      })
        .then(
          addendum => {
            commit('updateMenuSectionAddendumSuccess', addendum.data)
            dispatch('alert/success', 'Edited addendum.', { root: true })
          },
          error => {
            commit('failure', { stateName: stateName, error: error })
            dispatch('alert/error', error, { root: true })
          }
        )
    }

    // Menu Section Item Addendums
    if (addendumType === 'menu_section_item') {
      const stateName = 'updatemenusectionitemaddendum'
      commit('request', { stateName: stateName })

      menuaddendumsservice.update({
        addendumId,
        menuAddendumId,
        restGroupId,
        addendumType,
        addendumTypeId,
        label,
        lineText,
        displayOrder,
        association,
        isHeader,
        isFooter,
        printOnly,
        deleteAddendum
      })
        .then(
          addendum => {
            commit('updateMenuSsectionItemAddendumSuccess', addendum.data)
            dispatch('alert/success', 'Edited addendum.', { root: true })
          },
          error => {
            commit('failure', { stateName: stateName, error: error })
            dispatch('alert/error', error, { root: true })
          }
        )
    }
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  getAllMenuSectionItemAddendumsSuccess (state, addendums) {
    state.allmenusectionitemaddendums = { addendums }
    const selectData = []
    if (addendums) {
      Array.from(addendums).forEach(addendum => {
        selectData.push({ code: addendum.menu_section_item_addendum_id, label: addendum.label })
      })
    }
    state.menuSectionItemIdsNamesSelect = selectData
  },
  getAllMenuSectionAddendumsSuccess (state, addendums) {
    state.allmenusectionaddendums = { addendums }
    const selectData = []
    if (addendums) {
      Array.from(addendums).forEach(addendum => {
        selectData.push({ code: addendum.menu_section_addendum_id, label: addendum.label })
      })
    }
    state.menuSectionIdsNamesSelect = selectData
  },
  getAllMenuAddendumsSuccess (state, addendums) {
    state.allmenuaddendums = { addendums }
    const selectData = []
    if (addendums) {
      Array.from(addendums).forEach(addendum => {
        selectData.push({ code: addendum.menu_addendum_id, label: addendum.label })
      })
    }
    state.menuIdsNamesSelect = selectData
  },
  getMenuAddendumByIdSuccess (state, addendum) {
    state.activemenuaddendum = addendum
  },
  getMenuSectionAddendumByIdSuccess (state, addendum) {
    state.activemenusectionaddendum = addendum
  },
  getMenuSectionItemAddendumByIdSuccess (state, addendum) {
    state.activemenusectionitemaddendum = addendum
  },
  addMenuAddendumSuccess (state, addendum) {
    state.addmenuaddendum = addendum
  },
  addMenuSectionAddendumSuccess (state, addendum) {
    state.addmenusectionaddendum = addendum
  },
  addMenuSectionItemAddendumSuccess (state, addendum) {
    state.addmenusectionitemaddendum = addendum
  },
  updateMenuAddendumSuccess (state, addendum) {
    state.updatemenuaddendum = addendum
  },
  updateMenuSectionAddendumSuccess (state, addendum) {
    state.updatemenusectionaddendum = addendum
  },
  updateMenuSectionItemAddendumSuccess (state, addendum) {
    state.updatemenusectionitemaddendum = addendum
  }
}

export const menuaddendums = {
  namespaced: true,
  state,
  actions,
  mutations
}
