<template>
  <div class="component-container brands">
    <div class="container-fluid">
      <div class="row" v-if="$parent.setup.brandsOperated === 1">
        <div class="col-md-6 middle-divider">
          <h1 class="mb-3">What is the name of your brand?</h1>
        </div>
        <div class="col-md-6">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                 <div class="grey-text">
                    <mdb-input label="Fill in the name of your brand." v-model="$parent.setup.brandName"  icon="edit" type="text" class="p-0" />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="$parent.setup.brandsOperated === 2">
        <div class="col-md-6 middle-divider">
          <h1 class="mb-3">What are the names of your brands?</h1>
          <ul>
            <li>Fill in the name of one of your brands and click the <mdb-btn icon="plus-circle" color="success" size="sm" v-on:click="addBrand()">Add</mdb-btn>.</li>
            <li>You can start with one (recommended) and add more later or add them all now.</li>
          </ul>
        </div>
        <div class="col-md-6">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <mdb-input class="mt-0 mb-3" placeholder="Fill in the name of a brand to add." v-model="newBrandName" ariaDescribedBy="button-addon2">
                  <mdb-btn icon="plus-circle" color="success" size="md" group slot="append" id="button-addon2" v-on:click="addBrand()">Add</mdb-btn>
                </mdb-input>
              </div>
            </div>
            <div class="row d-flex justify-content-between align-items-center" v-for="(brand, brandIndex) of getBrands()" v-bind:key="brandIndex">
              <div class="col-auto">
                {{brand.name}}
              </div>
              <div class="col-auto">
                <button class="btn btn-sm btn-primary" v-on:click="editBrandName(brand)">Change Name</button>
                <button class="btn btn-sm btn-danger" v-on:click="deleteBrandConfirm(brandIndex, brand)">Remove</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 text-left">
          <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.goToPrevComponent()"><mdb-icon icon="arrow-alt-circle-left" size='lg' /> Go Back</button>
        </div>
        <div class="col-md-6 text-right">
           <button v-if="$parent.setup.restaurantsOperated !== 0" type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.restaurantsComponent()">Next Question <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
        </div>
      </div>
    </div>
    <div class="modal fade" id="brandNameEditor" tabindex="-1" aria-labelledby="brandNameEditorLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Edit the brand name below and click "Save" when you are done.</p>
            <div class="grey-text">
              <mdb-input label="Edit the name of this brand." v-model="editingBrandName"  icon="edit" type="text" class="p-0" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger float-left" v-on:click="closeBrandEdit()">Cancel</button>
            <button type="button" class="btn btn-success" v-on:click="saveBrand()">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="brandNameDelete" tabindex="-1" aria-labelledby="brandNameDeleteLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Are you sure you want to delete this brand? This is not reversable.</p>
            <p>Brand Name: <b>{{deleteBrand.name}}</b></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger float-left" v-on:click="closeBrandDelete()">No, I want to keep this brand.</button>
            <button type="button" class="btn btn-success" v-on:click="removeBrand()">Yes, I'm sure.</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mdbInput, mdbIcon, mdbBtn } from 'mdbvue'
export default {
  name: 'brands',
  components: { mdbInput, mdbIcon, mdbBtn },
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
      newBrandName: '',
      editingBrandName: '',
      editingBrandId: '',
      deleteBrand: '',
      deleteBrandIndex: ''
    }
  },
  watch: {},
  methods: {
    addBrand () {
      if (this.newBrandName === '') {
        return
      }
      const brand = {
        id: this.$parent.uuid(),
        name: this.newBrandName,
        operator: this.$parent.setup.holdingCompanyName
      }
      this.$parent.setup.brands.push(brand)
      this.newBrandName = ''
    },
    editBrandName (brand) {
      this.editingBrandName = brand.name
      this.editingBrandId = brand.id
      this.$parent.openModal('brandNameEditor')
    },
    closeBrandEdit () {
      this.$parent.closeModal('brandNameEditor')
      this.editingBrandName = ''
      this.editingBrandId = ''
    },
    saveBrand () {
      const data = {
        name: 'brands',
        keyName: 'id',
        keyValue: this.editingBrandId,
        updateKey: 'name',
        updateKeyValue: this.editingBrandName
      }
      this.$parent.updateSetupStateArrayItem(data)
      this.closeBrandEdit()
    },
    removeBrand () {
      const data = JSON.parse(JSON.stringify(this.deleteBrand))
      const trashItem = {
        id: this.$parent.uuid(),
        location: ['setup', 'brands'],
        value: data
      }
      this.$parent.moveToTrash(trashItem)
      this.$delete(this.$parent.setup.brands, this.deleteBrandIndex)
      this.$parent.closeModal('brandNameDelete')
    },
    deleteBrandConfirm (index, brand) {
      this.deleteBrand = brand
      this.deleteBrandIndex = index
      this.$parent.openModal('brandNameDelete')
    },
    closeBrandDelete () {
      this.deleteBrand = ''
      this.deleteBrandIndex = ''
      this.$parent.closeModal('brandNameDelete')
    },
    getBrands () {
      return this.$parent.setup.brands
    }
  },
  computed: {}
}
</script>
