<template>
  <div class="component-container complete">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 middle-divider">
          <h1 class="mb-3">Congratulations, you did it!</h1>
          <p>You have successfully setup your restaurant, a menu and some items.</p>
        </div>
        <div class="col-md-6 d-flex align-items-center">
          <p>Visit the Restaurant Owner Portal to where we have configured your workspace to administer your restaurant organization.</p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 text-left">
          <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.goToPrevComponent()"><mdb-icon icon="arrow-alt-circle-left" size='lg' /> Go Back</button>        </div>
        <div class="col-md-6 text-right">
           <router-link class="btn btn-primary btn-lg px-3" to="/ownerportalv1">Owner Portal <mdb-icon icon="arrow-alt-circle-right" size='lg' /></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mdbIcon } from 'mdbvue'
export default {
  name: 'completed',
  components: { mdbIcon },
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {}
  },
  watch: {},
  methods: {},
  computed: {}
}
</script>
