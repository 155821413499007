import { addendumsService } from '../_services'

const state = {
  all: {},
  idsNamesSelect: [],
  activeaddendum: {},
  addaddendum: {},
  updateaddendum: {}
}

const actions = {
  getAll ({ commit }, restGroupId) {
    const stateName = 'all'
    commit('request', { stateName: stateName })

    addendumsService.getAll(restGroupId)
      .then(
        addendums => commit('getAllSuccess', addendums.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getById ({ commit }, id) {
    const stateName = 'activeaddendum'
    commit('request', { stateName: stateName })

    addendumsService.getById(id)
      .then(
        addendum => commit('getByIdSuccess', addendum.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  add ({ dispatch, commit }, restGroupId, label, lineText) {
    const stateName = 'addaddendum'
    commit('request', { stateName: stateName })

    addendumsService.add(restGroupId, label, lineText)
      .then(
        addendum => {
          commit('addSuccess', addendum.data)
          dispatch('alert/success', 'Added addendum.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  update ({ dispatch, commit }, addendumId, restGroupId, label, lineText) {
    const stateName = 'updateaddendum'
    commit('request', { stateName: stateName })

    addendumsService.update(addendumId, restGroupId, label, lineText)
      .then(
        addendum => {
          commit('updateSuccess', addendum.data)
          dispatch('alert/success', 'Edited addendum.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  getAllSuccess (state, addendums) {
    state.all = { addendums }
    const selectData = []
    if (addendums) {
      Array.from(addendums).forEach(addendum => {
        selectData.push({ code: addendum.addendum_id, label: addendum.label })
      })
    }
    state.idsNamesSelect = selectData
  },
  getByIdSuccess (state, addendum) {
    state.activeaddendum = addendum
  },
  addSuccess (state, addendum) {
    state.addaddendum = addendum
  },
  updateSuccess (state, addendum) {
    state.updateaddendum = addendum
  }
}

export const addendums = {
  namespaced: true,
  state,
  actions,
  mutations
}
