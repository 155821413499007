export function singleRestaurant () {
  const data = {
    operator: [],
    brand: [
      {
        id: 'c8e59dc8-5e15-43ec-9063-b9b7d4ae7343',
        name: 'singleRestaurant',
        description: ''
      }
    ],
    restaurant_group: [],
    restaurant: [
      {
        id: '55345d2f-9b2c-44fb-b42b-154325c5cebd',
        brandId: 'c8e59dc8-5e15-43ec-9063-b9b7d4ae7343',
        name: 'Taco Restaurant',
        description: 'The Best Taco Restaurant',
        address: '1234 Test St.',
        address2: 'Suite 2',
        city: 'Chico',
        stateProvince: 'CA',
        postalCode: '95928',
        country: 'United States'
      }
    ],
    restaurant_sales_tax: {},
    menu: [
      {
        id: '7dbbf0b1-4cbc-45ae-bfc3-af1a70f1e42f',
        restaurantId: '55345d2f-9b2c-44fb-b42b-154325c5cebd',
        name: 'All Day',
        description: ''
      }
    ],
    menu_section: [
      {
        id: 'e3b0d525-4514-4b44-b07d-66da04c9735d',
        menuId: '7dbbf0b1-4cbc-45ae-bfc3-af1a70f1e42f',
        name: 'Tacos',
        description: '',
        displayOrder: 1
      },
      {
        id: '9913dbdc-d98c-4664-87ba-88222e5dbad0',
        menuId: '7dbbf0b1-4cbc-45ae-bfc3-af1a70f1e42f',
        name: 'Burritos',
        description: '',
        displayOrder: 2
      },
      {
        id: '86474f5b-e5db-497d-9a82-566f77a23c81',
        menuId: '7dbbf0b1-4cbc-45ae-bfc3-af1a70f1e42f',
        name: 'Drinks',
        description: '',
        displayOrder: 3
      }
    ],
    menu_subsection: [],
    item: [
      {
        id: '129dc7b0-f6d0-4b0e-8d8e-4e2beb4e3197',
        name: 'Beef',
        description: 'Beef Taco',
        price: '$5.00'
      },
      {
        id: '3605ff21-a875-46f7-8a9e-192a77359708',
        name: 'Chicken',
        description: 'Chicken Taco',
        price: '$5.00',
        sectionId: 'e3b0d525-4514-4b44-b07d-66da04c9735d'
      },
      {
        id: 'b414446f-b3a5-48f6-aba0-8e7d937f8d2d',
        name: 'Beef ',
        description: 'Beef Burrito',
        price: '$9.00',
        sectionId: '9913dbdc-d98c-4664-87ba-88222e5dbad0'
      },
      {
        id: '166fefc6-4c73-4898-8083-01fd2b4a2a33',
        name: 'Horchata',
        description: 'sweet rice drink',
        price: '$3.00',
        sectionId: '86474f5b-e5db-497d-9a82-566f77a23c81'
      }
    ],
    menu_item: [
      {
        id: 'a4edcf3e-d755-4cee-96e2-0c9436d802f9',
        menuId: '7dbbf0b1-4cbc-45ae-bfc3-af1a70f1e42f',
        itemId: '129dc7b0-f6d0-4b0e-8d8e-4e2beb4e3197',
        name: 'Beef',
        description: 'Beef Taco',
        price: '$5.00',
        sectionId: 'e3b0d525-4514-4b44-b07d-66da04c9735d'
      },
      {
        id: 'ce8781d2-6160-4bac-ba81-df0f51f87f75',
        menuId: '7dbbf0b1-4cbc-45ae-bfc3-af1a70f1e42f',
        itemId: '3605ff21-a875-46f7-8a9e-192a77359708',
        name: 'Chicken',
        description: 'Chicken Taco',
        price: '$5.00'
      },
      {
        id: '75c42146-b446-4aa2-82f9-ea93d5a5c138',
        menuId: '7dbbf0b1-4cbc-45ae-bfc3-af1a70f1e42f',
        itemId: 'b414446f-b3a5-48f6-aba0-8e7d937f8d2d',
        name: 'Beef ',
        description: 'Beef Burrito',
        price: '$9.00'
      },
      {
        id: 'ff34e02d-148b-4c22-a9e6-26ed4f789a79',
        menuId: '7dbbf0b1-4cbc-45ae-bfc3-af1a70f1e42f',
        itemId: '166fefc6-4c73-4898-8083-01fd2b4a2a33',
        name: 'Horchata',
        description: 'sweet rice drink',
        price: '$3.00'
      }
    ],
    menu_section_item: [
      {
        id: '1911c4c9-616d-459a-b946-c9889596c5c2',
        menuItemId: 'a4edcf3e-d755-4cee-96e2-0c9436d802f9',
        menuSectionId: 'e3b0d525-4514-4b44-b07d-66da04c9735d',
        displayOrder: 1
      },
      {
        id: '20bf5c7b-ce3f-4c1d-bccc-f51e98dd69ec',
        menuItemId: 'ce8781d2-6160-4bac-ba81-df0f51f87f75',
        menuSectionId: 'e3b0d525-4514-4b44-b07d-66da04c9735d',
        displayOrder: 2
      },
      {
        id: '3425617e-ba40-4813-843e-ad47b8257b3e',
        menuItemId: '75c42146-b446-4aa2-82f9-ea93d5a5c138',
        menuSectionId: '9913dbdc-d98c-4664-87ba-88222e5dbad0',
        displayOrder: 1
      },
      {
        id: '11ee30ae-6390-4bde-afe7-6ed4b8081687',
        menuItemId: 'ff34e02d-148b-4c22-a9e6-26ed4f789a79',
        menuSectionId: '86474f5b-e5db-497d-9a82-566f77a23c81',
        displayOrder: 1
      }
    ],
    modifier_set: [],
    modifier: [],
    modifier_set_member: {},
    item_modifier_set: {},
    item_modifier: {},
    menu_item_modifier_set: {},
    menu_item_modifier: {},
    restOperationType: false,
    importData: [],
    importType: '',
    sortItemsBy: 'name',
    sortItemsAsc: true,
    fullScreen: true,
    activeRestaurant: '',
    activeBrand: {},
    activeMenu: {
      id: '7dbbf0b1-4cbc-45ae-bfc3-af1a70f1e42f',
      restaurantId: '55345d2f-9b2c-44fb-b42b-154325c5cebd',
      name: 'All Day',
      description: ''
    },
    activeSection: '',
    activeItem: {
      id: '166fefc6-4c73-4898-8083-01fd2b4a2a33',
      name: 'Horchata',
      description: 'sweet rice drink',
      price: '$3.00',
      sectionId: '86474f5b-e5db-497d-9a82-566f77a23c81'
    },
    activeMenuSectionItem: {},
    selectedRestaurantId: '55345d2f-9b2c-44fb-b42b-154325c5cebd',
    selectedMenuId: '7dbbf0b1-4cbc-45ae-bfc3-af1a70f1e42f',
    selectedSectionId: '86474f5b-e5db-497d-9a82-566f77a23c81',
    editingRestaurant: false,
    restaurantsOperated: '1',
    holdingCompany: '0',
    brandsOperated: '',
    setFullScreen: {},
    updateState: {},
    updateStateArrayItem: {}
  }
  return data
}
