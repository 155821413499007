<template>
  <div class="component-container holding-compnay-name">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 middle-divider">
          <h1 class="mb-3">What is the name of your holding company?</h1>
        </div>
        <div class="col-md-6">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                 <div class="grey-text">
                    <mdb-input label="Fill in the name of your holding company." v-model="$parent.setup.holdingCompanyName" icon="edit" type="text" />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 text-left">
          <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.goToPrevComponent()"><mdb-icon icon="arrow-alt-circle-left" size='lg' /> Go Back</button>
        </div>
        <div class="col-md-6 text-right" v-if="$parent.setup.holdingCompanyName !== ''">
           <button v-if="$parent.setup.restaurantsOperated === 2" type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.brandsOperatedComponent()">Next Question <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
           <button v-if="$parent.setup.restaurantsOperated === 1" type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.restaurantsComponent()">Next Question <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mdbInput, mdbIcon } from 'mdbvue'
export default {
  name: 'holdingCompanyName',
  components: { mdbInput, mdbIcon },
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {}
  },
  watch: {},
  methods: {},
  computed: {}
}
</script>
