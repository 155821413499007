import { searchService } from '../_services'

const state = {
  results: {}
}

const actions = {
  search ({ dispatch, commit }, searchText, searchOption) {
    commit('searchRequest')
    searchService.search(searchText, searchOption)
      .then(
        results => {
          commit('searchSuccess', results.data)
        },
        error => {
          commit('searchFailure', error)
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  searchRequest (state) {
    state.results = { }
  },
  searchSuccess (state, results) {
    state.results = { results }
  },
  searchFailure (state, error) {
    state.results = { error }
  }
}

export const search = {
  namespaced: true,
  state,
  actions,
  mutations
}
