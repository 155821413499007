export function multipleRestaurants () {
  const data = {
    operator: [
      {
        id: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        name: 'Black Sheep Restaurants',
        description: ''
      }
    ],
    brand: [
      {
        id: '1fc2a330-cefa-4f42-8814-7aa664cc2b53',
        name: 'Belon',
        description: ''
      },
      {
        id: 'a0a7fad0-01b8-4e8f-8453-9f02d88dfcce',
        name: 'Ho Lee Fook',
        description: ''
      },
      {
        id: '73e58892-7fc0-4c12-a967-3c0484a6ca08',
        name: 'New Punjab Club',
        description: ''
      },
      {
        id: '1f1f6e75-ca86-49f2-a4dd-0306d90c8f46',
        name: 'Osteria Marzia',
        description: ''
      },
      {
        id: 'f15f8aa8-d30b-4b0d-ae67-5ae205695b7a',
        name: 'Associazione Chianti',
        description: ''
      },
      {
        id: 'b26d90c1-29c1-4ba8-ba3d-ed5f2d807547',
        name: 'Crown Super Deluxe',
        description: ''
      },
      {
        id: 'e1fbbab8-c9d7-4046-ada9-a5f78ed34b10',
        name: 'Artemis & Apollo',
        description: ''
      },
      {
        id: 'cbdf12a9-2de7-4554-82bb-7a5d8c9b6a6a',
        name: 'Hotal Columbo',
        description: ''
      },
      {
        id: '176317eb-3ce9-4540-bed4-1f81a563339b',
        name: 'Taqueria Super Macho',
        description: ''
      },
      {
        id: '5c9cb9c7-c723-4869-b6e1-ca326765d160',
        name: 'Butter Cake Shop',
        description: ''
      },
      {
        id: '6cbffc6a-b89d-462f-82b6-6cc9e5c4c2ec',
        name: 'Fukuro',
        description: ''
      },
      {
        id: '80145dab-dc85-4a12-8033-1e9f99820938',
        name: 'Rajasthan Rifles',
        description: ''
      },
      {
        id: '9e4aaa97-dad3-4e03-8f1d-9235f221f459',
        name: 'Uncle Desi Food & Sons',
        description: ''
      },
      {
        id: 'f9ec9f22-6f62-4e89-9caf-a01d0f792e50',
        name: 'Buenos Aires Polo Club',
        description: ''
      },
      {
        id: '2b7e9946-6c2d-4e1f-94d9-9a80ce7790e6',
        name: 'Carbone',
        description: ''
      },
      {
        id: 'f1d7fe7b-989d-4b2f-9d20-40d0190e2280',
        name: 'Saigon',
        description: ''
      },
      {
        id: 'f8195096-049b-477a-8663-ff7dad69cc32',
        name: 'Maison Libanaise',
        description: ''
      },
      {
        id: '05d3c139-2bdc-497d-af02-9d9d366e88f4',
        name: 'Saigon',
        description: ''
      },
      {
        id: '1321c1ee-fd0b-4ac5-939c-b5076b8ce25c',
        name: 'Chom Chom',
        description: ''
      },
      {
        id: '67674e5b-27bc-48a1-b4f6-4e013f0ec52c',
        name: 'La Vache!',
        description: ''
      },
      {
        id: 'bcc10315-07a7-40a8-bde8-ec889b81f111',
        name: 'Soul Food Thai',
        description: ''
      },
      {
        id: '79bbda51-a227-47d1-9ba0-ef4d2db34cc2',
        name: 'Motorino',
        description: ''
      },
      {
        id: '3a14261f-3108-4798-8f60-6f5da9a77a39',
        name: 'Burger Circus',
        description: ''
      },
      {
        id: 'f6695379-5402-4a8b-b225-004abfb157f3',
        name: 'Stazione Novella',
        description: ''
      },
      {
        id: '1ee2174b-0f8b-4530-b642-7d1c546d1932',
        name: 'Canton Disco',
        description: ''
      }
    ],
    restaurant_group: [
      {
        id: '6a3edc31-cb43-4881-b472-c0395798e374',
        brandId: '1fc2a330-cefa-4f42-8814-7aa664cc2b53',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        name: 'Black Sheep Hong Kong',
        description: ''
      },
      {
        id: '69606bb4-a0a3-4e60-be27-e1b250e25657',
        brandId: '1ee2174b-0f8b-4530-b642-7d1c546d1932',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        name: 'Black Sheep Shanghai',
        description: ''
      }
    ],
    restaurant: [
      {
        id: 'dc2a997f-ce9d-4c51-8a95-2cd84782fecd',
        brandId: '1fc2a330-cefa-4f42-8814-7aa664cc2b53',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Belon',
        description: 'A neo-Parisian bistro',
        address: '41 Elgin Street, SoHo, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '2a4f9ed0-67c4-4d79-911b-ee00657406ed',
        brandId: 'a0a7fad0-01b8-4e8f-8453-9f02d88dfcce',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Ho Lee Fook',
        description: 'A funky Chinese kitchen',
        address: '3-5 Elgin Street, SoHo, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '9fe02d91-782a-44b8-9aa2-f29c9b20ca72',
        brandId: '73e58892-7fc0-4c12-a967-3c0484a6ca08',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'New Punjab Club',
        description: 'A boisterous Punjabi tandoor grillhouse',
        address: '34 Wyndham Street, Central, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '6a94679c-d0ab-4d7d-866c-16767d344950',
        brandId: '1f1f6e75-ca86-49f2-a4dd-0306d90c8f46',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Osteria Marzia',
        description: 'Refined coastal Italian fare',
        address: 'The Fleming, 41 Fleming Road, Wan Chai, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '0f77254b-f344-4bbe-9bf5-784f345b4382',
        brandId: 'f15f8aa8-d30b-4b0d-ae67-5ae205695b7a',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Associazione Chianti',
        description: 'Soulful Tuscan trattoria',
        address: '15 Ship Street, Wan Chai, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '8766eda5-9cf5-4815-b327-2511ffd150ce',
        brandId: 'b26d90c1-29c1-4ba8-ba3d-ed5f2d807547',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Crown Super Deluxe',
        description: 'A lavish teppanyaki affair',
        address: 'Mezzanine, LKF Tower, 33 Wyndham Street, Central, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '0fbf3f20-7420-475d-a154-35f0ba32590b',
        brandId: 'e1fbbab8-c9d7-4046-ada9-a5f78ed34b10',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Artemis & Apollo',
        description: 'A Greek taverna for the people',
        address: '9-11 Moon Street, Wan Chai, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '11c54fc9-f2ce-4db5-93fe-ff2a560110e6',
        brandId: 'cbdf12a9-2de7-4554-82bb-7a5d8c9b6a6a',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Hotel Columbo',
        description: 'An everyday Sri Lankan canteen',
        address: '31 Elgin Street, SoHo, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '5c06fd96-5f78-4113-8be3-5b84e8f6ffbb',
        brandId: '176317eb-3ce9-4540-bed4-1f81a563339b',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Taqueria Super Macho',
        description: 'A no-fuss, Baja-inspired taqueria',
        address: '33-35 Bridges Street, Sheung Wan, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: 'f0ccb63c-9e2b-4f27-b9cc-5785a181b21c',
        brandId: '5c9cb9c7-c723-4869-b6e1-ca326765d160',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Butter Cake Shop',
        description: 'A virtual cake shop of baked-to-order nostalgic delights.',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: 'd5a7d316-189f-49b3-9b79-62b6ae109d74',
        brandId: '6cbffc6a-b89d-462f-82b6-6cc9e5c4c2ec',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Fukuro',
        description: "An izakaya for SoHo's night owls",
        address: '1-5 Elgin Street, SoHo, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '2ab4db88-2c26-4235-bf09-73f206be1125',
        brandId: '80145dab-dc85-4a12-8033-1e9f99820938',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Rajasthan Rifles',
        description: 'An Anglo-Indian mess hall',
        address: 'G/F, The Peak Galleria, The Peak, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '14e63a5f-d46f-4490-9aa4-bb93544ccd55',
        brandId: '9e4aaa97-dad3-4e03-8f1d-9235f221f459',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Uncle Desi Food & Sons',
        description: 'Greatest Indian food delivery',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: 'fed6c8d2-c7e3-4566-93a7-b93a01172398',
        brandId: 'f9ec9f22-6f62-4e89-9caf-a01d0f792e50',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Buenos Aires Polo Club',
        description: 'The handsome Argentinian steakhouse',
        address: '7/F, LKF Tower, 33 Wyndham Street, Central, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: 'b671688c-6aa1-4ddd-8fb3-7287311289c1',
        brandId: '2b7e9946-6c2d-4e1f-94d9-9a80ce7790e6',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Carbone',
        description: 'A mid-century New York-Italian',
        address: '9/F, LKF Tower, 33 Wyndham Street, Central, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: 'fcaa0bc7-d00b-47b6-8136-c7177fb24fa5',
        brandId: 'f1d7fe7b-989d-4b2f-9d20-40d0190e2280',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Le Garcon Saigon',
        description: 'A Saigonese grillhouse',
        address: '12-18 Wing Fung Street, Wan Chai, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '4ce35af3-1f18-42a0-a17c-2663846d818f',
        brandId: 'f8195096-049b-477a-8663-ff7dad69cc32',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Maison Libanaise',
        description: 'An all day Lebanese canteen',
        address: '10 Shelley Street, SoHo, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '51a63fae-01be-431f-b7b1-385e6ebca63b',
        brandId: '05d3c139-2bdc-497d-af02-9d9d366e88f4',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Le Petit Saigon',
        description: "Hong Kong's favourite bánh mì",
        address: '16 Wing Fung Street, Wan Chai, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '044d3fba-0d53-442d-825f-d3fdb72a1be2',
        brandId: '1321c1ee-fd0b-4ac5-939c-b5076b8ce25c',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chom Chom',
        description: 'A Hanoi bia hoi bar and kitchen',
        address: '58 Peel Street, SoHo, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '6b3b79ff-87db-4b70-a31d-d4d16422212f',
        brandId: '67674e5b-27bc-48a1-b4f6-4e013f0ec52c',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'La Vache!',
        description: 'A Parisian entrecôte steakhouse',
        address: '48 Peel Street, SoHo, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: 'ed500a51-4901-4185-8d59-315cf8c3c454',
        brandId: '67674e5b-27bc-48a1-b4f6-4e013f0ec52c',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'La Vache!',
        description: 'A Parisian entrecôte steakhouse',
        address: '12 Hart Avenue, Tsim Sha Tsui, Kowloon, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '96247d64-d03b-4d6f-946d-a20390c2467c',
        brandId: 'bcc10315-07a7-40a8-bde8-ec889b81f111',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Soul Food Thai',
        description: 'A neighbourhood Thai restaurant',
        address: '26-28 Elgin Street, SoHo, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '08bf7874-7cbd-47ed-b87b-33ace299d535',
        brandId: '79bbda51-a227-47d1-9ba0-ef4d2db34cc2',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Motorino',
        description: 'A neighbourhood Neapolitan pizzeria',
        address: '14 Shelley Street, SoHo, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '90528b4e-58fe-44f0-8fee-5a8af3f82d24',
        brandId: '79bbda51-a227-47d1-9ba0-ef4d2db34cc2',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Motorino',
        description: 'A neighbourhood Neapolitan pizzeria',
        address: '15 Ship Street, Wan Chai, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '225e83f4-0537-4aa6-8642-f62b5d9e3b7b',
        brandId: '3a14261f-3108-4798-8f60-6f5da9a77a39',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Burger Circus',
        description: 'An all-American diner',
        address: '22 Hollywood Road, SoHo, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: '629099c2-80c3-45e6-8b5f-4489424bb68e',
        brandId: 'f6695379-5402-4a8b-b225-004abfb157f3',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Stazione Novella',
        description: 'A buzzing Florentine wine bar',
        address: '52-56 Staunton Street, SoHo, Hong Kong',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      },
      {
        id: 'f9c69fef-eb5a-42fc-81de-73725893dd1a',
        brandId: '1ee2174b-0f8b-4530-b642-7d1c546d1932',
        operatorId: '6e6522a5-6508-475c-8cf7-99189f4f5b49',
        restaurantGroupId: '69606bb4-a0a3-4e60-be27-e1b250e25657',
        name: 'Canton Disco',
        description: 'Bringing 1970s Hong Kong nightlife to Shanghai',
        address: '199 Nanjing Road East, Shanghai, China',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      }
    ],
    restaurant_sales_tax: {},
    menu: [
      {
        id: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        restaurantId: '2a4f9ed0-67c4-4d79-911b-ee00657406ed',
        name: 'Main',
        description: ''
      },
      {
        id: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        restaurantId: '9fe02d91-782a-44b8-9aa2-f29c9b20ca72',
        name: 'The Menu',
        description: ''
      },
      {
        id: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        restaurantId: '6a94679c-d0ab-4d7d-866c-16767d344950',
        name: 'Menu',
        description: ''
      },
      {
        id: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        restaurantId: '6a94679c-d0ab-4d7d-866c-16767d344950',
        name: 'Set Lunch',
        description: ''
      },
      {
        id: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        restaurantId: '0f77254b-f344-4bbe-9bf5-784f345b4382',
        name: 'Menu',
        description: ''
      },
      {
        id: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        restaurantId: '8766eda5-9cf5-4815-b327-2511ffd150ce',
        name: 'Set Menus',
        description: ''
      },
      {
        id: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        restaurantId: '8766eda5-9cf5-4815-b327-2511ffd150ce',
        name: 'A la Carte',
        description: ''
      },
      {
        id: '75192f42-b5ec-472d-bce8-406d1a088ead',
        restaurantId: '0fbf3f20-7420-475d-a154-35f0ba32590b',
        name: 'Menu',
        description: ''
      },
      {
        id: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        restaurantId: '0fbf3f20-7420-475d-a154-35f0ba32590b',
        name: 'Lunch Menu',
        description: ''
      },
      {
        id: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        restaurantId: '0fbf3f20-7420-475d-a154-35f0ba32590b',
        name: 'Menu',
        description: ''
      },
      {
        id: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        restaurantId: '5c06fd96-5f78-4113-8be3-5b84e8f6ffbb',
        name: 'La Comida',
        description: ''
      },
      {
        id: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        restaurantId: 'f0ccb63c-9e2b-4f27-b9cc-5785a181b21c',
        name: 'Bakery',
        description: ''
      },
      {
        id: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        restaurantId: 'd5a7d316-189f-49b3-9b79-62b6ae109d74',
        name: 'Food Menu',
        description: ''
      },
      {
        id: '5b845b14-8332-464b-841a-512bbdc9462a',
        restaurantId: 'd5a7d316-189f-49b3-9b79-62b6ae109d74',
        name: 'Drinks Menu',
        description: ''
      },
      {
        id: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        restaurantId: '2ab4db88-2c26-4235-bf09-73f206be1125',
        name: 'Breakfast',
        description: ''
      },
      {
        id: '0fac3686-4512-48c6-8b73-4c0e2936cf57',
        restaurantId: '2ab4db88-2c26-4235-bf09-73f206be1125',
        name: 'Luncheon and Later',
        description: ''
      },
      {
        id: 'a9e20399-2184-4c0d-a131-1d43a1008f74',
        restaurantId: '2ab4db88-2c26-4235-bf09-73f206be1125',
        name: 'Sizzlers',
        description: ''
      },
      {
        id: 'f960941f-00e4-4f9d-b38c-0c18703676ac',
        restaurantId: '2ab4db88-2c26-4235-bf09-73f206be1125',
        name: 'From the Tandoor',
        description: ''
      },
      {
        id: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        restaurantId: '2ab4db88-2c26-4235-bf09-73f206be1125',
        name: 'Curry',
        description: ''
      },
      {
        id: 'b0997cf3-6691-42a5-b586-88163d78a4e7',
        restaurantId: '2ab4db88-2c26-4235-bf09-73f206be1125',
        name: 'Pudding',
        description: ''
      },
      {
        id: '5642da57-70ba-47d6-848e-acc3224fbca0',
        restaurantId: '14e63a5f-d46f-4490-9aa4-bb93544ccd55',
        name: 'Menu',
        description: ''
      },
      {
        id: 'a95e3b15-cb09-49dc-a8f7-343eb8076460',
        restaurantId: '51a63fae-01be-431f-b7b1-385e6ebca63b',
        name: 'Junk Catering',
        description: ''
      },
      {
        id: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        restaurantId: '4ce35af3-1f18-42a0-a17c-2663846d818f',
        name: 'Food',
        description: ''
      },
      {
        id: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        restaurantId: '4ce35af3-1f18-42a0-a17c-2663846d818f',
        name: 'Drinks',
        description: ''
      },
      {
        id: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        restaurantId: '044d3fba-0d53-442d-825f-d3fdb72a1be2',
        name: 'Menu',
        description: ''
      },
      {
        id: '4a39d9a1-e452-45b1-90ec-a1cb2a067367',
        restaurantId: 'ed500a51-4901-4185-8d59-315cf8c3c454',
        name: 'Today',
        description: ''
      },
      {
        id: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        restaurantId: '96247d64-d03b-4d6f-946d-a20390c2467c',
        name: 'Menu',
        description: ''
      },
      {
        id: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        restaurantId: '90528b4e-58fe-44f0-8fee-5a8af3f82d24',
        name: 'A la Carte',
        description: ''
      },
      {
        id: '2fc1b9e3-84b2-41ff-bd09-f8430bd996a2',
        restaurantId: '90528b4e-58fe-44f0-8fee-5a8af3f82d24',
        name: 'Lunch',
        description: ''
      },
      {
        id: 'e7964f59-297b-44c4-afd5-d4c7df320113',
        restaurantId: '90528b4e-58fe-44f0-8fee-5a8af3f82d24',
        name: 'Brunch',
        description: ''
      },
      {
        id: 'e0223415-31db-4d4a-9299-488d04ec4402',
        restaurantId: '225e83f4-0537-4aa6-8642-f62b5d9e3b7b',
        name: 'Menu',
        description: ''
      },
      {
        id: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        restaurantId: '629099c2-80c3-45e6-8b5f-4489424bb68e',
        name: 'Wine',
        description: ''
      },
      {
        id: '0d180b38-3e5f-464d-9286-d2f5150b2cb0',
        restaurantId: '629099c2-80c3-45e6-8b5f-4489424bb68e',
        name: 'Cocktails',
        description: ''
      },
      {
        id: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        restaurantId: '629099c2-80c3-45e6-8b5f-4489424bb68e',
        name: 'Coffee',
        description: ''
      }
    ],
    menu_section: [
      {
        id: '93f19411-ee8b-4634-902d-debce568401c',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        name: 'Snacks',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '80a3979b-0a68-4ea7-9229-d7a6f06917cd',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        name: 'Salads',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: '6112d2b7-33c0-4f3f-b316-bff50484019a',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        name: 'Roast Meats',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '33476a60-506d-442c-b0c8-6c5c19a029f3',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        name: 'Wok-fried',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: 'b5ba20ed-9cf8-444e-bdbc-088c3da371fb',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        name: 'Not a Small Plate',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: '9a4a5eda-f558-4249-9aa4-62ea5c3b50a7',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        name: 'Rice and Noodles',
        description: 'Section Description',
        displayOrder: 6
      },
      {
        id: '6d7f664a-e27a-40e0-ba92-d48ad0143b81',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        name: 'Dessert',
        description: 'Section Description',
        displayOrder: 7
      },
      {
        id: '6d4ab773-15f5-400e-a784-0de81bdf2264',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        name: 'Tandoor',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '6a7deada-ccc8-4fe0-a8a0-a8484edeb692',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        name: 'Deg Aur Sabzi',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: 'dd14a87c-07ab-4909-96f3-06c7ae840e20',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        name: 'Nashta',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: 'fb1654b7-c4cd-4240-85cb-c718c09e913e',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        name: 'Salaad Aur Achar',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: '859c1dd6-c116-4d94-a536-b92276ac634f',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        name: 'Naan Aur Roti',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: 'da3e4f92-d445-43ac-9eb9-f3703fd22f66',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        name: 'Crudi E Cotti',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '12e4d122-3270-4721-a33d-30c22684033a',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        name: 'Paste',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: 'be2e5f9a-f816-44ac-818f-7ad6adf58c02',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        name: 'Pesci Invernali',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '3bfbf45f-e426-4808-bb0a-c1c929bb1c78',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        name: 'Insalate',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: '1044d60c-5463-424c-a5cd-c319e14b1a16',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        name: 'Dalla Griglia',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: '1649fe5e-6b8a-48be-bd17-336e8dfedb9c',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        name: 'Contorni',
        description: 'Section Description',
        displayOrder: 6
      },
      {
        id: 'f1e2f360-2d48-4895-aa96-024b1e6564e3',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        name: 'Dolci',
        description: 'Section Description',
        displayOrder: 7
      },
      {
        id: '9bf1d331-b18c-4089-8a16-33c17697a9df',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        name: 'Set Lunch',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: 'e61c9477-51e2-4131-91bb-8009c03f5e3e',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        name: 'I Nostri Vini',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: '7f6df0d2-84f8-4b47-a28c-3b2d0233432c',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        name: 'Antipasti',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '06957be3-3701-4c05-9502-3d00a5a5e23f',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        name: 'Piatti Principali',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: 'ec0fbf7b-647b-4910-aa79-f047fd16003d',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        name: 'Dolce',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: '275b1ece-0d1f-4b0a-8447-052fd7f82e47',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        name: 'Bevande',
        description: 'Section Description',
        displayOrder: 6
      },
      {
        id: '298c2b1b-d662-4b6f-b27b-e9fa259a9928',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        name: 'I TAGLI DEL MACELLAIO',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '526ae38a-9262-4342-87fd-f2245f0d4f45',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        name: 'ANTIPASTI & INSALATE',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: '7fbc4f58-4829-4fb5-b4b0-6433c3d14c37',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        name: 'PRIMI',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '0c437470-a8d3-493d-8f36-fec92e526aa6',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        name: 'CONTORNI',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: '093b4cf8-636b-4d84-81ba-a68c1b2d375c',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        name: 'Crown',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '117e4638-07e2-4bb3-be2a-63066316425b',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        name: 'Super',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: '74266fb2-c345-4934-a700-e11aad51705e',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        name: 'Deluxe',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: 'd6e6055a-ff15-4a9d-bde7-46875bbf7ecc',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        name: 'Main',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '3e5b4575-f51e-4ea9-ab4d-350d3200cf5c',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        name: 'Sushi',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: '04b0a8ff-3a88-44ff-bc9d-371ceb963d99',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        name: 'Sashimi',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '254f83bd-b43a-4d24-8364-87236ac010f5',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        name: 'Tempura',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: '448983dc-0963-4c85-a328-6ca0691b9c21',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        name: 'Hoso Maki',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: '0a221d25-16b8-4e0b-83a4-8950e098bf5d',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        name: 'Teppenyaki',
        description: 'Section Description',
        displayOrder: 6
      },
      {
        id: '723ac5dc-3e62-4492-b9cf-dae6768388b8',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        name: 'Vegetable',
        description: 'Section Description',
        displayOrder: 7
      },
      {
        id: 'e40d1c89-6cc8-4239-b4b3-6d8f67cd0ffd',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        name: 'Cold Mezze',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '8bfc470f-65e9-4684-a009-8d5aeec68038',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        name: 'Hot Mezze',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: 'f21d6cd8-a628-4747-8183-5f1df9347362',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        name: 'Spit-roasted Meats',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: 'da67951d-4aed-495b-8f00-2e5b860ab2f3',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        name: 'Salads',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: '6dc451c7-4b10-4b14-ac12-6e7f060832a5',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        name: 'Seafood',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: '8d3e65b9-922a-4b9e-aa64-ff2ef03a6138',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        name: 'Sides',
        description: 'Section Description',
        displayOrder: 6
      },
      {
        id: 'd77fa069-acb8-42db-8fda-957eeb94bbba',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        name: 'Dessert',
        description: 'Section Description',
        displayOrder: 7
      },
      {
        id: 'f8365a43-1190-49a1-8fa0-ed8071c7dd50',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        name: 'Main',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '762d56d0-908d-42c6-9554-a088404a4123',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        name: 'Mezze',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: '93ada6dc-13a1-4fac-836b-3f69a95c5b92',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        name: 'Salads',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '843d146c-06f5-4528-8e16-252d2746be97',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        name: 'Spit-roasted Meats',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: '669c1b19-098b-48ae-bfd4-3d83eff300b9',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        name: 'Dessert',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: '7a594515-a713-4d79-a026-bc57b6a7d0a1',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        name: 'Drinks',
        description: 'Section Description',
        displayOrder: 6
      },
      {
        id: '90bec1bc-c6c4-457b-bb33-4e96c7dfaccf',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        name: 'Snacks',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '095705dc-6e27-4408-b784-221f974fc5f8',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        name: 'Short Eats',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: '124dfcde-b8ce-4a1f-b2e2-1f48b0477159',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        name: 'Kari',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '4353e3e5-4322-443c-ae06-d1babfb4dda3',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        name: 'Chutneys and Sambols',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: '3b4dd8b2-6ff7-405d-a6df-6c03c97f0886',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        name: 'Kothu',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: 'fc8d4a1d-4dcb-4d7f-844c-40a6f9fd3c24',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        name: 'Hoppiers, Dosa and Stuffed Paratha',
        description: 'Section Description',
        displayOrder: 6
      },
      {
        id: 'c7eed0b9-a883-49e4-b4ef-c5f29a2e361a',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        name: 'From the Crab Tank',
        description: 'Section Description',
        displayOrder: 7
      },
      {
        id: '87687b3a-8217-4441-bbc7-b34606704246',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        name: 'Sides',
        description: 'Section Description',
        displayOrder: 8
      },
      {
        id: '4afa3644-aa57-447d-9cb9-4f4bf33e1760',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        name: 'Baja & Jalisco Tacos',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '601553e5-0cd8-4d97-8f23-91781218c546',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        name: 'Los Tacos Mexicanos',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: 'a67691f1-fa7b-4298-be9e-86528b948d75',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        name: 'Platos Pequenos',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: 'faa5ac6c-98fe-4c1e-b4c6-0cec704db177',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        name: 'Al Lado',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: '7462cad7-8892-47f6-b4fc-923a9cfe26c0',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        name: 'Pie of the Month',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: 'ff2c0e0f-8c5d-4f58-b716-37b91657673d',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        name: 'Cakes',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: '3e7c16c4-ab5f-44b8-9f4f-5bf5f0272889',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        name: 'Pies',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '276bdfd7-0e84-4167-a469-750fc3a364ab',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        name: 'Doggie Treats',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: '8f370fb1-e568-4924-b34a-16e1ee7a39ce',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        name: 'Raw',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '6551732b-1141-4a40-9b6e-97a4904397e8',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        name: 'Snacks',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: 'd7f8bb48-4deb-4557-8e1d-9b6a33409514',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        name: 'Tempura',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '6a877b77-9a90-4e5a-abb9-96c967199335',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        name: 'Robata',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: '628d3d06-6ac6-4496-9a57-874e0276a55f',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        name: 'Noodles/Rice',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: '9cfdfcfc-ac01-44ee-ad07-b3f959b99640',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        name: 'Sweet',
        description: 'Section Description',
        displayOrder: 6
      },
      {
        id: '36766192-8911-4558-80b9-23e101337404',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        name: 'Sake',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '0b75f0e9-70e2-4d11-ac96-9432d189b589',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        name: 'Sweet',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: '0e8554b1-5e5c-4abc-9794-a5f91ff5c81d',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        name: 'Highball',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '5d49079c-9b41-4262-b50d-a58ae666fcc2',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        name: 'Cocktail',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: 'ebade368-a88f-4c0e-a869-f47f0b2f5d4a',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        name: 'Shochu',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: '15309fcb-8e96-4ba5-a9a2-ae7614f4f1c1',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        name: 'Japanese Whisky',
        description: 'Section Description',
        displayOrder: 6
      },
      {
        id: 'e083bfea-ee78-45cd-a7ff-d038b15f48ab',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        name: 'Taiwanese Whisky',
        description: 'Section Description',
        displayOrder: 7
      },
      {
        id: '4906272c-d063-426c-8b62-da0417eea9d9',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        name: 'Mains',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: 'edff71c5-8072-41d3-9914-a87629bfea50',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        name: 'Sides',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        menuId: '0fac3686-4512-48c6-8b73-4c0e2936cf57',
        name: 'Main',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: 'a15a1654-d7d4-44e2-9a6c-06f0b33cea04',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        name: 'Company Special Biryani',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '31448f19-ee70-490f-9b97-bebe555b441d',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        name: 'Bread and Rice',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: 'c22b977f-c622-4f76-96fd-fc934a7d7ef6',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        name: 'Salad/Chutney/Raita',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: 'ce2de121-5777-4539-bfc5-a42f1db1285b',
        menuId: 'b0997cf3-6691-42a5-b586-88163d78a4e7',
        name: 'Main',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '863dacf5-373f-442e-8c13-40fcd9f4b32b',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        name: 'Tasty Bites',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: 'ae85d09a-19cd-459f-908e-f724975af103',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        name: 'Smoky Tandoor',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: '2427fc5a-2311-44c5-b60f-17d885c823b2',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        name: 'Secret Pot of Wonders',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '1afa0605-4a17-4acf-93c7-34ad07279598',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        name: 'Curry & Spice',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: 'b0e4fee9-2ae8-4ba9-bac2-e65da89817d9',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        name: 'Wonderful Additions',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: 'e2c7ec90-1af3-4d78-958c-f5714676e89d',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        name: 'Drinks',
        description: 'Section Description',
        displayOrder: 6
      },
      {
        id: '55f13a05-b0d4-4060-b779-7530b7a3a817',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        name: 'Les Petits Plats & Les Snacks',
        description: 'Section Description',
        displayOrder: 7
      },
      {
        id: '3e11ee70-4eca-42d0-b49e-dfd15ec38d56',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        name: 'DIY Rice Paper Rolls',
        description: 'Section Description',
        displayOrder: 8
      },
      {
        id: '224ee548-1107-4683-8b13-007fd556c0b6',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        name: 'Le Grill',
        description: 'Section Description',
        displayOrder: 9
      },
      {
        id: '372b3ca0-0047-42fe-a148-e2c3cdc12402',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        name: 'Le Wok',
        description: 'Section Description',
        displayOrder: 10
      },
      {
        id: '99c636dd-8196-4975-82e0-9df8aa0a3c4d',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        name: 'Les Desserts',
        description: 'Section Description',
        displayOrder: 11
      },
      {
        id: '74e96c9a-3080-42c9-b98c-c22ce60a9876',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        name: 'Banh Mi',
        description: 'Section Description',
        displayOrder: 12
      },
      {
        id: '9790a68a-ddeb-4398-9173-f94e58baba6d',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        name: 'Sides',
        description: 'Section Description',
        displayOrder: 13
      },
      {
        id: '4983844f-421f-4675-abc1-3d8dda39e7fe',
        menuId: 'a95e3b15-cb09-49dc-a8f7-343eb8076460',
        name: 'Banh Mi Onboard',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '19b93361-8832-4f35-b949-e87f68575e7f',
        menuId: 'a95e3b15-cb09-49dc-a8f7-343eb8076460',
        name: 'To Share',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: 'c64c6b97-0530-48bf-b157-5d9ccc413e61',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        name: 'Cold Mezze',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: 'c69da935-4ff0-4f70-be1d-e85c84c3b29e',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        name: 'Salads',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: 'd2a4049b-b59c-4a2f-a687-6a68ac8c5db1',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        name: 'Hot Mezze',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '0baa8f96-72ca-4dcd-8bf9-a1afe302494f',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        name: 'Larger Dishes',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: '8e6eb62b-c6b2-4d61-99a2-ab6e22d3516d',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        name: 'From the Grill',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: '04ee5963-8d26-4c0d-80fd-777519fc1068',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        name: 'On the Side',
        description: 'Section Description',
        displayOrder: 6
      },
      {
        id: 'b3217336-77ed-456d-a74f-fc2fbeb6cf41',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        name: 'White Wines',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: 'ab715290-6234-40fe-9b22-6b0cc6b69e5f',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        name: 'Lebanon',
        description: 'Sub Section Description',
        displayOrder: 1
      },
      {
        id: '9fbc5fa7-53d1-466b-be02-4c9c39e0d202',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        name: 'France',
        description: 'Sub Section Description',
        displayOrder: 2
      },
      {
        id: '503930e0-a23c-4d97-a984-93a26b8b1d87',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        name: 'Rose',
        description: 'Sub Section Description',
        displayOrder: 3
      },
      {
        id: 'b172f070-cdd7-4e5d-829c-52df9b189da3',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        name: 'Bubbles',
        description: 'Sub Section Description',
        displayOrder: 4
      },
      {
        id: 'f6825a58-237b-49ee-84c6-ee17299b70ad',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        name: 'Sweet',
        description: 'Sub Section Description',
        displayOrder: 5
      },
      {
        id: '6d323cfe-ce18-4c3c-9626-7a634739eb1e',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        name: 'Red Wines',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: 'e60c17e6-ec69-4cbd-94b3-c62809393330',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        name: 'Lebanon',
        description: 'Sub Section Description',
        displayOrder: 1
      },
      {
        id: 'c70a2573-09cf-4a16-a5d2-4c3befe5b6ae',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        name: 'France',
        description: 'Sub Section Description',
        displayOrder: 2
      },
      {
        id: '17fb7640-50a3-4d6b-8811-21b0e679512c',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        name: 'Small Bites',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '481bbb15-a73a-4e45-9118-6840361bd981',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        name: 'Rolls',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: 'adb246d4-0c7e-4e8c-a5d0-ccb11a0e237e',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        name: 'Salads',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '400e7dc3-85ea-4eaf-bfc5-e87dd67f1e9e',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        name: 'Large Plates',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: 'ae816cab-6534-4740-8815-549ff6a9c021',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        name: 'Sides',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: '250f44e6-6ec9-478b-a230-304ea15effd2',
        menuId: '4a39d9a1-e452-45b1-90ec-a1cb2a067367',
        name: 'Main',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '9b7cb079-769e-4a23-8f33-47689b4dbff2',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        name: 'Street Snacks',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: 'd089f7db-7312-430a-85d8-12cd4b218437',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        name: 'Soup & Salads',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: '98c3311f-2ba8-4339-9154-79f17cc1173f',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        name: 'Noodles & Rice',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '26d597d4-51df-460b-a4a5-4fc32986ff2d',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        name: 'Vegetables & Sides',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: '51303ef8-6237-4e4c-917e-242764d26189',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        name: 'Meats',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: 'c0fdb324-b497-4932-ba89-c182c01d5dd5',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        name: 'Curries',
        description: 'Section Description',
        displayOrder: 6
      },
      {
        id: 'a73a930d-457e-4f34-b582-fa8fdcb91f30',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        name: 'Desserts',
        description: 'Section Description',
        displayOrder: 7
      },
      {
        id: '58b8d9a9-d0d4-41cc-9c31-45d6f1b44ce0',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        name: "Can't Decide",
        description: 'Section Description',
        displayOrder: 8
      },
      {
        id: 'ca4be1b9-391c-41e8-ae73-14af04091cc4',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        name: 'Antipasti',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '7d43cf2c-5c41-49f0-8d0e-b7d8e6a1e1f5',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        name: 'Pizze',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: '0682f7d0-41cf-49e1-819c-6c6109c4bc8d',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        name: 'Dolci',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: 'b4533b15-56da-409e-b396-a0bbe682832d',
        menuId: '2fc1b9e3-84b2-41ff-bd09-f8430bd996a2',
        name: 'Main',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '5c508637-376b-4442-a0ea-6bdb3bd7c1da',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        name: 'Burgers',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '54073acc-3ae2-439e-8415-a0031b97c808',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        name: 'Fried & Sides',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: '34e87b7e-0e4c-4e04-8e83-55a8890abc3f',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        name: 'Shakes',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '920ec36b-d796-4387-86c1-b693ed90c9c5',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        name: 'Make It a Combo',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: 'b581d32f-e8d5-408a-823a-5b21e234611a',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        name: 'Add-Ons',
        description: 'Section Description',
        displayOrder: 5
      },
      {
        id: '5ee67c2e-5d3a-420a-a31d-fe210caa5432',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        name: 'Drinks',
        description: 'Section Description',
        displayOrder: 6
      },
      {
        id: 'a11b3f12-64b1-4c3d-991e-56583ee39874',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        name: 'Morning',
        description: 'Section Description',
        displayOrder: 7
      },
      {
        id: '69fd031e-9945-4434-847e-d071a94837ab',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        name: 'All Day',
        description: 'Section Description',
        displayOrder: 8
      },
      {
        id: 'e7932ff7-19eb-470b-80ee-4b3822892ba6',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        name: 'Salumi Misti',
        description: 'Section Description',
        displayOrder: 9
      },
      {
        id: 'd6aa54d5-f2be-4c86-9887-084bbf9a6508',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        name: 'Formaggi Misti',
        description: 'Section Description',
        displayOrder: 10
      },
      {
        id: '56133c35-9e48-4168-afc6-68d55f57fd3c',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        name: 'Formaggi e Salumi Misti',
        description: 'Section Description',
        displayOrder: 11
      },
      {
        id: '53dcff0e-2928-47ab-9629-f206394fa499',
        menuId: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        name: 'Spumante',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: 'be9c333a-7f21-4623-bbe6-239ff2453fbd',
        menuId: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        name: 'Bianco',
        description: 'Section Description',
        displayOrder: 2
      },
      {
        id: 'aeafb54d-9c69-4774-a195-5b12098951d0',
        menuId: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        name: 'Rosato',
        description: 'Section Description',
        displayOrder: 3
      },
      {
        id: '88035f39-3512-45b0-a823-a3bb6bef3dc0',
        menuId: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        name: 'Rosso',
        description: 'Section Description',
        displayOrder: 4
      },
      {
        id: '25bc8f71-9766-4e42-bc6a-e192042423c0',
        menuId: '0d180b38-3e5f-464d-9286-d2f5150b2cb0',
        name: 'Cocktails',
        description: 'Section Description',
        displayOrder: 1
      },
      {
        id: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        name: 'Coffee',
        description: 'Section Description',
        displayOrder: 1
      }
    ],
    menu_subsection: [
      {
        id: 'fed0707b-8983-416e-8eba-a4698c7bad2d',
        parentSectionId: 'b3217336-77ed-456d-a74f-fc2fbeb6cf41',
        childSectionId: 'ab715290-6234-40fe-9b22-6b0cc6b69e5f',
        levelNbr: 1,
        displayOrder: 1
      },
      {
        id: 'cb8a4cb2-ac5f-40dd-8d51-58454977c4e0',
        parentSectionId: 'b3217336-77ed-456d-a74f-fc2fbeb6cf41',
        childSectionId: '9fbc5fa7-53d1-466b-be02-4c9c39e0d202',
        levelNbr: 1,
        displayOrder: 2
      },
      {
        id: 'ed9ffe9a-0eb0-4a5e-89d9-a62f8d68a0a7',
        parentSectionId: 'b3217336-77ed-456d-a74f-fc2fbeb6cf41',
        childSectionId: '503930e0-a23c-4d97-a984-93a26b8b1d87',
        levelNbr: 1,
        displayOrder: 3
      },
      {
        id: '0a3859f9-4002-4a22-841f-e6b7dc8a4cd2',
        parentSectionId: 'b3217336-77ed-456d-a74f-fc2fbeb6cf41',
        childSectionId: 'b172f070-cdd7-4e5d-829c-52df9b189da3',
        levelNbr: 1,
        displayOrder: 4
      },
      {
        id: '52a02188-3049-4143-b7ec-1322fa1c1c1b',
        parentSectionId: 'b3217336-77ed-456d-a74f-fc2fbeb6cf41',
        childSectionId: 'f6825a58-237b-49ee-84c6-ee17299b70ad',
        levelNbr: 1,
        displayOrder: 5
      },
      {
        id: '527859e0-ccca-46d1-9163-ffd2e58bf487',
        parentSectionId: '6d323cfe-ce18-4c3c-9626-7a634739eb1e',
        childSectionId: 'e60c17e6-ec69-4cbd-94b3-c62809393330',
        levelNbr: 1,
        displayOrder: 1
      },
      {
        id: 'f4575f03-d514-4452-a8e3-48a53bc98f7d',
        parentSectionId: '6d323cfe-ce18-4c3c-9626-7a634739eb1e',
        childSectionId: 'c70a2573-09cf-4a16-a5d2-4c3befe5b6ae',
        levelNbr: 1,
        displayOrder: 2
      }
    ],
    item: [
      {
        id: 'caa0d776-3951-4eee-b964-d9794eaaa7f0',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Dumplings',
        description: 'mom’s “mostly cabbage, a little bit of pork” dumplings, sacha soy dressing'
      },
      {
        id: '2e5d890b-69ce-41f7-b9af-cbc30380a6d4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Prawn Toast',
        description: '“prawn toast x okonomiyaki” kewpie mayonnaise, bull-dog sauce, shaved cabbage, aonori'
      },
      {
        id: '33b5eb91-fae0-425b-81d7-947bd8d89ccd',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chicken Wings',
        description: 'chongqing-style chicken wings, heaven-facing chillies, dried'
      },
      {
        id: 'ad47d525-b216-431d-a2c4-aa2d4d47a5e7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chiles',
        description: 'chillies, sichuan pepper'
      },
      {
        id: '4871c493-9f5c-4057-bab7-22f164e321d1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Steak Tartare',
        description: 'wagyu steak tartare, cured egg yolk, knife cut chilli powder, sorrel'
      },
      {
        id: '12502bbb-fcd0-4127-83ad-988a6bb1bbe6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Eggplant Salad',
        description: 'smoked eggplant salad, fermented chillies, red cabbage, vietnamese mint'
      },
      {
        id: 'd7da450e-a132-4993-b0c3-21fceec604db',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Calamari',
        description: 'grilled calamari, caramelised onion and tamarind jam, pickled cucumber, vietnamese shiso'
      },
      {
        id: 'ef09c2a8-dfe4-4bd7-9197-a09ce7d337bc',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Bean Salad',
        description: 'wing bean and edamame salad, vegetarian XO, garlic sauce, toasted rice'
      },
      {
        id: '5ced7264-2af8-44a2-9c98-6144a00a9e69',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Bamboo Salad',
        description: 'bamboo salad, pickled lotus root, snow peas, five-spiced tofu, sesame'
      },
      {
        id: 'ebf8ba65-6429-46bf-84a1-ca3d1e09eb07',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'roast goose'
      },
      {
        id: '21aefdf2-6507-4d5a-b38d-9fdd4c6cff08',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'crispy skin chicken'
      },
      {
        id: 'a867c35b-2946-443e-9e78-c1c3fc74c507',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'kurobuta pork char-siu'
      },
      {
        id: 'bbca1565-8926-44cb-bcf8-d87116778cb3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'roast platter'
      },
      {
        id: '05c0e0ad-050a-4981-aed3-5f0c9edbdf51',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Mixed Vegetables',
        description: 'asparagus, broccoli, sugar snap peas, garlic, shaoxing'
      },
      {
        id: '12ce089f-a9d3-4b28-ba8f-f1ae00aeec9f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Fried Corn',
        description: 'fried corn, typhoon shelter-style, crispy garlic crumbs'
      },
      {
        id: '058375eb-7061-4f3c-883b-b1528fd87663',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Spinach',
        description: 'sautéed baby spinach'
      },
      {
        id: 'b7725007-1a6d-4447-8fb4-60dedaf9ca86',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Claypot Cabbage',
        description: 'sizzling claypot cabbage, tomato anchovy sambal, fish sauce'
      },
      {
        id: '6eda1090-f6f2-46ba-854d-23fa9088772b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Mapo Tofu',
        description: 'mapo tofu, minced pork, broad bean chilli paste, pickled turnips'
      },
      {
        id: '5f102881-b23e-4490-8c2d-37956978a812',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Mustard Greens',
        description: 'stir-fried pork mince, fermented mustard greens, vietnamese mint, toasted chilli flakes'
      },
      {
        id: '14d787fe-687f-46dc-b062-30b0b4f13bac',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Beef Tenderloin',
        description: 'black angus beef tenderloin, black peppercorn, celery, cashews***'
      },
      {
        id: '5deba2d8-dba4-4d8c-a334-0f8cab8dceb9',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Fried Barramundi',
        description: 'jack’s fried salt and pepper barramundi, silken tofu, lemon'
      },
      {
        id: '40ed3fa1-b4b1-4575-a6f2-9082e2457014',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Steamed Barramundi',
        description: 'steamed barramundi, ginger, green shallot'
      },
      {
        id: '3a06b963-3546-4643-812d-b11d7e0c9ab1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Snapper',
        description: 'whole grilled star snapper, fermented beans, chopped chilli, dill, green shallot oil'
      },
      {
        id: '4dec6127-dc72-4242-b609-92a65a9cf3f7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Wagyu Short Rib',
        description: 'roast wagyu short rib, jalapeño purée, green shallot kimchi, soy glaze'
      },
      {
        id: '2f289069-35f7-48e4-954a-08e700755189',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Fried Rice',
        description: 'egg fried rice, crispy garlic, green shallot'
      },
      {
        id: 'ba3011a8-d114-4dbd-8979-151577345814',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Prawn Fried Rice',
        description: 'prawn and bacon fried rice, chinese preserved vegetables, shellfish oil'
      },
      {
        id: '5328a6e4-a3b6-42fa-a0b1-377c1b83a555',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Rockpool Fried Rice',
        description: 'rockpool fried rice, salted whitebait, sakura ebi, aonori'
      },
      {
        id: '9d833901-a737-45b9-a6e9-1b4df8ed2823',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Dan Dan Noodles',
        description: 'wagyu dan dan noodles, sesame paste, crispy panko, rice paddy herbs'
      },
      {
        id: 'ccc3a136-508e-40a3-b08b-dce54fcd7884',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Seafood Chow Mein',
        description: 'combination seafood chow-mein, tiger prawns, calamari, snow peas'
      },
      {
        id: 'f7abf5af-4d67-4a91-a919-12f4dcc82ee2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Cheong Fun',
        description: 'wok-fried cheong fun with XO, white garlic chives, toasted sesame'
      },
      {
        id: 'aeb06d0e-fef3-49c8-9d29-255ca5eb664f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Shaanxi Noodles',
        description: 'shaanxi-style scissor-cut noodles, seasonal vegetables'
      },
      {
        id: '45b7823b-d401-4477-856f-a4d74599b127',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Steamed Rice',
        description: 'mix your own steamed rice, seaweed, toasted sesame, pork floss, sesame oil'
      },
      {
        id: '3584fdd3-ab62-47d1-841b-36bf622b19fb',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Breakfast 2.0',
        description: '“breakfast 2.0” horlicks ice cream, cornflake honey joy, walnut oatmeal, dried longan, cocoa coffee crumbs'
      },
      {
        id: '3da4cc71-a858-4403-a27e-79d90cde7ea1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: "Guard's Pudding",
        description: 'guard’s pudding, candied lemon, kavalan whisky cream'
      },
      {
        id: '92fa03dd-0781-408f-b0aa-c844a09c3601',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chinese Sorbet',
        description: 'chinese date and yoghurt sorbet, taiwanese guava, salted plum powder, yakult granita'
      },
      {
        id: '4c04eb4b-319e-45c7-96a6-c338d4d42f98',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MURGH TIKKA ANGAR',
        description: 'Spice-marinated chicken, liver chutney'
      },
      {
        id: 'c147aaa6-f44e-4efc-ab8f-13ef2a2d2aff',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TANDOORI LOBSTER',
        description: 'Boston lobster, cucumber raita, laccha paratha'
      },
      {
        id: 'cf38174c-c5e9-45ca-b790-2dea6cd70db8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'NIMBU JHINGA',
        description: 'Prawns, burnt garlic chutney'
      },
      {
        id: '0fbd894d-4944-40e7-bc85-59c1b23587e5',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TANDOORI COBIA',
        description: 'Line-caught Cobia, smoked tomato & samphire chutney'
      },
      {
        id: 'd585ea90-1c50-4d2c-8dc1-a1375884965d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MASALEWALI CHANP',
        description: 'Lamb chops, ember-roasted onion'
      },
      {
        id: '2da976fc-1862-4e6d-b1be-e0ebc67d78a1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MOGEWALA PANEER',
        description: 'Spiced soft cheese, beet kachumbar'
      },
      {
        id: '7763a735-644c-4ff6-9115-1d344a345d84',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TANDOORI GOBHI',
        description: 'Cauliflower, green mango raita, gunpowder'
      },
      {
        id: '104d0291-b25a-44c9-94fb-5240d94f5a95',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MUGHAL ROOM MAKHANI',
        description: 'Chicken tikka, spiced tomato, butter'
      },
      {
        id: '84a79c35-0c6d-4fed-b3e9-d20703954cc4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'JUNGLEE MURGHI MASALA',
        description: 'Guinea fowl, ground onions'
      },
      {
        id: 'da7f6980-6c1b-4d15-bb78-35ca51beeaa9',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'GOSHT SALAAN',
        description: 'Lamb shank, black cardamom'
      },
      {
        id: 'e51d6e5a-eb67-471f-bfea-e189c4f88bc2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SAAG PANEER',
        description: 'Spinach, soft cheese, garlic'
      },
      {
        id: '13993ba3-9d2c-4f84-9c84-f4edd8a1bceb',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'DUM ALOO',
        description: 'Papa Coctel Peruvian potatoes, tomato, green cardamom'
      },
      {
        id: 'f8fb4b47-6431-4a82-a52f-8fec4bae985b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'DAL MAKHANI',
        description: 'Slow-cooked black lentils, churned butter, fenugreek'
      },
      {
        id: '7ff4f131-ca30-4f8c-9a45-0fbae8322887',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BASMATI RICE',
        description: 'Aromatic rice'
      },
      {
        id: 'f8849f65-4834-4e49-b6fa-317cb9e4491b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SAMOSA CHAAT',
        description: 'Smashed samosas, crispy noodles, tamarind glaze, yoghurt'
      },
      {
        id: '8908dc7c-3a20-415a-8711-2952b6a150b3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'KEEMA PAU',
        description: 'Spiced mutton, milk buns, crisp potatoes'
      },
      {
        id: '2ad099c0-3ccf-4f0d-8026-7b69854a7cb0',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TANDOORI ANDA',
        description: 'Taiyouran eggs, kachumbar, smoked salt'
      },
      {
        id: '70922818-6f32-4b44-b687-70aace42de20',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'AMRITSARI MACHLI',
        description: 'Batter-fried sole, dill raita'
      },
      {
        id: '2b8958ff-8575-4346-abf0-bb789fed51d7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'HARA SALAAD',
        description: 'Carrot, cucumber, mooli, turnip'
      },
      {
        id: 'f1a21389-54fc-4cc9-b821-e9d513cf2c2d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PYAZ AUR MIRCHI SALAD',
        description: 'Nasik onions, green chilli, nimbu'
      },
      {
        id: '963b06b9-529d-413a-9d5b-e6ac9774b408',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHUTNEYS'
      },
      {
        id: 'e55cc037-78c4-49a0-b92d-476ae1b3fc21',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'RAITA'
      },
      {
        id: '44d2f903-0bd2-4a32-aee3-8eac28079def',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BUTTER NAAN'
      },
      {
        id: '536bb4c1-8570-4156-b0ff-ecf3c85b774a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TANDOORI ROTI'
      },
      {
        id: 'da28b044-f8ec-41d7-a667-3615bc10d8b5',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PANEER KULCHA'
      },
      {
        id: '403bd6c3-28ac-4a83-8b47-f653f5c19fe2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LACCHA PARATHA'
      },
      {
        id: 'e80e96ce-094d-4e00-82fe-dabda270ce15',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'OYSTERS',
        description: 'half-dozen'
      },
      {
        id: '063dcab1-90ab-4708-962b-d4ed25d8211b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'HAMACHI',
        description: 'cherry tomato, olives, basil'
      },
      {
        id: '94614e3d-7e03-4a0c-82ba-f3687cea63de',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'HOKKAIDO SCALLOPS',
        description: 'finger lime caviar, pomelo, grapefruit citronette'
      },
      {
        id: 'f859a444-a8d9-4ddb-9d58-47442affb91e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CANNOLICCHI',
        description: 'razor clams, salsa verde, caper, lemon'
      },
      {
        id: '35f3182d-4106-40ce-994e-1cdc149a3ea4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CALAMARI FRITTI',
        description: 'red chilli, green chilli'
      },
      {
        id: '67be4b05-232f-4782-aa9e-c3f5f62fa1d6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'FRUTTI DI MARE',
        description: 'market seafood, tomato, basil, grilled bread'
      },
      {
        id: '9990c205-12f2-4f6a-977a-6779664ea176',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PASTA CON LE SARDE',
        description: 'sardines, saffron, wild fennel, pine nuts, Sicilian bread crumbs'
      },
      {
        id: '92a3d061-513b-43ed-abaa-af1f4b972b1e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHITARRA',
        description: 'Boston lobster, cherry tomato'
      },
      {
        id: '08f97d7f-3b7a-459a-bbdb-25031418ef41',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SPAGHETTI A.O.P',
        description: 'aglio olio e peperoncino'
      },
      {
        id: 'ff900bb8-2de8-4677-99ae-dbd872410dcc',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CAVATELLI',
        description: 'market fish, cherry tomato, basil'
      },
      {
        id: '20323919-fa69-4bc2-b93d-20c379ccee07',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CRUDO DI DENTICE',
        description: 'snapper, pomegranate, citrus'
      },
      {
        id: '22c02cd8-df1c-4655-8820-fce47be19855',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SEPPIA',
        description: 'cuttlefish, Sorrento lemon, extra virgin olive oil'
      },
      {
        id: '188d1241-dc0b-4736-ad5f-fbc6d97f2d38',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LUMACHE DI MARE',
        description: 'braised whelk, potato puree'
      },
      {
        id: '3b000ba7-a66d-4d8a-a601-a821d4ec6e3e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MEZZI PACCHERI CON MAZZANCOLLE',
        description: 'half paccheri, tiger prawn, broccoli, almonds'
      },
      {
        id: '642e9138-8ed8-467a-9155-2ad5040f0ce3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BURRATA',
        description: 'beets, sorrel, pistachios, balsamic reduction'
      },
      {
        id: '7e09aa92-0c17-468a-af78-4595d6afc29a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'INSALATA MISTA',
        description: 'mixed leaves, agrodolce dressing'
      },
      {
        id: '2b7a3224-d3ca-40b4-b8eb-01b59b031a6e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'POLLO AL MATTONE',
        description: 'spiced chargrilled chicken'
      },
      {
        id: '99d435e7-aa61-409d-87ea-9d23a8bcb8a7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'FILETTO',
        description: '10oz USDA Prime Fillet, arugula, cherry tomato, parmesan, aged balsamic vinegar'
      },
      {
        id: 'c3f10f89-683f-440d-9c53-bbd477a48536',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PESCATO DEL GIORNO',
        description: 'market fish and your choice of preparation: simply grilled, puttanesca, brown butter caper sauce, salt-baked, acqua pazza'
      },
      {
        id: '123dfa37-eca0-4124-bf5a-c7df977ff560',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ZUCCHINI FRITTI'
      },
      {
        id: 'b392313e-c935-458b-ad67-95b8b7452aa5',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'FIRE-GRILLED VEGETABLES'
      },
      {
        id: '8441f6b1-2a60-411c-a34b-504f3fbfefa3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BROCCOLINI, GARLIC & CHILLI'
      },
      {
        id: 'ecb49e47-fd8e-4140-95f9-2142f36795d9',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'POTATOES & ROSEMARY'
      },
      {
        id: '110fd0e1-eb46-4594-8aa1-1256bbdb933b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PANNACOTTA AL MIELE',
        description: 'panna cotta, millefiori honey, mixed berries'
      },
      {
        id: '7327dd7d-4388-4cf5-954b-a67e3a58af98',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PASTIERA NAPOLETANA',
        description: 'ricotta, orange blossom'
      },
      {
        id: 'f3d022e1-4a03-4df1-ae1f-180335f36700',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'RUM BABA (SERVES 2)',
        description: 'limoncello chantilly'
      },
      {
        id: '0a58e61f-be72-4b81-9738-44fe28b43225',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CANNOLI SICILIANI',
        description: 'ricotta, chocolate chips, cinnamon, candied orange'
      },
      {
        id: '20c46971-8f95-4b7c-be54-fcc828c2d94c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'FRESHLY CHURNED GELATO'
      },
      {
        id: 'c9f8c640-0c90-4397-9e2c-af84918d8d87',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Set Lunch',
        description: 'choice of antipasto and piatto principale',
        price: 238
      },
      {
        id: 'eb02ba19-b52c-48f6-a0bf-8b115ff81306',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: "Sommelier's selection",
        description: 'Wine by the glass',
        price: 68
      },
      {
        id: '7c69a3b0-3038-4918-afae-7efbd2e6d365',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Insalata Mista',
        description: 'mixed salad'
      },
      {
        id: '85bdbee0-dda5-460a-946e-e77c635edc7c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Zuppa di Stagione',
        description: 'seasonal soup'
      },
      {
        id: '6b24e8fd-836b-4f84-bdcc-ca1121261f8b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Crudo del Giorno',
        description: "Chef's daily crudo",
        price: 68
      },
      {
        id: '54b90cfa-bdc6-456b-a07e-3c6e81b7e7cd',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Pasta Fresca',
        description: 'daily pasta'
      },
      {
        id: '9deb3c97-1e71-4a0e-b4be-0497064f303f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Linguine Alle Vongole',
        description: 'Hokkaido clams, parsley, chilli'
      },
      {
        id: '228ec363-dc80-4960-b78e-455be4c09c31',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Pescato del Giorno',
        description: 'catch of the day'
      },
      {
        id: '6e3052b2-e5e8-4d6e-a357-9d2b0303f09a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Pollo alla Griglia',
        description: 'spiced chargrilled chicken'
      },
      {
        id: '8c76b5e5-14d7-4291-998b-b81b48f2e69d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Filetto',
        description: '150g USA Prime Fillet, arugula, cherry tomato, parmesan, aged balsamic vinegar',
        price: 68
      },
      {
        id: '62af99f7-dc02-42f2-a203-6d92d852b073',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Gelato',
        description: 'freshly churned gelato',
        price: 28
      },
      {
        id: '2041533d-4ae4-4234-bf9a-0170204f9aca',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Coffee',
        price: 38
      },
      {
        id: '74c05ef4-13da-409a-ada7-cf7794c1a545',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Tea',
        price: 38
      },
      {
        id: 'd1b44a86-3a4d-41f0-9808-b1b71d3bb138',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Orange Juice',
        description: 'fresh-pressed',
        price: 38
      },
      {
        id: '63d0af9d-3921-4400-b514-7d1b6868f233',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Grapefruit Juice',
        description: 'fresh-pressed',
        price: 38
      },
      {
        id: '5fe148b8-3acc-4e91-82af-19cad3383ed1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BISTECCA ALLA FIORENTINA',
        description: '1kg T-bone steak, thick-cut and cooked medium rare in true Florentine fashion - Serves 2 people'
      },
      {
        id: '779ccb52-66b4-4bed-8b99-f6cf6d14ead0',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'COSTATA ALLA FIORENTINA',
        description: '1kg bone-in ribeye steak, rich and marbled - Serves 2 people'
      },
      {
        id: 'a921c7f9-a9a3-4038-a5ec-dd926b871db4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'COSTATA',
        description: '350g boneless ribeye, rich in flavour'
      },
      {
        id: '7da109fe-18cc-455d-8071-ccd40a4afd6a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CONTROFILETTO CON L’OSSO',
        description: '450g bone-in striploin, thick-cut'
      },
      {
        id: '63bad4c2-5e74-4bec-9198-0012f078d0b2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'FILETTO',
        description: '250g tender fillet steak'
      },
      {
        id: '9e124326-56fc-4956-8ff4-8807002eea21',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'POLLO AL BURRO OMAGGIO A SOSTANZA',
        description: 'Chicken breast pan-fried in brown butter, our nod to the Florentine institution Trattoria Sostanza'
      },
      {
        id: '6926c356-85b8-476d-9ca2-5b4b4586d308',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CROSTINI MISTI',
        description: 'Three rustic crostini topped individually with chicken liver, tomato with garlic and braised cannellini beans'
      },
      {
        id: '31d88a56-9b7c-4df5-b42d-68457a8d0d33',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SALUMI MISTI',
        description: 'A selection of Italian cured meats'
      },
      {
        id: 'b07930d0-2ec1-4f74-9562-6082fc4f68be',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CARNE CRUDA',
        description: 'Tuscan steak tartare with Carta di Musica crackers'
      },
      {
        id: '92922d9d-48c5-445f-8741-60a5c7ee8210',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PECORINO AL FIENO',
        description: 'Pecorino stagionato with fresh pear and thyme-infused honey'
      },
      {
        id: '0f7657d3-4330-4772-8220-a70712373d54',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PANE PECORINO E ACCIUGHE',
        description: 'Grilled country bread with roasted semi-stagionato pecorino and anchovies'
      },
      {
        id: 'cd34f527-95a8-4d6e-a697-7d96269da92b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'INSALATA CESARE',
        description: 'Mixed chicory leaves with anchovies, parmesan and lemon dressing'
      },
      {
        id: 'df558f4a-513c-4ba8-92ae-1e0b5aa1d8c4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PICI',
        description: 'Hand-rolled pasta with cavolo nero, pistachio and pecorino'
      },
      {
        id: 'af039508-908a-4af4-8f66-086663e5cd3a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TAGLIATELLE',
        description: 'Ribbons of pasta with white truffle and butter'
      },
      {
        id: '945bab34-2dd4-46b9-8ac8-6bbeb0e3455c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'RIGATONI',
        description: 'Pecorino and black pepper'
      },
      {
        id: '534e1941-b744-4e71-a0bb-475878776dc7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TUSCAN BRODO',
        description: 'Bone broth, farm egg and parmesan'
      },
      {
        id: '849e0241-3f21-4e4b-bfd0-037c5262edf9',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'GARGANELLI OMAGGIO A TRATTORIA CAMMILLO',
        description: 'Pasta with a Chianti beef ragu and parmesan inspired by Florence’s Trattoria Cammillo'
      },
      {
        id: '4e0c2045-d31a-4bca-ba10-8cafdc121430',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SPINACI',
        description: 'Braised spinach with olive oil, garlic and lemon'
      },
      {
        id: 'd9963c6b-da30-48f1-b6a7-6ee19bbfa562',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PATATA',
        description: 'Roasted potato with whipped lardo and rosemary'
      },
      {
        id: '255179e4-8bf0-4ffa-be1e-7aebed60ecd2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'FAGIOLI',
        description: 'Braised cannellini beans in soffritto and olive oil'
      },
      {
        id: '2ccb0827-146c-4752-aa68-97e0900a5590',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BROCCOLINI',
        description: 'Grilled broccolini, bagna cauda and lemon'
      },
      {
        id: '30169eb5-8a72-415a-be53-4edad76c37b2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Crown',
        price: 788
      },
      {
        id: 'aca1d615-4abb-40c6-8f22-e333fa702f38',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Mizuna Salad'
      },
      {
        id: '2bb22804-67b3-4f72-8232-482ed7bedcc5',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Tokushima Fruit Tomato'
      },
      {
        id: '4d253a68-9fac-44fc-8af5-105d8ff5b77e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Grilled Seasonal Vegetables'
      },
      {
        id: '97e7231f-029c-4b7a-b384-b8b967ad9bc0',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'US Prime Tenderloin 180g OR US Prime Sirloin 200g'
      },
      {
        id: '16832860-6580-42a5-b27c-3aa56a35549c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Garlic Fried Rice'
      },
      {
        id: '67a3c8ae-ddc1-4307-bb3d-5f3472591cbd',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Pickles'
      },
      {
        id: 'd662b26a-c8d8-4b09-a56f-7e67770f5736',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Miso Soup',
        price: 988
      },
      {
        id: '9176236d-fd2a-4e8e-b72f-829a676c3044',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Super'
      },
      {
        id: '1dc69e71-9a96-48cb-978e-c7b1b18755cc',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Mizuna Salad'
      },
      {
        id: 'aa323c6b-d8dd-4f8d-b5a0-42a46bef51b0',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Assorted Toyosu Market Sashimi'
      },
      {
        id: '0a19b3a2-01f9-44da-b8bd-8060459a7e25',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Tokushima Fruit Tomato'
      },
      {
        id: '1e89ec40-4b3d-488c-b5d9-b14d66c5d197',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Hokkaido Scallop OR Tiger Prawn'
      },
      {
        id: '98774540-362d-4c02-9e99-0d474484b1d3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Grilled Seasonal Vegetables'
      },
      {
        id: '8e2f32a3-9e16-47c2-a0a7-75d909ef1dc8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Australian Wagyu Ribeye 150g'
      },
      {
        id: '100add08-a3d3-46fc-b3e5-1dead115f226',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Garlic Fried Rice'
      },
      {
        id: 'dd68d277-f433-4491-867a-a6d9e371b11c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Pickles'
      },
      {
        id: 'dae185ae-e825-4d0e-8c7a-c8b8efb919db',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Miso Soup'
      },
      {
        id: 'da5fcdf0-8e1b-4455-921b-8b0a001a99ad',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Deluxe'
      },
      {
        id: 'a39bb651-3b17-4395-8b0b-c9d17001c2d3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Mizuna Salad'
      },
      {
        id: '2a6ee159-9afd-45d1-974f-603879975317',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Assorted Toyosu Market Sashimi'
      },
      {
        id: '0c528ca2-31e8-4066-a08d-745403b6693e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Tokushima Fruit Tomato'
      },
      {
        id: 'f4405659-ea58-4d49-a79d-c1a9881f9982',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Teppan Spiny Lobster in Coral Butter'
      },
      {
        id: '956a220a-141a-46e2-88f6-f0ee7d38b36e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Grilled Seasonal Vegetables'
      },
      {
        id: '0c83f960-7a51-499f-892b-d5debbd12254',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'A5 Kagoshima Wagyu Tenderloin 120g OR A5 Kagoshima Wagyu Sirloin 140g'
      },
      {
        id: '1b7d223a-469e-483d-9412-23a21bd0d482',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Wagyu Garlic Fried Rice'
      },
      {
        id: 'd8fbf2f3-31c8-4fae-8adb-922e33e90718',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Pickles'
      },
      {
        id: 'df17d2c3-e978-4918-a1b1-59a273d311d2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Miso Soup'
      },
      {
        id: '20e35c65-8678-47e8-afd6-2d36a171b316',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Omakase Sushi',
        price: 268
      },
      {
        id: 'a3895648-1828-4b00-91b3-3f1a5e01c388',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Omakase Sashimi',
        price: 308
      },
      {
        id: 'c627f5c5-1c89-472d-81c4-923ec6ae1877',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Assorted Tempura',
        description: 'Tenshi No Ebi, Kisu, Eggplant, Pumpkin, Sweet Potato',
        price: 258
      },
      {
        id: '9f5c9084-c281-483b-827d-8ed6b4f87995',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Assorted Vegetable Tempura',
        description: 'Asparagus, Maitake, Sweet Potato, Eggplant, Pumpkin',
        price: 158
      },
      {
        id: '5396d8f8-195b-4cc5-9014-9c929b534357',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Uni',
        description: '1 piece',
        price: 208
      },
      {
        id: '142df1fe-0a53-40fa-be3a-578d1aaa6af1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Ikura',
        description: '1 piece',
        price: 118
      },
      {
        id: '72699a0d-5f3a-4887-b67c-882e88b9ab51',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'O Toro',
        description: '1 piece',
        price: 268
      },
      {
        id: 'a4e9bc72-088e-459b-99c6-2f39e92dc9d6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chu Toro',
        description: '2 pieces',
        price: 198
      },
      {
        id: '582461fb-2993-4fd7-b50a-f5d1d48ba7b0',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Akami',
        description: '2 pieces',
        price: 118
      },
      {
        id: 'a8e5898b-3db5-47bd-9e6d-4f84f25902e5',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Botan Ebi',
        description: '2 pieces',
        price: 188
      },
      {
        id: '453cbc30-2526-46c7-b070-fa36b7fdc50a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Salmon',
        description: '2 pieces',
        price: 78
      },
      {
        id: '71c4ddd9-7386-47ef-959a-6d5aea8c5436',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Kampachi',
        description: '2 pieces',
        price: 78
      },
      {
        id: '3bd68586-90cc-423d-9901-470a23618394',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Hirame',
        description: '2 pieces',
        price: 88
      },
      {
        id: '52cf837f-7c9f-4b71-ba53-b5957d764667',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Amaebi',
        description: '3 pieces',
        price: 108
      },
      {
        id: '70cf1fdb-476f-4499-9100-8beb25220649',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Uni',
        description: '10 grams',
        price: 208
      },
      {
        id: '3ace0649-6035-4ccd-8072-871f9097b1c5',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'O Toro',
        description: '2 pieces',
        price: 358
      },
      {
        id: '98de1fcc-2195-49c6-bd2f-bda8c25f2ebd',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chu Toro',
        description: '2 pieces',
        price: 178
      },
      {
        id: '9fa56868-d403-4636-b974-419bb1e21ea4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Akami',
        description: '2 pieces',
        price: 108
      },
      {
        id: 'a2eba57d-386c-4901-a479-951c693c57aa',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Salmon',
        description: '2 pieces',
        price: 58
      },
      {
        id: '4c569e6c-2908-41f6-8a7c-12152f2a934f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Kampachi',
        description: '2 pieces',
        price: 68
      },
      {
        id: 'cb3e40a9-729d-452a-92be-ffda070c58df',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Hirame',
        description: '2 pieces',
        price: 78
      },
      {
        id: '0aca68d0-3ddf-44e5-8548-fd20130db18c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Tenshi No Ebi',
        description: '1 piece',
        price: 108
      },
      {
        id: '8082711e-da66-49f0-aac9-8aa0b6e846e4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Kisu',
        description: '1 piece',
        price: 98
      },
      {
        id: '71b3d698-1438-4cc7-8a1f-4c22982abb94',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Maitake',
        description: '50 grams',
        price: 78
      },
      {
        id: '8fe8e9de-c936-4933-b6a3-4368dc786e50',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Asparagus',
        description: '1 piece',
        price: 68
      },
      {
        id: '8cbefded-dcbe-4320-9ce3-3c509c42c940',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Sweet Potato',
        description: '2 pieces',
        price: 68
      },
      {
        id: '702216eb-a728-44e6-862c-82405d429e3d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Kappa Maki',
        price: 108
      },
      {
        id: 'fbf3912b-ff05-4691-883e-38ac581916dd',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Kampyou Maki',
        price: 108
      },
      {
        id: '7c077079-9937-430e-88b5-6fc7b90946c6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Negitoro Maki',
        price: 188
      },
      {
        id: 'ddb50aa9-325f-4f36-9d0e-9bd297642677',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Foie Gras',
        price: 178
      },
      {
        id: '91f51ad2-71e2-494d-aeba-3a15f2a7e842',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'US Prime Sirloin',
        description: '200 grams',
        price: 388
      },
      {
        id: '8d69572b-e31d-4b65-ba05-3bc7b3e7411e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'US Prime Tenderloin',
        description: '180 grams',
        price: 388
      },
      {
        id: '8ffc248b-ce22-4fd3-be5b-259a47db47f1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Australian Wagyu Ribeye',
        description: '150 grams',
        price: 458
      },
      {
        id: '0fd6d048-c969-4e93-9826-9b4cf20e3367',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'A5 Kagoshima Wagyu Sirloin',
        description: '140 grams',
        price: 688
      },
      {
        id: 'ad1aa36e-4f4c-4f55-a6cd-7d181e3164f1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'A5 Kagoshima Wagyu Tenderloin',
        description: '120 grams',
        price: 688
      },
      {
        id: '72af8f2c-86f5-44d7-91bd-cc3672b04e6e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Hokkaido Scallop',
        price: 128
      },
      {
        id: '9050348e-13bd-46bc-8221-0c2404c96ebb',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Tiger Prawn',
        price: 138
      },
      {
        id: 'd8d1cdfd-3572-4159-93d4-addc6ff1d12c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Amadai',
        price: 158
      },
      {
        id: '98e91b7c-8716-4cd1-9357-3c16e93dfddd',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'King Salmon',
        price: 178
      },
      {
        id: '64130dac-6922-4f58-9188-55f6912b018f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Spiny Lobster',
        price: 688
      },
      {
        id: 'c284b34d-c01e-4041-a82f-5ead332e299b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Edamame',
        price: 38
      },
      {
        id: 'cb7ca159-c2ad-4628-b9c5-1ea3f6fb7060',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Sweet Potato',
        price: 48
      },
      {
        id: '9a588659-4465-4cab-bfc6-b7b3a8ca208e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Pumpkin',
        price: 48
      },
      {
        id: 'e70ab813-ac07-404e-bbd9-eb70ad644088',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Spinach',
        price: 88
      },
      {
        id: 'eb096692-d69f-4805-ac4d-8f5e62520243',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Asparagus',
        price: 108
      },
      {
        id: 'b0e27eb3-b9b9-4d89-b42f-646d6943030e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Maitake',
        price: 108
      },
      {
        id: 'a0aad60d-a174-406c-811e-6ecd7933b06d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TARAMASALATA'
      },
      {
        id: '9590d45d-73b8-4ad9-8dfd-8c0e7f170f62',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TZATZIKI THEODOROS'
      },
      {
        id: '32d68eb4-3490-4e11-9e5c-a9308ba44079',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MARINATED OLIVES'
      },
      {
        id: 'd0981624-5658-4503-9d2b-b39403268639',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'HUMMUS'
      },
      {
        id: '18515dae-09ab-431b-a7ed-c92b92c2f824',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ROASTED EGGPLANT'
      },
      {
        id: 'd2d922b3-7bb4-4308-a039-57119bf8273f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'VEGETABLE CRUDITÉS'
      },
      {
        id: 'fb53bf49-7327-4599-a5d5-c256f6c19b52',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WARM PITA'
      },
      {
        id: '0289e7a1-0556-4b13-9b8b-cb8f057aaa7b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'FAVA MASH'
      },
      {
        id: '6a25fd16-46df-44e7-b82e-ba6f7d60191c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'AVGOLEMONO TIS GIAGIAS'
      },
      {
        id: 'ba8a5ec7-bb0d-4f62-9df5-c2d08f3dc18a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SAGANAKI PLATANOS'
      },
      {
        id: '6b08668e-553e-4927-8359-88da03ee0eaa',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SPANAKOPITA'
      },
      {
        id: '422d7a54-7609-4642-b65f-bddbccbe2241',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PORK SOUVLAKI'
      },
      {
        id: '91162354-e05d-4d78-9a88-36ca6935f5f7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHICKEN SOUVLAKI'
      },
      {
        id: '55891417-a49c-4361-9cbb-36e6bcc53188',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LOUKANIKO'
      },
      {
        id: 'e163245d-6482-4dd9-96d7-2108b3918db4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'STEKI TOU ILIA LAMB CHOPS BY THE KILO'
      },
      {
        id: '19e5d215-d499-4e46-9feb-0ef06ca85fcd',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'GREEK VILLAGE'
      },
      {
        id: '65c23ef9-2f83-4b47-8ba2-031d444c9800',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CYPRIOT'
      },
      {
        id: '6939c3da-c9d6-4fef-8a19-da4776e2ad0c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MARINATED ANCHOVIES'
      },
      {
        id: '69410c2b-bd42-4c10-a3bc-3499ecc88b76',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PRAWN SAGANAKI'
      },
      {
        id: '1e89bb93-8fd4-45bd-bfb1-f5f7cf8105f4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CALAMARAK'
      },
      {
        id: '9c0fda01-16cb-40ed-9363-235f91481f4e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'HORTA'
      },
      {
        id: '11c7fb83-8a6f-4f1f-bf87-c0c44b32a530',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PATATAKIA'
      },
      {
        id: '485c26e9-ab7b-46dc-a517-2402226aa284',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LABNEH CHEESECAKE'
      },
      {
        id: 'ca981f0f-908a-44b7-adb9-d24cdd784a08',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Lunch Feast',
        price: 178
      },
      {
        id: 'd3f1ecf7-4eca-4c71-895b-f9472033b1db',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TZATZIKI THEODOROS'
      },
      {
        id: 'b4c2069e-2278-4f66-b8d5-156508a28ec1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'HUMMUS'
      },
      {
        id: '06f02f5f-00a9-4491-8a7b-879aa39d7dc0',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ROASTED EGGPLANT',
        description: 'Served with crudites'
      },
      {
        id: '65a30d7d-9ac4-49b8-bd42-3642d947393e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'AVGOLEMONO TIS GIAGIAS'
      },
      {
        id: '5ae5dce3-0ea4-4aad-9ccb-2280b855040d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CYPRIOT SALAD'
      },
      {
        id: 'bc8fd61d-7255-4781-bdb6-becd4aac30d7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHICKEN SOUVLAKI',
        description: 'Served with warm pita'
      },
      {
        id: '4f68a000-a338-441b-96e5-e8fc186e37cd',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PORK SOUVLAKI',
        description: 'Served with warm pita'
      },
      {
        id: '5302dc6f-44c8-4573-aa63-a2ceec75012d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PATATAKIA',
        description: 'Served with warm pita',
        price: 58
      },
      {
        id: 'a2619d2d-252d-440b-b591-d81b5fefd54b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LABNEH CHEESECAKE',
        price: 68
      },
      {
        id: 'd11da2b1-134d-4bb7-b7f0-21f1342a26f4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'GREEK CHAMOMILE ICED TEA',
        price: 58
      },
      {
        id: '3d610abc-ef2f-4fca-b24d-84fe68ac3f32',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'FRESH-PRESSED WATERMELON JUICE',
        price: 58
      },
      {
        id: '99481ab3-9a47-4129-b350-1f74c3db248e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '961 LAGER',
        price: 68
      },
      {
        id: '4893fae4-79d5-4010-88ad-fd33ba865712',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'OUZO PILAVAS',
        price: 68
      },
      {
        id: '14dab063-a227-41c8-9e90-6aa03bba668a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'KIR-YIANNI, PARANGA WHITE 2018',
        price: 68
      },
      {
        id: 'eac414f2-36e6-4249-b71d-ae189ab3e12c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'KIR-YIANNI, PARANGA RED 2017',
        price: 68
      },
      {
        id: '19405fa5-a547-4df9-9f9c-ec532b7aed58',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'cashew and peanut fry',
        price: 48
      },
      {
        id: 'e536f226-7e52-4b1d-ace4-f3fc8deff536',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'chilli potato fry',
        price: 48
      },
      {
        id: 'bdb7ada5-eaf3-41a4-9f13-fce527a254bd',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'devilled shrimps',
        price: 128
      },
      {
        id: '0675bb54-8b26-4ad5-8958-9d4f0c3b1fb8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'idli, sambhar, podi, coconut chutney',
        price: 58
      },
      {
        id: 'f49f1217-b32b-452a-9ab6-8dcca3640288',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'bone marrow varuval, pol roti',
        price: 158
      },
      {
        id: 'b176dd86-9ebc-4e78-975e-b8c591b57c0d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'fish cutlets with hot sauce',
        price: 68
      },
      {
        id: '76d8fdce-2c10-4fe5-b614-07b35d317daf',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'beef chilli fry',
        price: 108
      },
      {
        id: '43f35d51-e44e-4925-9615-4acd5f6c6129',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'chicken kari',
        price: 138
      },
      {
        id: '6674fb73-6429-496a-aaa6-5c6cbfe368a6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'fish kari',
        price: 138
      },
      {
        id: 'caf8dfd9-f1f3-4bc4-b75e-c17df2e0a4ef',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'prawn kari',
        price: 158
      },
      {
        id: 'c217791b-ac86-433c-bd59-8cd84b5b96f1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'seasonal vegetable kari',
        price: 108
      },
      {
        id: 'ff728f93-c154-470a-9905-42d3bae37515',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'pol sambol',
        price: 28
      },
      {
        id: '66b32dd7-f3eb-4255-a86b-732dda7db6ef',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'seeni sambol',
        price: 28
      },
      {
        id: '40c7a8ab-0f06-4a2d-ba0d-3684578ef291',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'coconut curry leaf chutney',
        price: 28
      },
      {
        id: 'e14f9de2-76cb-4958-9898-1561da6a3d92',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'lunu miris',
        price: 28
      },
      {
        id: 'c0035c12-e5e0-4e9d-83be-70900d05388d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'pineapple and mango pickle',
        price: 28
      },
      {
        id: '2299e358-102c-4a6c-bb03-99ae01c8350e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'chicken kothu',
        price: 138
      },
      {
        id: 'e6fe2505-6268-407f-91a6-90a37e39ddbe',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'vegetable kothu',
        price: 118
      },
      {
        id: '46b365a1-6c2c-4b9f-8f53-0509e8d8b4b8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'shrimp kothu',
        price: 158
      },
      {
        id: '735e9f62-e240-4581-8c7f-8f9a83bef04a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'hopper with kiri hodi, pol sambol and lunu miris',
        price: 88
      },
      {
        id: 'a8f4c593-0ff6-431f-8945-a9451b6965a5',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'hopper with kiri hodi, pol sambol and lunu miris  with egg',
        price: 108
      },
      {
        id: '1a281073-9847-48d0-ac06-23c4b824fd1c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'plain dosa with sambhar and coconut chutney 88',
        description: 'with egg or masala'
      },
      {
        id: '279edca7-6560-429a-85de-9127cb10a6d2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'stuffed cheese parathas with gravy',
        price: 108
      },
      {
        id: '34bb7227-ae73-4b65-b015-db749d4b8d42',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'mud crab with choice of sauce',
        price: 688
      },
      {
        id: 'af065a7a-6ec4-4457-beb4-f72db85eafc6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'sambhar',
        price: 38
      },
      {
        id: 'edebaa5b-0c35-4807-99a2-9b1349c23d62',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'kiri hodi',
        price: 38
      },
      {
        id: '6e4f093f-c40b-48f7-98d1-17b714fe5c1b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'idli',
        price: 38
      },
      {
        id: '1e1a346a-7e0f-41ee-9675-381cc89f0642',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'brinjal moju',
        price: 58
      },
      {
        id: 'a0a92f50-643e-4afd-9b1e-52f5a9ad2a03',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'spinach mallum',
        price: 58
      },
      {
        id: 'd5c3186b-663b-4ada-9d9c-085e65920cfe',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'cabbage mallum',
        price: 58
      },
      {
        id: '7e280169-a72f-4c6d-8ffb-90d8a6470986',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'pol roti',
        price: 48
      },
      {
        id: '156af98f-fb3a-4dff-aa12-627c844d4d46',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'paratha',
        price: 48
      },
      {
        id: 'e33af736-f1a4-4627-891b-c58e49dd5967',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'plain hopper',
        price: 58
      },
      {
        id: '120f03ae-c74b-4bf5-98f8-198cb2d14530',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'egg hopper',
        price: 78
      },
      {
        id: 'b60cc6a4-db12-47f7-b6af-4cb02980907d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'steamed rice',
        price: 48
      },
      {
        id: 'bf59d5cb-0a90-4006-8bee-3d96d8de2404',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'yoghurt',
        price: 38
      },
      {
        id: 'ba8b3561-aaba-4d81-b8bc-eccc2b9bfecf',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CRISPY FISH',
        description: 'cabbage slaw',
        price: 32
      },
      {
        id: 'c62ec5c7-68a3-4237-a3e7-c731ccc5eb4c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SHRIMP A LA PLANCHA',
        description: 'chipotle chili, achiote, salsa roja',
        price: 38
      },
      {
        id: '1e29c551-698d-4b51-b214-f0d06e03b691',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'HABANERO-MARINATED STEAK',
        description: 'chili & roasted garlic-spiced beef, salsa Verde',
        price: 38
      },
      {
        id: '9cdb1e59-b293-4b06-8613-d38c84d52459',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'AL PASTOR',
        description: 'chipotle-marinated pork, pineapple, bacon',
        price: 42
      },
      {
        id: 'c5def5e3-be7b-469b-a4f9-f6a76f9ef0de',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ROAST CHICKEN',
        description: 'salsa verde, vegetables en escabeche, queso fresco',
        price: 42
      },
      {
        id: 'acb9b4f2-eb08-4d9f-9d2f-5d41f7f52fd8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'GRILLED AVOCADO',
        description: 'cabbage slaw, salsa verde, queso fresco',
        price: 38
      },
      {
        id: 'c2cf9ae4-2bd6-4dde-8b70-358b723346dc',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WARM TORTILLA CHIPS SERVED WITH',
        description: 'SALSAS',
        price: 72
      },
      {
        id: '9e05fe81-3731-43e2-bd84-e7680df5780e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WARM TORTILLA CHIPS SERVED WITH',
        description: 'BLACK BEANS',
        price: 82
      },
      {
        id: '54912817-0ad4-470e-8b1f-9a87e9a7bff9',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WARM TORTILLA CHIPS SERVED WITH',
        description: 'GUACAMOLE',
        price: 88
      },
      {
        id: 'aa851bdd-94f2-42e1-adbb-3897134fb72b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHARCOAL-GRILLED MEXICAN STREET CORN ',
        description: 'queso añejo, guajillo chili, lime',
        price: 42
      },
      {
        id: 'c3234913-4efa-4c8c-b6c8-7e2b6eba67fc',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'GRILLED CHICKEN TOSTADA ',
        description: 'guacamole, chiles toreados, sour cream',
        price: 72
      },
      {
        id: '5bf1701b-a809-4cb8-a03c-c8ded5e53b2f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'QUESO LOCO',
        description: 'grilled corn, oyster mushroom, pico de gallo',
        price: 98
      },
      {
        id: '2bf9594c-6af9-49ff-a3dc-86e42a0f7978',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'THREE CHEESE QUESADILLA',
        description: 'queso oaxaca, tomato chipotle mayonnaise, cabbage slaw',
        price: 72
      },
      {
        id: '00122b70-61e1-4d43-9eb8-0622475028f5',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MARKET CEVICHE',
        description: 'ask about our daily baja-inspired selections',
        price: 'MP'
      },
      {
        id: 'f1df35c1-39df-4224-9393-c1b57a009a90',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WARM TORTILLA CHIPS ',
        price: 42
      },
      {
        id: 'b59d89f0-3e38-4a4b-921c-afd7612142f8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TORTILLA BASKET ',
        price: 22
      },
      {
        id: 'c296e3ea-e3c3-48ba-b1c0-58809a319db5',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'GUACAMOLE',
        price: 58
      },
      {
        id: '2281add8-8ea2-4f97-9aac-44d73a1ee59a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Peanut Butter Pie',
        description: '9’’Chocolate Cookie Crumb, Dark Chocolate Ganache, Peanut Butter Mousse, Caramel Whipped Cream, Caramel Pearls, Chocolate Sauce',
        price: 308
      },
      {
        id: 'f10feda5-4164-4e07-80ba-39bf96922769',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'New York Cheesecake',
        description: 'New York-style Cheesecake, Vanilla Cookie Crumb Base',
        price: 650
      },
      {
        id: 'b71426b6-9f01-4a1c-81e4-c37e1a5f5e23',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Triple Chocolate Cake',
        description: 'Rich Devilsfood Chocolate Cake, Chocolate Butter Cream, Cocoa Nibs',
        price: 650
      },
      {
        id: '99998b1a-d4cf-4bf0-a633-e15b58143844',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Pineapple Upside-down Cake',
        description: 'Vanilla Sponge Cake, Fresh Pineapple, Cherries',
        price: 550
      },
      {
        id: '950656d1-c5fa-4077-8a46-27e91d58b59a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Carrot Cake',
        description: 'Carrot Cake, Classic Cream Cheese Frosting, Pineapple Bits, Coconut Sprinkles',
        price: 450
      },
      {
        id: 'f9878f58-b7e4-4633-b89f-90ceb5b03af3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Strawberry Shortcake',
        description: 'Vanilla Sponge Cake, Vanilla & Buttermilk Whipped Cream, Fresh Strawberries, Strawberry Purée, Lemon Zest.',
        price: 550
      },
      {
        id: '9bdb406a-b8d8-4fb2-8b57-39c27da1bdea',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Coconut Chiffon Cake',
        description: 'Coconut Chiffon Cake, Coconut Whipped Cream Frosting, Desiccated Coconut',
        price: 450
      },
      {
        id: 'be299032-53c3-4827-bfa4-ff44efaabfc3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Olive Oil Lemon Cake',
        description: 'Olive Oil & Citrus Cake, Rose Icing, Dried Rose Petals',
        price: 500
      },
      {
        id: '77a53e6d-51c5-464d-8905-1a1bbe542906',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Red Velvet Cake',
        description: 'Red Velvet Cake, Cream Cheese Frosting',
        price: 650
      },
      {
        id: '4ef948ff-059a-4447-ab65-0b0ff9c2d785',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Banana Cream Pie',
        description: 'Graham Cracker Crust, Sliced Banana, Pastry Cream, Caramel, Whipped Cream.',
        price: 400
      },
      {
        id: 'c8de36ca-6451-406b-8c01-ee03abd15f0f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Blueberry Crumble Pie',
        description: 'Flaky Pastry, Blueberry, Vanilla, Lemon Zest, Oats, Almonds, Brown Sugar',
        price: 400
      },
      {
        id: 'd2b4b105-6e0d-4db9-8b2f-45d6125ef3b1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Applie Pie',
        description: 'Buttery Biscuit Base, Sliced Apples, Cinnamon, Caramel, Flaky Lattice Top',
        price: 400
      },
      {
        id: '858e4876-f7d3-4d29-a950-df74333d453c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Key Lime Pie',
        description: 'Coconut cookie crust, velvety lime curd center packed full of citrusy flavors topped with toasted meringue',
        price: 400
      },
      {
        id: '1259a051-f6da-44ec-b0d8-6200046c8413',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Honey Butter Chess Pie',
        description: 'Buttery Pie Crust, Honey & Cornmeal Custard Filling, Icing Sugar',
        price: 400
      },
      {
        id: '9cddfdd1-73dc-430d-8bb0-b32439e335e2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Doggie Cake',
        description: 'Carrot Cake, Oats, Whole Wheat Doggy Biscuits (Sugar-Free)',
        price: 300
      },
      {
        id: 'f3243a44-8647-407d-901e-98ca9036800c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'OYSTER',
        description: 'YUZU PONZU'
      },
      {
        id: 'ec20170b-2d5f-4f69-85be-4b2b36e23000',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SWEET SHRIMP',
        description: 'FIVE SPICE SOY'
      },
      {
        id: '5f285b0f-b2c2-4e4d-8e60-584032b1dbbf',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MARKET FISH TARTARE',
        description: 'YUZU KOSHO'
      },
      {
        id: '286c9627-a014-429d-b0aa-5cc743421a39',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PLATTER OF MARKET FISH',
        description: 'SEAWEED SOY'
      },
      {
        id: '4c129190-b1ac-42cb-a765-c9ffdfaf571e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'KINGFISH',
        description: 'TOMATO JALAPEÑO PONZU SAUCE'
      },
      {
        id: 'dcb6c199-6b8d-4d4a-855d-da32f32c04b8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BEEF TARTARE',
        description: 'CHILLI, SESAME DRESSING'
      },
      {
        id: '7aab4a76-93a0-49b2-acf2-128aa17dd745',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PICKLED MACKEREL',
        description: 'YAMAGATA DASHI'
      },
      {
        id: 'f0bff9be-6e64-4c66-a59d-c85f969e68e7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SALMON',
        description: 'NANBAN SAUCE, CHILLI OIL, PINE NUTS'
      },
      {
        id: '2b189293-c4bf-43e1-9fba-05b095b777e8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SNAPPER',
        description: 'SHISO DRESSING, DAIKON'
      },
      {
        id: '2aaa40bc-f308-43bd-ba88-d27bd7fdb5a1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'EDAMAME'
      },
      {
        id: '92c1df8a-bdfc-465e-b5ce-3befba8054ca',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'DRUNKEN CLAMS',
        description: 'PLUM, SAKE, SHICHIMI'
      },
      {
        id: '36689690-8fb7-42f1-b23a-a61e0d866ad7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CRISPY CARAMEL BUTTER CORN'
      },
      {
        id: '3e6070e9-8b72-4283-8f2e-afa708b953a1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SEASONAL VEGETABLES',
        description: 'PICKLED IN YUZU'
      },
      {
        id: '3a013df0-4c9a-4382-99b2-4ebab5731722',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ONSEN TAMAGO',
        description: 'BACON, POTATO SALAD, CRISPY POTATOES'
      },
      {
        id: 'b7028fb8-9bd8-44ed-8931-cfc6a6883fa1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PRAWN GYOZA',
        description: 'MISO BISQUE'
      },
      {
        id: '61393f54-5df3-4e40-bfc1-17007050c70a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WAKAME SALAD',
        description: 'GINGER SOY DRESSING'
      },
      {
        id: '8553a4b0-7ad4-44a6-a1bc-d717311454e5',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'JAPANESE OMELETTE',
        description: 'GRATED DAIKON, SALMON ROE'
      },
      {
        id: '844ae218-2954-4051-b062-d55f331ccdcd',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CRAB CHAWANMUSHI',
        description: 'SNOW CRAB, CRAB DASHI'
      },
      {
        id: '3a233d2d-dba1-4a6c-9345-d52453b913d6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SPINACH OHITASHI',
        description: 'SWEET WHITE SESAME PURÉE'
      },
      {
        id: '0ddbdc8e-e465-4a2b-9f80-7d8f1253dd25',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BEEF TATAKI',
        description: 'DAIKON VINAIGRETTE'
      },
      {
        id: '380fbc4d-7236-40de-be8b-7c2a84c517bd',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SLOW-COOKED PORK BELLY',
        description: 'KARASHI, PICKLED CUCUMBER'
      },
      {
        id: '32d32b01-ac85-4f90-bada-0dd2adefe473',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'AGEDASHI TOFU',
        description: 'JAPANESE MUSHROOM VINAIGRETTE, PRAWN OIL, BONITO'
      },
      {
        id: '4cfadbda-20ac-477d-86a9-5a9e81a75e92',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SPICY CHICKEN KARAAGE',
        description: 'MIZUNA, CURRY VINAIGRETTE'
      },
      {
        id: 'e6cb1573-9960-4e73-adf9-01fd253d57fc',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SCALLOP MOZZARELLA',
        description: 'SHIITAKE, PORT WINE'
      },
      {
        id: 'e9a277c8-df49-4153-a526-83c9b951b333',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PRAWN TEMPURA',
        description: 'SPICY MAYONNAISE'
      },
      {
        id: '77f88b3d-f52f-40e7-b045-671d3e9f99da',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WHITING KATSU',
        description: 'SALMON ROE'
      },
      {
        id: '48f55b47-14f8-4f9a-af31-fed508492184',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHICKEN WING',
        description: 'WAGYU, PORK, MISO, DUKKAH'
      },
      {
        id: 'f52befa9-78e4-470d-837b-675c3e13bb56',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SOFT SHELL CRAB',
        description: 'MOZZARELLA, LEMON CHILLI DRESSING'
      },
      {
        id: '9bc697bf-ccf3-422d-a9ad-02fb81f5da8f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'GRILLED SCALLOP',
        description: 'OYSTER BUTTER'
      },
      {
        id: '90b9b021-7a29-4cc9-93af-22cbd5a19d2b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'HAMACHI COLLAR',
        description: 'FUJI APPLE PONZU'
      },
      {
        id: 'c58e1944-08b0-4263-91b0-f4c787018423',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SAIKYO MISO BLACK COD'
      },
      {
        id: 'be240cbb-59d4-460a-b592-9b6ac5d99e3d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'A4 WAGYU SUKIYAKI',
        description: 'CURED EGG YOLK, SMOKED NEGI'
      },
      {
        id: '99342b30-05fa-445d-ade4-9005af8cbab7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WAGYU ZABUTON',
        description: 'SEAWEED SOY, SMOKED OIL, ENOKI'
      },
      {
        id: '23efe49c-db15-43d3-93c4-fb694761ec1f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MISO EGGPLANT'
      },
      {
        id: '814ec234-55a9-416b-a443-38fc2e42d696',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'YAKI UDON',
        description: 'SNOW CRAB, CRAB MISO BUTTER'
      },
      {
        id: 'b918657a-1f33-4cf3-a450-a935956879c6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WAGYU KAMAMESHI HOT POT RICE',
        description: 'SANSHO MISO, CURED EGG YOLK'
      },
      {
        id: 'c2e3dfc0-a9a4-44c3-9a56-7c298447e862',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SASHIMI DON',
        description: 'MARKET FISH, SALMON ROE'
      },
      {
        id: '30937531-9748-4d32-b6df-ca73ea01bcb4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MONAKA ICE CREAM SANDWICH'
      },
      {
        id: '598e346d-ff85-4814-bc44-525a94dc7fec',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'EDO MISO POPSICLE',
        description: 'MOCHI, GREEN TEA CRUMBLE'
      },
      {
        id: '33d10df6-1098-446d-bff5-e495ce47b953',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'YOSHINOGAWA GENSEN KARAKUCHI (NIIGATA)',
        description: 'LIGHT, DRY & FOOD FRIENDLY - LEMON, MANDARIN & ROSE'
      },
      {
        id: '64c50d5c-0bd0-475a-9a70-e169c194d640',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'KIKUSAKI TOKUSEN (NAGANO)',
        description: 'SOFT, MEDIUM DRY & MELLOW - PASSION FRUIT & MUSHROOM'
      },
      {
        id: '2e95dcde-9a9b-407a-ad89-f1fdd9c5250a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'IBARAGI RAIRAKU KASEN (AKASHI)',
        description: 'MILD, MEDIUM DRY & BALANCED - COOKED FRUITS & FLOWERS'
      },
      {
        id: 'dbde21b0-bdcf-49b4-b94d-cb976722f365',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TSUBOSAKA HONJOZO KINTSUBO (HYOGO)',
        description: 'INTENSE & DRY - COCONUT & FRESH BANANA'
      },
      {
        id: '174c4965-8962-41a7-bc31-dcd4e6813e05',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ENASAN JUNMAI (GIFU)',
        description: 'FRESH & MEDIUM SWEET - EXOTIC FRUITS & PINEAPPLE'
      },
      {
        id: '28c7057b-360d-472f-8345-49413c980822',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'OKURAHONKE YAMAHAI TOKUBETSU JUNMAI BIZENASAHI MUROKA NAMA GENSHU (NARA)',
        description: 'FULL BODIED & MEDIUM DRY - NUTS, PASSION FRUIT & LYCHEE'
      },
      {
        id: '4100ba64-6643-4a9a-a4c0-b1cea2e36aad',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MATSUNOTSUKASA JUNMAI GINJO RAKU (SHIGA)',
        description: 'INTENSE & MEDIUM DRY - RIPE BANANA & APRICOT'
      },
      {
        id: '60a80df3-6f68-44a6-9ec7-5e300e933427',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MANOTSURU JUNMAI GINJO (NIIGATA)',
        description: 'MELLOW & MEDIUM DRY - SWEET SPICES, CANDIED CITRUS & WHITE FLOWERS'
      },
      {
        id: 'c5ec2164-bf17-4a70-a2a2-bc84bb88b025',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SENKIN JUNMAI DAIGINJO MUKU (TOCHIGI)',
        description: 'RICH & DRY - COOKED CITRUS, MARMALADE & COMPOTE'
      },
      {
        id: 'b028cab3-070c-4fd3-9e77-32ab2e8bc3ab',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'DAISHINSHU TEIPPAI JUNMAI DAIGINJO (NAGANO)',
        description: 'FRESH & MEDIUM DRY - GRAPE, PEAR, TOASTY & LONG FINISH'
      },
      {
        id: 'a905b059-be14-45cd-bf5b-4ee70acce650',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MANOTSURU JUNMAI NIGORI (NIIGATA)',
        description: 'RICH & MELLOW - MILKY, COCONUT WATER, PEACH, UMAMI'
      },
      {
        id: '9d3f8e63-26c8-4ce1-a788-50046e75b21e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'FUKUJU AWASAKI SPARKLING (HYOGO)',
        description: 'FRESH & MEDIUM SWEET - MELON & ORANGE PEEL'
      },
      {
        id: '703ee4af-376c-457b-af56-131a92b021d2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'YOSHINOGAWA GENSEN UMESHU (NIIGATA)'
      },
      {
        id: '98e9e6af-3549-45d4-bc7f-294fc74701c1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'YOSHINOGAWA YUZUSHU (NIIGATA)'
      },
      {
        id: '7b1fed53-73ab-4f0a-b19b-b52eef7d5e45',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'KURAFUTO',
        description: 'MEZCAL, TEQUILA, GRAPEFRUIT TONIC'
      },
      {
        id: '03658bfa-d2eb-4a24-a24f-d4e7c24ed72a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'DONTO',
        description: 'JAPANESE WHISKY, GREEN APPLE, SODA'
      },
      {
        id: 'f56c9329-db51-4869-98a5-8f20ee4a0330',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'UME',
        description: 'GIN, PLUM LIQUEUR, SODA'
      },
      {
        id: '06d207c2-d605-4339-bb3a-8708138fadda',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'NATSUKASHII',
        description: 'CACHAÇA, CREME DE KYOHO, SODA'
      },
      {
        id: '383efd1e-0905-4b19-a313-75314354c821',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'KEN',
        description: 'DARK RUM, UMESHU, GINGER ALE'
      },
      {
        id: 'b1ea8c26-1c95-464a-8ecc-bf39342cf7d8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'KANKITSURUI',
        description: 'GIN, YUZU, CAPI SODA'
      },
      {
        id: 'f17fda7d-f042-4ff8-b8fe-8ba97001d5f8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SALARY MAN COMBO',
        description: 'LONDON DRY GIN, WHITE BITTER, YUZU-SHU'
      },
      {
        id: '1ba8a5e2-c3d5-401b-bd3f-085463601dc9',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'COFFEE & YUZU CREAM',
        description: 'CITRUS VODKA, ESPRESSO COFFEE LIQUEUR, YUZU CREAM'
      },
      {
        id: 'ba132bae-cdb0-443d-94be-3f1cbe258f12',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'DEN-EN SHUZOU KIN LABEL (KAGOSHIMA)'
      },
      {
        id: '09459033-ba6b-43fa-8fa6-614f0aa44667',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'DEN-EN SHUZOU SHIMAZUHAM (KAGOSHIMA)'
      },
      {
        id: 'c9b2f9fa-96d5-41a1-bbd2-57af06decc0a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SUNTORY OLD'
      },
      {
        id: 'f33af878-7839-4431-aa7f-f8953cedc31f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ICHIROS MALT MIZUNARA'
      },
      {
        id: '76b3e72c-80c8-47a4-a0a3-9493f5c98eb6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'AKASHI NAS'
      },
      {
        id: '1e7d6679-bbcb-4e1c-958a-ba546112d298',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'NIKKA FROM THE BARREL'
      },
      {
        id: '75e36ddc-10c2-420a-8580-0f0183247e62',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MARS MALTAGE ‘COSMO’'
      },
      {
        id: '6003da40-2c8d-4ac5-9ffc-369d6d0ed902',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'YOICHI NAS'
      },
      {
        id: 'e591957d-5a65-47c1-bd10-055cd521f06c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'KAVALAN PORT CASK'
      },
      {
        id: '306a324f-3f22-48a0-a36a-aef9f94ca8b4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'KAVALAN SINGLE MALT'
      },
      {
        id: 'ab55a12c-eaa3-4ff3-b57a-e812d816c9d1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '1. MASALA OMELETTE',
        description: 'A three-egg omelette with chopped onions, tomatoes, green chillies and coriander, served with masala-roasted tomato.'
      },
      {
        id: 'de1071cb-1173-4406-ace0-bf2e4f4f1e8b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '2. BHURJEE',
        description: 'A three-egg masala scramble, piled up on milk buns and served with masala-roasted tomato.'
      },
      {
        id: '8d5be00d-90b8-488b-8224-e94cf2c2ca3d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '3. KEEMA ANDA PAU',
        description: 'Spicy mutton and eggs scrambled together to be scooped up with buttery milk buns.'
      },
      {
        id: 'affe7326-f673-470d-a49f-9545bc08fd7d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '4. KULCHA ANDA FRY',
        description: 'Naan filled with spiced potato served with two fried eggs, ketchup and coriander chutney.'
      },
      {
        id: 'b5edf25a-4695-4df5-90c8-18b28880eef1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '5. DALHOUSIE PORRIDGE',
        description: 'Organic porridge oats cooked with milk, banana and sweet dates.'
      },
      {
        id: '8047dc49-ad7f-40f2-b4b4-69197ca8a37e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '6. FRUIT & YOGHURT',
        description: 'Fresh grapes and melon, topped with natural yoghurt and manuka honey.'
      },
      {
        id: '56244b31-ab7b-43c4-8968-2566f21ab705',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '7. COMPANY GRADE A GRANOLA',
        description: 'Paleo muesli with a honey-baked blend of nuts, seeds and coconut. Served with fresh fruit, natural yoghurt and manuka honey.'
      },
      {
        id: 'd506ae5b-57e6-4290-8a60-67d23e28e805',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '8. RIFLES ROLLS',
        description: 'Tandoor-baked naan with spicy cheese and tomato chutney, filled with your choice of: a. Bacon and honey, b. Sausage and crushed peppercorns, c. Three fried eggs, d. Or a combination of fillings'
      },
      {
        id: '32dc785e-4077-41dd-9573-5298647f1600',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MASALA BEANS'
      },
      {
        id: '7c836d45-1c50-4368-8421-4a7c2e6197ec',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'GRILLED TOMATO'
      },
      {
        id: '3567a4c5-b6ce-4b2b-a367-d9e2403732c9',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'GRILLED MUSHROOMS'
      },
      {
        id: '2285efc5-27bd-4737-b291-096c7ea49f2a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PORK SAUSAGE'
      },
      {
        id: '75bfb556-41c9-4481-a456-95a75855fb11',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'THREE RASHERS OF STREAKY BACON'
      },
      {
        id: '21f8d4a2-fdc0-45bf-8910-15032613225a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PLAIN NAAN'
      },
      {
        id: '2240fd70-033d-4a1d-84e7-f89c5f7e2fe8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MILK BUNS'
      },
      {
        id: '191f9c90-d25f-4199-9451-bc48035d7c32',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BUTTERED TOAST'
      },
      {
        id: '9c6af51c-5b48-4df9-81fa-e3799efe4041',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'JAM AND MARMELADE'
      },
      {
        id: 'c84d6023-14d1-4af6-835a-fc9d557a4d70',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '9. MULLIGATAWNY',
        description: 'An ‘English’ soup from Madras, of pulled chicken, celery and rice. A well-known favourite amongst the gora afsars and topeewalahs.'
      },
      {
        id: '62c46a35-9247-40c0-99db-f8f1fe62d5e1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '10. SAMOSA CHUTNEY',
        description: 'Loved by sepoy to Sahib, these flaky pastry triangles are filled with savoury, spiced potatoes and green peas, to be dipped in tamarind chutney.'
      },
      {
        id: '13f84241-35c7-4dc0-b934-5878461cebf8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '11. SPECIAL SIXTY-FIVE',
        description: 'A dish originating from Hotel Buhari in Chennai of crispy, fiery morsels – ask your orderly for the special of the day.'
      },
      {
        id: '5e35183e-0c66-4edc-8b48-b82a99102068',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '12. KEEMA ANDA PAU',
        description: 'An easy, sloppy fix of slow-cooked mutton and crushed boiled egg, served with buttered milk buns. A dish found abundantly in mess halls from London to Ludhiana.'
      },
      {
        id: 'd37aa020-79d4-4599-a856-bfda9d3b9e84',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '13. CHICKEN CUTLET',
        description: 'Inspired by the Kabiraji cutlet, the ever-popular, breaded, crispy chicken found in social clubs all over the subcontinent.'
      },
      {
        id: 'd3cc1676-3c3a-4699-babf-924967abde46',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '14. RAJASTHAN RIFLES CLUB SANDWICH',
        description: 'Soft white bread filled with chicken tikka, celery, white sauce, masala omelette, tomato chutney and cheddar from Lord Newborough’s Rhug Estate. Served with thick-cut chips and on the menu by request of the General’s youngest daughter.'
      },
      {
        id: 'dc8e47cd-8aa3-46e3-878b-4dbffb0a42a1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '15. ADMIRAL’S FISH N CHIPS',
        description: 'Beer-battered Hoki fillets with masala fries and marrow fat peas.'
      },
      {
        id: 'e8893cbf-09d5-489d-98f5-6582bcdd2899',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '16. CHUTNEY SANDWICH',
        description: 'Sliced white bread slathered with salted butter, mint chutney, cucumber and tomatoes.'
      },
      {
        id: 'a8d69dbe-7a3e-44a9-b94d-cbfa795295c2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '17. VEGETABLE ‘CUTLETS’',
        description: 'Beetroot and peanut ‘cutlets’ slathered liberally in parsley butter.'
      },
      {
        id: '3ceb11b8-8b93-42f1-a90a-854f7d3b99d9',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '18. LAMB CHOP',
        description: 'Lamb cutlets marinated in special Xacuti spices and griddle-fried.'
      },
      {
        id: '69dc73b2-9f6f-4352-8d87-96c1e7a24871',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '19. SOOLA SALMON',
        description: 'A favourite of Captain Vir Pratap, Bakkafrost salmon from the Faroe islands is lightly marinated with Soola spices and seared.'
      },
      {
        id: '1f9d5b8c-ec66-40f0-887a-b95169328d0c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '20. STEAK AND CHIPS',
        description: 'Pan-seared USDA Prime strip steak with a dry spice rub and thyme.'
      },
      {
        id: 'ee49f2bf-b90c-42b0-b67f-bdfef506799c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '21. BEEF SEEKH KEBAB',
        description: 'Ground beef chuck short ribs seasoned with green chillies, roasted cumin and loaded with cheddar cheese, then flash-grilled in the tandoor.'
      },
      {
        id: '7cf0b1d0-9573-49c0-8fdc-dd919a8aea1f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '22. CHICKEN TIKKA',
        description: 'Local chicken marinated in yoghurt, ground chillies and mustard, accompanied by a tart coriander chutney and dressed Poona onions.'
      },
      {
        id: '1beb8b5b-f049-4a88-b270-9b0f64768d09',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '23. SHASHLIK PANEER',
        description: 'Pieces of fresh, house-made paneer are marinated in spices and skewered with capsicum, onions and tomatoes, then grilled in the tandoor. Seasoned with lime juice and chaat masala, the paneer, though tender, has a crisp singe on the surface.'
      },
      {
        id: '0e2c4ebb-7535-4377-a498-f639a1307fca',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '24. BROCCOLI NARGIS',
        description: 'Broccoli, fresh cheese and hen egg, cooked in the tandoor. A very good way for the sepoys to have their greens.'
      },
      {
        id: 'b422f98e-e22e-4247-98f5-73ff060437f8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '25. BUTTER CHICKEN',
        description: 'Cantonment version of every soldier’s favourite chicken curry, with braised chicken tikka, tomato and butter.'
      },
      {
        id: '56699555-da94-4c6b-a02b-042214e77158',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '26. HURRY-BURRY CHICKEN CURRY',
        description: 'Country-style chicken curry with potatoes and shallots. Popularised in the ports and seaside towns harbouring cargo ships transporting tea, spices and sepoys.'
      },
      {
        id: '67683bbd-b083-4933-8c3c-ccb135f58183',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '27. PANEER MATTAR',
        description: 'A very rustic and quick curry of soft cheese, peas and ginger.'
      },
      {
        id: '833c1cb9-f4c4-4410-b2dd-d16aceaf12c6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '28. PEDRO VINDALOO',
        description: 'The Head Cook’s best friend Pedro shares his Goan recipe for Vindaloo with prawns, white wine vinegar and garlic.'
      },
      {
        id: '2aee620d-dc04-4d2c-ac4c-2647662b914a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '29. RAILWAY MUTTON CURRY',
        description: 'Present on the menu of every dining car, this lamb and potato curry made the railway journey a memorable culinary experience.'
      },
      {
        id: '476f6e5d-55a3-49f4-9315-70089e16d4d2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '30. FISH HAVILDAR RAMU',
        description: 'A stew of river fish with onions, tomatoes and goraka.'
      },
      {
        id: '8fa070f5-c945-4acd-8111-7bca9fb9f0b7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '31. DUM ALOO “GUNPOWDER”',
        description: 'A company special potato dish to please the vegetarian officers.'
      },
      {
        id: '9acdd497-663d-4662-a1bb-3af0f2320a57',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '32. DAL RIFLESWALA',
        description: 'The cook-room’s signature slow-cooked lentils smoked over charcoal embers.'
      },
      {
        id: '811b877d-5f2b-40ef-86ad-b88474dca084',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '33. CLUBWALA PALAK',
        description: 'Creamed spinach is given the Rifles touch with a tempering of garlic, onion and tomato masala.'
      },
      {
        id: 'aa38ba26-8902-4b05-9779-1fa1f7a23897',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '34. STEAMED VEGETABLES',
        description: 'Seasonal vegetables with butter and parsley.'
      },
      {
        id: 'ba891785-552f-4089-86a4-03a7e52ef30d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '35 MUSHROOM AND GREEN PEA',
        description: 'A beautiful summer treat of morels and petit pois in layers of fragrant rice with saffron and cream.'
      },
      {
        id: '2fed29ce-9459-4af1-af7b-557dc2c67613',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '36. LAMB BIRYANI',
        description: 'A one-pot meal for the regiment on the move. Lamb shoulder and ribs marinated in spices, then slow-cooked in layers of aged basmati rice with mint and saffron. Served with raita.'
      },
      {
        id: '068f344d-5f13-4475-815e-ced593ddd025',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PLAIN NAAN'
      },
      {
        id: '2a6dbd76-ee61-44db-b62e-389becae3e27',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BUTTER OR GARLIC NAAN'
      },
      {
        id: '698baf4e-df13-4e9b-8fd1-9398c1304b0c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PLAIN OR BUTTER ROTI'
      },
      {
        id: '92364219-4299-403b-ab45-b41d984dc0d1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LACCHA PARATHA'
      },
      {
        id: '4bf95b0f-176c-4229-8ede-0e97ab69e1d1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MILK BUNS'
      },
      {
        id: '81f91f2c-20af-4694-84bf-7898835a55ab',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'STEAMED RICE'
      },
      {
        id: '2655c911-c627-4da3-be1b-b3cc12ecd268',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'HERB RICE'
      },
      {
        id: 'f9a1c0d2-f06d-4808-a534-76b3c7d06cef',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: "MIXED VEGETABLE 'KACHUMBAR' SALAD"
      },
      {
        id: '5aa48cf3-df11-4287-9f95-ccaea0ab4d07',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ONION CHILLI SALAD'
      },
      {
        id: '1c3c254e-2f1b-4629-9f5e-d1025e203850',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PINEAPPLE RAITA'
      },
      {
        id: 'c914ad06-764b-43b0-bace-dae76b2ddab8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MINT CHUTNEY'
      },
      {
        id: '2d6cfd33-d87a-46b4-a68a-eacaaed0f0fb',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TOMATO CHILLI CHUTNEY'
      },
      {
        id: '1692efa7-163a-495c-beb5-416e456176e2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MANGO CHUTNEY'
      },
      {
        id: '4f9e80f7-5aef-4e60-8439-51b047f11c61',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MANGO PICKLE'
      },
      {
        id: 'ea7a2d0a-bd77-4cdd-86b7-7f158069e7bb',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LEMON PICKLE'
      },
      {
        id: '79a56f76-9db0-43c8-8ccf-b5842cb797ba',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ASSORTMENT OF CHUTNEYS'
      },
      {
        id: '7901acac-759f-41f9-bec2-f1e9f41116a7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LEMON POSSET',
        description: 'Velvety lemon cream with a preserved fruit cocktail.'
      },
      {
        id: '65c9eaac-0795-41fc-873a-5214840b37cf',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BREAD AND BUTTER PUDDING',
        description: 'A warm blanket of a pudding with bread, custard and raisins, just like nanny made for the gora afsars back in Blighty.'
      },
      {
        id: 'e3216928-834e-4143-9cc3-0b6b1e046cc6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TREACLE TART',
        description: 'The Commanding Officer’s wife’s recipe perfected in the tea estates of Conoor and served with cool clotted cream.'
      },
      {
        id: 'd7e5425e-2053-4f81-92b9-27f466cfecf2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHEESE AND CHUTNEY PLATE',
        description: 'Semi-hard cow’s milk cheese from Lord Newborough of the Rhug Estate, made by hand in open vats and covered in nettle leaves. A taste of the English countryside for the homesick gora afsar.'
      },
      {
        id: '11dd78a5-f271-4d85-a6c5-89b5cb6a7299',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Poppadums',
        description: 'Fried and roasted lentil crisps',
        price: 8
      },
      {
        id: 'f9114176-0203-4b9e-9cb9-c36600b984f4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Aloo Samosa',
        description: 'Spiced potatoes cuddled in flaky pastry',
        price: 38
      },
      {
        id: '8dab87e4-7ddc-4b3b-b155-94cb4669ce2b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Onion Pakora',
        description: 'Onion and chickpea fritters',
        price: 38
      },
      {
        id: '957c7ca2-3cf0-4792-bea1-fa4a8733d57a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chicken Tikka',
        description: 'Boneless chicken thigh seasoned lightly with spices, then cooked in the tandoor',
        price: 128
      },
      {
        id: '06a81705-7a33-44b3-9e06-ceff3e4b3bf1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Fish Tikka',
        description: 'Local market fresh fish seasoned lightly with spices, then cooked in the tandoor',
        price: 128
      },
      {
        id: '26235f65-8761-412f-a75c-50f62dffc013',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Butter Chicken',
        description: 'Old Delhi-style chicken in a lightly spiced and very buttery gravy',
        price: 138
      },
      {
        id: '58acfa2a-ed74-4af9-aaa1-458740b31641',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chicken Tikka Masala',
        description: 'Your best bet - Chicken Tikka tossed in tomatoes and grated coconut',
        price: 128
      },
      {
        id: '1eb61d07-1577-4959-b5d1-4ce88c88a8ae',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Lamb Rogan Josh',
        description: 'Tender slow-cooked Curry of lamb and star anise',
        price: 138
      },
      {
        id: 'e034af23-8b68-4953-ac8b-b31ee216c525',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Prawn Masala',
        description: 'Uncle-style Prawn Masala with onions, tomatoes and chilli',
        price: 138
      },
      {
        id: 'd1035ff3-ec18-4ca0-bec3-349ae7559ccf',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Paneer Butter Masala',
        description: 'Soft cheese in a rich sauce with onions and tomatoes',
        price: 98
      },
      {
        id: '72f9acae-245e-4d2b-8fe1-1622fecb7c2a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Vegetable Balti',
        description: 'Mixed vegetables with Uncle’s secret spices',
        price: 98
      },
      {
        id: '998721b5-db76-45af-9d43-864ce9acfe52',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Saag Aloo',
        description: 'Lovely dish of spinach and potatoes',
        price: 78
      },
      {
        id: '2528c080-9b82-4ef7-967c-e606146a40ff',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Dal Tadka Fry',
        description: 'Uncle’s world-famous yellow lentils',
        price: 88
      },
      {
        id: 'd749bd01-d318-4067-9b4e-cdd7bcd58e3d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Saag Paneer',
        description: 'Tasty dish of spinach and soft cheese',
        price: 88
      },
      {
        id: 'db26200f-4236-4ad9-ac83-8e0f9ee02012',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Raita',
        description: 'Cooling yoghurt sauce',
        price: 28
      },
      {
        id: '86fb0898-ed15-4437-a65c-1f768d724dae',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Naan',
        description: 'Fluffy leavened bread baked in the tandoor, brushed with butter',
        price: '28/26'
      },
      {
        id: '40d9a36f-c2b8-4095-a665-45cc48e78713',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Tandoori Roti',
        description: 'Wholemeal flatbread baked in the tandoor',
        price: 18
      },
      {
        id: 'dd4c3266-95c0-4001-b9db-f3afaafa1e6c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Kachumber',
        description: 'Indian chopped vegetable salad',
        price: 28
      },
      {
        id: '48f4001a-0527-47d9-ab94-eda2abdaecf6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Steamed Rice',
        price: 28
      },
      {
        id: '590d44ef-945f-42c4-ac38-8e8550119b50',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Onion and Chilli Salad',
        description: 'Spicy onion salad',
        price: 8
      },
      {
        id: 'fc273670-ce34-4248-83b7-58a85c16f9f2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Johnnie Walker Black Lagel',
        description: '200 ml bottle',
        price: 158
      },
      {
        id: 'a447ae27-02e9-4987-86cb-3dff2c913aca',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Kingfisher Beer - Strong',
        description: '500 ml can',
        price: 38
      },
      {
        id: '9d7974a6-3af5-4875-8653-9137f56e34ff',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SHREDDED CHICKEN SALAD',
        description: 'cabbage, rau ram, pickled chilli, prawn chips'
      },
      {
        id: 'd6739261-cc22-42d2-a54f-cfe2b00ae6bf',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'GREEN PAPAYA SALAD',
        description: 'cashews, pickled chilli, crispy rice paper'
      },
      {
        id: '9a50288b-607f-4ad2-88e1-65e4534c1cd0',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'VIETNAMESE CHARCUTERIE',
        description: 'chicken liver pâté, beef jerky, pickles, bánh mì'
      },
      {
        id: '7739ad66-b432-4703-904a-6ffb2dc11c61',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'NAM VANG-STYLE SALT & PEPPER CHICKEN WINGS'
      },
      {
        id: '4119a8b4-4b3f-4b07-9739-cfa7775f0387',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHA GIÒ',
        description: 'pork & prawn spring rolls, nuóc mâm châm'
      },
      {
        id: '9b526efb-7a44-40f5-a92a-b054ee881998',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'STICKY SOFT-SHELL CRAB',
        description: 'green mango, chilli jam'
      },
      {
        id: '64eb911e-331d-481e-9edc-162a44537516',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CRISPY SHRIMP RICE CAKE',
        description: 'salted radish, peanut sauce'
      },
      {
        id: '48e43ba3-ec1e-40e3-a5ab-0ff12f452405',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BARRAMUNDI',
        description: 'grilled in banana leaf with lemongrass chilli paste'
      },
      {
        id: '2dbfe825-4c3a-4bb2-9b42-c2d081a32326',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'OKINAWA PORK COLLAR',
        description: 'grilled over wood fire with lemongrass and maple glaze'
      },
      {
        id: 'ac32dd23-4d55-4733-a04d-c8cdcdd63f45',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'RANGERS VALLEY WAGYU BAVETTE',
        description: 'slow-cooked over embers with caramelised fish sauce'
      },
      {
        id: '04de0dce-4d02-4ac8-9ac2-b21932f9bc44',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'GRILLED CALAMARI',
        description: 'leek salsa, mint'
      },
      {
        id: '4de305d4-d557-40ee-babd-da36a122d847',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: "CÀ PHÊ SŪ'A PORK RIBS",
        description: 'Vietnamese coffee, pickled radish'
      },
      {
        id: 'b96adf10-f198-4f2f-97fc-1f2cb9e4c972',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '333 SAIGON BEER CAN CHICKEN',
        description: 'lemongrass, pickled onion, rau ram'
      },
      {
        id: '54bd4d5f-265a-47a4-9f60-21e36465e504',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SLOW-ROASTED TE MANA LAMB RIBS',
        description: "rau ram salsa, nu'ó'c màu cam"
      },
      {
        id: 'fcc07dce-3f25-4e5c-8b88-fb966803c9de',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WOOD-FIRED SUCKLING PIG',
        description: 'fried & steamed baos, Vietnamese kimchi (48 hours notice)'
      },
      {
        id: '7ddd755f-5de5-4ca0-98bc-1ff131cb8ec9',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WOK-FRIED VIETNAMESE GREENS',
        description: 'ginger, garlic'
      },
      {
        id: '8d35ac72-f300-47a2-9d3f-2657af36981d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BUTTER SWEET CORN',
        description: 'mushroom XO, puffed rice, kaffir lime leaf'
      },
      {
        id: '196fa82c-4583-4458-ac77-a7eb77b4a021',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CRISPY EGGPLANT',
        description: 'mixed herbs, ginger glaze'
      },
      {
        id: 'af5dce2a-79a2-4f16-b40b-50d790d0df49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'FIVE SPICE TOFU',
        description: 'crispy lemongrass, curry leaves'
      },
      {
        id: '68cb5178-a7fe-48a0-8a34-dd0bfe2877e5',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SNOW CRAB FRIED RICE',
        description: 'crab miso, bean sprouts'
      },
      {
        id: '3fa0bcb4-6ed6-4a35-972d-d83b0a824182',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MUSHROOM HU TIEU',
        description: 'rice noodles, crispy enoki, chives'
      },
      {
        id: 'fd36e5a1-1ce0-4ccf-9ab3-61bd87280a1d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHÈ',
        description: 'smoked coconut ice cream, honey joy cornflakes, black sesame crumbs'
      },
      {
        id: 'f448b1d8-0653-49f2-97b1-7a488d2c97cd',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WOOD-FIRED BANANA BREAD',
        description: 'warm maple caramel, candied walnuts'
      },
      {
        id: 'c390f733-3e20-4b6e-8361-61d02020f43d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHOCOLATE & ALMOND BROWNIE',
        description: 'passion fruit coulis, vanilla mascarpone'
      },
      {
        id: 'd8127bac-60ee-4f9d-9ac9-8afa786349da',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'VIETNAMESE DRIP COFFEE',
        description: 'coconut ice cream affogato'
      },
      {
        id: '517a1d7a-a973-46c0-9be4-4d406272d8f1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BÁNH MÌ THIT',
        description: 'Rolled pork belly, pork terrine, Vietnamese cured meats, pork floss, mayo, chicken liver pâté, pickles, spring onion, cucumber, coriander, chilli, soya sauce',
        price: 88
      },
      {
        id: '0018dcfa-4e39-449f-9ee3-b1bce67573d9',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BÁNH MÌ GÀ',
        description: 'Shredded chicken, mayo, chicken liver pâté, pickles, spring onion, cucumber, coriander, chilli, soya sauce',
        price: 88
      },
      {
        id: '9fe58dad-61e4-4b92-a848-d0cf89920bd4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BÁNH MÌ CHAY',
        description: 'Tofu, mayo, pickles, spring onion, cucumber, coriander, chilli, soya sauce',
        price: 88
      },
      {
        id: '74ff6e8b-837b-4789-8385-39df4cf95670',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'POTATO GEMS',
        description: 'Spicy mayo',
        price: 38
      },
      {
        id: '5c5d8cce-dfc2-4da7-a665-8d19c78ba10f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'THICK CUT FRIES',
        description: 'Spicy mayo',
        price: 38
      },
      {
        id: 'ae0a8f96-120a-456d-adfe-45494072db42',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LE PETIT SAIGON COLESLAW',
        description: 'Vietnamese herbs, cashew nut mayo',
        price: 38
      },
      {
        id: 'df75be13-4fc0-420d-b184-558cfed12857',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SAIGON WINGS',
        description: 'Chicken wings, mixed herbs, spicy mayo',
        price: 68
      },
      {
        id: '1e1b8000-c962-4cc6-b292-a3666949c2fd',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BÁNH MÌ THIT',
        description: 'rolled pork belly, pork terrine, Vietnamese cured meats, pork floss, mayo, chicken liver pâté, pickles, spring onion, cucumber, coriander, chilli, soya sauce'
      },
      {
        id: '69607301-2247-43ba-a450-514f92aff6cb',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BÁNH MÌ GÀ',
        description: 'shredded chicken, mayo, chicken liver pâté, pickles, spring onion, cucumber, coriander, chilli, soya sauce'
      },
      {
        id: '6983d951-98b4-4500-b5a3-eb8e5fd06b4e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BÁNH MÌ CHAY',
        description: 'tofu, mayo, pickles, spring onion, cucumber, coriander, chilli, soya sauce'
      },
      {
        id: '22bd3ef2-b75f-492d-8167-bf4ea80cbcb1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LE PETIT SAIGON COLESLAW',
        description: 'Vietnamese herbs, cashew nut mayonnaise'
      },
      {
        id: '6b8af98f-911a-4678-b4b0-3abd43bd10a2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SAIGON CHICKEN WINGS',
        description: 'chicken wings, mixed herbs, spicy mayonnaise'
      },
      {
        id: '23efb0ee-fce5-4fc2-a224-582f06a7932e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LE PETIT SAIGON COOKIES',
        description: 'two per person'
      },
      {
        id: 'd270ca0b-c498-48ff-a609-b4d411cc5229',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'HUMMUS BIL TAHINI',
        description: 'Chickpea purée, tahini, lemon juice, olive oil',
        price: 68
      },
      {
        id: '6a4a99a7-fff3-4e46-97db-8fe8d4e648a2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BABA GHANOUSH',
        description: 'Charred eggplant, yoghurt, tahini, olive oil',
        price: 68
      },
      {
        id: 'dacc7d74-c31c-49a3-84ac-8299f2ec0339',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MAISON’S LABNÉ',
        description: 'House-spiced yoghurt, cucumber, olive oil, za’atar',
        price: 68
      },
      {
        id: '57af22a5-c4af-4ab1-a966-7f3874661626',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'HUMMUS SHAWARMA',
        description: 'Hummus, sliced marinated roast beef, olive oil',
        price: 98
      },
      {
        id: 'dc226198-2e92-4ccd-8142-5c5b4f9aea0a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LEVANTINE FATTOUSH',
        description: 'Gem lettuce, cherry tomato, radish, mint, crispy pita khobez, sumac dressing',
        price: 128
      },
      {
        id: 'eedb1d3d-141e-4ad6-8e7f-b9f8e2b12a6f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BARLEY & HALLOUMI',
        description: 'Baked halloumi, pearl barley, sultanas, pomegranates, chickpeas, herbs, pine nuts',
        price: 158
      },
      {
        id: '1d829734-a5df-4a23-821b-d46134798758',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TABBOULÉ',
        description: 'Parsley, mint, bulgur, onion, tomato',
        price: 98
      },
      {
        id: '5319022a-aeb2-45fa-973e-9f04b2ce8e01',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MAISON’S FALAFEL',
        description: 'Chickpeas, fava beans, sesame seeds, tahini yoghurt, pickled turnip',
        price: 128
      },
      {
        id: 'd06fbc4d-8a4b-439c-a8bc-4d8908ea67a2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PAN-FRIED HALLOUMI',
        description: 'Honey-glazed halloumi, figs, Byzantine dressing',
        price: 158
      },
      {
        id: 'b0aecdc4-2214-4661-bbe6-f3605b9567af',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ARAYES',
        description: 'Grilled pita khobez, chargrilled spiced lamb, tahini, pomegranates',
        price: 158
      },
      {
        id: '771644ec-7982-47f3-b491-680963b7f5e0',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SAWDA DJEJ',
        description: 'Pan-fried chicken liver, garlic, lemon, pomegranate molasses',
        price: 148
      },
      {
        id: '3ad3f251-cbe9-4529-bea2-91e3b99d2b33',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'EGGPLANT MOUSSAKA',
        description: 'Roasted eggplant, tomato ragu, chickpeas, cumin, mint',
        price: 168
      },
      {
        id: 'f988751e-01f1-4b3f-bce4-ec5db19d0af6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHICKEN MESHOUÉ',
        description: 'Chargrilled marinated chicken, freekeh and pomegranate salad, Beiruti toum',
        price: 228
      },
      {
        id: '5f068c35-82d8-4008-87ba-77879ace4cfb',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BAKED SAMKÉ HARRA',
        description: 'Sea bass fillet, coriander, tomato tahini sauce, roasted pine nuts',
        price: 248
      },
      {
        id: 'b5286305-006b-40e3-9eb4-dcdc6f6c9a31',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ROASTED CAULIFLOWER',
        description: 'Harissa-roasted cauliflower, zhoug, tahini, sumac, dried lime',
        price: 128
      },
      {
        id: 'bb596e28-975b-476e-8a67-ab1dadcdee02',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LAMB KAFTA',
        description: 'Chargrilled spiced lamb skewers, sumac-grilled tomato, hummus',
        price: 288
      },
      {
        id: 'aa7bc604-edd1-4a70-b63d-80db3aa69e46',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LAHÉM MESHOUÉ',
        description: 'Chargrilled beef skewers, harissa, grilled pickled chilli',
        price: 278
      },
      {
        id: 'e40b0857-4fe7-4f48-9b03-67f63e2e53a5',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MASHAWI',
        description: 'Beef skewer, chicken skewer, lamb skewer, grilled pita, pickled chilli, toum',
        price: 388
      },
      {
        id: '5caf6463-ddf6-4f6f-88cc-86ecf9eb4a6a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SHISH TAOUK',
        description: 'Chargrilled chicken skewers, sumac-grilled tomato, toum',
        price: 168
      },
      {
        id: '4968d468-18c1-49ba-9d6e-0712c742bca2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'HEARTH-BAKED PITA BREAD',
        price: 58
      },
      {
        id: '5997aa82-bf3a-4f2c-9ac6-d4043c3ada43',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'HOUSE-MADE PICKLES',
        description: 'Green chilli, pink turnip, cauliflower',
        price: 48
      },
      {
        id: 'e5d0b7a7-eb16-47e2-8ee3-a5baec5888ee',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'RICE PILAF',
        description: 'Rice, green lentils, almonds, onion, warm spices',
        price: 78
      },
      {
        id: 'e056de24-8f2e-418e-9859-9d75c1c26307',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chateau Kefraya, Les Bretèches 2017',
        description: 'Muscat/Sauvignon Blanc/Viognier',
        price: 78
      },
      {
        id: 'ff477a71-ced3-434a-a854-a350f55721a2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chateau Ksara, Blanc de Blancs 2018',
        description: 'Chardonnay/Sauvignon Blanc/Semillon',
        price: 98
      },
      {
        id: '24922efa-1484-4c2b-a7a8-abe98f0f7a53',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chateau Ksara, Chardonnay 2004',
        description: 'Chardonnay',
        price: 888
      },
      {
        id: 'd8c89a7e-8813-4af1-a120-db4ac4e7ad88',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Massaya 2018',
        description: 'Obeidi/Rolle/Clairette/Sauvignon blanc/Chardonnay',
        price: 488
      },
      {
        id: '3e78721c-3cba-4d10-9a7c-a826435dd516',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chateau Ksara, Chardonnay 2016',
        description: 'Chardonnay',
        price: 128
      },
      {
        id: '5e586300-cf78-4d1f-b4c9-fd0ab304ef90',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LePlan Vermeersch, Cotes du Rhone 2018',
        description: 'Grenache/Marsanne',
        price: 368
      },
      {
        id: '7fa121ea-9a68-44e7-83b8-3399ff3f86a5',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Bernard Defaix, Chablis 2018',
        description: 'Chardonnay',
        price: 768
      },
      {
        id: '5bdd562b-3a89-4b61-aa65-379e66f476e0',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Leon Beyer, Riesling 2018',
        description: 'Riesling',
        price: 648
      },
      {
        id: 'ab70fa8b-d916-4bcc-9482-1a8964b4421c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chateau Kefraya, Les Breteches Rose 2019',
        description: 'Grenache/Cinsaul',
        price: 78
      },
      {
        id: '98b221d7-e389-4fa6-a9f0-7238d28b0bf2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Torresella Extra Dry Prosecco NV',
        description: 'Glera',
        price: 78
      },
      {
        id: '97cd27ca-99f9-41c1-8744-e5e23fbafa8f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chateau Ksara, Moscatel NV',
        description: 'Muscat/Gewuurztraminer',
        price: 88
      },
      {
        id: 'dc7cfdab-b221-4351-8683-f5065768a112',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chateau Kefraya, Les Breteches 2017',
        description: 'Cinsault/Cabernet Sauvignon/ Grenache/Carignan',
        price: 78
      },
      {
        id: '9e99c8ab-a47e-4034-9a2e-04f92fb0fc98',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Ixsir, Altitudes Red 2016',
        description: 'Cabernet Sauvignon/Syrah/Caladoc/Tempranillo',
        price: 498
      },
      {
        id: 'bcba1252-d623-4ed1-b5cb-bcd703216dd2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chateau Kefraya 2015',
        description: 'Cabernet Sauvignon/Syrah/Carignan/Mouvèdre',
        price: 128
      },
      {
        id: '60792b4e-e81a-409a-925c-3f34bdc83f65',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chateau Musar 2002',
        description: 'Cabernet Sauvignon/Carignan/Cinsault',
        price: 998
      },
      {
        id: '93c96589-c6ec-4d7b-b297-1ba54139a0a3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chateau Ksara, Cabernet Sauvignon 2000',
        description: 'Cabernet Sauvignon',
        price: 778
      },
      {
        id: '50d7cb6d-97d7-4308-9964-7f187011266b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chateau Ksara, Le Prieure 2018 Cinsault/Syrah/Mourvèdre/',
        description: 'Cabernet Sauvignon',
        price: 88
      },
      {
        id: '5f31173d-81d1-4ef9-9013-811b26407d28',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chateau St Thomas Pinot Noir 2013',
        description: 'Pinot Noir',
        price: 118
      },
      {
        id: '8f001f04-585c-4437-b87d-1f6bfdc2a562',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chateau Ksara, Cabernet Sauvignon 2017',
        description: 'Cabernet Sauvignon',
        price: 668
      },
      {
        id: '567b7257-80f0-4fbe-897e-7cef313117f0',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Chateau Ksara, Cuvee du Troisieme Millenaire 2014',
        description: 'Petit Verdot/Cabernet Sauvignon/Syrah',
        price: 888
      },
      {
        id: '2ebbaf2c-1018-4c1e-b833-5819599401b6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Domaine Piron, Beaujolais Villages 2017',
        description: 'Gamay',
        price: 488
      },
      {
        id: 'd3858da4-9890-443e-9342-ec0dc283bac1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Dufouleur, Bourgogne Pinot Noir 2018',
        description: 'Pinot Noir',
        price: 598
      },
      {
        id: '1dced2f3-2895-4e5a-b180-649589325e82',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Lagrave Martillac, Pessac-Leognan 2016',
        description: 'Mourvèdre/Grenache',
        price: 758
      },
      {
        id: 'ec4cadac-abbd-49e6-a3c6-7741b7ca0b69',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Domaine Grand Veneur, Cotes du Rhone',
        description: 'Les Champauvins 2015 Grenache/Syrah/Mourvedre',
        price: 628
      },
      {
        id: '1b54cf2c-88b9-459d-8a66-426cda76bbf6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'VFC Wings',
        description: 'Vietnamese Fried Chicken Wings, Garlic, Sticky Fish Sauce, Chilli, Peanut',
        price: 108
      },
      {
        id: 'ea58af6e-c482-4fda-978d-33fdc71b73a8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Salt & Pepper Squid',
        description: 'Garlic, Black Bean, Chillies, Sriracha Mayo',
        price: 118
      },
      {
        id: 'e5822d17-c865-4014-8222-aa0bd9694665',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Lemongrass & Kaffir Chicken Skewers',
        description: 'Garlic, Ginger, Kaffir Lime, Chilli Salt, Calamansi Lime',
        price: 108
      },
      {
        id: '9f9d6ae2-a97b-4d0b-9d19-d3990cc27db4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Prawn Crackers',
        description: 'Spicy Nuoc Cham Dip, Peanut',
        price: 48
      },
      {
        id: '1f3e8a9d-195e-4871-b671-862f29d056b7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Grilled Tiger Prawns',
        description: 'Garlic Butter, Chilli, Lime, Nuoc Cham',
        price: 118
      },
      {
        id: '149f4f25-794b-430b-8954-8b9399ae64c7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Vietnamese Pizza',
        description: 'Crispy Rice Paper, Braised Short Rib, Sriracha Mayo, Fried Shallot, Peanut, Pickles, Vietnamese Herbs',
        price: 88
      },
      {
        id: '2cbc592d-8840-41e4-9f6a-b57ab951788d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Roasted Bone Marrow',
        description: 'Brown Butter Nuoc Cham, Rau Ram Oil, Lime, Chinese Doughnut',
        price: 128
      },
      {
        id: '6df00c88-3b4b-4965-bc52-0d4dc70e9a31',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Grilled Diver Scallop & Pomelo',
        description: 'Red Onion, Fried Shallot, Peanut, Nuoc Cham, Vietnamese Herbs',
        price: 128
      },
      {
        id: 'd17156d7-f9e4-49c5-8802-721f41b9de40',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Crispy Spring Roll',
        description: 'Pork, Mushroom, Vegetables, Coriander',
        price: 108
      },
      {
        id: '10b02d83-57ad-4daa-88b4-bab452cacab2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Pho Roll',
        description: 'Beef, Fresh Rice Noodles, Garlic, Thai Basil, Scallion Oil, Peanut',
        price: 98
      },
      {
        id: '1738a4fb-dd44-4550-9ace-9072fab79bd4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Shrimp Rice Paper Roll',
        description: 'Vermicelli, Mango, Vietnamese Herbs, Avocado, Mint',
        price: 108
      },
      {
        id: '55107c07-1185-427a-bd08-62a21b06813f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Summer Paper Roll',
        description: 'Market Vegetables, Vermicelli, Avocado, Vietnamese Herbs, Peanut Sauce',
        price: 88
      },
      {
        id: '49a6c7bb-60c5-45dc-a7a1-8f74d33d6161',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Crispy Sole Fillet Paper Roll',
        description: 'Vermicelli, Tamarind, Kaffir Lime, Coriander',
        price: 108
      },
      {
        id: '6e89900c-6229-464e-b64d-9c58ef19d0b0',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Kaffir Chicken Cabbage Salad',
        description: 'Poached Chicken, Popcorn Chicken, Carrot, Cabbage, Vietnamese Herbs, Rau Ram, Peanut',
        price: 108
      },
      {
        id: 'e6993d21-2b7f-4b6b-8156-ecef5df98f4c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Shaking Beef',
        description: 'USDA Tenderloin, Watercress, Cherry Tomato, Salt & Pepper Lime Dip, Peanut, Jasmine Rice',
        price: 228
      },
      {
        id: 'fa4b22cc-b4e7-4bfc-8483-eb51c338be62',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Caramel Black Cod',
        description: 'Caramel Fish Sauce, Garlic, Lemongrass, Lime, Ginger, Jasmine Rice',
        price: 218
      },
      {
        id: 'faf8cffa-a22e-40ef-89cb-e8ae342c3d72',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Cha Ca Hanoi',
        description: 'White Sole Fillet, Turmeric, Dill, Red Onion, Vermicelli, Peanut',
        price: 188
      },
      {
        id: '8137bd14-1145-4fa7-bb55-b03eff7308e3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Bun Cha Hanoi',
        description: 'Grilled Pork Belly and Patties, Vermicelli, Pickles, Vietnamese Herbs, Chilli, Garlic',
        price: 188
      },
      {
        id: 'a9ffd723-aba9-4d35-b585-b9b8e91f64d8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Short Rib Lettuce Wraps',
        description: 'Pho-braised Short Ribs, Lettuce, Vermicelli, Spring Onion, Chilli, Peanut Sauce',
        price: 208
      },
      {
        id: 'b9cd39a5-7054-4b8f-aea0-2a99cb259b94',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Wok-fried Morning Glory',
        description: 'Water Spinach, Fermented Tofu, Chilli, Baby Garlic, Sesame',
        price: 78
      },
      {
        id: 'c3129853-aeba-4f69-bc93-040e328efdc6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Charred Gai Lan in Curry',
        description: 'Chinese Broccoli, Coconut Sriracha Curry Sauce',
        price: 78
      },
      {
        id: '57ca5b7a-8e0b-43bf-abed-690374b9fcb3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Vermicelli Noodles',
        price: 28
      },
      {
        id: 'e3e8b57f-ed4f-4df7-b736-681ca95c9d6c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Steamed Jasmine Rice',
        price: 28
      },
      {
        id: 'aaeedf0f-b5b8-4903-981a-0bb7ca9671de',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'La Vache!',
        description: 'Trimmed entrecôte steak “La Vache!” with special house sauce, French fries and green salad with walnuts',
        price: 318
      },
      {
        id: '9b6bc45f-fb03-4819-8df4-f555dd967d3c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHICKEN SATAY',
        description: 'Chicken, coconut milk, red curry, peanut sauce',
        price: 98
      },
      {
        id: 'bd3a42aa-65e3-4825-90f0-ee79939317a4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CRISPY CHICKEN WINGS',
        description: 'Chicken wings, turmeric, coriander, peppercorns, fried shallot',
        price: 98
      },
      {
        id: '5840ac54-5d43-49b7-963b-785922556251',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'VEGETARIAN SAMOSAS',
        description: 'Potato, curry powder, warm spices, mint yoghurt dip',
        price: 88
      },
      {
        id: 'e7a39086-703b-4163-9e50-a81c2aace84d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TUM YAM GOONG',
        description: 'Lemongrass, lime leaf, galangal, prawns, chilli jam',
        price: 108
      },
      {
        id: '9cfe2019-70b2-45a5-b7cd-37523baf7049',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SPICY PAPAYA SALAD',
        description: 'Green papaya, long bean, tomato, dried shrimp, peanuts',
        price: 78
      },
      {
        id: '2b4c454d-36af-4f88-83b1-b0ea9d648284',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BANANA BLOSSOM SALAD',
        description: 'Raw banana flower, coconut cream, prawns, sweet basil, chilli jam',
        price: 108
      },
      {
        id: '1dc63a0e-9faf-4fb2-b51e-74322d0124e4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'GRILLED SQUID SALAD',
        description: 'Wood-grilled squid, Chinese celery, coriander',
        price: 128
      },
      {
        id: '868f8457-ddc7-4e28-a496-6e8f98320168',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CRAB FRIED RICE',
        description: 'Jasmine rice, charred corn, crab, spring onion, seafood sauce',
        price: 138
      },
      {
        id: 'dbc23f2a-a1d3-4769-9550-5ba87bd554e0',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SOFT-SHELL CRAB PAD THAI',
        description: 'Noodles, tofu, bean sprout, egg, radish pickle, soft-shell crab',
        price: 148
      },
      {
        id: 'a3e4ea50-f899-48be-9069-88ae9dbeb8be',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PAD SEE EW',
        description: 'Wok-charred rice noodles, chicken thigh, sweet soy',
        price: 128
      },
      {
        id: '67c89ad4-101c-47ab-a544-d83c1d950699',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHICKEN GRAPOW',
        description: 'Steamed rice, wok-fried chicken, holy basil, garlic, chilli, Japanese egg',
        price: 148
      },
      {
        id: '59d8b15d-f6ce-468e-afd6-8df9badb4158',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: "DRUNKARD'S NOODLES",
        description: 'Spicy wok-fried rice noodles, squid, prawns, holy basil, kaffir lime leaf',
        price: 148
      },
      {
        id: '2f3f860e-cce5-47c1-b5ad-47d32655b87b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'STIR-FRIED MORNING GLORY',
        description: 'Morning glory, garlic, chilli',
        price: 78
      },
      {
        id: '680c9c6e-ca17-4d14-81c1-cfd3c60f3f61',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WOK-FRIED MIXED VEGETABLES',
        description: 'Garlic, oyster sauce, tofu',
        price: 78
      },
      {
        id: 'a3f4be71-ffa3-474c-9200-122f3a135c35',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WOK-FRIED BROCCOLI',
        description: 'Garlic, chilli, peppercorns',
        price: 78
      },
      {
        id: '7b70b67b-027b-4dd5-ae87-e3d75f9fb818',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'JASMINE RICE',
        price: 28
      },
      {
        id: '083236f3-1d86-43fa-9e29-b944dbe98662',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'STICKY RICE',
        price: 18
      },
      {
        id: 'f1565608-7d48-457d-aae7-de9d4a28df23',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PORK LARB',
        description: 'Minced pork, roasted rice powder, herbs',
        price: 128
      },
      {
        id: '976104b9-58c6-433b-bb7e-a09713ef7149',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHARGRILLED PORK JOWL',
        description: 'Marinated pork jowl, spicy dipping sauce',
        price: 138
      },
      {
        id: '789407c4-4219-4c72-8f0d-9d4d9765562c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHARGRILLED CHICKEN',
        description: 'Marinated chicken leg and thigh, spicy dipping sauce',
        price: 138
      },
      {
        id: '8be32b78-78ba-4b0d-b045-ed276fa0b587',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'GREEN CHICKEN CURRY',
        description: 'Organic chicken, coconut cream, pea eggplant, basil',
        price: 158
      },
      {
        id: '188125ef-0078-4b2d-af53-ad70804f8b81',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'YELLOW CHICKEN CURRY',
        description: 'Organic chicken, carrot, potato, white onion, fried shallot, coconut cream',
        price: 158
      },
      {
        id: '377c9d78-6810-4921-9921-7a346541df14',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BEEF BRISKET PENANG CURRY',
        description: 'Beef brisket, warm spices, burnt pineapple, coconut cream, roasted peanuts',
        price: 178
      },
      {
        id: '1fb04e67-bcfd-4883-a2ad-f9ed2a13a13e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LAMB MASSAMAN CURRY',
        description: 'Lamb shoulder, massaman spice, new potato, crispy shallot',
        price: 188
      },
      {
        id: '1eba1a45-29fd-442f-9683-a184d3c75e75',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'VEGETARIAN RED CURRY',
        description: 'Market vegetables, red curry, pressed tofu',
        price: 148
      },
      {
        id: '5c8d5854-6006-4837-bb22-19735c039a65',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'COCONUT ICE CREAM',
        description: 'Coconut ice cream, salted palm sugar caramel, peanuts',
        price: 68
      },
      {
        id: 'd2677f34-d63e-47ad-9744-2969d9b4b67a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MANGO STICKY RICE',
        description: 'Sweet sticky rice, mango, crispy yellow mung bean',
        price: 68
      },
      {
        id: 'dace5694-08ec-41ff-9608-617b3888a58a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: "Chef's Choice",
        description: 'Let Chef send you his favourites, portioned to share',
        price: 268
      },
      {
        id: '22de9337-67fb-440f-91bd-4373932ba284',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHOPPED SALAD',
        description: 'Red Radicchio / Endive / Radish / Boiled Egg / Grape Tomato / Salami Milano / Provolone / Gaeta Olive / House Dressing',
        price: 178
      },
      {
        id: '4125446f-cb55-41bd-9b97-4f5125a81945',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MIXED GREENS SALAD',
        description: 'Mixed Greens / Parsley / Mint / Black Pepper / House Dressing / Red Onion',
        price: 108
      },
      {
        id: '26ceb258-25a4-472b-92c1-9b1c32f3efc5',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PROSCIUTTO DI PARMA',
        description: '+ Buffalo Mozzarella 48',
        price: 158
      },
      {
        id: '27f5f0bd-4e5d-44e8-9557-b8f3e46595fb',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MEATBALLS',
        description: 'Braised In Tomato Sauce',
        price: 138
      },
      {
        id: '6eb70fbd-61e2-48d3-856b-77e9bfbfc453',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MARINATED BEET SALAD',
        description: 'Beetroots / Red Onion / Gaeta Olives / Soft Boiled Egg / Ricotta Salata / White Anchovies',
        price: 178
      },
      {
        id: '9da2fe5d-5524-46ae-98b8-c91d6972e9da',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ARUGULA SALAD',
        description: 'Red Onion / Dried Figs / Fresh Pear / Pancetta / Gorgonzola / House Dressing / Garlic / E.V. Olive Oil',
        price: 148
      },
      {
        id: '82c90154-67bd-40dd-9aa4-8096b366a81b',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CHICKEN WINGS',
        description: 'Red Onion / Lemon / Fresh Chili / Mint / E.V. Olive Oil',
        price: 148
      },
      {
        id: '4b1fa6ad-28ec-49a5-bcbc-59823b8be6a2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MARINARA',
        description: 'Tomato Sauce / Oregano / Garlic / Pecorino / E.V. Olive Oil',
        price: 118
      },
      {
        id: 'e1334b50-418d-42b5-9031-24af5f960a33',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MARGHERITA',
        description: 'Tomato Sauce / Mozzarella Di Bufala / Basil / Pecorino / E.V. Olive Oil',
        price: 178
      },
      {
        id: '9182bd87-5206-42bc-9ac1-905b33595e11',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SOPPRESSATA PICCANTE',
        description: 'Tomato Sauce / Fior Di Latte / Fresh Chili / Garlic / Oregano / Pecorino / E.V. Olive Oil',
        price: 198
      },
      {
        id: 'd4792806-a89b-416b-ae33-9143594758cf',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MEATBALL',
        description: 'Tomato Sauce / Fior Di Latte / Pecorino / Basil / E.V. Olive Oil',
        price: 198
      },
      {
        id: 'd2bd8d2b-297f-444c-87c9-d63f5977838a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'QUATTRO FORMAGGI',
        description: 'Gorgonzola / Provolone / Fior Di Latte / Pecorino / Parsley / Chili Oil',
        price: 198
      },
      {
        id: '7169de05-cc2a-4675-9f8e-30f2aa7425cf',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CREMINI MUSHROOM',
        description: 'Fior Di Latte / Italian Sausage / Garlic / Thyme / Pecorino / Gaeta Olives / E.V. Olive Oil',
        price: 198
      },
      {
        id: '3c9de4eb-3ba2-4106-8d6e-ab51ae30d927',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BRUSSELS SPROUT',
        description: 'Fior Di Latte / Brussels Sprouts / Pancetta / Garlic / Pecorino / E.V. Olive Oil',
        price: 198
      },
      {
        id: 'f0985c7b-7984-444b-ac78-8309770c01ff',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'PROSCIUTTO DI PARMA',
        description: 'Fior Di Latte / Fresh Oregano / Pecorino / E.V. Olive Oil',
        price: 228
      },
      {
        id: 'c8ffa579-212f-44ce-8f2e-0fcfeeb9c112',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BRESAOLA',
        description: 'Arugula / Fior Di Latte / Pecorino / E.V. Olive Oil',
        price: 218
      },
      {
        id: 'a3310c65-8690-4499-b60e-3d5d550d7d6a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'COLATURA DI ALICI',
        description: 'Mozzarella Di Bufala / Red Onion / Grape Tomato / White Anchovy / Chili Flake / Gaeta Olive / E.V. Olive Oil',
        price: 198
      },
      {
        id: '12af6c3d-a12e-40af-a740-42ac322b28ec',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'TIRAMISU',
        price: 78
      },
      {
        id: 'e943f49d-22f9-4b20-80fb-8f5ac457e6f4',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'NUTELLA CALZONE',
        description: '(with marshmallow)',
        price: 98
      },
      {
        id: '05e1f999-94d9-413f-9d33-c63b2cba151c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Set Lunch',
        description: 'CHOICE OF MARINATED BEET SALAD, MIXED GREENS SALAD OR ROASTED SWEET CORN & A PIZZA',
        price: 128
      },
      {
        id: 'e1634cb7-a6a0-48e4-94d1-e95810599d81',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MARINARA',
        description: 'Tomato Sauce / Oregano / Garlic / Pecorino / E.V. Olive Oil'
      },
      {
        id: '034130a3-5189-4604-9e41-627b1638b17f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MARGHERITA',
        description: 'Tomato Sauce / Mozzarella Di Bufala / Basil / Pecorino / E.V. Olive Oil'
      },
      {
        id: '918ad3f2-d8ff-424c-a325-88037b559822',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: "ALL'UOVO",
        description: 'Fior Di Latte / Sunnyside Farm Egg / Smoked Pancetta / Pecorino / Basil / Chili Oil'
      },
      {
        id: '9943a68b-87c4-4e3a-b099-6c93eccb2519',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SOPPRESSATA PICCANTE',
        description: 'Tomato Sauce / Fior Di Latte / Fresh Chili / Garlic / Oregano / Pecorino / E.V. Olive Oil'
      },
      {
        id: 'bff87612-ef42-4a37-9754-43d56db806e6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BRUSSELS SPROUT',
        description: 'Fior Di Latte / Brussels Sprouts / Pancetta / Garlic / Pecorino / E.V. Olive Oil'
      },
      {
        id: '4d7a1c95-428e-49ce-8934-d08ddf51bcee',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Set Brunch',
        description: 'CHOICE OF CAPRESE SALAD, ROASTED SWEET CORN OR MIXED GREENS SALAD & A PIZZA',
        price: 168
      },
      {
        id: 'f5b7e0b9-3eed-4513-b996-29417ca0d5fb',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MARGHERITA',
        description: 'Tomato Sauce / Mozzarella Di Bufala / Basil / Pecorino / E.V. Olive Oil'
      },
      {
        id: '8d7beab8-ac3c-428d-a121-56aaddef9e00',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: "ALL'UOVO",
        description: 'Fior Di Latte / Sunny Side Farm Egg / Smoked Pancetta / Pecorino / Basil / Chili Oil'
      },
      {
        id: '652798f0-bcfd-4592-ac12-009390980ff9',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '#1 CLASSIC BURGER',
        description: '5oz beef patty, lettuce, tomato, pickles, Circus sauce',
        price: 88
      },
      {
        id: 'd17f3f76-cc3e-47ca-9a10-af2d90cf6168',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '#2 AMERICAN CHEESEBURGER',
        description: '5oz beef patty, Wisconsin cheddar, sliced onion, pickles, Circus sauce',
        price: 98
      },
      {
        id: '63a9a0c9-938b-41a8-b999-7719f8bdecab',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '#3 DELUXE',
        description: '5oz beef patty, bacon, savory onion, Wisconsin cheddar, Circus sauce',
        price: 108
      },
      {
        id: '6f044ec1-8fe8-47ed-b628-5a5c1aa7ece7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '#4 DOUBLE',
        description: 'Double 5oz beef patties, Wisconsin cheddar, sliced onion, Circus sauce',
        price: 128
      },
      {
        id: 'c6aef8ff-3a8e-41c5-b255-058571d49c73',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '#5 VEGGIE BURGER',
        description: '5oz veggie patty, pickles, tomato, lettuce, miso mayonnaise',
        price: 98
      },
      {
        id: '6ccf2c9e-5161-4004-8fcd-6fd28ad1ee0c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '#6 SPICY CHICKEN',
        description: 'Buttermilk-brined chicken thigh, jack cheese, pickles, jalapeño, sriracha, ranch dressing',
        price: 98
      },
      {
        id: 'c69a2e88-08e8-4717-b4a7-98b41f24465d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '#7 FISH FILLET',
        description: 'Crispy fish fillet, Wisconsin cheddar, tartar sauce, pickles',
        price: 98
      },
      {
        id: 'cb812b65-c84d-4183-92e2-8d3b81042d9d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'FRIES',
        price: 38
      },
      {
        id: '3ee64f11-7d20-484d-ad28-3f544a6a635a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'LOADED FRIES',
        description: 'Bacon, cheddar, sour cream, spring onion',
        price: 58
      },
      {
        id: 'ccf82a9a-7d79-445c-98f3-845975bfe80e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: '1/2 DOZEN WINGS',
        description: "Your choice of Frank's RedHot sauce, smoky BBQ or honey garlic",
        price: 88
      },
      {
        id: '3b663ec2-32e6-4b17-b36a-565b034a5996',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'JALAPEÑO POPPERS',
        description: 'Cream cheese filling, ranch dip',
        price: 78
      },
      {
        id: 'a4b4592b-300f-4960-ad14-77697f13c7c0',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ONION RINGS',
        price: 38
      },
      {
        id: 'f9aec571-eac0-4c56-931e-ee93c7967918',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'MILKSHAKE',
        description: 'Vanilla/ Strawberry/ Chocolate/ Salted Caramel',
        price: 68
      },
      {
        id: '8d1e1591-c484-4c37-88e6-cdce5d72990f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CANDY SHAKE',
        description: 'M&Ms/ Kit Kat/ Oreos/ Reese’s Pieces',
        price: 78
      },
      {
        id: 'fcf52dde-a9b4-4e8f-9f5c-6325faa25a30',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ADD FRIES AND ANY HOUSEMADE REFRESHMENTS',
        price: '+55'
      },
      {
        id: 'd22e9136-bcc7-498b-a373-a2c9304848d1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ADD FRIES AND A BEER',
        price: '+75'
      },
      {
        id: 'becd0cfc-93d1-4d7e-bb71-6f1e131d03f2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'ADD FRIES AND A MILKSHAKE',
        price: '+85'
      },
      {
        id: 'ab9ebb31-4c1c-415c-a31e-78033e01de6d',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'CRISPY BACON',
        price: 18
      },
      {
        id: '53367d1e-43ff-4eed-b294-d99b67945320',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SAVORY ONION',
        price: 8
      },
      {
        id: '56f53c63-e7f2-4c95-a806-c4e35d68e2ca',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'JACK CHEESE',
        price: 18
      },
      {
        id: 'fa743393-6561-45df-98cd-046df20c2a49',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'SLICED PICKLE',
        price: 8
      },
      {
        id: '72575858-461c-483d-b815-41f374f15447',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'FRIED EGG',
        price: 8
      },
      {
        id: 'faf11fc5-8cb0-4eaf-9810-eccf62477058',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'EXTRA PATTY',
        price: 58
      },
      {
        id: '8fdf3579-c282-48a9-a57c-22bd6076ba52',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BLUE CHEESE SAUCE',
        price: 18
      },
      {
        id: '2eb50e62-5454-4544-8767-a51da581e57a',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'JALAPEÑOS',
        price: 8
      },
      {
        id: '933d8b59-4a3c-4c9a-ae90-4594412aa4b3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'HOUSE-MADE REFRESHMENTS',
        description: 'Passion Fruit Pineapple Soda, Lemon Soda, Cola,Brewed Ice Tea, Fresh Lemonade, Arnold Palmer, Add a shot +35',
        price: 48
      },
      {
        id: '9c2323e3-c9ff-4e8d-93e6-cd30923a6964',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'WINE',
        description: 'Glass/Bottle',
        price: '48/228'
      },
      {
        id: 'de76aad6-0ddc-4d17-9681-5c258e128d58',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'BEER',
        description: 'Modela Negra / Budweiser',
        price: 58
      },
      {
        id: '5bc1d63f-59ae-4219-9574-df0b7b6d6765',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'STILL/SPARKLING WATER',
        description: '550 ml',
        price: 28
      },
      {
        id: 'ec2de584-e117-4a27-acb5-6d2df8b29cb6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Breakfast Panini',
        description: 'Pancetta, Two Fried Eggs, Chilli Mostarda, Mozzarella',
        price: 98
      },
      {
        id: 'ab3bf25b-ee1e-4351-a26a-8db8cd7c9e4e',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'San Frediano',
        description: 'Arugula, Prosciutto, Buffalo Mozzarella',
        price: 98
      },
      {
        id: '83812dfe-30b2-488c-9070-ef4af35aba56',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Santo Spirito',
        description: 'Chicken Cutlet, Coppa, Mozzarella, Tomato, Basil Spread',
        price: 98
      },
      {
        id: '88422fce-a8ee-4868-838d-9cb0814989df',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'San Niccolo',
        description: 'Coppa, Fennel Salami, Salami Milano, Provolone, Tapenade',
        price: 98
      },
      {
        id: '6b9e8111-b89b-47ff-945e-a1dbd17ef632',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Santa Maria',
        description: 'Mortadella, Pickled Peppers, Taleggio',
        price: 88
      },
      {
        id: 'f944e457-7d14-4d2e-a33b-9a447528f4eb',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Mercato Centrale',
        description: 'Chickpea Fritter, Calabrian Chili, Pickled Eggplant',
        price: 88
      },
      {
        id: 'f6c50983-09c2-46b6-b968-6b15ac95979c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Assortito',
        price: '148/178'
      },
      {
        id: '16d22754-75df-4048-bb01-699e543999bf',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Prosciutto Di Parma',
        price: 98
      },
      {
        id: '5db59a6e-b6a3-424d-844a-bcf1f9c49565',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Bresaola',
        price: 98
      },
      {
        id: 'd0d78f48-281d-49d9-bc30-d9ef711c67a3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Soppressata',
        price: 78
      },
      {
        id: 'bb91df59-bf3b-472b-b559-c2bce12b6858',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Coppa',
        price: 78
      },
      {
        id: '0b556137-f989-4190-a331-873e9b734edc',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Assortito',
        description: 'Gorgonzola, Parmesan, Taleggio, Asiago',
        price: '158/198'
      },
      {
        id: '35b4ae5d-50a0-46dc-82a8-1d858a4b307f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'FORMAGGI E SALUMI MISTI',
        price: 198
      },
      {
        id: '88a2e019-a241-46b7-8ab4-1239bf9709a1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Cinzano, Veneto NV',
        price: '88/358'
      },
      {
        id: '3610652d-9bb7-444c-867c-fc9771bd5a18',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Zonin ‘20 Ventiterre’ Pinot Grigio, Venezia 2019',
        price: '88/358'
      },
      {
        id: '82126716-a188-49a9-8df6-be79638bbaee',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Santa Sofia ‘Montefoscarino’ Soave Classico, Veneto 2017',
        price: '98/398'
      },
      {
        id: '1cb13adc-fac2-4958-a4fe-1e2eb6ab0a07',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Tenute Nicosia ‘Fondo Filara’ Grillo, Sicily 2018',
        price: '118/488'
      },
      {
        id: '71df3abb-1e31-480c-958b-416942d596a6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Zeni ‘Vigne Alte’ Bardolino Chiaretto Classico, Veneto 2018',
        price: '108/448'
      },
      {
        id: 'fafcfea9-5cc4-43a0-9d49-7f19d7a14833',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Etrusca Chianti Magnum (1.5L), Tuscany 2018',
        price: '98/788'
      },
      {
        id: '2878612d-a8de-49ed-8491-cddd801dbc79',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Salgari ‘Le Pesse di Saga’ Valpolicella Classico Superiore, Veneto 2014',
        price: '108/448'
      },
      {
        id: '264c0459-b2dd-4a80-bbb1-3862b5688cb1',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Primitivo ‘Sasseo’ Masseria Altemura, Puglia 2017',
        price: '108/448'
      },
      {
        id: 'd90647b6-ac3e-434b-a962-25d0d37fafac',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Nero d’Avola ‘Corale’ Settesoli, Sicily 2018',
        price: '88/358'
      },
      {
        id: 'ba79b498-ae86-4ae1-98bf-2461f6e7df12',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Farnese ‘Casale Vecchio’ Montepulciano d’Abruzzo, Abruzzo 2017',
        price: '138/578'
      },
      {
        id: '922a1009-bb05-43cc-82f9-afce8127a5c9',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Americano',
        description: 'Campari, Sweet Vermouth, Soda Water',
        price: 88
      },
      {
        id: 'c7630f3f-af44-45e4-af0f-6b1186606792',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Negroni',
        description: 'Gin, Campari, Sweet Vermouth',
        price: 88
      },
      {
        id: '3504ac08-7c34-42c5-89a2-afd352c8e0ab',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Il Presidente',
        description: 'Campari, Prosecco, Aperol, Ginger Ale',
        price: 88
      },
      {
        id: '035ceeaa-215a-4ae5-8239-eab80fa2b3a3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Aperol Spritz',
        description: 'Prosecco, Aperol, Soda Water',
        price: 88
      },
      {
        id: '49323d1b-e729-424c-a540-f06b08f7dfb2',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Espresso Martini',
        description: 'Absolut Vodka , Kahlua, Nitro Coffee',
        price: 108
      },
      {
        id: '866b2cbb-e340-47dd-942a-d3140bfdb054',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Espresso Ristretto',
        price: 22
      },
      {
        id: '71918738-b37d-4ce7-92f8-1e50bd6234dc',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Espresso',
        price: 22
      },
      {
        id: 'a139a31f-244a-4305-bae3-cc64b9cf809f',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Doppio',
        price: 24
      },
      {
        id: '244de314-0b65-4468-ac94-7c4c01addba7',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Espresso Macchiato',
        price: 28
      },
      {
        id: '40d9be9f-7cbc-441f-9e2f-d856b5da98a8',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Caffè Americano',
        description: '8 / 14 oz',
        price: '28 / 30'
      },
      {
        id: 'd03ca183-6b8a-49f1-a1b6-b81a9cb6f137',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Espresso Lungo',
        description: '8 / 14 oz',
        price: '30 / 32'
      },
      {
        id: 'b17de559-af13-4b3e-bfde-f5926064164c',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Cortado',
        price: 32
      },
      {
        id: '75f14585-e7d0-4522-99f0-e91592adac30',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Caffè Latte',
        description: '8 / 14 oz',
        price: '34 / 38'
      },
      {
        id: 'ba3ecfb7-6730-423d-a3ba-e74362375255',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Cappuccino',
        description: '8 / 14 oz',
        price: '34 / 38'
      },
      {
        id: '1573b37d-2a92-4fe7-a430-07e74a6504c3',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Flat White',
        description: '8 / 14 oz',
        price: '34 / 38'
      },
      {
        id: '0692546e-65db-4b15-9d8f-e77ee2d0e015',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Caffè Mocha',
        description: '8 / 14 oz',
        price: '36 / 38'
      },
      {
        id: 'dff75e38-3885-481e-9acd-5169ced1abe6',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Caffè Corretto',
        price: 58
      },
      {
        id: '6e4e3779-5809-4173-a65f-5bd3c238b696',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Iced Espresso/Americano',
        price: 32
      },
      {
        id: '528d5ee4-b7e6-421d-8184-4df75574d595',
        restaurantGroupId: '6a3edc31-cb43-4881-b472-c0395798e374',
        name: 'Iced Latte',
        price: 36
      }
    ],
    menu_item: [
      {
        id: '96988253-7ae0-4d1f-a460-6e91948b2292',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: 'caa0d776-3951-4eee-b964-d9794eaaa7f0',
        name: 'Dumplings',
        description: 'mom’s “mostly cabbage, a little bit of pork” dumplings, sacha soy dressing'
      },
      {
        id: '947c9161-b311-46ec-990f-222a69844efd',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '2e5d890b-69ce-41f7-b9af-cbc30380a6d4',
        name: 'Prawn Toast',
        description: '“prawn toast x okonomiyaki” kewpie mayonnaise, bull-dog sauce, shaved cabbage, aonori'
      },
      {
        id: '6657b6b9-0c5a-4cef-9eb7-634c0778d798',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '33b5eb91-fae0-425b-81d7-947bd8d89ccd',
        name: 'Chicken Wings',
        description: 'chongqing-style chicken wings, heaven-facing chillies, dried'
      },
      {
        id: '99c69096-78ab-4363-9fc2-8333113b3052',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: 'ad47d525-b216-431d-a2c4-aa2d4d47a5e7',
        name: 'Chiles',
        description: 'chillies, sichuan pepper'
      },
      {
        id: 'f4433921-df8c-4823-b936-284efc502332',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '4871c493-9f5c-4057-bab7-22f164e321d1',
        name: 'Steak Tartare',
        description: 'wagyu steak tartare, cured egg yolk, knife cut chilli powder, sorrel'
      },
      {
        id: '1c6a1c8f-a7c5-4e29-8b13-85f805b498dd',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '12502bbb-fcd0-4127-83ad-988a6bb1bbe6',
        name: 'Eggplant Salad',
        description: 'smoked eggplant salad, fermented chillies, red cabbage, vietnamese mint'
      },
      {
        id: '50207fcd-59e2-4d1a-9801-f89367f354e8',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: 'd7da450e-a132-4993-b0c3-21fceec604db',
        name: 'Calamari',
        description: 'grilled calamari, caramelised onion and tamarind jam, pickled cucumber, vietnamese shiso'
      },
      {
        id: 'cbc196a3-55e1-4d3c-87ad-f746b9e6b380',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: 'ef09c2a8-dfe4-4bd7-9197-a09ce7d337bc',
        name: 'Bean Salad',
        description: 'wing bean and edamame salad, vegetarian XO, garlic sauce, toasted rice'
      },
      {
        id: '3d00e2aa-9603-4aeb-96f1-0c3d02954f26',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '5ced7264-2af8-44a2-9c98-6144a00a9e69',
        name: 'Bamboo Salad',
        description: 'bamboo salad, pickled lotus root, snow peas, five-spiced tofu, sesame'
      },
      {
        id: '198fca60-e0f0-426e-86d8-aa3d82b04fc4',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: 'ebf8ba65-6429-46bf-84a1-ca3d1e09eb07',
        name: 'roast goose'
      },
      {
        id: 'd4c08ef4-7dc1-4952-b8d8-3a549fcdec9b',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '21aefdf2-6507-4d5a-b38d-9fdd4c6cff08',
        name: 'crispy skin chicken'
      },
      {
        id: '097d2291-87aa-4106-abd3-53219e3933ed',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: 'a867c35b-2946-443e-9e78-c1c3fc74c507',
        name: 'kurobuta pork char-siu'
      },
      {
        id: '3f1a6e78-a453-4516-a449-5d6da3837776',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: 'bbca1565-8926-44cb-bcf8-d87116778cb3',
        name: 'roast platter'
      },
      {
        id: '8fb718cd-31ab-46b6-a7fc-38b37ae335b2',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '05c0e0ad-050a-4981-aed3-5f0c9edbdf51',
        name: 'Mixed Vegetables',
        description: 'asparagus, broccoli, sugar snap peas, garlic, shaoxing'
      },
      {
        id: 'ce188e2c-005f-4159-ab60-d20701290043',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '12ce089f-a9d3-4b28-ba8f-f1ae00aeec9f',
        name: 'Fried Corn',
        description: 'fried corn, typhoon shelter-style, crispy garlic crumbs'
      },
      {
        id: '3f2005f0-107c-46c0-9aa0-a4f5dc085493',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '058375eb-7061-4f3c-883b-b1528fd87663',
        name: 'Spinach',
        description: 'sautéed baby spinach'
      },
      {
        id: 'f455b89f-47c1-4f9c-b9af-5b6aa5138664',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: 'b7725007-1a6d-4447-8fb4-60dedaf9ca86',
        name: 'Claypot Cabbage',
        description: 'sizzling claypot cabbage, tomato anchovy sambal, fish sauce'
      },
      {
        id: '3cc6dd48-9ee6-435a-8861-1e02770188e4',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '6eda1090-f6f2-46ba-854d-23fa9088772b',
        name: 'Mapo Tofu',
        description: 'mapo tofu, minced pork, broad bean chilli paste, pickled turnips'
      },
      {
        id: '9bb67af1-8480-4499-b18c-5daaf9975f1b',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '5f102881-b23e-4490-8c2d-37956978a812',
        name: 'Mustard Greens',
        description: 'stir-fried pork mince, fermented mustard greens, vietnamese mint, toasted chilli flakes'
      },
      {
        id: '582ed279-0987-448a-b5c4-bb642fe006ba',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '14d787fe-687f-46dc-b062-30b0b4f13bac',
        name: 'Beef Tenderloin',
        description: 'black angus beef tenderloin, black peppercorn, celery, cashews***'
      },
      {
        id: '4f50ffc5-38f3-4652-8368-bd09309a9e9a',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '5deba2d8-dba4-4d8c-a334-0f8cab8dceb9',
        name: 'Fried Barramundi',
        description: 'jack’s fried salt and pepper barramundi, silken tofu, lemon'
      },
      {
        id: 'e694b6e4-5225-4e0c-9c76-7dd3cde2c4c2',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '40ed3fa1-b4b1-4575-a6f2-9082e2457014',
        name: 'Steamed Barramundi',
        description: 'steamed barramundi, ginger, green shallot'
      },
      {
        id: '959d9d20-4b21-43ed-bffd-ddbef6f0208e',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '3a06b963-3546-4643-812d-b11d7e0c9ab1',
        name: 'Snapper',
        description: 'whole grilled star snapper, fermented beans, chopped chilli, dill, green shallot oil'
      },
      {
        id: '4b36c97f-4658-4fa3-93cc-242edd860230',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '4dec6127-dc72-4242-b609-92a65a9cf3f7',
        name: 'Wagyu Short Rib',
        description: 'roast wagyu short rib, jalapeño purée, green shallot kimchi, soy glaze'
      },
      {
        id: '0e3bbafc-3ec9-49df-993b-e87f42bf8263',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '2f289069-35f7-48e4-954a-08e700755189',
        name: 'Fried Rice',
        description: 'egg fried rice, crispy garlic, green shallot'
      },
      {
        id: 'f11135fb-a913-4192-b023-4241f0a94102',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: 'ba3011a8-d114-4dbd-8979-151577345814',
        name: 'Prawn Fried Rice',
        description: 'prawn and bacon fried rice, chinese preserved vegetables, shellfish oil'
      },
      {
        id: 'a48821eb-ed4c-47c4-9281-956dc25227b1',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '5328a6e4-a3b6-42fa-a0b1-377c1b83a555',
        name: 'Rockpool Fried Rice',
        description: 'rockpool fried rice, salted whitebait, sakura ebi, aonori'
      },
      {
        id: '17249247-d9d4-47b6-8b77-b802a37ee5b3',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '9d833901-a737-45b9-a6e9-1b4df8ed2823',
        name: 'Dan Dan Noodles',
        description: 'wagyu dan dan noodles, sesame paste, crispy panko, rice paddy herbs'
      },
      {
        id: '2f2da974-6605-4ada-957e-67dfebd77b7f',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: 'ccc3a136-508e-40a3-b08b-dce54fcd7884',
        name: 'Seafood Chow Mein',
        description: 'combination seafood chow-mein, tiger prawns, calamari, snow peas'
      },
      {
        id: 'eace8090-8b93-4988-9f45-704bf7fee10a',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: 'f7abf5af-4d67-4a91-a919-12f4dcc82ee2',
        name: 'Cheong Fun',
        description: 'wok-fried cheong fun with XO, white garlic chives, toasted sesame'
      },
      {
        id: '34a64336-f27f-49bd-a215-9dd00307c2e0',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: 'aeb06d0e-fef3-49c8-9d29-255ca5eb664f',
        name: 'Shaanxi Noodles',
        description: 'shaanxi-style scissor-cut noodles, seasonal vegetables'
      },
      {
        id: '52e1546d-6592-4360-807b-02f04c48195e',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '45b7823b-d401-4477-856f-a4d74599b127',
        name: 'Steamed Rice',
        description: 'mix your own steamed rice, seaweed, toasted sesame, pork floss, sesame oil'
      },
      {
        id: 'a23eeda8-dfa2-4f33-8126-28aaeab5d652',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '3584fdd3-ab62-47d1-841b-36bf622b19fb',
        name: 'Breakfast 2.0',
        description: '“breakfast 2.0” horlicks ice cream, cornflake honey joy, walnut oatmeal, dried longan, cocoa coffee crumbs'
      },
      {
        id: '9b37cd2e-ae79-479b-b27a-f2c6f854c359',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '3da4cc71-a858-4403-a27e-79d90cde7ea1',
        name: "Guard's Pudding",
        description: 'guard’s pudding, candied lemon, kavalan whisky cream'
      },
      {
        id: '57059d96-cad5-4af6-b557-30ae6e32351c',
        menuId: 'a7d9085c-3c36-40cf-90d4-6ca7104a12d4',
        itemId: '92fa03dd-0781-408f-b0aa-c844a09c3601',
        name: 'Chinese Sorbet',
        description: 'chinese date and yoghurt sorbet, taiwanese guava, salted plum powder, yakult granita'
      },
      {
        id: 'c2bc75b0-037c-489b-8026-f1d62989f25e',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '4c04eb4b-319e-45c7-96a6-c338d4d42f98',
        name: 'MURGH TIKKA ANGAR',
        description: 'Spice-marinated chicken, liver chutney'
      },
      {
        id: 'a812ec47-7d96-4c11-864a-9ba2b660c3c8',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: 'c147aaa6-f44e-4efc-ab8f-13ef2a2d2aff',
        name: 'TANDOORI LOBSTER',
        description: 'Boston lobster, cucumber raita, laccha paratha'
      },
      {
        id: '65d59562-ac88-4260-856a-b2f53baa6727',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: 'cf38174c-c5e9-45ca-b790-2dea6cd70db8',
        name: 'NIMBU JHINGA',
        description: 'Prawns, burnt garlic chutney'
      },
      {
        id: 'd4917701-ce6f-4342-9689-a25fa186e5b1',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '0fbd894d-4944-40e7-bc85-59c1b23587e5',
        name: 'TANDOORI COBIA',
        description: 'Line-caught Cobia, smoked tomato & samphire chutney'
      },
      {
        id: '0c278451-8667-4f1a-8345-30c23ad1cd0f',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: 'd585ea90-1c50-4d2c-8dc1-a1375884965d',
        name: 'MASALEWALI CHANP',
        description: 'Lamb chops, ember-roasted onion'
      },
      {
        id: '91256cbe-b53e-4445-83b9-868eec325ac9',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '2da976fc-1862-4e6d-b1be-e0ebc67d78a1',
        name: 'MOGEWALA PANEER',
        description: 'Spiced soft cheese, beet kachumbar'
      },
      {
        id: 'a3d31cea-7452-4ff6-bf60-31f88531b264',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '7763a735-644c-4ff6-9115-1d344a345d84',
        name: 'TANDOORI GOBHI',
        description: 'Cauliflower, green mango raita, gunpowder'
      },
      {
        id: 'ecd47b6b-2b7d-40c3-92a4-dd8134f49bc6',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '104d0291-b25a-44c9-94fb-5240d94f5a95',
        name: 'MUGHAL ROOM MAKHANI',
        description: 'Chicken tikka, spiced tomato, butter'
      },
      {
        id: 'a498ea9b-9703-4638-a1c7-b597878d0bb0',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '84a79c35-0c6d-4fed-b3e9-d20703954cc4',
        name: 'JUNGLEE MURGHI MASALA',
        description: 'Guinea fowl, ground onions'
      },
      {
        id: 'ae82b36e-c024-4b64-ac37-cd166966158e',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: 'da7f6980-6c1b-4d15-bb78-35ca51beeaa9',
        name: 'GOSHT SALAAN',
        description: 'Lamb shank, black cardamom'
      },
      {
        id: 'e90ad0a5-8ed0-4142-a708-86e31c9cbde7',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: 'e51d6e5a-eb67-471f-bfea-e189c4f88bc2',
        name: 'SAAG PANEER',
        description: 'Spinach, soft cheese, garlic'
      },
      {
        id: 'afcbea2e-b939-44ff-ae48-965a31ed674d',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '13993ba3-9d2c-4f84-9c84-f4edd8a1bceb',
        name: 'DUM ALOO',
        description: 'Papa Coctel Peruvian potatoes, tomato, green cardamom'
      },
      {
        id: '4c9d0fd2-cfa8-4bd9-bc7c-8e3e6666108f',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: 'f8fb4b47-6431-4a82-a52f-8fec4bae985b',
        name: 'DAL MAKHANI',
        description: 'Slow-cooked black lentils, churned butter, fenugreek'
      },
      {
        id: '9fb86257-f1e8-488c-a20e-7b667f58e7e8',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '7ff4f131-ca30-4f8c-9a45-0fbae8322887',
        name: 'BASMATI RICE',
        description: 'Aromatic rice'
      },
      {
        id: '842b34d3-14d9-4419-aef1-ea9e72a7158d',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: 'f8849f65-4834-4e49-b6fa-317cb9e4491b',
        name: 'SAMOSA CHAAT',
        description: 'Smashed samosas, crispy noodles, tamarind glaze, yoghurt'
      },
      {
        id: '9a128a43-b35c-448e-ad70-f7340ef70e3c',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '8908dc7c-3a20-415a-8711-2952b6a150b3',
        name: 'KEEMA PAU',
        description: 'Spiced mutton, milk buns, crisp potatoes'
      },
      {
        id: '315db29c-c041-42b2-a91e-ecf835c0a892',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '2ad099c0-3ccf-4f0d-8026-7b69854a7cb0',
        name: 'TANDOORI ANDA',
        description: 'Taiyouran eggs, kachumbar, smoked salt'
      },
      {
        id: '5d383b7b-e7be-4fae-b726-c96a1226a1b7',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '70922818-6f32-4b44-b687-70aace42de20',
        name: 'AMRITSARI MACHLI',
        description: 'Batter-fried sole, dill raita'
      },
      {
        id: '8d57df07-90ef-4427-9bfc-a59a48824bc2',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '2b8958ff-8575-4346-abf0-bb789fed51d7',
        name: 'HARA SALAAD',
        description: 'Carrot, cucumber, mooli, turnip'
      },
      {
        id: '34c6e649-9326-4e42-893a-5166ebec5f68',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: 'f1a21389-54fc-4cc9-b821-e9d513cf2c2d',
        name: 'PYAZ AUR MIRCHI SALAD',
        description: 'Nasik onions, green chilli, nimbu'
      },
      {
        id: '0c7cdfe6-3fd9-49e1-ab7b-17db355f52b9',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '963b06b9-529d-413a-9d5b-e6ac9774b408',
        name: 'CHUTNEYS'
      },
      {
        id: '4317d7a6-9839-47a3-885d-1ab8bc792669',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: 'e55cc037-78c4-49a0-b92d-476ae1b3fc21',
        name: 'RAITA'
      },
      {
        id: '019b3d8f-c87a-499f-bc06-365152f0b0e4',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '44d2f903-0bd2-4a32-aee3-8eac28079def',
        name: 'BUTTER NAAN'
      },
      {
        id: 'adcf1611-c940-40e4-a2e8-94c8b02432cf',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '536bb4c1-8570-4156-b0ff-ecf3c85b774a',
        name: 'TANDOORI ROTI'
      },
      {
        id: '42e46000-7f7a-4b26-8de0-3d04c166ec18',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: 'da28b044-f8ec-41d7-a667-3615bc10d8b5',
        name: 'PANEER KULCHA'
      },
      {
        id: 'e1252879-cebe-4113-bdd7-da2a3ec64856',
        menuId: '4ed44831-d5c6-4c91-9c5f-c9bbaad94566',
        itemId: '403bd6c3-28ac-4a83-8b47-f653f5c19fe2',
        name: 'LACCHA PARATHA'
      },
      {
        id: '7d4b7ecf-f9cc-41f5-a79b-072d6db82bd4',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: 'e80e96ce-094d-4e00-82fe-dabda270ce15',
        name: 'OYSTERS',
        description: 'half-dozen'
      },
      {
        id: 'df51bdff-49ff-4839-924b-664235730310',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '063dcab1-90ab-4708-962b-d4ed25d8211b',
        name: 'HAMACHI',
        description: 'cherry tomato, olives, basil'
      },
      {
        id: 'd8862305-f3e5-412d-b777-b10883c00c32',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '94614e3d-7e03-4a0c-82ba-f3687cea63de',
        name: 'HOKKAIDO SCALLOPS',
        description: 'finger lime caviar, pomelo, grapefruit citronette'
      },
      {
        id: 'e14a98df-30e2-4ed3-97df-dfe8c5a8e860',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: 'f859a444-a8d9-4ddb-9d58-47442affb91e',
        name: 'CANNOLICCHI',
        description: 'razor clams, salsa verde, caper, lemon'
      },
      {
        id: 'ab85ce83-4118-462e-9ebc-173ab0f3cf5a',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '35f3182d-4106-40ce-994e-1cdc149a3ea4',
        name: 'CALAMARI FRITTI',
        description: 'red chilli, green chilli'
      },
      {
        id: 'd7b233b1-d590-44fd-8eca-062913116876',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '67be4b05-232f-4782-aa9e-c3f5f62fa1d6',
        name: 'FRUTTI DI MARE',
        description: 'market seafood, tomato, basil, grilled bread'
      },
      {
        id: 'afbb652f-8afb-47d6-890c-396182b3d7f1',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '9990c205-12f2-4f6a-977a-6779664ea176',
        name: 'PASTA CON LE SARDE',
        description: 'sardines, saffron, wild fennel, pine nuts, Sicilian bread crumbs'
      },
      {
        id: 'b097f3f7-352b-4d91-8971-eb75f43ee6f6',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '92a3d061-513b-43ed-abaa-af1f4b972b1e',
        name: 'CHITARRA',
        description: 'Boston lobster, cherry tomato'
      },
      {
        id: 'f4b7b7c7-94c2-49bc-aa6a-169f08aee8f6',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '08f97d7f-3b7a-459a-bbdb-25031418ef41',
        name: 'SPAGHETTI A.O.P',
        description: 'aglio olio e peperoncino'
      },
      {
        id: '68110502-8f7c-4159-8862-d7ca15fbc606',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: 'ff900bb8-2de8-4677-99ae-dbd872410dcc',
        name: 'CAVATELLI',
        description: 'market fish, cherry tomato, basil'
      },
      {
        id: 'c4c2d06d-b023-4c3d-9e6b-4d9cce5adb10',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '20323919-fa69-4bc2-b93d-20c379ccee07',
        name: 'CRUDO DI DENTICE',
        description: 'snapper, pomegranate, citrus'
      },
      {
        id: '945d1f76-a67e-427f-a256-d09bcc7d838e',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '22c02cd8-df1c-4655-8820-fce47be19855',
        name: 'SEPPIA',
        description: 'cuttlefish, Sorrento lemon, extra virgin olive oil'
      },
      {
        id: 'bf2d2ec7-53ed-4438-acd5-a8873dbb79cf',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '188d1241-dc0b-4736-ad5f-fbc6d97f2d38',
        name: 'LUMACHE DI MARE',
        description: 'braised whelk, potato puree'
      },
      {
        id: '25fa5fbc-0a1e-4dde-bb4b-9be1d6c65ae3',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '3b000ba7-a66d-4d8a-a601-a821d4ec6e3e',
        name: 'MEZZI PACCHERI CON MAZZANCOLLE',
        description: 'half paccheri, tiger prawn, broccoli, almonds'
      },
      {
        id: 'ae58cdd3-7de5-4fce-a264-bd455b899d57',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '642e9138-8ed8-467a-9155-2ad5040f0ce3',
        name: 'BURRATA',
        description: 'beets, sorrel, pistachios, balsamic reduction'
      },
      {
        id: 'db116260-ca99-4846-b43a-06175cb6dbef',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '7e09aa92-0c17-468a-af78-4595d6afc29a',
        name: 'INSALATA MISTA',
        description: 'mixed leaves, agrodolce dressing'
      },
      {
        id: 'c0cebaed-2d15-44ce-863d-8f99cc93f7c4',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '2b7a3224-d3ca-40b4-b8eb-01b59b031a6e',
        name: 'POLLO AL MATTONE',
        description: 'spiced chargrilled chicken'
      },
      {
        id: '04a9b831-9403-4ff2-a952-a3153478345f',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '99d435e7-aa61-409d-87ea-9d23a8bcb8a7',
        name: 'FILETTO',
        description: '10oz USDA Prime Fillet, arugula, cherry tomato, parmesan, aged balsamic vinegar'
      },
      {
        id: '420b01f5-5f14-4e30-b361-7117c872b6ee',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: 'c3f10f89-683f-440d-9c53-bbd477a48536',
        name: 'PESCATO DEL GIORNO',
        description: 'market fish and your choice of preparation: simply grilled, puttanesca, brown butter caper sauce, salt-baked, acqua pazza'
      },
      {
        id: '3bea2536-997b-48cf-aebb-dc3ba96bf6c9',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '123dfa37-eca0-4124-bf5a-c7df977ff560',
        name: 'ZUCCHINI FRITTI'
      },
      {
        id: '971b8e55-b3ed-4134-b182-895c3734e22d',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: 'b392313e-c935-458b-ad67-95b8b7452aa5',
        name: 'FIRE-GRILLED VEGETABLES'
      },
      {
        id: '789c5f2f-fc1c-4ac6-acc4-a2535bafbe32',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '8441f6b1-2a60-411c-a34b-504f3fbfefa3',
        name: 'BROCCOLINI, GARLIC & CHILLI'
      },
      {
        id: '012d1793-242b-4d05-bc3d-85a8bc9c3b80',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: 'ecb49e47-fd8e-4140-95f9-2142f36795d9',
        name: 'POTATOES & ROSEMARY'
      },
      {
        id: '347f0f0d-13cb-499d-8bd5-d72ea5745549',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '110fd0e1-eb46-4594-8aa1-1256bbdb933b',
        name: 'PANNACOTTA AL MIELE',
        description: 'panna cotta, millefiori honey, mixed berries'
      },
      {
        id: 'd206ea58-1689-4c47-b0a2-298d523c9245',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '7327dd7d-4388-4cf5-954b-a67e3a58af98',
        name: 'PASTIERA NAPOLETANA',
        description: 'ricotta, orange blossom'
      },
      {
        id: 'b382bbd6-d465-41a4-9322-85a1c54bf690',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: 'f3d022e1-4a03-4df1-ae1f-180335f36700',
        name: 'RUM BABA (SERVES 2)',
        description: 'limoncello chantilly'
      },
      {
        id: '50130542-4cdd-45c4-81bf-b7196f21e8ef',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '0a58e61f-be72-4b81-9738-44fe28b43225',
        name: 'CANNOLI SICILIANI',
        description: 'ricotta, chocolate chips, cinnamon, candied orange'
      },
      {
        id: 'eaacde9b-56ab-4d5b-a45c-1491ae3a97ce',
        menuId: 'd62acdfb-bda5-4067-94ba-7ef13e0dda32',
        itemId: '20c46971-8f95-4b7c-be54-fcc828c2d94c',
        name: 'FRESHLY CHURNED GELATO'
      },
      {
        id: '22cd1612-8b45-4da9-a2bb-3a92558e0238',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: 'c9f8c640-0c90-4397-9e2c-af84918d8d87',
        name: 'Set Lunch',
        description: 'choice of antipasto and piatto principale',
        price: 238
      },
      {
        id: '52c1f5f0-9a2e-4fa3-93e0-8c4007927a57',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: 'eb02ba19-b52c-48f6-a0bf-8b115ff81306',
        name: "Sommelier's selection",
        description: 'Wine by the glass',
        price: 68
      },
      {
        id: '3339bd0f-1ba4-49ca-9304-87fa5d83896e',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: '7c69a3b0-3038-4918-afae-7efbd2e6d365',
        name: 'Insalata Mista',
        description: 'mixed salad'
      },
      {
        id: '753c7fa5-5403-4e80-911f-b1e2e468e34a',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: '85bdbee0-dda5-460a-946e-e77c635edc7c',
        name: 'Zuppa di Stagione',
        description: 'seasonal soup'
      },
      {
        id: 'f0b693f0-112e-4cd7-99a1-0b42cc079951',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: '6b24e8fd-836b-4f84-bdcc-ca1121261f8b',
        name: 'Crudo del Giorno',
        description: "Chef's daily crudo",
        price: 68
      },
      {
        id: '938315a9-d204-4b45-9dec-a84f8c1c4bdc',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: '54b90cfa-bdc6-456b-a07e-3c6e81b7e7cd',
        name: 'Pasta Fresca',
        description: 'daily pasta'
      },
      {
        id: 'b5a1ba7e-80d8-4b4c-9e78-0eda71c953e0',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: '9deb3c97-1e71-4a0e-b4be-0497064f303f',
        name: 'Linguine Alle Vongole',
        description: 'Hokkaido clams, parsley, chilli'
      },
      {
        id: 'cc401749-30f4-4dcd-9864-633743000853',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: '228ec363-dc80-4960-b78e-455be4c09c31',
        name: 'Pescato del Giorno',
        description: 'catch of the day'
      },
      {
        id: '3ee50e57-ecb8-48f5-8ad9-8ee2df789b6d',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: '6e3052b2-e5e8-4d6e-a357-9d2b0303f09a',
        name: 'Pollo alla Griglia',
        description: 'spiced chargrilled chicken'
      },
      {
        id: 'aebb16b3-da54-4867-bd0b-f42bee37752f',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: '8c76b5e5-14d7-4291-998b-b81b48f2e69d',
        name: 'Filetto',
        description: '150g USA Prime Fillet, arugula, cherry tomato, parmesan, aged balsamic vinegar',
        price: 68
      },
      {
        id: '1145df0e-a0b5-4e9f-9377-b5dc509fcb23',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: '62af99f7-dc02-42f2-a203-6d92d852b073',
        name: 'Gelato',
        description: 'freshly churned gelato',
        price: 28
      },
      {
        id: '28a31cea-ee02-466e-89cc-dfb8ecb7cd1c',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: '2041533d-4ae4-4234-bf9a-0170204f9aca',
        name: 'Coffee',
        price: 38
      },
      {
        id: 'df15dec5-7680-44e0-a82d-b38756d66320',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: '74c05ef4-13da-409a-ada7-cf7794c1a545',
        name: 'Tea',
        price: 38
      },
      {
        id: '7733d676-8840-4816-bea0-1d83d192a630',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: 'd1b44a86-3a4d-41f0-9808-b1b71d3bb138',
        name: 'Orange Juice',
        description: 'fresh-pressed',
        price: 38
      },
      {
        id: 'aa017fc8-89fc-435c-b763-c054bbb2f34b',
        menuId: 'f17c92d6-ceb9-4779-b29f-4945e5d722ec',
        itemId: '63d0af9d-3921-4400-b514-7d1b6868f233',
        name: 'Grapefruit Juice',
        description: 'fresh-pressed',
        price: 38
      },
      {
        id: 'ca4018d8-17b9-4713-baf9-ead214ee95a9',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '5fe148b8-3acc-4e91-82af-19cad3383ed1',
        name: 'BISTECCA ALLA FIORENTINA',
        description: '1kg T-bone steak, thick-cut and cooked medium rare in true Florentine fashion - Serves 2 people'
      },
      {
        id: '9288c839-b9f0-4bed-b1e3-925108c90caa',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '779ccb52-66b4-4bed-8b99-f6cf6d14ead0',
        name: 'COSTATA ALLA FIORENTINA',
        description: '1kg bone-in ribeye steak, rich and marbled - Serves 2 people'
      },
      {
        id: '595e4ad2-17c5-47be-be8f-6aaa1edebc03',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: 'a921c7f9-a9a3-4038-a5ec-dd926b871db4',
        name: 'COSTATA',
        description: '350g boneless ribeye, rich in flavour'
      },
      {
        id: '3c287039-eec3-4678-98ee-c27b196c3389',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '7da109fe-18cc-455d-8071-ccd40a4afd6a',
        name: 'CONTROFILETTO CON L’OSSO',
        description: '450g bone-in striploin, thick-cut'
      },
      {
        id: '05772f0d-454f-4ab7-8743-08ff42ad6a99',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '63bad4c2-5e74-4bec-9198-0012f078d0b2',
        name: 'FILETTO',
        description: '250g tender fillet steak'
      },
      {
        id: '852ed621-1370-47f0-b05d-c219fa4f3217',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '9e124326-56fc-4956-8ff4-8807002eea21',
        name: 'POLLO AL BURRO OMAGGIO A SOSTANZA',
        description: 'Chicken breast pan-fried in brown butter, our nod to the Florentine institution Trattoria Sostanza'
      },
      {
        id: 'ac7ec3ed-eb51-4672-b139-29b9f5a3703e',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '6926c356-85b8-476d-9ca2-5b4b4586d308',
        name: 'CROSTINI MISTI',
        description: 'Three rustic crostini topped individually with chicken liver, tomato with garlic and braised cannellini beans'
      },
      {
        id: '73461dc3-91e4-4872-9bd9-56346cd92fb0',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '31d88a56-9b7c-4df5-b42d-68457a8d0d33',
        name: 'SALUMI MISTI',
        description: 'A selection of Italian cured meats'
      },
      {
        id: '65d82fc3-cdb7-4fc8-aa6b-787fd5d386cf',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: 'b07930d0-2ec1-4f74-9562-6082fc4f68be',
        name: 'CARNE CRUDA',
        description: 'Tuscan steak tartare with Carta di Musica crackers'
      },
      {
        id: '2e3beaa8-b82e-4140-a2f9-44051da49d04',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '92922d9d-48c5-445f-8741-60a5c7ee8210',
        name: 'PECORINO AL FIENO',
        description: 'Pecorino stagionato with fresh pear and thyme-infused honey'
      },
      {
        id: '9b149c78-eda5-443c-b82d-9bc6ced1e954',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '0f7657d3-4330-4772-8220-a70712373d54',
        name: 'PANE PECORINO E ACCIUGHE',
        description: 'Grilled country bread with roasted semi-stagionato pecorino and anchovies'
      },
      {
        id: '34e9c987-7198-441c-9efb-39dc3a206e00',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: 'cd34f527-95a8-4d6e-a697-7d96269da92b',
        name: 'INSALATA CESARE',
        description: 'Mixed chicory leaves with anchovies, parmesan and lemon dressing'
      },
      {
        id: '23eafc74-bad0-4c78-8a3b-8fe0c83074fb',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: 'df558f4a-513c-4ba8-92ae-1e0b5aa1d8c4',
        name: 'PICI',
        description: 'Hand-rolled pasta with cavolo nero, pistachio and pecorino'
      },
      {
        id: '2f0951a9-1d75-4829-b4c6-ed6a5b72f9fd',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: 'af039508-908a-4af4-8f66-086663e5cd3a',
        name: 'TAGLIATELLE',
        description: 'Ribbons of pasta with white truffle and butter'
      },
      {
        id: 'b168d788-463f-405d-affb-d0020b6e486a',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '945bab34-2dd4-46b9-8ac8-6bbeb0e3455c',
        name: 'RIGATONI',
        description: 'Pecorino and black pepper'
      },
      {
        id: 'c31a2482-9542-42e8-b7d1-6d9191be2a8b',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '534e1941-b744-4e71-a0bb-475878776dc7',
        name: 'TUSCAN BRODO',
        description: 'Bone broth, farm egg and parmesan'
      },
      {
        id: '959e811b-183a-4379-9f21-a471a8c34fdc',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '849e0241-3f21-4e4b-bfd0-037c5262edf9',
        name: 'GARGANELLI OMAGGIO A TRATTORIA CAMMILLO',
        description: 'Pasta with a Chianti beef ragu and parmesan inspired by Florence’s Trattoria Cammillo'
      },
      {
        id: '2d449149-631a-4831-85e6-e1f6136106a2',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '4e0c2045-d31a-4bca-ba10-8cafdc121430',
        name: 'SPINACI',
        description: 'Braised spinach with olive oil, garlic and lemon'
      },
      {
        id: '43caf338-34fd-49a2-b25a-4ee06b68bed8',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: 'd9963c6b-da30-48f1-b6a7-6ee19bbfa562',
        name: 'PATATA',
        description: 'Roasted potato with whipped lardo and rosemary'
      },
      {
        id: '70105652-1b9d-4b57-8b4d-c7d094e84a87',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '255179e4-8bf0-4ffa-be1e-7aebed60ecd2',
        name: 'FAGIOLI',
        description: 'Braised cannellini beans in soffritto and olive oil'
      },
      {
        id: '26cfdc5d-6d04-40fb-bd9b-0297fc9dbeeb',
        menuId: '86d352ee-8c66-4ce1-a4af-90f674b9fa55',
        itemId: '2ccb0827-146c-4752-aa68-97e0900a5590',
        name: 'BROCCOLINI',
        description: 'Grilled broccolini, bagna cauda and lemon'
      },
      {
        id: '81694bb4-9bd2-4340-b183-725f8b02b03d',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '30169eb5-8a72-415a-be53-4edad76c37b2',
        name: 'Crown',
        price: 788
      },
      {
        id: '30919eec-3838-43db-975a-2026829a861c',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: 'aca1d615-4abb-40c6-8f22-e333fa702f38',
        name: 'Mizuna Salad'
      },
      {
        id: 'ede70c4c-e6b7-4617-95c6-16e193f1b897',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '2bb22804-67b3-4f72-8232-482ed7bedcc5',
        name: 'Tokushima Fruit Tomato'
      },
      {
        id: '4818657c-1825-40fc-8584-2e4bc38d8a30',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '4d253a68-9fac-44fc-8af5-105d8ff5b77e',
        name: 'Grilled Seasonal Vegetables'
      },
      {
        id: 'eff47d71-5c2a-4442-a337-2423f4d13aef',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '97e7231f-029c-4b7a-b384-b8b967ad9bc0',
        name: 'US Prime Tenderloin 180g OR US Prime Sirloin 200g'
      },
      {
        id: 'b89df93f-776b-42f4-99ce-89b56a5f14e2',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '16832860-6580-42a5-b27c-3aa56a35549c',
        name: 'Garlic Fried Rice'
      },
      {
        id: '5c2d4177-b752-49ca-b29b-b982fc039fd9',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '67a3c8ae-ddc1-4307-bb3d-5f3472591cbd',
        name: 'Pickles'
      },
      {
        id: 'a212bf6f-38ee-4fb2-ae59-2e931ea8e482',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: 'd662b26a-c8d8-4b09-a56f-7e67770f5736',
        name: 'Miso Soup',
        price: 988
      },
      {
        id: 'b550c7ac-b5c6-4eea-9d07-3aa0dc1d5fc5',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '9176236d-fd2a-4e8e-b72f-829a676c3044',
        name: 'Super'
      },
      {
        id: 'c1ad72bf-b986-4aa5-91a1-d25ffebd6004',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '1dc69e71-9a96-48cb-978e-c7b1b18755cc',
        name: 'Mizuna Salad'
      },
      {
        id: 'e2e1648d-56a8-47e8-8ed3-47694a51cd0d',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: 'aa323c6b-d8dd-4f8d-b5a0-42a46bef51b0',
        name: 'Assorted Toyosu Market Sashimi'
      },
      {
        id: '6bbd756c-4845-462d-bbde-77be7a12bdf7',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '0a19b3a2-01f9-44da-b8bd-8060459a7e25',
        name: 'Tokushima Fruit Tomato'
      },
      {
        id: '03816c9b-5fc8-473c-9dd2-f1c406c41f6d',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '1e89ec40-4b3d-488c-b5d9-b14d66c5d197',
        name: 'Hokkaido Scallop OR Tiger Prawn'
      },
      {
        id: 'b778269b-a0b9-45cc-bc68-55348e997986',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '98774540-362d-4c02-9e99-0d474484b1d3',
        name: 'Grilled Seasonal Vegetables'
      },
      {
        id: 'da488009-d11e-45c7-8feb-5265e9fc46a8',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '8e2f32a3-9e16-47c2-a0a7-75d909ef1dc8',
        name: 'Australian Wagyu Ribeye 150g'
      },
      {
        id: '468da12d-218a-4153-bdf6-af4ab428229b',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '100add08-a3d3-46fc-b3e5-1dead115f226',
        name: 'Garlic Fried Rice'
      },
      {
        id: '77395645-bd79-4069-82ab-af5a63c3aaab',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: 'dd68d277-f433-4491-867a-a6d9e371b11c',
        name: 'Pickles'
      },
      {
        id: '8b627b16-461d-4f7d-9867-0a2812ce8c93',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: 'dae185ae-e825-4d0e-8c7a-c8b8efb919db',
        name: 'Miso Soup'
      },
      {
        id: '0a445ef7-7493-490a-abdc-1abc56d35eaa',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: 'da5fcdf0-8e1b-4455-921b-8b0a001a99ad',
        name: 'Deluxe'
      },
      {
        id: '098624dd-f586-4911-a614-96829bba5a34',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: 'a39bb651-3b17-4395-8b0b-c9d17001c2d3',
        name: 'Mizuna Salad'
      },
      {
        id: '21f29d8b-5023-4039-9936-4dec585a7198',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '2a6ee159-9afd-45d1-974f-603879975317',
        name: 'Assorted Toyosu Market Sashimi'
      },
      {
        id: '0d326643-8d0e-40a1-9a75-86f82f2f6f89',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '0c528ca2-31e8-4066-a08d-745403b6693e',
        name: 'Tokushima Fruit Tomato'
      },
      {
        id: '5c58c7a4-3c00-49ae-a41c-9419d3ca28f8',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: 'f4405659-ea58-4d49-a79d-c1a9881f9982',
        name: 'Teppan Spiny Lobster in Coral Butter'
      },
      {
        id: '3ec897a2-6cc3-4f31-a29d-148be7766473',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '956a220a-141a-46e2-88f6-f0ee7d38b36e',
        name: 'Grilled Seasonal Vegetables'
      },
      {
        id: '7f74096c-31a4-4deb-81a6-54cea4342333',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '0c83f960-7a51-499f-892b-d5debbd12254',
        name: 'A5 Kagoshima Wagyu Tenderloin 120g OR A5 Kagoshima Wagyu Sirloin 140g'
      },
      {
        id: '86ba79f6-a93d-4fd2-8bda-82ecbb78dee7',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: '1b7d223a-469e-483d-9412-23a21bd0d482',
        name: 'Wagyu Garlic Fried Rice'
      },
      {
        id: '3a11b801-3ea0-4c0d-881f-dd71e2c8c276',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: 'd8fbf2f3-31c8-4fae-8adb-922e33e90718',
        name: 'Pickles'
      },
      {
        id: '686e4916-1df5-4b06-b184-48413101a5d9',
        menuId: '23e6439a-1b05-4729-aa69-e6c8082f847d',
        itemId: 'df17d2c3-e978-4918-a1b1-59a273d311d2',
        name: 'Miso Soup'
      },
      {
        id: '28cb72d5-7cf8-4dcb-be5e-4348b27d849b',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '20e35c65-8678-47e8-afd6-2d36a171b316',
        name: 'Omakase Sushi',
        price: 268
      },
      {
        id: 'ce1d2e24-e830-4b8d-8843-8f17101da0f9',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'a3895648-1828-4b00-91b3-3f1a5e01c388',
        name: 'Omakase Sashimi',
        price: 308
      },
      {
        id: 'b8ce9a1e-5b8d-456d-a195-d80ac9827054',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'c627f5c5-1c89-472d-81c4-923ec6ae1877',
        name: 'Assorted Tempura',
        description: 'Tenshi No Ebi, Kisu, Eggplant, Pumpkin, Sweet Potato',
        price: 258
      },
      {
        id: 'c59c340e-59c7-4e39-9a56-469b96b88515',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '9f5c9084-c281-483b-827d-8ed6b4f87995',
        name: 'Assorted Vegetable Tempura',
        description: 'Asparagus, Maitake, Sweet Potato, Eggplant, Pumpkin',
        price: 158
      },
      {
        id: 'dacc32d5-dc89-482f-9a40-a1e22652d7a2',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '5396d8f8-195b-4cc5-9014-9c929b534357',
        name: 'Uni',
        description: '1 piece',
        price: 208
      },
      {
        id: '17fd2df8-4129-4620-9b80-19d5537d7a3a',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '142df1fe-0a53-40fa-be3a-578d1aaa6af1',
        name: 'Ikura',
        description: '1 piece',
        price: 118
      },
      {
        id: '9d15a915-da05-41b7-97a1-2e2811398da0',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '72699a0d-5f3a-4887-b67c-882e88b9ab51',
        name: 'O Toro',
        description: '1 piece',
        price: 268
      },
      {
        id: '31679568-fcca-4a5d-851a-4cf54d61fc80',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'a4e9bc72-088e-459b-99c6-2f39e92dc9d6',
        name: 'Chu Toro',
        description: '2 pieces',
        price: 198
      },
      {
        id: '4f95dd75-213e-4a4c-839e-b9a057796bbf',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '582461fb-2993-4fd7-b50a-f5d1d48ba7b0',
        name: 'Akami',
        description: '2 pieces',
        price: 118
      },
      {
        id: '84b1ccee-f820-4e99-a7f9-43573cd13987',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'a8e5898b-3db5-47bd-9e6d-4f84f25902e5',
        name: 'Botan Ebi',
        description: '2 pieces',
        price: 188
      },
      {
        id: 'ce725b99-aeb1-4d4c-a617-347b7c912b41',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '453cbc30-2526-46c7-b070-fa36b7fdc50a',
        name: 'Salmon',
        description: '2 pieces',
        price: 78
      },
      {
        id: '43a0696a-b0d2-4c8f-9ef1-1ec3114da0b4',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '71c4ddd9-7386-47ef-959a-6d5aea8c5436',
        name: 'Kampachi',
        description: '2 pieces',
        price: 78
      },
      {
        id: 'acf753dd-005f-4c72-b768-e6198f83e678',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '3bd68586-90cc-423d-9901-470a23618394',
        name: 'Hirame',
        description: '2 pieces',
        price: 88
      },
      {
        id: '9bf1a783-afe4-422b-9063-27ac0f81b334',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '52cf837f-7c9f-4b71-ba53-b5957d764667',
        name: 'Amaebi',
        description: '3 pieces',
        price: 108
      },
      {
        id: '7c20092a-afee-4a87-b4c0-44add6ee3902',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '70cf1fdb-476f-4499-9100-8beb25220649',
        name: 'Uni',
        description: '10 grams',
        price: 208
      },
      {
        id: '610b6193-7b80-469f-84c6-22e0010af5c2',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '3ace0649-6035-4ccd-8072-871f9097b1c5',
        name: 'O Toro',
        description: '2 pieces',
        price: 358
      },
      {
        id: 'bb4cc81d-a21f-43d9-911f-021cf608f579',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '98de1fcc-2195-49c6-bd2f-bda8c25f2ebd',
        name: 'Chu Toro',
        description: '2 pieces',
        price: 178
      },
      {
        id: 'b9336248-30c8-4650-9756-2030c8076e00',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '9fa56868-d403-4636-b974-419bb1e21ea4',
        name: 'Akami',
        description: '2 pieces',
        price: 108
      },
      {
        id: '3552406a-9870-4083-bb47-4d31f0faffe3',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'a2eba57d-386c-4901-a479-951c693c57aa',
        name: 'Salmon',
        description: '2 pieces',
        price: 58
      },
      {
        id: '4c98f7e0-cbef-4505-a4ca-f3f3661fe1cc',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '4c569e6c-2908-41f6-8a7c-12152f2a934f',
        name: 'Kampachi',
        description: '2 pieces',
        price: 68
      },
      {
        id: 'd2a69b30-8563-4c06-86d4-107b21caccb8',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'cb3e40a9-729d-452a-92be-ffda070c58df',
        name: 'Hirame',
        description: '2 pieces',
        price: 78
      },
      {
        id: 'bc71bb96-847f-4aa5-b7c8-15f15f55f324',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '0aca68d0-3ddf-44e5-8548-fd20130db18c',
        name: 'Tenshi No Ebi',
        description: '1 piece',
        price: 108
      },
      {
        id: 'f4e62fd6-d8cf-469a-8abf-276fa5ac0afd',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '8082711e-da66-49f0-aac9-8aa0b6e846e4',
        name: 'Kisu',
        description: '1 piece',
        price: 98
      },
      {
        id: 'ceb61d92-c1e5-4eb3-90e7-7ce4363a74bb',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '71b3d698-1438-4cc7-8a1f-4c22982abb94',
        name: 'Maitake',
        description: '50 grams',
        price: 78
      },
      {
        id: 'ec0bb749-68f2-4179-8380-b34351ca01ce',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '8fe8e9de-c936-4933-b6a3-4368dc786e50',
        name: 'Asparagus',
        description: '1 piece',
        price: 68
      },
      {
        id: 'b22f8442-bbaf-4367-8dc5-eb5546b6ed4d',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '8cbefded-dcbe-4320-9ce3-3c509c42c940',
        name: 'Sweet Potato',
        description: '2 pieces',
        price: 68
      },
      {
        id: '90120691-0952-4074-ab38-b4875a5f4117',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '702216eb-a728-44e6-862c-82405d429e3d',
        name: 'Kappa Maki',
        price: 108
      },
      {
        id: '4c67d48a-6efa-45cc-85bb-e51edd5590d9',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'fbf3912b-ff05-4691-883e-38ac581916dd',
        name: 'Kampyou Maki',
        price: 108
      },
      {
        id: 'ab8dff89-ad27-49c1-adb0-97cfd1ff39b3',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '7c077079-9937-430e-88b5-6fc7b90946c6',
        name: 'Negitoro Maki',
        price: 188
      },
      {
        id: '37b69e1b-5aa1-4697-b5f2-05545de63614',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'ddb50aa9-325f-4f36-9d0e-9bd297642677',
        name: 'Foie Gras',
        price: 178
      },
      {
        id: '862e9586-8ae2-45d3-b517-6a5d804e856a',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '91f51ad2-71e2-494d-aeba-3a15f2a7e842',
        name: 'US Prime Sirloin',
        description: '200 grams',
        price: 388
      },
      {
        id: '6ccd0456-bdd8-4cad-8176-3665b901d8ef',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '8d69572b-e31d-4b65-ba05-3bc7b3e7411e',
        name: 'US Prime Tenderloin',
        description: '180 grams',
        price: 388
      },
      {
        id: '4ec8dfb5-c7db-4ffe-97c5-8b241941d2fb',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '8ffc248b-ce22-4fd3-be5b-259a47db47f1',
        name: 'Australian Wagyu Ribeye',
        description: '150 grams',
        price: 458
      },
      {
        id: '96b4418f-e014-474f-9fb1-ebd113cf4db1',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '0fd6d048-c969-4e93-9826-9b4cf20e3367',
        name: 'A5 Kagoshima Wagyu Sirloin',
        description: '140 grams',
        price: 688
      },
      {
        id: '4cbb0274-6e8e-44f3-98b7-5a88541346dd',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'ad1aa36e-4f4c-4f55-a6cd-7d181e3164f1',
        name: 'A5 Kagoshima Wagyu Tenderloin',
        description: '120 grams',
        price: 688
      },
      {
        id: '1c742c35-773f-4a03-88aa-9d2d459d4941',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '72af8f2c-86f5-44d7-91bd-cc3672b04e6e',
        name: 'Hokkaido Scallop',
        price: 128
      },
      {
        id: 'de7e5ab5-e8c4-4382-b871-f34cdc0311c9',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '9050348e-13bd-46bc-8221-0c2404c96ebb',
        name: 'Tiger Prawn',
        price: 138
      },
      {
        id: '45ef18ca-f236-4311-9020-969951357045',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'd8d1cdfd-3572-4159-93d4-addc6ff1d12c',
        name: 'Amadai',
        price: 158
      },
      {
        id: 'd6caf06a-be80-4d84-b755-3aa948bb01f6',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '98e91b7c-8716-4cd1-9357-3c16e93dfddd',
        name: 'King Salmon',
        price: 178
      },
      {
        id: '97a5e269-6fd7-4b59-aeb3-fcb518422dfe',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '64130dac-6922-4f58-9188-55f6912b018f',
        name: 'Spiny Lobster',
        price: 688
      },
      {
        id: '9006ff98-ea26-4436-b3ab-699eb2be4188',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'c284b34d-c01e-4041-a82f-5ead332e299b',
        name: 'Edamame',
        price: 38
      },
      {
        id: 'e9443242-3ef6-4eea-a60f-3ea4debffce3',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'cb7ca159-c2ad-4628-b9c5-1ea3f6fb7060',
        name: 'Sweet Potato',
        price: 48
      },
      {
        id: '382716c9-4f4d-41c0-bcc9-4fd58edd2e40',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: '9a588659-4465-4cab-bfc6-b7b3a8ca208e',
        name: 'Pumpkin',
        price: 48
      },
      {
        id: 'd59660d8-b034-4cc4-8bc0-b0e6ed4c6603',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'e70ab813-ac07-404e-bbd9-eb70ad644088',
        name: 'Spinach',
        price: 88
      },
      {
        id: '05680816-8934-4d8d-bb07-1a2c71da24f6',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'eb096692-d69f-4805-ac4d-8f5e62520243',
        name: 'Asparagus',
        price: 108
      },
      {
        id: '835a79fe-b4cf-444d-9bc7-fd90bba43e0e',
        menuId: 'fa04f604-1b22-4656-88eb-16ea24bacc9d',
        itemId: 'b0e27eb3-b9b9-4d89-b42f-646d6943030e',
        name: 'Maitake',
        price: 108
      },
      {
        id: '97b9c618-420c-4677-b3c4-4b6a15e79cf1',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: 'a0aad60d-a174-406c-811e-6ecd7933b06d',
        name: 'TARAMASALATA'
      },
      {
        id: 'fff307b9-a957-49d2-bb8b-27fb6c5aacaa',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '9590d45d-73b8-4ad9-8dfd-8c0e7f170f62',
        name: 'TZATZIKI THEODOROS'
      },
      {
        id: 'ffd8036c-98c0-4241-a933-c0e9d7920546',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '32d68eb4-3490-4e11-9e5c-a9308ba44079',
        name: 'MARINATED OLIVES'
      },
      {
        id: 'd6e0d463-6f8f-40e7-8592-229d2644ea86',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: 'd0981624-5658-4503-9d2b-b39403268639',
        name: 'HUMMUS'
      },
      {
        id: '00a9c41c-af55-4792-9d2f-690c543d0fde',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '18515dae-09ab-431b-a7ed-c92b92c2f824',
        name: 'ROASTED EGGPLANT'
      },
      {
        id: '7fc35445-defc-4f3c-937d-d98516cd0355',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: 'd2d922b3-7bb4-4308-a039-57119bf8273f',
        name: 'VEGETABLE CRUDITÉS'
      },
      {
        id: '85e36fd3-1257-443e-86f1-9b436dbdc06a',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: 'fb53bf49-7327-4599-a5d5-c256f6c19b52',
        name: 'WARM PITA'
      },
      {
        id: '9a54d5e8-e1dd-4d27-8b89-3d589cb4c3b7',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '0289e7a1-0556-4b13-9b8b-cb8f057aaa7b',
        name: 'FAVA MASH'
      },
      {
        id: '9e732e34-bf7b-4470-8c67-a98e229f9b7e',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '6a25fd16-46df-44e7-b82e-ba6f7d60191c',
        name: 'AVGOLEMONO TIS GIAGIAS'
      },
      {
        id: '75acee60-a5ae-43cd-a573-6314fbe01d30',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: 'ba8a5ec7-bb0d-4f62-9df5-c2d08f3dc18a',
        name: 'SAGANAKI PLATANOS'
      },
      {
        id: '3ecdfb29-7e90-40bd-a579-09a830c2d189',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '6b08668e-553e-4927-8359-88da03ee0eaa',
        name: 'SPANAKOPITA'
      },
      {
        id: '1ee57a1f-7857-418e-b85b-7158846072ad',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '422d7a54-7609-4642-b65f-bddbccbe2241',
        name: 'PORK SOUVLAKI'
      },
      {
        id: '26628a60-db8f-46a0-ad89-54a34a177403',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '91162354-e05d-4d78-9a88-36ca6935f5f7',
        name: 'CHICKEN SOUVLAKI'
      },
      {
        id: '21fce716-57cd-4740-8ed0-7cfe66c679ba',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '55891417-a49c-4361-9cbb-36e6bcc53188',
        name: 'LOUKANIKO'
      },
      {
        id: '5432a649-a822-4650-9270-9e07c1b6c80d',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: 'e163245d-6482-4dd9-96d7-2108b3918db4',
        name: 'STEKI TOU ILIA LAMB CHOPS BY THE KILO'
      },
      {
        id: 'a124e68d-a32a-4fd9-bf6f-219a9a0af236',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '19e5d215-d499-4e46-9feb-0ef06ca85fcd',
        name: 'GREEK VILLAGE'
      },
      {
        id: '11d57957-4e87-4408-af07-ea3dd39345b7',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '65c23ef9-2f83-4b47-8ba2-031d444c9800',
        name: 'CYPRIOT'
      },
      {
        id: '94164298-1a61-44fd-982b-67985a2aeeaf',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '6939c3da-c9d6-4fef-8a19-da4776e2ad0c',
        name: 'MARINATED ANCHOVIES'
      },
      {
        id: 'fe5d8973-25b6-4bc6-8592-972ea2247a75',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '69410c2b-bd42-4c10-a3bc-3499ecc88b76',
        name: 'PRAWN SAGANAKI'
      },
      {
        id: 'cd453d92-ab17-43cd-86ea-e5aa7277f009',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '1e89bb93-8fd4-45bd-bfb1-f5f7cf8105f4',
        name: 'CALAMARAK'
      },
      {
        id: '7fa59ed0-fdd3-4a9f-9ddb-7839960c8528',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '9c0fda01-16cb-40ed-9363-235f91481f4e',
        name: 'HORTA'
      },
      {
        id: '543efd70-2d42-44f7-bbe9-d3c2b2977da2',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '11c7fb83-8a6f-4f1f-bf87-c0c44b32a530',
        name: 'PATATAKIA'
      },
      {
        id: '6cb95e0d-a748-41d7-b5fc-8057ef0e8850',
        menuId: '75192f42-b5ec-472d-bce8-406d1a088ead',
        itemId: '485c26e9-ab7b-46dc-a517-2402226aa284',
        name: 'LABNEH CHEESECAKE'
      },
      {
        id: '68bf5882-e9d2-4f61-8cce-e2ead6bf410a',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: 'ca981f0f-908a-44b7-adb9-d24cdd784a08',
        name: 'Lunch Feast',
        price: 178
      },
      {
        id: '629fdbe6-54db-426f-bef0-af565d70ff24',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: 'd3f1ecf7-4eca-4c71-895b-f9472033b1db',
        name: 'TZATZIKI THEODOROS'
      },
      {
        id: '12610a34-5588-43b9-9c54-449eebdeff4b',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: 'b4c2069e-2278-4f66-b8d5-156508a28ec1',
        name: 'HUMMUS'
      },
      {
        id: '12860f05-47e6-4542-91db-fd284fe31e64',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: '06f02f5f-00a9-4491-8a7b-879aa39d7dc0',
        name: 'ROASTED EGGPLANT',
        description: 'Served with crudites'
      },
      {
        id: '7268da0d-d3d9-45b5-87f2-354b204b6220',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: '65a30d7d-9ac4-49b8-bd42-3642d947393e',
        name: 'AVGOLEMONO TIS GIAGIAS'
      },
      {
        id: '11b84b84-fe5c-45e5-a668-0eaa76c23ffc',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: '5ae5dce3-0ea4-4aad-9ccb-2280b855040d',
        name: 'CYPRIOT SALAD'
      },
      {
        id: '8a4833e4-c536-41be-ad3c-77893c81c52b',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: 'bc8fd61d-7255-4781-bdb6-becd4aac30d7',
        name: 'CHICKEN SOUVLAKI',
        description: 'Served with warm pita'
      },
      {
        id: 'f62382f9-095b-4b55-b882-d38bb5f32e6d',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: '4f68a000-a338-441b-96e5-e8fc186e37cd',
        name: 'PORK SOUVLAKI',
        description: 'Served with warm pita'
      },
      {
        id: '491022eb-739e-4b76-9f0d-4559376bf06b',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: '5302dc6f-44c8-4573-aa63-a2ceec75012d',
        name: 'PATATAKIA',
        description: 'Served with warm pita',
        price: 58
      },
      {
        id: 'd6e9c7b1-55cb-4b14-af71-2ad613eddbef',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: 'a2619d2d-252d-440b-b591-d81b5fefd54b',
        name: 'LABNEH CHEESECAKE',
        price: 68
      },
      {
        id: 'e1d7d572-6a85-4ac6-8703-319af65ace50',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: 'd11da2b1-134d-4bb7-b7f0-21f1342a26f4',
        name: 'GREEK CHAMOMILE ICED TEA',
        price: 58
      },
      {
        id: 'f896f76c-016d-4b38-afec-6ad0fceaabe4',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: '3d610abc-ef2f-4fca-b24d-84fe68ac3f32',
        name: 'FRESH-PRESSED WATERMELON JUICE',
        price: 58
      },
      {
        id: '3e8ef4d1-49db-4f03-8dbb-74812c556062',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: '99481ab3-9a47-4129-b350-1f74c3db248e',
        name: '961 LAGER',
        price: 68
      },
      {
        id: 'cddbfed3-a06f-4f52-ac5b-9fc221af3d9c',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: '4893fae4-79d5-4010-88ad-fd33ba865712',
        name: 'OUZO PILAVAS',
        price: 68
      },
      {
        id: '9076a825-7d92-471a-808e-dcda3b2b2920',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: '14dab063-a227-41c8-9e90-6aa03bba668a',
        name: 'KIR-YIANNI, PARANGA WHITE 2018',
        price: 68
      },
      {
        id: '99df16f5-c90a-406c-b306-2d048030aefa',
        menuId: 'cf5594a4-7258-47e3-9827-5ddf2be95e93',
        itemId: 'eac414f2-36e6-4249-b71d-ae189ab3e12c',
        name: 'KIR-YIANNI, PARANGA RED 2017',
        price: 68
      },
      {
        id: '74fa5ed7-3739-4556-ad4a-dc10b0a8e573',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '19405fa5-a547-4df9-9f9c-ec532b7aed58',
        name: 'cashew and peanut fry',
        price: 48
      },
      {
        id: 'dacce1c3-f5ab-45f8-b5b3-e3ddfa36f2bd',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'e536f226-7e52-4b1d-ace4-f3fc8deff536',
        name: 'chilli potato fry',
        price: 48
      },
      {
        id: '62d012cd-e1b5-4e35-b13b-ed885d1bed20',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'bdb7ada5-eaf3-41a4-9f13-fce527a254bd',
        name: 'devilled shrimps',
        price: 128
      },
      {
        id: '880311a3-6abf-414e-bff3-e5b87d1369f1',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '0675bb54-8b26-4ad5-8958-9d4f0c3b1fb8',
        name: 'idli, sambhar, podi, coconut chutney',
        price: 58
      },
      {
        id: '2d083c8f-3fb2-4b2b-a066-8d6ac78a8592',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'f49f1217-b32b-452a-9ab6-8dcca3640288',
        name: 'bone marrow varuval, pol roti',
        price: 158
      },
      {
        id: '7b564638-ab79-4e30-91e0-33e10eb44437',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'b176dd86-9ebc-4e78-975e-b8c591b57c0d',
        name: 'fish cutlets with hot sauce',
        price: 68
      },
      {
        id: 'dd318c4f-7bd0-4626-bb0e-6b12d8dea36b',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '76d8fdce-2c10-4fe5-b614-07b35d317daf',
        name: 'beef chilli fry',
        price: 108
      },
      {
        id: 'e80aa033-9ffa-4fda-90d6-d3828e11b7f7',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '43f35d51-e44e-4925-9615-4acd5f6c6129',
        name: 'chicken kari',
        price: 138
      },
      {
        id: '8471de27-cb44-4c14-b0df-9198ae9f68ef',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '6674fb73-6429-496a-aaa6-5c6cbfe368a6',
        name: 'fish kari',
        price: 138
      },
      {
        id: '64f46c86-d240-4dfe-a341-19fa09891466',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'caf8dfd9-f1f3-4bc4-b75e-c17df2e0a4ef',
        name: 'prawn kari',
        price: 158
      },
      {
        id: '0d63edec-627d-4b61-bdcb-64a5eaa190d6',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'c217791b-ac86-433c-bd59-8cd84b5b96f1',
        name: 'seasonal vegetable kari',
        price: 108
      },
      {
        id: '809d8742-d235-4246-90c6-c6eff9e592b6',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'ff728f93-c154-470a-9905-42d3bae37515',
        name: 'pol sambol',
        price: 28
      },
      {
        id: '7ef98c34-5b1d-4085-8d52-efcdbdd106af',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '66b32dd7-f3eb-4255-a86b-732dda7db6ef',
        name: 'seeni sambol',
        price: 28
      },
      {
        id: 'b44f8e4a-d2db-4bac-8808-8c8b249e2ff2',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '40c7a8ab-0f06-4a2d-ba0d-3684578ef291',
        name: 'coconut curry leaf chutney',
        price: 28
      },
      {
        id: '6c89293d-8a79-4b01-ad3b-0687b7644caa',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'e14f9de2-76cb-4958-9898-1561da6a3d92',
        name: 'lunu miris',
        price: 28
      },
      {
        id: '90a63d4a-f0f9-440e-85cd-e04170a05ad5',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'c0035c12-e5e0-4e9d-83be-70900d05388d',
        name: 'pineapple and mango pickle',
        price: 28
      },
      {
        id: '91e8bce3-8dbe-4197-ab9b-df8670379108',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '2299e358-102c-4a6c-bb03-99ae01c8350e',
        name: 'chicken kothu',
        price: 138
      },
      {
        id: '392b9e70-823b-45c4-ada0-6d57a8fad50a',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'e6fe2505-6268-407f-91a6-90a37e39ddbe',
        name: 'vegetable kothu',
        price: 118
      },
      {
        id: '2a943491-b3ab-4bda-949c-8d097a53dac2',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '46b365a1-6c2c-4b9f-8f53-0509e8d8b4b8',
        name: 'shrimp kothu',
        price: 158
      },
      {
        id: '58e15c9f-ce8d-479a-8627-5b81905dc812',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '735e9f62-e240-4581-8c7f-8f9a83bef04a',
        name: 'hopper with kiri hodi, pol sambol and lunu miris',
        price: 88
      },
      {
        id: 'ac7b50b0-1395-4b2f-9d66-cdb105daa324',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'a8f4c593-0ff6-431f-8945-a9451b6965a5',
        name: 'hopper with kiri hodi, pol sambol and lunu miris  with egg',
        price: 108
      },
      {
        id: 'be4ec890-b0bd-4f24-b787-35376caf4026',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '1a281073-9847-48d0-ac06-23c4b824fd1c',
        name: 'plain dosa with sambhar and coconut chutney 88',
        description: 'with egg or masala'
      },
      {
        id: 'd97b29d7-444e-4b9b-9d3c-1651c2c6d05b',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '279edca7-6560-429a-85de-9127cb10a6d2',
        name: 'stuffed cheese parathas with gravy',
        price: 108
      },
      {
        id: '8e4038ef-ca36-4056-9d12-27eaa01927fc',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '34bb7227-ae73-4b65-b015-db749d4b8d42',
        name: 'mud crab with choice of sauce',
        price: 688
      },
      {
        id: '9a5c6dab-87f5-4837-9be0-e4457ca30fbf',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'af065a7a-6ec4-4457-beb4-f72db85eafc6',
        name: 'sambhar',
        price: 38
      },
      {
        id: '5ad26e45-0cfb-47fd-b073-a4da1472f932',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'edebaa5b-0c35-4807-99a2-9b1349c23d62',
        name: 'kiri hodi',
        price: 38
      },
      {
        id: 'f9d380b2-513f-498e-897a-239fe6fcab6e',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '6e4f093f-c40b-48f7-98d1-17b714fe5c1b',
        name: 'idli',
        price: 38
      },
      {
        id: 'a94986c8-f8df-4ff0-809c-51601d8b2630',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '1e1a346a-7e0f-41ee-9675-381cc89f0642',
        name: 'brinjal moju',
        price: 58
      },
      {
        id: '7356483e-6d7f-4675-af0a-8ddf93aee0b5',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'a0a92f50-643e-4afd-9b1e-52f5a9ad2a03',
        name: 'spinach mallum',
        price: 58
      },
      {
        id: '7bfcd8b9-e7ba-4c54-8515-73268c5025f3',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'd5c3186b-663b-4ada-9d9c-085e65920cfe',
        name: 'cabbage mallum',
        price: 58
      },
      {
        id: '06794a8a-4f26-45ba-9c2d-6c64313b4074',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '7e280169-a72f-4c6d-8ffb-90d8a6470986',
        name: 'pol roti',
        price: 48
      },
      {
        id: '99078cb3-b981-425a-955f-0a545f87c968',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '156af98f-fb3a-4dff-aa12-627c844d4d46',
        name: 'paratha',
        price: 48
      },
      {
        id: 'd6382085-c9de-4b1b-984c-b7d2fd5e9947',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'e33af736-f1a4-4627-891b-c58e49dd5967',
        name: 'plain hopper',
        price: 58
      },
      {
        id: '6eedaeeb-a34a-4c1e-8fdb-198e5b613a82',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: '120f03ae-c74b-4bf5-98f8-198cb2d14530',
        name: 'egg hopper',
        price: 78
      },
      {
        id: '896847bb-9b7f-4cdf-8956-f08d4d2581a0',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'b60cc6a4-db12-47f7-b6af-4cb02980907d',
        name: 'steamed rice',
        price: 48
      },
      {
        id: 'fe81a5ec-a403-4a3b-b956-3dd911448a2a',
        menuId: 'd43ca4ce-16b5-47c8-a5fd-53870c7eb0cf',
        itemId: 'bf59d5cb-0a90-4006-8bee-3d96d8de2404',
        name: 'yoghurt',
        price: 38
      },
      {
        id: '2a632cd9-be19-4f61-878a-220faad2a43d',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: 'ba8b3561-aaba-4d81-b8bc-eccc2b9bfecf',
        name: 'CRISPY FISH',
        description: 'cabbage slaw',
        price: 32
      },
      {
        id: '8505f036-148f-413e-95c3-094b0d2c3328',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: 'c62ec5c7-68a3-4237-a3e7-c731ccc5eb4c',
        name: 'SHRIMP A LA PLANCHA',
        description: 'chipotle chili, achiote, salsa roja',
        price: 38
      },
      {
        id: 'a79700d1-ebb4-4258-a53b-5ae0ed9989d6',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: '1e29c551-698d-4b51-b214-f0d06e03b691',
        name: 'HABANERO-MARINATED STEAK',
        description: 'chili & roasted garlic-spiced beef, salsa Verde',
        price: 38
      },
      {
        id: 'b30e624b-aebf-40ae-b010-f9b17becea4f',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: '9cdb1e59-b293-4b06-8613-d38c84d52459',
        name: 'AL PASTOR',
        description: 'chipotle-marinated pork, pineapple, bacon',
        price: 42
      },
      {
        id: '6895afa5-7e76-4982-802b-34d0c089c263',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: 'c5def5e3-be7b-469b-a4f9-f6a76f9ef0de',
        name: 'ROAST CHICKEN',
        description: 'salsa verde, vegetables en escabeche, queso fresco',
        price: 42
      },
      {
        id: 'e9e7a538-1176-4923-95a3-46c7c3c1d885',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: 'acb9b4f2-eb08-4d9f-9d2f-5d41f7f52fd8',
        name: 'GRILLED AVOCADO',
        description: 'cabbage slaw, salsa verde, queso fresco',
        price: 38
      },
      {
        id: '588eb4dd-dcab-4e80-93db-3a4d1882f959',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: 'c2cf9ae4-2bd6-4dde-8b70-358b723346dc',
        name: 'WARM TORTILLA CHIPS SERVED WITH',
        description: 'SALSAS',
        price: 72
      },
      {
        id: 'd41bb19a-1741-45f7-a9b6-51f9cbd3e15e',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: '9e05fe81-3731-43e2-bd84-e7680df5780e',
        name: 'WARM TORTILLA CHIPS SERVED WITH',
        description: 'BLACK BEANS',
        price: 82
      },
      {
        id: 'aa11d30c-b007-4400-9c7c-8e2590e8ed20',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: '54912817-0ad4-470e-8b1f-9a87e9a7bff9',
        name: 'WARM TORTILLA CHIPS SERVED WITH',
        description: 'GUACAMOLE',
        price: 88
      },
      {
        id: 'e0d958f6-71a3-460a-8617-9cd3acac3d12',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: 'aa851bdd-94f2-42e1-adbb-3897134fb72b',
        name: 'CHARCOAL-GRILLED MEXICAN STREET CORN ',
        description: 'queso añejo, guajillo chili, lime',
        price: 42
      },
      {
        id: '0f46b83b-eec0-4eb7-af91-e5f7ba4ed617',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: 'c3234913-4efa-4c8c-b6c8-7e2b6eba67fc',
        name: 'GRILLED CHICKEN TOSTADA ',
        description: 'guacamole, chiles toreados, sour cream',
        price: 72
      },
      {
        id: '6138867e-0a5f-478b-b430-1bbb024dcfad',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: '5bf1701b-a809-4cb8-a03c-c8ded5e53b2f',
        name: 'QUESO LOCO',
        description: 'grilled corn, oyster mushroom, pico de gallo',
        price: 98
      },
      {
        id: '31d82290-33d5-4a43-9202-462842527f75',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: '2bf9594c-6af9-49ff-a3dc-86e42a0f7978',
        name: 'THREE CHEESE QUESADILLA',
        description: 'queso oaxaca, tomato chipotle mayonnaise, cabbage slaw',
        price: 72
      },
      {
        id: '2f960594-bd33-4766-aacf-3191a6b9087c',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: '00122b70-61e1-4d43-9eb8-0622475028f5',
        name: 'MARKET CEVICHE',
        description: 'ask about our daily baja-inspired selections',
        price: 'MP'
      },
      {
        id: '3ae1cad2-d480-40c3-a510-43724f80fbee',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: 'f1df35c1-39df-4224-9393-c1b57a009a90',
        name: 'WARM TORTILLA CHIPS ',
        price: 42
      },
      {
        id: '92f55592-60f0-4600-aa27-6627bdcb98e7',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: 'b59d89f0-3e38-4a4b-921c-afd7612142f8',
        name: 'TORTILLA BASKET ',
        price: 22
      },
      {
        id: 'db77a518-707e-4e45-aaa6-0f18c2d269df',
        menuId: '3a388bc6-f81a-4ba6-8f3c-866e667e4d8e',
        itemId: 'c296e3ea-e3c3-48ba-b1c0-58809a319db5',
        name: 'GUACAMOLE',
        price: 58
      },
      {
        id: '4308f761-094a-4434-9d1f-0ff930118b4c',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: '2281add8-8ea2-4f97-9aac-44d73a1ee59a',
        name: 'Peanut Butter Pie',
        description: '9’’Chocolate Cookie Crumb, Dark Chocolate Ganache, Peanut Butter Mousse, Caramel Whipped Cream, Caramel Pearls, Chocolate Sauce',
        price: 308
      },
      {
        id: '94ef22a1-ea89-45db-91b9-428180685557',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: 'f10feda5-4164-4e07-80ba-39bf96922769',
        name: 'New York Cheesecake',
        description: 'New York-style Cheesecake, Vanilla Cookie Crumb Base',
        price: 650
      },
      {
        id: '96c04370-8118-4dcf-8a38-9e24aa1b618f',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: 'b71426b6-9f01-4a1c-81e4-c37e1a5f5e23',
        name: 'Triple Chocolate Cake',
        description: 'Rich Devilsfood Chocolate Cake, Chocolate Butter Cream, Cocoa Nibs',
        price: 650
      },
      {
        id: 'fe92b67e-8135-4047-9bfc-21c9e4f8e562',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: '99998b1a-d4cf-4bf0-a633-e15b58143844',
        name: 'Pineapple Upside-down Cake',
        description: 'Vanilla Sponge Cake, Fresh Pineapple, Cherries',
        price: 550
      },
      {
        id: '7591f561-5a87-46f9-81bd-3f749c5c6f54',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: '950656d1-c5fa-4077-8a46-27e91d58b59a',
        name: 'Carrot Cake',
        description: 'Carrot Cake, Classic Cream Cheese Frosting, Pineapple Bits, Coconut Sprinkles',
        price: 450
      },
      {
        id: '789e5a4c-f7db-464a-9875-1c40893f24e7',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: 'f9878f58-b7e4-4633-b89f-90ceb5b03af3',
        name: 'Strawberry Shortcake',
        description: 'Vanilla Sponge Cake, Vanilla & Buttermilk Whipped Cream, Fresh Strawberries, Strawberry Purée, Lemon Zest.',
        price: 550
      },
      {
        id: '9418a652-4223-4699-b693-f35443476afe',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: '9bdb406a-b8d8-4fb2-8b57-39c27da1bdea',
        name: 'Coconut Chiffon Cake',
        description: 'Coconut Chiffon Cake, Coconut Whipped Cream Frosting, Desiccated Coconut',
        price: 450
      },
      {
        id: '7deef02d-c19b-45d5-a2b2-423d5b16aa40',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: 'be299032-53c3-4827-bfa4-ff44efaabfc3',
        name: 'Olive Oil Lemon Cake',
        description: 'Olive Oil & Citrus Cake, Rose Icing, Dried Rose Petals',
        price: 500
      },
      {
        id: '48452d4f-2305-4fe5-8e0b-7943f6762b3f',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: '77a53e6d-51c5-464d-8905-1a1bbe542906',
        name: 'Red Velvet Cake',
        description: 'Red Velvet Cake, Cream Cheese Frosting',
        price: 650
      },
      {
        id: 'b9381ea0-237d-4812-bb82-5adbc9e3657d',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: '4ef948ff-059a-4447-ab65-0b0ff9c2d785',
        name: 'Banana Cream Pie',
        description: 'Graham Cracker Crust, Sliced Banana, Pastry Cream, Caramel, Whipped Cream.',
        price: 400
      },
      {
        id: '02097b19-da5e-464d-af31-082d4d6709f0',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: 'c8de36ca-6451-406b-8c01-ee03abd15f0f',
        name: 'Blueberry Crumble Pie',
        description: 'Flaky Pastry, Blueberry, Vanilla, Lemon Zest, Oats, Almonds, Brown Sugar',
        price: 400
      },
      {
        id: 'b81c6b1f-46df-422f-b4e2-1e37071b943a',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: 'd2b4b105-6e0d-4db9-8b2f-45d6125ef3b1',
        name: 'Applie Pie',
        description: 'Buttery Biscuit Base, Sliced Apples, Cinnamon, Caramel, Flaky Lattice Top',
        price: 400
      },
      {
        id: '0315e223-5f4f-443d-a7bd-3ba35f2055a0',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: '858e4876-f7d3-4d29-a950-df74333d453c',
        name: 'Key Lime Pie',
        description: 'Coconut cookie crust, velvety lime curd center packed full of citrusy flavors topped with toasted meringue',
        price: 400
      },
      {
        id: '4b03c776-e9ed-4f1a-867e-377b3e12ba20',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: '1259a051-f6da-44ec-b0d8-6200046c8413',
        name: 'Honey Butter Chess Pie',
        description: 'Buttery Pie Crust, Honey & Cornmeal Custard Filling, Icing Sugar',
        price: 400
      },
      {
        id: '55391e92-2812-4899-b548-c0366c5bd0b9',
        menuId: '7f44a982-9173-43b3-8fd4-e37c16a4bbaa',
        itemId: '9cddfdd1-73dc-430d-8bb0-b32439e335e2',
        name: 'Doggie Cake',
        description: 'Carrot Cake, Oats, Whole Wheat Doggy Biscuits (Sugar-Free)',
        price: 300
      },
      {
        id: '16c4b491-c43f-4083-813e-830a9ce73ee9',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: 'f3243a44-8647-407d-901e-98ca9036800c',
        name: 'OYSTER',
        description: 'YUZU PONZU'
      },
      {
        id: '26836fe2-aaec-4f68-a7cd-32e7996cdf4f',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: 'ec20170b-2d5f-4f69-85be-4b2b36e23000',
        name: 'SWEET SHRIMP',
        description: 'FIVE SPICE SOY'
      },
      {
        id: '6462260a-fec1-429d-be0a-cc8c9b7c781e',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '5f285b0f-b2c2-4e4d-8e60-584032b1dbbf',
        name: 'MARKET FISH TARTARE',
        description: 'YUZU KOSHO'
      },
      {
        id: 'f35838f7-0560-4bbf-87a1-4aba11ca66ef',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '286c9627-a014-429d-b0aa-5cc743421a39',
        name: 'PLATTER OF MARKET FISH',
        description: 'SEAWEED SOY'
      },
      {
        id: 'bee28a66-2050-4ff0-be0a-092dd763a3e2',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '4c129190-b1ac-42cb-a765-c9ffdfaf571e',
        name: 'KINGFISH',
        description: 'TOMATO JALAPEÑO PONZU SAUCE'
      },
      {
        id: '3f5bcdb2-0b45-4500-b930-04abc2cd8ea0',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: 'dcb6c199-6b8d-4d4a-855d-da32f32c04b8',
        name: 'BEEF TARTARE',
        description: 'CHILLI, SESAME DRESSING'
      },
      {
        id: 'd3eeb202-1d27-414b-a477-0c86b885f153',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '7aab4a76-93a0-49b2-acf2-128aa17dd745',
        name: 'PICKLED MACKEREL',
        description: 'YAMAGATA DASHI'
      },
      {
        id: '9e8b61a6-7c14-4905-9796-303255fb72aa',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: 'f0bff9be-6e64-4c66-a59d-c85f969e68e7',
        name: 'SALMON',
        description: 'NANBAN SAUCE, CHILLI OIL, PINE NUTS'
      },
      {
        id: '8cd409e1-2ef2-48a8-9e0f-259d21cd9cf8',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '2b189293-c4bf-43e1-9fba-05b095b777e8',
        name: 'SNAPPER',
        description: 'SHISO DRESSING, DAIKON'
      },
      {
        id: '6fbddc8d-5d28-46ef-87fa-78461c500a06',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '2aaa40bc-f308-43bd-ba88-d27bd7fdb5a1',
        name: 'EDAMAME'
      },
      {
        id: 'd4b54f45-4029-4b75-8953-68e19e795de9',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '92c1df8a-bdfc-465e-b5ce-3befba8054ca',
        name: 'DRUNKEN CLAMS',
        description: 'PLUM, SAKE, SHICHIMI'
      },
      {
        id: '84957f9e-eed5-47bd-87be-d134c4006fb5',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '36689690-8fb7-42f1-b23a-a61e0d866ad7',
        name: 'CRISPY CARAMEL BUTTER CORN'
      },
      {
        id: '6ac9b499-ed39-4f31-be0c-04ae815cb06d',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '3e6070e9-8b72-4283-8f2e-afa708b953a1',
        name: 'SEASONAL VEGETABLES',
        description: 'PICKLED IN YUZU'
      },
      {
        id: '286f2b42-9a67-4504-905a-975b00a5380f',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '3a013df0-4c9a-4382-99b2-4ebab5731722',
        name: 'ONSEN TAMAGO',
        description: 'BACON, POTATO SALAD, CRISPY POTATOES'
      },
      {
        id: 'cd84baf9-546e-4131-a454-bfaabbdfa8cd',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: 'b7028fb8-9bd8-44ed-8931-cfc6a6883fa1',
        name: 'PRAWN GYOZA',
        description: 'MISO BISQUE'
      },
      {
        id: '85977a5e-725d-4dd7-90f4-7d411a5c39f4',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '61393f54-5df3-4e40-bfc1-17007050c70a',
        name: 'WAKAME SALAD',
        description: 'GINGER SOY DRESSING'
      },
      {
        id: '620446b2-419d-4914-8d39-781dec4e0ab1',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '8553a4b0-7ad4-44a6-a1bc-d717311454e5',
        name: 'JAPANESE OMELETTE',
        description: 'GRATED DAIKON, SALMON ROE'
      },
      {
        id: '74d9d2af-99c2-496a-9f77-55237d519c71',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '844ae218-2954-4051-b062-d55f331ccdcd',
        name: 'CRAB CHAWANMUSHI',
        description: 'SNOW CRAB, CRAB DASHI'
      },
      {
        id: '5ebf6ab2-039f-4413-b272-b8fb978f19c3',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '3a233d2d-dba1-4a6c-9345-d52453b913d6',
        name: 'SPINACH OHITASHI',
        description: 'SWEET WHITE SESAME PURÉE'
      },
      {
        id: 'f8db1029-76df-4055-a3a8-be81f4c15850',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '0ddbdc8e-e465-4a2b-9f80-7d8f1253dd25',
        name: 'BEEF TATAKI',
        description: 'DAIKON VINAIGRETTE'
      },
      {
        id: '6c415367-e852-4541-9e7a-e926e05253cc',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '380fbc4d-7236-40de-be8b-7c2a84c517bd',
        name: 'SLOW-COOKED PORK BELLY',
        description: 'KARASHI, PICKLED CUCUMBER'
      },
      {
        id: '6d59ba2f-303e-4c05-a9d9-c297f53fc26b',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '32d32b01-ac85-4f90-bada-0dd2adefe473',
        name: 'AGEDASHI TOFU',
        description: 'JAPANESE MUSHROOM VINAIGRETTE, PRAWN OIL, BONITO'
      },
      {
        id: '988208bf-eca3-409f-b8ec-ea0963a43a4a',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '4cfadbda-20ac-477d-86a9-5a9e81a75e92',
        name: 'SPICY CHICKEN KARAAGE',
        description: 'MIZUNA, CURRY VINAIGRETTE'
      },
      {
        id: 'b4b7c24a-934e-41f7-a34f-22138baee824',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: 'e6cb1573-9960-4e73-adf9-01fd253d57fc',
        name: 'SCALLOP MOZZARELLA',
        description: 'SHIITAKE, PORT WINE'
      },
      {
        id: '8259feff-3db2-452f-9888-eb13863c0533',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: 'e9a277c8-df49-4153-a526-83c9b951b333',
        name: 'PRAWN TEMPURA',
        description: 'SPICY MAYONNAISE'
      },
      {
        id: 'a8f410d3-f2ed-4013-b8eb-d6a1dcebce77',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '77f88b3d-f52f-40e7-b045-671d3e9f99da',
        name: 'WHITING KATSU',
        description: 'SALMON ROE'
      },
      {
        id: 'a57e93f3-10f9-4167-b635-8de3fc240d1d',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '48f55b47-14f8-4f9a-af31-fed508492184',
        name: 'CHICKEN WING',
        description: 'WAGYU, PORK, MISO, DUKKAH'
      },
      {
        id: '5919bb43-cad5-47c7-b142-96a89c664bf4',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: 'f52befa9-78e4-470d-837b-675c3e13bb56',
        name: 'SOFT SHELL CRAB',
        description: 'MOZZARELLA, LEMON CHILLI DRESSING'
      },
      {
        id: '7ffa83f5-6f0a-4ec0-9a9b-7c2c0b07f6ad',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '9bc697bf-ccf3-422d-a9ad-02fb81f5da8f',
        name: 'GRILLED SCALLOP',
        description: 'OYSTER BUTTER'
      },
      {
        id: '1ea1b4f0-06a6-4bb4-9164-6833da370e48',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '90b9b021-7a29-4cc9-93af-22cbd5a19d2b',
        name: 'HAMACHI COLLAR',
        description: 'FUJI APPLE PONZU'
      },
      {
        id: '95eccaab-5aaf-468a-aca9-25da15aeaa9b',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: 'c58e1944-08b0-4263-91b0-f4c787018423',
        name: 'SAIKYO MISO BLACK COD'
      },
      {
        id: 'f9235812-5daa-43b0-ac76-5f7527f14a2e',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: 'be240cbb-59d4-460a-b592-9b6ac5d99e3d',
        name: 'A4 WAGYU SUKIYAKI',
        description: 'CURED EGG YOLK, SMOKED NEGI'
      },
      {
        id: '43b7aaa4-64d9-4f0d-ac2b-8ac353a6bb73',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '99342b30-05fa-445d-ade4-9005af8cbab7',
        name: 'WAGYU ZABUTON',
        description: 'SEAWEED SOY, SMOKED OIL, ENOKI'
      },
      {
        id: 'ee36759e-d67e-4964-ac9a-f3c226fa74dd',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '23efe49c-db15-43d3-93c4-fb694761ec1f',
        name: 'MISO EGGPLANT'
      },
      {
        id: 'cf550c36-d1c6-4c4e-855f-8fc9cbe44bf4',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '814ec234-55a9-416b-a443-38fc2e42d696',
        name: 'YAKI UDON',
        description: 'SNOW CRAB, CRAB MISO BUTTER'
      },
      {
        id: 'e31ebe2b-3472-46a1-9936-7ea08783854a',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: 'b918657a-1f33-4cf3-a450-a935956879c6',
        name: 'WAGYU KAMAMESHI HOT POT RICE',
        description: 'SANSHO MISO, CURED EGG YOLK'
      },
      {
        id: '2a92e47f-bf37-46d6-b22e-7c17a6b2aa8d',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: 'c2e3dfc0-a9a4-44c3-9a56-7c298447e862',
        name: 'SASHIMI DON',
        description: 'MARKET FISH, SALMON ROE'
      },
      {
        id: 'f54a49df-73b6-45cd-8b3c-0d08425f4f53',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '30937531-9748-4d32-b6df-ca73ea01bcb4',
        name: 'MONAKA ICE CREAM SANDWICH'
      },
      {
        id: '44bf595b-9b39-4efd-8df8-e49d19dac427',
        menuId: '48d61d8d-4dee-48ee-a484-3a63fa6895ed',
        itemId: '598e346d-ff85-4814-bc44-525a94dc7fec',
        name: 'EDO MISO POPSICLE',
        description: 'MOCHI, GREEN TEA CRUMBLE'
      },
      {
        id: '866d7692-2865-41be-a838-db91d68b2071',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '33d10df6-1098-446d-bff5-e495ce47b953',
        name: 'YOSHINOGAWA GENSEN KARAKUCHI (NIIGATA)',
        description: 'LIGHT, DRY & FOOD FRIENDLY - LEMON, MANDARIN & ROSE'
      },
      {
        id: '051a5b1f-baeb-48fd-bef0-eabdad9b0f77',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '64c50d5c-0bd0-475a-9a70-e169c194d640',
        name: 'KIKUSAKI TOKUSEN (NAGANO)',
        description: 'SOFT, MEDIUM DRY & MELLOW - PASSION FRUIT & MUSHROOM'
      },
      {
        id: '40d152f8-1900-44ec-97e9-9d90c26746a0',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '2e95dcde-9a9b-407a-ad89-f1fdd9c5250a',
        name: 'IBARAGI RAIRAKU KASEN (AKASHI)',
        description: 'MILD, MEDIUM DRY & BALANCED - COOKED FRUITS & FLOWERS'
      },
      {
        id: '8b231bdc-e6fe-4c23-b338-0dede9be7e11',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: 'dbde21b0-bdcf-49b4-b94d-cb976722f365',
        name: 'TSUBOSAKA HONJOZO KINTSUBO (HYOGO)',
        description: 'INTENSE & DRY - COCONUT & FRESH BANANA'
      },
      {
        id: '6251f0fe-d8cb-4c0b-93d9-d2c438144608',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '174c4965-8962-41a7-bc31-dcd4e6813e05',
        name: 'ENASAN JUNMAI (GIFU)',
        description: 'FRESH & MEDIUM SWEET - EXOTIC FRUITS & PINEAPPLE'
      },
      {
        id: 'beba6ce2-308a-4e37-840b-d689b807e298',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '28c7057b-360d-472f-8345-49413c980822',
        name: 'OKURAHONKE YAMAHAI TOKUBETSU JUNMAI BIZENASAHI MUROKA NAMA GENSHU (NARA)',
        description: 'FULL BODIED & MEDIUM DRY - NUTS, PASSION FRUIT & LYCHEE'
      },
      {
        id: '388f4a1f-b60e-44ac-b833-8ad6cfc491a0',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '4100ba64-6643-4a9a-a4c0-b1cea2e36aad',
        name: 'MATSUNOTSUKASA JUNMAI GINJO RAKU (SHIGA)',
        description: 'INTENSE & MEDIUM DRY - RIPE BANANA & APRICOT'
      },
      {
        id: '28fe3f09-ee2f-44c5-bdab-94db58adaa75',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '60a80df3-6f68-44a6-9ec7-5e300e933427',
        name: 'MANOTSURU JUNMAI GINJO (NIIGATA)',
        description: 'MELLOW & MEDIUM DRY - SWEET SPICES, CANDIED CITRUS & WHITE FLOWERS'
      },
      {
        id: 'bbf0e782-1a1a-4676-9949-7aff500c4b3f',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: 'c5ec2164-bf17-4a70-a2a2-bc84bb88b025',
        name: 'SENKIN JUNMAI DAIGINJO MUKU (TOCHIGI)',
        description: 'RICH & DRY - COOKED CITRUS, MARMALADE & COMPOTE'
      },
      {
        id: '06082644-fe01-4390-8421-6bda2491013f',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: 'b028cab3-070c-4fd3-9e77-32ab2e8bc3ab',
        name: 'DAISHINSHU TEIPPAI JUNMAI DAIGINJO (NAGANO)',
        description: 'FRESH & MEDIUM DRY - GRAPE, PEAR, TOASTY & LONG FINISH'
      },
      {
        id: '78a8eca7-0326-4d55-af58-693f1636850c',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: 'a905b059-be14-45cd-bf5b-4ee70acce650',
        name: 'MANOTSURU JUNMAI NIGORI (NIIGATA)',
        description: 'RICH & MELLOW - MILKY, COCONUT WATER, PEACH, UMAMI'
      },
      {
        id: 'e6fb39db-1093-4e00-ae96-c81b6e5001b8',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '9d3f8e63-26c8-4ce1-a788-50046e75b21e',
        name: 'FUKUJU AWASAKI SPARKLING (HYOGO)',
        description: 'FRESH & MEDIUM SWEET - MELON & ORANGE PEEL'
      },
      {
        id: 'aded7ff8-19d8-4c4c-8728-969f1dda65f2',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '703ee4af-376c-457b-af56-131a92b021d2',
        name: 'YOSHINOGAWA GENSEN UMESHU (NIIGATA)'
      },
      {
        id: '46e59c3f-7834-485a-b4e7-7b22692cfd89',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '98e9e6af-3549-45d4-bc7f-294fc74701c1',
        name: 'YOSHINOGAWA YUZUSHU (NIIGATA)'
      },
      {
        id: '0b8c112c-07b2-402e-84ca-53419046e268',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '7b1fed53-73ab-4f0a-b19b-b52eef7d5e45',
        name: 'KURAFUTO',
        description: 'MEZCAL, TEQUILA, GRAPEFRUIT TONIC'
      },
      {
        id: '382f3290-c5d3-45f4-813d-0cafd31cd56d',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '03658bfa-d2eb-4a24-a24f-d4e7c24ed72a',
        name: 'DONTO',
        description: 'JAPANESE WHISKY, GREEN APPLE, SODA'
      },
      {
        id: 'c80a0ac0-1f92-43da-916f-2eb11e203aa7',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: 'f56c9329-db51-4869-98a5-8f20ee4a0330',
        name: 'UME',
        description: 'GIN, PLUM LIQUEUR, SODA'
      },
      {
        id: '29b70c33-3ed5-48c3-9b68-faf4d9af7bcc',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '06d207c2-d605-4339-bb3a-8708138fadda',
        name: 'NATSUKASHII',
        description: 'CACHAÇA, CREME DE KYOHO, SODA'
      },
      {
        id: 'f9db9d9e-570d-4253-985f-817b0e047c7f',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '383efd1e-0905-4b19-a313-75314354c821',
        name: 'KEN',
        description: 'DARK RUM, UMESHU, GINGER ALE'
      },
      {
        id: '53dc4fbf-df08-499b-b773-3572e22f2fca',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: 'b1ea8c26-1c95-464a-8ecc-bf39342cf7d8',
        name: 'KANKITSURUI',
        description: 'GIN, YUZU, CAPI SODA'
      },
      {
        id: '56e63fc8-a65e-4e06-aa2e-67dccc32694f',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: 'f17fda7d-f042-4ff8-b8fe-8ba97001d5f8',
        name: 'SALARY MAN COMBO',
        description: 'LONDON DRY GIN, WHITE BITTER, YUZU-SHU'
      },
      {
        id: 'e496e0fc-1cf1-4f81-a560-b5d0f2e75aac',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '1ba8a5e2-c3d5-401b-bd3f-085463601dc9',
        name: 'COFFEE & YUZU CREAM',
        description: 'CITRUS VODKA, ESPRESSO COFFEE LIQUEUR, YUZU CREAM'
      },
      {
        id: 'e9ab8cf5-6d61-4f5d-af51-c4ab910f9b82',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: 'ba132bae-cdb0-443d-94be-3f1cbe258f12',
        name: 'DEN-EN SHUZOU KIN LABEL (KAGOSHIMA)'
      },
      {
        id: '7d0afffd-01a5-44d3-84f7-ae7292feb7d4',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '09459033-ba6b-43fa-8fa6-614f0aa44667',
        name: 'DEN-EN SHUZOU SHIMAZUHAM (KAGOSHIMA)'
      },
      {
        id: '415357ac-be3f-4a04-9e15-6a58dec7fc86',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: 'c9b2f9fa-96d5-41a1-bbd2-57af06decc0a',
        name: 'SUNTORY OLD'
      },
      {
        id: '83ff0408-8661-487f-b826-b2f701e9ecbc',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: 'f33af878-7839-4431-aa7f-f8953cedc31f',
        name: 'ICHIROS MALT MIZUNARA'
      },
      {
        id: '5abf5230-e7e9-478a-8961-3ee88d442087',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '76b3e72c-80c8-47a4-a0a3-9493f5c98eb6',
        name: 'AKASHI NAS'
      },
      {
        id: '15d49a2d-23a1-468d-b4d7-4ad1fe3f8671',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '1e7d6679-bbcb-4e1c-958a-ba546112d298',
        name: 'NIKKA FROM THE BARREL'
      },
      {
        id: 'a41d76c9-3afc-44a4-af62-b8e5aae53800',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '75e36ddc-10c2-420a-8580-0f0183247e62',
        name: 'MARS MALTAGE ‘COSMO’'
      },
      {
        id: '9d980a57-8f33-429a-b12d-2c5e7d7b0e2e',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '6003da40-2c8d-4ac5-9ffc-369d6d0ed902',
        name: 'YOICHI NAS'
      },
      {
        id: '78687fe7-5793-477b-97b1-277f59734c7c',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: 'e591957d-5a65-47c1-bd10-055cd521f06c',
        name: 'KAVALAN PORT CASK'
      },
      {
        id: '45d12a7e-9933-473c-a206-e52ac8669d82',
        menuId: '5b845b14-8332-464b-841a-512bbdc9462a',
        itemId: '306a324f-3f22-48a0-a36a-aef9f94ca8b4',
        name: 'KAVALAN SINGLE MALT'
      },
      {
        id: 'e37f1d0c-7a38-4f94-8fcb-8ea64c9cf5a4',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: 'ab55a12c-eaa3-4ff3-b57a-e812d816c9d1',
        name: '1. MASALA OMELETTE',
        description: 'A three-egg omelette with chopped onions, tomatoes, green chillies and coriander, served with masala-roasted tomato.'
      },
      {
        id: '28cf6eb3-75d8-45cf-9060-13719c3ff635',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: 'de1071cb-1173-4406-ace0-bf2e4f4f1e8b',
        name: '2. BHURJEE',
        description: 'A three-egg masala scramble, piled up on milk buns and served with masala-roasted tomato.'
      },
      {
        id: '801f1a9a-b41b-45b4-88d1-c6b75a359f5b',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: '8d5be00d-90b8-488b-8224-e94cf2c2ca3d',
        name: '3. KEEMA ANDA PAU',
        description: 'Spicy mutton and eggs scrambled together to be scooped up with buttery milk buns.'
      },
      {
        id: '10202191-e83b-4bf2-95a5-5212517fa79d',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: 'affe7326-f673-470d-a49f-9545bc08fd7d',
        name: '4. KULCHA ANDA FRY',
        description: 'Naan filled with spiced potato served with two fried eggs, ketchup and coriander chutney.'
      },
      {
        id: '2e10df10-2b9b-4cb6-ad76-174f00a97aaf',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: 'b5edf25a-4695-4df5-90c8-18b28880eef1',
        name: '5. DALHOUSIE PORRIDGE',
        description: 'Organic porridge oats cooked with milk, banana and sweet dates.'
      },
      {
        id: '450163cf-fa14-49f7-b9e8-d6dae97ba906',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: '8047dc49-ad7f-40f2-b4b4-69197ca8a37e',
        name: '6. FRUIT & YOGHURT',
        description: 'Fresh grapes and melon, topped with natural yoghurt and manuka honey.'
      },
      {
        id: 'a0f99af4-11ee-40d3-bfa9-9e5f56fb954f',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: '56244b31-ab7b-43c4-8968-2566f21ab705',
        name: '7. COMPANY GRADE A GRANOLA',
        description: 'Paleo muesli with a honey-baked blend of nuts, seeds and coconut. Served with fresh fruit, natural yoghurt and manuka honey.'
      },
      {
        id: '4b24d4e0-b15e-4c13-90fe-382a6b802a27',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: 'd506ae5b-57e6-4290-8a60-67d23e28e805',
        name: '8. RIFLES ROLLS',
        description: 'Tandoor-baked naan with spicy cheese and tomato chutney, filled with your choice of: a. Bacon and honey, b. Sausage and crushed peppercorns, c. Three fried eggs, d. Or a combination of fillings'
      },
      {
        id: 'c7b1032d-a68b-49c0-81c9-5ee4bbe97eae',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: '32dc785e-4077-41dd-9573-5298647f1600',
        name: 'MASALA BEANS'
      },
      {
        id: 'be0a79cf-877c-4200-951f-0a5b41ebd370',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: '7c836d45-1c50-4368-8421-4a7c2e6197ec',
        name: 'GRILLED TOMATO'
      },
      {
        id: '370511ff-eda4-4f88-b73c-c784bb77330d',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: '3567a4c5-b6ce-4b2b-a367-d9e2403732c9',
        name: 'GRILLED MUSHROOMS'
      },
      {
        id: '29c87c83-a281-447f-9734-ceabaf0b46f6',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: '2285efc5-27bd-4737-b291-096c7ea49f2a',
        name: 'PORK SAUSAGE'
      },
      {
        id: 'e59dddb8-bc47-4c7b-8977-d3a1274eac73',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: '75bfb556-41c9-4481-a456-95a75855fb11',
        name: 'THREE RASHERS OF STREAKY BACON'
      },
      {
        id: '7a98a84d-22ee-4ca2-bcef-9790acae6e3e',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: '21f8d4a2-fdc0-45bf-8910-15032613225a',
        name: 'PLAIN NAAN'
      },
      {
        id: '3ba00c24-3072-4572-a57d-bd41092fd57e',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: '2240fd70-033d-4a1d-84e7-f89c5f7e2fe8',
        name: 'MILK BUNS'
      },
      {
        id: '8a8e144e-dd1f-4db8-804e-d8bda51fde90',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: '191f9c90-d25f-4199-9451-bc48035d7c32',
        name: 'BUTTERED TOAST'
      },
      {
        id: '5ce5b2ca-5258-46e5-abcb-051b14575610',
        menuId: '07e21638-f16f-4d69-8d8b-1a2423ff8789',
        itemId: '9c6af51c-5b48-4df9-81fa-e3799efe4041',
        name: 'JAM AND MARMELADE'
      },
      {
        id: '36d4397d-92f3-46c6-b03c-23f7b1522cdf',
        menuId: '0fac3686-4512-48c6-8b73-4c0e2936cf57',
        itemId: 'c84d6023-14d1-4af6-835a-fc9d557a4d70',
        name: '9. MULLIGATAWNY',
        description: 'An ‘English’ soup from Madras, of pulled chicken, celery and rice. A well-known favourite amongst the gora afsars and topeewalahs.'
      },
      {
        id: 'dfd0c9a6-a74c-4846-ba6b-00859fc7f160',
        menuId: '0fac3686-4512-48c6-8b73-4c0e2936cf57',
        itemId: '62c46a35-9247-40c0-99db-f8f1fe62d5e1',
        name: '10. SAMOSA CHUTNEY',
        description: 'Loved by sepoy to Sahib, these flaky pastry triangles are filled with savoury, spiced potatoes and green peas, to be dipped in tamarind chutney.'
      },
      {
        id: '4fadc91b-b9b2-4ee5-8e1b-4658e727f2f3',
        menuId: '0fac3686-4512-48c6-8b73-4c0e2936cf57',
        itemId: '13f84241-35c7-4dc0-b934-5878461cebf8',
        name: '11. SPECIAL SIXTY-FIVE',
        description: 'A dish originating from Hotel Buhari in Chennai of crispy, fiery morsels – ask your orderly for the special of the day.'
      },
      {
        id: 'c3f4b931-7779-4f6a-a1be-7aa2c292de7f',
        menuId: '0fac3686-4512-48c6-8b73-4c0e2936cf57',
        itemId: '5e35183e-0c66-4edc-8b48-b82a99102068',
        name: '12. KEEMA ANDA PAU',
        description: 'An easy, sloppy fix of slow-cooked mutton and crushed boiled egg, served with buttered milk buns. A dish found abundantly in mess halls from London to Ludhiana.'
      },
      {
        id: 'fa24e3af-13a3-40ce-85d9-c03d2508ab3b',
        menuId: '0fac3686-4512-48c6-8b73-4c0e2936cf57',
        itemId: 'd37aa020-79d4-4599-a856-bfda9d3b9e84',
        name: '13. CHICKEN CUTLET',
        description: 'Inspired by the Kabiraji cutlet, the ever-popular, breaded, crispy chicken found in social clubs all over the subcontinent.'
      },
      {
        id: '3b52e9ff-4104-4b8f-a2bd-b02845fc093c',
        menuId: '0fac3686-4512-48c6-8b73-4c0e2936cf57',
        itemId: 'd3cc1676-3c3a-4699-babf-924967abde46',
        name: '14. RAJASTHAN RIFLES CLUB SANDWICH',
        description: 'Soft white bread filled with chicken tikka, celery, white sauce, masala omelette, tomato chutney and cheddar from Lord Newborough’s Rhug Estate. Served with thick-cut chips and on the menu by request of the General’s youngest daughter.'
      },
      {
        id: '92c7546b-aedf-49c2-b92d-b8242ea90753',
        menuId: '0fac3686-4512-48c6-8b73-4c0e2936cf57',
        itemId: 'dc8e47cd-8aa3-46e3-878b-4dbffb0a42a1',
        name: '15. ADMIRAL’S FISH N CHIPS',
        description: 'Beer-battered Hoki fillets with masala fries and marrow fat peas.'
      },
      {
        id: 'e964e8d1-7cfb-4e9d-9b0f-e5fbd154d1f0',
        menuId: '0fac3686-4512-48c6-8b73-4c0e2936cf57',
        itemId: 'e8893cbf-09d5-489d-98f5-6582bcdd2899',
        name: '16. CHUTNEY SANDWICH',
        description: 'Sliced white bread slathered with salted butter, mint chutney, cucumber and tomatoes.'
      },
      {
        id: 'c647bd18-a2cf-4f82-9e0e-f1decf449858',
        menuId: 'a9e20399-2184-4c0d-a131-1d43a1008f74',
        itemId: 'a8d69dbe-7a3e-44a9-b94d-cbfa795295c2',
        name: '17. VEGETABLE ‘CUTLETS’',
        description: 'Beetroot and peanut ‘cutlets’ slathered liberally in parsley butter.'
      },
      {
        id: '777f539a-9f58-4b37-96b3-5240ed68ba33',
        menuId: 'a9e20399-2184-4c0d-a131-1d43a1008f74',
        itemId: '3ceb11b8-8b93-42f1-a90a-854f7d3b99d9',
        name: '18. LAMB CHOP',
        description: 'Lamb cutlets marinated in special Xacuti spices and griddle-fried.'
      },
      {
        id: '47b061d7-2c2d-452c-8f8a-7041d92fbea3',
        menuId: 'a9e20399-2184-4c0d-a131-1d43a1008f74',
        itemId: '69dc73b2-9f6f-4352-8d87-96c1e7a24871',
        name: '19. SOOLA SALMON',
        description: 'A favourite of Captain Vir Pratap, Bakkafrost salmon from the Faroe islands is lightly marinated with Soola spices and seared.'
      },
      {
        id: 'f76b4a07-4bc7-425a-8dcb-7b40cb7fa115',
        menuId: 'a9e20399-2184-4c0d-a131-1d43a1008f74',
        itemId: '1f9d5b8c-ec66-40f0-887a-b95169328d0c',
        name: '20. STEAK AND CHIPS',
        description: 'Pan-seared USDA Prime strip steak with a dry spice rub and thyme.'
      },
      {
        id: '453b720a-0643-45c0-b51e-0c02baadab8c',
        menuId: 'f960941f-00e4-4f9d-b38c-0c18703676ac',
        itemId: 'ee49f2bf-b90c-42b0-b67f-bdfef506799c',
        name: '21. BEEF SEEKH KEBAB',
        description: 'Ground beef chuck short ribs seasoned with green chillies, roasted cumin and loaded with cheddar cheese, then flash-grilled in the tandoor.'
      },
      {
        id: 'fcec9a4d-a3a9-4ae6-8859-c11539be04f0',
        menuId: 'f960941f-00e4-4f9d-b38c-0c18703676ac',
        itemId: '7cf0b1d0-9573-49c0-8fdc-dd919a8aea1f',
        name: '22. CHICKEN TIKKA',
        description: 'Local chicken marinated in yoghurt, ground chillies and mustard, accompanied by a tart coriander chutney and dressed Poona onions.'
      },
      {
        id: '419a2463-b320-479e-8987-137d46c796a1',
        menuId: 'f960941f-00e4-4f9d-b38c-0c18703676ac',
        itemId: '1beb8b5b-f049-4a88-b270-9b0f64768d09',
        name: '23. SHASHLIK PANEER',
        description: 'Pieces of fresh, house-made paneer are marinated in spices and skewered with capsicum, onions and tomatoes, then grilled in the tandoor. Seasoned with lime juice and chaat masala, the paneer, though tender, has a crisp singe on the surface.'
      },
      {
        id: 'b0faa81c-229d-4894-b34c-4eae9a766253',
        menuId: 'f960941f-00e4-4f9d-b38c-0c18703676ac',
        itemId: '0e2c4ebb-7535-4377-a498-f639a1307fca',
        name: '24. BROCCOLI NARGIS',
        description: 'Broccoli, fresh cheese and hen egg, cooked in the tandoor. A very good way for the sepoys to have their greens.'
      },
      {
        id: '236d571e-d7c1-4192-b9a4-60bdc1c66391',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: 'b422f98e-e22e-4247-98f5-73ff060437f8',
        name: '25. BUTTER CHICKEN',
        description: 'Cantonment version of every soldier’s favourite chicken curry, with braised chicken tikka, tomato and butter.'
      },
      {
        id: '75961969-420e-4f28-a96b-f70762d3ddbd',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '56699555-da94-4c6b-a02b-042214e77158',
        name: '26. HURRY-BURRY CHICKEN CURRY',
        description: 'Country-style chicken curry with potatoes and shallots. Popularised in the ports and seaside towns harbouring cargo ships transporting tea, spices and sepoys.'
      },
      {
        id: 'ffa535bf-d58d-4faa-a864-14fd9983e0ab',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '67683bbd-b083-4933-8c3c-ccb135f58183',
        name: '27. PANEER MATTAR',
        description: 'A very rustic and quick curry of soft cheese, peas and ginger.'
      },
      {
        id: '5cc35763-1325-4aa0-872d-6dda3a054784',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '833c1cb9-f4c4-4410-b2dd-d16aceaf12c6',
        name: '28. PEDRO VINDALOO',
        description: 'The Head Cook’s best friend Pedro shares his Goan recipe for Vindaloo with prawns, white wine vinegar and garlic.'
      },
      {
        id: 'd31e027e-b5fb-443e-b9d4-83e53a72a6ce',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '2aee620d-dc04-4d2c-ac4c-2647662b914a',
        name: '29. RAILWAY MUTTON CURRY',
        description: 'Present on the menu of every dining car, this lamb and potato curry made the railway journey a memorable culinary experience.'
      },
      {
        id: '62792eff-b2a0-4be0-9cf4-7dc5b0abb23d',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '476f6e5d-55a3-49f4-9315-70089e16d4d2',
        name: '30. FISH HAVILDAR RAMU',
        description: 'A stew of river fish with onions, tomatoes and goraka.'
      },
      {
        id: 'cb7bcec0-c47b-4ba7-8db6-b608277bec05',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '8fa070f5-c945-4acd-8111-7bca9fb9f0b7',
        name: '31. DUM ALOO “GUNPOWDER”',
        description: 'A company special potato dish to please the vegetarian officers.'
      },
      {
        id: '38e52739-87c0-4d2e-85b5-6dd306671504',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '9acdd497-663d-4662-a1bb-3af0f2320a57',
        name: '32. DAL RIFLESWALA',
        description: 'The cook-room’s signature slow-cooked lentils smoked over charcoal embers.'
      },
      {
        id: '9454c011-7ac6-4601-8896-5eaaeae3f235',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '811b877d-5f2b-40ef-86ad-b88474dca084',
        name: '33. CLUBWALA PALAK',
        description: 'Creamed spinach is given the Rifles touch with a tempering of garlic, onion and tomato masala.'
      },
      {
        id: 'ecb66336-c88f-4369-b6d0-6776a704eb7d',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: 'aa38ba26-8902-4b05-9779-1fa1f7a23897',
        name: '34. STEAMED VEGETABLES',
        description: 'Seasonal vegetables with butter and parsley.'
      },
      {
        id: '503e12c9-6fb2-415d-8ee6-f33dcafbe7c0',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: 'ba891785-552f-4089-86a4-03a7e52ef30d',
        name: '35 MUSHROOM AND GREEN PEA',
        description: 'A beautiful summer treat of morels and petit pois in layers of fragrant rice with saffron and cream.'
      },
      {
        id: 'af4184d9-3c71-48d4-ae9a-d9dbfa619375',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '2fed29ce-9459-4af1-af7b-557dc2c67613',
        name: '36. LAMB BIRYANI',
        description: 'A one-pot meal for the regiment on the move. Lamb shoulder and ribs marinated in spices, then slow-cooked in layers of aged basmati rice with mint and saffron. Served with raita.'
      },
      {
        id: 'bf721b56-40f2-4bff-8c47-98e1ffa93d62',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '068f344d-5f13-4475-815e-ced593ddd025',
        name: 'PLAIN NAAN'
      },
      {
        id: '7c49eac9-ca43-454f-a71d-c5c0ea9ebf5f',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '2a6dbd76-ee61-44db-b62e-389becae3e27',
        name: 'BUTTER OR GARLIC NAAN'
      },
      {
        id: '3c21964a-ee83-4bdb-91e8-e154203649ba',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '698baf4e-df13-4e9b-8fd1-9398c1304b0c',
        name: 'PLAIN OR BUTTER ROTI'
      },
      {
        id: '646119a3-9547-4895-ad14-04db99df2fe7',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '92364219-4299-403b-ab45-b41d984dc0d1',
        name: 'LACCHA PARATHA'
      },
      {
        id: '452cd212-582a-4662-8964-e4c036587daf',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '4bf95b0f-176c-4229-8ede-0e97ab69e1d1',
        name: 'MILK BUNS'
      },
      {
        id: '6c833b75-b365-4c23-82d9-022705c126d2',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '81f91f2c-20af-4694-84bf-7898835a55ab',
        name: 'STEAMED RICE'
      },
      {
        id: 'bcb87e0c-25ed-4695-ae9a-b82013925cbd',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '2655c911-c627-4da3-be1b-b3cc12ecd268',
        name: 'HERB RICE'
      },
      {
        id: '59ad9c42-e38d-4acd-86d6-60fe0f42f31b',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: 'f9a1c0d2-f06d-4808-a534-76b3c7d06cef',
        name: "MIXED VEGETABLE 'KACHUMBAR' SALAD"
      },
      {
        id: 'df5e5f7d-85d5-4bc3-9b2f-ae2e9a13932b',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '5aa48cf3-df11-4287-9f95-ccaea0ab4d07',
        name: 'ONION CHILLI SALAD'
      },
      {
        id: '0bb32aac-ba01-4256-899b-76ed987af269',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '1c3c254e-2f1b-4629-9f5e-d1025e203850',
        name: 'PINEAPPLE RAITA'
      },
      {
        id: '48400ca3-b4e7-4eab-be4c-ce7c8f0cbdb8',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: 'c914ad06-764b-43b0-bace-dae76b2ddab8',
        name: 'MINT CHUTNEY'
      },
      {
        id: 'de6c7716-3d87-450d-9f6a-99a0c0613318',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '2d6cfd33-d87a-46b4-a68a-eacaaed0f0fb',
        name: 'TOMATO CHILLI CHUTNEY'
      },
      {
        id: '33caaff3-71e8-439b-be7c-6e0b92628609',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '1692efa7-163a-495c-beb5-416e456176e2',
        name: 'MANGO CHUTNEY'
      },
      {
        id: 'eb865344-bac0-4c4b-afc2-ec54e1ede037',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '4f9e80f7-5aef-4e60-8439-51b047f11c61',
        name: 'MANGO PICKLE'
      },
      {
        id: 'b634bd0e-7141-4cbc-a4e1-a15a8e048a99',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: 'ea7a2d0a-bd77-4cdd-86b7-7f158069e7bb',
        name: 'LEMON PICKLE'
      },
      {
        id: 'f6d0caca-e22c-4267-a32b-fb1ea467c6ef',
        menuId: '3fd4d3dd-c0ce-42da-839a-ae470922ff7a',
        itemId: '79a56f76-9db0-43c8-8ccf-b5842cb797ba',
        name: 'ASSORTMENT OF CHUTNEYS'
      },
      {
        id: '791d3f10-5ded-4a65-849e-6bb309983214',
        menuId: 'b0997cf3-6691-42a5-b586-88163d78a4e7',
        itemId: '7901acac-759f-41f9-bec2-f1e9f41116a7',
        name: 'LEMON POSSET',
        description: 'Velvety lemon cream with a preserved fruit cocktail.'
      },
      {
        id: '9524b8f9-b560-4211-834c-5faa156afd05',
        menuId: 'b0997cf3-6691-42a5-b586-88163d78a4e7',
        itemId: '65c9eaac-0795-41fc-873a-5214840b37cf',
        name: 'BREAD AND BUTTER PUDDING',
        description: 'A warm blanket of a pudding with bread, custard and raisins, just like nanny made for the gora afsars back in Blighty.'
      },
      {
        id: '1139c806-e7bf-4dad-873b-18fa00c8552a',
        menuId: 'b0997cf3-6691-42a5-b586-88163d78a4e7',
        itemId: 'e3216928-834e-4143-9cc3-0b6b1e046cc6',
        name: 'TREACLE TART',
        description: 'The Commanding Officer’s wife’s recipe perfected in the tea estates of Conoor and served with cool clotted cream.'
      },
      {
        id: '48cd461b-8ed9-4ef1-8eee-3367d2e127f1',
        menuId: 'b0997cf3-6691-42a5-b586-88163d78a4e7',
        itemId: 'd7e5425e-2053-4f81-92b9-27f466cfecf2',
        name: 'CHEESE AND CHUTNEY PLATE',
        description: 'Semi-hard cow’s milk cheese from Lord Newborough of the Rhug Estate, made by hand in open vats and covered in nettle leaves. A taste of the English countryside for the homesick gora afsar.'
      },
      {
        id: '233472ca-8d20-49b8-9856-bc5ad791601d',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '11dd78a5-f271-4d85-a6c5-89b5cb6a7299',
        name: 'Poppadums',
        description: 'Fried and roasted lentil crisps',
        price: 8
      },
      {
        id: '4235d277-0c25-43a5-add9-2b90ea7a15af',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'f9114176-0203-4b9e-9cb9-c36600b984f4',
        name: 'Aloo Samosa',
        description: 'Spiced potatoes cuddled in flaky pastry',
        price: 38
      },
      {
        id: 'f75d2b47-471e-40db-b678-bf3d5edff53e',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '8dab87e4-7ddc-4b3b-b155-94cb4669ce2b',
        name: 'Onion Pakora',
        description: 'Onion and chickpea fritters',
        price: 38
      },
      {
        id: 'e468bf47-bcdd-4757-9740-c3e7e3e69cb5',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '957c7ca2-3cf0-4792-bea1-fa4a8733d57a',
        name: 'Chicken Tikka',
        description: 'Boneless chicken thigh seasoned lightly with spices, then cooked in the tandoor',
        price: 128
      },
      {
        id: '3cf90a41-ff0c-4bde-a0a4-ec7b1797fb19',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '06a81705-7a33-44b3-9e06-ceff3e4b3bf1',
        name: 'Fish Tikka',
        description: 'Local market fresh fish seasoned lightly with spices, then cooked in the tandoor',
        price: 128
      },
      {
        id: 'bcbc3a02-be00-4e1f-b111-b94017cb57fd',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '26235f65-8761-412f-a75c-50f62dffc013',
        name: 'Butter Chicken',
        description: 'Old Delhi-style chicken in a lightly spiced and very buttery gravy',
        price: 138
      },
      {
        id: '1e739c93-d86e-4246-8ab0-1ef22f6d9314',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '58acfa2a-ed74-4af9-aaa1-458740b31641',
        name: 'Chicken Tikka Masala',
        description: 'Your best bet - Chicken Tikka tossed in tomatoes and grated coconut',
        price: 128
      },
      {
        id: '6ee36913-c7aa-49bf-9cfd-0614fa1a4bfd',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '1eb61d07-1577-4959-b5d1-4ce88c88a8ae',
        name: 'Lamb Rogan Josh',
        description: 'Tender slow-cooked Curry of lamb and star anise',
        price: 138
      },
      {
        id: '3f3c6c04-d95a-4c5d-8f0d-20e7d3d7a38e',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'e034af23-8b68-4953-ac8b-b31ee216c525',
        name: 'Prawn Masala',
        description: 'Uncle-style Prawn Masala with onions, tomatoes and chilli',
        price: 138
      },
      {
        id: 'a55dad8d-1caf-425d-ae68-177ecf233b0a',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'd1035ff3-ec18-4ca0-bec3-349ae7559ccf',
        name: 'Paneer Butter Masala',
        description: 'Soft cheese in a rich sauce with onions and tomatoes',
        price: 98
      },
      {
        id: '1b8c48f0-ba35-405f-9bea-a3691f568511',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '72f9acae-245e-4d2b-8fe1-1622fecb7c2a',
        name: 'Vegetable Balti',
        description: 'Mixed vegetables with Uncle’s secret spices',
        price: 98
      },
      {
        id: '96e8f7b0-5c2d-4024-9c23-77cd5dc147c3',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '998721b5-db76-45af-9d43-864ce9acfe52',
        name: 'Saag Aloo',
        description: 'Lovely dish of spinach and potatoes',
        price: 78
      },
      {
        id: '40784a3d-e13c-4c39-8b6e-5bec46a26a18',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '2528c080-9b82-4ef7-967c-e606146a40ff',
        name: 'Dal Tadka Fry',
        description: 'Uncle’s world-famous yellow lentils',
        price: 88
      },
      {
        id: '783e7776-9ecb-4bb7-9a03-7e236d2f5b1b',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'd749bd01-d318-4067-9b4e-cdd7bcd58e3d',
        name: 'Saag Paneer',
        description: 'Tasty dish of spinach and soft cheese',
        price: 88
      },
      {
        id: 'b6b0ab3b-ae44-4fbc-a53f-becf73e32dcf',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'db26200f-4236-4ad9-ac83-8e0f9ee02012',
        name: 'Raita',
        description: 'Cooling yoghurt sauce',
        price: 28
      },
      {
        id: 'a31604f0-b1d5-49b4-8786-73ef0de53980',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '86fb0898-ed15-4437-a65c-1f768d724dae',
        name: 'Naan',
        description: 'Fluffy leavened bread baked in the tandoor, brushed with butter',
        price: '28/26'
      },
      {
        id: 'bbb1efe8-3ef3-4a2e-a2c5-ab80822053b9',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '40d9a36f-c2b8-4095-a665-45cc48e78713',
        name: 'Tandoori Roti',
        description: 'Wholemeal flatbread baked in the tandoor',
        price: 18
      },
      {
        id: '06e8ec22-833d-4b24-90fb-b54f8aba856c',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'dd4c3266-95c0-4001-b9db-f3afaafa1e6c',
        name: 'Kachumber',
        description: 'Indian chopped vegetable salad',
        price: 28
      },
      {
        id: 'fb5b4b97-0084-4fcf-816b-4b16062a0fe8',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '48f4001a-0527-47d9-ab94-eda2abdaecf6',
        name: 'Steamed Rice',
        price: 28
      },
      {
        id: '48b75ad7-48bc-4bea-b539-6b42f6d07bed',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '590d44ef-945f-42c4-ac38-8e8550119b50',
        name: 'Onion and Chilli Salad',
        description: 'Spicy onion salad',
        price: 8
      },
      {
        id: 'ca13b8a3-cb32-448a-9107-3e927b8e0fb7',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'fc273670-ce34-4248-83b7-58a85c16f9f2',
        name: 'Johnnie Walker Black Lagel',
        description: '200 ml bottle',
        price: 158
      },
      {
        id: '1022508f-8b76-48b3-bb3c-b4d567ab6962',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'a447ae27-02e9-4987-86cb-3dff2c913aca',
        name: 'Kingfisher Beer - Strong',
        description: '500 ml can',
        price: 38
      },
      {
        id: 'e28561e2-a8f1-423a-aee9-58117c3ab5b8',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '9d7974a6-3af5-4875-8653-9137f56e34ff',
        name: 'SHREDDED CHICKEN SALAD',
        description: 'cabbage, rau ram, pickled chilli, prawn chips'
      },
      {
        id: '68d687ec-d72f-49ed-8912-0066c328034f',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'd6739261-cc22-42d2-a54f-cfe2b00ae6bf',
        name: 'GREEN PAPAYA SALAD',
        description: 'cashews, pickled chilli, crispy rice paper'
      },
      {
        id: 'e3a6b29f-b0d5-41c8-b287-9ee968bb8855',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '9a50288b-607f-4ad2-88e1-65e4534c1cd0',
        name: 'VIETNAMESE CHARCUTERIE',
        description: 'chicken liver pâté, beef jerky, pickles, bánh mì'
      },
      {
        id: 'b9bd93cc-a283-434f-bf9f-2dd412c457cd',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '7739ad66-b432-4703-904a-6ffb2dc11c61',
        name: 'NAM VANG-STYLE SALT & PEPPER CHICKEN WINGS'
      },
      {
        id: 'a275bcf0-db5b-45ea-b7be-4f15608b50ca',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '4119a8b4-4b3f-4b07-9739-cfa7775f0387',
        name: 'CHA GIÒ',
        description: 'pork & prawn spring rolls, nuóc mâm châm'
      },
      {
        id: '2a3043b5-afb6-432b-8122-3d7f086515ea',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '9b526efb-7a44-40f5-a92a-b054ee881998',
        name: 'STICKY SOFT-SHELL CRAB',
        description: 'green mango, chilli jam'
      },
      {
        id: 'c83fd336-a70a-4c4e-9dab-d2aa5180e1c9',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '64eb911e-331d-481e-9edc-162a44537516',
        name: 'CRISPY SHRIMP RICE CAKE',
        description: 'salted radish, peanut sauce'
      },
      {
        id: '27de2d96-e7cb-411d-90a2-a1f826c8912e',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '48e43ba3-ec1e-40e3-a5ab-0ff12f452405',
        name: 'BARRAMUNDI',
        description: 'grilled in banana leaf with lemongrass chilli paste'
      },
      {
        id: '5e4f822d-8ade-4d60-b60c-137ea814c01b',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '2dbfe825-4c3a-4bb2-9b42-c2d081a32326',
        name: 'OKINAWA PORK COLLAR',
        description: 'grilled over wood fire with lemongrass and maple glaze'
      },
      {
        id: 'cabc663e-511a-48aa-9c91-e7567748a188',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'ac32dd23-4d55-4733-a04d-c8cdcdd63f45',
        name: 'RANGERS VALLEY WAGYU BAVETTE',
        description: 'slow-cooked over embers with caramelised fish sauce'
      },
      {
        id: '7f2cf0fb-f8c1-423d-8363-c0a8bfaac498',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '04de0dce-4d02-4ac8-9ac2-b21932f9bc44',
        name: 'GRILLED CALAMARI',
        description: 'leek salsa, mint'
      },
      {
        id: 'b289c15a-08b2-4ac3-bd64-8d70077b1488',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '4de305d4-d557-40ee-babd-da36a122d847',
        name: "CÀ PHÊ SŪ'A PORK RIBS",
        description: 'Vietnamese coffee, pickled radish'
      },
      {
        id: '2de5a918-b63a-46c3-b4a3-3c21490542a4',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'b96adf10-f198-4f2f-97fc-1f2cb9e4c972',
        name: '333 SAIGON BEER CAN CHICKEN',
        description: 'lemongrass, pickled onion, rau ram'
      },
      {
        id: 'fcd06d13-3166-4618-aa58-53ad553c92f9',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '54bd4d5f-265a-47a4-9f60-21e36465e504',
        name: 'SLOW-ROASTED TE MANA LAMB RIBS',
        description: "rau ram salsa, nu'ó'c màu cam"
      },
      {
        id: '751930bf-cd10-4bc3-a41e-d9dcb7eaab1a',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'fcc07dce-3f25-4e5c-8b88-fb966803c9de',
        name: 'WOOD-FIRED SUCKLING PIG',
        description: 'fried & steamed baos, Vietnamese kimchi (48 hours notice)'
      },
      {
        id: '7907e468-71f7-4989-b6c9-e64370852b70',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '7ddd755f-5de5-4ca0-98bc-1ff131cb8ec9',
        name: 'WOK-FRIED VIETNAMESE GREENS',
        description: 'ginger, garlic'
      },
      {
        id: 'f4da7031-f2fe-45f8-ac96-0d3ddd2decc7',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '8d35ac72-f300-47a2-9d3f-2657af36981d',
        name: 'BUTTER SWEET CORN',
        description: 'mushroom XO, puffed rice, kaffir lime leaf'
      },
      {
        id: '745f30eb-f427-4181-a0b7-876b29452b8c',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '196fa82c-4583-4458-ac77-a7eb77b4a021',
        name: 'CRISPY EGGPLANT',
        description: 'mixed herbs, ginger glaze'
      },
      {
        id: 'a2c54ad3-16a2-496e-86ea-343716d87653',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'af5dce2a-79a2-4f16-b40b-50d790d0df49',
        name: 'FIVE SPICE TOFU',
        description: 'crispy lemongrass, curry leaves'
      },
      {
        id: '1b8fc4a7-60ba-4f97-a12d-9817df6f6acc',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '68cb5178-a7fe-48a0-8a34-dd0bfe2877e5',
        name: 'SNOW CRAB FRIED RICE',
        description: 'crab miso, bean sprouts'
      },
      {
        id: '240249de-bcca-4cde-a19e-339f392d1070',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '3fa0bcb4-6ed6-4a35-972d-d83b0a824182',
        name: 'MUSHROOM HU TIEU',
        description: 'rice noodles, crispy enoki, chives'
      },
      {
        id: '88a703b5-db86-4c4c-89e9-af5bd230adb6',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'fd36e5a1-1ce0-4ccf-9ab3-61bd87280a1d',
        name: 'CHÈ',
        description: 'smoked coconut ice cream, honey joy cornflakes, black sesame crumbs'
      },
      {
        id: 'c4c05ef9-bbe5-4a0e-98fc-15c8d4279acf',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'f448b1d8-0653-49f2-97b1-7a488d2c97cd',
        name: 'WOOD-FIRED BANANA BREAD',
        description: 'warm maple caramel, candied walnuts'
      },
      {
        id: '3fd2b331-b3c9-4361-9265-bc1931b98bd5',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'c390f733-3e20-4b6e-8361-61d02020f43d',
        name: 'CHOCOLATE & ALMOND BROWNIE',
        description: 'passion fruit coulis, vanilla mascarpone'
      },
      {
        id: '78c6091b-4336-49ce-8874-488b6fe141b9',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'd8127bac-60ee-4f9d-9ac9-8afa786349da',
        name: 'VIETNAMESE DRIP COFFEE',
        description: 'coconut ice cream affogato'
      },
      {
        id: '69b36dc9-9dcf-4bb1-b19a-eb80edf82dea',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '517a1d7a-a973-46c0-9be4-4d406272d8f1',
        name: 'BÁNH MÌ THIT',
        description: 'Rolled pork belly, pork terrine, Vietnamese cured meats, pork floss, mayo, chicken liver pâté, pickles, spring onion, cucumber, coriander, chilli, soya sauce',
        price: 88
      },
      {
        id: 'ccd057fc-c607-4861-b033-24d395ee5bee',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '0018dcfa-4e39-449f-9ee3-b1bce67573d9',
        name: 'BÁNH MÌ GÀ',
        description: 'Shredded chicken, mayo, chicken liver pâté, pickles, spring onion, cucumber, coriander, chilli, soya sauce',
        price: 88
      },
      {
        id: 'ca7f01c3-75a3-4c0b-b52a-671585b1bf1d',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '9fe58dad-61e4-4b92-a848-d0cf89920bd4',
        name: 'BÁNH MÌ CHAY',
        description: 'Tofu, mayo, pickles, spring onion, cucumber, coriander, chilli, soya sauce',
        price: 88
      },
      {
        id: 'c7156e6a-74e6-41d4-8909-b21261df9bba',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '74ff6e8b-837b-4789-8385-39df4cf95670',
        name: 'POTATO GEMS',
        description: 'Spicy mayo',
        price: 38
      },
      {
        id: '5036550e-fcd5-476e-9609-c2fb1ee86611',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: '5c5d8cce-dfc2-4da7-a665-8d19c78ba10f',
        name: 'THICK CUT FRIES',
        description: 'Spicy mayo',
        price: 38
      },
      {
        id: 'dc9d48b3-99fe-46b5-98f0-25f6c4a05fac',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'ae0a8f96-120a-456d-adfe-45494072db42',
        name: 'LE PETIT SAIGON COLESLAW',
        description: 'Vietnamese herbs, cashew nut mayo',
        price: 38
      },
      {
        id: '98dd838e-0370-41cb-ae73-c632069f9947',
        menuId: '5642da57-70ba-47d6-848e-acc3224fbca0',
        itemId: 'df75be13-4fc0-420d-b184-558cfed12857',
        name: 'SAIGON WINGS',
        description: 'Chicken wings, mixed herbs, spicy mayo',
        price: 68
      },
      {
        id: '13352dfd-317f-41e3-aedd-6eff09cb0a02',
        menuId: 'a95e3b15-cb09-49dc-a8f7-343eb8076460',
        itemId: '1e1b8000-c962-4cc6-b292-a3666949c2fd',
        name: 'BÁNH MÌ THIT',
        description: 'rolled pork belly, pork terrine, Vietnamese cured meats, pork floss, mayo, chicken liver pâté, pickles, spring onion, cucumber, coriander, chilli, soya sauce'
      },
      {
        id: '308da0cd-063c-4443-8dda-075a941c7aea',
        menuId: 'a95e3b15-cb09-49dc-a8f7-343eb8076460',
        itemId: '69607301-2247-43ba-a450-514f92aff6cb',
        name: 'BÁNH MÌ GÀ',
        description: 'shredded chicken, mayo, chicken liver pâté, pickles, spring onion, cucumber, coriander, chilli, soya sauce'
      },
      {
        id: '2f529b4d-2adf-441c-8cf1-5be7bb22f9fe',
        menuId: 'a95e3b15-cb09-49dc-a8f7-343eb8076460',
        itemId: '6983d951-98b4-4500-b5a3-eb8e5fd06b4e',
        name: 'BÁNH MÌ CHAY',
        description: 'tofu, mayo, pickles, spring onion, cucumber, coriander, chilli, soya sauce'
      },
      {
        id: '984bb10a-0093-4727-9c04-c3ea6d09f1f8',
        menuId: 'a95e3b15-cb09-49dc-a8f7-343eb8076460',
        itemId: '22bd3ef2-b75f-492d-8167-bf4ea80cbcb1',
        name: 'LE PETIT SAIGON COLESLAW',
        description: 'Vietnamese herbs, cashew nut mayonnaise'
      },
      {
        id: '22eb1ead-f9cd-4e11-be00-5fe6dda6376d',
        menuId: 'a95e3b15-cb09-49dc-a8f7-343eb8076460',
        itemId: '6b8af98f-911a-4678-b4b0-3abd43bd10a2',
        name: 'SAIGON CHICKEN WINGS',
        description: 'chicken wings, mixed herbs, spicy mayonnaise'
      },
      {
        id: '2f8388ed-8ad7-435e-bf62-2b944c8756e2',
        menuId: 'a95e3b15-cb09-49dc-a8f7-343eb8076460',
        itemId: '23efb0ee-fce5-4fc2-a224-582f06a7932e',
        name: 'LE PETIT SAIGON COOKIES',
        description: 'two per person'
      },
      {
        id: 'e5862aa9-b671-4e69-9c1b-dc0af2bae291',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: 'd270ca0b-c498-48ff-a609-b4d411cc5229',
        name: 'HUMMUS BIL TAHINI',
        description: 'Chickpea purée, tahini, lemon juice, olive oil',
        price: 68
      },
      {
        id: '0d3d5bd4-3adf-4d3f-9163-a7a4f7e37f4c',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: '6a4a99a7-fff3-4e46-97db-8fe8d4e648a2',
        name: 'BABA GHANOUSH',
        description: 'Charred eggplant, yoghurt, tahini, olive oil',
        price: 68
      },
      {
        id: 'f16877fd-36d2-41fb-b159-dcf6194ac54f',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: 'dacc7d74-c31c-49a3-84ac-8299f2ec0339',
        name: 'MAISON’S LABNÉ',
        description: 'House-spiced yoghurt, cucumber, olive oil, za’atar',
        price: 68
      },
      {
        id: '6d9a1c57-587f-4865-8fe9-b68ca75de31a',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: '57af22a5-c4af-4ab1-a966-7f3874661626',
        name: 'HUMMUS SHAWARMA',
        description: 'Hummus, sliced marinated roast beef, olive oil',
        price: 98
      },
      {
        id: '3c276895-b3bb-4211-bc72-785d2a61e476',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: 'dc226198-2e92-4ccd-8142-5c5b4f9aea0a',
        name: 'LEVANTINE FATTOUSH',
        description: 'Gem lettuce, cherry tomato, radish, mint, crispy pita khobez, sumac dressing',
        price: 128
      },
      {
        id: 'af453c65-cda4-4c9c-96ca-8242873b22fc',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: 'eedb1d3d-141e-4ad6-8e7f-b9f8e2b12a6f',
        name: 'BARLEY & HALLOUMI',
        description: 'Baked halloumi, pearl barley, sultanas, pomegranates, chickpeas, herbs, pine nuts',
        price: 158
      },
      {
        id: '2fddb60c-67ef-4b21-84a9-17983506355f',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: '1d829734-a5df-4a23-821b-d46134798758',
        name: 'TABBOULÉ',
        description: 'Parsley, mint, bulgur, onion, tomato',
        price: 98
      },
      {
        id: '05481b92-bcd3-414e-a7ba-006476f143f4',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: '5319022a-aeb2-45fa-973e-9f04b2ce8e01',
        name: 'MAISON’S FALAFEL',
        description: 'Chickpeas, fava beans, sesame seeds, tahini yoghurt, pickled turnip',
        price: 128
      },
      {
        id: '95e47bb3-224c-4d73-bc03-cb57e6a102aa',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: 'd06fbc4d-8a4b-439c-a8bc-4d8908ea67a2',
        name: 'PAN-FRIED HALLOUMI',
        description: 'Honey-glazed halloumi, figs, Byzantine dressing',
        price: 158
      },
      {
        id: '5010c875-d7cc-496d-88bb-073284c05e36',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: 'b0aecdc4-2214-4661-bbe6-f3605b9567af',
        name: 'ARAYES',
        description: 'Grilled pita khobez, chargrilled spiced lamb, tahini, pomegranates',
        price: 158
      },
      {
        id: '58e424ac-c244-4a72-a3c0-d449457f71ce',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: '771644ec-7982-47f3-b491-680963b7f5e0',
        name: 'SAWDA DJEJ',
        description: 'Pan-fried chicken liver, garlic, lemon, pomegranate molasses',
        price: 148
      },
      {
        id: 'b48b8087-7ed2-4f9c-8900-966b606fa302',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: '3ad3f251-cbe9-4529-bea2-91e3b99d2b33',
        name: 'EGGPLANT MOUSSAKA',
        description: 'Roasted eggplant, tomato ragu, chickpeas, cumin, mint',
        price: 168
      },
      {
        id: '6e22b812-1dae-440c-9869-beb3547c801f',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: 'f988751e-01f1-4b3f-bce4-ec5db19d0af6',
        name: 'CHICKEN MESHOUÉ',
        description: 'Chargrilled marinated chicken, freekeh and pomegranate salad, Beiruti toum',
        price: 228
      },
      {
        id: '1ea18169-1324-46df-8f50-97750d67a300',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: '5f068c35-82d8-4008-87ba-77879ace4cfb',
        name: 'BAKED SAMKÉ HARRA',
        description: 'Sea bass fillet, coriander, tomato tahini sauce, roasted pine nuts',
        price: 248
      },
      {
        id: '5c78560d-05a6-4c82-90a6-f31f0649ca1a',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: 'b5286305-006b-40e3-9eb4-dcdc6f6c9a31',
        name: 'ROASTED CAULIFLOWER',
        description: 'Harissa-roasted cauliflower, zhoug, tahini, sumac, dried lime',
        price: 128
      },
      {
        id: 'fc2af762-62d8-40aa-88db-76830b2cdcca',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: 'bb596e28-975b-476e-8a67-ab1dadcdee02',
        name: 'LAMB KAFTA',
        description: 'Chargrilled spiced lamb skewers, sumac-grilled tomato, hummus',
        price: 288
      },
      {
        id: 'b7f9815c-bdd3-47fd-a649-1f4923c84b28',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: 'aa7bc604-edd1-4a70-b63d-80db3aa69e46',
        name: 'LAHÉM MESHOUÉ',
        description: 'Chargrilled beef skewers, harissa, grilled pickled chilli',
        price: 278
      },
      {
        id: '948cda5e-5ed8-4ace-9b7b-5144a344f580',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: 'e40b0857-4fe7-4f48-9b03-67f63e2e53a5',
        name: 'MASHAWI',
        description: 'Beef skewer, chicken skewer, lamb skewer, grilled pita, pickled chilli, toum',
        price: 388
      },
      {
        id: '1de4aed7-2531-4593-a41a-b08c18ca6f48',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: '5caf6463-ddf6-4f6f-88cc-86ecf9eb4a6a',
        name: 'SHISH TAOUK',
        description: 'Chargrilled chicken skewers, sumac-grilled tomato, toum',
        price: 168
      },
      {
        id: '43f3befc-adf2-4c6c-9ee1-71a04100e80f',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: '4968d468-18c1-49ba-9d6e-0712c742bca2',
        name: 'HEARTH-BAKED PITA BREAD',
        price: 58
      },
      {
        id: '0bafdabe-1586-4ed1-b2d0-56a471e3a81f',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: '5997aa82-bf3a-4f2c-9ac6-d4043c3ada43',
        name: 'HOUSE-MADE PICKLES',
        description: 'Green chilli, pink turnip, cauliflower',
        price: 48
      },
      {
        id: 'eb9c4da7-4ec9-46de-a82c-f9b77c30fb75',
        menuId: '0f01fb96-e8b5-47d8-ae1b-a10816536fb7',
        itemId: 'e5d0b7a7-eb16-47e2-8ee3-a5baec5888ee',
        name: 'RICE PILAF',
        description: 'Rice, green lentils, almonds, onion, warm spices',
        price: 78
      },
      {
        id: '4d1bda10-285f-474d-a82c-637cd6954968',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: 'e056de24-8f2e-418e-9859-9d75c1c26307',
        name: 'Chateau Kefraya, Les Bretèches 2017',
        description: 'Muscat/Sauvignon Blanc/Viognier',
        price: 78
      },
      {
        id: '782d38b0-8387-44f2-871a-0e7f1b34f3b0',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: 'ff477a71-ced3-434a-a854-a350f55721a2',
        name: 'Chateau Ksara, Blanc de Blancs 2018',
        description: 'Chardonnay/Sauvignon Blanc/Semillon',
        price: 98
      },
      {
        id: '905e8d9d-400b-4c55-bf26-744bcfbaee29',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '24922efa-1484-4c2b-a7a8-abe98f0f7a53',
        name: 'Chateau Ksara, Chardonnay 2004',
        description: 'Chardonnay',
        price: 888
      },
      {
        id: 'b98ea31c-0556-44a5-bb05-2c96b70d98ae',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: 'd8c89a7e-8813-4af1-a120-db4ac4e7ad88',
        name: 'Massaya 2018',
        description: 'Obeidi/Rolle/Clairette/Sauvignon blanc/Chardonnay',
        price: 488
      },
      {
        id: 'f87f45d2-c117-40fa-aa3c-db07a3877800',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '3e78721c-3cba-4d10-9a7c-a826435dd516',
        name: 'Chateau Ksara, Chardonnay 2016',
        description: 'Chardonnay',
        price: 128
      },
      {
        id: '8e854ddf-d39d-44c3-be9d-fd9890f3dc90',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '5e586300-cf78-4d1f-b4c9-fd0ab304ef90',
        name: 'LePlan Vermeersch, Cotes du Rhone 2018',
        description: 'Grenache/Marsanne',
        price: 368
      },
      {
        id: '3e0e7b3a-bb2f-49e7-b7f4-f8453235eae4',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '7fa121ea-9a68-44e7-83b8-3399ff3f86a5',
        name: 'Bernard Defaix, Chablis 2018',
        description: 'Chardonnay',
        price: 768
      },
      {
        id: '2f86e3f1-3eb7-40f4-a159-d6b2a7ff1b34',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '5bdd562b-3a89-4b61-aa65-379e66f476e0',
        name: 'Leon Beyer, Riesling 2018',
        description: 'Riesling',
        price: 648
      },
      {
        id: '24b20675-67ec-4f4c-9150-e170498d0354',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: 'ab70fa8b-d916-4bcc-9482-1a8964b4421c',
        name: 'Chateau Kefraya, Les Breteches Rose 2019',
        description: 'Grenache/Cinsaul',
        price: 78
      },
      {
        id: '353ba312-a5d3-415d-b91b-5f388d5b9dac',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '98b221d7-e389-4fa6-a9f0-7238d28b0bf2',
        name: 'Torresella Extra Dry Prosecco NV',
        description: 'Glera',
        price: 78
      },
      {
        id: '0da97a88-a7fe-423c-bee7-67c209957cc6',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '97cd27ca-99f9-41c1-8744-e5e23fbafa8f',
        name: 'Chateau Ksara, Moscatel NV',
        description: 'Muscat/Gewuurztraminer',
        price: 88
      },
      {
        id: 'f95bae0f-792b-456a-b793-b5aa888e614d',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: 'dc7cfdab-b221-4351-8683-f5065768a112',
        name: 'Chateau Kefraya, Les Breteches 2017',
        description: 'Cinsault/Cabernet Sauvignon/ Grenache/Carignan',
        price: 78
      },
      {
        id: 'f20661a1-a2d7-455b-97c6-2953c8a0ee4b',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '9e99c8ab-a47e-4034-9a2e-04f92fb0fc98',
        name: 'Ixsir, Altitudes Red 2016',
        description: 'Cabernet Sauvignon/Syrah/Caladoc/Tempranillo',
        price: 498
      },
      {
        id: '8b56c481-587e-4610-94d7-07c797b1a01e',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: 'bcba1252-d623-4ed1-b5cb-bcd703216dd2',
        name: 'Chateau Kefraya 2015',
        description: 'Cabernet Sauvignon/Syrah/Carignan/Mouvèdre',
        price: 128
      },
      {
        id: 'c5b182be-a315-4e0d-830d-bac430c4c92e',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '60792b4e-e81a-409a-925c-3f34bdc83f65',
        name: 'Chateau Musar 2002',
        description: 'Cabernet Sauvignon/Carignan/Cinsault',
        price: 998
      },
      {
        id: '86f86ed9-b70a-4d55-932a-5f8a4d910f76',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '93c96589-c6ec-4d7b-b297-1ba54139a0a3',
        name: 'Chateau Ksara, Cabernet Sauvignon 2000',
        description: 'Cabernet Sauvignon',
        price: 778
      },
      {
        id: '11e39cbf-a506-4829-9b84-c00bb1051b64',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '50d7cb6d-97d7-4308-9964-7f187011266b',
        name: 'Chateau Ksara, Le Prieure 2018 Cinsault/Syrah/Mourvèdre/',
        description: 'Cabernet Sauvignon',
        price: 88
      },
      {
        id: 'ff78a3ac-376d-42e6-a130-9ee160e5b875',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '5f31173d-81d1-4ef9-9013-811b26407d28',
        name: 'Chateau St Thomas Pinot Noir 2013',
        description: 'Pinot Noir',
        price: 118
      },
      {
        id: '925658df-d07c-4c85-8e16-5d62a81d3f72',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '8f001f04-585c-4437-b87d-1f6bfdc2a562',
        name: 'Chateau Ksara, Cabernet Sauvignon 2017',
        description: 'Cabernet Sauvignon',
        price: 668
      },
      {
        id: '709d777e-255e-4240-a103-d79f4e0d8796',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '567b7257-80f0-4fbe-897e-7cef313117f0',
        name: 'Chateau Ksara, Cuvee du Troisieme Millenaire 2014',
        description: 'Petit Verdot/Cabernet Sauvignon/Syrah',
        price: 888
      },
      {
        id: 'f343459a-29ec-4a4b-8ece-501cda994287',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '2ebbaf2c-1018-4c1e-b833-5819599401b6',
        name: 'Domaine Piron, Beaujolais Villages 2017',
        description: 'Gamay',
        price: 488
      },
      {
        id: 'a9ca2487-ef04-4456-809a-8e4ca4f2b661',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: 'd3858da4-9890-443e-9342-ec0dc283bac1',
        name: 'Dufouleur, Bourgogne Pinot Noir 2018',
        description: 'Pinot Noir',
        price: 598
      },
      {
        id: '053cf6ad-c082-4450-ad3a-91ccd1c6b2b2',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: '1dced2f3-2895-4e5a-b180-649589325e82',
        name: 'Lagrave Martillac, Pessac-Leognan 2016',
        description: 'Mourvèdre/Grenache',
        price: 758
      },
      {
        id: '94ffba7f-c8b3-4124-81f2-0b861c34e33c',
        menuId: '0cefde16-6009-41a2-8423-a1f3f44c30d4',
        itemId: 'ec4cadac-abbd-49e6-a3c6-7741b7ca0b69',
        name: 'Domaine Grand Veneur, Cotes du Rhone',
        description: 'Les Champauvins 2015 Grenache/Syrah/Mourvedre',
        price: 628
      },
      {
        id: '3c53c162-b5e7-4f31-b257-8d14cbb54eb9',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: '1b54cf2c-88b9-459d-8a66-426cda76bbf6',
        name: 'VFC Wings',
        description: 'Vietnamese Fried Chicken Wings, Garlic, Sticky Fish Sauce, Chilli, Peanut',
        price: 108
      },
      {
        id: '98c94481-cec7-42a0-9b80-a72811f48db3',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: 'ea58af6e-c482-4fda-978d-33fdc71b73a8',
        name: 'Salt & Pepper Squid',
        description: 'Garlic, Black Bean, Chillies, Sriracha Mayo',
        price: 118
      },
      {
        id: '77175c8a-478a-4ef2-9ac3-5f0e14425e55',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: 'e5822d17-c865-4014-8222-aa0bd9694665',
        name: 'Lemongrass & Kaffir Chicken Skewers',
        description: 'Garlic, Ginger, Kaffir Lime, Chilli Salt, Calamansi Lime',
        price: 108
      },
      {
        id: 'de2d6e0c-8cc6-4a70-b32f-6145a49eff08',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: '9f9d6ae2-a97b-4d0b-9d19-d3990cc27db4',
        name: 'Prawn Crackers',
        description: 'Spicy Nuoc Cham Dip, Peanut',
        price: 48
      },
      {
        id: 'b5a77f47-2261-4041-bf71-956805c9a2bc',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: '1f3e8a9d-195e-4871-b671-862f29d056b7',
        name: 'Grilled Tiger Prawns',
        description: 'Garlic Butter, Chilli, Lime, Nuoc Cham',
        price: 118
      },
      {
        id: '4a2b2cf3-fa22-401a-91ec-2fd2cb064e01',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: '149f4f25-794b-430b-8954-8b9399ae64c7',
        name: 'Vietnamese Pizza',
        description: 'Crispy Rice Paper, Braised Short Rib, Sriracha Mayo, Fried Shallot, Peanut, Pickles, Vietnamese Herbs',
        price: 88
      },
      {
        id: '5c8ec958-3159-44fe-8de9-f1f51579a4e0',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: '2cbc592d-8840-41e4-9f6a-b57ab951788d',
        name: 'Roasted Bone Marrow',
        description: 'Brown Butter Nuoc Cham, Rau Ram Oil, Lime, Chinese Doughnut',
        price: 128
      },
      {
        id: 'fde91ea0-8eeb-441e-8508-60371808c820',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: '6df00c88-3b4b-4965-bc52-0d4dc70e9a31',
        name: 'Grilled Diver Scallop & Pomelo',
        description: 'Red Onion, Fried Shallot, Peanut, Nuoc Cham, Vietnamese Herbs',
        price: 128
      },
      {
        id: '5244b8d2-caf9-48a5-b20a-1f66da8baac4',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: 'd17156d7-f9e4-49c5-8802-721f41b9de40',
        name: 'Crispy Spring Roll',
        description: 'Pork, Mushroom, Vegetables, Coriander',
        price: 108
      },
      {
        id: 'c6d384e1-5bf8-4771-916d-c4fe725089b6',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: '10b02d83-57ad-4daa-88b4-bab452cacab2',
        name: 'Pho Roll',
        description: 'Beef, Fresh Rice Noodles, Garlic, Thai Basil, Scallion Oil, Peanut',
        price: 98
      },
      {
        id: '0ed69ec8-e610-45bb-929f-ef7b225a180a',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: '1738a4fb-dd44-4550-9ace-9072fab79bd4',
        name: 'Shrimp Rice Paper Roll',
        description: 'Vermicelli, Mango, Vietnamese Herbs, Avocado, Mint',
        price: 108
      },
      {
        id: 'fc3a9766-9edc-41a0-8c16-f2a49145ae8a',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: '55107c07-1185-427a-bd08-62a21b06813f',
        name: 'Summer Paper Roll',
        description: 'Market Vegetables, Vermicelli, Avocado, Vietnamese Herbs, Peanut Sauce',
        price: 88
      },
      {
        id: '6c20700d-5cae-4d6e-b4c4-135421e8b1e6',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: '49a6c7bb-60c5-45dc-a7a1-8f74d33d6161',
        name: 'Crispy Sole Fillet Paper Roll',
        description: 'Vermicelli, Tamarind, Kaffir Lime, Coriander',
        price: 108
      },
      {
        id: '1ba379f9-daa2-49d0-aef1-642e9ecedba6',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: '6e89900c-6229-464e-b64d-9c58ef19d0b0',
        name: 'Kaffir Chicken Cabbage Salad',
        description: 'Poached Chicken, Popcorn Chicken, Carrot, Cabbage, Vietnamese Herbs, Rau Ram, Peanut',
        price: 108
      },
      {
        id: '4ad0908f-a7b5-4c0d-8e2f-4d1baea32d30',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: 'e6993d21-2b7f-4b6b-8156-ecef5df98f4c',
        name: 'Shaking Beef',
        description: 'USDA Tenderloin, Watercress, Cherry Tomato, Salt & Pepper Lime Dip, Peanut, Jasmine Rice',
        price: 228
      },
      {
        id: '6f14444f-10ab-4787-b130-4ba54e6a27e4',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: 'fa4b22cc-b4e7-4bfc-8483-eb51c338be62',
        name: 'Caramel Black Cod',
        description: 'Caramel Fish Sauce, Garlic, Lemongrass, Lime, Ginger, Jasmine Rice',
        price: 218
      },
      {
        id: '38c349bd-5521-4734-b23e-e235bc5dd8eb',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: 'faf8cffa-a22e-40ef-89cb-e8ae342c3d72',
        name: 'Cha Ca Hanoi',
        description: 'White Sole Fillet, Turmeric, Dill, Red Onion, Vermicelli, Peanut',
        price: 188
      },
      {
        id: '7f38fd28-555b-43c0-a04d-89136f01d9a2',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: '8137bd14-1145-4fa7-bb55-b03eff7308e3',
        name: 'Bun Cha Hanoi',
        description: 'Grilled Pork Belly and Patties, Vermicelli, Pickles, Vietnamese Herbs, Chilli, Garlic',
        price: 188
      },
      {
        id: 'cf62862a-5b82-414d-98c7-5733e5552ed0',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: 'a9ffd723-aba9-4d35-b585-b9b8e91f64d8',
        name: 'Short Rib Lettuce Wraps',
        description: 'Pho-braised Short Ribs, Lettuce, Vermicelli, Spring Onion, Chilli, Peanut Sauce',
        price: 208
      },
      {
        id: '6739028b-c6a6-48d9-885f-b38c758ebc0f',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: 'b9cd39a5-7054-4b8f-aea0-2a99cb259b94',
        name: 'Wok-fried Morning Glory',
        description: 'Water Spinach, Fermented Tofu, Chilli, Baby Garlic, Sesame',
        price: 78
      },
      {
        id: 'd5b5c2ec-fb51-4a45-804f-6d015fe01343',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: 'c3129853-aeba-4f69-bc93-040e328efdc6',
        name: 'Charred Gai Lan in Curry',
        description: 'Chinese Broccoli, Coconut Sriracha Curry Sauce',
        price: 78
      },
      {
        id: '7790cc8f-3197-42d2-94ea-a17f99b63bbc',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: '57ca5b7a-8e0b-43bf-abed-690374b9fcb3',
        name: 'Vermicelli Noodles',
        price: 28
      },
      {
        id: '7f4e05bf-3149-4f4f-b7db-b0f9c3a456de',
        menuId: 'd8f5b56c-4c76-4dc8-8d77-d2213cbeeda1',
        itemId: 'e3e8b57f-ed4f-4df7-b736-681ca95c9d6c',
        name: 'Steamed Jasmine Rice',
        price: 28
      },
      {
        id: '21172a6d-7756-4b7c-8fa4-c9beb01cdae2',
        menuId: '4a39d9a1-e452-45b1-90ec-a1cb2a067367',
        itemId: 'aaeedf0f-b5b8-4903-981a-0bb7ca9671de',
        name: 'La Vache!',
        description: 'Trimmed entrecôte steak “La Vache!” with special house sauce, French fries and green salad with walnuts',
        price: 318
      },
      {
        id: '9a213128-9b44-4368-9aa7-44d7eb4e5991',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '9b6bc45f-fb03-4819-8df4-f555dd967d3c',
        name: 'CHICKEN SATAY',
        description: 'Chicken, coconut milk, red curry, peanut sauce',
        price: 98
      },
      {
        id: 'cd0e54ae-ec5d-46c2-9a0c-b3b735a206a7',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: 'bd3a42aa-65e3-4825-90f0-ee79939317a4',
        name: 'CRISPY CHICKEN WINGS',
        description: 'Chicken wings, turmeric, coriander, peppercorns, fried shallot',
        price: 98
      },
      {
        id: '8ac80167-0a06-4a5e-9953-3a9d5cf7afdd',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '5840ac54-5d43-49b7-963b-785922556251',
        name: 'VEGETARIAN SAMOSAS',
        description: 'Potato, curry powder, warm spices, mint yoghurt dip',
        price: 88
      },
      {
        id: 'a642db17-f45c-4063-8ec8-e177b5dc85ef',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: 'e7a39086-703b-4163-9e50-a81c2aace84d',
        name: 'TUM YAM GOONG',
        description: 'Lemongrass, lime leaf, galangal, prawns, chilli jam',
        price: 108
      },
      {
        id: '28e7d5bf-dde6-448f-a42a-e71469da1ae8',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '9cfe2019-70b2-45a5-b7cd-37523baf7049',
        name: 'SPICY PAPAYA SALAD',
        description: 'Green papaya, long bean, tomato, dried shrimp, peanuts',
        price: 78
      },
      {
        id: 'c7fd0da9-9da8-44aa-8413-773280efa406',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '2b4c454d-36af-4f88-83b1-b0ea9d648284',
        name: 'BANANA BLOSSOM SALAD',
        description: 'Raw banana flower, coconut cream, prawns, sweet basil, chilli jam',
        price: 108
      },
      {
        id: '5615f7e9-8541-471d-a71c-bdaa882a39a0',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '1dc63a0e-9faf-4fb2-b51e-74322d0124e4',
        name: 'GRILLED SQUID SALAD',
        description: 'Wood-grilled squid, Chinese celery, coriander',
        price: 128
      },
      {
        id: 'e6985905-1bed-449d-9520-3db8c8382262',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '868f8457-ddc7-4e28-a496-6e8f98320168',
        name: 'CRAB FRIED RICE',
        description: 'Jasmine rice, charred corn, crab, spring onion, seafood sauce',
        price: 138
      },
      {
        id: '80d989cd-b979-4269-b271-5ecd10d36e04',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: 'dbc23f2a-a1d3-4769-9550-5ba87bd554e0',
        name: 'SOFT-SHELL CRAB PAD THAI',
        description: 'Noodles, tofu, bean sprout, egg, radish pickle, soft-shell crab',
        price: 148
      },
      {
        id: '406b01ff-a54d-466a-ab09-3759a4991560',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: 'a3e4ea50-f899-48be-9069-88ae9dbeb8be',
        name: 'PAD SEE EW',
        description: 'Wok-charred rice noodles, chicken thigh, sweet soy',
        price: 128
      },
      {
        id: '9aaa532b-0378-495e-9fac-e3670dc9d9e9',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '67c89ad4-101c-47ab-a544-d83c1d950699',
        name: 'CHICKEN GRAPOW',
        description: 'Steamed rice, wok-fried chicken, holy basil, garlic, chilli, Japanese egg',
        price: 148
      },
      {
        id: 'a09f6fd8-d717-49bd-baa3-d08d7cd5b979',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '59d8b15d-f6ce-468e-afd6-8df9badb4158',
        name: "DRUNKARD'S NOODLES",
        description: 'Spicy wok-fried rice noodles, squid, prawns, holy basil, kaffir lime leaf',
        price: 148
      },
      {
        id: '3320f7ad-9f60-4b3e-953c-33b44b9ff9c5',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '2f3f860e-cce5-47c1-b5ad-47d32655b87b',
        name: 'STIR-FRIED MORNING GLORY',
        description: 'Morning glory, garlic, chilli',
        price: 78
      },
      {
        id: 'd00e7cb4-0b2a-46f5-84f6-9b2a8129453e',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '680c9c6e-ca17-4d14-81c1-cfd3c60f3f61',
        name: 'WOK-FRIED MIXED VEGETABLES',
        description: 'Garlic, oyster sauce, tofu',
        price: 78
      },
      {
        id: '24e99f23-9cb4-402d-9059-8983c121e24f',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: 'a3f4be71-ffa3-474c-9200-122f3a135c35',
        name: 'WOK-FRIED BROCCOLI',
        description: 'Garlic, chilli, peppercorns',
        price: 78
      },
      {
        id: '586a5701-cce1-4226-ad56-524a9edd9ce8',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '7b70b67b-027b-4dd5-ae87-e3d75f9fb818',
        name: 'JASMINE RICE',
        price: 28
      },
      {
        id: 'ce47a0b8-2e44-4855-b115-5f66bd84754f',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '083236f3-1d86-43fa-9e29-b944dbe98662',
        name: 'STICKY RICE',
        price: 18
      },
      {
        id: '253f0b6f-ca2f-49c9-9271-2de009815746',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: 'f1565608-7d48-457d-aae7-de9d4a28df23',
        name: 'PORK LARB',
        description: 'Minced pork, roasted rice powder, herbs',
        price: 128
      },
      {
        id: '3ca6daf2-4208-41f3-b63d-58345467c5bc',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '976104b9-58c6-433b-bb7e-a09713ef7149',
        name: 'CHARGRILLED PORK JOWL',
        description: 'Marinated pork jowl, spicy dipping sauce',
        price: 138
      },
      {
        id: 'b6922a5c-3c92-425f-83c2-cf83b0967a44',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '789407c4-4219-4c72-8f0d-9d4d9765562c',
        name: 'CHARGRILLED CHICKEN',
        description: 'Marinated chicken leg and thigh, spicy dipping sauce',
        price: 138
      },
      {
        id: '0c744322-234a-475f-9844-79b6d984e5a5',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '8be32b78-78ba-4b0d-b045-ed276fa0b587',
        name: 'GREEN CHICKEN CURRY',
        description: 'Organic chicken, coconut cream, pea eggplant, basil',
        price: 158
      },
      {
        id: 'd65ad30e-d0f8-4c7d-932d-ef68f5783970',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '188125ef-0078-4b2d-af53-ad70804f8b81',
        name: 'YELLOW CHICKEN CURRY',
        description: 'Organic chicken, carrot, potato, white onion, fried shallot, coconut cream',
        price: 158
      },
      {
        id: 'd006d9c5-4711-4aaf-95d7-724020b407e4',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '377c9d78-6810-4921-9921-7a346541df14',
        name: 'BEEF BRISKET PENANG CURRY',
        description: 'Beef brisket, warm spices, burnt pineapple, coconut cream, roasted peanuts',
        price: 178
      },
      {
        id: 'b36ff12b-329a-4abb-8e73-21dac5b01c9d',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '1fb04e67-bcfd-4883-a2ad-f9ed2a13a13e',
        name: 'LAMB MASSAMAN CURRY',
        description: 'Lamb shoulder, massaman spice, new potato, crispy shallot',
        price: 188
      },
      {
        id: '96861857-d01b-435f-bdeb-abf943f47818',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '1eba1a45-29fd-442f-9683-a184d3c75e75',
        name: 'VEGETARIAN RED CURRY',
        description: 'Market vegetables, red curry, pressed tofu',
        price: 148
      },
      {
        id: '3d8bdd4f-62e2-4f49-bec5-56cb851de3ce',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: '5c8d5854-6006-4837-bb22-19735c039a65',
        name: 'COCONUT ICE CREAM',
        description: 'Coconut ice cream, salted palm sugar caramel, peanuts',
        price: 68
      },
      {
        id: 'd365fadc-e11b-4096-ab7e-70a3daf749ba',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: 'd2677f34-d63e-47ad-9744-2969d9b4b67a',
        name: 'MANGO STICKY RICE',
        description: 'Sweet sticky rice, mango, crispy yellow mung bean',
        price: 68
      },
      {
        id: 'b1b8cf51-7c8b-467c-bd8b-ad2701a7ff2f',
        menuId: 'e1b244f3-f410-41e4-b33e-ab0c4f6f18b5',
        itemId: 'dace5694-08ec-41ff-9608-617b3888a58a',
        name: "Chef's Choice",
        description: 'Let Chef send you his favourites, portioned to share',
        price: 268
      },
      {
        id: '498598f8-b9af-4154-9251-af2056fc65e9',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: '22de9337-67fb-440f-91bd-4373932ba284',
        name: 'CHOPPED SALAD',
        description: 'Red Radicchio / Endive / Radish / Boiled Egg / Grape Tomato / Salami Milano / Provolone / Gaeta Olive / House Dressing',
        price: 178
      },
      {
        id: 'a1185edb-32b2-4991-9e3d-dc1a5493f23a',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: '4125446f-cb55-41bd-9b97-4f5125a81945',
        name: 'MIXED GREENS SALAD',
        description: 'Mixed Greens / Parsley / Mint / Black Pepper / House Dressing / Red Onion',
        price: 108
      },
      {
        id: 'ff2cd3f9-5686-49a7-8676-1535732f906f',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: '26ceb258-25a4-472b-92c1-9b1c32f3efc5',
        name: 'PROSCIUTTO DI PARMA',
        description: '+ Buffalo Mozzarella 48',
        price: 158
      },
      {
        id: 'dcade7e7-d197-48e4-ac5d-739c53c98eeb',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: '27f5f0bd-4e5d-44e8-9557-b8f3e46595fb',
        name: 'MEATBALLS',
        description: 'Braised In Tomato Sauce',
        price: 138
      },
      {
        id: 'add1f6cb-eab5-4942-976f-03c05a3eaefb',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: '6eb70fbd-61e2-48d3-856b-77e9bfbfc453',
        name: 'MARINATED BEET SALAD',
        description: 'Beetroots / Red Onion / Gaeta Olives / Soft Boiled Egg / Ricotta Salata / White Anchovies',
        price: 178
      },
      {
        id: '9aa04415-c10f-4d91-9276-8175465a5af3',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: '9da2fe5d-5524-46ae-98b8-c91d6972e9da',
        name: 'ARUGULA SALAD',
        description: 'Red Onion / Dried Figs / Fresh Pear / Pancetta / Gorgonzola / House Dressing / Garlic / E.V. Olive Oil',
        price: 148
      },
      {
        id: '0ccac3ca-23ce-4071-995b-0b559e908cf5',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: '82c90154-67bd-40dd-9aa4-8096b366a81b',
        name: 'CHICKEN WINGS',
        description: 'Red Onion / Lemon / Fresh Chili / Mint / E.V. Olive Oil',
        price: 148
      },
      {
        id: '5fcdad47-3673-4062-a135-e04bfcdcb134',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: '4b1fa6ad-28ec-49a5-bcbc-59823b8be6a2',
        name: 'MARINARA',
        description: 'Tomato Sauce / Oregano / Garlic / Pecorino / E.V. Olive Oil',
        price: 118
      },
      {
        id: 'ec9ff819-2dd9-4444-a476-684adf125837',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: 'e1334b50-418d-42b5-9031-24af5f960a33',
        name: 'MARGHERITA',
        description: 'Tomato Sauce / Mozzarella Di Bufala / Basil / Pecorino / E.V. Olive Oil',
        price: 178
      },
      {
        id: '00a001ba-5abc-4b33-a075-cbdeda407d20',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: '9182bd87-5206-42bc-9ac1-905b33595e11',
        name: 'SOPPRESSATA PICCANTE',
        description: 'Tomato Sauce / Fior Di Latte / Fresh Chili / Garlic / Oregano / Pecorino / E.V. Olive Oil',
        price: 198
      },
      {
        id: 'f62ef31c-d6b5-4f4b-a7c2-6ff90ebfe3f8',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: 'd4792806-a89b-416b-ae33-9143594758cf',
        name: 'MEATBALL',
        description: 'Tomato Sauce / Fior Di Latte / Pecorino / Basil / E.V. Olive Oil',
        price: 198
      },
      {
        id: 'ed0018e1-2256-4fba-9258-7880341e2e9a',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: 'd2bd8d2b-297f-444c-87c9-d63f5977838a',
        name: 'QUATTRO FORMAGGI',
        description: 'Gorgonzola / Provolone / Fior Di Latte / Pecorino / Parsley / Chili Oil',
        price: 198
      },
      {
        id: '8b2eb3e2-4a29-4b1d-be03-a2a80d35a15d',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: '7169de05-cc2a-4675-9f8e-30f2aa7425cf',
        name: 'CREMINI MUSHROOM',
        description: 'Fior Di Latte / Italian Sausage / Garlic / Thyme / Pecorino / Gaeta Olives / E.V. Olive Oil',
        price: 198
      },
      {
        id: '25d28644-d7e6-4277-b8e2-aa9f7e20c65a',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: '3c9de4eb-3ba2-4106-8d6e-ab51ae30d927',
        name: 'BRUSSELS SPROUT',
        description: 'Fior Di Latte / Brussels Sprouts / Pancetta / Garlic / Pecorino / E.V. Olive Oil',
        price: 198
      },
      {
        id: '0faca297-f347-442a-933f-0ba6395e044c',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: 'f0985c7b-7984-444b-ac78-8309770c01ff',
        name: 'PROSCIUTTO DI PARMA',
        description: 'Fior Di Latte / Fresh Oregano / Pecorino / E.V. Olive Oil',
        price: 228
      },
      {
        id: 'bfbc083c-c412-4968-a0b8-a3000c480eb4',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: 'c8ffa579-212f-44ce-8f2e-0fcfeeb9c112',
        name: 'BRESAOLA',
        description: 'Arugula / Fior Di Latte / Pecorino / E.V. Olive Oil',
        price: 218
      },
      {
        id: '89f25e6b-8d4d-456e-a5c3-33c103e9a19b',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: 'a3310c65-8690-4499-b60e-3d5d550d7d6a',
        name: 'COLATURA DI ALICI',
        description: 'Mozzarella Di Bufala / Red Onion / Grape Tomato / White Anchovy / Chili Flake / Gaeta Olive / E.V. Olive Oil',
        price: 198
      },
      {
        id: '4acf0bbe-2780-4250-895e-bffa291efb7e',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: '12af6c3d-a12e-40af-a740-42ac322b28ec',
        name: 'TIRAMISU',
        price: 78
      },
      {
        id: '92b3eca0-01e1-4d0d-99c8-726385c15cdb',
        menuId: '1f42d370-ffa2-47e8-b0fc-9e3af1d92663',
        itemId: 'e943f49d-22f9-4b20-80fb-8f5ac457e6f4',
        name: 'NUTELLA CALZONE',
        description: '(with marshmallow)',
        price: 98
      },
      {
        id: '1f1a040f-2a51-403a-acba-26b6ada2919a',
        menuId: '2fc1b9e3-84b2-41ff-bd09-f8430bd996a2',
        itemId: '05e1f999-94d9-413f-9d33-c63b2cba151c',
        name: 'Set Lunch',
        description: 'CHOICE OF MARINATED BEET SALAD, MIXED GREENS SALAD OR ROASTED SWEET CORN & A PIZZA',
        price: 128
      },
      {
        id: '9d31e707-4417-47cc-b0a7-959e0a204fec',
        menuId: '2fc1b9e3-84b2-41ff-bd09-f8430bd996a2',
        itemId: 'e1634cb7-a6a0-48e4-94d1-e95810599d81',
        name: 'MARINARA',
        description: 'Tomato Sauce / Oregano / Garlic / Pecorino / E.V. Olive Oil'
      },
      {
        id: '9de760ac-a51f-4e9a-915b-edf233cc6bd0',
        menuId: '2fc1b9e3-84b2-41ff-bd09-f8430bd996a2',
        itemId: '034130a3-5189-4604-9e41-627b1638b17f',
        name: 'MARGHERITA',
        description: 'Tomato Sauce / Mozzarella Di Bufala / Basil / Pecorino / E.V. Olive Oil'
      },
      {
        id: 'b2be0e58-e35e-4cac-9028-4abf7bc1000f',
        menuId: '2fc1b9e3-84b2-41ff-bd09-f8430bd996a2',
        itemId: '918ad3f2-d8ff-424c-a325-88037b559822',
        name: "ALL'UOVO",
        description: 'Fior Di Latte / Sunnyside Farm Egg / Smoked Pancetta / Pecorino / Basil / Chili Oil'
      },
      {
        id: 'a825d0c1-415b-4c92-8d20-e59741a6f78e',
        menuId: '2fc1b9e3-84b2-41ff-bd09-f8430bd996a2',
        itemId: '9943a68b-87c4-4e3a-b099-6c93eccb2519',
        name: 'SOPPRESSATA PICCANTE',
        description: 'Tomato Sauce / Fior Di Latte / Fresh Chili / Garlic / Oregano / Pecorino / E.V. Olive Oil'
      },
      {
        id: '2559673f-14bc-439d-bd15-0defab251a07',
        menuId: '2fc1b9e3-84b2-41ff-bd09-f8430bd996a2',
        itemId: 'bff87612-ef42-4a37-9754-43d56db806e6',
        name: 'BRUSSELS SPROUT',
        description: 'Fior Di Latte / Brussels Sprouts / Pancetta / Garlic / Pecorino / E.V. Olive Oil'
      },
      {
        id: 'f63ba325-0e9f-4acd-bba8-872edb7dade4',
        menuId: 'e7964f59-297b-44c4-afd5-d4c7df320113',
        itemId: '4d7a1c95-428e-49ce-8934-d08ddf51bcee',
        name: 'Set Brunch',
        description: 'CHOICE OF CAPRESE SALAD, ROASTED SWEET CORN OR MIXED GREENS SALAD & A PIZZA',
        price: 168
      },
      {
        id: '85024b68-4dfd-4eab-9cbb-f3ff6eeb7dd3',
        menuId: 'e7964f59-297b-44c4-afd5-d4c7df320113',
        itemId: 'f5b7e0b9-3eed-4513-b996-29417ca0d5fb',
        name: 'MARGHERITA',
        description: 'Tomato Sauce / Mozzarella Di Bufala / Basil / Pecorino / E.V. Olive Oil'
      },
      {
        id: 'b4846f81-5bb8-4b56-8e89-95c898d74a2e',
        menuId: 'e7964f59-297b-44c4-afd5-d4c7df320113',
        itemId: '8d7beab8-ac3c-428d-a121-56aaddef9e00',
        name: "ALL'UOVO",
        description: 'Fior Di Latte / Sunny Side Farm Egg / Smoked Pancetta / Pecorino / Basil / Chili Oil'
      },
      {
        id: '9289fa5a-5b81-4f28-bb2e-b29701d6e3ba',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '652798f0-bcfd-4592-ac12-009390980ff9',
        name: '#1 CLASSIC BURGER',
        description: '5oz beef patty, lettuce, tomato, pickles, Circus sauce',
        price: 88
      },
      {
        id: '85fd5ef7-f594-4bf9-95dc-06d561f27055',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'd17f3f76-cc3e-47ca-9a10-af2d90cf6168',
        name: '#2 AMERICAN CHEESEBURGER',
        description: '5oz beef patty, Wisconsin cheddar, sliced onion, pickles, Circus sauce',
        price: 98
      },
      {
        id: '58128ff8-2796-475e-9960-2ae7eb5c95aa',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '63a9a0c9-938b-41a8-b999-7719f8bdecab',
        name: '#3 DELUXE',
        description: '5oz beef patty, bacon, savory onion, Wisconsin cheddar, Circus sauce',
        price: 108
      },
      {
        id: 'c37292b8-c147-4c69-9650-42f2e8b85a85',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '6f044ec1-8fe8-47ed-b628-5a5c1aa7ece7',
        name: '#4 DOUBLE',
        description: 'Double 5oz beef patties, Wisconsin cheddar, sliced onion, Circus sauce',
        price: 128
      },
      {
        id: 'f7bfd447-e33a-4ac2-b225-3e1282ca7acf',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'c6aef8ff-3a8e-41c5-b255-058571d49c73',
        name: '#5 VEGGIE BURGER',
        description: '5oz veggie patty, pickles, tomato, lettuce, miso mayonnaise',
        price: 98
      },
      {
        id: '651d851c-bfe6-4c70-b22f-3933547ac62b',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '6ccf2c9e-5161-4004-8fcd-6fd28ad1ee0c',
        name: '#6 SPICY CHICKEN',
        description: 'Buttermilk-brined chicken thigh, jack cheese, pickles, jalapeño, sriracha, ranch dressing',
        price: 98
      },
      {
        id: '440d2dc2-96dd-4ae8-ae3c-cd4b483c05d7',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'c69a2e88-08e8-4717-b4a7-98b41f24465d',
        name: '#7 FISH FILLET',
        description: 'Crispy fish fillet, Wisconsin cheddar, tartar sauce, pickles',
        price: 98
      },
      {
        id: '36503a26-78cf-450b-a97c-69573eaa871e',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'cb812b65-c84d-4183-92e2-8d3b81042d9d',
        name: 'FRIES',
        price: 38
      },
      {
        id: 'a6762b6d-5ce9-49dd-ba2f-5da7394b8c50',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '3ee64f11-7d20-484d-ad28-3f544a6a635a',
        name: 'LOADED FRIES',
        description: 'Bacon, cheddar, sour cream, spring onion',
        price: 58
      },
      {
        id: 'a789c8df-dc3d-44f3-83bb-3af4f539f9e4',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'ccf82a9a-7d79-445c-98f3-845975bfe80e',
        name: '1/2 DOZEN WINGS',
        description: "Your choice of Frank's RedHot sauce, smoky BBQ or honey garlic",
        price: 88
      },
      {
        id: 'a348a9f3-3a45-4312-a493-8dfeb2e97864',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '3b663ec2-32e6-4b17-b36a-565b034a5996',
        name: 'JALAPEÑO POPPERS',
        description: 'Cream cheese filling, ranch dip',
        price: 78
      },
      {
        id: 'd780c36c-7886-47fd-9ebb-98acca23cc46',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'a4b4592b-300f-4960-ad14-77697f13c7c0',
        name: 'ONION RINGS',
        price: 38
      },
      {
        id: '45e978fc-e3ee-44d8-8d9d-7104e7663a6d',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'f9aec571-eac0-4c56-931e-ee93c7967918',
        name: 'MILKSHAKE',
        description: 'Vanilla/ Strawberry/ Chocolate/ Salted Caramel',
        price: 68
      },
      {
        id: '7f8351c8-99d2-42d3-a6bb-93ce88f4030b',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '8d1e1591-c484-4c37-88e6-cdce5d72990f',
        name: 'CANDY SHAKE',
        description: 'M&Ms/ Kit Kat/ Oreos/ Reese’s Pieces',
        price: 78
      },
      {
        id: 'c38e4f8e-e4c6-4cdc-a044-ece81c855635',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'fcf52dde-a9b4-4e8f-9f5c-6325faa25a30',
        name: 'ADD FRIES AND ANY HOUSEMADE REFRESHMENTS',
        price: '+55'
      },
      {
        id: '4bf3f7e4-d7f9-4137-8cc2-237799c42691',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'd22e9136-bcc7-498b-a373-a2c9304848d1',
        name: 'ADD FRIES AND A BEER',
        price: '+75'
      },
      {
        id: '7a8aece4-0cd8-4c68-b135-efe20957dc4a',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'becd0cfc-93d1-4d7e-bb71-6f1e131d03f2',
        name: 'ADD FRIES AND A MILKSHAKE',
        price: '+85'
      },
      {
        id: '8ca8830a-77c7-4e30-8fa7-d51025bdcc79',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'ab9ebb31-4c1c-415c-a31e-78033e01de6d',
        name: 'CRISPY BACON',
        price: 18
      },
      {
        id: 'de85dcde-7ca2-4471-aea1-b9b31222dd56',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '53367d1e-43ff-4eed-b294-d99b67945320',
        name: 'SAVORY ONION',
        price: 8
      },
      {
        id: '131a07ba-67fe-4f7b-80bc-9b9bdeb9ebdc',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '56f53c63-e7f2-4c95-a806-c4e35d68e2ca',
        name: 'JACK CHEESE',
        price: 18
      },
      {
        id: 'cdbe9999-cb3e-45f0-b334-987355f670a0',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'fa743393-6561-45df-98cd-046df20c2a49',
        name: 'SLICED PICKLE',
        price: 8
      },
      {
        id: '8e195e96-7c76-4b35-bd55-102fbb2c4f36',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '72575858-461c-483d-b815-41f374f15447',
        name: 'FRIED EGG',
        price: 8
      },
      {
        id: '418d850a-62bb-442e-bda0-b3662ae09c65',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'faf11fc5-8cb0-4eaf-9810-eccf62477058',
        name: 'EXTRA PATTY',
        price: 58
      },
      {
        id: '5f630db7-b20c-4c05-922d-a4bb0833b157',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '8fdf3579-c282-48a9-a57c-22bd6076ba52',
        name: 'BLUE CHEESE SAUCE',
        price: 18
      },
      {
        id: 'c6217d86-a00b-4d64-a80b-268574bb291e',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '2eb50e62-5454-4544-8767-a51da581e57a',
        name: 'JALAPEÑOS',
        price: 8
      },
      {
        id: 'b713d3ce-49c9-4c38-ada3-85865014d98d',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '933d8b59-4a3c-4c9a-ae90-4594412aa4b3',
        name: 'HOUSE-MADE REFRESHMENTS',
        description: 'Passion Fruit Pineapple Soda, Lemon Soda, Cola,Brewed Ice Tea, Fresh Lemonade, Arnold Palmer, Add a shot +35',
        price: 48
      },
      {
        id: 'f39ba32f-febc-4fca-ab30-b798304d2db9',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '9c2323e3-c9ff-4e8d-93e6-cd30923a6964',
        name: 'WINE',
        description: 'Glass/Bottle',
        price: '48/228'
      },
      {
        id: '94d97d91-8be4-4283-9c6d-733700ad387a',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'de76aad6-0ddc-4d17-9681-5c258e128d58',
        name: 'BEER',
        description: 'Modela Negra / Budweiser',
        price: 58
      },
      {
        id: 'df95cd1a-7b40-4694-a1d8-0bfcaaef4be6',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '5bc1d63f-59ae-4219-9574-df0b7b6d6765',
        name: 'STILL/SPARKLING WATER',
        description: '550 ml',
        price: 28
      },
      {
        id: '9df1c0d3-86ea-46ac-aee1-10838a0880f3',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'ec2de584-e117-4a27-acb5-6d2df8b29cb6',
        name: 'Breakfast Panini',
        description: 'Pancetta, Two Fried Eggs, Chilli Mostarda, Mozzarella',
        price: 98
      },
      {
        id: '25b4e364-faa1-4a38-9b6b-cdbb0d1694e7',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'ab3bf25b-ee1e-4351-a26a-8db8cd7c9e4e',
        name: 'San Frediano',
        description: 'Arugula, Prosciutto, Buffalo Mozzarella',
        price: 98
      },
      {
        id: '4aa443ae-4d6f-40e1-a2c4-31030d393e7b',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '83812dfe-30b2-488c-9070-ef4af35aba56',
        name: 'Santo Spirito',
        description: 'Chicken Cutlet, Coppa, Mozzarella, Tomato, Basil Spread',
        price: 98
      },
      {
        id: '1508b090-5c3d-44d2-9bb5-f352bc8fd3fc',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '88422fce-a8ee-4868-838d-9cb0814989df',
        name: 'San Niccolo',
        description: 'Coppa, Fennel Salami, Salami Milano, Provolone, Tapenade',
        price: 98
      },
      {
        id: '000c9e18-42e9-493f-97dc-81880b268a1d',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '6b9e8111-b89b-47ff-945e-a1dbd17ef632',
        name: 'Santa Maria',
        description: 'Mortadella, Pickled Peppers, Taleggio',
        price: 88
      },
      {
        id: '99eccbaa-6aa6-428e-b021-c131eeda995f',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'f944e457-7d14-4d2e-a33b-9a447528f4eb',
        name: 'Mercato Centrale',
        description: 'Chickpea Fritter, Calabrian Chili, Pickled Eggplant',
        price: 88
      },
      {
        id: '306658ea-385b-438d-95e0-e17f4a1b030b',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'f6c50983-09c2-46b6-b968-6b15ac95979c',
        name: 'Assortito',
        price: '148/178'
      },
      {
        id: 'a712a81a-5f10-4ace-97c9-c94dcd0c6f9a',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '16d22754-75df-4048-bb01-699e543999bf',
        name: 'Prosciutto Di Parma',
        price: 98
      },
      {
        id: '5b2ad7f2-6d39-4319-aa80-94de193ca039',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '5db59a6e-b6a3-424d-844a-bcf1f9c49565',
        name: 'Bresaola',
        price: 98
      },
      {
        id: '589e47f8-ee1e-415b-a1cc-d38a6236f0c3',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'd0d78f48-281d-49d9-bc30-d9ef711c67a3',
        name: 'Soppressata',
        price: 78
      },
      {
        id: '5c5ec610-46e9-4286-8de8-e8e814a57335',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: 'bb91df59-bf3b-472b-b559-c2bce12b6858',
        name: 'Coppa',
        price: 78
      },
      {
        id: '41bfa653-a1c7-4853-a9bf-06ab98ce6e0c',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '0b556137-f989-4190-a331-873e9b734edc',
        name: 'Assortito',
        description: 'Gorgonzola, Parmesan, Taleggio, Asiago',
        price: '158/198'
      },
      {
        id: '555f1fe8-8922-4f71-aeb0-a864ce666cc0',
        menuId: 'e0223415-31db-4d4a-9299-488d04ec4402',
        itemId: '35b4ae5d-50a0-46dc-82a8-1d858a4b307f',
        name: 'FORMAGGI E SALUMI MISTI',
        price: 198
      },
      {
        id: 'adf4e856-b827-4950-9124-2b0c328438c4',
        menuId: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        itemId: '88a2e019-a241-46b7-8ab4-1239bf9709a1',
        name: 'Cinzano, Veneto NV',
        price: '88/358'
      },
      {
        id: 'd7c6cb0a-7cd3-4851-8d81-8b4b57d41a03',
        menuId: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        itemId: '3610652d-9bb7-444c-867c-fc9771bd5a18',
        name: 'Zonin ‘20 Ventiterre’ Pinot Grigio, Venezia 2019',
        price: '88/358'
      },
      {
        id: 'd4f14f83-b90a-464e-bc48-ac98d45d37d2',
        menuId: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        itemId: '82126716-a188-49a9-8df6-be79638bbaee',
        name: 'Santa Sofia ‘Montefoscarino’ Soave Classico, Veneto 2017',
        price: '98/398'
      },
      {
        id: '08d3ada6-8d77-4a02-ab28-d20229897d63',
        menuId: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        itemId: '1cb13adc-fac2-4958-a4fe-1e2eb6ab0a07',
        name: 'Tenute Nicosia ‘Fondo Filara’ Grillo, Sicily 2018',
        price: '118/488'
      },
      {
        id: 'de87a971-200d-4c78-8d05-3fb8a720e766',
        menuId: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        itemId: '71df3abb-1e31-480c-958b-416942d596a6',
        name: 'Zeni ‘Vigne Alte’ Bardolino Chiaretto Classico, Veneto 2018',
        price: '108/448'
      },
      {
        id: 'ea081e2e-7d3c-4a44-95c7-70640ae4c82b',
        menuId: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        itemId: 'fafcfea9-5cc4-43a0-9d49-7f19d7a14833',
        name: 'Etrusca Chianti Magnum (1.5L), Tuscany 2018',
        price: '98/788'
      },
      {
        id: '91838c4c-6504-4200-8a2d-d1efd0f53629',
        menuId: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        itemId: '2878612d-a8de-49ed-8491-cddd801dbc79',
        name: 'Salgari ‘Le Pesse di Saga’ Valpolicella Classico Superiore, Veneto 2014',
        price: '108/448'
      },
      {
        id: '07645125-c14f-40c5-a937-7e48b9387203',
        menuId: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        itemId: '264c0459-b2dd-4a80-bbb1-3862b5688cb1',
        name: 'Primitivo ‘Sasseo’ Masseria Altemura, Puglia 2017',
        price: '108/448'
      },
      {
        id: '98ce7605-25d8-4604-9aba-29075097d2a9',
        menuId: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        itemId: 'd90647b6-ac3e-434b-a962-25d0d37fafac',
        name: 'Nero d’Avola ‘Corale’ Settesoli, Sicily 2018',
        price: '88/358'
      },
      {
        id: '27124cc1-0b57-4c7c-b33e-892dea04665e',
        menuId: '7bb3eb0a-625c-4437-abbb-8ffd323265fe',
        itemId: 'ba79b498-ae86-4ae1-98bf-2461f6e7df12',
        name: 'Farnese ‘Casale Vecchio’ Montepulciano d’Abruzzo, Abruzzo 2017',
        price: '138/578'
      },
      {
        id: 'c6afc4db-a805-4078-a6f0-415c01263721',
        menuId: '0d180b38-3e5f-464d-9286-d2f5150b2cb0',
        itemId: '922a1009-bb05-43cc-82f9-afce8127a5c9',
        name: 'Americano',
        description: 'Campari, Sweet Vermouth, Soda Water',
        price: 88
      },
      {
        id: '911bec71-d315-4137-bd7f-8f29473b3772',
        menuId: '0d180b38-3e5f-464d-9286-d2f5150b2cb0',
        itemId: 'c7630f3f-af44-45e4-af0f-6b1186606792',
        name: 'Negroni',
        description: 'Gin, Campari, Sweet Vermouth',
        price: 88
      },
      {
        id: '4e28f318-ba7b-4ea4-b183-ceb5047e2b83',
        menuId: '0d180b38-3e5f-464d-9286-d2f5150b2cb0',
        itemId: '3504ac08-7c34-42c5-89a2-afd352c8e0ab',
        name: 'Il Presidente',
        description: 'Campari, Prosecco, Aperol, Ginger Ale',
        price: 88
      },
      {
        id: 'd8fef775-35d0-4f69-b2ac-6dbe4a89f279',
        menuId: '0d180b38-3e5f-464d-9286-d2f5150b2cb0',
        itemId: '035ceeaa-215a-4ae5-8239-eab80fa2b3a3',
        name: 'Aperol Spritz',
        description: 'Prosecco, Aperol, Soda Water',
        price: 88
      },
      {
        id: 'b5da2413-567c-470f-91fd-384f1664adf0',
        menuId: '0d180b38-3e5f-464d-9286-d2f5150b2cb0',
        itemId: '49323d1b-e729-424c-a540-f06b08f7dfb2',
        name: 'Espresso Martini',
        description: 'Absolut Vodka , Kahlua, Nitro Coffee',
        price: 108
      },
      {
        id: '33b06b0b-a2e8-4ef6-9e94-f02d503b139f',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        itemId: '866b2cbb-e340-47dd-942a-d3140bfdb054',
        name: 'Espresso Ristretto',
        price: 22
      },
      {
        id: '90a45599-d93c-46f2-81ad-4b7d01befb9a',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        itemId: '71918738-b37d-4ce7-92f8-1e50bd6234dc',
        name: 'Espresso',
        price: 22
      },
      {
        id: 'c7d1c8dd-ee2b-4887-9230-05a451a08586',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        itemId: 'a139a31f-244a-4305-bae3-cc64b9cf809f',
        name: 'Doppio',
        price: 24
      },
      {
        id: 'cf6932bc-8b45-4925-a3a0-aad592b3ee21',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        itemId: '244de314-0b65-4468-ac94-7c4c01addba7',
        name: 'Espresso Macchiato',
        price: 28
      },
      {
        id: '786bec21-b70c-48d2-a940-ae415d4415f9',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        itemId: '40d9be9f-7cbc-441f-9e2f-d856b5da98a8',
        name: 'Caffè Americano',
        description: '8 / 14 oz',
        price: '28 / 30'
      },
      {
        id: '161e6023-9a17-468c-8acf-29320eac842b',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        itemId: 'd03ca183-6b8a-49f1-a1b6-b81a9cb6f137',
        name: 'Espresso Lungo',
        description: '8 / 14 oz',
        price: '30 / 32'
      },
      {
        id: '1119943b-3885-4669-a5b6-2e6714bb705a',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        itemId: 'b17de559-af13-4b3e-bfde-f5926064164c',
        name: 'Cortado',
        price: 32
      },
      {
        id: '98ff6ca1-12c5-4729-8abd-d070fc39aa64',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        itemId: '75f14585-e7d0-4522-99f0-e91592adac30',
        name: 'Caffè Latte',
        description: '8 / 14 oz',
        price: '34 / 38'
      },
      {
        id: 'f92b01fc-6fb0-40f2-af9b-ca467be1529a',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        itemId: 'ba3ecfb7-6730-423d-a3ba-e74362375255',
        name: 'Cappuccino',
        description: '8 / 14 oz',
        price: '34 / 38'
      },
      {
        id: 'd6a2242b-650c-4df5-914a-b7ebf4e631e5',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        itemId: '1573b37d-2a92-4fe7-a430-07e74a6504c3',
        name: 'Flat White',
        description: '8 / 14 oz',
        price: '34 / 38'
      },
      {
        id: '7dce51e2-896d-4fd0-8353-3ea320055e79',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        itemId: '0692546e-65db-4b15-9d8f-e77ee2d0e015',
        name: 'Caffè Mocha',
        description: '8 / 14 oz',
        price: '36 / 38'
      },
      {
        id: '3c1368d0-d0ea-4a6e-8cb0-342d8df655eb',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        itemId: 'dff75e38-3885-481e-9acd-5169ced1abe6',
        name: 'Caffè Corretto',
        price: 58
      },
      {
        id: '4e14a273-95aa-41c3-a40a-eb34726ae075',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        itemId: '6e4e3779-5809-4173-a65f-5bd3c238b696',
        name: 'Iced Espresso/Americano',
        price: 32
      },
      {
        id: 'b87a7472-b4a0-495e-bbf9-99e674a8f6e9',
        menuId: 'b6108a42-d05b-4117-b93b-2d0e5d766b34',
        itemId: '528d5ee4-b7e6-421d-8184-4df75574d595',
        name: 'Iced Latte',
        price: 36
      }
    ],
    menu_section_item: [
      {
        id: '51c6af10-7a64-49bd-8158-2adfdd2362b8',
        menuItemId: '96988253-7ae0-4d1f-a460-6e91948b2292',
        menuSectionId: '93f19411-ee8b-4634-902d-debce568401c',
        displayOrder: 1
      },
      {
        id: 'f7b56e66-39ff-4348-841c-58e1c9f76dc6',
        menuItemId: '947c9161-b311-46ec-990f-222a69844efd',
        menuSectionId: '93f19411-ee8b-4634-902d-debce568401c',
        displayOrder: 2
      },
      {
        id: '0e7bd0ef-217c-42ca-82ab-b80fbeb59e8e',
        menuItemId: '6657b6b9-0c5a-4cef-9eb7-634c0778d798',
        menuSectionId: '93f19411-ee8b-4634-902d-debce568401c',
        displayOrder: 3
      },
      {
        id: '27b0658a-c16b-42ca-9aa4-98516f79306c',
        menuItemId: '99c69096-78ab-4363-9fc2-8333113b3052',
        menuSectionId: '93f19411-ee8b-4634-902d-debce568401c',
        displayOrder: 4
      },
      {
        id: '3f74ca89-cc6a-413a-9226-c88f0d3851eb',
        menuItemId: 'f4433921-df8c-4823-b936-284efc502332',
        menuSectionId: '93f19411-ee8b-4634-902d-debce568401c',
        displayOrder: 5
      },
      {
        id: '60017e41-bcf0-403e-aced-408535b26279',
        menuItemId: '1c6a1c8f-a7c5-4e29-8b13-85f805b498dd',
        menuSectionId: '80a3979b-0a68-4ea7-9229-d7a6f06917cd',
        displayOrder: 1
      },
      {
        id: '32ad3d35-89a9-4e64-8ea0-f5ab634efb47',
        menuItemId: '50207fcd-59e2-4d1a-9801-f89367f354e8',
        menuSectionId: '80a3979b-0a68-4ea7-9229-d7a6f06917cd',
        displayOrder: 2
      },
      {
        id: '235822f3-0cb8-4a44-8d93-9949548cc34f',
        menuItemId: 'cbc196a3-55e1-4d3c-87ad-f746b9e6b380',
        menuSectionId: '80a3979b-0a68-4ea7-9229-d7a6f06917cd',
        displayOrder: 3
      },
      {
        id: '188e0a98-ee13-4274-b344-90aec3201013',
        menuItemId: '3d00e2aa-9603-4aeb-96f1-0c3d02954f26',
        menuSectionId: '80a3979b-0a68-4ea7-9229-d7a6f06917cd',
        displayOrder: 4
      },
      {
        id: 'e5cfb982-55fe-4bb4-9430-324062fed236',
        menuItemId: '198fca60-e0f0-426e-86d8-aa3d82b04fc4',
        menuSectionId: '6112d2b7-33c0-4f3f-b316-bff50484019a',
        displayOrder: 1
      },
      {
        id: '929280d0-59cd-498d-8766-c3aff29e571e',
        menuItemId: 'd4c08ef4-7dc1-4952-b8d8-3a549fcdec9b',
        menuSectionId: '6112d2b7-33c0-4f3f-b316-bff50484019a',
        displayOrder: 2
      },
      {
        id: 'cc881c8c-a511-45d4-bed0-a7275533c3b5',
        menuItemId: '097d2291-87aa-4106-abd3-53219e3933ed',
        menuSectionId: '6112d2b7-33c0-4f3f-b316-bff50484019a',
        displayOrder: 3
      },
      {
        id: '719de2ed-db01-4b66-985d-40a987fdb4eb',
        menuItemId: '3f1a6e78-a453-4516-a449-5d6da3837776',
        menuSectionId: '6112d2b7-33c0-4f3f-b316-bff50484019a',
        displayOrder: 4
      },
      {
        id: '5d2a4c6e-c4bc-4436-bfdb-2062c269b66c',
        menuItemId: '8fb718cd-31ab-46b6-a7fc-38b37ae335b2',
        menuSectionId: '33476a60-506d-442c-b0c8-6c5c19a029f3',
        displayOrder: 1
      },
      {
        id: '4072aa97-b1a6-4efb-a0c4-3087950d48ee',
        menuItemId: 'ce188e2c-005f-4159-ab60-d20701290043',
        menuSectionId: '33476a60-506d-442c-b0c8-6c5c19a029f3',
        displayOrder: 2
      },
      {
        id: '4ca63c21-4f03-4abc-8d1a-584985ebd970',
        menuItemId: '3f2005f0-107c-46c0-9aa0-a4f5dc085493',
        menuSectionId: '33476a60-506d-442c-b0c8-6c5c19a029f3',
        displayOrder: 3
      },
      {
        id: 'd8327afa-dd2d-4911-892d-9d5a42f0d7a5',
        menuItemId: 'f455b89f-47c1-4f9c-b9af-5b6aa5138664',
        menuSectionId: '33476a60-506d-442c-b0c8-6c5c19a029f3',
        displayOrder: 4
      },
      {
        id: 'cf6c8ca4-e821-41c7-8ee2-ba6e333b8539',
        menuItemId: '3cc6dd48-9ee6-435a-8861-1e02770188e4',
        menuSectionId: '33476a60-506d-442c-b0c8-6c5c19a029f3',
        displayOrder: 5
      },
      {
        id: '271e62c9-b8fd-4973-b437-c57c667642d4',
        menuItemId: '9bb67af1-8480-4499-b18c-5daaf9975f1b',
        menuSectionId: '33476a60-506d-442c-b0c8-6c5c19a029f3',
        displayOrder: 6
      },
      {
        id: '483291ec-d56e-47cf-9956-85a0f90c56a0',
        menuItemId: '582ed279-0987-448a-b5c4-bb642fe006ba',
        menuSectionId: '33476a60-506d-442c-b0c8-6c5c19a029f3',
        displayOrder: 7
      },
      {
        id: '863e295f-3f20-4580-8e21-5a59cdf7277a',
        menuItemId: '4f50ffc5-38f3-4652-8368-bd09309a9e9a',
        menuSectionId: 'b5ba20ed-9cf8-444e-bdbc-088c3da371fb',
        displayOrder: 1
      },
      {
        id: 'f5fde42e-4e3c-43f3-b173-c564838f2d0b',
        menuItemId: 'e694b6e4-5225-4e0c-9c76-7dd3cde2c4c2',
        menuSectionId: 'b5ba20ed-9cf8-444e-bdbc-088c3da371fb',
        displayOrder: 2
      },
      {
        id: '6c98dea0-5351-4cf2-9ccd-c39fc8d2119a',
        menuItemId: '959d9d20-4b21-43ed-bffd-ddbef6f0208e',
        menuSectionId: 'b5ba20ed-9cf8-444e-bdbc-088c3da371fb',
        displayOrder: 3
      },
      {
        id: '9d887ea3-7d81-4faf-ba21-3622608b7bd9',
        menuItemId: '4b36c97f-4658-4fa3-93cc-242edd860230',
        menuSectionId: 'b5ba20ed-9cf8-444e-bdbc-088c3da371fb',
        displayOrder: 4
      },
      {
        id: '65b8e4e5-0fbb-4f1e-a6a0-4636cf522aa5',
        menuItemId: '0e3bbafc-3ec9-49df-993b-e87f42bf8263',
        menuSectionId: '9a4a5eda-f558-4249-9aa4-62ea5c3b50a7',
        displayOrder: 1
      },
      {
        id: '963a0fbd-7e07-4831-861e-f8a1ee9982a4',
        menuItemId: 'f11135fb-a913-4192-b023-4241f0a94102',
        menuSectionId: '9a4a5eda-f558-4249-9aa4-62ea5c3b50a7',
        displayOrder: 2
      },
      {
        id: '8d660d37-b0fa-4717-9340-b2f4a545729b',
        menuItemId: 'a48821eb-ed4c-47c4-9281-956dc25227b1',
        menuSectionId: '9a4a5eda-f558-4249-9aa4-62ea5c3b50a7',
        displayOrder: 3
      },
      {
        id: '1f6cda0c-645b-459f-a296-48f1691bbf95',
        menuItemId: '17249247-d9d4-47b6-8b77-b802a37ee5b3',
        menuSectionId: '9a4a5eda-f558-4249-9aa4-62ea5c3b50a7',
        displayOrder: 4
      },
      {
        id: '7db5e173-7c94-43ac-b85d-07494957b5dc',
        menuItemId: '2f2da974-6605-4ada-957e-67dfebd77b7f',
        menuSectionId: '9a4a5eda-f558-4249-9aa4-62ea5c3b50a7',
        displayOrder: 5
      },
      {
        id: 'b180c85c-ca7e-4a5c-a555-92dbb1a5f04f',
        menuItemId: 'eace8090-8b93-4988-9f45-704bf7fee10a',
        menuSectionId: '9a4a5eda-f558-4249-9aa4-62ea5c3b50a7',
        displayOrder: 6
      },
      {
        id: 'a663e802-1a01-452d-9ab1-2ca918fb5438',
        menuItemId: '34a64336-f27f-49bd-a215-9dd00307c2e0',
        menuSectionId: '9a4a5eda-f558-4249-9aa4-62ea5c3b50a7',
        displayOrder: 7
      },
      {
        id: '41658f23-56f9-47ee-bfcb-d164815dd478',
        menuItemId: '52e1546d-6592-4360-807b-02f04c48195e',
        menuSectionId: '9a4a5eda-f558-4249-9aa4-62ea5c3b50a7',
        displayOrder: 8
      },
      {
        id: 'a3a7194b-e11f-4cbb-b9bd-5827773a1043',
        menuItemId: 'a23eeda8-dfa2-4f33-8126-28aaeab5d652',
        menuSectionId: '6d7f664a-e27a-40e0-ba92-d48ad0143b81',
        displayOrder: 1
      },
      {
        id: '0a487035-e24d-42f9-8d1d-cd2c65044c1f',
        menuItemId: '9b37cd2e-ae79-479b-b27a-f2c6f854c359',
        menuSectionId: '6d7f664a-e27a-40e0-ba92-d48ad0143b81',
        displayOrder: 2
      },
      {
        id: '3e30568f-8db3-44d7-9506-9bc9e91c797a',
        menuItemId: '57059d96-cad5-4af6-b557-30ae6e32351c',
        menuSectionId: '6d7f664a-e27a-40e0-ba92-d48ad0143b81',
        displayOrder: 3
      },
      {
        id: '9affcdab-96cc-4d74-bf0b-4a25c7af8109',
        menuItemId: 'c2bc75b0-037c-489b-8026-f1d62989f25e',
        menuSectionId: '6d4ab773-15f5-400e-a784-0de81bdf2264',
        displayOrder: 1
      },
      {
        id: '5b0969b1-790a-4d5e-b41e-722d4dc76374',
        menuItemId: 'a812ec47-7d96-4c11-864a-9ba2b660c3c8',
        menuSectionId: '6d4ab773-15f5-400e-a784-0de81bdf2264',
        displayOrder: 2
      },
      {
        id: 'cceff9c7-42ba-4eab-863d-e5f9dca9486a',
        menuItemId: '65d59562-ac88-4260-856a-b2f53baa6727',
        menuSectionId: '6d4ab773-15f5-400e-a784-0de81bdf2264',
        displayOrder: 3
      },
      {
        id: '0362013a-da9d-4bfc-94eb-744c52143627',
        menuItemId: 'd4917701-ce6f-4342-9689-a25fa186e5b1',
        menuSectionId: '6d4ab773-15f5-400e-a784-0de81bdf2264',
        displayOrder: 4
      },
      {
        id: '54be8530-f338-41c5-b30f-3c9ad74fd14a',
        menuItemId: '0c278451-8667-4f1a-8345-30c23ad1cd0f',
        menuSectionId: '6d4ab773-15f5-400e-a784-0de81bdf2264',
        displayOrder: 5
      },
      {
        id: '13bcadc3-f163-434d-8a75-ef18d17c334b',
        menuItemId: '91256cbe-b53e-4445-83b9-868eec325ac9',
        menuSectionId: '6d4ab773-15f5-400e-a784-0de81bdf2264',
        displayOrder: 6
      },
      {
        id: '34d98c86-794e-4b29-930a-65f1c7d3a316',
        menuItemId: 'a3d31cea-7452-4ff6-bf60-31f88531b264',
        menuSectionId: '6d4ab773-15f5-400e-a784-0de81bdf2264',
        displayOrder: 7
      },
      {
        id: 'b52fbdec-3bd4-4d14-83e7-56dc871ecc03',
        menuItemId: 'ecd47b6b-2b7d-40c3-92a4-dd8134f49bc6',
        menuSectionId: '6a7deada-ccc8-4fe0-a8a0-a8484edeb692',
        displayOrder: 1
      },
      {
        id: '50b415d6-2a1b-49af-a111-927427f59505',
        menuItemId: 'a498ea9b-9703-4638-a1c7-b597878d0bb0',
        menuSectionId: '6a7deada-ccc8-4fe0-a8a0-a8484edeb692',
        displayOrder: 2
      },
      {
        id: '2a2ac1e3-ac8a-427c-945b-ffc93841077f',
        menuItemId: 'ae82b36e-c024-4b64-ac37-cd166966158e',
        menuSectionId: '6a7deada-ccc8-4fe0-a8a0-a8484edeb692',
        displayOrder: 3
      },
      {
        id: '30d653ec-da61-494f-b76a-c754d7de7e99',
        menuItemId: 'e90ad0a5-8ed0-4142-a708-86e31c9cbde7',
        menuSectionId: '6a7deada-ccc8-4fe0-a8a0-a8484edeb692',
        displayOrder: 4
      },
      {
        id: '63e95808-c971-4fe0-bcc4-5fb696e65a32',
        menuItemId: 'afcbea2e-b939-44ff-ae48-965a31ed674d',
        menuSectionId: '6a7deada-ccc8-4fe0-a8a0-a8484edeb692',
        displayOrder: 5
      },
      {
        id: '9539e919-30c0-471b-92c4-a12a59671656',
        menuItemId: '4c9d0fd2-cfa8-4bd9-bc7c-8e3e6666108f',
        menuSectionId: '6a7deada-ccc8-4fe0-a8a0-a8484edeb692',
        displayOrder: 6
      },
      {
        id: '90f3850e-bc99-4dd6-9a3a-d17809019656',
        menuItemId: '9fb86257-f1e8-488c-a20e-7b667f58e7e8',
        menuSectionId: '6a7deada-ccc8-4fe0-a8a0-a8484edeb692',
        displayOrder: 7
      },
      {
        id: '313278b4-1252-4432-86a3-b3fece548c92',
        menuItemId: '842b34d3-14d9-4419-aef1-ea9e72a7158d',
        menuSectionId: 'dd14a87c-07ab-4909-96f3-06c7ae840e20',
        displayOrder: 1
      },
      {
        id: '67098ad7-4f10-4edf-9024-5d1c4611712a',
        menuItemId: '9a128a43-b35c-448e-ad70-f7340ef70e3c',
        menuSectionId: 'dd14a87c-07ab-4909-96f3-06c7ae840e20',
        displayOrder: 2
      },
      {
        id: 'd32bfe03-3898-4e68-93fc-5e77e9d008cb',
        menuItemId: '315db29c-c041-42b2-a91e-ecf835c0a892',
        menuSectionId: 'dd14a87c-07ab-4909-96f3-06c7ae840e20',
        displayOrder: 3
      },
      {
        id: 'b459ffd5-1e36-443d-a9ce-96e6697cc768',
        menuItemId: '5d383b7b-e7be-4fae-b726-c96a1226a1b7',
        menuSectionId: 'dd14a87c-07ab-4909-96f3-06c7ae840e20',
        displayOrder: 4
      },
      {
        id: 'bccd5486-e532-424d-8cf1-46b54451c75e',
        menuItemId: '8d57df07-90ef-4427-9bfc-a59a48824bc2',
        menuSectionId: 'fb1654b7-c4cd-4240-85cb-c718c09e913e',
        displayOrder: 1
      },
      {
        id: '2ed09253-aebe-4684-9701-fc3bd73b4e3e',
        menuItemId: '34c6e649-9326-4e42-893a-5166ebec5f68',
        menuSectionId: 'fb1654b7-c4cd-4240-85cb-c718c09e913e',
        displayOrder: 2
      },
      {
        id: '0dbb3532-a1e2-4a2c-9a2e-07c4d43e804e',
        menuItemId: '0c7cdfe6-3fd9-49e1-ab7b-17db355f52b9',
        menuSectionId: 'fb1654b7-c4cd-4240-85cb-c718c09e913e',
        displayOrder: 3
      },
      {
        id: '408394f2-d217-4dd4-a067-72c71da4c691',
        menuItemId: '4317d7a6-9839-47a3-885d-1ab8bc792669',
        menuSectionId: 'fb1654b7-c4cd-4240-85cb-c718c09e913e',
        displayOrder: 4
      },
      {
        id: '79077cdc-9e13-40ab-92d7-7e5c281e4103',
        menuItemId: '019b3d8f-c87a-499f-bc06-365152f0b0e4',
        menuSectionId: '859c1dd6-c116-4d94-a536-b92276ac634f',
        displayOrder: 1
      },
      {
        id: '4a3c373d-632a-4dc4-89f4-9a8dcff1201b',
        menuItemId: 'adcf1611-c940-40e4-a2e8-94c8b02432cf',
        menuSectionId: '859c1dd6-c116-4d94-a536-b92276ac634f',
        displayOrder: 2
      },
      {
        id: '7e378d37-20cc-4f92-9e3b-d4c19db49e4c',
        menuItemId: '42e46000-7f7a-4b26-8de0-3d04c166ec18',
        menuSectionId: '859c1dd6-c116-4d94-a536-b92276ac634f',
        displayOrder: 3
      },
      {
        id: '2edefd99-69ad-4499-bc3f-456f62a40044',
        menuItemId: 'e1252879-cebe-4113-bdd7-da2a3ec64856',
        menuSectionId: '859c1dd6-c116-4d94-a536-b92276ac634f',
        displayOrder: 4
      },
      {
        id: '2b11b7b9-f66d-43f7-ba75-29674aab638c',
        menuItemId: '7d4b7ecf-f9cc-41f5-a79b-072d6db82bd4',
        menuSectionId: 'da3e4f92-d445-43ac-9eb9-f3703fd22f66',
        displayOrder: 1
      },
      {
        id: 'f7ac0d6a-1c73-4bdb-81db-375610f0a905',
        menuItemId: 'df51bdff-49ff-4839-924b-664235730310',
        menuSectionId: 'da3e4f92-d445-43ac-9eb9-f3703fd22f66',
        displayOrder: 2
      },
      {
        id: '2b7d688a-43fa-428a-bcce-e63371d9c763',
        menuItemId: 'd8862305-f3e5-412d-b777-b10883c00c32',
        menuSectionId: 'da3e4f92-d445-43ac-9eb9-f3703fd22f66',
        displayOrder: 3
      },
      {
        id: '73c8e2be-1a32-4440-a84c-747a67cde12c',
        menuItemId: 'e14a98df-30e2-4ed3-97df-dfe8c5a8e860',
        menuSectionId: 'da3e4f92-d445-43ac-9eb9-f3703fd22f66',
        displayOrder: 4
      },
      {
        id: 'aea340c0-189e-4354-a150-fbec21bdf93b',
        menuItemId: 'ab85ce83-4118-462e-9ebc-173ab0f3cf5a',
        menuSectionId: 'da3e4f92-d445-43ac-9eb9-f3703fd22f66',
        displayOrder: 5
      },
      {
        id: '5a25e5f1-1c7f-4140-98a5-cd2e849e3d72',
        menuItemId: 'd7b233b1-d590-44fd-8eca-062913116876',
        menuSectionId: 'da3e4f92-d445-43ac-9eb9-f3703fd22f66',
        displayOrder: 6
      },
      {
        id: '14cdb03d-a315-47dd-8807-b5ebb1485737',
        menuItemId: 'afbb652f-8afb-47d6-890c-396182b3d7f1',
        menuSectionId: '12e4d122-3270-4721-a33d-30c22684033a',
        displayOrder: 1
      },
      {
        id: '9fb76f00-566d-4d51-aa5d-62a9ddafdc71',
        menuItemId: 'b097f3f7-352b-4d91-8971-eb75f43ee6f6',
        menuSectionId: '12e4d122-3270-4721-a33d-30c22684033a',
        displayOrder: 2
      },
      {
        id: 'a34a5764-b3dc-4eaa-bb5b-4f11d143ecab',
        menuItemId: 'f4b7b7c7-94c2-49bc-aa6a-169f08aee8f6',
        menuSectionId: '12e4d122-3270-4721-a33d-30c22684033a',
        displayOrder: 3
      },
      {
        id: '7b6fcbf5-a0e3-48f5-9294-adb0135fc2b9',
        menuItemId: '68110502-8f7c-4159-8862-d7ca15fbc606',
        menuSectionId: '12e4d122-3270-4721-a33d-30c22684033a',
        displayOrder: 4
      },
      {
        id: '6616b0c5-8d9b-419e-aa95-820852d5b099',
        menuItemId: 'c4c2d06d-b023-4c3d-9e6b-4d9cce5adb10',
        menuSectionId: 'be2e5f9a-f816-44ac-818f-7ad6adf58c02',
        displayOrder: 1
      },
      {
        id: '30f6d866-43df-4c9d-a403-795014fb9292',
        menuItemId: '945d1f76-a67e-427f-a256-d09bcc7d838e',
        menuSectionId: 'be2e5f9a-f816-44ac-818f-7ad6adf58c02',
        displayOrder: 2
      },
      {
        id: 'cdde8bbb-2cf7-44d6-a342-2603a125c643',
        menuItemId: 'bf2d2ec7-53ed-4438-acd5-a8873dbb79cf',
        menuSectionId: 'be2e5f9a-f816-44ac-818f-7ad6adf58c02',
        displayOrder: 3
      },
      {
        id: 'b76ea391-7616-4f61-8814-a6ed1da51182',
        menuItemId: '25fa5fbc-0a1e-4dde-bb4b-9be1d6c65ae3',
        menuSectionId: 'be2e5f9a-f816-44ac-818f-7ad6adf58c02',
        displayOrder: 4
      },
      {
        id: 'daced4ae-f5a3-4d22-a98e-e011d66b604e',
        menuItemId: 'ae58cdd3-7de5-4fce-a264-bd455b899d57',
        menuSectionId: '3bfbf45f-e426-4808-bb0a-c1c929bb1c78',
        displayOrder: 1
      },
      {
        id: '3f9964fa-c4c7-485c-8dc7-fa4538a0df5d',
        menuItemId: 'db116260-ca99-4846-b43a-06175cb6dbef',
        menuSectionId: '3bfbf45f-e426-4808-bb0a-c1c929bb1c78',
        displayOrder: 2
      },
      {
        id: 'e82ac7ad-3303-4459-9e70-8546e434b914',
        menuItemId: 'c0cebaed-2d15-44ce-863d-8f99cc93f7c4',
        menuSectionId: '1044d60c-5463-424c-a5cd-c319e14b1a16',
        displayOrder: 1
      },
      {
        id: 'd172ebdf-24ac-47c8-b32b-0994adb13252',
        menuItemId: '04a9b831-9403-4ff2-a952-a3153478345f',
        menuSectionId: '1044d60c-5463-424c-a5cd-c319e14b1a16',
        displayOrder: 2
      },
      {
        id: 'f3748258-d3bf-420a-b0fa-09be2faa3bd5',
        menuItemId: '420b01f5-5f14-4e30-b361-7117c872b6ee',
        menuSectionId: '1044d60c-5463-424c-a5cd-c319e14b1a16',
        displayOrder: 3
      },
      {
        id: 'f9d16b92-061d-457d-86cf-45c5adb1dc42',
        menuItemId: '3bea2536-997b-48cf-aebb-dc3ba96bf6c9',
        menuSectionId: '1649fe5e-6b8a-48be-bd17-336e8dfedb9c',
        displayOrder: 1
      },
      {
        id: '861d729a-01dc-40d5-a01f-dc1591ad54f7',
        menuItemId: '971b8e55-b3ed-4134-b182-895c3734e22d',
        menuSectionId: '1649fe5e-6b8a-48be-bd17-336e8dfedb9c',
        displayOrder: 2
      },
      {
        id: 'cda70d43-c0c1-4532-8f23-c2f3ad115f8d',
        menuItemId: '789c5f2f-fc1c-4ac6-acc4-a2535bafbe32',
        menuSectionId: '1649fe5e-6b8a-48be-bd17-336e8dfedb9c',
        displayOrder: 3
      },
      {
        id: 'c3a573ca-6180-4614-990f-4afcd099c2bb',
        menuItemId: '012d1793-242b-4d05-bc3d-85a8bc9c3b80',
        menuSectionId: '1649fe5e-6b8a-48be-bd17-336e8dfedb9c',
        displayOrder: 4
      },
      {
        id: '87c5cfe4-ed86-4f31-b4d6-2048543921d6',
        menuItemId: '347f0f0d-13cb-499d-8bd5-d72ea5745549',
        menuSectionId: 'f1e2f360-2d48-4895-aa96-024b1e6564e3',
        displayOrder: 1
      },
      {
        id: 'ec7e2229-2a8d-402f-bd1e-46a980a3b851',
        menuItemId: 'd206ea58-1689-4c47-b0a2-298d523c9245',
        menuSectionId: 'f1e2f360-2d48-4895-aa96-024b1e6564e3',
        displayOrder: 2
      },
      {
        id: 'aa825a81-1714-42e6-a2db-e24008d89f55',
        menuItemId: 'b382bbd6-d465-41a4-9322-85a1c54bf690',
        menuSectionId: 'f1e2f360-2d48-4895-aa96-024b1e6564e3',
        displayOrder: 3
      },
      {
        id: '4d8a7352-552a-494c-9810-4cc65d85c287',
        menuItemId: '50130542-4cdd-45c4-81bf-b7196f21e8ef',
        menuSectionId: 'f1e2f360-2d48-4895-aa96-024b1e6564e3',
        displayOrder: 4
      },
      {
        id: '0eaf35ee-edff-4eca-b32f-e7e90893eb22',
        menuItemId: 'eaacde9b-56ab-4d5b-a45c-1491ae3a97ce',
        menuSectionId: 'f1e2f360-2d48-4895-aa96-024b1e6564e3',
        displayOrder: 5
      },
      {
        id: 'd5a9bbab-05ca-44d5-8112-ba2beb698624',
        menuItemId: '22cd1612-8b45-4da9-a2bb-3a92558e0238',
        menuSectionId: '9bf1d331-b18c-4089-8a16-33c17697a9df',
        displayOrder: 1
      },
      {
        id: '3648f5e6-6794-410f-8652-9346cbd899d7',
        menuItemId: '52c1f5f0-9a2e-4fa3-93e0-8c4007927a57',
        menuSectionId: 'e61c9477-51e2-4131-91bb-8009c03f5e3e',
        displayOrder: 1
      },
      {
        id: 'c8ea7729-4408-43b4-bac4-46a206c532b3',
        menuItemId: '3339bd0f-1ba4-49ca-9304-87fa5d83896e',
        menuSectionId: '7f6df0d2-84f8-4b47-a28c-3b2d0233432c',
        displayOrder: 1
      },
      {
        id: '724f0d34-aaaa-40de-bdbe-49b0b55b7af0',
        menuItemId: '753c7fa5-5403-4e80-911f-b1e2e468e34a',
        menuSectionId: '7f6df0d2-84f8-4b47-a28c-3b2d0233432c',
        displayOrder: 2
      },
      {
        id: 'b0a2b2d5-0d37-4e4d-8a6a-805e7061d0f6',
        menuItemId: 'f0b693f0-112e-4cd7-99a1-0b42cc079951',
        menuSectionId: '7f6df0d2-84f8-4b47-a28c-3b2d0233432c',
        displayOrder: 3
      },
      {
        id: 'c69b82a0-7ac9-4b6a-b90a-07fe584c8e80',
        menuItemId: '938315a9-d204-4b45-9dec-a84f8c1c4bdc',
        menuSectionId: '06957be3-3701-4c05-9502-3d00a5a5e23f',
        displayOrder: 1
      },
      {
        id: '33990cd7-6542-425a-9f1a-dd6b331c8f03',
        menuItemId: 'b5a1ba7e-80d8-4b4c-9e78-0eda71c953e0',
        menuSectionId: '06957be3-3701-4c05-9502-3d00a5a5e23f',
        displayOrder: 2
      },
      {
        id: '5ae50834-df06-426b-bcdb-d26b060013cb',
        menuItemId: 'cc401749-30f4-4dcd-9864-633743000853',
        menuSectionId: '06957be3-3701-4c05-9502-3d00a5a5e23f',
        displayOrder: 3
      },
      {
        id: 'af3920f0-bfbf-4cf3-894b-268af76860da',
        menuItemId: '3ee50e57-ecb8-48f5-8ad9-8ee2df789b6d',
        menuSectionId: '06957be3-3701-4c05-9502-3d00a5a5e23f',
        displayOrder: 4
      },
      {
        id: '263c4dcf-1951-4e1e-90fc-35c36cb6d1fb',
        menuItemId: 'aebb16b3-da54-4867-bd0b-f42bee37752f',
        menuSectionId: '06957be3-3701-4c05-9502-3d00a5a5e23f',
        displayOrder: 5
      },
      {
        id: 'dfeee14c-d255-4682-88dc-bd5644f71c23',
        menuItemId: '1145df0e-a0b5-4e9f-9377-b5dc509fcb23',
        menuSectionId: 'ec0fbf7b-647b-4910-aa79-f047fd16003d',
        displayOrder: 1
      },
      {
        id: '556ce335-01b0-4373-acec-ab120e1df846',
        menuItemId: '28a31cea-ee02-466e-89cc-dfb8ecb7cd1c',
        menuSectionId: '275b1ece-0d1f-4b0a-8447-052fd7f82e47',
        displayOrder: 1
      },
      {
        id: '52ac5c8c-8ad3-4159-a12f-6476731be839',
        menuItemId: 'df15dec5-7680-44e0-a82d-b38756d66320',
        menuSectionId: '275b1ece-0d1f-4b0a-8447-052fd7f82e47',
        displayOrder: 2
      },
      {
        id: 'd3c14631-54f1-4dfd-aee4-423b19578a49',
        menuItemId: '7733d676-8840-4816-bea0-1d83d192a630',
        menuSectionId: '275b1ece-0d1f-4b0a-8447-052fd7f82e47',
        displayOrder: 3
      },
      {
        id: 'eee2aeab-bef2-44cd-b089-e1bf5d7f4ad7',
        menuItemId: 'aa017fc8-89fc-435c-b763-c054bbb2f34b',
        menuSectionId: '275b1ece-0d1f-4b0a-8447-052fd7f82e47',
        displayOrder: 4
      },
      {
        id: '021a3dba-611c-47c2-bfbc-a1b2f1771717',
        menuItemId: 'ca4018d8-17b9-4713-baf9-ead214ee95a9',
        menuSectionId: '298c2b1b-d662-4b6f-b27b-e9fa259a9928',
        displayOrder: 1
      },
      {
        id: '74e9c7bb-a4c1-415c-ac65-b11a66f1d94c',
        menuItemId: '9288c839-b9f0-4bed-b1e3-925108c90caa',
        menuSectionId: '298c2b1b-d662-4b6f-b27b-e9fa259a9928',
        displayOrder: 2
      },
      {
        id: '0ab27297-193c-471f-b019-25d968949cfb',
        menuItemId: '595e4ad2-17c5-47be-be8f-6aaa1edebc03',
        menuSectionId: '298c2b1b-d662-4b6f-b27b-e9fa259a9928',
        displayOrder: 3
      },
      {
        id: '48f036de-b106-4dc8-8286-8716b33d190d',
        menuItemId: '3c287039-eec3-4678-98ee-c27b196c3389',
        menuSectionId: '298c2b1b-d662-4b6f-b27b-e9fa259a9928',
        displayOrder: 4
      },
      {
        id: '18c59f96-ee4e-4f97-bf1b-5ef671b8891f',
        menuItemId: '05772f0d-454f-4ab7-8743-08ff42ad6a99',
        menuSectionId: '298c2b1b-d662-4b6f-b27b-e9fa259a9928',
        displayOrder: 5
      },
      {
        id: '5d5dea97-f7a4-49bc-9f49-02deb65dd167',
        menuItemId: '852ed621-1370-47f0-b05d-c219fa4f3217',
        menuSectionId: '298c2b1b-d662-4b6f-b27b-e9fa259a9928',
        displayOrder: 6
      },
      {
        id: '750dbb33-164b-4807-b687-01e219e51d6f',
        menuItemId: 'ac7ec3ed-eb51-4672-b139-29b9f5a3703e',
        menuSectionId: '526ae38a-9262-4342-87fd-f2245f0d4f45',
        displayOrder: 1
      },
      {
        id: '7d515353-dd61-4aa1-b0cf-1a12a175f1f6',
        menuItemId: '73461dc3-91e4-4872-9bd9-56346cd92fb0',
        menuSectionId: '526ae38a-9262-4342-87fd-f2245f0d4f45',
        displayOrder: 2
      },
      {
        id: '305e6b7a-64a1-4bc5-bae6-ae95532ff68a',
        menuItemId: '65d82fc3-cdb7-4fc8-aa6b-787fd5d386cf',
        menuSectionId: '526ae38a-9262-4342-87fd-f2245f0d4f45',
        displayOrder: 3
      },
      {
        id: 'd8243371-7bd4-4074-8952-bb2583785794',
        menuItemId: '2e3beaa8-b82e-4140-a2f9-44051da49d04',
        menuSectionId: '526ae38a-9262-4342-87fd-f2245f0d4f45',
        displayOrder: 4
      },
      {
        id: '0635c4ed-7f1c-4d4d-a720-552f3fb83e72',
        menuItemId: '9b149c78-eda5-443c-b82d-9bc6ced1e954',
        menuSectionId: '526ae38a-9262-4342-87fd-f2245f0d4f45',
        displayOrder: 5
      },
      {
        id: '98e5e709-5a29-4074-96ba-99eac62bbb62',
        menuItemId: '34e9c987-7198-441c-9efb-39dc3a206e00',
        menuSectionId: '526ae38a-9262-4342-87fd-f2245f0d4f45',
        displayOrder: 6
      },
      {
        id: '5fba3f22-5f26-440e-b293-d598bf7f6fde',
        menuItemId: '23eafc74-bad0-4c78-8a3b-8fe0c83074fb',
        menuSectionId: '7fbc4f58-4829-4fb5-b4b0-6433c3d14c37',
        displayOrder: 1
      },
      {
        id: '48771acf-81ee-4a28-a6af-3d5a70beb700',
        menuItemId: '2f0951a9-1d75-4829-b4c6-ed6a5b72f9fd',
        menuSectionId: '7fbc4f58-4829-4fb5-b4b0-6433c3d14c37',
        displayOrder: 2
      },
      {
        id: '6a96c04a-bbbf-454f-a579-b82b85f3ce08',
        menuItemId: 'b168d788-463f-405d-affb-d0020b6e486a',
        menuSectionId: '7fbc4f58-4829-4fb5-b4b0-6433c3d14c37',
        displayOrder: 3
      },
      {
        id: '3151aee3-ba22-44b9-a1d7-2512006ffdc7',
        menuItemId: 'c31a2482-9542-42e8-b7d1-6d9191be2a8b',
        menuSectionId: '7fbc4f58-4829-4fb5-b4b0-6433c3d14c37',
        displayOrder: 4
      },
      {
        id: '770e7f30-c813-474f-b176-c1c1a74bacc2',
        menuItemId: '959e811b-183a-4379-9f21-a471a8c34fdc',
        menuSectionId: '7fbc4f58-4829-4fb5-b4b0-6433c3d14c37',
        displayOrder: 5
      },
      {
        id: 'c267a253-09eb-496c-a38b-b86bf3d3661c',
        menuItemId: '2d449149-631a-4831-85e6-e1f6136106a2',
        menuSectionId: '0c437470-a8d3-493d-8f36-fec92e526aa6',
        displayOrder: 1
      },
      {
        id: '52a11b43-6db4-4737-831c-344135df74f0',
        menuItemId: '43caf338-34fd-49a2-b25a-4ee06b68bed8',
        menuSectionId: '0c437470-a8d3-493d-8f36-fec92e526aa6',
        displayOrder: 2
      },
      {
        id: '3cf0add1-ab38-4a8a-b293-e9336223402e',
        menuItemId: '70105652-1b9d-4b57-8b4d-c7d094e84a87',
        menuSectionId: '0c437470-a8d3-493d-8f36-fec92e526aa6',
        displayOrder: 3
      },
      {
        id: '1624bc91-9e3a-4732-abcb-edb772ca4f30',
        menuItemId: '26cfdc5d-6d04-40fb-bd9b-0297fc9dbeeb',
        menuSectionId: '0c437470-a8d3-493d-8f36-fec92e526aa6',
        displayOrder: 4
      },
      {
        id: '11499a85-3098-4419-8d05-4d415901e0d0',
        menuItemId: '81694bb4-9bd2-4340-b183-725f8b02b03d',
        menuSectionId: '093b4cf8-636b-4d84-81ba-a68c1b2d375c',
        displayOrder: 1
      },
      {
        id: '7b77bdb4-e684-4130-9e0c-acdef08d1bb1',
        menuItemId: '30919eec-3838-43db-975a-2026829a861c',
        menuSectionId: '093b4cf8-636b-4d84-81ba-a68c1b2d375c',
        displayOrder: 2
      },
      {
        id: '14e5ab7a-975d-4cce-89bc-513418eb999e',
        menuItemId: 'ede70c4c-e6b7-4617-95c6-16e193f1b897',
        menuSectionId: '093b4cf8-636b-4d84-81ba-a68c1b2d375c',
        displayOrder: 3
      },
      {
        id: 'e427d7ef-8a87-4bad-988f-4c2f125d8307',
        menuItemId: '4818657c-1825-40fc-8584-2e4bc38d8a30',
        menuSectionId: '093b4cf8-636b-4d84-81ba-a68c1b2d375c',
        displayOrder: 4
      },
      {
        id: '1aa004ae-71c5-451f-aa28-ea8c3b62ecd7',
        menuItemId: 'eff47d71-5c2a-4442-a337-2423f4d13aef',
        menuSectionId: '093b4cf8-636b-4d84-81ba-a68c1b2d375c',
        displayOrder: 5
      },
      {
        id: '685e1e01-a6a0-4b7d-bde3-853a831429c6',
        menuItemId: 'b89df93f-776b-42f4-99ce-89b56a5f14e2',
        menuSectionId: '093b4cf8-636b-4d84-81ba-a68c1b2d375c',
        displayOrder: 6
      },
      {
        id: '640168a1-c688-4746-9cc7-979f8ea671a2',
        menuItemId: '5c2d4177-b752-49ca-b29b-b982fc039fd9',
        menuSectionId: '093b4cf8-636b-4d84-81ba-a68c1b2d375c',
        displayOrder: 7
      },
      {
        id: '959bd78a-23b7-4c92-9d3a-227c59c32365',
        menuItemId: 'a212bf6f-38ee-4fb2-ae59-2e931ea8e482',
        menuSectionId: '093b4cf8-636b-4d84-81ba-a68c1b2d375c',
        displayOrder: 8
      },
      {
        id: '026591b0-0268-45cb-aff5-a64a90381b6a',
        menuItemId: 'b550c7ac-b5c6-4eea-9d07-3aa0dc1d5fc5',
        menuSectionId: '117e4638-07e2-4bb3-be2a-63066316425b',
        displayOrder: 1
      },
      {
        id: '9a3ec9ca-46af-4203-bd62-10a179e5f213',
        menuItemId: 'c1ad72bf-b986-4aa5-91a1-d25ffebd6004',
        menuSectionId: '117e4638-07e2-4bb3-be2a-63066316425b',
        displayOrder: 2
      },
      {
        id: 'd3b4b77a-8763-44fc-bf43-687107ed0c09',
        menuItemId: 'e2e1648d-56a8-47e8-8ed3-47694a51cd0d',
        menuSectionId: '117e4638-07e2-4bb3-be2a-63066316425b',
        displayOrder: 3
      },
      {
        id: '5a6dc2ba-a07c-4a50-b051-db75aadddd93',
        menuItemId: '6bbd756c-4845-462d-bbde-77be7a12bdf7',
        menuSectionId: '117e4638-07e2-4bb3-be2a-63066316425b',
        displayOrder: 4
      },
      {
        id: '64132f06-274b-4875-b279-a60af063b928',
        menuItemId: '03816c9b-5fc8-473c-9dd2-f1c406c41f6d',
        menuSectionId: '117e4638-07e2-4bb3-be2a-63066316425b',
        displayOrder: 5
      },
      {
        id: '29521856-e651-421c-8100-e178af99b86f',
        menuItemId: 'b778269b-a0b9-45cc-bc68-55348e997986',
        menuSectionId: '117e4638-07e2-4bb3-be2a-63066316425b',
        displayOrder: 6
      },
      {
        id: '71e5942b-3d31-443a-8c11-4dc77d9e072b',
        menuItemId: 'da488009-d11e-45c7-8feb-5265e9fc46a8',
        menuSectionId: '117e4638-07e2-4bb3-be2a-63066316425b',
        displayOrder: 7
      },
      {
        id: '03ee81cb-13b5-4e78-98b9-b325743642fd',
        menuItemId: '468da12d-218a-4153-bdf6-af4ab428229b',
        menuSectionId: '117e4638-07e2-4bb3-be2a-63066316425b',
        displayOrder: 8
      },
      {
        id: 'd530325d-2361-48b7-97a0-318ac4a717e3',
        menuItemId: '77395645-bd79-4069-82ab-af5a63c3aaab',
        menuSectionId: '117e4638-07e2-4bb3-be2a-63066316425b',
        displayOrder: 9
      },
      {
        id: '9bec164a-f52a-42b4-b8e9-f654db4c188f',
        menuItemId: '8b627b16-461d-4f7d-9867-0a2812ce8c93',
        menuSectionId: '117e4638-07e2-4bb3-be2a-63066316425b',
        displayOrder: 10
      },
      {
        id: '94e0dd09-fc11-4166-9bad-aec7e339e121',
        menuItemId: '0a445ef7-7493-490a-abdc-1abc56d35eaa',
        menuSectionId: '74266fb2-c345-4934-a700-e11aad51705e',
        displayOrder: 1
      },
      {
        id: '9fc9488d-1151-4b73-b075-7e36f6f274b2',
        menuItemId: '098624dd-f586-4911-a614-96829bba5a34',
        menuSectionId: '74266fb2-c345-4934-a700-e11aad51705e',
        displayOrder: 2
      },
      {
        id: 'cda1f814-db87-4ff4-80cb-e946a73f36f7',
        menuItemId: '21f29d8b-5023-4039-9936-4dec585a7198',
        menuSectionId: '74266fb2-c345-4934-a700-e11aad51705e',
        displayOrder: 3
      },
      {
        id: '67dc890d-675c-4ca1-89ed-6f8fe22935a9',
        menuItemId: '0d326643-8d0e-40a1-9a75-86f82f2f6f89',
        menuSectionId: '74266fb2-c345-4934-a700-e11aad51705e',
        displayOrder: 4
      },
      {
        id: '3cfe3f0f-04c4-4dc3-934c-fd42dc11289c',
        menuItemId: '5c58c7a4-3c00-49ae-a41c-9419d3ca28f8',
        menuSectionId: '74266fb2-c345-4934-a700-e11aad51705e',
        displayOrder: 5
      },
      {
        id: 'e10f45f2-d346-48f8-9b2a-6bbc6b0ede9f',
        menuItemId: '3ec897a2-6cc3-4f31-a29d-148be7766473',
        menuSectionId: '74266fb2-c345-4934-a700-e11aad51705e',
        displayOrder: 6
      },
      {
        id: '0236eb33-4977-4d84-8c4d-9dc4ae1027ad',
        menuItemId: '7f74096c-31a4-4deb-81a6-54cea4342333',
        menuSectionId: '74266fb2-c345-4934-a700-e11aad51705e',
        displayOrder: 7
      },
      {
        id: '77177aab-f603-4f70-8f66-936390eaf757',
        menuItemId: '86ba79f6-a93d-4fd2-8bda-82ecbb78dee7',
        menuSectionId: '74266fb2-c345-4934-a700-e11aad51705e',
        displayOrder: 8
      },
      {
        id: 'e4b4d4eb-c09b-4fe0-ada8-ee79205400b1',
        menuItemId: '3a11b801-3ea0-4c0d-881f-dd71e2c8c276',
        menuSectionId: '74266fb2-c345-4934-a700-e11aad51705e',
        displayOrder: 9
      },
      {
        id: 'b1760438-c9cf-4573-b47b-29705b48d3af',
        menuItemId: '686e4916-1df5-4b06-b184-48413101a5d9',
        menuSectionId: '74266fb2-c345-4934-a700-e11aad51705e',
        displayOrder: 10
      },
      {
        id: 'fb9b8917-f3ef-474e-88c8-26d19a648c3d',
        menuItemId: '28cb72d5-7cf8-4dcb-be5e-4348b27d849b',
        menuSectionId: 'd6e6055a-ff15-4a9d-bde7-46875bbf7ecc',
        displayOrder: 1
      },
      {
        id: 'd4994cbc-a0c1-4655-a6fe-bb74f8708ac9',
        menuItemId: 'ce1d2e24-e830-4b8d-8843-8f17101da0f9',
        menuSectionId: 'd6e6055a-ff15-4a9d-bde7-46875bbf7ecc',
        displayOrder: 2
      },
      {
        id: 'bedad4e6-c927-4ccc-84fb-480bb8c50bf6',
        menuItemId: 'b8ce9a1e-5b8d-456d-a195-d80ac9827054',
        menuSectionId: 'd6e6055a-ff15-4a9d-bde7-46875bbf7ecc',
        displayOrder: 3
      },
      {
        id: 'f1adeb96-1759-4c44-ba6b-822088eabc8a',
        menuItemId: 'c59c340e-59c7-4e39-9a56-469b96b88515',
        menuSectionId: 'd6e6055a-ff15-4a9d-bde7-46875bbf7ecc',
        displayOrder: 4
      },
      {
        id: 'cf951b54-05f3-421b-a841-e36f6c7367e2',
        menuItemId: 'dacc32d5-dc89-482f-9a40-a1e22652d7a2',
        menuSectionId: '3e5b4575-f51e-4ea9-ab4d-350d3200cf5c',
        displayOrder: 1
      },
      {
        id: 'ce8f2c14-7a0a-4876-84fb-b35062bdd9f3',
        menuItemId: '17fd2df8-4129-4620-9b80-19d5537d7a3a',
        menuSectionId: '3e5b4575-f51e-4ea9-ab4d-350d3200cf5c',
        displayOrder: 2
      },
      {
        id: '51b8a5d2-c8bd-4f3b-b6c2-d3464af14823',
        menuItemId: '9d15a915-da05-41b7-97a1-2e2811398da0',
        menuSectionId: '3e5b4575-f51e-4ea9-ab4d-350d3200cf5c',
        displayOrder: 3
      },
      {
        id: '71d677b8-24e6-45ce-97fb-58d512a6e211',
        menuItemId: '31679568-fcca-4a5d-851a-4cf54d61fc80',
        menuSectionId: '3e5b4575-f51e-4ea9-ab4d-350d3200cf5c',
        displayOrder: 4
      },
      {
        id: 'd07af642-4920-45ba-bcea-32650e983beb',
        menuItemId: '4f95dd75-213e-4a4c-839e-b9a057796bbf',
        menuSectionId: '3e5b4575-f51e-4ea9-ab4d-350d3200cf5c',
        displayOrder: 5
      },
      {
        id: 'a2f8e52c-5029-4634-8d01-145fedfbdd24',
        menuItemId: '84b1ccee-f820-4e99-a7f9-43573cd13987',
        menuSectionId: '3e5b4575-f51e-4ea9-ab4d-350d3200cf5c',
        displayOrder: 6
      },
      {
        id: '056933e4-c904-4fff-bd54-53be1cb7e1b3',
        menuItemId: 'ce725b99-aeb1-4d4c-a617-347b7c912b41',
        menuSectionId: '3e5b4575-f51e-4ea9-ab4d-350d3200cf5c',
        displayOrder: 7
      },
      {
        id: '35682b15-3232-41cd-aeb3-55660df72cbd',
        menuItemId: '43a0696a-b0d2-4c8f-9ef1-1ec3114da0b4',
        menuSectionId: '3e5b4575-f51e-4ea9-ab4d-350d3200cf5c',
        displayOrder: 8
      },
      {
        id: 'a7b46fe7-6f7b-46c8-8bb3-529349d78c07',
        menuItemId: 'acf753dd-005f-4c72-b768-e6198f83e678',
        menuSectionId: '3e5b4575-f51e-4ea9-ab4d-350d3200cf5c',
        displayOrder: 9
      },
      {
        id: '5c865800-5ef1-4284-8b9e-8e6a098295cc',
        menuItemId: '9bf1a783-afe4-422b-9063-27ac0f81b334',
        menuSectionId: '04b0a8ff-3a88-44ff-bc9d-371ceb963d99',
        displayOrder: 1
      },
      {
        id: 'f76bcd9a-56c8-4b49-b77c-a37cab90c8a2',
        menuItemId: '7c20092a-afee-4a87-b4c0-44add6ee3902',
        menuSectionId: '04b0a8ff-3a88-44ff-bc9d-371ceb963d99',
        displayOrder: 2
      },
      {
        id: '0ae40831-0c7b-4ff7-8a5e-914eef5bce9b',
        menuItemId: '610b6193-7b80-469f-84c6-22e0010af5c2',
        menuSectionId: '04b0a8ff-3a88-44ff-bc9d-371ceb963d99',
        displayOrder: 3
      },
      {
        id: 'b1110042-04ab-43a6-904d-284f2e81e7dc',
        menuItemId: 'bb4cc81d-a21f-43d9-911f-021cf608f579',
        menuSectionId: '04b0a8ff-3a88-44ff-bc9d-371ceb963d99',
        displayOrder: 4
      },
      {
        id: '061fcff5-060d-4326-a1ae-0636256a65a9',
        menuItemId: 'b9336248-30c8-4650-9756-2030c8076e00',
        menuSectionId: '04b0a8ff-3a88-44ff-bc9d-371ceb963d99',
        displayOrder: 5
      },
      {
        id: 'b038f2f7-33cd-4adf-9747-1402b9224c0e',
        menuItemId: '3552406a-9870-4083-bb47-4d31f0faffe3',
        menuSectionId: '04b0a8ff-3a88-44ff-bc9d-371ceb963d99',
        displayOrder: 6
      },
      {
        id: '0761c4ad-67a4-455d-b110-ad80f6244e35',
        menuItemId: '4c98f7e0-cbef-4505-a4ca-f3f3661fe1cc',
        menuSectionId: '04b0a8ff-3a88-44ff-bc9d-371ceb963d99',
        displayOrder: 7
      },
      {
        id: 'af6c1541-4228-4c0f-b99b-b29849d0697b',
        menuItemId: 'd2a69b30-8563-4c06-86d4-107b21caccb8',
        menuSectionId: '04b0a8ff-3a88-44ff-bc9d-371ceb963d99',
        displayOrder: 8
      },
      {
        id: '751b6a50-18a1-4489-83b5-46a867f23df6',
        menuItemId: 'bc71bb96-847f-4aa5-b7c8-15f15f55f324',
        menuSectionId: '254f83bd-b43a-4d24-8364-87236ac010f5',
        displayOrder: 1
      },
      {
        id: '5579886a-33bf-4435-a9ca-e25444b8d567',
        menuItemId: 'f4e62fd6-d8cf-469a-8abf-276fa5ac0afd',
        menuSectionId: '254f83bd-b43a-4d24-8364-87236ac010f5',
        displayOrder: 2
      },
      {
        id: '5f8c9326-6140-4c46-921c-bdc5417baec8',
        menuItemId: 'ceb61d92-c1e5-4eb3-90e7-7ce4363a74bb',
        menuSectionId: '254f83bd-b43a-4d24-8364-87236ac010f5',
        displayOrder: 3
      },
      {
        id: '8402dde5-b063-488c-9a33-d7dff034ebf4',
        menuItemId: 'ec0bb749-68f2-4179-8380-b34351ca01ce',
        menuSectionId: '254f83bd-b43a-4d24-8364-87236ac010f5',
        displayOrder: 4
      },
      {
        id: 'd12e0ef7-2c77-40dc-a212-459ab55b081e',
        menuItemId: 'b22f8442-bbaf-4367-8dc5-eb5546b6ed4d',
        menuSectionId: '254f83bd-b43a-4d24-8364-87236ac010f5',
        displayOrder: 5
      },
      {
        id: '831d4e6e-9df4-4651-96a3-f3fdfae964b0',
        menuItemId: '90120691-0952-4074-ab38-b4875a5f4117',
        menuSectionId: '448983dc-0963-4c85-a328-6ca0691b9c21',
        displayOrder: 1
      },
      {
        id: '6aec9ea7-52c8-4024-939c-c2eb68c919eb',
        menuItemId: '4c67d48a-6efa-45cc-85bb-e51edd5590d9',
        menuSectionId: '448983dc-0963-4c85-a328-6ca0691b9c21',
        displayOrder: 2
      },
      {
        id: '350cb856-b94e-4680-b3d0-626b8bfb1759',
        menuItemId: 'ab8dff89-ad27-49c1-adb0-97cfd1ff39b3',
        menuSectionId: '448983dc-0963-4c85-a328-6ca0691b9c21',
        displayOrder: 3
      },
      {
        id: '232d78be-907d-4522-87ba-dac5ccb0a6a2',
        menuItemId: '37b69e1b-5aa1-4697-b5f2-05545de63614',
        menuSectionId: '0a221d25-16b8-4e0b-83a4-8950e098bf5d',
        displayOrder: 1
      },
      {
        id: '189e4b80-0bc4-41e5-be56-838527d97859',
        menuItemId: '862e9586-8ae2-45d3-b517-6a5d804e856a',
        menuSectionId: '0a221d25-16b8-4e0b-83a4-8950e098bf5d',
        displayOrder: 2
      },
      {
        id: '2268c029-6702-4b7e-8f71-966a00a58e27',
        menuItemId: '6ccd0456-bdd8-4cad-8176-3665b901d8ef',
        menuSectionId: '0a221d25-16b8-4e0b-83a4-8950e098bf5d',
        displayOrder: 3
      },
      {
        id: 'afb05bd1-371f-4b4d-8cb6-520519ca7d9a',
        menuItemId: '4ec8dfb5-c7db-4ffe-97c5-8b241941d2fb',
        menuSectionId: '0a221d25-16b8-4e0b-83a4-8950e098bf5d',
        displayOrder: 4
      },
      {
        id: 'a17286a9-7629-490e-a9d8-9d1d79d9d47d',
        menuItemId: '96b4418f-e014-474f-9fb1-ebd113cf4db1',
        menuSectionId: '0a221d25-16b8-4e0b-83a4-8950e098bf5d',
        displayOrder: 5
      },
      {
        id: 'a4cf645f-7f3b-4db3-ae88-a5d1f4fa90a9',
        menuItemId: '4cbb0274-6e8e-44f3-98b7-5a88541346dd',
        menuSectionId: '0a221d25-16b8-4e0b-83a4-8950e098bf5d',
        displayOrder: 6
      },
      {
        id: '58bc7283-ca4d-449d-a05d-4b2d5b6b4480',
        menuItemId: '1c742c35-773f-4a03-88aa-9d2d459d4941',
        menuSectionId: '0a221d25-16b8-4e0b-83a4-8950e098bf5d',
        displayOrder: 7
      },
      {
        id: 'fd273377-45d7-46b2-90cd-cfbb33f836a4',
        menuItemId: 'de7e5ab5-e8c4-4382-b871-f34cdc0311c9',
        menuSectionId: '0a221d25-16b8-4e0b-83a4-8950e098bf5d',
        displayOrder: 8
      },
      {
        id: 'ed56c877-d7f1-4caa-95b2-bd8e506d3a47',
        menuItemId: '45ef18ca-f236-4311-9020-969951357045',
        menuSectionId: '0a221d25-16b8-4e0b-83a4-8950e098bf5d',
        displayOrder: 9
      },
      {
        id: '18fd0181-225e-4361-aadb-ea7d1ad0fbc4',
        menuItemId: 'd6caf06a-be80-4d84-b755-3aa948bb01f6',
        menuSectionId: '0a221d25-16b8-4e0b-83a4-8950e098bf5d',
        displayOrder: 10
      },
      {
        id: 'cdacba0e-9805-4fa7-9383-be0e45997efc',
        menuItemId: '97a5e269-6fd7-4b59-aeb3-fcb518422dfe',
        menuSectionId: '0a221d25-16b8-4e0b-83a4-8950e098bf5d',
        displayOrder: 11
      },
      {
        id: '9312dccb-c2fa-4bc9-856d-264dbfb3c960',
        menuItemId: '9006ff98-ea26-4436-b3ab-699eb2be4188',
        menuSectionId: '723ac5dc-3e62-4492-b9cf-dae6768388b8',
        displayOrder: 1
      },
      {
        id: '300eac05-18e8-4ba5-8fc8-fd5d1c22dc15',
        menuItemId: 'e9443242-3ef6-4eea-a60f-3ea4debffce3',
        menuSectionId: '723ac5dc-3e62-4492-b9cf-dae6768388b8',
        displayOrder: 2
      },
      {
        id: '81f04c48-c2e1-443f-9a67-43760c3ce25e',
        menuItemId: '382716c9-4f4d-41c0-bcc9-4fd58edd2e40',
        menuSectionId: '723ac5dc-3e62-4492-b9cf-dae6768388b8',
        displayOrder: 3
      },
      {
        id: 'fd8723da-e07e-450c-b571-7fa7a31e2867',
        menuItemId: 'd59660d8-b034-4cc4-8bc0-b0e6ed4c6603',
        menuSectionId: '723ac5dc-3e62-4492-b9cf-dae6768388b8',
        displayOrder: 4
      },
      {
        id: '3c234132-de25-4fc3-99af-2deba59d0a25',
        menuItemId: '05680816-8934-4d8d-bb07-1a2c71da24f6',
        menuSectionId: '723ac5dc-3e62-4492-b9cf-dae6768388b8',
        displayOrder: 5
      },
      {
        id: '05057656-534d-4612-b7af-997c566290b3',
        menuItemId: '835a79fe-b4cf-444d-9bc7-fd90bba43e0e',
        menuSectionId: '723ac5dc-3e62-4492-b9cf-dae6768388b8',
        displayOrder: 6
      },
      {
        id: 'c9541f40-3c36-40fd-ae12-815a11b7944d',
        menuItemId: '97b9c618-420c-4677-b3c4-4b6a15e79cf1',
        menuSectionId: 'e40d1c89-6cc8-4239-b4b3-6d8f67cd0ffd',
        displayOrder: 1
      },
      {
        id: '60ca4669-8b3a-44dd-abc3-9ab747549c7c',
        menuItemId: 'fff307b9-a957-49d2-bb8b-27fb6c5aacaa',
        menuSectionId: 'e40d1c89-6cc8-4239-b4b3-6d8f67cd0ffd',
        displayOrder: 2
      },
      {
        id: '0dc09e4b-1bb0-4749-868c-abb5667a2303',
        menuItemId: 'ffd8036c-98c0-4241-a933-c0e9d7920546',
        menuSectionId: 'e40d1c89-6cc8-4239-b4b3-6d8f67cd0ffd',
        displayOrder: 3
      },
      {
        id: '4c6df218-f2b4-462e-a3df-c9eb838a805a',
        menuItemId: 'd6e0d463-6f8f-40e7-8592-229d2644ea86',
        menuSectionId: 'e40d1c89-6cc8-4239-b4b3-6d8f67cd0ffd',
        displayOrder: 4
      },
      {
        id: 'b8cb0553-9c4c-418d-87e1-c0aaa417b76f',
        menuItemId: '00a9c41c-af55-4792-9d2f-690c543d0fde',
        menuSectionId: 'e40d1c89-6cc8-4239-b4b3-6d8f67cd0ffd',
        displayOrder: 5
      },
      {
        id: 'c870ba61-c468-4a5d-8f58-e71364a2803d',
        menuItemId: '7fc35445-defc-4f3c-937d-d98516cd0355',
        menuSectionId: 'e40d1c89-6cc8-4239-b4b3-6d8f67cd0ffd',
        displayOrder: 6
      },
      {
        id: '0d4aee60-94db-4625-a780-b6d41a962f82',
        menuItemId: '85e36fd3-1257-443e-86f1-9b436dbdc06a',
        menuSectionId: '8bfc470f-65e9-4684-a009-8d5aeec68038',
        displayOrder: 1
      },
      {
        id: '31f6b13f-7868-4a46-98c8-61da83ebdd3d',
        menuItemId: '9a54d5e8-e1dd-4d27-8b89-3d589cb4c3b7',
        menuSectionId: '8bfc470f-65e9-4684-a009-8d5aeec68038',
        displayOrder: 2
      },
      {
        id: '3820e6e8-5105-42a6-8c70-8e042ec72077',
        menuItemId: '9e732e34-bf7b-4470-8c67-a98e229f9b7e',
        menuSectionId: '8bfc470f-65e9-4684-a009-8d5aeec68038',
        displayOrder: 3
      },
      {
        id: 'd31c20f2-e09a-45f7-9e91-b4289ff13273',
        menuItemId: '75acee60-a5ae-43cd-a573-6314fbe01d30',
        menuSectionId: '8bfc470f-65e9-4684-a009-8d5aeec68038',
        displayOrder: 4
      },
      {
        id: 'd06ca9d2-bc48-439a-ae37-63a265f432e4',
        menuItemId: '3ecdfb29-7e90-40bd-a579-09a830c2d189',
        menuSectionId: '8bfc470f-65e9-4684-a009-8d5aeec68038',
        displayOrder: 5
      },
      {
        id: 'f6d6e217-b00d-477f-b91f-4746520cf61d',
        menuItemId: '1ee57a1f-7857-418e-b85b-7158846072ad',
        menuSectionId: 'f21d6cd8-a628-4747-8183-5f1df9347362',
        displayOrder: 1
      },
      {
        id: '93ed3dd7-a1b2-43e4-b6bd-1a510a9e262c',
        menuItemId: '26628a60-db8f-46a0-ad89-54a34a177403',
        menuSectionId: 'f21d6cd8-a628-4747-8183-5f1df9347362',
        displayOrder: 2
      },
      {
        id: '38043fe9-a7c4-4dc8-ae31-1935c505494c',
        menuItemId: '21fce716-57cd-4740-8ed0-7cfe66c679ba',
        menuSectionId: 'f21d6cd8-a628-4747-8183-5f1df9347362',
        displayOrder: 3
      },
      {
        id: 'c99e15bd-48a2-48b0-915b-bfaf1995a5cf',
        menuItemId: '5432a649-a822-4650-9270-9e07c1b6c80d',
        menuSectionId: 'f21d6cd8-a628-4747-8183-5f1df9347362',
        displayOrder: 4
      },
      {
        id: 'a852cd63-6a37-4213-ba21-cc4f939e2236',
        menuItemId: 'a124e68d-a32a-4fd9-bf6f-219a9a0af236',
        menuSectionId: 'da67951d-4aed-495b-8f00-2e5b860ab2f3',
        displayOrder: 1
      },
      {
        id: 'adbcac5e-9561-43b5-b124-c65a023eb40c',
        menuItemId: '11d57957-4e87-4408-af07-ea3dd39345b7',
        menuSectionId: 'da67951d-4aed-495b-8f00-2e5b860ab2f3',
        displayOrder: 2
      },
      {
        id: 'fd24a8ab-d921-4330-bcc4-a40d07dbc6b1',
        menuItemId: '94164298-1a61-44fd-982b-67985a2aeeaf',
        menuSectionId: '6dc451c7-4b10-4b14-ac12-6e7f060832a5',
        displayOrder: 1
      },
      {
        id: 'a48f2681-1b0a-40f0-aafe-60f7b9e1c150',
        menuItemId: 'fe5d8973-25b6-4bc6-8592-972ea2247a75',
        menuSectionId: '6dc451c7-4b10-4b14-ac12-6e7f060832a5',
        displayOrder: 2
      },
      {
        id: 'f4fd8de0-c3a4-4d26-948f-e4bac2c753ac',
        menuItemId: 'cd453d92-ab17-43cd-86ea-e5aa7277f009',
        menuSectionId: '6dc451c7-4b10-4b14-ac12-6e7f060832a5',
        displayOrder: 3
      },
      {
        id: 'b16d4610-5671-4ba6-96b9-bb773edad93c',
        menuItemId: '7fa59ed0-fdd3-4a9f-9ddb-7839960c8528',
        menuSectionId: '8d3e65b9-922a-4b9e-aa64-ff2ef03a6138',
        displayOrder: 1
      },
      {
        id: 'e141eeae-e242-4850-88b5-72d64513d1c8',
        menuItemId: '543efd70-2d42-44f7-bbe9-d3c2b2977da2',
        menuSectionId: '8d3e65b9-922a-4b9e-aa64-ff2ef03a6138',
        displayOrder: 2
      },
      {
        id: '3896da6d-7025-45b7-8bb7-5091b4f92ad7',
        menuItemId: '6cb95e0d-a748-41d7-b5fc-8057ef0e8850',
        menuSectionId: 'd77fa069-acb8-42db-8fda-957eeb94bbba',
        displayOrder: 1
      },
      {
        id: '72358f71-d035-4b2c-ba60-fae833b2ce42',
        menuItemId: '68bf5882-e9d2-4f61-8cce-e2ead6bf410a',
        menuSectionId: 'f8365a43-1190-49a1-8fa0-ed8071c7dd50',
        displayOrder: 1
      },
      {
        id: '1e103aac-a527-454d-bc0b-b8a6d60e6075',
        menuItemId: '629fdbe6-54db-426f-bef0-af565d70ff24',
        menuSectionId: '762d56d0-908d-42c6-9554-a088404a4123',
        displayOrder: 1
      },
      {
        id: 'df68fbcc-7b28-4f85-91c2-131efb27c361',
        menuItemId: '12610a34-5588-43b9-9c54-449eebdeff4b',
        menuSectionId: '762d56d0-908d-42c6-9554-a088404a4123',
        displayOrder: 2
      },
      {
        id: '97071043-51d8-4bb8-a15e-a39d7017ab4e',
        menuItemId: '12860f05-47e6-4542-91db-fd284fe31e64',
        menuSectionId: '762d56d0-908d-42c6-9554-a088404a4123',
        displayOrder: 3
      },
      {
        id: 'c483da24-2e2e-448c-94c0-01cbbaa46672',
        menuItemId: '7268da0d-d3d9-45b5-87f2-354b204b6220',
        menuSectionId: '762d56d0-908d-42c6-9554-a088404a4123',
        displayOrder: 4
      },
      {
        id: '562e2d0f-3a17-43f0-bbaa-13c4db2121c6',
        menuItemId: '11b84b84-fe5c-45e5-a668-0eaa76c23ffc',
        menuSectionId: '93ada6dc-13a1-4fac-836b-3f69a95c5b92',
        displayOrder: 1
      },
      {
        id: '3f8a4132-a3f4-40da-a469-6d88beab6840',
        menuItemId: '8a4833e4-c536-41be-ad3c-77893c81c52b',
        menuSectionId: '843d146c-06f5-4528-8e16-252d2746be97',
        displayOrder: 1
      },
      {
        id: 'f702754c-acd8-4771-990d-a958b431d9be',
        menuItemId: 'f62382f9-095b-4b55-b882-d38bb5f32e6d',
        menuSectionId: '843d146c-06f5-4528-8e16-252d2746be97',
        displayOrder: 2
      },
      {
        id: '13c627a8-6b0c-4694-a3db-78948029a31c',
        menuItemId: '491022eb-739e-4b76-9f0d-4559376bf06b',
        menuSectionId: '843d146c-06f5-4528-8e16-252d2746be97',
        displayOrder: 3
      },
      {
        id: '2d741632-3c3c-4c48-b0f3-31e695efc27d',
        menuItemId: 'd6e9c7b1-55cb-4b14-af71-2ad613eddbef',
        menuSectionId: '669c1b19-098b-48ae-bfd4-3d83eff300b9',
        displayOrder: 1
      },
      {
        id: '542ac743-0a29-473a-b156-4899ece8ccfa',
        menuItemId: 'e1d7d572-6a85-4ac6-8703-319af65ace50',
        menuSectionId: '7a594515-a713-4d79-a026-bc57b6a7d0a1',
        displayOrder: 1
      },
      {
        id: '2a578614-5e15-4f0f-a6d1-2bdc4e09bfcf',
        menuItemId: 'f896f76c-016d-4b38-afec-6ad0fceaabe4',
        menuSectionId: '7a594515-a713-4d79-a026-bc57b6a7d0a1',
        displayOrder: 2
      },
      {
        id: 'f6b7c4e7-3acd-4b6b-94ba-a7ff20bb9a22',
        menuItemId: '3e8ef4d1-49db-4f03-8dbb-74812c556062',
        menuSectionId: '7a594515-a713-4d79-a026-bc57b6a7d0a1',
        displayOrder: 3
      },
      {
        id: 'f58d9a40-12b1-497a-91ad-75fd4eb711e2',
        menuItemId: 'cddbfed3-a06f-4f52-ac5b-9fc221af3d9c',
        menuSectionId: '7a594515-a713-4d79-a026-bc57b6a7d0a1',
        displayOrder: 4
      },
      {
        id: '2b258979-c1d0-491b-9798-72e4829c908f',
        menuItemId: '9076a825-7d92-471a-808e-dcda3b2b2920',
        menuSectionId: '7a594515-a713-4d79-a026-bc57b6a7d0a1',
        displayOrder: 5
      },
      {
        id: '06af7ef1-9981-435b-a33e-9013a97fa409',
        menuItemId: '99df16f5-c90a-406c-b306-2d048030aefa',
        menuSectionId: '7a594515-a713-4d79-a026-bc57b6a7d0a1',
        displayOrder: 6
      },
      {
        id: '0b8046f7-312d-4e67-8934-92f4ccf38313',
        menuItemId: '74fa5ed7-3739-4556-ad4a-dc10b0a8e573',
        menuSectionId: '90bec1bc-c6c4-457b-bb33-4e96c7dfaccf',
        displayOrder: 1
      },
      {
        id: 'b6f67381-9baf-4657-aca2-cc677db20707',
        menuItemId: 'dacce1c3-f5ab-45f8-b5b3-e3ddfa36f2bd',
        menuSectionId: '90bec1bc-c6c4-457b-bb33-4e96c7dfaccf',
        displayOrder: 2
      },
      {
        id: '4e8739ab-fe15-4030-873b-01f698d38dff',
        menuItemId: '62d012cd-e1b5-4e35-b13b-ed885d1bed20',
        menuSectionId: '095705dc-6e27-4408-b784-221f974fc5f8',
        displayOrder: 1
      },
      {
        id: '819fdb35-1a10-4b4e-af13-696748e3295f',
        menuItemId: '880311a3-6abf-414e-bff3-e5b87d1369f1',
        menuSectionId: '095705dc-6e27-4408-b784-221f974fc5f8',
        displayOrder: 2
      },
      {
        id: '17b506f7-7de3-441b-a474-483b2b17e61c',
        menuItemId: '2d083c8f-3fb2-4b2b-a066-8d6ac78a8592',
        menuSectionId: '095705dc-6e27-4408-b784-221f974fc5f8',
        displayOrder: 3
      },
      {
        id: 'e06dccc4-0644-40a9-9d3d-40866e7148ed',
        menuItemId: '7b564638-ab79-4e30-91e0-33e10eb44437',
        menuSectionId: '095705dc-6e27-4408-b784-221f974fc5f8',
        displayOrder: 4
      },
      {
        id: '96a764bb-15a8-4826-9773-af98d569cbf3',
        menuItemId: 'dd318c4f-7bd0-4626-bb0e-6b12d8dea36b',
        menuSectionId: '095705dc-6e27-4408-b784-221f974fc5f8',
        displayOrder: 5
      },
      {
        id: '568fee0b-c6f2-40a8-b7bd-3b6879204f45',
        menuItemId: 'e80aa033-9ffa-4fda-90d6-d3828e11b7f7',
        menuSectionId: '124dfcde-b8ce-4a1f-b2e2-1f48b0477159',
        displayOrder: 1
      },
      {
        id: 'd07093cc-ec48-47a5-9311-9fbfd52564a5',
        menuItemId: '8471de27-cb44-4c14-b0df-9198ae9f68ef',
        menuSectionId: '124dfcde-b8ce-4a1f-b2e2-1f48b0477159',
        displayOrder: 2
      },
      {
        id: '9f451652-6d49-44e1-862e-3206ddc3589d',
        menuItemId: '64f46c86-d240-4dfe-a341-19fa09891466',
        menuSectionId: '124dfcde-b8ce-4a1f-b2e2-1f48b0477159',
        displayOrder: 3
      },
      {
        id: 'aa71411c-a637-4dfc-833e-13f8b1f14691',
        menuItemId: '0d63edec-627d-4b61-bdcb-64a5eaa190d6',
        menuSectionId: '124dfcde-b8ce-4a1f-b2e2-1f48b0477159',
        displayOrder: 4
      },
      {
        id: '84829e08-f335-40b7-a042-d306fb823796',
        menuItemId: '809d8742-d235-4246-90c6-c6eff9e592b6',
        menuSectionId: '4353e3e5-4322-443c-ae06-d1babfb4dda3',
        displayOrder: 1
      },
      {
        id: '535b6e62-46e7-408f-9329-932eae8efe9b',
        menuItemId: '7ef98c34-5b1d-4085-8d52-efcdbdd106af',
        menuSectionId: '4353e3e5-4322-443c-ae06-d1babfb4dda3',
        displayOrder: 2
      },
      {
        id: '60d2e2ea-8ac6-46f1-b66e-b4ce8bb8150e',
        menuItemId: 'b44f8e4a-d2db-4bac-8808-8c8b249e2ff2',
        menuSectionId: '4353e3e5-4322-443c-ae06-d1babfb4dda3',
        displayOrder: 3
      },
      {
        id: 'c31f9808-e922-442b-9247-504d239a8db8',
        menuItemId: '6c89293d-8a79-4b01-ad3b-0687b7644caa',
        menuSectionId: '4353e3e5-4322-443c-ae06-d1babfb4dda3',
        displayOrder: 4
      },
      {
        id: '82a9f18e-7d21-4ed9-8063-bfdc7d3c4f6e',
        menuItemId: '90a63d4a-f0f9-440e-85cd-e04170a05ad5',
        menuSectionId: '4353e3e5-4322-443c-ae06-d1babfb4dda3',
        displayOrder: 5
      },
      {
        id: '2f160f38-5413-4793-9568-7306ba5a5a8f',
        menuItemId: '91e8bce3-8dbe-4197-ab9b-df8670379108',
        menuSectionId: '3b4dd8b2-6ff7-405d-a6df-6c03c97f0886',
        displayOrder: 1
      },
      {
        id: '34bab72d-5dd2-4d5b-8a8d-9e48d34b8a24',
        menuItemId: '392b9e70-823b-45c4-ada0-6d57a8fad50a',
        menuSectionId: '3b4dd8b2-6ff7-405d-a6df-6c03c97f0886',
        displayOrder: 2
      },
      {
        id: 'f2a0690c-863b-481d-9fd6-83b43602efbe',
        menuItemId: '2a943491-b3ab-4bda-949c-8d097a53dac2',
        menuSectionId: '3b4dd8b2-6ff7-405d-a6df-6c03c97f0886',
        displayOrder: 3
      },
      {
        id: '51ea85dd-83c0-49b5-ac65-e7d7bd1ef312',
        menuItemId: '58e15c9f-ce8d-479a-8627-5b81905dc812',
        menuSectionId: 'fc8d4a1d-4dcb-4d7f-844c-40a6f9fd3c24',
        displayOrder: 1
      },
      {
        id: '8ed86fa5-88ef-4e88-814f-18799f3fca00',
        menuItemId: 'ac7b50b0-1395-4b2f-9d66-cdb105daa324',
        menuSectionId: 'fc8d4a1d-4dcb-4d7f-844c-40a6f9fd3c24',
        displayOrder: 2
      },
      {
        id: 'aad3d345-cc5a-4080-8328-817fae2eab09',
        menuItemId: 'be4ec890-b0bd-4f24-b787-35376caf4026',
        menuSectionId: 'fc8d4a1d-4dcb-4d7f-844c-40a6f9fd3c24',
        displayOrder: 3
      },
      {
        id: '382388ec-6dee-4ca0-a2d8-baf02e253292',
        menuItemId: 'd97b29d7-444e-4b9b-9d3c-1651c2c6d05b',
        menuSectionId: 'fc8d4a1d-4dcb-4d7f-844c-40a6f9fd3c24',
        displayOrder: 4
      },
      {
        id: '39cc60e5-05b6-449c-bc4d-5eb98c399937',
        menuItemId: '8e4038ef-ca36-4056-9d12-27eaa01927fc',
        menuSectionId: 'c7eed0b9-a883-49e4-b4ef-c5f29a2e361a',
        displayOrder: 1
      },
      {
        id: '9d1b0821-db65-4a4a-aaaf-fd6915a8d66c',
        menuItemId: '9a5c6dab-87f5-4837-9be0-e4457ca30fbf',
        menuSectionId: '87687b3a-8217-4441-bbc7-b34606704246',
        displayOrder: 1
      },
      {
        id: 'f156fc30-c564-483a-bb1e-66584712a36c',
        menuItemId: '5ad26e45-0cfb-47fd-b073-a4da1472f932',
        menuSectionId: '87687b3a-8217-4441-bbc7-b34606704246',
        displayOrder: 2
      },
      {
        id: '06429630-76a4-4524-9668-f882828a6874',
        menuItemId: 'f9d380b2-513f-498e-897a-239fe6fcab6e',
        menuSectionId: '87687b3a-8217-4441-bbc7-b34606704246',
        displayOrder: 3
      },
      {
        id: '3f73e920-5a2f-469b-a349-2aed3843a154',
        menuItemId: 'a94986c8-f8df-4ff0-809c-51601d8b2630',
        menuSectionId: '87687b3a-8217-4441-bbc7-b34606704246',
        displayOrder: 4
      },
      {
        id: 'e10ad14d-836c-4e5c-9cfc-5ad55bacef04',
        menuItemId: '7356483e-6d7f-4675-af0a-8ddf93aee0b5',
        menuSectionId: '87687b3a-8217-4441-bbc7-b34606704246',
        displayOrder: 5
      },
      {
        id: '4583726a-4b9d-4f70-b6fc-883fb6f7aca3',
        menuItemId: '7bfcd8b9-e7ba-4c54-8515-73268c5025f3',
        menuSectionId: '87687b3a-8217-4441-bbc7-b34606704246',
        displayOrder: 6
      },
      {
        id: '867f1106-b947-4184-a811-dcceb797ab0a',
        menuItemId: '06794a8a-4f26-45ba-9c2d-6c64313b4074',
        menuSectionId: '87687b3a-8217-4441-bbc7-b34606704246',
        displayOrder: 7
      },
      {
        id: '285f2314-f32a-4a0c-923c-073d12d47748',
        menuItemId: '99078cb3-b981-425a-955f-0a545f87c968',
        menuSectionId: '87687b3a-8217-4441-bbc7-b34606704246',
        displayOrder: 8
      },
      {
        id: 'a92e3a77-5d9e-4450-ab92-568904381f1e',
        menuItemId: 'd6382085-c9de-4b1b-984c-b7d2fd5e9947',
        menuSectionId: '87687b3a-8217-4441-bbc7-b34606704246',
        displayOrder: 9
      },
      {
        id: 'f01b6ed1-2b34-48f9-9f14-9fe3a2565f7b',
        menuItemId: '6eedaeeb-a34a-4c1e-8fdb-198e5b613a82',
        menuSectionId: '87687b3a-8217-4441-bbc7-b34606704246',
        displayOrder: 10
      },
      {
        id: 'b3fb6489-7c0c-412e-a249-726f584e7885',
        menuItemId: '896847bb-9b7f-4cdf-8956-f08d4d2581a0',
        menuSectionId: '87687b3a-8217-4441-bbc7-b34606704246',
        displayOrder: 11
      },
      {
        id: '43bdee53-145a-4fc5-8bb1-e2d1f1c15eb8',
        menuItemId: 'fe81a5ec-a403-4a3b-b956-3dd911448a2a',
        menuSectionId: '87687b3a-8217-4441-bbc7-b34606704246',
        displayOrder: 12
      },
      {
        id: '06d4d588-7398-475e-b340-de4bcc72d6b8',
        menuItemId: '2a632cd9-be19-4f61-878a-220faad2a43d',
        menuSectionId: '4afa3644-aa57-447d-9cb9-4f4bf33e1760',
        displayOrder: 1
      },
      {
        id: '446a91fe-1252-4b25-85d8-54a06f6e7af4',
        menuItemId: '8505f036-148f-413e-95c3-094b0d2c3328',
        menuSectionId: '4afa3644-aa57-447d-9cb9-4f4bf33e1760',
        displayOrder: 2
      },
      {
        id: '9842257a-b649-4818-9366-e286acd31528',
        menuItemId: 'a79700d1-ebb4-4258-a53b-5ae0ed9989d6',
        menuSectionId: '4afa3644-aa57-447d-9cb9-4f4bf33e1760',
        displayOrder: 3
      },
      {
        id: '8c4f5769-2634-46dd-96a0-f093b9909231',
        menuItemId: 'b30e624b-aebf-40ae-b010-f9b17becea4f',
        menuSectionId: '601553e5-0cd8-4d97-8f23-91781218c546',
        displayOrder: 1
      },
      {
        id: 'c75e4f4d-7bd7-45f3-b105-7a488203b863',
        menuItemId: '6895afa5-7e76-4982-802b-34d0c089c263',
        menuSectionId: '601553e5-0cd8-4d97-8f23-91781218c546',
        displayOrder: 2
      },
      {
        id: '4c3a0681-0e38-4942-a4cc-ffc8d0575348',
        menuItemId: 'e9e7a538-1176-4923-95a3-46c7c3c1d885',
        menuSectionId: '601553e5-0cd8-4d97-8f23-91781218c546',
        displayOrder: 3
      },
      {
        id: '805c8478-31d7-483f-8e41-a2d5ab955ebd',
        menuItemId: '588eb4dd-dcab-4e80-93db-3a4d1882f959',
        menuSectionId: 'a67691f1-fa7b-4298-be9e-86528b948d75',
        displayOrder: 1
      },
      {
        id: '684fbe67-d02c-4f7d-b4c5-519d97b9e9fc',
        menuItemId: 'd41bb19a-1741-45f7-a9b6-51f9cbd3e15e',
        menuSectionId: 'a67691f1-fa7b-4298-be9e-86528b948d75',
        displayOrder: 2
      },
      {
        id: '844915c6-19c6-40cf-835f-2604438933df',
        menuItemId: 'aa11d30c-b007-4400-9c7c-8e2590e8ed20',
        menuSectionId: 'a67691f1-fa7b-4298-be9e-86528b948d75',
        displayOrder: 3
      },
      {
        id: '928982e3-e9cd-4122-9263-d7e13d21480b',
        menuItemId: 'e0d958f6-71a3-460a-8617-9cd3acac3d12',
        menuSectionId: 'a67691f1-fa7b-4298-be9e-86528b948d75',
        displayOrder: 4
      },
      {
        id: '64825aa0-79bd-4bdc-86fe-57c55e0eb99b',
        menuItemId: '0f46b83b-eec0-4eb7-af91-e5f7ba4ed617',
        menuSectionId: 'a67691f1-fa7b-4298-be9e-86528b948d75',
        displayOrder: 5
      },
      {
        id: '9391c832-1196-4dc3-9439-aabc97b82405',
        menuItemId: '6138867e-0a5f-478b-b430-1bbb024dcfad',
        menuSectionId: 'a67691f1-fa7b-4298-be9e-86528b948d75',
        displayOrder: 6
      },
      {
        id: '377e776c-c493-4352-a12b-69c55c9fc44b',
        menuItemId: '31d82290-33d5-4a43-9202-462842527f75',
        menuSectionId: 'a67691f1-fa7b-4298-be9e-86528b948d75',
        displayOrder: 7
      },
      {
        id: 'ef71b485-cf12-4aba-8e81-6cc15ed639e0',
        menuItemId: '2f960594-bd33-4766-aacf-3191a6b9087c',
        menuSectionId: 'a67691f1-fa7b-4298-be9e-86528b948d75',
        displayOrder: 8
      },
      {
        id: 'cd6aa390-ebc8-475d-8df6-b9b30fc3012c',
        menuItemId: '3ae1cad2-d480-40c3-a510-43724f80fbee',
        menuSectionId: 'faa5ac6c-98fe-4c1e-b4c6-0cec704db177',
        displayOrder: 1
      },
      {
        id: 'e3176b15-572e-413e-9fad-1221c703c9c8',
        menuItemId: '92f55592-60f0-4600-aa27-6627bdcb98e7',
        menuSectionId: 'faa5ac6c-98fe-4c1e-b4c6-0cec704db177',
        displayOrder: 2
      },
      {
        id: '4504e02e-853c-4135-9916-f4041fe4e6fa',
        menuItemId: 'db77a518-707e-4e45-aaa6-0f18c2d269df',
        menuSectionId: 'faa5ac6c-98fe-4c1e-b4c6-0cec704db177',
        displayOrder: 3
      },
      {
        id: '1ad83ce3-5767-434d-be89-0e8aba535b27',
        menuItemId: '4308f761-094a-4434-9d1f-0ff930118b4c',
        menuSectionId: '7462cad7-8892-47f6-b4fc-923a9cfe26c0',
        displayOrder: 1
      },
      {
        id: '12d2f15d-7aa6-4ca8-84ce-5e1950b8ae41',
        menuItemId: '94ef22a1-ea89-45db-91b9-428180685557',
        menuSectionId: 'ff2c0e0f-8c5d-4f58-b716-37b91657673d',
        displayOrder: 1
      },
      {
        id: '658d0ddd-f8c5-45f5-b7e6-5a8d7eaa517d',
        menuItemId: '96c04370-8118-4dcf-8a38-9e24aa1b618f',
        menuSectionId: 'ff2c0e0f-8c5d-4f58-b716-37b91657673d',
        displayOrder: 2
      },
      {
        id: '5ce04350-8100-43c9-a5ad-01528d926272',
        menuItemId: 'fe92b67e-8135-4047-9bfc-21c9e4f8e562',
        menuSectionId: 'ff2c0e0f-8c5d-4f58-b716-37b91657673d',
        displayOrder: 3
      },
      {
        id: 'b53bbc77-11d6-401a-a7f6-7ef57ceb4564',
        menuItemId: '7591f561-5a87-46f9-81bd-3f749c5c6f54',
        menuSectionId: 'ff2c0e0f-8c5d-4f58-b716-37b91657673d',
        displayOrder: 4
      },
      {
        id: '7107159c-a805-4005-9d81-f9e47914670e',
        menuItemId: '789e5a4c-f7db-464a-9875-1c40893f24e7',
        menuSectionId: 'ff2c0e0f-8c5d-4f58-b716-37b91657673d',
        displayOrder: 5
      },
      {
        id: 'd0952c6f-9efa-45b9-af7d-f1e847450588',
        menuItemId: '9418a652-4223-4699-b693-f35443476afe',
        menuSectionId: 'ff2c0e0f-8c5d-4f58-b716-37b91657673d',
        displayOrder: 6
      },
      {
        id: '6f67ae19-8f05-471e-84a9-3001e0e7d245',
        menuItemId: '7deef02d-c19b-45d5-a2b2-423d5b16aa40',
        menuSectionId: 'ff2c0e0f-8c5d-4f58-b716-37b91657673d',
        displayOrder: 7
      },
      {
        id: '91e48a28-92a0-4904-ba77-716fd2a20538',
        menuItemId: '48452d4f-2305-4fe5-8e0b-7943f6762b3f',
        menuSectionId: 'ff2c0e0f-8c5d-4f58-b716-37b91657673d',
        displayOrder: 8
      },
      {
        id: '66bc9018-96cb-4975-afdc-bee2a372d7ad',
        menuItemId: 'b9381ea0-237d-4812-bb82-5adbc9e3657d',
        menuSectionId: '3e7c16c4-ab5f-44b8-9f4f-5bf5f0272889',
        displayOrder: 1
      },
      {
        id: 'f6f36ce5-4a3a-454e-b569-84b0e1ced10b',
        menuItemId: '02097b19-da5e-464d-af31-082d4d6709f0',
        menuSectionId: '3e7c16c4-ab5f-44b8-9f4f-5bf5f0272889',
        displayOrder: 2
      },
      {
        id: 'f49db5f5-c21b-4857-a281-34bdb28a4a30',
        menuItemId: 'b81c6b1f-46df-422f-b4e2-1e37071b943a',
        menuSectionId: '3e7c16c4-ab5f-44b8-9f4f-5bf5f0272889',
        displayOrder: 3
      },
      {
        id: '49027df5-6472-4fe2-b57c-738a2aea2d51',
        menuItemId: '0315e223-5f4f-443d-a7bd-3ba35f2055a0',
        menuSectionId: '3e7c16c4-ab5f-44b8-9f4f-5bf5f0272889',
        displayOrder: 4
      },
      {
        id: '8854bf70-76b2-4e34-b10f-cbe5d71e7829',
        menuItemId: '4b03c776-e9ed-4f1a-867e-377b3e12ba20',
        menuSectionId: '3e7c16c4-ab5f-44b8-9f4f-5bf5f0272889',
        displayOrder: 5
      },
      {
        id: 'd989f511-b839-48e5-bc04-d9200567ad2f',
        menuItemId: '55391e92-2812-4899-b548-c0366c5bd0b9',
        menuSectionId: '276bdfd7-0e84-4167-a469-750fc3a364ab',
        displayOrder: 1
      },
      {
        id: '74425f6a-ba9c-4835-aa48-e53caf4a6d9a',
        menuItemId: '16c4b491-c43f-4083-813e-830a9ce73ee9',
        menuSectionId: '8f370fb1-e568-4924-b34a-16e1ee7a39ce',
        displayOrder: 1
      },
      {
        id: '8ad50fab-17b8-480f-9e1a-566958a04063',
        menuItemId: '26836fe2-aaec-4f68-a7cd-32e7996cdf4f',
        menuSectionId: '8f370fb1-e568-4924-b34a-16e1ee7a39ce',
        displayOrder: 2
      },
      {
        id: 'a6577f0d-4598-4ab6-978c-16397ed3ae3b',
        menuItemId: '6462260a-fec1-429d-be0a-cc8c9b7c781e',
        menuSectionId: '8f370fb1-e568-4924-b34a-16e1ee7a39ce',
        displayOrder: 3
      },
      {
        id: 'fd1a8834-1429-4722-9d04-92e74d1062bf',
        menuItemId: 'f35838f7-0560-4bbf-87a1-4aba11ca66ef',
        menuSectionId: '8f370fb1-e568-4924-b34a-16e1ee7a39ce',
        displayOrder: 4
      },
      {
        id: '5b208838-0b91-4653-b8dd-0d753a8f14f6',
        menuItemId: 'bee28a66-2050-4ff0-be0a-092dd763a3e2',
        menuSectionId: '8f370fb1-e568-4924-b34a-16e1ee7a39ce',
        displayOrder: 5
      },
      {
        id: 'fb0f0b26-70dc-4413-8323-5b7fadf3dd64',
        menuItemId: '3f5bcdb2-0b45-4500-b930-04abc2cd8ea0',
        menuSectionId: '8f370fb1-e568-4924-b34a-16e1ee7a39ce',
        displayOrder: 6
      },
      {
        id: '16efe4fe-9ba5-4cd2-b920-3977c9dd430d',
        menuItemId: 'd3eeb202-1d27-414b-a477-0c86b885f153',
        menuSectionId: '8f370fb1-e568-4924-b34a-16e1ee7a39ce',
        displayOrder: 7
      },
      {
        id: 'eb66a43e-7870-43d1-a83d-ed0742d35361',
        menuItemId: '9e8b61a6-7c14-4905-9796-303255fb72aa',
        menuSectionId: '8f370fb1-e568-4924-b34a-16e1ee7a39ce',
        displayOrder: 8
      },
      {
        id: 'e6159c6e-0ff4-4a5a-8999-d426e90288bd',
        menuItemId: '8cd409e1-2ef2-48a8-9e0f-259d21cd9cf8',
        menuSectionId: '8f370fb1-e568-4924-b34a-16e1ee7a39ce',
        displayOrder: 9
      },
      {
        id: '6fe7aac3-c1cb-4bde-bc69-32159ebd5a15',
        menuItemId: '6fbddc8d-5d28-46ef-87fa-78461c500a06',
        menuSectionId: '6551732b-1141-4a40-9b6e-97a4904397e8',
        displayOrder: 1
      },
      {
        id: 'ce628c88-d3f9-4d75-ae23-2ab6dcfbf0b9',
        menuItemId: 'd4b54f45-4029-4b75-8953-68e19e795de9',
        menuSectionId: '6551732b-1141-4a40-9b6e-97a4904397e8',
        displayOrder: 2
      },
      {
        id: '08dff479-65cd-452f-a027-c79b093c50fb',
        menuItemId: '84957f9e-eed5-47bd-87be-d134c4006fb5',
        menuSectionId: '6551732b-1141-4a40-9b6e-97a4904397e8',
        displayOrder: 3
      },
      {
        id: '4d43cf89-c395-4c22-afec-c49801960fc9',
        menuItemId: '6ac9b499-ed39-4f31-be0c-04ae815cb06d',
        menuSectionId: '6551732b-1141-4a40-9b6e-97a4904397e8',
        displayOrder: 4
      },
      {
        id: 'a116b28e-5292-40a1-9918-057fa70b053c',
        menuItemId: '286f2b42-9a67-4504-905a-975b00a5380f',
        menuSectionId: '6551732b-1141-4a40-9b6e-97a4904397e8',
        displayOrder: 5
      },
      {
        id: '5c8ef0ae-236f-4b92-aeb2-72c8b34e3436',
        menuItemId: 'cd84baf9-546e-4131-a454-bfaabbdfa8cd',
        menuSectionId: '6551732b-1141-4a40-9b6e-97a4904397e8',
        displayOrder: 6
      },
      {
        id: 'bb7fa709-5ac5-4a94-8aa1-70caa49425b8',
        menuItemId: '85977a5e-725d-4dd7-90f4-7d411a5c39f4',
        menuSectionId: '6551732b-1141-4a40-9b6e-97a4904397e8',
        displayOrder: 7
      },
      {
        id: '649fb088-e6d8-4e0f-b4dd-1da556d3da37',
        menuItemId: '620446b2-419d-4914-8d39-781dec4e0ab1',
        menuSectionId: '6551732b-1141-4a40-9b6e-97a4904397e8',
        displayOrder: 8
      },
      {
        id: 'befa977e-f2b7-4875-ab47-27d01b28a8cb',
        menuItemId: '74d9d2af-99c2-496a-9f77-55237d519c71',
        menuSectionId: '6551732b-1141-4a40-9b6e-97a4904397e8',
        displayOrder: 9
      },
      {
        id: '0625bb12-4e68-47a7-880c-82931990baaa',
        menuItemId: '5ebf6ab2-039f-4413-b272-b8fb978f19c3',
        menuSectionId: '6551732b-1141-4a40-9b6e-97a4904397e8',
        displayOrder: 10
      },
      {
        id: 'b910cbe2-d611-4ced-a211-3d90bddf34e3',
        menuItemId: 'f8db1029-76df-4055-a3a8-be81f4c15850',
        menuSectionId: '6551732b-1141-4a40-9b6e-97a4904397e8',
        displayOrder: 11
      },
      {
        id: 'bb5e254b-80b5-42e2-943b-46ac747de81b',
        menuItemId: '6c415367-e852-4541-9e7a-e926e05253cc',
        menuSectionId: '6551732b-1141-4a40-9b6e-97a4904397e8',
        displayOrder: 12
      },
      {
        id: 'd41d1f43-03ce-4b34-821c-49997a0fc546',
        menuItemId: '6d59ba2f-303e-4c05-a9d9-c297f53fc26b',
        menuSectionId: 'd7f8bb48-4deb-4557-8e1d-9b6a33409514',
        displayOrder: 1
      },
      {
        id: '7d8b5fd0-2d7d-43ca-9cb8-2c441121586e',
        menuItemId: '988208bf-eca3-409f-b8ec-ea0963a43a4a',
        menuSectionId: 'd7f8bb48-4deb-4557-8e1d-9b6a33409514',
        displayOrder: 2
      },
      {
        id: '049b9722-df89-460a-abac-db42f5ffd3f2',
        menuItemId: 'b4b7c24a-934e-41f7-a34f-22138baee824',
        menuSectionId: 'd7f8bb48-4deb-4557-8e1d-9b6a33409514',
        displayOrder: 3
      },
      {
        id: '588bdf35-daf1-4393-a2d1-de2845e6890c',
        menuItemId: '8259feff-3db2-452f-9888-eb13863c0533',
        menuSectionId: 'd7f8bb48-4deb-4557-8e1d-9b6a33409514',
        displayOrder: 4
      },
      {
        id: 'e8615d6b-ce4d-4ce1-b806-2e8b55b84cd5',
        menuItemId: 'a8f410d3-f2ed-4013-b8eb-d6a1dcebce77',
        menuSectionId: 'd7f8bb48-4deb-4557-8e1d-9b6a33409514',
        displayOrder: 5
      },
      {
        id: '1289d8a0-face-445a-8924-d1a56e02f95e',
        menuItemId: 'a57e93f3-10f9-4167-b635-8de3fc240d1d',
        menuSectionId: 'd7f8bb48-4deb-4557-8e1d-9b6a33409514',
        displayOrder: 6
      },
      {
        id: '30ad236a-660c-48d0-8874-8903e5b100ef',
        menuItemId: '5919bb43-cad5-47c7-b142-96a89c664bf4',
        menuSectionId: 'd7f8bb48-4deb-4557-8e1d-9b6a33409514',
        displayOrder: 7
      },
      {
        id: '2e052d3e-13fb-4c28-b961-815e1e157116',
        menuItemId: '7ffa83f5-6f0a-4ec0-9a9b-7c2c0b07f6ad',
        menuSectionId: '6a877b77-9a90-4e5a-abb9-96c967199335',
        displayOrder: 1
      },
      {
        id: 'b8b2db60-180b-4b02-800a-5fd955692a60',
        menuItemId: '1ea1b4f0-06a6-4bb4-9164-6833da370e48',
        menuSectionId: '6a877b77-9a90-4e5a-abb9-96c967199335',
        displayOrder: 2
      },
      {
        id: '291974dd-78f7-415d-84e6-8895e478ec63',
        menuItemId: '95eccaab-5aaf-468a-aca9-25da15aeaa9b',
        menuSectionId: '6a877b77-9a90-4e5a-abb9-96c967199335',
        displayOrder: 3
      },
      {
        id: '12716e8a-e9bd-4b5a-89c7-825c55198e28',
        menuItemId: 'f9235812-5daa-43b0-ac76-5f7527f14a2e',
        menuSectionId: '6a877b77-9a90-4e5a-abb9-96c967199335',
        displayOrder: 4
      },
      {
        id: 'ffcbac3f-931f-4baa-8ee1-b61e22b8db35',
        menuItemId: '43b7aaa4-64d9-4f0d-ac2b-8ac353a6bb73',
        menuSectionId: '6a877b77-9a90-4e5a-abb9-96c967199335',
        displayOrder: 5
      },
      {
        id: '033dd0e2-72e3-4c8f-8ee3-cf3b4f85236d',
        menuItemId: 'ee36759e-d67e-4964-ac9a-f3c226fa74dd',
        menuSectionId: '6a877b77-9a90-4e5a-abb9-96c967199335',
        displayOrder: 6
      },
      {
        id: '8af20e43-a538-4485-b7d8-500ab745b7c9',
        menuItemId: 'cf550c36-d1c6-4c4e-855f-8fc9cbe44bf4',
        menuSectionId: '628d3d06-6ac6-4496-9a57-874e0276a55f',
        displayOrder: 1
      },
      {
        id: '2f3a1f8f-c3fd-480b-a91a-c4a95f34a27d',
        menuItemId: 'e31ebe2b-3472-46a1-9936-7ea08783854a',
        menuSectionId: '628d3d06-6ac6-4496-9a57-874e0276a55f',
        displayOrder: 2
      },
      {
        id: '5a48d73f-8033-4289-893d-72acbd95f17e',
        menuItemId: '2a92e47f-bf37-46d6-b22e-7c17a6b2aa8d',
        menuSectionId: '628d3d06-6ac6-4496-9a57-874e0276a55f',
        displayOrder: 3
      },
      {
        id: '030abe12-7866-454e-a928-66f2289ebfd2',
        menuItemId: 'f54a49df-73b6-45cd-8b3c-0d08425f4f53',
        menuSectionId: '9cfdfcfc-ac01-44ee-ad07-b3f959b99640',
        displayOrder: 1
      },
      {
        id: 'f80e7e4f-500a-429a-9466-bffd3a1d54f1',
        menuItemId: '44bf595b-9b39-4efd-8df8-e49d19dac427',
        menuSectionId: '9cfdfcfc-ac01-44ee-ad07-b3f959b99640',
        displayOrder: 2
      },
      {
        id: 'cc8c6872-a250-4d58-9a78-876c42d95dd4',
        menuItemId: '866d7692-2865-41be-a838-db91d68b2071',
        menuSectionId: '36766192-8911-4558-80b9-23e101337404',
        displayOrder: 1
      },
      {
        id: '590fe186-b909-4a0a-9591-260427e191d6',
        menuItemId: '051a5b1f-baeb-48fd-bef0-eabdad9b0f77',
        menuSectionId: '36766192-8911-4558-80b9-23e101337404',
        displayOrder: 2
      },
      {
        id: 'be50fc8c-199a-447f-bd49-76a58242cad1',
        menuItemId: '40d152f8-1900-44ec-97e9-9d90c26746a0',
        menuSectionId: '36766192-8911-4558-80b9-23e101337404',
        displayOrder: 3
      },
      {
        id: '89119086-909d-4ec3-810c-7038b6c518e5',
        menuItemId: '8b231bdc-e6fe-4c23-b338-0dede9be7e11',
        menuSectionId: '36766192-8911-4558-80b9-23e101337404',
        displayOrder: 4
      },
      {
        id: '92821d62-f2f4-4b94-ae13-76a6c8082c11',
        menuItemId: '6251f0fe-d8cb-4c0b-93d9-d2c438144608',
        menuSectionId: '36766192-8911-4558-80b9-23e101337404',
        displayOrder: 5
      },
      {
        id: '8c83966f-79d2-4251-9940-6e1c0537599e',
        menuItemId: 'beba6ce2-308a-4e37-840b-d689b807e298',
        menuSectionId: '36766192-8911-4558-80b9-23e101337404',
        displayOrder: 6
      },
      {
        id: 'c6c51dde-4245-43e1-87fc-7c5bb5e82a17',
        menuItemId: '388f4a1f-b60e-44ac-b833-8ad6cfc491a0',
        menuSectionId: '36766192-8911-4558-80b9-23e101337404',
        displayOrder: 7
      },
      {
        id: 'c75f3c6f-9a79-4b8b-b46a-ae1bd0e8bf53',
        menuItemId: '28fe3f09-ee2f-44c5-bdab-94db58adaa75',
        menuSectionId: '36766192-8911-4558-80b9-23e101337404',
        displayOrder: 8
      },
      {
        id: 'f1d597ff-5948-4151-add1-424b49f4773f',
        menuItemId: 'bbf0e782-1a1a-4676-9949-7aff500c4b3f',
        menuSectionId: '36766192-8911-4558-80b9-23e101337404',
        displayOrder: 9
      },
      {
        id: '97259465-8f4e-4163-84bc-383d11d658ce',
        menuItemId: '06082644-fe01-4390-8421-6bda2491013f',
        menuSectionId: '36766192-8911-4558-80b9-23e101337404',
        displayOrder: 10
      },
      {
        id: 'b20d73c4-0e69-43f6-ad6f-5c6b6766ef8c',
        menuItemId: '78a8eca7-0326-4d55-af58-693f1636850c',
        menuSectionId: '36766192-8911-4558-80b9-23e101337404',
        displayOrder: 11
      },
      {
        id: '11278694-7d90-49b0-bcb5-837d72abdc27',
        menuItemId: 'e6fb39db-1093-4e00-ae96-c81b6e5001b8',
        menuSectionId: '36766192-8911-4558-80b9-23e101337404',
        displayOrder: 12
      },
      {
        id: 'e8924f16-2d81-4394-a014-bbd0c7aaf89d',
        menuItemId: 'aded7ff8-19d8-4c4c-8728-969f1dda65f2',
        menuSectionId: '0b75f0e9-70e2-4d11-ac96-9432d189b589',
        displayOrder: 1
      },
      {
        id: '4bc0788f-fec7-48d9-8549-2757617e1db9',
        menuItemId: '46e59c3f-7834-485a-b4e7-7b22692cfd89',
        menuSectionId: '0b75f0e9-70e2-4d11-ac96-9432d189b589',
        displayOrder: 2
      },
      {
        id: '4179e2b5-3bf7-4099-8c6d-cdce8fa07d2d',
        menuItemId: '0b8c112c-07b2-402e-84ca-53419046e268',
        menuSectionId: '0e8554b1-5e5c-4abc-9794-a5f91ff5c81d',
        displayOrder: 1
      },
      {
        id: '3a900a9a-86d5-4fab-817a-376fcd99b229',
        menuItemId: '382f3290-c5d3-45f4-813d-0cafd31cd56d',
        menuSectionId: '0e8554b1-5e5c-4abc-9794-a5f91ff5c81d',
        displayOrder: 2
      },
      {
        id: '78a1f988-f59f-4d57-b7c0-814e1be7ed4f',
        menuItemId: 'c80a0ac0-1f92-43da-916f-2eb11e203aa7',
        menuSectionId: '0e8554b1-5e5c-4abc-9794-a5f91ff5c81d',
        displayOrder: 3
      },
      {
        id: 'c7c84e93-f39b-4356-9bf4-bb017b7f08c1',
        menuItemId: '29b70c33-3ed5-48c3-9b68-faf4d9af7bcc',
        menuSectionId: '0e8554b1-5e5c-4abc-9794-a5f91ff5c81d',
        displayOrder: 4
      },
      {
        id: '163df368-1e52-4f84-98ad-1517685a63fa',
        menuItemId: 'f9db9d9e-570d-4253-985f-817b0e047c7f',
        menuSectionId: '0e8554b1-5e5c-4abc-9794-a5f91ff5c81d',
        displayOrder: 5
      },
      {
        id: '90616bf2-a9a2-40cb-9418-98b27b97eed5',
        menuItemId: '53dc4fbf-df08-499b-b773-3572e22f2fca',
        menuSectionId: '0e8554b1-5e5c-4abc-9794-a5f91ff5c81d',
        displayOrder: 6
      },
      {
        id: 'eadeb285-1dde-4291-996f-160fc286c701',
        menuItemId: '56e63fc8-a65e-4e06-aa2e-67dccc32694f',
        menuSectionId: '5d49079c-9b41-4262-b50d-a58ae666fcc2',
        displayOrder: 1
      },
      {
        id: '74ed00ec-f60d-4793-907a-2e62282f2dca',
        menuItemId: 'e496e0fc-1cf1-4f81-a560-b5d0f2e75aac',
        menuSectionId: '5d49079c-9b41-4262-b50d-a58ae666fcc2',
        displayOrder: 2
      },
      {
        id: '835c9afc-d54a-4f11-8cd7-bb5b08a48195',
        menuItemId: 'e9ab8cf5-6d61-4f5d-af51-c4ab910f9b82',
        menuSectionId: 'ebade368-a88f-4c0e-a869-f47f0b2f5d4a',
        displayOrder: 1
      },
      {
        id: '4e31880e-8212-4abe-9f74-3f16ccb3a4fb',
        menuItemId: '7d0afffd-01a5-44d3-84f7-ae7292feb7d4',
        menuSectionId: 'ebade368-a88f-4c0e-a869-f47f0b2f5d4a',
        displayOrder: 2
      },
      {
        id: '2ea1a761-6445-4a04-bf33-ba026f3a2cb7',
        menuItemId: '415357ac-be3f-4a04-9e15-6a58dec7fc86',
        menuSectionId: '15309fcb-8e96-4ba5-a9a2-ae7614f4f1c1',
        displayOrder: 1
      },
      {
        id: '133f4744-5cd7-467e-88ee-eaae59eb7f6a',
        menuItemId: '83ff0408-8661-487f-b826-b2f701e9ecbc',
        menuSectionId: '15309fcb-8e96-4ba5-a9a2-ae7614f4f1c1',
        displayOrder: 2
      },
      {
        id: '802b7ce8-528b-4017-96e1-8109fd873841',
        menuItemId: '5abf5230-e7e9-478a-8961-3ee88d442087',
        menuSectionId: '15309fcb-8e96-4ba5-a9a2-ae7614f4f1c1',
        displayOrder: 3
      },
      {
        id: '2c3356f0-ca3a-426f-81a1-3f145a01ee69',
        menuItemId: '15d49a2d-23a1-468d-b4d7-4ad1fe3f8671',
        menuSectionId: '15309fcb-8e96-4ba5-a9a2-ae7614f4f1c1',
        displayOrder: 4
      },
      {
        id: '3452485b-f4be-4557-a8ac-0120178df74f',
        menuItemId: 'a41d76c9-3afc-44a4-af62-b8e5aae53800',
        menuSectionId: '15309fcb-8e96-4ba5-a9a2-ae7614f4f1c1',
        displayOrder: 5
      },
      {
        id: 'c6eaba61-ffb4-4963-8b43-b92a3f04b7fc',
        menuItemId: '9d980a57-8f33-429a-b12d-2c5e7d7b0e2e',
        menuSectionId: '15309fcb-8e96-4ba5-a9a2-ae7614f4f1c1',
        displayOrder: 6
      },
      {
        id: '62bd1cfc-599d-4cb2-808d-c2e54421df29',
        menuItemId: '78687fe7-5793-477b-97b1-277f59734c7c',
        menuSectionId: 'e083bfea-ee78-45cd-a7ff-d038b15f48ab',
        displayOrder: 1
      },
      {
        id: '65d129b0-e4ff-4dea-993c-c98b6b3d7102',
        menuItemId: '45d12a7e-9933-473c-a206-e52ac8669d82',
        menuSectionId: 'e083bfea-ee78-45cd-a7ff-d038b15f48ab',
        displayOrder: 2
      },
      {
        id: 'c93ff953-7901-411c-a15e-915766c4d6c0',
        menuItemId: 'e37f1d0c-7a38-4f94-8fcb-8ea64c9cf5a4',
        menuSectionId: '4906272c-d063-426c-8b62-da0417eea9d9',
        displayOrder: 1
      },
      {
        id: '3301473b-024d-417b-9444-7cef0f225cd1',
        menuItemId: '28cf6eb3-75d8-45cf-9060-13719c3ff635',
        menuSectionId: '4906272c-d063-426c-8b62-da0417eea9d9',
        displayOrder: 2
      },
      {
        id: '7e3c0a4e-6ebd-4d88-b615-d645134a62c6',
        menuItemId: '801f1a9a-b41b-45b4-88d1-c6b75a359f5b',
        menuSectionId: '4906272c-d063-426c-8b62-da0417eea9d9',
        displayOrder: 3
      },
      {
        id: '355fb5c4-1651-4ece-966e-83641e65f99b',
        menuItemId: '10202191-e83b-4bf2-95a5-5212517fa79d',
        menuSectionId: '4906272c-d063-426c-8b62-da0417eea9d9',
        displayOrder: 4
      },
      {
        id: '9838ea2f-03e8-428e-a358-6d245f489fe0',
        menuItemId: '2e10df10-2b9b-4cb6-ad76-174f00a97aaf',
        menuSectionId: '4906272c-d063-426c-8b62-da0417eea9d9',
        displayOrder: 5
      },
      {
        id: 'ef0bbfe4-eb91-47cc-a656-72f29f62ab6c',
        menuItemId: '450163cf-fa14-49f7-b9e8-d6dae97ba906',
        menuSectionId: '4906272c-d063-426c-8b62-da0417eea9d9',
        displayOrder: 6
      },
      {
        id: '7ba2c24d-5fd9-473b-95f7-95dd4f2580e3',
        menuItemId: 'a0f99af4-11ee-40d3-bfa9-9e5f56fb954f',
        menuSectionId: '4906272c-d063-426c-8b62-da0417eea9d9',
        displayOrder: 7
      },
      {
        id: '96240cbd-568c-4882-9802-a4ad6763b18b',
        menuItemId: '4b24d4e0-b15e-4c13-90fe-382a6b802a27',
        menuSectionId: '4906272c-d063-426c-8b62-da0417eea9d9',
        displayOrder: 8
      },
      {
        id: '07384b99-ec09-462a-9e2f-79e82ab99a7a',
        menuItemId: 'c7b1032d-a68b-49c0-81c9-5ee4bbe97eae',
        menuSectionId: 'edff71c5-8072-41d3-9914-a87629bfea50',
        displayOrder: 1
      },
      {
        id: '338d6d71-3943-43f0-ac27-c3524b47b969',
        menuItemId: 'be0a79cf-877c-4200-951f-0a5b41ebd370',
        menuSectionId: 'edff71c5-8072-41d3-9914-a87629bfea50',
        displayOrder: 2
      },
      {
        id: '88dd017a-908a-44e2-80ba-6f0fc63aff4d',
        menuItemId: '370511ff-eda4-4f88-b73c-c784bb77330d',
        menuSectionId: 'edff71c5-8072-41d3-9914-a87629bfea50',
        displayOrder: 3
      },
      {
        id: 'e1959940-6817-40a9-98ab-e34e67f5f4a2',
        menuItemId: '29c87c83-a281-447f-9734-ceabaf0b46f6',
        menuSectionId: 'edff71c5-8072-41d3-9914-a87629bfea50',
        displayOrder: 4
      },
      {
        id: 'dcae6456-0df4-4df1-931f-79ac5577192b',
        menuItemId: 'e59dddb8-bc47-4c7b-8977-d3a1274eac73',
        menuSectionId: 'edff71c5-8072-41d3-9914-a87629bfea50',
        displayOrder: 5
      },
      {
        id: '116b51fb-25d7-430a-a174-5ff90e60e5a7',
        menuItemId: '7a98a84d-22ee-4ca2-bcef-9790acae6e3e',
        menuSectionId: 'edff71c5-8072-41d3-9914-a87629bfea50',
        displayOrder: 6
      },
      {
        id: 'd9d07cb9-fdc1-46f8-9ced-0fb05513e78a',
        menuItemId: '3ba00c24-3072-4572-a57d-bd41092fd57e',
        menuSectionId: 'edff71c5-8072-41d3-9914-a87629bfea50',
        displayOrder: 7
      },
      {
        id: 'b39929f8-60c4-4828-be6d-6353f64fae69',
        menuItemId: '8a8e144e-dd1f-4db8-804e-d8bda51fde90',
        menuSectionId: 'edff71c5-8072-41d3-9914-a87629bfea50',
        displayOrder: 8
      },
      {
        id: '88bba8d7-8057-4652-9cae-8dff00869316',
        menuItemId: '5ce5b2ca-5258-46e5-abcb-051b14575610',
        menuSectionId: 'edff71c5-8072-41d3-9914-a87629bfea50',
        displayOrder: 9
      },
      {
        id: '1852e835-f686-46de-acaf-95e5275a0c1f',
        menuItemId: '36d4397d-92f3-46c6-b03c-23f7b1522cdf',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 1
      },
      {
        id: '1a17e095-7633-48a0-9b8b-b3f960525a99',
        menuItemId: 'dfd0c9a6-a74c-4846-ba6b-00859fc7f160',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 2
      },
      {
        id: '73bc369e-cdaf-48d6-8395-8323021bea10',
        menuItemId: '4fadc91b-b9b2-4ee5-8e1b-4658e727f2f3',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 3
      },
      {
        id: '7f3b1831-947e-4447-a6f9-9301fc6c89d5',
        menuItemId: 'c3f4b931-7779-4f6a-a1be-7aa2c292de7f',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 4
      },
      {
        id: 'fd2475a0-46dc-4813-bff6-8ac1542fa625',
        menuItemId: 'fa24e3af-13a3-40ce-85d9-c03d2508ab3b',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 5
      },
      {
        id: '587e6d17-6e83-4aae-be43-f9138f40b420',
        menuItemId: '3b52e9ff-4104-4b8f-a2bd-b02845fc093c',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 6
      },
      {
        id: '055348cf-68e0-4624-b627-b91540c78145',
        menuItemId: '92c7546b-aedf-49c2-b92d-b8242ea90753',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 7
      },
      {
        id: '8bf3b248-33d8-48c0-82ac-7215f10bcda1',
        menuItemId: 'e964e8d1-7cfb-4e9d-9b0f-e5fbd154d1f0',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 8
      },
      {
        id: '46a6265b-f86d-4983-acc0-b4870f6a13a4',
        menuItemId: 'c647bd18-a2cf-4f82-9e0e-f1decf449858',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 1
      },
      {
        id: '3ca212b8-877a-40bb-a9cd-7a8da98896f1',
        menuItemId: '777f539a-9f58-4b37-96b3-5240ed68ba33',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 2
      },
      {
        id: 'c1686fed-a897-46ef-8c61-d2c4ee1d21cf',
        menuItemId: '47b061d7-2c2d-452c-8f8a-7041d92fbea3',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 3
      },
      {
        id: '35d59588-46a3-45f0-a3fd-01f588fc65ca',
        menuItemId: 'f76b4a07-4bc7-425a-8dcb-7b40cb7fa115',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 4
      },
      {
        id: 'bdacf1b1-e131-4c69-ab66-928eaecd3dc5',
        menuItemId: '453b720a-0643-45c0-b51e-0c02baadab8c',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 1
      },
      {
        id: '9b4c65c1-dce3-43af-978d-06048543abcc',
        menuItemId: 'fcec9a4d-a3a9-4ae6-8859-c11539be04f0',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 2
      },
      {
        id: '6cf2feb5-60d6-4deb-b886-d607c518d7f2',
        menuItemId: '419a2463-b320-479e-8987-137d46c796a1',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 3
      },
      {
        id: '15f3a27c-66d2-40dd-bffe-ad10de1d2e58',
        menuItemId: 'b0faa81c-229d-4894-b34c-4eae9a766253',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 4
      },
      {
        id: '8254bfce-c4de-487a-8a04-9ba1774a48c3',
        menuItemId: '236d571e-d7c1-4192-b9a4-60bdc1c66391',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 1
      },
      {
        id: 'a7302c34-9b9e-45cb-a184-75e999b8c9e8',
        menuItemId: '75961969-420e-4f28-a96b-f70762d3ddbd',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 2
      },
      {
        id: '5c4389ac-e578-4f70-9209-80b727768597',
        menuItemId: 'ffa535bf-d58d-4faa-a864-14fd9983e0ab',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 3
      },
      {
        id: '00fb5a54-63c5-4ccd-9a58-e7fd667a7071',
        menuItemId: '5cc35763-1325-4aa0-872d-6dda3a054784',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 4
      },
      {
        id: '64a64070-297b-4111-93b3-26e8790c36c8',
        menuItemId: 'd31e027e-b5fb-443e-b9d4-83e53a72a6ce',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 5
      },
      {
        id: '8edefb82-4195-466c-8a8d-f0fa7a755e2e',
        menuItemId: '62792eff-b2a0-4be0-9cf4-7dc5b0abb23d',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 6
      },
      {
        id: 'fff30521-1014-4c6f-9fa2-df9743a6d3f0',
        menuItemId: 'cb7bcec0-c47b-4ba7-8db6-b608277bec05',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 7
      },
      {
        id: 'a4d18302-274a-4d2d-b09a-4afc315e8e37',
        menuItemId: '38e52739-87c0-4d2e-85b5-6dd306671504',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 8
      },
      {
        id: '02ffc44c-0110-45bb-b317-063e934683a1',
        menuItemId: '9454c011-7ac6-4601-8896-5eaaeae3f235',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 9
      },
      {
        id: 'b8a4fb26-ddaf-43d8-85e8-5e0ac8b7cf14',
        menuItemId: 'ecb66336-c88f-4369-b6d0-6776a704eb7d',
        menuSectionId: '734607b3-cfdd-4242-a4b9-b00b0bb160d9',
        displayOrder: 10
      },
      {
        id: '2fb1a44b-ace4-4f30-bfa9-f1acefca09f0',
        menuItemId: '503e12c9-6fb2-415d-8ee6-f33dcafbe7c0',
        menuSectionId: 'a15a1654-d7d4-44e2-9a6c-06f0b33cea04',
        displayOrder: 1
      },
      {
        id: '9387e6d0-e8ca-4a2b-866b-591bc912cea4',
        menuItemId: 'af4184d9-3c71-48d4-ae9a-d9dbfa619375',
        menuSectionId: 'a15a1654-d7d4-44e2-9a6c-06f0b33cea04',
        displayOrder: 2
      },
      {
        id: '62447a06-2189-41fa-bffe-5edcefd9e11a',
        menuItemId: 'bf721b56-40f2-4bff-8c47-98e1ffa93d62',
        menuSectionId: '31448f19-ee70-490f-9b97-bebe555b441d',
        displayOrder: 1
      },
      {
        id: 'f0e59de9-f2d0-4a15-ac5f-0ebdd4945c18',
        menuItemId: '7c49eac9-ca43-454f-a71d-c5c0ea9ebf5f',
        menuSectionId: '31448f19-ee70-490f-9b97-bebe555b441d',
        displayOrder: 2
      },
      {
        id: '436dfd3a-1229-4e6e-81d3-78649ce9b070',
        menuItemId: '3c21964a-ee83-4bdb-91e8-e154203649ba',
        menuSectionId: '31448f19-ee70-490f-9b97-bebe555b441d',
        displayOrder: 3
      },
      {
        id: '098c695d-9764-428f-8ebc-5f6c5b972cab',
        menuItemId: '646119a3-9547-4895-ad14-04db99df2fe7',
        menuSectionId: '31448f19-ee70-490f-9b97-bebe555b441d',
        displayOrder: 4
      },
      {
        id: 'dcb095ad-c16a-4c90-8b0b-ce53ddd98fa0',
        menuItemId: '452cd212-582a-4662-8964-e4c036587daf',
        menuSectionId: '31448f19-ee70-490f-9b97-bebe555b441d',
        displayOrder: 5
      },
      {
        id: '6c0e9d9b-96bb-4c64-b978-f0a8858a7d07',
        menuItemId: '6c833b75-b365-4c23-82d9-022705c126d2',
        menuSectionId: '31448f19-ee70-490f-9b97-bebe555b441d',
        displayOrder: 6
      },
      {
        id: 'b5efc312-bcb8-4f94-a994-f10ed0b91593',
        menuItemId: 'bcb87e0c-25ed-4695-ae9a-b82013925cbd',
        menuSectionId: '31448f19-ee70-490f-9b97-bebe555b441d',
        displayOrder: 7
      },
      {
        id: '87a52d46-cd90-4099-b08d-1451e21540e9',
        menuItemId: '59ad9c42-e38d-4acd-86d6-60fe0f42f31b',
        menuSectionId: 'c22b977f-c622-4f76-96fd-fc934a7d7ef6',
        displayOrder: 1
      },
      {
        id: '92d090eb-c07b-4918-ba75-d23c4ccd8120',
        menuItemId: 'df5e5f7d-85d5-4bc3-9b2f-ae2e9a13932b',
        menuSectionId: 'c22b977f-c622-4f76-96fd-fc934a7d7ef6',
        displayOrder: 2
      },
      {
        id: '03604516-b833-4cfe-b28d-08e6b2674a4d',
        menuItemId: '0bb32aac-ba01-4256-899b-76ed987af269',
        menuSectionId: 'c22b977f-c622-4f76-96fd-fc934a7d7ef6',
        displayOrder: 3
      },
      {
        id: '3abd41e8-733b-44be-b461-fb93f97ecd20',
        menuItemId: '48400ca3-b4e7-4eab-be4c-ce7c8f0cbdb8',
        menuSectionId: 'c22b977f-c622-4f76-96fd-fc934a7d7ef6',
        displayOrder: 4
      },
      {
        id: '62931bc1-9491-4024-be7b-29c8ee016fb5',
        menuItemId: 'de6c7716-3d87-450d-9f6a-99a0c0613318',
        menuSectionId: 'c22b977f-c622-4f76-96fd-fc934a7d7ef6',
        displayOrder: 5
      },
      {
        id: 'f4d9ecff-ef23-4359-aa6c-0022d7ea6e44',
        menuItemId: '33caaff3-71e8-439b-be7c-6e0b92628609',
        menuSectionId: 'c22b977f-c622-4f76-96fd-fc934a7d7ef6',
        displayOrder: 6
      },
      {
        id: 'b9e655f4-d5bf-4d8f-8c52-52be3d9461f6',
        menuItemId: 'eb865344-bac0-4c4b-afc2-ec54e1ede037',
        menuSectionId: 'c22b977f-c622-4f76-96fd-fc934a7d7ef6',
        displayOrder: 7
      },
      {
        id: '5e3a05fd-a231-4787-90ea-596d2d05feb7',
        menuItemId: 'b634bd0e-7141-4cbc-a4e1-a15a8e048a99',
        menuSectionId: 'c22b977f-c622-4f76-96fd-fc934a7d7ef6',
        displayOrder: 8
      },
      {
        id: 'ea6bbc36-9404-42cd-9edd-b20499bcb9e6',
        menuItemId: 'f6d0caca-e22c-4267-a32b-fb1ea467c6ef',
        menuSectionId: 'c22b977f-c622-4f76-96fd-fc934a7d7ef6',
        displayOrder: 9
      },
      {
        id: 'f67cab0e-4fec-46ef-bee6-1a9e31693e14',
        menuItemId: '791d3f10-5ded-4a65-849e-6bb309983214',
        menuSectionId: 'ce2de121-5777-4539-bfc5-a42f1db1285b',
        displayOrder: 1
      },
      {
        id: '59ca60fc-6a26-4daa-9afe-5f4c95320d12',
        menuItemId: '9524b8f9-b560-4211-834c-5faa156afd05',
        menuSectionId: 'ce2de121-5777-4539-bfc5-a42f1db1285b',
        displayOrder: 2
      },
      {
        id: 'fc08afa2-b0bb-484c-9f08-2566049b4987',
        menuItemId: '1139c806-e7bf-4dad-873b-18fa00c8552a',
        menuSectionId: 'ce2de121-5777-4539-bfc5-a42f1db1285b',
        displayOrder: 3
      },
      {
        id: '19a57472-0eb4-4e9b-b49f-3ba5e5860405',
        menuItemId: '48cd461b-8ed9-4ef1-8eee-3367d2e127f1',
        menuSectionId: 'ce2de121-5777-4539-bfc5-a42f1db1285b',
        displayOrder: 4
      },
      {
        id: 'e0024dbf-11e0-4478-80e5-fc37eb1cff56',
        menuItemId: '233472ca-8d20-49b8-9856-bc5ad791601d',
        menuSectionId: '863dacf5-373f-442e-8c13-40fcd9f4b32b',
        displayOrder: 1
      },
      {
        id: 'fa951676-dd56-4fb7-9d5b-9f9ae9a703ff',
        menuItemId: '4235d277-0c25-43a5-add9-2b90ea7a15af',
        menuSectionId: '863dacf5-373f-442e-8c13-40fcd9f4b32b',
        displayOrder: 2
      },
      {
        id: 'bf68adce-a0f9-40a3-a283-fc007fc17167',
        menuItemId: 'f75d2b47-471e-40db-b678-bf3d5edff53e',
        menuSectionId: '863dacf5-373f-442e-8c13-40fcd9f4b32b',
        displayOrder: 3
      },
      {
        id: '37ef39ba-cf45-4da4-8e50-c23f59bd946a',
        menuItemId: 'e468bf47-bcdd-4757-9740-c3e7e3e69cb5',
        menuSectionId: 'ae85d09a-19cd-459f-908e-f724975af103',
        displayOrder: 1
      },
      {
        id: '892a2989-b37a-4c63-b2df-4d8d994495f7',
        menuItemId: '3cf90a41-ff0c-4bde-a0a4-ec7b1797fb19',
        menuSectionId: 'ae85d09a-19cd-459f-908e-f724975af103',
        displayOrder: 2
      },
      {
        id: 'd855e30e-7999-4e25-be5a-59dbda539cad',
        menuItemId: 'bcbc3a02-be00-4e1f-b111-b94017cb57fd',
        menuSectionId: '2427fc5a-2311-44c5-b60f-17d885c823b2',
        displayOrder: 1
      },
      {
        id: '74f58c6c-cd07-4c38-8a00-bb739d6a2219',
        menuItemId: '1e739c93-d86e-4246-8ab0-1ef22f6d9314',
        menuSectionId: '2427fc5a-2311-44c5-b60f-17d885c823b2',
        displayOrder: 2
      },
      {
        id: 'b4311376-eac4-48ac-bf08-04423ecc74cf',
        menuItemId: '6ee36913-c7aa-49bf-9cfd-0614fa1a4bfd',
        menuSectionId: '2427fc5a-2311-44c5-b60f-17d885c823b2',
        displayOrder: 3
      },
      {
        id: '8039632e-284e-4c97-aa0a-10662f0d6db4',
        menuItemId: '3f3c6c04-d95a-4c5d-8f0d-20e7d3d7a38e',
        menuSectionId: '2427fc5a-2311-44c5-b60f-17d885c823b2',
        displayOrder: 4
      },
      {
        id: '9efd5c63-ed32-45d1-998c-a8b897cdb04b',
        menuItemId: 'a55dad8d-1caf-425d-ae68-177ecf233b0a',
        menuSectionId: '2427fc5a-2311-44c5-b60f-17d885c823b2',
        displayOrder: 5
      },
      {
        id: '6c54706d-213e-4d50-ad50-b442ab38d833',
        menuItemId: '1b8c48f0-ba35-405f-9bea-a3691f568511',
        menuSectionId: '2427fc5a-2311-44c5-b60f-17d885c823b2',
        displayOrder: 6
      },
      {
        id: '31a5b837-ef6a-4235-abfe-e0776029fc19',
        menuItemId: '96e8f7b0-5c2d-4024-9c23-77cd5dc147c3',
        menuSectionId: '1afa0605-4a17-4acf-93c7-34ad07279598',
        displayOrder: 1
      },
      {
        id: 'a91dce51-9fd4-4bda-ad69-3f3f847bc67b',
        menuItemId: '40784a3d-e13c-4c39-8b6e-5bec46a26a18',
        menuSectionId: '1afa0605-4a17-4acf-93c7-34ad07279598',
        displayOrder: 2
      },
      {
        id: 'bf0efaf0-aeaf-4a88-af00-970f1b7efe49',
        menuItemId: '783e7776-9ecb-4bb7-9a03-7e236d2f5b1b',
        menuSectionId: '1afa0605-4a17-4acf-93c7-34ad07279598',
        displayOrder: 3
      },
      {
        id: '8a4f9e6c-8318-4ecd-8baa-a6aede2dfe1c',
        menuItemId: 'b6b0ab3b-ae44-4fbc-a53f-becf73e32dcf',
        menuSectionId: 'b0e4fee9-2ae8-4ba9-bac2-e65da89817d9',
        displayOrder: 1
      },
      {
        id: '9c5b6762-cce9-4b10-80b3-231939f3903c',
        menuItemId: 'a31604f0-b1d5-49b4-8786-73ef0de53980',
        menuSectionId: 'b0e4fee9-2ae8-4ba9-bac2-e65da89817d9',
        displayOrder: 2
      },
      {
        id: 'b04c91a3-60dc-4d63-ac39-166f920938d7',
        menuItemId: 'bbb1efe8-3ef3-4a2e-a2c5-ab80822053b9',
        menuSectionId: 'b0e4fee9-2ae8-4ba9-bac2-e65da89817d9',
        displayOrder: 3
      },
      {
        id: 'a48176d9-472d-4e12-a41e-258ae6aab40a',
        menuItemId: '06e8ec22-833d-4b24-90fb-b54f8aba856c',
        menuSectionId: 'b0e4fee9-2ae8-4ba9-bac2-e65da89817d9',
        displayOrder: 4
      },
      {
        id: 'caf899f9-21f2-486b-8fe3-c9d5aebdd59c',
        menuItemId: 'fb5b4b97-0084-4fcf-816b-4b16062a0fe8',
        menuSectionId: 'b0e4fee9-2ae8-4ba9-bac2-e65da89817d9',
        displayOrder: 5
      },
      {
        id: '9bf78ec7-36cd-4784-b656-45fc9f54130f',
        menuItemId: '48b75ad7-48bc-4bea-b539-6b42f6d07bed',
        menuSectionId: 'b0e4fee9-2ae8-4ba9-bac2-e65da89817d9',
        displayOrder: 6
      },
      {
        id: '1990930d-33a6-4d48-a858-16e185e7ebac',
        menuItemId: 'ca13b8a3-cb32-448a-9107-3e927b8e0fb7',
        menuSectionId: 'e2c7ec90-1af3-4d78-958c-f5714676e89d',
        displayOrder: 1
      },
      {
        id: '1902c9a7-235a-4fe8-afe7-f2d3738b87a5',
        menuItemId: '1022508f-8b76-48b3-bb3c-b4d567ab6962',
        menuSectionId: 'e2c7ec90-1af3-4d78-958c-f5714676e89d',
        displayOrder: 2
      },
      {
        id: 'b752ab17-f87c-435c-bcf3-91e7da62e033',
        menuItemId: 'e28561e2-a8f1-423a-aee9-58117c3ab5b8',
        menuSectionId: '55f13a05-b0d4-4060-b779-7530b7a3a817',
        displayOrder: 1
      },
      {
        id: 'f8ad6cb3-f183-46b7-8689-d61d019156d2',
        menuItemId: '68d687ec-d72f-49ed-8912-0066c328034f',
        menuSectionId: '55f13a05-b0d4-4060-b779-7530b7a3a817',
        displayOrder: 2
      },
      {
        id: 'c162b337-859b-41f0-9a05-c645976eb663',
        menuItemId: 'e3a6b29f-b0d5-41c8-b287-9ee968bb8855',
        menuSectionId: '55f13a05-b0d4-4060-b779-7530b7a3a817',
        displayOrder: 3
      },
      {
        id: 'ae63a7e5-5534-4f03-bf14-cfd762f71fdd',
        menuItemId: 'b9bd93cc-a283-434f-bf9f-2dd412c457cd',
        menuSectionId: '55f13a05-b0d4-4060-b779-7530b7a3a817',
        displayOrder: 4
      },
      {
        id: '5618c4e7-a676-4b81-a4bb-ea98dc2361e1',
        menuItemId: 'a275bcf0-db5b-45ea-b7be-4f15608b50ca',
        menuSectionId: '55f13a05-b0d4-4060-b779-7530b7a3a817',
        displayOrder: 5
      },
      {
        id: '9771605b-099d-4981-8e12-0180e982a0f0',
        menuItemId: '2a3043b5-afb6-432b-8122-3d7f086515ea',
        menuSectionId: '55f13a05-b0d4-4060-b779-7530b7a3a817',
        displayOrder: 6
      },
      {
        id: '64a01e0c-6619-4143-9d32-a8d8371ce072',
        menuItemId: 'c83fd336-a70a-4c4e-9dab-d2aa5180e1c9',
        menuSectionId: '55f13a05-b0d4-4060-b779-7530b7a3a817',
        displayOrder: 7
      },
      {
        id: '621c3407-f9dc-4d5c-9f1e-a672059fb227',
        menuItemId: '27de2d96-e7cb-411d-90a2-a1f826c8912e',
        menuSectionId: '3e11ee70-4eca-42d0-b49e-dfd15ec38d56',
        displayOrder: 1
      },
      {
        id: 'bbe150db-3417-4142-bd03-7fae7bcf68c6',
        menuItemId: '5e4f822d-8ade-4d60-b60c-137ea814c01b',
        menuSectionId: '3e11ee70-4eca-42d0-b49e-dfd15ec38d56',
        displayOrder: 2
      },
      {
        id: 'ed1419b6-395d-4906-8b43-4578c660cf37',
        menuItemId: 'cabc663e-511a-48aa-9c91-e7567748a188',
        menuSectionId: '3e11ee70-4eca-42d0-b49e-dfd15ec38d56',
        displayOrder: 3
      },
      {
        id: '56f53ce8-066c-4fe7-a02c-a3a2e9704acb',
        menuItemId: '7f2cf0fb-f8c1-423d-8363-c0a8bfaac498',
        menuSectionId: '224ee548-1107-4683-8b13-007fd556c0b6',
        displayOrder: 1
      },
      {
        id: '99a82b5e-0722-4317-9b2e-028d1fb179d6',
        menuItemId: 'b289c15a-08b2-4ac3-bd64-8d70077b1488',
        menuSectionId: '224ee548-1107-4683-8b13-007fd556c0b6',
        displayOrder: 2
      },
      {
        id: '610fcd8d-bedf-48bb-987f-21aa809e79cf',
        menuItemId: '2de5a918-b63a-46c3-b4a3-3c21490542a4',
        menuSectionId: '224ee548-1107-4683-8b13-007fd556c0b6',
        displayOrder: 3
      },
      {
        id: '80027722-08c4-48ae-9778-2039771de52e',
        menuItemId: 'fcd06d13-3166-4618-aa58-53ad553c92f9',
        menuSectionId: '224ee548-1107-4683-8b13-007fd556c0b6',
        displayOrder: 4
      },
      {
        id: 'c1e78d45-b17d-40df-9990-ba664321c6eb',
        menuItemId: '751930bf-cd10-4bc3-a41e-d9dcb7eaab1a',
        menuSectionId: '224ee548-1107-4683-8b13-007fd556c0b6',
        displayOrder: 5
      },
      {
        id: '8a13591c-b4c3-456f-816c-713ec077606d',
        menuItemId: '7907e468-71f7-4989-b6c9-e64370852b70',
        menuSectionId: '372b3ca0-0047-42fe-a148-e2c3cdc12402',
        displayOrder: 1
      },
      {
        id: '5bc84200-5d68-4541-98d2-0be82d05db5e',
        menuItemId: 'f4da7031-f2fe-45f8-ac96-0d3ddd2decc7',
        menuSectionId: '372b3ca0-0047-42fe-a148-e2c3cdc12402',
        displayOrder: 2
      },
      {
        id: '6520e45f-243b-4aa4-9a1c-3db40a87d823',
        menuItemId: '745f30eb-f427-4181-a0b7-876b29452b8c',
        menuSectionId: '372b3ca0-0047-42fe-a148-e2c3cdc12402',
        displayOrder: 3
      },
      {
        id: '9e17593b-0670-4a09-8d54-1b4c9fdde902',
        menuItemId: 'a2c54ad3-16a2-496e-86ea-343716d87653',
        menuSectionId: '372b3ca0-0047-42fe-a148-e2c3cdc12402',
        displayOrder: 4
      },
      {
        id: '19e4670d-fbff-4652-9c99-8911cdef5218',
        menuItemId: '1b8fc4a7-60ba-4f97-a12d-9817df6f6acc',
        menuSectionId: '372b3ca0-0047-42fe-a148-e2c3cdc12402',
        displayOrder: 5
      },
      {
        id: 'c2024a9f-16f1-46db-8eae-9454b342549c',
        menuItemId: '240249de-bcca-4cde-a19e-339f392d1070',
        menuSectionId: '372b3ca0-0047-42fe-a148-e2c3cdc12402',
        displayOrder: 6
      },
      {
        id: 'e8b27e19-96fb-4010-ba02-ca5856bfee64',
        menuItemId: '88a703b5-db86-4c4c-89e9-af5bd230adb6',
        menuSectionId: '99c636dd-8196-4975-82e0-9df8aa0a3c4d',
        displayOrder: 1
      },
      {
        id: 'e57a481d-4ee2-4cdd-9863-1650e157b5fe',
        menuItemId: 'c4c05ef9-bbe5-4a0e-98fc-15c8d4279acf',
        menuSectionId: '99c636dd-8196-4975-82e0-9df8aa0a3c4d',
        displayOrder: 2
      },
      {
        id: '65c268e9-b183-4b36-bf98-eb05ca7c4fef',
        menuItemId: '3fd2b331-b3c9-4361-9265-bc1931b98bd5',
        menuSectionId: '99c636dd-8196-4975-82e0-9df8aa0a3c4d',
        displayOrder: 3
      },
      {
        id: '3fc08ba9-d3c0-4388-8005-51bb0c822353',
        menuItemId: '78c6091b-4336-49ce-8874-488b6fe141b9',
        menuSectionId: '99c636dd-8196-4975-82e0-9df8aa0a3c4d',
        displayOrder: 4
      },
      {
        id: '087b75fb-d237-47c7-9951-a4fccec04060',
        menuItemId: '69b36dc9-9dcf-4bb1-b19a-eb80edf82dea',
        menuSectionId: '74e96c9a-3080-42c9-b98c-c22ce60a9876',
        displayOrder: 1
      },
      {
        id: 'cf60ee55-bf73-4b09-b565-059d193784b3',
        menuItemId: 'ccd057fc-c607-4861-b033-24d395ee5bee',
        menuSectionId: '74e96c9a-3080-42c9-b98c-c22ce60a9876',
        displayOrder: 2
      },
      {
        id: '8a793d12-4bcb-4918-93f9-8ae3e4345cf8',
        menuItemId: 'ca7f01c3-75a3-4c0b-b52a-671585b1bf1d',
        menuSectionId: '74e96c9a-3080-42c9-b98c-c22ce60a9876',
        displayOrder: 3
      },
      {
        id: 'a3d9a37f-7351-405a-8e87-b839e39e14ec',
        menuItemId: 'c7156e6a-74e6-41d4-8909-b21261df9bba',
        menuSectionId: '9790a68a-ddeb-4398-9173-f94e58baba6d',
        displayOrder: 1
      },
      {
        id: '52341dcc-58d3-42b0-b83b-c5fbd4fab368',
        menuItemId: '5036550e-fcd5-476e-9609-c2fb1ee86611',
        menuSectionId: '9790a68a-ddeb-4398-9173-f94e58baba6d',
        displayOrder: 2
      },
      {
        id: '1f7e19a1-d60c-4e93-8c62-97353e1b0501',
        menuItemId: 'dc9d48b3-99fe-46b5-98f0-25f6c4a05fac',
        menuSectionId: '9790a68a-ddeb-4398-9173-f94e58baba6d',
        displayOrder: 3
      },
      {
        id: '1b84b048-8257-4f29-9f2d-8a033a15436b',
        menuItemId: '98dd838e-0370-41cb-ae73-c632069f9947',
        menuSectionId: '9790a68a-ddeb-4398-9173-f94e58baba6d',
        displayOrder: 4
      },
      {
        id: '6bc635fb-3a17-446c-b3d2-a86282d99ee1',
        menuItemId: '13352dfd-317f-41e3-aedd-6eff09cb0a02',
        menuSectionId: '4983844f-421f-4675-abc1-3d8dda39e7fe',
        displayOrder: 1
      },
      {
        id: '66568572-00a5-45b0-ad78-f501585f6b5d',
        menuItemId: '308da0cd-063c-4443-8dda-075a941c7aea',
        menuSectionId: '4983844f-421f-4675-abc1-3d8dda39e7fe',
        displayOrder: 2
      },
      {
        id: 'c49b4310-07f0-40e2-a87e-111fec0e0fbd',
        menuItemId: '2f529b4d-2adf-441c-8cf1-5be7bb22f9fe',
        menuSectionId: '4983844f-421f-4675-abc1-3d8dda39e7fe',
        displayOrder: 3
      },
      {
        id: '02be6b22-813f-486d-b72a-6a7e46f72a8d',
        menuItemId: '984bb10a-0093-4727-9c04-c3ea6d09f1f8',
        menuSectionId: '19b93361-8832-4f35-b949-e87f68575e7f',
        displayOrder: 1
      },
      {
        id: '76fec9be-a543-4f6a-a7d5-4e02f60cf486',
        menuItemId: '22eb1ead-f9cd-4e11-be00-5fe6dda6376d',
        menuSectionId: '19b93361-8832-4f35-b949-e87f68575e7f',
        displayOrder: 2
      },
      {
        id: '3df6f77f-11a0-434b-a530-f0321adba686',
        menuItemId: '2f8388ed-8ad7-435e-bf62-2b944c8756e2',
        menuSectionId: '19b93361-8832-4f35-b949-e87f68575e7f',
        displayOrder: 3
      },
      {
        id: '09445577-b390-4432-8557-ed389f2b688e',
        menuItemId: 'e5862aa9-b671-4e69-9c1b-dc0af2bae291',
        menuSectionId: 'c64c6b97-0530-48bf-b157-5d9ccc413e61',
        displayOrder: 1
      },
      {
        id: '6ac17853-0ef4-43a8-8bae-d2200795be30',
        menuItemId: '0d3d5bd4-3adf-4d3f-9163-a7a4f7e37f4c',
        menuSectionId: 'c64c6b97-0530-48bf-b157-5d9ccc413e61',
        displayOrder: 2
      },
      {
        id: '6e6e6cd0-f341-47b3-b87a-1025f2d9dbd4',
        menuItemId: 'f16877fd-36d2-41fb-b159-dcf6194ac54f',
        menuSectionId: 'c64c6b97-0530-48bf-b157-5d9ccc413e61',
        displayOrder: 3
      },
      {
        id: '41fd3c1a-e196-49e7-b027-2da1cf9e3f54',
        menuItemId: '6d9a1c57-587f-4865-8fe9-b68ca75de31a',
        menuSectionId: 'c64c6b97-0530-48bf-b157-5d9ccc413e61',
        displayOrder: 4
      },
      {
        id: '0e56d3f5-2e33-4966-9389-71693d0d885f',
        menuItemId: '3c276895-b3bb-4211-bc72-785d2a61e476',
        menuSectionId: 'c69da935-4ff0-4f70-be1d-e85c84c3b29e',
        displayOrder: 1
      },
      {
        id: 'dd65d802-d0fd-4d83-8033-4247c84a9e8a',
        menuItemId: 'af453c65-cda4-4c9c-96ca-8242873b22fc',
        menuSectionId: 'c69da935-4ff0-4f70-be1d-e85c84c3b29e',
        displayOrder: 2
      },
      {
        id: 'a0ddc3a7-2001-4618-8d40-7cc28b1d64a3',
        menuItemId: '2fddb60c-67ef-4b21-84a9-17983506355f',
        menuSectionId: 'c69da935-4ff0-4f70-be1d-e85c84c3b29e',
        displayOrder: 3
      },
      {
        id: 'd9c6dd8a-aa37-4174-bb48-94e6b845d231',
        menuItemId: '05481b92-bcd3-414e-a7ba-006476f143f4',
        menuSectionId: 'd2a4049b-b59c-4a2f-a687-6a68ac8c5db1',
        displayOrder: 1
      },
      {
        id: '145cc772-c4e9-4136-82a8-694479aec3d4',
        menuItemId: '95e47bb3-224c-4d73-bc03-cb57e6a102aa',
        menuSectionId: 'd2a4049b-b59c-4a2f-a687-6a68ac8c5db1',
        displayOrder: 2
      },
      {
        id: 'd9e816d2-f5f3-471b-a14a-77b16ff5d2f6',
        menuItemId: '5010c875-d7cc-496d-88bb-073284c05e36',
        menuSectionId: 'd2a4049b-b59c-4a2f-a687-6a68ac8c5db1',
        displayOrder: 3
      },
      {
        id: 'a890457c-69e3-4ce7-b13c-f1d450318844',
        menuItemId: '58e424ac-c244-4a72-a3c0-d449457f71ce',
        menuSectionId: 'd2a4049b-b59c-4a2f-a687-6a68ac8c5db1',
        displayOrder: 4
      },
      {
        id: '03c9dfaa-ee1d-43b1-946d-36f15440e144',
        menuItemId: 'b48b8087-7ed2-4f9c-8900-966b606fa302',
        menuSectionId: '0baa8f96-72ca-4dcd-8bf9-a1afe302494f',
        displayOrder: 1
      },
      {
        id: 'b4f56ac9-4c59-4b09-9da6-676e91301767',
        menuItemId: '6e22b812-1dae-440c-9869-beb3547c801f',
        menuSectionId: '0baa8f96-72ca-4dcd-8bf9-a1afe302494f',
        displayOrder: 2
      },
      {
        id: '3e226af9-35e7-442e-b3a1-bbc8066a6dcf',
        menuItemId: '1ea18169-1324-46df-8f50-97750d67a300',
        menuSectionId: '0baa8f96-72ca-4dcd-8bf9-a1afe302494f',
        displayOrder: 3
      },
      {
        id: '236a5be9-9bd7-4aba-a655-95e1a44edbfa',
        menuItemId: '5c78560d-05a6-4c82-90a6-f31f0649ca1a',
        menuSectionId: '0baa8f96-72ca-4dcd-8bf9-a1afe302494f',
        displayOrder: 4
      },
      {
        id: '3e2e3245-ea0b-41ac-a3cc-8de3c3b37898',
        menuItemId: 'fc2af762-62d8-40aa-88db-76830b2cdcca',
        menuSectionId: '8e6eb62b-c6b2-4d61-99a2-ab6e22d3516d',
        displayOrder: 1
      },
      {
        id: '8316caf0-e078-4a7e-86f2-ca609f45a839',
        menuItemId: 'b7f9815c-bdd3-47fd-a649-1f4923c84b28',
        menuSectionId: '8e6eb62b-c6b2-4d61-99a2-ab6e22d3516d',
        displayOrder: 2
      },
      {
        id: 'a857da8c-8562-498c-949f-a21ded066aef',
        menuItemId: '948cda5e-5ed8-4ace-9b7b-5144a344f580',
        menuSectionId: '8e6eb62b-c6b2-4d61-99a2-ab6e22d3516d',
        displayOrder: 3
      },
      {
        id: 'c3f52bad-8f3b-4e2e-a80f-e261e1a8a6a3',
        menuItemId: '1de4aed7-2531-4593-a41a-b08c18ca6f48',
        menuSectionId: '8e6eb62b-c6b2-4d61-99a2-ab6e22d3516d',
        displayOrder: 4
      },
      {
        id: '72cad799-eafe-4940-852a-1115ab263865',
        menuItemId: '43f3befc-adf2-4c6c-9ee1-71a04100e80f',
        menuSectionId: '04ee5963-8d26-4c0d-80fd-777519fc1068',
        displayOrder: 1
      },
      {
        id: 'ad682b07-03d1-486f-8750-b4bd30cb26f0',
        menuItemId: '0bafdabe-1586-4ed1-b2d0-56a471e3a81f',
        menuSectionId: '04ee5963-8d26-4c0d-80fd-777519fc1068',
        displayOrder: 2
      },
      {
        id: '411a4762-03a6-4c59-8d3d-f501fbe88804',
        menuItemId: 'eb9c4da7-4ec9-46de-a82c-f9b77c30fb75',
        menuSectionId: '04ee5963-8d26-4c0d-80fd-777519fc1068',
        displayOrder: 3
      },
      {
        id: '7a6b0b38-8afe-40c6-9375-820bb3d91215',
        menuItemId: '4d1bda10-285f-474d-a82c-637cd6954968',
        menuSectionId: 'ab715290-6234-40fe-9b22-6b0cc6b69e5f',
        displayOrder: 1
      },
      {
        id: '0a199f0b-b08f-4524-ab06-a62b11094c1a',
        menuItemId: '782d38b0-8387-44f2-871a-0e7f1b34f3b0',
        menuSectionId: 'ab715290-6234-40fe-9b22-6b0cc6b69e5f',
        displayOrder: 2
      },
      {
        id: '1f5d31de-dc77-49cb-ae34-647578fb1f92',
        menuItemId: '905e8d9d-400b-4c55-bf26-744bcfbaee29',
        menuSectionId: 'ab715290-6234-40fe-9b22-6b0cc6b69e5f',
        displayOrder: 3
      },
      {
        id: 'd41d90fe-8fa6-43fa-8eca-4975c35274ed',
        menuItemId: 'b98ea31c-0556-44a5-bb05-2c96b70d98ae',
        menuSectionId: 'ab715290-6234-40fe-9b22-6b0cc6b69e5f',
        displayOrder: 4
      },
      {
        id: '1e611f87-b0ff-4914-8145-1b202c73f8cc',
        menuItemId: 'f87f45d2-c117-40fa-aa3c-db07a3877800',
        menuSectionId: 'ab715290-6234-40fe-9b22-6b0cc6b69e5f',
        displayOrder: 5
      },
      {
        id: '520fbe67-20f4-4e08-a976-dc39c95fa53c',
        menuItemId: '8e854ddf-d39d-44c3-be9d-fd9890f3dc90',
        menuSectionId: '9fbc5fa7-53d1-466b-be02-4c9c39e0d202',
        displayOrder: 1
      },
      {
        id: 'fb384082-81cd-40cd-8c91-928294c4da1e',
        menuItemId: '3e0e7b3a-bb2f-49e7-b7f4-f8453235eae4',
        menuSectionId: '9fbc5fa7-53d1-466b-be02-4c9c39e0d202',
        displayOrder: 2
      },
      {
        id: '5cff1747-148d-4dc6-a464-b7321c21805c',
        menuItemId: '2f86e3f1-3eb7-40f4-a159-d6b2a7ff1b34',
        menuSectionId: '9fbc5fa7-53d1-466b-be02-4c9c39e0d202',
        displayOrder: 3
      },
      {
        id: 'ec12ad74-a375-4a81-9718-11e7a866b2ef',
        menuItemId: '24b20675-67ec-4f4c-9150-e170498d0354',
        menuSectionId: '503930e0-a23c-4d97-a984-93a26b8b1d87',
        displayOrder: 1
      },
      {
        id: 'f9823fb6-a520-4045-aaf7-a1e74af2596b',
        menuItemId: '353ba312-a5d3-415d-b91b-5f388d5b9dac',
        menuSectionId: 'b172f070-cdd7-4e5d-829c-52df9b189da3',
        displayOrder: 1
      },
      {
        id: 'd8ab68b1-7049-4f11-995e-4f4d0e0c2c1b',
        menuItemId: '0da97a88-a7fe-423c-bee7-67c209957cc6',
        menuSectionId: 'f6825a58-237b-49ee-84c6-ee17299b70ad',
        displayOrder: 1
      },
      {
        id: 'e773926b-c82b-4ab8-8d4e-246cb88fd7e4',
        menuItemId: 'f95bae0f-792b-456a-b793-b5aa888e614d',
        menuSectionId: 'e60c17e6-ec69-4cbd-94b3-c62809393330',
        displayOrder: 1
      },
      {
        id: 'c6b2b121-b5e1-4fbc-8f37-290e63466be8',
        menuItemId: 'f20661a1-a2d7-455b-97c6-2953c8a0ee4b',
        menuSectionId: 'e60c17e6-ec69-4cbd-94b3-c62809393330',
        displayOrder: 2
      },
      {
        id: '37802546-9880-48c5-9fe7-ef710dc4b311',
        menuItemId: '8b56c481-587e-4610-94d7-07c797b1a01e',
        menuSectionId: 'e60c17e6-ec69-4cbd-94b3-c62809393330',
        displayOrder: 3
      },
      {
        id: '1fea4208-9eb8-48b1-827c-9268eaee8ed5',
        menuItemId: 'c5b182be-a315-4e0d-830d-bac430c4c92e',
        menuSectionId: 'e60c17e6-ec69-4cbd-94b3-c62809393330',
        displayOrder: 4
      },
      {
        id: 'cba041ae-0baf-44a7-ab35-217bc7192e78',
        menuItemId: '86f86ed9-b70a-4d55-932a-5f8a4d910f76',
        menuSectionId: 'e60c17e6-ec69-4cbd-94b3-c62809393330',
        displayOrder: 5
      },
      {
        id: '2da0f0dd-3694-4b90-8f37-a5697698d7d6',
        menuItemId: '11e39cbf-a506-4829-9b84-c00bb1051b64',
        menuSectionId: 'e60c17e6-ec69-4cbd-94b3-c62809393330',
        displayOrder: 6
      },
      {
        id: '95d80fe6-ceca-4038-bdba-ddbc9167cf0c',
        menuItemId: 'ff78a3ac-376d-42e6-a130-9ee160e5b875',
        menuSectionId: 'e60c17e6-ec69-4cbd-94b3-c62809393330',
        displayOrder: 7
      },
      {
        id: '303291ed-f13a-40c4-a4b4-6b0ccc3daa80',
        menuItemId: '925658df-d07c-4c85-8e16-5d62a81d3f72',
        menuSectionId: 'e60c17e6-ec69-4cbd-94b3-c62809393330',
        displayOrder: 8
      },
      {
        id: '35bd6073-20ba-4244-8eb8-7bae14f5da38',
        menuItemId: '709d777e-255e-4240-a103-d79f4e0d8796',
        menuSectionId: 'e60c17e6-ec69-4cbd-94b3-c62809393330',
        displayOrder: 9
      },
      {
        id: 'a3bf82ad-3a6d-4c87-aebe-b75800edb865',
        menuItemId: 'f343459a-29ec-4a4b-8ece-501cda994287',
        menuSectionId: 'c70a2573-09cf-4a16-a5d2-4c3befe5b6ae',
        displayOrder: 1
      },
      {
        id: 'dcbdb190-9e7c-4beb-9fa5-3c3367f8abde',
        menuItemId: 'a9ca2487-ef04-4456-809a-8e4ca4f2b661',
        menuSectionId: 'c70a2573-09cf-4a16-a5d2-4c3befe5b6ae',
        displayOrder: 2
      },
      {
        id: '63aae5a2-8113-4a74-bc27-36f343460e97',
        menuItemId: '053cf6ad-c082-4450-ad3a-91ccd1c6b2b2',
        menuSectionId: 'c70a2573-09cf-4a16-a5d2-4c3befe5b6ae',
        displayOrder: 3
      },
      {
        id: '1448e961-0392-4ac1-bd5b-7efb80f32859',
        menuItemId: '94ffba7f-c8b3-4124-81f2-0b861c34e33c',
        menuSectionId: 'c70a2573-09cf-4a16-a5d2-4c3befe5b6ae',
        displayOrder: 4
      },
      {
        id: '92697caf-70aa-43f6-9e91-e642ee8b995f',
        menuItemId: '3c53c162-b5e7-4f31-b257-8d14cbb54eb9',
        menuSectionId: '17fb7640-50a3-4d6b-8811-21b0e679512c',
        displayOrder: 1
      },
      {
        id: '0ae0cf97-e161-47aa-a677-497350db4f4f',
        menuItemId: '98c94481-cec7-42a0-9b80-a72811f48db3',
        menuSectionId: '17fb7640-50a3-4d6b-8811-21b0e679512c',
        displayOrder: 2
      },
      {
        id: 'cb3456f3-5346-4628-80ce-5378709d1bfc',
        menuItemId: '77175c8a-478a-4ef2-9ac3-5f0e14425e55',
        menuSectionId: '17fb7640-50a3-4d6b-8811-21b0e679512c',
        displayOrder: 3
      },
      {
        id: '5279371e-534d-46f8-8a9a-0e3999c933dd',
        menuItemId: 'de2d6e0c-8cc6-4a70-b32f-6145a49eff08',
        menuSectionId: '17fb7640-50a3-4d6b-8811-21b0e679512c',
        displayOrder: 4
      },
      {
        id: '09e828b1-8379-4ef6-9d0d-7c2d9781e68b',
        menuItemId: 'b5a77f47-2261-4041-bf71-956805c9a2bc',
        menuSectionId: '17fb7640-50a3-4d6b-8811-21b0e679512c',
        displayOrder: 5
      },
      {
        id: '91594a52-eaa9-4da4-a5b7-518db1906e32',
        menuItemId: '4a2b2cf3-fa22-401a-91ec-2fd2cb064e01',
        menuSectionId: '17fb7640-50a3-4d6b-8811-21b0e679512c',
        displayOrder: 6
      },
      {
        id: '75a731ff-6e1b-488c-b818-3d8ccf5b34bc',
        menuItemId: '5c8ec958-3159-44fe-8de9-f1f51579a4e0',
        menuSectionId: '17fb7640-50a3-4d6b-8811-21b0e679512c',
        displayOrder: 7
      },
      {
        id: '1b6db36f-2faa-4d67-a8de-a46bc6c9d291',
        menuItemId: 'fde91ea0-8eeb-441e-8508-60371808c820',
        menuSectionId: '17fb7640-50a3-4d6b-8811-21b0e679512c',
        displayOrder: 8
      },
      {
        id: 'bb330f96-23af-4f4e-9022-c5a0fb637e79',
        menuItemId: '5244b8d2-caf9-48a5-b20a-1f66da8baac4',
        menuSectionId: '481bbb15-a73a-4e45-9118-6840361bd981',
        displayOrder: 1
      },
      {
        id: 'a45f4b7b-baa5-4422-9bd4-5e54aaa6e85a',
        menuItemId: 'c6d384e1-5bf8-4771-916d-c4fe725089b6',
        menuSectionId: '481bbb15-a73a-4e45-9118-6840361bd981',
        displayOrder: 2
      },
      {
        id: '02de0cc3-6ae4-42a1-a284-bf836f2f34fc',
        menuItemId: '0ed69ec8-e610-45bb-929f-ef7b225a180a',
        menuSectionId: '481bbb15-a73a-4e45-9118-6840361bd981',
        displayOrder: 3
      },
      {
        id: '2806cb79-ccba-49ac-b65f-963b5e7f7412',
        menuItemId: 'fc3a9766-9edc-41a0-8c16-f2a49145ae8a',
        menuSectionId: '481bbb15-a73a-4e45-9118-6840361bd981',
        displayOrder: 4
      },
      {
        id: 'ecc87a8b-cb5a-4ed7-8a48-67bfab0e9115',
        menuItemId: '6c20700d-5cae-4d6e-b4c4-135421e8b1e6',
        menuSectionId: '481bbb15-a73a-4e45-9118-6840361bd981',
        displayOrder: 5
      },
      {
        id: '38e1b3dd-5516-4183-8d01-0b58aaa9d8e3',
        menuItemId: '1ba379f9-daa2-49d0-aef1-642e9ecedba6',
        menuSectionId: 'adb246d4-0c7e-4e8c-a5d0-ccb11a0e237e',
        displayOrder: 1
      },
      {
        id: 'd8acbf9c-4ca7-4de2-8323-08b85bd5aea5',
        menuItemId: '4ad0908f-a7b5-4c0d-8e2f-4d1baea32d30',
        menuSectionId: '400e7dc3-85ea-4eaf-bfc5-e87dd67f1e9e',
        displayOrder: 1
      },
      {
        id: '41e0eb30-c4b6-458d-a486-72616b43db87',
        menuItemId: '6f14444f-10ab-4787-b130-4ba54e6a27e4',
        menuSectionId: '400e7dc3-85ea-4eaf-bfc5-e87dd67f1e9e',
        displayOrder: 2
      },
      {
        id: 'b6640508-cd25-4907-85aa-288290bb4a48',
        menuItemId: '38c349bd-5521-4734-b23e-e235bc5dd8eb',
        menuSectionId: '400e7dc3-85ea-4eaf-bfc5-e87dd67f1e9e',
        displayOrder: 3
      },
      {
        id: '202f2aa3-7973-41d7-944d-1fade24f17fb',
        menuItemId: '7f38fd28-555b-43c0-a04d-89136f01d9a2',
        menuSectionId: '400e7dc3-85ea-4eaf-bfc5-e87dd67f1e9e',
        displayOrder: 4
      },
      {
        id: 'c5336fd4-229e-4e33-b086-459be7b2aa83',
        menuItemId: 'cf62862a-5b82-414d-98c7-5733e5552ed0',
        menuSectionId: '400e7dc3-85ea-4eaf-bfc5-e87dd67f1e9e',
        displayOrder: 5
      },
      {
        id: '82aa3865-62c9-4caa-a450-7ef7a3779e89',
        menuItemId: '6739028b-c6a6-48d9-885f-b38c758ebc0f',
        menuSectionId: 'ae816cab-6534-4740-8815-549ff6a9c021',
        displayOrder: 1
      },
      {
        id: '636164ad-ba09-4a2a-b21d-8dcbfbf5c0b3',
        menuItemId: 'd5b5c2ec-fb51-4a45-804f-6d015fe01343',
        menuSectionId: 'ae816cab-6534-4740-8815-549ff6a9c021',
        displayOrder: 2
      },
      {
        id: '26a0afb9-bd9f-408b-b252-1b407e406040',
        menuItemId: '7790cc8f-3197-42d2-94ea-a17f99b63bbc',
        menuSectionId: 'ae816cab-6534-4740-8815-549ff6a9c021',
        displayOrder: 3
      },
      {
        id: '3c5d6439-6d1e-4582-8beb-971bdcf87a73',
        menuItemId: '7f4e05bf-3149-4f4f-b7db-b0f9c3a456de',
        menuSectionId: 'ae816cab-6534-4740-8815-549ff6a9c021',
        displayOrder: 4
      },
      {
        id: '9a04c4cb-6ab2-4c18-bf46-2586d0c73ddb',
        menuItemId: '21172a6d-7756-4b7c-8fa4-c9beb01cdae2',
        menuSectionId: '250f44e6-6ec9-478b-a230-304ea15effd2',
        displayOrder: 1
      },
      {
        id: '66264963-dbfa-495b-b175-111f27d237ca',
        menuItemId: '9a213128-9b44-4368-9aa7-44d7eb4e5991',
        menuSectionId: '9b7cb079-769e-4a23-8f33-47689b4dbff2',
        displayOrder: 1
      },
      {
        id: '623ec080-4a27-4dad-8ad2-47e0ed536672',
        menuItemId: 'cd0e54ae-ec5d-46c2-9a0c-b3b735a206a7',
        menuSectionId: '9b7cb079-769e-4a23-8f33-47689b4dbff2',
        displayOrder: 2
      },
      {
        id: '9c9ff432-e322-4726-9045-3069ba112b0e',
        menuItemId: '8ac80167-0a06-4a5e-9953-3a9d5cf7afdd',
        menuSectionId: '9b7cb079-769e-4a23-8f33-47689b4dbff2',
        displayOrder: 3
      },
      {
        id: '095c2a49-82ee-4605-b4f5-c0f6146563e4',
        menuItemId: 'a642db17-f45c-4063-8ec8-e177b5dc85ef',
        menuSectionId: 'd089f7db-7312-430a-85d8-12cd4b218437',
        displayOrder: 1
      },
      {
        id: '22cdff23-b777-4be4-8b7e-2bf9195707d5',
        menuItemId: '28e7d5bf-dde6-448f-a42a-e71469da1ae8',
        menuSectionId: 'd089f7db-7312-430a-85d8-12cd4b218437',
        displayOrder: 2
      },
      {
        id: '7dfdea5e-d5c1-44b2-b685-9e1530b2ad69',
        menuItemId: 'c7fd0da9-9da8-44aa-8413-773280efa406',
        menuSectionId: 'd089f7db-7312-430a-85d8-12cd4b218437',
        displayOrder: 3
      },
      {
        id: 'dcf80678-0a4c-4e15-854d-75fe1bc280cb',
        menuItemId: '5615f7e9-8541-471d-a71c-bdaa882a39a0',
        menuSectionId: 'd089f7db-7312-430a-85d8-12cd4b218437',
        displayOrder: 4
      },
      {
        id: '438cba2e-ba20-4210-bb27-396a94301ec7',
        menuItemId: 'e6985905-1bed-449d-9520-3db8c8382262',
        menuSectionId: '98c3311f-2ba8-4339-9154-79f17cc1173f',
        displayOrder: 1
      },
      {
        id: 'aac4b8c1-7a47-40fa-bb60-2fcac456d536',
        menuItemId: '80d989cd-b979-4269-b271-5ecd10d36e04',
        menuSectionId: '98c3311f-2ba8-4339-9154-79f17cc1173f',
        displayOrder: 2
      },
      {
        id: 'b31dc6c6-4976-4a0d-8a5c-c533628bab4d',
        menuItemId: '406b01ff-a54d-466a-ab09-3759a4991560',
        menuSectionId: '98c3311f-2ba8-4339-9154-79f17cc1173f',
        displayOrder: 3
      },
      {
        id: 'a074522b-b5cb-465a-b550-0710bf7578c6',
        menuItemId: '9aaa532b-0378-495e-9fac-e3670dc9d9e9',
        menuSectionId: '98c3311f-2ba8-4339-9154-79f17cc1173f',
        displayOrder: 4
      },
      {
        id: '053a1001-e13b-4455-9513-c8bfd5240b73',
        menuItemId: 'a09f6fd8-d717-49bd-baa3-d08d7cd5b979',
        menuSectionId: '98c3311f-2ba8-4339-9154-79f17cc1173f',
        displayOrder: 5
      },
      {
        id: 'fc80dc81-120c-40fc-ae77-ae2b9f4c58a8',
        menuItemId: '3320f7ad-9f60-4b3e-953c-33b44b9ff9c5',
        menuSectionId: '26d597d4-51df-460b-a4a5-4fc32986ff2d',
        displayOrder: 1
      },
      {
        id: 'fadf1049-ce7f-49eb-9345-29d8d2f89b60',
        menuItemId: 'd00e7cb4-0b2a-46f5-84f6-9b2a8129453e',
        menuSectionId: '26d597d4-51df-460b-a4a5-4fc32986ff2d',
        displayOrder: 2
      },
      {
        id: 'e73c19c8-2450-4679-a8ae-5d3892e150c2',
        menuItemId: '24e99f23-9cb4-402d-9059-8983c121e24f',
        menuSectionId: '26d597d4-51df-460b-a4a5-4fc32986ff2d',
        displayOrder: 3
      },
      {
        id: 'bd6ce56a-9bfd-4bc2-92f5-79f524eec245',
        menuItemId: '586a5701-cce1-4226-ad56-524a9edd9ce8',
        menuSectionId: '26d597d4-51df-460b-a4a5-4fc32986ff2d',
        displayOrder: 4
      },
      {
        id: '619e24e0-3bff-43a3-80d1-7a965b9997c4',
        menuItemId: 'ce47a0b8-2e44-4855-b115-5f66bd84754f',
        menuSectionId: '26d597d4-51df-460b-a4a5-4fc32986ff2d',
        displayOrder: 5
      },
      {
        id: '39434b07-a5dd-4d2e-b89a-0d4278d63e7b',
        menuItemId: '253f0b6f-ca2f-49c9-9271-2de009815746',
        menuSectionId: '51303ef8-6237-4e4c-917e-242764d26189',
        displayOrder: 1
      },
      {
        id: '96614acc-64dc-4a07-b802-914011682fba',
        menuItemId: '3ca6daf2-4208-41f3-b63d-58345467c5bc',
        menuSectionId: '51303ef8-6237-4e4c-917e-242764d26189',
        displayOrder: 2
      },
      {
        id: 'b7622fd2-a112-4a51-8705-87744c1eb441',
        menuItemId: 'b6922a5c-3c92-425f-83c2-cf83b0967a44',
        menuSectionId: '51303ef8-6237-4e4c-917e-242764d26189',
        displayOrder: 3
      },
      {
        id: '71725724-6f3f-4704-ac26-3703ba3a35ee',
        menuItemId: '0c744322-234a-475f-9844-79b6d984e5a5',
        menuSectionId: 'c0fdb324-b497-4932-ba89-c182c01d5dd5',
        displayOrder: 1
      },
      {
        id: '3f0bdcb0-45f0-4c57-a88e-8bcd3c50fb9f',
        menuItemId: 'd65ad30e-d0f8-4c7d-932d-ef68f5783970',
        menuSectionId: 'c0fdb324-b497-4932-ba89-c182c01d5dd5',
        displayOrder: 2
      },
      {
        id: '630fb98b-c882-4647-8000-d7ed47bb13ff',
        menuItemId: 'd006d9c5-4711-4aaf-95d7-724020b407e4',
        menuSectionId: 'c0fdb324-b497-4932-ba89-c182c01d5dd5',
        displayOrder: 3
      },
      {
        id: 'b9f3c2e3-290b-4e37-abe9-6da496b7dcad',
        menuItemId: 'b36ff12b-329a-4abb-8e73-21dac5b01c9d',
        menuSectionId: 'c0fdb324-b497-4932-ba89-c182c01d5dd5',
        displayOrder: 4
      },
      {
        id: 'cb879f75-cbac-4414-9863-0088a017f736',
        menuItemId: '96861857-d01b-435f-bdeb-abf943f47818',
        menuSectionId: 'c0fdb324-b497-4932-ba89-c182c01d5dd5',
        displayOrder: 5
      },
      {
        id: '29e2bbd0-a95d-4859-afd4-d9282e50b7f6',
        menuItemId: '3d8bdd4f-62e2-4f49-bec5-56cb851de3ce',
        menuSectionId: 'a73a930d-457e-4f34-b582-fa8fdcb91f30',
        displayOrder: 1
      },
      {
        id: '12cb6d20-9f87-4637-9748-11547d913c11',
        menuItemId: 'd365fadc-e11b-4096-ab7e-70a3daf749ba',
        menuSectionId: 'a73a930d-457e-4f34-b582-fa8fdcb91f30',
        displayOrder: 2
      },
      {
        id: 'c806150c-bab0-42c1-99de-a0b572f12f3d',
        menuItemId: 'b1b8cf51-7c8b-467c-bd8b-ad2701a7ff2f',
        menuSectionId: '58b8d9a9-d0d4-41cc-9c31-45d6f1b44ce0',
        displayOrder: 1
      },
      {
        id: '7e13fddb-a82d-46b7-8600-126fe693c0a2',
        menuItemId: '498598f8-b9af-4154-9251-af2056fc65e9',
        menuSectionId: 'ca4be1b9-391c-41e8-ae73-14af04091cc4',
        displayOrder: 1
      },
      {
        id: '1b4b1e56-e50c-48c2-b500-59c6cabbc3b1',
        menuItemId: 'a1185edb-32b2-4991-9e3d-dc1a5493f23a',
        menuSectionId: 'ca4be1b9-391c-41e8-ae73-14af04091cc4',
        displayOrder: 2
      },
      {
        id: 'ed1e6ee6-f128-4f6f-a03a-a1be358e303b',
        menuItemId: 'ff2cd3f9-5686-49a7-8676-1535732f906f',
        menuSectionId: 'ca4be1b9-391c-41e8-ae73-14af04091cc4',
        displayOrder: 3
      },
      {
        id: 'd9767a00-7b14-49dd-b06f-b338b8da3284',
        menuItemId: 'dcade7e7-d197-48e4-ac5d-739c53c98eeb',
        menuSectionId: 'ca4be1b9-391c-41e8-ae73-14af04091cc4',
        displayOrder: 4
      },
      {
        id: 'b20ba848-fabb-4ffa-9ae5-abeee1e9c020',
        menuItemId: 'add1f6cb-eab5-4942-976f-03c05a3eaefb',
        menuSectionId: 'ca4be1b9-391c-41e8-ae73-14af04091cc4',
        displayOrder: 5
      },
      {
        id: '8eddd79f-bdfb-4ec6-9466-61d0b3309953',
        menuItemId: '9aa04415-c10f-4d91-9276-8175465a5af3',
        menuSectionId: 'ca4be1b9-391c-41e8-ae73-14af04091cc4',
        displayOrder: 6
      },
      {
        id: 'db25a67e-af35-46c8-8076-4cec6ad4eed2',
        menuItemId: '0ccac3ca-23ce-4071-995b-0b559e908cf5',
        menuSectionId: 'ca4be1b9-391c-41e8-ae73-14af04091cc4',
        displayOrder: 7
      },
      {
        id: 'fc8cb07a-abbf-4ea4-ac61-5fbfca242272',
        menuItemId: '5fcdad47-3673-4062-a135-e04bfcdcb134',
        menuSectionId: '7d43cf2c-5c41-49f0-8d0e-b7d8e6a1e1f5',
        displayOrder: 1
      },
      {
        id: '7cb36ec1-c55c-4faf-bcd7-754b6a0b68c6',
        menuItemId: 'ec9ff819-2dd9-4444-a476-684adf125837',
        menuSectionId: '7d43cf2c-5c41-49f0-8d0e-b7d8e6a1e1f5',
        displayOrder: 2
      },
      {
        id: 'e36b0cc6-13ad-479f-a49d-c3148286b1c8',
        menuItemId: '00a001ba-5abc-4b33-a075-cbdeda407d20',
        menuSectionId: '7d43cf2c-5c41-49f0-8d0e-b7d8e6a1e1f5',
        displayOrder: 3
      },
      {
        id: 'f85c288a-fdfa-4b1f-b15d-7b57f934d1c5',
        menuItemId: 'f62ef31c-d6b5-4f4b-a7c2-6ff90ebfe3f8',
        menuSectionId: '7d43cf2c-5c41-49f0-8d0e-b7d8e6a1e1f5',
        displayOrder: 4
      },
      {
        id: '82191edd-6e21-4827-907c-6bff87294604',
        menuItemId: 'ed0018e1-2256-4fba-9258-7880341e2e9a',
        menuSectionId: '7d43cf2c-5c41-49f0-8d0e-b7d8e6a1e1f5',
        displayOrder: 5
      },
      {
        id: '20ee0efd-02a3-4251-8a49-d8af4a7581bf',
        menuItemId: '8b2eb3e2-4a29-4b1d-be03-a2a80d35a15d',
        menuSectionId: '7d43cf2c-5c41-49f0-8d0e-b7d8e6a1e1f5',
        displayOrder: 6
      },
      {
        id: '79df99ec-3878-4feb-9297-c3d2efbdb95b',
        menuItemId: '25d28644-d7e6-4277-b8e2-aa9f7e20c65a',
        menuSectionId: '7d43cf2c-5c41-49f0-8d0e-b7d8e6a1e1f5',
        displayOrder: 7
      },
      {
        id: '6cf65a13-1dac-4e80-a6ee-fdf6edc1e5d6',
        menuItemId: '0faca297-f347-442a-933f-0ba6395e044c',
        menuSectionId: '7d43cf2c-5c41-49f0-8d0e-b7d8e6a1e1f5',
        displayOrder: 8
      },
      {
        id: '255e146c-6123-4e78-8a53-933b10181dd9',
        menuItemId: 'bfbc083c-c412-4968-a0b8-a3000c480eb4',
        menuSectionId: '7d43cf2c-5c41-49f0-8d0e-b7d8e6a1e1f5',
        displayOrder: 9
      },
      {
        id: '4ed948cf-2dca-4fb9-8621-2d0c677af4ef',
        menuItemId: '89f25e6b-8d4d-456e-a5c3-33c103e9a19b',
        menuSectionId: '7d43cf2c-5c41-49f0-8d0e-b7d8e6a1e1f5',
        displayOrder: 10
      },
      {
        id: 'd54eff49-1162-4eb6-a950-3624dcbf4c58',
        menuItemId: '4acf0bbe-2780-4250-895e-bffa291efb7e',
        menuSectionId: '0682f7d0-41cf-49e1-819c-6c6109c4bc8d',
        displayOrder: 1
      },
      {
        id: 'fefe4be7-e66d-4d98-8d88-a8576ad0402d',
        menuItemId: '92b3eca0-01e1-4d0d-99c8-726385c15cdb',
        menuSectionId: '0682f7d0-41cf-49e1-819c-6c6109c4bc8d',
        displayOrder: 2
      },
      {
        id: '81a82214-9cde-459c-866e-3d038a365690',
        menuItemId: '1f1a040f-2a51-403a-acba-26b6ada2919a',
        menuSectionId: 'b4533b15-56da-409e-b396-a0bbe682832d',
        displayOrder: 1
      },
      {
        id: 'ab3abefd-96bf-4c77-8ce1-6e25596dc436',
        menuItemId: '9d31e707-4417-47cc-b0a7-959e0a204fec',
        menuSectionId: 'b4533b15-56da-409e-b396-a0bbe682832d',
        displayOrder: 2
      },
      {
        id: '0b984f83-2b78-4fae-b1fd-68b7979ac137',
        menuItemId: '9de760ac-a51f-4e9a-915b-edf233cc6bd0',
        menuSectionId: 'b4533b15-56da-409e-b396-a0bbe682832d',
        displayOrder: 3
      },
      {
        id: '7cb4e1be-ca8b-4a21-b545-cf9c11b01cb2',
        menuItemId: 'b2be0e58-e35e-4cac-9028-4abf7bc1000f',
        menuSectionId: 'b4533b15-56da-409e-b396-a0bbe682832d',
        displayOrder: 4
      },
      {
        id: '04b3b795-e8ca-4471-a1a6-4fc4fe7df99e',
        menuItemId: 'a825d0c1-415b-4c92-8d20-e59741a6f78e',
        menuSectionId: 'b4533b15-56da-409e-b396-a0bbe682832d',
        displayOrder: 5
      },
      {
        id: '2c348906-d178-48e7-8673-9da26446efca',
        menuItemId: '2559673f-14bc-439d-bd15-0defab251a07',
        menuSectionId: 'b4533b15-56da-409e-b396-a0bbe682832d',
        displayOrder: 6
      },
      {
        id: '782191cf-8250-4480-a634-94b8e63cd482',
        menuItemId: 'f63ba325-0e9f-4acd-bba8-872edb7dade4',
        menuSectionId: 'b4533b15-56da-409e-b396-a0bbe682832d',
        displayOrder: 1
      },
      {
        id: '72251706-a6ce-48ea-a4e3-75214a66dd94',
        menuItemId: '85024b68-4dfd-4eab-9cbb-f3ff6eeb7dd3',
        menuSectionId: 'b4533b15-56da-409e-b396-a0bbe682832d',
        displayOrder: 2
      },
      {
        id: 'f9cfe8a0-958c-4790-8725-9609fac332a0',
        menuItemId: 'b4846f81-5bb8-4b56-8e89-95c898d74a2e',
        menuSectionId: 'b4533b15-56da-409e-b396-a0bbe682832d',
        displayOrder: 3
      },
      {
        id: 'c59a5325-7993-464f-996f-d90df7f956f0',
        menuItemId: '9289fa5a-5b81-4f28-bb2e-b29701d6e3ba',
        menuSectionId: '5c508637-376b-4442-a0ea-6bdb3bd7c1da',
        displayOrder: 1
      },
      {
        id: '71f9aa3b-c6ae-46f1-b20c-5fedadde819f',
        menuItemId: '85fd5ef7-f594-4bf9-95dc-06d561f27055',
        menuSectionId: '5c508637-376b-4442-a0ea-6bdb3bd7c1da',
        displayOrder: 2
      },
      {
        id: '2b767ffa-d46a-46f0-b27f-9c69015c645d',
        menuItemId: '58128ff8-2796-475e-9960-2ae7eb5c95aa',
        menuSectionId: '5c508637-376b-4442-a0ea-6bdb3bd7c1da',
        displayOrder: 3
      },
      {
        id: 'ddfb4859-b66d-4f96-b227-36acd7467ca0',
        menuItemId: 'c37292b8-c147-4c69-9650-42f2e8b85a85',
        menuSectionId: '5c508637-376b-4442-a0ea-6bdb3bd7c1da',
        displayOrder: 4
      },
      {
        id: '38727800-b9f8-440e-abdc-ce60c728fcad',
        menuItemId: 'f7bfd447-e33a-4ac2-b225-3e1282ca7acf',
        menuSectionId: '5c508637-376b-4442-a0ea-6bdb3bd7c1da',
        displayOrder: 5
      },
      {
        id: '53861fe1-c5b9-4012-94dc-63c131f381e8',
        menuItemId: '651d851c-bfe6-4c70-b22f-3933547ac62b',
        menuSectionId: '5c508637-376b-4442-a0ea-6bdb3bd7c1da',
        displayOrder: 6
      },
      {
        id: '3049fe76-fff6-49c4-808d-c98e6c335bea',
        menuItemId: '440d2dc2-96dd-4ae8-ae3c-cd4b483c05d7',
        menuSectionId: '5c508637-376b-4442-a0ea-6bdb3bd7c1da',
        displayOrder: 7
      },
      {
        id: '56bde8e7-b51b-4440-b700-c1d46580c9d2',
        menuItemId: '36503a26-78cf-450b-a97c-69573eaa871e',
        menuSectionId: '54073acc-3ae2-439e-8415-a0031b97c808',
        displayOrder: 1
      },
      {
        id: 'd2602fb6-e2f4-489c-ba4d-60960791c5b8',
        menuItemId: 'a6762b6d-5ce9-49dd-ba2f-5da7394b8c50',
        menuSectionId: '54073acc-3ae2-439e-8415-a0031b97c808',
        displayOrder: 2
      },
      {
        id: '8e3a9f9d-136e-42c2-97e0-927de5a25b40',
        menuItemId: 'a789c8df-dc3d-44f3-83bb-3af4f539f9e4',
        menuSectionId: '54073acc-3ae2-439e-8415-a0031b97c808',
        displayOrder: 3
      },
      {
        id: '45dd10f3-9de8-46ca-adf1-e4af87552e77',
        menuItemId: 'a348a9f3-3a45-4312-a493-8dfeb2e97864',
        menuSectionId: '54073acc-3ae2-439e-8415-a0031b97c808',
        displayOrder: 4
      },
      {
        id: '3b863b7c-8137-4935-8782-f45f71206836',
        menuItemId: 'd780c36c-7886-47fd-9ebb-98acca23cc46',
        menuSectionId: '54073acc-3ae2-439e-8415-a0031b97c808',
        displayOrder: 5
      },
      {
        id: '1f5be55e-34e7-4900-b1ff-e73887cbb494',
        menuItemId: '45e978fc-e3ee-44d8-8d9d-7104e7663a6d',
        menuSectionId: '34e87b7e-0e4c-4e04-8e83-55a8890abc3f',
        displayOrder: 1
      },
      {
        id: '944698c8-531d-44c2-8551-d09434f05b5c',
        menuItemId: '7f8351c8-99d2-42d3-a6bb-93ce88f4030b',
        menuSectionId: '34e87b7e-0e4c-4e04-8e83-55a8890abc3f',
        displayOrder: 2
      },
      {
        id: '6ab5d237-d3c7-41e0-8a1b-ae84d51450c2',
        menuItemId: 'c38e4f8e-e4c6-4cdc-a044-ece81c855635',
        menuSectionId: '920ec36b-d796-4387-86c1-b693ed90c9c5',
        displayOrder: 1
      },
      {
        id: '92824aa0-aea9-49e4-b32c-c7c19bb25d4b',
        menuItemId: '4bf3f7e4-d7f9-4137-8cc2-237799c42691',
        menuSectionId: '920ec36b-d796-4387-86c1-b693ed90c9c5',
        displayOrder: 2
      },
      {
        id: '66fee157-e5cd-475f-b324-6261af6049c1',
        menuItemId: '7a8aece4-0cd8-4c68-b135-efe20957dc4a',
        menuSectionId: '920ec36b-d796-4387-86c1-b693ed90c9c5',
        displayOrder: 3
      },
      {
        id: 'f4d4f30d-01ae-4f55-a63e-2ab3d2fb6637',
        menuItemId: '8ca8830a-77c7-4e30-8fa7-d51025bdcc79',
        menuSectionId: 'b581d32f-e8d5-408a-823a-5b21e234611a',
        displayOrder: 1
      },
      {
        id: '869c17d9-7536-4ca4-9ba9-d59845c12dd6',
        menuItemId: 'de85dcde-7ca2-4471-aea1-b9b31222dd56',
        menuSectionId: 'b581d32f-e8d5-408a-823a-5b21e234611a',
        displayOrder: 2
      },
      {
        id: '59315586-2674-4ef9-b0d4-dd9c3f821841',
        menuItemId: '131a07ba-67fe-4f7b-80bc-9b9bdeb9ebdc',
        menuSectionId: 'b581d32f-e8d5-408a-823a-5b21e234611a',
        displayOrder: 3
      },
      {
        id: '41090913-7afc-495d-bd84-6c7298cd88cf',
        menuItemId: 'cdbe9999-cb3e-45f0-b334-987355f670a0',
        menuSectionId: 'b581d32f-e8d5-408a-823a-5b21e234611a',
        displayOrder: 4
      },
      {
        id: 'c6a6d9d4-f111-4a0d-a78a-ca2ba2578df9',
        menuItemId: '8e195e96-7c76-4b35-bd55-102fbb2c4f36',
        menuSectionId: 'b581d32f-e8d5-408a-823a-5b21e234611a',
        displayOrder: 5
      },
      {
        id: 'b6dd2826-7356-4d57-8843-60ffd2025f5b',
        menuItemId: '418d850a-62bb-442e-bda0-b3662ae09c65',
        menuSectionId: 'b581d32f-e8d5-408a-823a-5b21e234611a',
        displayOrder: 6
      },
      {
        id: 'd4b0c90c-0b3f-4015-b7e8-58bb93175b9b',
        menuItemId: '5f630db7-b20c-4c05-922d-a4bb0833b157',
        menuSectionId: 'b581d32f-e8d5-408a-823a-5b21e234611a',
        displayOrder: 7
      },
      {
        id: '6cc3fd35-230a-4b54-b9f9-9900198b3832',
        menuItemId: 'c6217d86-a00b-4d64-a80b-268574bb291e',
        menuSectionId: 'b581d32f-e8d5-408a-823a-5b21e234611a',
        displayOrder: 8
      },
      {
        id: '96240ef9-70e6-4fea-a799-c5f360f74854',
        menuItemId: 'b713d3ce-49c9-4c38-ada3-85865014d98d',
        menuSectionId: '5ee67c2e-5d3a-420a-a31d-fe210caa5432',
        displayOrder: 1
      },
      {
        id: 'f3f87102-853b-4bd4-852a-353f74206af4',
        menuItemId: 'f39ba32f-febc-4fca-ab30-b798304d2db9',
        menuSectionId: '5ee67c2e-5d3a-420a-a31d-fe210caa5432',
        displayOrder: 2
      },
      {
        id: '235f6386-f678-4103-bee4-ebbc036c60d1',
        menuItemId: '94d97d91-8be4-4283-9c6d-733700ad387a',
        menuSectionId: '5ee67c2e-5d3a-420a-a31d-fe210caa5432',
        displayOrder: 3
      },
      {
        id: '05cbf771-3c6c-48f3-8f27-b033382ec23b',
        menuItemId: 'df95cd1a-7b40-4694-a1d8-0bfcaaef4be6',
        menuSectionId: '5ee67c2e-5d3a-420a-a31d-fe210caa5432',
        displayOrder: 4
      },
      {
        id: '21f3eaf8-7754-4d61-a37e-5e8778a059c8',
        menuItemId: '9df1c0d3-86ea-46ac-aee1-10838a0880f3',
        menuSectionId: 'a11b3f12-64b1-4c3d-991e-56583ee39874',
        displayOrder: 1
      },
      {
        id: '7e0fc5a9-8630-4389-8e63-f0aa9b032278',
        menuItemId: '25b4e364-faa1-4a38-9b6b-cdbb0d1694e7',
        menuSectionId: '69fd031e-9945-4434-847e-d071a94837ab',
        displayOrder: 1
      },
      {
        id: '85b2641f-0e8e-4206-a9e1-b96b3e03d376',
        menuItemId: '4aa443ae-4d6f-40e1-a2c4-31030d393e7b',
        menuSectionId: '69fd031e-9945-4434-847e-d071a94837ab',
        displayOrder: 2
      },
      {
        id: '494a64fa-b021-4fdf-ae35-bc3ec1279ea9',
        menuItemId: '1508b090-5c3d-44d2-9bb5-f352bc8fd3fc',
        menuSectionId: '69fd031e-9945-4434-847e-d071a94837ab',
        displayOrder: 3
      },
      {
        id: '847aa8c4-1018-428c-9621-15e8c6be2320',
        menuItemId: '000c9e18-42e9-493f-97dc-81880b268a1d',
        menuSectionId: '69fd031e-9945-4434-847e-d071a94837ab',
        displayOrder: 4
      },
      {
        id: 'd56de349-2226-4c03-b0ae-866b7852dae6',
        menuItemId: '99eccbaa-6aa6-428e-b021-c131eeda995f',
        menuSectionId: '69fd031e-9945-4434-847e-d071a94837ab',
        displayOrder: 5
      },
      {
        id: '0af59a1f-3192-47c0-b201-9ec7deb008b9',
        menuItemId: '306658ea-385b-438d-95e0-e17f4a1b030b',
        menuSectionId: 'e7932ff7-19eb-470b-80ee-4b3822892ba6',
        displayOrder: 1
      },
      {
        id: 'a21260ec-9265-4df9-9b05-843095c6933b',
        menuItemId: 'a712a81a-5f10-4ace-97c9-c94dcd0c6f9a',
        menuSectionId: 'e7932ff7-19eb-470b-80ee-4b3822892ba6',
        displayOrder: 2
      },
      {
        id: '1d22495c-5291-4884-92b6-caf900be6df8',
        menuItemId: '5b2ad7f2-6d39-4319-aa80-94de193ca039',
        menuSectionId: 'e7932ff7-19eb-470b-80ee-4b3822892ba6',
        displayOrder: 3
      },
      {
        id: '2cc8380c-d6ea-4143-9498-c854edbdd284',
        menuItemId: '589e47f8-ee1e-415b-a1cc-d38a6236f0c3',
        menuSectionId: 'e7932ff7-19eb-470b-80ee-4b3822892ba6',
        displayOrder: 4
      },
      {
        id: '2da58ef8-d8d1-4649-b909-a360fcb61e97',
        menuItemId: '5c5ec610-46e9-4286-8de8-e8e814a57335',
        menuSectionId: 'e7932ff7-19eb-470b-80ee-4b3822892ba6',
        displayOrder: 5
      },
      {
        id: 'd643800c-438e-433c-ac29-28c3ec23c332',
        menuItemId: '41bfa653-a1c7-4853-a9bf-06ab98ce6e0c',
        menuSectionId: 'd6aa54d5-f2be-4c86-9887-084bbf9a6508',
        displayOrder: 1
      },
      {
        id: '32f314cb-e917-462b-ab8a-f9b362ff7e7b',
        menuItemId: '555f1fe8-8922-4f71-aeb0-a864ce666cc0',
        menuSectionId: '56133c35-9e48-4168-afc6-68d55f57fd3c',
        displayOrder: 1
      },
      {
        id: 'f245d7dd-4fed-4294-b7f0-cff902946b68',
        menuItemId: 'adf4e856-b827-4950-9124-2b0c328438c4',
        menuSectionId: '53dcff0e-2928-47ab-9629-f206394fa499',
        displayOrder: 1
      },
      {
        id: '2a2fa34f-f198-49a3-8756-ef846655d06c',
        menuItemId: 'd7c6cb0a-7cd3-4851-8d81-8b4b57d41a03',
        menuSectionId: 'be9c333a-7f21-4623-bbe6-239ff2453fbd',
        displayOrder: 1
      },
      {
        id: 'ee6d859f-bb1f-4f63-8220-c9757e406934',
        menuItemId: 'd4f14f83-b90a-464e-bc48-ac98d45d37d2',
        menuSectionId: 'be9c333a-7f21-4623-bbe6-239ff2453fbd',
        displayOrder: 2
      },
      {
        id: 'a10c29db-38ab-4d5d-9a63-5fe45a8de88a',
        menuItemId: '08d3ada6-8d77-4a02-ab28-d20229897d63',
        menuSectionId: 'be9c333a-7f21-4623-bbe6-239ff2453fbd',
        displayOrder: 3
      },
      {
        id: 'afbe2de1-de56-4e8d-970c-301c50edd9f5',
        menuItemId: 'de87a971-200d-4c78-8d05-3fb8a720e766',
        menuSectionId: 'aeafb54d-9c69-4774-a195-5b12098951d0',
        displayOrder: 1
      },
      {
        id: '966e84ad-8897-454e-abc9-665d6ce1d404',
        menuItemId: 'ea081e2e-7d3c-4a44-95c7-70640ae4c82b',
        menuSectionId: '88035f39-3512-45b0-a823-a3bb6bef3dc0',
        displayOrder: 1
      },
      {
        id: '2ff5ae6e-ca83-46ad-b35c-6c0d418258a4',
        menuItemId: '91838c4c-6504-4200-8a2d-d1efd0f53629',
        menuSectionId: '88035f39-3512-45b0-a823-a3bb6bef3dc0',
        displayOrder: 2
      },
      {
        id: '61ef0215-eb9a-4415-9037-944a4623dd29',
        menuItemId: '07645125-c14f-40c5-a937-7e48b9387203',
        menuSectionId: '88035f39-3512-45b0-a823-a3bb6bef3dc0',
        displayOrder: 3
      },
      {
        id: '9959b877-e0e2-43b7-973a-fce2708ae85b',
        menuItemId: '98ce7605-25d8-4604-9aba-29075097d2a9',
        menuSectionId: '88035f39-3512-45b0-a823-a3bb6bef3dc0',
        displayOrder: 4
      },
      {
        id: '94ac4e2c-3d7c-40e5-a82f-be31ec045b70',
        menuItemId: '27124cc1-0b57-4c7c-b33e-892dea04665e',
        menuSectionId: '88035f39-3512-45b0-a823-a3bb6bef3dc0',
        displayOrder: 5
      },
      {
        id: '271ab32e-3470-4156-b2ce-50422d405115',
        menuItemId: 'c6afc4db-a805-4078-a6f0-415c01263721',
        menuSectionId: '25bc8f71-9766-4e42-bc6a-e192042423c0',
        displayOrder: 1
      },
      {
        id: 'fcb28f66-3dfb-440e-9259-2ccc9baebf07',
        menuItemId: '911bec71-d315-4137-bd7f-8f29473b3772',
        menuSectionId: '25bc8f71-9766-4e42-bc6a-e192042423c0',
        displayOrder: 2
      },
      {
        id: '39e6c049-3b09-4ffd-ba5f-28c6b9137149',
        menuItemId: '4e28f318-ba7b-4ea4-b183-ceb5047e2b83',
        menuSectionId: '25bc8f71-9766-4e42-bc6a-e192042423c0',
        displayOrder: 3
      },
      {
        id: '9aee0c98-b79e-48c9-9c46-eb3ad4b6e794',
        menuItemId: 'd8fef775-35d0-4f69-b2ac-6dbe4a89f279',
        menuSectionId: '25bc8f71-9766-4e42-bc6a-e192042423c0',
        displayOrder: 4
      },
      {
        id: 'dbec05e6-b1ff-403b-ba45-26f0cceac9b2',
        menuItemId: 'b5da2413-567c-470f-91fd-384f1664adf0',
        menuSectionId: '25bc8f71-9766-4e42-bc6a-e192042423c0',
        displayOrder: 5
      },
      {
        id: 'd5058265-f608-433f-a5d4-5de0bf9000a0',
        menuItemId: '33b06b0b-a2e8-4ef6-9e94-f02d503b139f',
        menuSectionId: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        displayOrder: 1
      },
      {
        id: '23db998d-3304-44f7-8bd4-a026e861194c',
        menuItemId: '90a45599-d93c-46f2-81ad-4b7d01befb9a',
        menuSectionId: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        displayOrder: 2
      },
      {
        id: 'd0c7c7f6-eeda-4518-944b-bda917bd13c8',
        menuItemId: 'c7d1c8dd-ee2b-4887-9230-05a451a08586',
        menuSectionId: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        displayOrder: 3
      },
      {
        id: '80436f1c-c628-4bc5-b426-811742bb6136',
        menuItemId: 'cf6932bc-8b45-4925-a3a0-aad592b3ee21',
        menuSectionId: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        displayOrder: 4
      },
      {
        id: '823dacc5-a84b-4d21-9ea2-c341fc8c137c',
        menuItemId: '786bec21-b70c-48d2-a940-ae415d4415f9',
        menuSectionId: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        displayOrder: 5
      },
      {
        id: 'd42cb18b-6b8f-495f-88b6-f52ead715ac7',
        menuItemId: '161e6023-9a17-468c-8acf-29320eac842b',
        menuSectionId: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        displayOrder: 6
      },
      {
        id: '065321ce-5cc8-41cc-8918-0aae10d4f87c',
        menuItemId: '1119943b-3885-4669-a5b6-2e6714bb705a',
        menuSectionId: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        displayOrder: 7
      },
      {
        id: '455ab5cd-96c5-4326-92e1-daad5d7de9c0',
        menuItemId: '98ff6ca1-12c5-4729-8abd-d070fc39aa64',
        menuSectionId: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        displayOrder: 8
      },
      {
        id: 'e1973505-8656-43af-bf89-418db0ddf079',
        menuItemId: 'f92b01fc-6fb0-40f2-af9b-ca467be1529a',
        menuSectionId: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        displayOrder: 9
      },
      {
        id: '6f2b423e-6062-48cf-b016-52ed5a765e05',
        menuItemId: 'd6a2242b-650c-4df5-914a-b7ebf4e631e5',
        menuSectionId: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        displayOrder: 10
      },
      {
        id: '244c2870-1548-4fa8-86d6-b0f0fee51a18',
        menuItemId: '7dce51e2-896d-4fd0-8353-3ea320055e79',
        menuSectionId: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        displayOrder: 11
      },
      {
        id: 'a0e427c1-79d8-48e6-9295-6d635516e83f',
        menuItemId: '3c1368d0-d0ea-4a6e-8cb0-342d8df655eb',
        menuSectionId: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        displayOrder: 12
      },
      {
        id: '60202519-6b97-4d8e-b862-ac902cbe1c59',
        menuItemId: '4e14a273-95aa-41c3-a40a-eb34726ae075',
        menuSectionId: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        displayOrder: 13
      },
      {
        id: '3d26a581-c385-4e9b-8b22-53ff7549abd1',
        menuItemId: 'b87a7472-b4a0-495e-bbf9-99e674a8f6e9',
        menuSectionId: '93704af6-be37-4ea8-8d2c-bf807cacbdf8',
        displayOrder: 14
      }
    ],
    modifier_set: [],
    modifier: [],
    modifier_set_member: {},
    item_modifier_set: {},
    item_modifier: {},
    menu_item_modifier_set: {},
    menu_item_modifier: {},
    restOperationType: false,
    importData: [],
    importType: 'items',
    sortItemsBy: 'name',
    sortItemsAsc: true,
    fullScreen: true,
    activeBrand: {},
    activeRestaurant: '',
    activeMenu: '',
    activeSection: '',
    activeItem: '',
    activeMenuSectionItem: '',
    selectedRestaurantId: '',
    selectedMenuId: '',
    selectedSectionId: '',
    editingRestaurant: false,
    restaurantsOperated: '2',
    holdingCompany: '1',
    brandsOperated: '2',
    setFullScreen: {},
    updateState: {}
  }
  return data
}
