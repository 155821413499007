import { authHeaderFilePost, apiClientSiteUrl, handleResponse } from '../_helpers'

export const importService = {
  uploadImportFile
}

function uploadImportFile (data) {
  data.formData.append('action', 'importFile')
  data.formData.append('restGroupId', data.restGroupId)
  data.formData.append('restId', data.restId)
  const requestOptions = {
    method: 'POST',
    headers: authHeaderFilePost(),
    body: data.formData
  }
  return fetch(apiClientSiteUrl('/import'), requestOptions).then(handleResponse)
}
