import { authHeader, apiUrl, handleResponse } from '../_helpers'

export const restGroupsService = {
  getAll,
  getById,
  add,
  update,
  getRestaurants
}

function getAll () {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'restgroup',
      data: {
        action: 'getAll'
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'restgroup',
      data: {
        action: 'getRestGroup',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getRestaurants (restGroupId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'restgroup',
      data: {
        action: 'getRestGroupRestaurants',
        restGroupId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function add (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'restgroup',
      data: {
        action: 'addRestGroup',
        operator_id: data.restgroupoperator,
        brand_id: data.restgroupbrand,
        name: data.restgroupname,
        description: data.restgroupdescription,
        association: data.restgroupassociation
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function update (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'restgroup',
      data: {
        action: 'updateRestGroup',
        restaurant_group_id: data.editingrestgroup,
        operator_id: data.restgroupoperator,
        brand_id: data.restgroupbrand,
        name: data.restgroupname,
        description: data.restgroupdescription,
        association: data.restgroupassociation,
        deleteRestGroup: data.deleterestgroup
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}
