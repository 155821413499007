<template>
  <div class="component-container menu">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 middle-divider">
          <h1 class="mb-3">What is the name of your menu?</h1>
          <ul>
            <li>Main Menu, All Day, Breakfast, Lunch or Dinner are some example menu names. You can use whatever is appropriate for your restaurant and change it at anytime.</li>
            <li>Your restaurant also might have multiple menus. For now let's work on setting up one of them. You can add more later.</li>
          </ul>
        </div>
        <div class="col-md-6">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                  <div class="grey-text">
                    <mdb-input label="Fill in the name of your menu." icon="edit" type="text" v-model="$parent.setup.menuName" class="p-0" />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 text-left">
          <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.goToPrevComponent()"><mdb-icon icon="arrow-alt-circle-left" size='lg' /> Go Back</button>        </div>
        <div class="col-md-6 text-right">
           <button v-if="$parent.setup.menuName" type="button" class="btn btn-primary btn-lg px-3" v-on:click="singleMenu()">Next Question <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mdbInput, mdbIcon } from 'mdbvue'
export default {
  name: 'singlemenu',
  components: { mdbInput, mdbIcon },
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
    }
  },
  watch: {},
  methods: {
    singleMenu () {
      const selectedMenuId = this.$parent.selectedMenuId
      const selectedRestaurantId = this.$parent.selectedRestaurantId
      const menuName = this.$parent.setup.menuName
      if (selectedMenuId) {
        const data = {
          name: 'menus',
          keyName: 'id',
          keyValue: selectedMenuId,
          updateKey: 'name',
          updateKeyValue: menuName
        }
        this.$parent.updateSetupStateArrayItem(data)
      } else {
        const menuId = this.$parent.uuid()
        const menu = {
          id: menuId,
          name: menuName,
          restId: selectedRestaurantId
        }
        this.$parent.updateState({ name: 'selectedMenuId', value: menuId })
        this.$parent.setup.menus.push(menu)
      }
      this.$parent.sectionsComponent()
    }
  },
  computed: {}
}
</script>
