import { restGroupsService } from '../_services'

const state = {
  all: {},
  activerestgroup: {},
  editrestgroup: {},
  addrestgroup: {},
  updaterestgroup: {},
  idsNamesSelect: [],
  restaurants: {}
}

const actions = {
  getAll ({ dispatch, commit }) {
    const stateName = 'all'
    commit('request', { stateName: stateName })

    restGroupsService.getAll()
      .then(
        restgroups => {
          commit('getAllSuccess', restgroups.data)
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getById ({ dispatch, commit }, id) {
    const stateName = 'activerestgroup'
    commit('request', { stateName: stateName })

    restGroupsService.getById(id)
      .then(
        restgroup => commit('getByIdSuccess', restgroup.data),
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getRestaurants ({ dispatch, commit }, restGroupId) {
    const stateName = 'restaurants'
    commit('request', { stateName: stateName })

    restGroupsService.getRestaurants(restGroupId)
      .then(
        restaurants => commit('getRestaurantsSuccess', restaurants.data),
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  add ({ dispatch, commit }, name, description) {
    const stateName = 'addrestgroup'
    commit('request', { stateName: stateName })

    restGroupsService.add(name, description)
      .then(
        restgroup => {
          commit('addSuccess', restgroup.data)
          dispatch('alert/success', 'Added restgroup', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  update ({ dispatch, commit }, restgroupId, name, description) {
    const stateName = 'updaterestgroup'
    commit('request', { stateName: stateName })

    restGroupsService.update(restgroupId, name, description)
      .then(
        restgroup => {
          commit('updateSuccess', restgroup.data)
          dispatch('alert/success', 'Edited restgroup', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  getAllSuccess (state, restgroups) {
    state.all = { restgroups }
    const selectData = []
    Array.from(restgroups).forEach(restgroup => {
      selectData.push({ code: restgroup.restaurant_group_id, label: restgroup.name })
    })
    state.idsNamesSelect = selectData
  },
  getByIdSuccess (state, restgroup) {
    state.activerestgroup = restgroup
  },
  getRestaurantsSuccess (state, restaurants) {
    state.restaurants = restaurants
  },
  addSuccess (state, restgroup) {
    state.addrestgroup = restgroup
  },
  updateSuccess (state, restgroup) {
    state.updaterestgroup = restgroup
  }
}

export const restgroups = {
  namespaced: true,
  state,
  actions,
  mutations
}
