<template>
  <div class="component-wrapper">
    <div class="container-fluid mb-sm-5">
      <div class="row">
        <div class="col-12">
          <div class="container mt-sm-5 my-1 bg-light">
              <div class="question ml-sm-5 pt-2">
                  <div class="py-2 h5"><b>Please add or import your menu items.</b></div>
                  <button type="button" class="btn btn-sm btn-primary" v-on:click="openItemEditor()">Add Item</button>
                  <button type="button" class="btn btn-sm btn-primary" v-on:click="showImport()">Import Items</button>
              </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="displayImport">
        <div class="col-12">
          <div class="container mt-sm-5 my-1 bg-light">
              <div class="question ml-sm-5 pt-2">
                  <div class="py-2 h5"><b>Item Import</b> <button type="button" class="btn btn-sm btn-danger" v-on:click="showImport()">x Close</button></div>
                  <p style="display: none;">Upload a properly formatted excel spreadsheet of item data to import items. <a href="/downloads/item-import-v1.xlsx">Download this template to get started.</a></p>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="importType" value="structure" :checked="importType === 'structure'" @change="$parent.updateState({ name: 'importType', value: 'structure' })">
                    <label class="form-check-label">Structure</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="importType" id="inlineRadio3" value="items" :checked="importType === 'items'" @change="$parent.updateState({ name: 'importType', value: 'items' })">
                    <label class="form-check-label" for="inlineRadio3">Items</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="file" @change="onChange($event)" class="pt-2 pb-2" />
                  </div>
              </div>
              <div class="container" v-if="importData.length">
                <p>Import Complete!</p>
                <pre>
                  {{importData}}
                </pre>
              </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="container mt-sm-5 my-1 bg-light">
              <div class="question ml-sm-5 pt-2">
                <div class="py-2 h5"><b>Menu Items</b> (select a menu)</div>
                <div class="ml-md-3 ml-sm-3 pl-md-5 pt-sm-0 pt-3">
                  <select class="form-control" :value="selectedMenuId" @change="restMenuSelectOnChange($event)">
                    <option value=''>-- Select Menu --</option>
                    <option v-for="(menu, index) of restMenuSelect()" v-bind:key="index" :value="menu.id">
                      {{menu.name}}
                    </option>
                  </select>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="this.selectedMenuId">
        <div class="col-12">
          <div v-if="availableSections(selectedMenuId).length" style="word-wrap: break-word;">
            <div class="form-row align-items-center" v-for="(section, sectionIndex) of availableSections(selectedMenuId)" v-bind:key="sectionIndex">
              <p><b>{{section.name}}</b></p>
              <div class="col-12" v-if="getMenuSectionItems(section.id).length">
                <div v-for="(sectionItem, sectionItemIndex) of getMenuSectionItems(section.id)" v-bind:key="sectionItemIndex">
                  <div class="row" v-if="getMenuItem(sectionItem.menuItemId).length">
                    <div class="col-2">
                      <div class="btn-group" role="group" aria-label="editingMenu">
                        <button type="button" class="btn btn-primary btn-sm" v-on:click="moveMenuSectionItem(sectionItem.id, section.id, -1)" :disabled="sectionItem.displayOrder === 1"><b-icon-chevron-up></b-icon-chevron-up></button>
                        <button type="button" class="btn btn-primary btn-sm" v-on:click="moveMenuSectionItem(sectionItem.id, section.id, 1)" :disabled="sectionItem.displayOrder === getMenuSectionItems(section.id).length"><b-icon-chevron-down></b-icon-chevron-down></button>
                      </div>
                    </div>
                    <div class="col-2">
                      {{getMenuItem(sectionItem.menuItemId)[0].name}}
                    </div>
                    <div class="col-5">
                      {{getMenuItem(sectionItem.menuItemId)[0].description}}
                    </div>
                    <div class="col-1">
                      {{getMenuItem(sectionItem.menuItemId)[0].price}}
                    </div>
                    <div class="col-2 text-right">
                      <button type="button" class="btn btn-sm btn-primary" v-on:click="editItem(getMenuItem(sectionItem.menuItemId)[0])">Edit</button>
                      <button type="button" class="btn btn-sm btn-danger" v-on:click="deleteMenuSectionItem(section.id, sectionItem.menuItemId, getMenuItem(sectionItem.menuItemId)[0].itemId, sectionItem.id)">Delete</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" v-if="isParentSection(section.id).length">
                <div class="form-row align-items-center px-1" v-for="(subSection, subSectionIndex) of isParentSection(section.id)" v-bind:key="subSectionIndex">
                  <p><b>{{getSection(subSection.childSectionId)[0].name}}</b></p>
                  <div class="col-12" v-if="getMenuSectionItems(subSection.childSectionId).length">
                    <div v-for="(subSectionItem, subSectionItemIndex) of getMenuSectionItems(subSection.childSectionId)" v-bind:key="subSectionItemIndex">
                      <div class="row" v-if="getMenuItem(subSectionItem.menuItemId).length">
                        <div class="col-2">
                            <div class="btn-group" role="group" aria-label="editingMenu">
                              <button type="button" class="btn btn-primary btn-sm" v-on:click="moveMenuSectionItem(subSectionItem.id, subSection.childSectionId, -1)" :disabled="subSectionItem.displayOrder === 1"><b-icon-chevron-up></b-icon-chevron-up></button>
                              <button type="button" class="btn btn-primary btn-sm" v-on:click="moveMenuSectionItem(subSectionItem.id, subSection.childSectionId, 1)" :disabled="subSectionItem.displayOrder === getMenuSectionItems(subSection.childSectionId).length"><b-icon-chevron-down></b-icon-chevron-down></button>
                            </div>
                        </div>
                        <div class="col-2">
                          {{getMenuItem(subSectionItem.menuItemId)[0].name}}
                        </div>
                        <div class="col-5">
                          {{getMenuItem(subSectionItem.menuItemId)[0].description}}
                        </div>
                        <div class="col-1">
                          {{getMenuItem(subSectionItem.menuItemId)[0].price}}
                        </div>
                        <div class="col-2 text-right">
                          <button type="button" class="btn btn-sm btn-primary" v-on:click="editItem(getMenuItem(subSectionItem.menuItemId)[0])">Edit</button>
                          <button type="button" class="btn btn-sm btn-danger" v-on:click="deleteMenuSectionItem(getSection(subSection.childSectionId)[0].id, subSectionItem.menuItemId, getMenuItem(subSectionItem.menuItemId)[0].itemId, subSectionItem.id)">Delete</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" v-if="isParentSection(subSection.childSectionId).length">
                    <div class="form-row align-items-center px-2" v-for="(subSubSection, subSubSectionIndex) of isParentSection(subSection.childSectionId)" v-bind:key="subSubSectionIndex">
                      <p><b>{{getSection(subSubSection.childSectionId)[0].name}}</b></p>
                      <div class="col-12" v-if="getMenuSectionItems(subSubSection.childSectionId).length">
                        <div v-for="(subSubSectionItem, subSubSectionItemIndex) of getMenuSectionItems(subSubSection.childSectionId)" v-bind:key="subSubSectionItemIndex">
                          <div class="row" v-if="getMenuItem(subSubSectionItem.menuItemId).length">
                            <div class="col-2">
                                <div class="btn-group" role="group" aria-label="editingMenu">
                                  <button type="button" class="btn btn-primary btn-sm" v-on:click="moveMenuSectionItem(subSubSectionItem.id, subSubSection.childSectionId, -1)" :disabled="subSubSectionItem.displayOrder === 1"><b-icon-chevron-up></b-icon-chevron-up></button>
                                  <button type="button" class="btn btn-primary btn-sm" v-on:click="moveMenuSectionItem(subSubSectionItem.id, subSubSection.childSectionId, 1)" :disabled="subSubSectionItem.displayOrder === getMenuSectionItems(subSubSection.childSectionId).length"><b-icon-chevron-down></b-icon-chevron-down></button>
                                </div>
                            </div>
                            <div class="col-2">
                              {{getMenuItem(subSubSectionItem.menuItemId)[0].name}}
                            </div>
                            <div class="col-5">
                              {{getMenuItem(subSubSectionItem.menuItemId)[0].description}}
                            </div>
                            <div class="col-1">
                              {{getMenuItem(subSubSectionItem.menuItemId)[0].price}}
                            </div>
                            <div class="col-2 text-right">
                              <button type="button" class="btn btn-sm btn-primary" v-on:click="editItem(getMenuItem(subSubSectionItem.menuItemId)[0])">Edit</button>
                              <button type="button" class="btn btn-sm btn-danger" v-on:click="deleteMenuSectionItem(getSection(subSubSection.childSectionId)[0].id, getMenuItem(subSubSectionItem.menuItemId)[0].itemId), subSubSectionItem.id">Delete</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>
        <div class="col-12">
          <p><b>Items (not assigned to any sections)</b></p>
          <div v-for="(menuItem, itemIndex) of getItems()" v-bind:key="itemIndex">
            <div class="row" v-if="!getMenuItemSections(menuItem.id).length">
              <div class="col-2">
              </div>
              <div class="col-2">
                {{menuItem.name}}
              </div>
              <div class="col-5">
                {{menuItem.description}}
              </div>
              <div class="col-1">
                {{menuItem.price}}
              </div>
              <div class="col-2 text-right">
                <button type="button" class="btn btn-sm btn-primary" v-on:click="editItem(menuItem)">Edit</button>
                <button type="button" class="btn btn-sm btn-danger" v-on:click="deleteMenuItem(menuItem.id, menuItem.itemId)">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="itemEditor" tabindex="-1" aria-labelledby="itemEditorLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel" v-if="!editingItem">Create an Item</h5>
            <h5 class="modal-title" id="exampleModalLabel" v-if="editingItem">Edit Item</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-row align-items-center mb-2">
              <div class="col">
                <label>Item Name (required)</label>
                <input type="text" class="form-control" placeholder="Item Name" v-model="activeItem.name">
              </div>
            </div>
            <div class="form-row align-items-center mb-2">
              <div class="col">
                <label>Item Description</label>
                <input type="text" class="form-control" placeholder="Item Description" v-model="activeItem.description">
              </div>
            </div>
            <div class="form-row align-items-center mb-2">
              <div class="col">
                <label>Item Price</label>
                <input type="text" class="form-control" placeholder="Item Price" v-model="activeItem.price">
              </div>
            </div>
            <hr>
            <p><b>Item Menu Assignment</b></p>
            <div class="form-row align-items-center mb-2">
              <div class="col">
                <label>Restaurant Menu (required)</label>
                <select class="form-control" :value="selectedMenuId" @change="restMenuSelectOnChange($event)">>
                  <option value="">-- Select Menu --</option>
                  <option v-for="(menu, index) of restMenuSelect()" v-bind:key="index" :value="menu.id">
                    {{menu.name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row align-items-center mb-2">
              <div class="col">
                <label>Section (optional)</label>
                <select class="form-control" :value="selectedSectionId" @change="restMenuSectionSelectOnChange($event)">>
                  <option value="" v-if="!selectedMenuId">-- Select Menu Above --</option>
                  <option value="" v-if="selectedMenuId">-- No Section --</option>
                  <option v-for="(section, index) of restMenuSectionSelect()" v-bind:key="index" :value="section.id">
                    {{section.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$parent.closeModal('itemEditor')">Close</button>
            <button type="button" class="btn btn-primary" v-on:click="addItem()" :disabled="!selectedMenuId || !activeItem.name" v-if="!editingItem">Add Item</button>
            <button type="button" class="btn btn-primary" v-on:click="saveEditedItem()" :disabled="!selectedMenuId || !activeItem.name" v-if="editingItem">Save Item</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from '../../../node_modules/xlsx/dist/xlsx.full.min.js'
import { mapState } from 'vuex'
export default {
  name: 'menuItems',
  props: {
    componentProps: {
      required: true
    }
  },
  watch: {
  },
  data () {
    return {
      displayImport: false,
      editingItem: false,
      file: null,
      importData: {}
    }
  },
  methods: {
    getItems () {
      if (this.selectedMenuId) {
        return this.restMenuItems
          .filter(m => m.menuId === this.selectedMenuId)
      }
    },
    getMenuItemSections (menuItemId) {
      return this.menuSectionItems
        .filter(m => m.menuItemId === menuItemId)
    },
    getMenuSectionItems (sectionId) {
      return this.menuSectionItems
        .filter(m => m.menuSectionId === sectionId)
        .sort((a, b) => a.displayOrder - b.displayOrder)
    },
    getMenuSectionItem (sectionId, menuItemId) {
      return this.menuSectionItems
        .filter(m => m.menuSectionId === sectionId && m.menuItemId === menuItemId)
        .sort((a, b) => a.displayOrder - b.displayOrder)
    },
    getMenuItem (menuItemId) {
      return this.restMenuItems
        .filter(m => m.id === menuItemId)
    },
    getMenu (menuId) {
      return this.menus
        .filter(m => m.id === menuId)
    },
    getRestaurant (restId) {
      return this.restaurants
        .filter(r => r.id === restId)
    },
    getSection (sectionId) {
      return this.sections
        .filter(s => s.id === sectionId)
    },
    resetActiveItem () {
      const activeItem = {
        id: this.$parent.uuid(),
        name: '',
        description: '',
        price: ''
      }
      this.$parent.updateState({ name: 'activeItem', value: activeItem })
      this.$parent.updateState({ name: 'selectedSectionId', value: '' })
      this.$parent.updateState({ name: 'activeMenuSectionItem', value: {} })
    },
    openItemEditor () {
      this.resetActiveItem()
      this.$parent.openModal('itemEditor')
    },
    addItem () {
      const item = JSON.parse(JSON.stringify(this.activeItem))
      this.items.push(item)
      const menuItemId = this.$parent.uuid()
      const menuId = this.selectedMenuId
      const sectionId = this.selectedSectionId

      // Add Menu_Item
      const addMenuItem = {
        id: menuItemId,
        menuId: menuId,
        itemId: item.id,
        name: item.name,
        description: item.description,
        price: item.price
      }
      this.restMenuItems.push(addMenuItem)

      // Add Menu Section Item
      if (sectionId) {
        const displayOrder = JSON.parse(JSON.stringify(this.getMenuSectionItems(sectionId)))
        const menuSectionItemId = this.$parent.uuid()
        const addMenuSectionItem = {
          id: menuSectionItemId,
          menuItemId: menuItemId,
          menuSectionId: sectionId,
          displayOrder: displayOrder.length + 1
        }
        this.menuSectionItems.push(addMenuSectionItem)
      }
      this.$parent.closeModal('itemEditor')
    },
    saveEditedItem () {
      const menuSectionItem = JSON.parse(JSON.stringify(this.activeMenuSectionItem))
      const menuItem = JSON.parse(JSON.stringify(this.activeItem))

      // Move menus if a new menu is selected
      if (this.selectedMenuId !== menuItem.menuId) {
        const data = {
          name: 'menu_item',
          keyName: 'id',
          keyValue: menuItem.id,
          updateKey: 'menuId',
          updateKeyValue: this.selectedMenuId
        }
        this.$parent.updateStateArrayItem(data)
      }

      // Update the Section if one is selected
      if (this.selectedSectionId && Object.keys(menuSectionItem).length !== 0) {
        const displayOrder = JSON.parse(JSON.stringify(this.getMenuSectionItems(this.selectedSectionId)))
        const updatedMenuSectionItem = {
          id: menuSectionItem[0].id,
          menuItemId: this.activeItem.id,
          menuSectionId: this.selectedSectionId,
          displayOrder: displayOrder.length + 1
        }
        const data = {
          name: 'menu_section_item',
          keyName: 'id',
          keyValue: menuSectionItem[0].id,
          updateKey: 'element',
          updateKeyValue: updatedMenuSectionItem
        }
        this.$parent.updateStateArrayItem(data)
        this.updateMenuSectionItemsOrder(this.selectedSectionId)
      }

      // Delete the section if it used to have one
      if (!this.selectedSectionId && Object.keys(menuSectionItem).length !== 0) {
        const menuSectionItemData = {
          name: 'menu_section_item',
          keyName: 'id',
          keyValue: menuSectionItem[0].id
        }
        this.$parent.deleteStateArrayItem(menuSectionItemData)
        this.updateMenuSectionItemsOrder(menuSectionItem[0].menuSectionId)
      }

      // Add the new menu item section
      if (this.selectedSectionId && Object.keys(menuSectionItem).length === 0) {
        const displayOrder = JSON.parse(JSON.stringify(this.getMenuSectionItems(this.selectedSectionId)))
        const menuSectionItemId = this.$parent.uuid()
        const addMenuSectionItem = {
          id: menuSectionItemId,
          menuItemId: this.activeItem.id,
          menuSectionId: this.selectedSectionId,
          displayOrder: displayOrder.length + 1
        }
        this.menuSectionItems.push(addMenuSectionItem)
        this.updateMenuSectionItemsOrder(this.selectedSectionId)
      }
      this.$parent.closeModal('itemEditor')
      this.editingItem = false
    },
    editItem (item) {
      this.$parent.updateState({ name: 'activeItem', value: item })
      this.$parent.updateState({ name: 'activeMenuSectionItem', value: {} })
      this.$parent.updateState({ name: 'selectedSectionId', value: '' })
      const menuItemSections = JSON.parse(JSON.stringify(this.getMenuItemSections(item.id)))
      this.$parent.updateState({ name: 'selectedMenuId', value: item.menuId })
      for (var r = 0; r < menuItemSections.length; r++) {
        const section = JSON.parse(JSON.stringify(this.getSection(menuItemSections[r].menuSectionId)))
        if (section.length) {
          this.$parent.updateState({ name: 'selectedSectionId', value: section[0].id })
          const activeMenuSectionItem = this.getMenuSectionItem(section[0].id, item.id)
          this.$parent.updateState({ name: 'activeMenuSectionItem', value: activeMenuSectionItem })
        }
      }
      this.editingItem = true
      this.$parent.openModal('itemEditor')
    },
    deleteMenuSectionItem (sectionId, menuItemId, itemId, menuSectionItemId) {
      const menuSectionItemData = {
        name: 'menu_section_item',
        keyName: 'id',
        keyValue: menuSectionItemId
      }
      this.$parent.deleteStateArrayItem(menuSectionItemData)
      /* this.deleteMenuItem(menuItemId, itemId) */
      this.updateMenuSectionItemsOrder(sectionId)
    },
    deleteMenuItem (menuItemId, itemId) {
      const menuItemData = {
        name: 'menu_item',
        keyName: 'id',
        keyValue: menuItemId
      }
      this.$parent.deleteStateArrayItem(menuItemData)

      const itemData = {
        name: 'item',
        keyName: 'id',
        keyValue: itemId
      }
      this.$parent.deleteStateArrayItem(itemData)
    },
    showImport () {
      if (this.displayImport) {
        this.displayImport = false
        return
      }
      this.displayImport = true
    },
    restMenuSelect () {
      const selectOptions = []
      const restaurants = JSON.parse(JSON.stringify(this.restaurants))
      const menus = JSON.parse(JSON.stringify(this.menus))
      for (var r = 0; r < restaurants.length; r++) {
        if (menus.length) {
          for (var m = 0; m < menus.length; m++) {
            if (menus[m].restaurantId === restaurants[r].id) {
              const menu = {
                name: restaurants[r].name + ' - ' + menus[m].name + ' - ' + restaurants[r].address,
                id: menus[m].id
              }
              selectOptions.push(menu)
            }
          }
        }
      }
      return selectOptions
    },
    restMenuSelectOnChange (event) {
      this.$parent.updateState({ name: 'selectedSectionId', value: '' })
      this.$parent.updateState({ name: 'selectedMenuId', value: event.target.value })
    },
    restMenuSectionSelect () {
      const sections = []
      if (!this.selectedMenuId) {
        return sections
      }
      const sectionData = JSON.parse(JSON.stringify(this.availableSections(this.selectedMenuId)))
      for (var i = 0; i < sectionData.length; i++) {
        const section = {
          name: sectionData[i].name,
          id: sectionData[i].id
        }
        sections.push(section)
        if (this.isParentSection(sectionData[i].id).length) {
          const subSectionData = JSON.parse(JSON.stringify(this.getSubSections(sectionData[i].id)))
          for (var ss = 0; ss < subSectionData.length; ss++) {
            const section = {
              name: sectionData[i].name + ' > ' + subSectionData[ss].name,
              id: subSectionData[ss].id
            }
            sections.push(section)
            if (this.isParentSection(subSectionData[ss].id).length) {
              const subSubSectionData = JSON.parse(JSON.stringify(this.getSubSections(subSectionData[ss].id)))
              for (var sss = 0; sss < subSubSectionData.length; sss++) {
                const section = {
                  name: sectionData[i].name + ' > ' + subSectionData[ss].name + ' > ' + subSubSectionData[sss].name,
                  id: subSubSectionData[sss].id
                }
                sections.push(section)
              }
            }
          }
        }
      }
      return sections
    },
    sectionTreeName (sectionId) {
      let sectionName
      const sectionData = JSON.parse(JSON.stringify(this.availableSections(this.selectedMenuId)))
      for (var i = 0; i < sectionData.length; i++) {
        sectionName = sectionData[i].name
        if (sectionData[i].id === sectionId) {
          return sectionName
        }
        if (this.isParentSection(sectionData[i].id).length) {
          const subSectionData = JSON.parse(JSON.stringify(this.getSubSections(sectionData[i].id)))
          for (var ss = 0; ss < subSectionData.length; ss++) {
            sectionName = sectionData[i].name + ' > ' + subSectionData[ss].name
            if (subSectionData[ss].id === sectionId) {
              return sectionName
            }
            if (this.isParentSection(subSectionData[ss].id).length) {
              const subSubSectionData = JSON.parse(JSON.stringify(this.getSubSections(subSectionData[ss].id)))
              for (var sss = 0; sss < subSubSectionData.length; sss++) {
                sectionName = sectionData[i].name + ' > ' + subSectionData[ss].name + ' > ' + subSubSectionData[sss].name
              }
            }
          }
        }
      }
      return sectionName
    },
    availableSections (menuId) {
      return this.sections
        .filter(s => s.menuId === menuId && this.isChildSection(s.id).length === 0)
        .sort((a, b) => a.displayOrder - b.displayOrder)
    },
    isChildSection (sectionId) {
      return this.subSections
        .filter(s => s.childSectionId === sectionId)
        .sort((a, b) => a.displayOrder - b.displayOrder)
    },
    isParentSection (sectionId) {
      return this.subSections
        .filter(s => s.parentSectionId === sectionId)
        .sort((a, b) => a.displayOrder - b.displayOrder)
    },
    getSubSections (sectionId) {
      const subSections = this.subSections
        .filter(s => s.parentSectionId === sectionId)
        .sort((a, b) => a.displayOrder - b.displayOrder)
      const childSectionIds = subSections.map(cs => { return cs.childSectionId })
      return this.sections.filter(section => childSectionIds.includes(section.id))
    },
    restMenuSectionSelectOnChange (event) {
      this.activeItem.sectionId = event.target.value
      this.$parent.updateState({ name: 'selectedSectionId', value: event.target.value })
    },
    moveMenuSectionItem (menuSectionItemId, sectionId, delta) {
      const menuSectionItems = JSON.parse(JSON.stringify(this.getMenuSectionItems(sectionId)))
      const sectionLength = menuSectionItems.length
      for (var index = 0; index < sectionLength; index++) {
        if (menuSectionItems[index].id === menuSectionItemId) {
          break
        }
      }
      var newIndex = index + delta
      if (newIndex < 0 || newIndex === sectionLength) return

      var indexes = [index, newIndex].sort((a, b) => a - b)
      menuSectionItems.splice(indexes[0], 2, menuSectionItems[indexes[1]], menuSectionItems[indexes[0]])
      var displayOrder = 1
      for (var sIndex = 0; sIndex < sectionLength; sIndex++) {
        if (menuSectionItems[sIndex] !== undefined) {
          const data = {
            name: 'menu_section_item',
            keyName: 'id',
            keyValue: menuSectionItems[sIndex].id,
            updateKey: 'displayOrder',
            updateKeyValue: displayOrder
          }
          this.$parent.updateStateArrayItem(data)
          displayOrder = displayOrder + 1
        }
      }
    },
    updateMenuSectionItemsOrder (sectionId) {
      const menuSectionItems = JSON.parse(JSON.stringify(this.getMenuSectionItems(sectionId)))
      var displayOrder = 1
      for (var sIndex = 0; sIndex < menuSectionItems.length; sIndex++) {
        if (menuSectionItems[sIndex] !== undefined) {
          const data = {
            name: 'menu_section_item',
            keyName: 'id',
            keyValue: menuSectionItems[sIndex].id,
            updateKey: 'displayOrder',
            updateKeyValue: displayOrder
          }
          this.$parent.updateStateArrayItem(data)
          displayOrder = displayOrder + 1
        }
      }
    },
    onChange (event) {
      let brandId, restId, restGroupId, operatorId, menuId, sectionId, subSectionId, subSubSectionId
      let brandName, restName, restGroupName, operatorName, menuName, sectionName, subSectionName, subSubSectionName
      this.file = event.target.files ? event.target.files[0] : null
      if (this.file) {
        const reader = new FileReader()

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result
          const wb = XLSX.read(bstr, { type: 'binary' })
          /* Get first worksheet */
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]
          /* Convert array of arrays */
          const dataJson = XLSX.utils.sheet_to_json(ws)
          this.importData = dataJson
          this.$parent.updateState({ name: 'importData', value: dataJson })

          // Parse Structure
          if (this.importType === 'structure') {
            for (var i = 0; i < dataJson.length; i++) {
              const row = JSON.parse(JSON.stringify(dataJson[i]))

              // Operator
              if (!operatorId || operatorName !== row.Operator) {
                operatorId = this.$parent.uuid()
                operatorName = row.Operator
                const operator = {
                  id: operatorId,
                  name: operatorName,
                  description: ''
                }
                this.operators.push(operator)
              }

              // Brand
              if (!brandId || brandName !== row.Brand) {
                brandId = this.$parent.uuid()
                brandName = row.Brand
                const brand = {
                  id: brandId,
                  name: brandName,
                  description: ''
                }
                this.brands.push(brand)
              }

              // Rest Group
              if (!restGroupId || restGroupName !== row['Restaurant Group']) {
                restGroupId = this.$parent.uuid()
                restGroupName = row['Restaurant Group']
                const restGroup = {
                  id: restGroupId,
                  brandId: brandId,
                  operatorId: operatorId,
                  name: restGroupName,
                  description: ''
                }
                this.restaurantGroups.push(restGroup)
              }

              // Add Restaurant
              const restaurant = {
                id: this.$parent.uuid(),
                brandId: brandId,
                operatorId: operatorId,
                restaurantGroupId: restGroupId,
                name: row.Restaurant,
                description: row.Description,
                address: row.Address,
                address2: '',
                city: '',
                stateProvince: '',
                postalCode: '',
                country: ''
              }
              this.restaurants.push(restaurant)
            }
            this.updateSetupOptions()
          }

          // Parse Items
          if (this.importType === 'items') {
            var sectionItemDisplayOrder = 1
            var subSectionItemDisplayOrder = 1
            var subSubSectionItemDisplayOrder = 1
            var sectionDisplayOrder = 1
            var subSectionDisplayOrder = 1
            var subSubSectionDisplayOrder = 1
            for (var k = 0; k < dataJson.length; k++) {
              const row = JSON.parse(JSON.stringify(dataJson[k]))

              // Get restId and restGroupId
              if (!restId || !restGroupId || restName !== row.Restaurant) {
                const data = JSON.parse(JSON.stringify(this.restaurants))
                for (var r = 0; r < data.length; r++) {
                  if (data[r].name === row.Restaurant) {
                    restName = data[r].name
                    restId = data[r].id
                    restGroupId = data[r].restaurantGroupId
                  }
                }
              }

              // Add Menu
              if (!menuId || menuName !== row.Menu) {
                menuId = this.$parent.uuid()
                menuName = row.Menu
                const menu = {
                  id: menuId,
                  restaurantId: restId,
                  name: menuName,
                  description: ''
                }
                this.menus.push(menu)
                sectionDisplayOrder = 1
                sectionItemDisplayOrder = 1
                subSectionDisplayOrder = 1
                subSectionItemDisplayOrder = 1
                subSubSectionDisplayOrder = 1
                subSubSectionItemDisplayOrder = 1
              }

              // Add Item
              const itemId = this.$parent.uuid()
              const item = {
                id: itemId,
                restaurantGroupId: restGroupId,
                name: row.Item,
                description: row.Description,
                price: row.Price
              }
              this.items.push(item)

              // Add Menu Item
              const menuItemId = this.$parent.uuid()
              const menuItem = {
                id: menuItemId,
                menuId: menuId,
                itemId: itemId,
                name: row.Item,
                description: row.Description,
                price: row.Price
              }
              this.restMenuItems.push(menuItem)

              // Add sections
              if ('Section' in row) {
                // Top Level Section
                if (!sectionId || sectionName !== row.Section.trim()) {
                  subSectionDisplayOrder = 1
                  sectionItemDisplayOrder = 1
                  sectionId = this.$parent.uuid()
                  sectionName = row.Section.trim()
                  const section = {
                    id: sectionId,
                    menuId: menuId,
                    name: sectionName,
                    description: 'Section Description',
                    displayOrder: sectionDisplayOrder
                  }
                  this.sections.push(section)
                  sectionDisplayOrder = sectionDisplayOrder + 1
                }
                // Sub Level Section
                if ('Subsection' in row) {
                  if (!subSectionId || subSectionName !== row.Subsection.trim()) {
                    subSectionItemDisplayOrder = 1
                    subSubSectionDisplayOrder = 1
                    subSectionId = this.$parent.uuid()
                    subSectionName = row.Subsection.trim()
                    const subSection = {
                      id: subSectionId,
                      menuId: menuId,
                      name: subSectionName,
                      description: 'Sub Section Description',
                      displayOrder: subSectionDisplayOrder
                    }
                    this.sections.push(subSection)
                    // Add Sub Section
                    const addSubSection = {
                      id: this.$parent.uuid(),
                      parentSectionId: sectionId,
                      childSectionId: subSectionId,
                      levelNbr: 1,
                      displayOrder: subSectionDisplayOrder
                    }
                    this.subSections.push(addSubSection)
                    subSectionDisplayOrder = subSectionDisplayOrder + 1
                  }
                  // Sub Sub Level Section
                  if ('Subsection 2' in row) {
                    if (!subSubSectionId || subSubSectionName !== row['Subsection 2']) {
                      subSubSectionItemDisplayOrder = 1
                      subSubSectionId = this.$parent.uuid()
                      subSubSectionName = row['Subsection 2'].trim()
                      const subSubSection = {
                        id: subSubSectionId,
                        menuId: menuId,
                        name: subSubSectionName,
                        description: 'Sub Sub Section Description',
                        displayOrder: subSubSectionDisplayOrder
                      }
                      this.sections.push(subSubSection)

                      // Add Sub Section
                      const addSubSubSection = {
                        id: this.$parent.uuid(),
                        parentSectionId: subSectionId,
                        childSectionId: subSubSectionId,
                        levelNbr: 2,
                        displayOrder: subSubSectionDisplayOrder
                      }
                      this.subSections.push(addSubSubSection)
                      subSubSectionDisplayOrder = subSubSectionDisplayOrder + 1
                    }
                  }
                }
              }

              // Add Menu Section Items
              if (subSubSectionId && 'Subsection 2' in row) {
                const menuSectionItemId = this.$parent.uuid()
                const item = {
                  id: menuSectionItemId,
                  menuItemId: menuItemId,
                  menuSectionId: subSubSectionId,
                  displayOrder: subSubSectionItemDisplayOrder
                }
                this.menuSectionItems.push(item)
                subSubSectionItemDisplayOrder = subSubSectionItemDisplayOrder + 1
                continue
              }
              if (subSectionId && 'Subsection' in row) {
                // Add Menu Section Item
                const menuSectionItemId = this.$parent.uuid()
                const item = {
                  id: menuSectionItemId,
                  menuItemId: menuItemId,
                  menuSectionId: subSectionId,
                  displayOrder: subSectionItemDisplayOrder
                }
                this.menuSectionItems.push(item)
                subSectionItemDisplayOrder = subSectionItemDisplayOrder + 1
                continue
              }
              if (sectionId && 'Section' in row) {
                // Add Menu Section Item
                const menuSectionItemId = this.$parent.uuid()
                const item = {
                  id: menuSectionItemId,
                  menuItemId: menuItemId,
                  menuSectionId: sectionId,
                  displayOrder: sectionItemDisplayOrder
                }
                this.menuSectionItems.push(item)
                sectionItemDisplayOrder = sectionItemDisplayOrder + 1
                continue
              }
            }
          }
        }
        reader.readAsBinaryString(this.file)
      }
    },
    addMenuSectionItem (menuItemId, menuSectionId) {
      const id = this.$parent.uuid()
      const item = {
        id: id,
        menuItemId: menuItemId,
        menuSectionId: menuSectionId,
        displayOrder: 1
      }
      this.menuSectionItems.push(item)
      return id
    },
    updateSetupOptions () {
      const brandData = JSON.parse(JSON.stringify(this.brands))
      if (brandData.length > 1) {
        this.$parent.updateState({ name: 'brandsOperated', value: '2' })
      } else {
        this.$parent.updateState({ name: 'brandsOperated', value: '1' })
      }
      const restData = JSON.parse(JSON.stringify(this.restaurants))
      if (restData.length > 1) {
        this.$parent.updateState({ name: 'restaurantsOperated', value: '2' })
      } else {
        this.$parent.updateState({ name: 'restaurantsOperated', value: '1' })
      }
    }
  },
  computed: {
    ...mapState({
      importDataStore: state => state.menussetupv2.importData,
      importType: state => state.menussetupv2.importType,
      restaurants: state => state.menussetupv2.restaurant,
      brands: state => state.menussetupv2.brand,
      operators: state => state.menussetupv2.operator,
      menus: state => state.menussetupv2.menu,
      restMenuItems: state => state.menussetupv2.menu_item,
      menuSectionItems: state => state.menussetupv2.menu_section_item,
      sections: state => state.menussetupv2.menu_section,
      subSections: state => state.menussetupv2.menu_subsection,
      restaurantGroups: state => state.menussetupv2.restaurant_group,
      restaurantsOperated: state => state.menussetupv2.restaurantsOperated,
      selectedRestaurantId: state => state.menussetupv2.selectedRestaurantId,
      selectedMenuId: state => state.menussetupv2.selectedMenuId,
      selectedSectionId: state => state.menussetupv2.selectedSectionId,
      items: state => state.menussetupv2.item,
      activeItem: state => state.menussetupv2.activeItem,
      activeMenuSectionItem: state => state.menussetupv2.activeMenuSectionItem
    })
  }
}
</script>
