<template>
    <div class="page properties-page">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <PropertyTypes/>
          </div>
          <div v-if="editValues" class="col-md-6 col-sm-12">
            <PropertyValues :key="editValues" :editpropertytypevalues="editValues" />
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import PropertyTypes from './child-components/PropertyTypes'
import PropertyValues from './child-components/PropertyValues'

export default {
  name: 'Properties',
  data () {
    return {
      editValues: ''
    }
  },
  components: {
    PropertyTypes,
    PropertyValues
  },
  computed: {
    ...mapState({
      managetypevalues: state => state.properties.managetypevalues
    })
  },
  watch: {
    managetypevalues: {
      handler (val, oldVal) {
        if (val) {
          this.editValues = val
          return
        }
        this.editValues = ''
      }
    }
  }
}
</script>
