<template>
    <div class="page rest-sales-taxe-rates-page">
      <h6 class="page-title sub-title">Restaurant Tax Rates <button v-on:click="addTaxRateButton" class="btn btn-sm btn-primary">Add Restaurant Tax Rate</button></h6>
      <div class="menu-form" v-if="taxrateform">
          <p>{{formtitle}}</p>
          <div class="container-fluid">
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <p>Available Restaurant Group Tax Rates.
                        <br />Don't see your tax rate? <router-link class=""  :to="{ name: 'managerestgroupcomponent', params: {id: restGroupId, componentName: 'SalesTaxRates' } }">Add One to your Restaurant Group</router-link></p>
                      <v-select placeholder="Search..." :options="salestaxrates" @input="setSalesTaxRate" v-model="salesTaxRateName"></v-select>
                      <div class="input-error" v-if="msg.errors.salesTaxRateId">
                          Valid Sales Tax Rate is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="isDefault" placeholder="" v-model="isDefault" :checked="isDefault == 1">
                      <label class="form-check-label" for="isDefault">
                        Is Default?
                      </label>
                    </div>
                  </div>
              </div>
              <div class="row" v-if="editingresttaxrate">
                <div class="col-sm-12 col-md-6 mb-3 deletecontrol text-right">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="deleteRestTaxRate" id="deleteRestTaxRate">
                    <label class="form-check-label" for="deleteRestTaxRate">
                      Delete this Sales Tax Rate?
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3 text-left form-controls">
                      <button v-on:click="saveTaxRateFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                      <button v-on:click="closeTaxRateFormButton" class="btn btn-sm btn-danger">Close</button>
                  </div>
              </div>
          </div>
      </div>
      <div v-if="'taxratedata' in restsalestaxrates" class="tax-rate-list">
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Sales Tax Rate</th>
                <th scope="col">Is Default</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>
                  <tr v-for="salesTaxRate in restsalestaxrates.taxratedata" :key="salesTaxRate.restaurant_sales_tax_id">
                    <td>{{salesTaxRate.restaurant_sales_tax_id}}</td>
                    <td>
                      <ul>
                        <li><span class="label">Name:</span> {{salesTaxRate.name}}</li>
                        <li><span class="label">Description:</span> {{salesTaxRate.description}}</li>
                        <li><span class="label">Tax Rate:</span> {{salesTaxRate.tax_rate}}</li>
                        <li><span class="label">City:</span> {{salesTaxRate.city}}</li>
                        <li><span class="label">Country:</span> {{salesTaxRate.country}}</li>
                        <li><span class="label">Province:</span> {{salesTaxRate.province}}</li>
                        <li><span class="label">Postal Code:</span> {{salesTaxRate.postal_code}}</li>
                        <li><span class="label">Country:</span> {{salesTaxRate.country}}</li>
                        <li><span class="label">Association:</span> {{salesTaxRate.association}}</li>
                      </ul>
                    </td>
                    <td>{{salesTaxRate.is_default}}</td>
                    <td>
                      <button v-on:click="editRestSalesTaxRate(salesTaxRate.restaurant_sales_tax_id)" class="btn btn-sm btn-primary">Edit</button>
                    </td>
                  </tr>
            </tbody>
          </table>
      </div>
    </div>
</template>
<style lang="scss" scoped>
  .tax-rate-list {
    .label {
      font-weight: bold;
    }
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'SalesTaxRates',
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
      restGroupId: '',
      restId: '',
      formtitle: 'Add New Tax Rate',
      formsavetext: 'Save',
      taxrateform: false,
      restSalesTaxRateId: '',
      restSalesTaxRate: '',
      salesTaxRateId: '',
      salesTaxRateName: '',
      association: '',
      isDefault: 0,
      editingresttaxrate: '',
      deleteRestTaxRate: false,
      msg: {
        errors: {
          salesTaxRateId: false
        }
      }
    }
  },
  computed: {
    ...mapState({
      activerestaurant: state => state.restaurants.activerestaurant,
      activerestgroup: state => state.restgroups.activerestgroup,
      activeresttaxrate: state => state.restaurants.activeresttaxrate,
      addresttaxrate: state => state.restaurants.addresttaxrate,
      updateresttaxrate: state => state.restaurants.updateresttaxrate,
      restsalestaxrates: state => state.restaurants.restsalestaxrates,
      salestaxrates: state => state.salestaxrates.idsNamesSelect
    })
  },
  created () {
    const { restGroupId, restId } = this.componentProps
    this.restGroupId = restGroupId
    this.restId = restId
    this.getRestSalesTaxRates({ restGroupId, restId })
    this.getSalesTaxRates(restGroupId)
  },
  watch: {
    activeresttaxrate: {
      handler (val, oldVal) {
        this.restSalesTaxRateId = val.restaurant_sales_tax_id
        this.isDefault = val.is_default
        this.association = val.association
        this.restSalesTaxRate = val.tax_rate
        this.editingresttaxrate = val.restaurant_sales_tax_id
        this.salesTaxRateId = val.sales_tax_rate_id
        this.salesTaxRateName = val.tax_rate + ' (' + val.name + ')'
      }
    },
    salesTaxRateId: {
      handler (val, oldVal) {
        this.validatTaxRateForm()
      }
    },
    addresttaxrate: {
      handler (val, oldVal) {
        if (val.restaurant_sales_tax_id) {
          const { restGroupId, restId } = this
          this.getRestSalesTaxRates({ restGroupId, restId })
          this.taxRateFormReset()
          this.closeTaxRateFormButton()
        }
      }
    },
    updateresttaxrate: {
      handler (val, oldVal) {
        if (val.restaurant_sales_tax_id) {
          const { restGroupId, restId } = this
          this.getRestSalesTaxRates({ restGroupId, restId })
          this.taxRateFormReset()
          this.closeTaxRateFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('restgroups', {
      getRestGroup: 'getById'
    }),
    ...mapActions('restaurants', {
      getRestaurant: 'getById',
      getRestSalesTaxRates: 'getRestSalesTaxRates',
      getRestSalesTaxRate: 'getRestSalesTaxRate',
      updateRestSalesTaxRate: 'updateRestSalesTaxRate',
      addRestSalesTaxRate: 'addRestSalesTaxRate'
    }),
    ...mapActions('salestaxrates', {
      getSalesTaxRates: 'getAll'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    taxRateFormReset (event) {
      this.formtitle = 'Add new Sales Tax Rate'
      this.restSalesTaxRateId = ''
      this.association = ''
      this.isDefault = 0
      this.editingresttaxrate = ''
      this.deleteRestTaxRate = false
    },
    closeTaxRateFormButton (event) {
      this.taxrateform = false
    },
    addTaxRateButton (event) {
      this.clearAlert()
      this.taxRateFormReset()
      if (this.taxrateform) {
        this.closeTaxRateFormButton()
        return
      }
      this.taxrateform = true
    },
    editRestSalesTaxRate (id) {
      this.clearAlert()
      this.getRestSalesTaxRate(id)
      this.editingresttaxrate = id
      this.taxrateform = true
      this.formtitle = 'Edit Restaurant Sales Tax Rate'
    },
    validatTaxRateForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    setSalesTaxRate (value) {
      if (value !== null) {
        this.salesTaxRateId = value.code
        this.association = this.activerestaurant.name + '-' + value.label
        return
      }
      this.salesTaxRateId = ''
      this.association = ''
    },
    saveTaxRateFormButton (event) {
      this.clearAlert()
      if (this.validatTaxRateForm() === false) {
        return
      }

      if (this.editingresttaxrate) {
        const {
          editingresttaxrate,
          restId,
          salesTaxRateId,
          isDefault,
          association,
          deleteRestTaxRate
        } = this
        this.updateRestSalesTaxRate({
          editingresttaxrate,
          restId,
          salesTaxRateId,
          isDefault,
          association,
          deleteRestTaxRate
        })
      } else {
        const {
          association,
          restId,
          salesTaxRateId,
          isDefault
        } = this
        this.addRestSalesTaxRate({
          association,
          restId,
          salesTaxRateId,
          isDefault
        })
      }
    }
  }
}
</script>
