import { authHeader, apiUrl, handleResponse } from '../_helpers'

export const unitsOfMeasureService = {
  getAll,
  getTypes,
  getById,
  add,
  update
}

function getAll (restGroupId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'uom',
      data: {
        action: 'getAll',
        restGroupId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getTypes (restGroupId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'uom',
      data: {
        action: 'getUnitTypes',
        restGroupId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'uom',
      data: {
        action: 'getUnitOfMeasure',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function add (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'uom',
      data: {
        action: 'addUnitOfMeasure',
        restGroupId: data.restGroupId,
        unitTypeId: data.unitTypeId,
        name: data.name,
        description: data.description
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function update (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'uom',
      data: {
        action: 'updateUnitOfMeasure',
        unitId: data.editingunitofmeasure,
        restGroupId: data.restGroupId,
        unitTypeId: data.unitTypeId,
        name: data.name,
        description: data.description,
        deleteUnitOfMeasure: data.deleteUnitOfMeasure
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}
