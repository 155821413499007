import { authHeader, apiUrl, handleResponse } from '../_helpers'

export const brandsService = {
  getAll,
  getById,
  add,
  update
}

function getAll () {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'brand',
      data: {
        action: 'getAll'
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'brand',
      data: {
        action: 'getBrand',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function add (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'brand',
      data: {
        action: 'addBrand',
        name: data.brandname,
        description: data.branddescription
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function update (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'brand',
      data: {
        action: 'updateBrand',
        brand_id: data.editingbrand,
        name: data.brandname,
        description: data.branddescription,
        deletebrand: data.deletebrand
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}
