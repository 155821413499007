export function multipleRestaurants () {
  const data = {
    brand: {
      name: "Mario and Maria's Restaurants",
      description: 'MM Brand Description.'
    },
    restaurant_group: {},
    restaurant: [
      {
        restId: 1,
        name: 'Taco Restaurant',
        description: 'The Best Tacos',
        address: '123 Test St.',
        address2: 'Suite #2',
        city: 'Chico',
        stateProvince: 'CA',
        postalCode: '95928',
        country: 'United States',
        taxRate: '7.4',
        type: 'single'
      },
      {
        restId: 2,
        name: "Mario's Pasta Palace",
        description: 'The best pasta in the mushroom kingdom.',
        address: '123 Luigi St.',
        address2: 'Bldg. A012',
        city: 'Castle',
        stateProvince: 'ML',
        postalCode: '123456',
        country: 'United States',
        taxRate: '8.5',
        type: 'multiple'
      }
    ],
    activeRestaurant: 2,
    menu: [
      {
        menuId: 1,
        restId: 1,
        name: 'Daily Menu',
        description: 'Our daily menu offerings.'
      },
      {
        menuId: 2,
        restId: 2,
        name: 'Dinner',
        description: 'Our Best Pasta dinners.'
      }
    ],
    activeMenu: 0,
    menu_section: [
      {
        menuSectionId: 1,
        menuId: 1,
        name: 'Tacos',
        description: 'Daily Taco Offerings',
        display_order: ''
      },
      {
        menuSectionId: 2,
        menuId: 1,
        name: 'Burritos',
        description: 'Daily Burrito Offerings',
        display_order: ''
      },
      {
        menuSectionId: 3,
        menuId: 1,
        name: 'Deserts',
        description: 'Daily Desserts',
        display_order: ''
      },
      {
        menuSectionId: 4,
        menuId: 2,
        name: 'Pasta',
        description: 'Our best pasta.',
        display_order: ''
      },
      {
        menuSectionId: 5,
        menuId: 2,
        name: 'Pizza',
        description: 'Our best Pizza',
        display_order: ''
      },
      {
        menuSectionId: 6,
        menuId: 2,
        name: 'Wine',
        description: 'Our best wine.',
        display_order: ''
      },
      {
        menuSectionId: 7,
        menuId: 2,
        name: 'Desert',
        description: 'Our best deserts.',
        display_order: ''
      }
    ],
    item: [
      {
        itemId: 1,
        name: 'Chicken Taco',
        description: 'The chicken taco.',
        price: '5.99',
        menuId: 1,
        menuSectionId: 1,
        display_order: '',
        image: false
      },
      {
        itemId: 2,
        name: 'Beef Taco',
        description: 'Our beef taco.',
        price: '6.99',
        menuId: 1,
        menuSectionId: 1,
        display_order: '',
        image: false
      },
      {
        itemId: 3,
        name: 'Chicken Burrito',
        description: 'Our chicken burrito.',
        price: '8.99',
        menuId: 1,
        menuSectionId: 2,
        display_order: '',
        image: false
      },
      {
        itemId: 4,
        name: 'Fried Ice Cream',
        description: 'Our fried ice cream.',
        price: '3.00',
        menuId: 1,
        menuSectionId: 3,
        display_order: '',
        image: false
      },
      {
        itemId: 5,
        name: 'Spaghetti ',
        description: '',
        price: '8.00',
        menuId: 2,
        menuSectionId: 4,
        display_order: '',
        image: false
      },
      {
        itemId: 6,
        name: 'Linguine ',
        description: '',
        price: '9.00',
        menuId: 2,
        menuSectionId: 4,
        display_order: '',
        image: false
      },
      {
        itemId: 7,
        name: 'Small 10"',
        description: '',
        price: '15.00',
        menuId: 2,
        menuSectionId: 5,
        display_order: '',
        image: false
      },
      {
        itemId: 8,
        name: 'Medium 16"',
        description: '',
        price: '20.00',
        menuId: 2,
        menuSectionId: 5,
        display_order: '',
        image: false
      },
      {
        itemId: 9,
        name: 'Pinot',
        description: '',
        price: '$6/glass $20/bottle',
        menuId: 2,
        menuSectionId: 6,
        display_order: '',
        image: false
      },
      {
        itemId: 10,
        name: 'Tiramisu',
        description: '',
        price: '6.00',
        menuId: 2,
        menuSectionId: 7,
        display_order: '',
        image: false
      }
    ],
    modifier_set: [
      {
        modifierSetId: 1,
        name: 'Burrito Addons',
        description: 'Our Burrito Addons'
      },
      {
        modifierSetId: 2,
        name: 'Taco Addons',
        description: 'Our Taco Addons'
      },
      {
        modifierSetId: 3,
        name: 'Pizza Toppings',
        description: 'Pizza toppings to add'
      },
      {
        modifierSetId: 4,
        name: 'Pasta Sauce',
        description: 'Choose your past sauce.'
      }
    ],
    activeModifierSet: 0,
    modifier: [
      {
        modifierId: 1,
        modifierSetId: 1,
        name: 'Extra Meat',
        description: ''
      },
      {
        modifierId: 2,
        modifierSetId: 1,
        name: 'Add Cheese',
        description: ''
      },
      {
        modifierId: 3,
        modifierSetId: 1,
        name: 'Add Guacamole',
        description: ''
      },
      {
        modifierId: 4,
        modifierSetId: 2,
        name: 'Extra Meat',
        description: ''
      },
      {
        modifierId: 5,
        modifierSetId: 2,
        name: 'Extra Cheese',
        description: ''
      },
      {
        modifierId: 6,
        modifierSetId: 2,
        name: 'No lettuce',
        description: ''
      },
      {
        modifierId: 7,
        modifierSetId: 3,
        name: 'Sausage',
        description: ''
      },
      {
        modifierId: 8,
        modifierSetId: 3,
        name: 'Extra Cheese',
        description: ''
      },
      {
        modifierId: 9,
        modifierSetId: 3,
        name: 'Pepperoni',
        description: ''
      },
      {
        modifierId: 10,
        modifierSetId: 4,
        name: 'Meat',
        description: ''
      },
      {
        modifierId: 11,
        modifierSetId: 4,
        name: 'Alfredo',
        description: ''
      },
      {
        modifierId: 12,
        modifierSetId: 4,
        name: 'Pesto',
        description: ''
      }
    ],
    restOperationType: 'multiple',
    display: {
      restOperationType: false,
      brandInformation: false,
      restInfo: true
    },
    importTestData: false
  }
  return data
}
