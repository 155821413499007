import { authHeader, apiUrl, handleResponse } from '../_helpers'

export const propertyService = {
  getAllTypes,
  getTypeById,
  addType,
  updateType,
  getAllTypeValues,
  getValueById,
  addValue,
  updateValue
}

function getAllTypes (restGroupId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'property',
      data: {
        action: 'getAllTypes',
        restGroupId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getTypeById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'property',
      data: {
        action: 'getPropertyType',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function addType (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'property',
      data: {
        action: 'addPropertyType',
        restGroupId: data.restgroupid,
        name: data.name,
        description: data.description
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function updateType (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'property',
      data: {
        action: 'updatePropertyType',
        property_type_id: data.editingpropertytype,
        restGroupId: data.restgroupid,
        name: data.name,
        description: data.description,
        deletePropertyType: data.deletepropertytype
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getAllTypeValues (propertyTypeId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'property',
      data: {
        action: 'getAllPropertyValues',
        propertyTypeId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getValueById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'property',
      data: {
        action: 'getPropertyValue',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function addValue (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'property',
      data: {
        action: 'addPropertyValue',
        propertyTypeId: data.editpropertytypevalues,
        property: data.property,
        description: data.description
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function updateValue (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'property',
      data: {
        action: 'updatePropertyValue',
        propertyValueId: data.editingpropertytypevalue,
        propertyTypeId: data.editpropertytypevalues,
        property: data.property,
        description: data.description,
        deletePropertyValue: data.deletepropertytypevalue
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}
