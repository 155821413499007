<template>
  <div class="component-wrapper">
    <div class="container-fluid mb-sm-5">
      <div class="row" v-if="activeBrand">
        <div class="col-12">
          <div class="container mt-sm-5 my-1 bg-light">
              <div class="question ml-sm-5 pt-2">
                <div class="py-2 h5">
                  Brand Manager <b>{{activeBrand.name}}</b>
                </div>
                <hr>
                <div class="form-row align-items-center">
                    <div class="col-12">
                        <label for="inlineFormInput">Name</label>
                        <input type="text" class="form-control mb-2" placeholder="Brand Name (required)" v-model="activeBrand.name">
                    </div>
                    <div class="col-12">
                      <hr>
                      <p><b>Restaurants Operated </b> <button type="submit" class="btn btn-sm btn-primary mb-2" :disabled="activeBrand.name === ''" v-on:click="addRestaurants(activeBrand.id)">Add New Restaurant</button></p>
                    </div>
                    <div class="col-12" v-if="brandRestaurants(activeBrand.id).length">
                      <div class="form-row align-items-center" v-for="(restaurant, restIndex) of brandRestaurants(activeBrand.id)" v-bind:key="restIndex">
                        <div class="col-6">{{restIndex +1}}. {{restaurant.name}}</div>
                        <div class="col-auto">
                          <div class="btn-group" role="group" aria-label="Restaurant Functions">
                            <button type="submit" class="btn btn-sm btn-primary mb-2" v-on:click="editRestaurant(restaurant)">Edit Details</button>
                            <button type="submit" class="btn btn-sm btn-primary mb-2" v-on:click="addMenus(restaurant.id)">Add Menus</button>
                            <button type="submit" class="btn btn-sm btn-danger mb-2" v-on:click="deleteRestaurant(restaurant.id)">Delete</button>
                          </div>
                        </div>
                        <div class="col-12">
                          <hr>
                        </div>
                      </div>
                    </div>
                    <div class="col-12" v-else>
                      No restaurants currently created.
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
           <button type="submit" class="btn btn-sm btn-primary" v-on:click="$parent.restaurantAdminComponent()"><b-icon-chevron-left></b-icon-chevron-left> Back to Restaurant Admin</button>
        </div>
        <div class="col-6 text-right">
          <button type="submit" class="btn btn-sm btn-danger" v-on:click="deleteBrand(activeBrand.id)">Delete Brand</button>
        </div>
      </div>
    </div>
    <div class="modal fade" id="addRestaurant" tabindex="-1" aria-labelledby="createRestaurantLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Restaurant</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Please specify information about your restaurant (only a name is required).</p>
            <div class="form-group">
              <label for="restName">Name</label>
              <input type="text" class="form-control" placeholder="Name" v-model="activeRestaurant.name">
            </div>
            <div class="form-group">
              <label for="restDescription">Description*</label>
              <input type="text" class="form-control" placeholder="Description" v-model="activeRestaurant.description">
            </div>
            <div class="form-group">
              <label for="restAddress">Address*</label>
              <input type="text" class="form-control" placeholder="Address" v-model="activeRestaurant.address">
            </div>
            <div class="form-group">
              <label for="restAddress2">Address 2*</label>
              <input type="text" class="form-control" placeholder="Address 2" v-model="activeRestaurant.address2">
            </div>
            <div class="form-group">
              <label for="restCity">City*</label>
              <input type="text" class="form-control" placeholder="City" v-model="activeRestaurant.city">
            </div>
            <div class="form-group">
              <label for="restProvince">State or Province*</label>
              <input type="text" class="form-control" placeholder="State or Province" v-model="activeRestaurant.stateProvince">
            </div>
            <div class="form-group">
              <label for="restPostalCode">Postal Code*</label>
              <input type="text" class="form-control" placeholder="Postal Code" v-model="activeRestaurant.postalCode">
            </div>
            <div class="form-group">
              <label for="restCountry">Country*</label>
              <input type="text" class="form-control" placeholder="Country" v-model="activeRestaurant.country">
            </div>
            <p class="text-right">* Optional</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-on:click="$parent.closeModal('addRestaurant')">Close</button>
            <button type="button" class="btn btn-primary" v-on:click="saveRestaurant()" :disabled="activeRestaurant.name === ''" >Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'restaurantBrand',
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
    }
  },
  watch: {},
  methods: {
    addMenus (restId) {
      this.$parent.menuStructureComponent(restId)
      this.$parent.updateState({ name: 'selectedRestaurantId', value: restId })
    },
    setActiveBrand (brandId) {
      const activeBrand = this.brands.filter(function (brand) {
        if (brand.id === brandId) {
          return brand
        }
      })
      if (activeBrand.length) {
        this.$parent.updateState({ name: 'activeBrand', value: activeBrand[0] })
      }
    },
    brandRestaurants (brandId) {
      return this.restaurants.filter(function (restaurant) {
        if (restaurant.brandId === brandId) {
          return restaurant
        }
      })
    },
    deleteBrand (brandId) {
      const data = {
        name: 'brand',
        keyName: 'id',
        keyValue: brandId
      }
      this.$parent.deleteStateArrayItem(data)
      const restData = {
        name: 'restaurant',
        keyName: 'brandId',
        keyValue: brandId
      }
      this.$parent.deleteStateArrayItem(restData)
      this.$parent.restaurantAdminComponent()
    },
    restDataInit (brandId) {
      const restInit = {
        id: this.$parent.uuid(),
        brandId: brandId,
        name: '',
        description: '',
        address: '',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: ''
      }
      this.$parent.updateState({ name: 'selectedRestaurantId', value: restInit.id })
      this.$parent.updateState({ name: 'activeRestaurant', value: restInit })
    },
    deleteRestaurant (restId) {
      const data = {
        name: 'restaurant',
        keyName: 'id',
        keyValue: restId
      }
      this.$parent.deleteStateArrayItem(data)
    },
    addRestaurants (brandName) {
      this.restDataInit(brandName)
      this.$parent.openModal('addRestaurant')
    },
    editRestaurant (restaurant) {
      this.$parent.updateState({ name: 'activeRestaurant', value: restaurant })
      this.$parent.updateState({ name: 'editingRestaurant', value: true })
      this.$parent.openModal('addRestaurant')
    },
    saveRestaurant () {
      if (this.editingRestaurant) {
        this.$parent.updateState({ name: 'editingRestaurant', value: false })
        this.$parent.closeModal('addRestaurant')
        return
      }
      const restaurant = JSON.parse(JSON.stringify(this.activeRestaurant))
      this.restaurants.unshift(restaurant)
      this.$parent.closeModal('addRestaurant')
    }
  },
  created () {
    this.setActiveBrand(this.componentProps.brandId)
  },
  computed: {
    ...mapState({
      restaurants: state => state.menussetupv2.restaurant,
      restaurantsOperated: state => state.menussetupv2.restaurantsOperated,
      holdingCompany: state => state.menussetupv2.holdingCompany,
      operators: state => state.menussetupv2.operator,
      brandsOperated: state => state.menussetupv2.brandsOperated,
      brands: state => state.menussetupv2.brand,
      activeRestaurant: state => state.menussetupv2.activeRestaurant,
      editingRestaurant: state => state.menussetupv2.editingRestaurant,
      activeBrand: state => state.menussetupv2.activeBrand
    })
  }
}
</script>
