<template>
    <div class="page addendums-page">
      <h6 class="page-title">Addemdums <button v-on:click="addAddendumButton" class="btn btn-sm btn-primary">Add +</button></h6>
      <div class="addendum-form" v-if="addendumform">
          <p>{{formtitle}}</p>
          <div class="container-fluid">
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="label">Label</label>
                      <input type="text" class="form-control" id="label" placeholder="" v-model="label">
                      <div class="input-error" v-if="msg.errors.label">
                          Valid label is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="lineText">Line Text</label>
                      <input type="text" class="form-control" id="lineText" placeholder="" v-model="lineText">
                  </div>
              </div>
              <div class="row" v-if="editingaddendum">
                <div class="col-sm-12 col-md-6 mb-3 deletecontrol text-right">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="deleteAddendum" id="deleteAddendum">
                    <label class="form-check-label" for="deleteAddendum">
                      Delete this Addendum?
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3 text-left form-controls">
                      <button v-on:click="saveAddendumFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                      <button v-on:click="closeAddendumFormButton" class="btn btn-sm btn-danger">Close</button>
                  </div>
              </div>
          </div>
      </div>
      <div v-if="'addendums' in groupaddendums" class="addendums-list">
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Label</th>
                <th scope="col">Line Text</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>
                  <tr v-for="addendum in groupaddendums.addendums" :key="addendum.addendum_id">
                    <td>{{addendum.addendum_id}}</td>
                    <td>{{addendum.label}}</td>
                    <td>{{addendum.line_text}}</td>
                    <td>
                      <button v-on:click="editAddendumButton(addendum.addendum_id)" class="btn btn-sm btn-primary">Edit</button>
                    </td>
                  </tr>
            </tbody>
          </table>
      </div>
      <div v-else>
        No addendums available.
      </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  label: 'Addendum',
  data () {
    return {
      formtitle: 'Add new Addendum',
      formsavetext: 'Save',
      addendumform: false,
      restGroupId: this.$route.params.id,
      label: '',
      lineText: '',
      editingaddendum: '',
      deleteAddendum: false,
      msg: {
        errors: {
          taxRate: false,
          label: false
        }
      }
    }
  },
  computed: {
    ...mapState({
      groupaddendums: state => state.addendums.all,
      activeaddendum: state => state.addendums.activeaddendum,
      addaddendum: state => state.addendums.addaddendum,
      updateaddendum: state => state.addendums.updateaddendum
    })
  },
  created () {
    this.getAddendums(this.restGroupId)
  },
  watch: {
    activeaddendum: {
      handler (val, oldVal) {
        this.label = val.label
        this.lineText = val.line_text
        this.editingaddendum = val.addendum_id
      }
    },
    label: {
      handler (val, oldVal) {
        this.validateAddendumForm()
      }
    },
    addaddendum: {
      handler (val, oldVal) {
        if (val.addendum_id) {
          this.getAddendums(this.restGroupId)
          this.AddendumFormReset()
          this.closeAddendumFormButton()
        }
      }
    },
    updateaddendum: {
      handler (val, oldVal) {
        if (val.addendum_id) {
          this.getAddendums(this.restGroupId)
          this.AddendumFormReset()
          this.closeAddendumFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('addendums', {
      getAddendums: 'getAll',
      getAddendum: 'getById',
      updateAddendum: 'update',
      addAddendum: 'add'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    AddendumFormReset (event) {
      this.formtitle = 'Add new Addendum'
      this.label = ''
      this.lineText = ''
      this.editingaddendum = ''
      this.deleteAddendum = false
    },
    closeAddendumFormButton (event) {
      this.addendumform = false
    },
    addAddendumButton (event) {
      this.clearAlert()
      this.AddendumFormReset()
      if (this.addendumform) {
        this.closeAddendumFormButton()
        return
      }
      this.addendumform = true
    },
    editAddendumButton (id) {
      this.clearAlert()
      this.getAddendum(id)
      this.editingaddendum = id
      this.addendumform = true
      this.formtitle = 'Edit Addendum'
    },
    validateAddendumForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    scrollToTop (elementId = null) {
      var top = 0
      if (elementId) {
        var element = document.getElementById(elementId)
        top = element.offsetTop
      }
      window.scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    },
    saveAddendumFormButton (event) {
      this.clearAlert()
      if (this.validateAddendumForm() === false) {
        return
      }

      if (this.editingaddendum) {
        const {
          editingaddendum,
          restGroupId,
          label,
          lineText,
          deleteAddendum
        } = this
        this.updateAddendum({
          editingaddendum,
          restGroupId,
          label,
          lineText,
          deleteAddendum
        })
      } else {
        const {
          restGroupId,
          label,
          lineText
        } = this
        this.addAddendum({
          restGroupId,
          label,
          lineText
        })
      }
    }
  }
}
</script>
