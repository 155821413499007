<template>
  <div class="component-wrapper">
    <div class="container-fluid mb-sm-5">
      <div class="row">
        <div class="col-12">
          <div class="container mt-sm-5 my-1 bg-light">
              <div class="question ml-sm-5 pt-2">
                <div class="py-2 h5"><b>Select a restaurant to add a menu(s)</b></div>
                  <div class="ml-md-3 ml-sm-3 pl-md-5 pt-sm-0 pt-3">
                    <select class="form-control" :value="selectedRestaurantId" @change="brandRestSelectOnChange($event)">
                      <option value=''>-- Select Restaurant --</option>
                      <option v-for="(restaurant, index) of brandRestSelect()" v-bind:key="index" :value="restaurant.id">
                        {{restaurant.name}}
                      </option>
                    </select>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="selectedRestaurantId">
        <div class="col-12">
          <div class="container mt-sm-5 my-1 bg-light">
              <div class="question ml-sm-5 pt-2">
                <div class="py-2 h5"><b>{{getRestName()}}</b></div>
                <hr>
                <p>What separate menus does this restaurant offer? (e.g. Lunch, Dinner, Drinks) <button class="btn btn-sm btn-primary" v-on:click="addMenu()">Add Menu</button></p>
                <div class="form-row align-items-center border border-bottom-0 border-left-0 border-right-0 pt-3" v-for="(menu, menuIndex) of availableMenus()" v-bind:key="menuIndex">
                  <div class="col-6">
                    <label class="sr-only" for="inlineFormInput">Name</label>
                    <input type="text" class="form-control mb-2" placeholder="Menu Name (required)" v-model="menu.name">
                  </div>
                  <div class="col-auto">
                    <div class="btn-group" role="group" aria-label="Menu Functions">
                       <button type="submit" class="btn btn-sm btn-danger mb-2" v-on:click="deleteMenu(menu.id)">Delete</button>
                    </div>
                  </div>
                  <div class="col-12">
                    <p>Does this menu feature different sections? (e.g. Appetizers, Mains, Sides, Dessert) <button type="submit" class="btn btn-sm btn-primary mb-2" :disabled="menu.name === ''" v-on:click="addSection(menu.id)">Add Section</button></p>
                  </div>
                  <div class="col-12" v-if="availableSections(menu.id).length">
                    <hr class="mx-5">
                    <p class="px-5"><b>Sections</b></p>
                    <div class="form-row align-items-center px-5" v-for="(section, sectionIndex) of availableSections(menu.id)" v-bind:key="sectionIndex">
                      <div class="col-auto">
                          <div class="btn-group" role="group" aria-label="editingMenu">
                            <button type="button" class="btn btn-primary btn-sm" v-on:click="moveMenuSection(section.id, menu.id, -1)" :disabled="section.displayOrder === 1"><b-icon-chevron-up></b-icon-chevron-up></button>
                            <button type="button" class="btn btn-primary btn-sm" v-on:click="moveMenuSection(section.id, menu.id, 1)" :disabled="section.displayOrder === availableSections(menu.id).length"><b-icon-chevron-down></b-icon-chevron-down></button>
                          </div>
                      </div>
                      <div class="col-6">
                        <label class="sr-only" for="inlineFormInput">Section Name</label>
                        <input type="text" class="form-control mb-2" placeholder="Section Name (required)" v-model="section.name">
                      </div>
                      <div class="col-auto">
                        <div class="btn-group" role="group" aria-label="Section Functions">
                          <button type="submit" class="btn btn-sm btn-primary mb-2" v-on:click="addSubSection(menu.id, section.id, 1)">Add Sub Section</button>
                          <button type="submit" class="btn btn-sm btn-danger mb-2" v-on:click="deleteSection(menu.id, section.id, 0)">Delete</button>
                        </div>
                      </div>
                      <div class="col-12" v-if="isParentSection(section.id).length">
                        <p class="px-5"><b>Sub Sections</b></p>
                        <div class="form-row align-items-center px-5" v-for="(subSection, subSectionIndex) of isParentSection(section.id)" v-bind:key="subSectionIndex">
                        <div class="col-auto">
                            <div class="btn-group" role="group" aria-label="editingMenu">
                              <button type="button" class="btn btn-primary btn-sm" v-on:click="moveSubSection(subSection.childSectionId, section.id, -1)" :disabled="subSection.displayOrder === 1"><b-icon-chevron-up></b-icon-chevron-up></button>
                              <button type="button" class="btn btn-primary btn-sm" v-on:click="moveSubSection(subSection.childSectionId, section.id, 1)" :disabled="subSection.displayOrder === getSubSections(section.id).length"><b-icon-chevron-down></b-icon-chevron-down></button>
                            </div>
                        </div>
                        <div class="col-6">
                          <label class="sr-only" for="inlineFormInput">Sub Section Name</label>
                          <input type="text" class="form-control mb-2" placeholder="Sub Section Name (required)" v-model="getSection(subSection.childSectionId)[0].name">
                        </div>
                        <div class="col-auto">
                          <div class="btn-group" role="group" aria-label="Section Functions">
                            <button type="submit" class="btn btn-sm btn-primary mb-2" v-on:click="addSubSection(menu.id, subSection.childSectionId, 2)">Add Sub Section</button>
                            <button type="submit" class="btn btn-sm btn-danger mb-2" v-on:click="deleteSection(subSection.parentSectionId, subSection.childSectionId, 1)">Delete</button>
                          </div>
                        </div>
                          <div class="col-12" v-if="isParentSection(subSection.childSectionId).length">
                            <p class="px-5"><b>Sub Sub Sections</b></p>
                            <div class="form-row align-items-center px-5" v-for="(subSubSection, subSubSectionIndex) of isParentSection(subSection.childSectionId)" v-bind:key="subSubSectionIndex">
                              <div class="col-auto">
                                  <div class="btn-group" role="group" aria-label="editingMenu">
                                    <button type="button" class="btn btn-primary btn-sm" v-on:click="moveSubSection(subSubSection.childSectionId, subSection.childSectionId, -1)" :disabled="subSubSection.displayOrder === 1"><b-icon-chevron-up></b-icon-chevron-up></button>
                                    <button type="button" class="btn btn-primary btn-sm" v-on:click="moveSubSection(subSubSection.childSectionId, subSection.childSectionId, 1)" :disabled="subSubSection.displayOrder === getSubSections(subSection.childSectionId).length"><b-icon-chevron-down></b-icon-chevron-down></button>
                                  </div>
                              </div>
                              <div class="col-6">
                                <label class="sr-only" for="inlineFormInput">Sub Section Name</label>
                                <input type="text" class="form-control mb-2" placeholder="Sub Section Name (required)" v-model="getSection(subSubSection.childSectionId)[0].name">
                              </div>
                              <div class="col-auto">
                                <div class="btn-group" role="group" aria-label="Section Functions">
                                  <button type="submit" class="btn btn-sm btn-danger mb-2" v-on:click="deleteSection(subSubSection.parentSectionId, subSubSection.childSectionId, 2)">Delete</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr>
                  </div>
                  <hr>
                </div>
              </div>
          </div>
        </div>
        <div class="modal fade" id="addMenu" tabindex="-1" aria-labelledby="createMenuLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Add a menu to {{getRestName()}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Please specify a name for this menu.</p>
                <div class="form-group">
                  <label for="restName">Name</label>
                  <input type="text" class="form-control mb-2" placeholder="Menu Name (required)" v-model="activeMenu.name">
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" v-on:click="closeModal('addMenu')">Close</button>
                <button type="button" class="btn btn-primary" v-on:click="saveMenu()" :disabled="activeMenu.name === ''" >Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
           <a class="btn btn-primary mt-sm-5" :class="{ 'disabled' : menus.length === 0 }" v-on:click="$parent.menuItemsComponent">Next: Menu Items</a>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
<script>

import { mapState } from 'vuex'
import $ from 'jquery'
export default {
  name: 'menuStructure',
  props: {
    componentProps: {
      required: true
    }
  },
  watch: {
  },
  data () {
    return {
    }
  },
  methods: {
    brandRestSelectOnChange (event) {
      console.log('here')
      this.$parent.updateState({ name: 'selectedRestaurantId', value: event.target.value })
    },
    getRestName () {
      const restaurants = JSON.parse(JSON.stringify(this.restaurants))
      if (restaurants.length) {
        for (var r = 0; r < restaurants.length; r++) {
          if (restaurants[r].id === this.selectedRestaurantId) {
            return restaurants[r].name
          }
        }
      }
    },
    getSection (sectionId) {
      return this.sections
        .filter(s => s.id === sectionId)
    },
    availableMenus () {
      const restaurantId = this.selectedRestaurantId
      return this.menus
        .filter(m => m.restaurantId === restaurantId)
    },
    availableSections (menuId) {
      return this.sections
        .filter(s => s.menuId === menuId && this.isChildSection(s.id).length === 0)
        .sort((a, b) => a.displayOrder - b.displayOrder)
    },
    isChildSection (sectionId) {
      return this.subSections
        .filter(s => s.childSectionId === sectionId)
        .sort((a, b) => a.displayOrder - b.displayOrder)
    },
    isParentSection (sectionId) {
      return this.subSections
        .filter(s => s.parentSectionId === sectionId)
        .sort((a, b) => a.displayOrder - b.displayOrder)
    },
    getSubSections (sectionId) {
      const subSections = this.subSections
        .filter(s => s.parentSectionId === sectionId)
        .sort((a, b) => a.displayOrder - b.displayOrder)
      const childSectionIds = subSections.map(cs => { return cs.childSectionId })
      return this.sections.filter(section => childSectionIds.includes(section.id))
    },
    addMenu () {
      const restaurantId = this.selectedRestaurantId
      const menuId = this.$parent.uuid()
      this.$parent.updateState({ name: 'activeMenu', value: { id: menuId, restaurantId: restaurantId, name: '', description: '' } })
      this.openModal('addMenu')
    },
    saveMenu () {
      const menu = JSON.parse(JSON.stringify(this.activeMenu))
      this.menus.push(menu)
      this.closeModal('addMenu')
    },
    deleteMenu (menuId) {
      const data = {
        name: 'menu',
        keyName: 'id',
        keyValue: menuId
      }
      this.$parent.deleteStateArrayItem(data)
      const sectionData = {
        name: 'menu_section',
        keyName: 'menuId',
        keyValue: menuId
      }
      this.$parent.deleteStateArrayItem(sectionData)
    },
    addSection (menuId) {
      const section = {
        id: this.$parent.uuid(),
        menuId: menuId,
        name: '',
        description: '',
        displayOrder: this.availableSections(menuId).length + 1
      }
      this.sections.push(section)
    },
    addSubSection (menuId, sectionId, levelNbr) {
      const displayOrder = this.getSubSections(sectionId).length + 1
      const subSectionId = this.$parent.uuid()
      const section = {
        id: subSectionId,
        menuId: menuId,
        name: '',
        description: '',
        displayOrder: displayOrder
      }
      this.sections.push(section)

      const addSubSection = {
        id: this.$parent.uuid(),
        parentSectionId: sectionId,
        childSectionId: subSectionId,
        levelNbr: levelNbr,
        displayOrder: displayOrder
      }
      this.subSections.push(addSubSection)
    },
    deleteSection (parentId, sectionId, levelNbr) {
      const deleteMenuSubsections = []
      const deleteMenuSections = [sectionId]
      const subSections = JSON.parse(JSON.stringify(this.isParentSection(sectionId)))
      if (subSections.length) {
        for (var index = 0; index < subSections.length; index++) {
          deleteMenuSubsections.push(subSections[index].id)
          deleteMenuSections.push(subSections[index].childSectionId)
          const subSubSections = JSON.parse(JSON.stringify(this.isParentSection(subSections[index].childSectionId)))
          if (subSections.length) {
            for (var sss = 0; sss < subSubSections; sss++) {
              deleteMenuSubsections.push(subSubSections[sss].id)
              deleteMenuSections.push(subSubSections[sss].childSectionId)
            }
          }
        }
      }
      deleteMenuSubsections.reverse()
      deleteMenuSections.reverse()
      if (levelNbr !== 0) {
        const isChild = JSON.parse(JSON.stringify(this.isChildSection(sectionId)))
        if (isChild.length) {
          deleteMenuSubsections.push(isChild[0].id)
        }
      }
      if (deleteMenuSubsections.length) {
        for (var ssd = 0; ssd < deleteMenuSubsections.length; ssd++) {
          const subSectionData = {
            name: 'menu_subsection',
            keyName: 'id',
            keyValue: deleteMenuSubsections[ssd]
          }
          this.$parent.deleteStateArrayItem(subSectionData)
        }
      }

      if (deleteMenuSections.length) {
        for (var sd = 0; sd < deleteMenuSections.length; sd++) {
          const sectionData = {
            name: 'menu_section',
            keyName: 'id',
            keyValue: deleteMenuSections[sd]
          }
          this.$parent.deleteStateArrayItem(sectionData)
          // Delete Menu Section items
          const sectionItemData = {
            name: 'menu_section_item',
            keyName: 'menuSectionId',
            keyValue: deleteMenuSections[sd]
          }
          this.$parent.deleteStateArrayItem(sectionItemData)
        }
      }

      // Update Orders
      if (levelNbr !== 0) {
        this.updateSubSectionOrder(parentId)
      } else {
        this.updateSectionOrder(parentId)
      }
    },
    updateSectionOrder (menuId) {
      const sections = JSON.parse(JSON.stringify(this.availableSections(menuId)))
      var displayOrder = 1
      for (var sIndex = 0; sIndex < sections.length; sIndex++) {
        if (sections[sIndex] !== undefined) {
          const data = {
            name: 'menu_section',
            keyName: 'id',
            keyValue: sections[sIndex].id,
            updateKey: 'displayOrder',
            updateKeyValue: displayOrder
          }
          this.$parent.updateStateArrayItem(data)
          displayOrder = displayOrder + 1
        }
      }
    },
    updateSubSectionOrder (sectionId) {
      const sections = JSON.parse(JSON.stringify(this.isParentSection(sectionId)))
      var displayOrder = 1
      for (var sIndex = 0; sIndex < sections.length; sIndex++) {
        if (sections[sIndex] !== undefined) {
          const data = {
            name: 'menu_subsection',
            keyName: 'id',
            keyValue: sections[sIndex].id,
            updateKey: 'displayOrder',
            updateKeyValue: displayOrder
          }
          this.$parent.updateStateArrayItem(data)
          displayOrder = displayOrder + 1
        }
      }
      this.availableMenus()
    },
    moveMenuSection (sectionId, menuId, delta) {
      const sections = JSON.parse(JSON.stringify(this.availableSections(menuId)))
      const sectionLength = sections.length
      for (var index = 0; index < sectionLength; index++) {
        if (sections[index].id === sectionId) {
          break
        }
      }
      var newIndex = index + delta
      if (newIndex < 0 || newIndex === sectionLength) return

      var indexes = [index, newIndex].sort((a, b) => a - b)
      sections.splice(indexes[0], 2, sections[indexes[1]], sections[indexes[0]])
      var displayOrder = 1
      for (var sIndex = 0; sIndex < sectionLength; sIndex++) {
        if (sections[sIndex] !== undefined) {
          const data = {
            name: 'menu_section',
            keyName: 'id',
            keyValue: sections[sIndex].id,
            updateKey: 'displayOrder',
            updateKeyValue: displayOrder
          }
          this.$parent.updateStateArrayItem(data)
          displayOrder = displayOrder + 1
        }
      }
    },
    moveSubSection (subSectionId, sectionId, delta) {
      const sections = JSON.parse(JSON.stringify(this.isParentSection(sectionId)))
      const sectionLength = sections.length
      for (var index = 0; index < sectionLength; index++) {
        if (sections[index].childSectionId === subSectionId) {
          break
        }
      }
      var newIndex = index + delta
      if (newIndex < 0 || newIndex === sectionLength) return

      var indexes = [index, newIndex].sort((a, b) => a - b)
      sections.splice(indexes[0], 2, sections[indexes[1]], sections[indexes[0]])
      var displayOrder = 1
      for (var sIndex = 0; sIndex < sectionLength; sIndex++) {
        if (sections[sIndex] !== undefined) {
          const data = {
            name: 'menu_subsection',
            keyName: 'id',
            keyValue: sections[sIndex].id,
            updateKey: 'displayOrder',
            updateKeyValue: displayOrder
          }
          this.$parent.updateStateArrayItem(data)
          displayOrder = displayOrder + 1
        }
      }
      this.availableMenus()
    },
    brandRestSelect () {
      const selectOptions = []
      const brands = JSON.parse(JSON.stringify(this.brands))
      for (var i = 0; i < brands.length; i++) {
        const restaurants = JSON.parse(JSON.stringify(this.restaurants))
        if (restaurants.length) {
          for (var r = 0; r < restaurants.length; r++) {
            if (restaurants[r].brandId === brands[i].id) {
              if (this.restaurantsOperated === '1') {
                const restaurant = {
                  name: restaurants[r].name,
                  id: restaurants[r].id
                }
                selectOptions.push(restaurant)
              } else {
                const restaurant = {
                  name: brands[i].name + ' - ' + restaurants[r].name + ' - ' + restaurants[r].address,
                  id: restaurants[r].id
                }
                selectOptions.push(restaurant)
              }
            }
          }
        }
      }
      return selectOptions
    },
    openModal (modalId) {
      $('#' + modalId).modal('show')
    },
    closeModal (modalId) {
      $('#' + modalId).modal('hide')
    }
  },
  created () {
    this.$parent.updateState({ name: 'selectedRestaurantId', value: this.componentProps.menuId })
  },
  computed: {
    ...mapState({
      restaurants: state => state.menussetupv2.restaurant,
      restaurantsOperated: state => state.menussetupv2.restaurantsOperated,
      activeRestaurant: state => state.menussetupv2.activeRestaurant,
      activeMenu: state => state.menussetupv2.activeMenu,
      selectedRestaurantId: state => state.menussetupv2.selectedRestaurantId,
      brands: state => state.menussetupv2.brand,
      menus: state => state.menussetupv2.menu,
      sections: state => state.menussetupv2.menu_section,
      activeSection: state => state.menussetupv2.activeSection,
      subSections: state => state.menussetupv2.menu_subsection
    })
  }
}
</script>
