<template>
    <div class="page manage-restaurant-group">
        <div class="restgroup-details">
            <h6>{{restgroupname}}</h6>
        </div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'Restaurants' }" v-on:click="restaurantsComponent">Restaurants</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'Modifiers' }" v-on:click="modifiersComponent">Modifiers</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'Items' }" v-on:click="itemsComponent">Items</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'UnitsOfMeasure' }" v-on:click="unitsofmeasureComponent">Units of Measure</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'SalesTaxRates' }" v-on:click="salesTaxRatesComponent">Sales Tax Rates</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'Properties' }" v-on:click="propertiesComponent">Properties</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'Addendums' }" v-on:click="addendumsComponent">Addendums</a>
          </li>
        </ul>
        <div class="container-fluid pt-2">
            <div class="row">
                <div class="col">
                    <component v-bind:is="component" />
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .page {
        margin-top: 1rem;
        .restgroup-details {
          border-bottom: 1px solid #ccc;
          margin-bottom: 1rem;
        }
        .group-details {
          padding-top: 10px;
        }
        .group-detail {
          font-weight: bold;
          padding-right: 5px;
          padding-left: 10px;
        }
    }
</style>
<script>
import { mapState, mapActions } from 'vuex'
import Restaurants from './restaurants/Restaurants'
import UnitsOfMeasure from './unitsofmeasure/UnitsOfMeasure'
import SalesTaxRates from './salestaxrates/SalesTaxRates'
import Modifiers from './modifiers/Modifiers'
import Items from './items/Items'
import Properties from './properties/Properties'
import Addendums from './addendums/Addendums'

export default {
  components: {
    Restaurants, SalesTaxRates, Modifiers, Items, Properties, UnitsOfMeasure, Addendums
  },
  data () {
    return {
      restGroupId: this.$route.params.id,
      restgroupoperatorname: '',
      restgroupbrand: '',
      restgroupbrandname: '',
      restgroupname: '',
      restgroupdescription: '',
      restgroupassociation: '',
      component: '',
      activeNav: '',
      msg: {
        errors: {}
      }
    }
  },
  computed: {
    ...mapState({
      activerestgroup: state => state.restgroups.activerestgroup
    })
  },
  created () {
    this.getRestGroup(this.restGroupId)
    this.component = this.$route.params.componentName ? this.$route.params.componentName : 'Restaurants'
    this.activeNav = this.$route.params.componentName ? this.$route.params.componentName : 'Restaurants'
  },
  watch: {
    activerestgroup: {
      handler (val, oldVal) {
        this.restGroupId = val.restaurant_group_id
        this.restgroupoperator = val.operator_id
        this.restgroupoperatorname = val.operator_name
        this.restgroupbrand = val.brand_id
        this.restgroupbrandname = val.brand_name
        this.restgroupname = val.name
        this.restgroupdescription = val.description
        this.restgroupassociation = val.association
      }
    }
  },
  methods: {
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    ...mapActions('restgroups', {
      getRestGroup: 'getById',
      updateRestGroup: 'update'
    }),
    restaurantsComponent (event) {
      this.clearAlert()
      this.component = Restaurants
      this.activeNav = 'Restaurants'
    },
    modifiersComponent (event) {
      this.clearAlert()
      this.component = Modifiers
      this.activeNav = 'Modifiers'
    },
    salesTaxRatesComponent (event) {
      this.clearAlert()
      this.component = SalesTaxRates
      this.activeNav = 'SalesTaxRates'
    },
    itemsComponent (event) {
      this.clearAlert()
      this.component = Items
      this.activeNav = 'Items'
    },
    propertiesComponent (event) {
      this.clearAlert()
      this.component = Properties
      this.activeNav = 'Properties'
    },
    unitsofmeasureComponent (event) {
      this.clearAlert()
      this.component = UnitsOfMeasure
      this.activeNav = 'UnitsOfMeasure'
    },
    addendumsComponent (event) {
      this.clearAlert()
      this.component = Addendums
      this.activeNav = 'Addendums'
    }
  }
}
</script>
