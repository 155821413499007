<template>
  <div class="component-container menus">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 middle-divider">
          <h1 class="mb-3">Which one of your restaurants should we create a menu for?</h1>
          <p>You've got multiple restaurants in your operation. Let us focus on one of them to add a menu too. You can add more later.</p>
        </div>
        <div class="col-md-6">
          <p>Select one of your restaurants to add a menu.</p>
          <div class="container-fluid">
            <div class="row" v-for="(restaurant, restIndex) of getRestaurants()" v-bind:key="restIndex" :value="restaurant.id">
              <div class="col">
                <button type="button"
                  class="btn btn-lg btn-block px-3 text-left mb-3"
                  :class="{ 'btn-success' : $parent.selectedRestaurantId === restaurant.id, 'btn-primary' : $parent.selectedRestaurantId !== restaurant.id }"
                  v-on:click="setSelectedRestaurantId(restaurant.id)">
                  <mdb-icon v-if="$parent.selectedRestaurantId === restaurant.id" far icon="check-square" size='lg' />
                  <mdb-icon v-else far icon="square" size='lg' /> {{restaurant.name}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 text-left">
          <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.goToPrevComponent()"><mdb-icon icon="arrow-alt-circle-left" size='lg' /> Go Back</button>        </div>
        <div class="col-md-6 text-right">
           <button v-if="$parent.selectedRestaurantId" type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.menuComponent()">Menu Name <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mdbIcon } from 'mdbvue'
export default {
  name: 'menus',
  components: { mdbIcon },
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
      newMenuName: ''
    }
  },
  watch: {},
  methods: {
    setSelectedRestaurantId (restId) {
      const selectedRestaurantId = this.$parent.selectedRestaurantId
      if (selectedRestaurantId === restId) {
        this.$parent.updateState({ name: 'selectedRestaurantId', value: '' })
        return
      }
      this.$parent.updateState({ name: 'selectedRestaurantId', value: restId })
    },
    getRestaurants () {
      return this.$parent.setup.restaurants
    }
  },
  computed: {}
}
</script>
