import { authHeader, apiUrl, handleResponse } from '../_helpers'

export const restaurantsService = {
  getAll,
  getById,
  add,
  update,
  getRestSalesTaxRates,
  getRestSalesTaxRate,
  addRestSalesTaxRate,
  updateRestSalesTaxRate
}

function getAll () {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'restaurants',
      data: {
        action: 'getAll'
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'restaurants',
      data: {
        action: 'getRestaurant',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function add (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'restaurants',
      data: {
        action: 'addRestaurant',
        restaurant_group_id: data.restaurantrestgroupid,
        name: data.restaurantname,
        description: data.restaurantdescription,
        address1: data.restaurantaddress1,
        address2: data.restaurantaddress2,
        city: data.restaurantcity,
        province: data.restaurantprovince,
        postal_code: data.restaurantpostalcode,
        country: data.restaurantcountry
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function update (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'restaurants',
      data: {
        action: 'updateRestaurant',
        restaurant_id: data.editingrestaurant,
        restaurant_group_id: data.restaurantrestgroupid,
        name: data.restaurantname,
        description: data.restaurantdescription,
        address1: data.restaurantaddress1,
        address2: data.restaurantaddress2,
        city: data.restaurantcity,
        province: data.restaurantprovince,
        postal_code: data.restaurantpostalcode,
        country: data.restaurantcountry,
        deleteRestaurant: data.deleterestaurant
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getRestSalesTaxRates (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'restaurants/salestaxrate',
      data: {
        action: 'getRestSalesTaxRates',
        restGroupId: data.restGroupId,
        restId: data.restId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getRestSalesTaxRate (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'restaurants/salestaxrate',
      data: {
        action: 'getRestSalesTaxRate',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function addRestSalesTaxRate (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'restaurants/salestaxrate',
      data: {
        action: 'addRestSalesTaxRate',
        restId: data.restId,
        salesTaxRateId: data.salesTaxRateId,
        association: data.association,
        isDefault: data.isDefault
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function updateRestSalesTaxRate (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'restaurants/salestaxrate',
      data: {
        action: 'updateRestSalesTaxRate',
        restaurantSalesTaxId: data.editingresttaxrate,
        restId: data.restId,
        salesTaxRateId: data.salesTaxRateId,
        association: data.association,
        isDefault: data.isDefault,
        deleteRestTaxRate: data.deleteRestTaxRate
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}
