<template>
    <div class="restgroups-page page">
        <h6 class="page-title">Restaurant Groups <button v-on:click="addRestGroupButton" class="btn btn-sm btn-primary">Add +</button></h6>
        <div class="restgroups-form" v-if="restgroupform">
            <p>{{formtitle}}</p>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 col-md-6 mb-3">
                        <p>Operator</p>
                        <v-select placeholder="Search..." :options="operatorsIdsNamesSelect" @input="setOperator" v-model="restgroupoperatorname"></v-select>
                        <div class="input-error" v-if="msg.errors.operator_id">
                            Valid Operator is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 mb-3">
                        <p>Brand</p>
                        <v-select placeholder="Search..." :options="brandsIdsNamesSelect" @input="setBrand" v-model="restgroupbrandname"></v-select>
                        <div class="input-error" v-if="msg.errors.brand_id">
                            Valid Brand is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 mb-3">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" id="name" placeholder="" v-model="restgroupname">
                        <div class="input-error" v-if="msg.errors.name">
                            Valid restgroup name is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 mb-3">
                        <label for="description">Description</label>
                        <input type="text" class="form-control" id="description" placeholder="" v-model="restgroupdescription">
                        <div class="input-error" v-if="msg.errors.description">
                            Valid restgroup Description is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 mb-3">
                        <label for="association">Association</label>
                        <input type="text" class="form-control" id="association" placeholder="" v-model="restgroupassociation">
                        <div class="input-error" v-if="msg.errors.association">
                            Valid restgroup Association is required.
                        </div>
                    </div>
                </div>
                <div class="row" v-if="editingrestgroup">
                  <div class="col-sm-12 col-md-6 mb-3 deletecontrol text-right">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="deleterestgroup" id="deleterestgroup">
                      <label class="form-check-label" for="deleterestgroup">
                        Delete this Restaurant Group?
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 mb-3 text-left form-controls">
                        <button v-on:click="saveRestGroupFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                        <button v-on:click="closeRestGroupFormButton" class="btn btn-sm btn-danger">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="restgroups.restgroups" class="restgroups-list">
            <table class="table table-striped">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Actions</th>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Association</th>
                  <th scope="col">Operator</th>
                  <th scope="col">Brand</th>
                </tr>
              </thead>
              <tbody>
                    <tr v-for="restgroup in restgroups.restgroups" :key="restgroup.name">
                      <td>
                        <div class="btn-group" role="group" aria-label="Basic example">
                          <router-link tag="button" class="btn btn-sm btn-success" :to="{ name: 'managerestgroup', params: {id: restgroup.restaurant_group_id } }">Manage</router-link>
                          <button v-on:click="editRestGroupButton(restgroup.restaurant_group_id)" class="btn btn-sm btn-primary">Edit</button>
                        </div>
                      </td>
                      <td>{{restgroup.restaurant_group_id}}</td>
                      <td><router-link class=""  :to="{ name: 'managerestgroup', params: {id: restgroup.restaurant_group_id } }">{{restgroup.name}}</router-link></td>
                      <td>{{restgroup.description}}</td>
                      <td>{{restgroup.association}}</td>
                      <td>{{restgroup.operator_name}}</td>
                      <td>{{restgroup.brand_name}}</td>
                    </tr>
              </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  data () {
    return {
      formtitle: 'Add new Rest Group',
      formsavetext: 'Save',
      restgroupform: false,
      restgroupoperatorname: '',
      restgroupbrand: '',
      restgroupbrandname: '',
      restgroupname: '',
      restgroupdescription: '',
      restgroupassociation: '',
      editingrestgroup: '',
      deleterestgroup: false,
      msg: { errors: { operator_id: false, brand_id: false, name: false, description: false, association: false } }
    }
  },
  computed: {
    ...mapState({
      restgroups: state => state.restgroups.all,
      activerestgroup: state => state.restgroups.activerestgroup,
      addrestgroup: state => state.restgroups.addrestgroup,
      updaterestgroup: state => state.restgroups.updaterestgroup,
      restgroupsIdsNamesSelect: state => state.restgroups.idsNamesSelect,
      operatorsIdsNamesSelect: state => state.operators.idsNamesSelect,
      brandsIdsNamesSelect: state => state.brands.idsNamesSelect
    })
  },
  created () {
    this.getAllRestGroups()
    this.getAllOperators()
    this.getAllBrands()
  },
  watch: {
    activerestgroup: {
      handler (val, oldVal) {
        this.restgroupoperator = val.operator_id
        this.restgroupoperatorname = val.operator_name
        this.restgroupbrand = val.brand_id
        this.restgroupbrandname = val.brand_name
        this.restgroupname = val.name
        this.restgroupdescription = val.description
        this.restgroupassociation = val.association
        this.editingrestgroup = val.restaurant_group_id
      }
    },
    restgroupoperator: {
      handler (val, oldVal) {
        this.validateRestGroupForm()
      }
    },
    restgroupbrand: {
      handler (val, oldVal) {
        this.validateRestGroupForm()
      }
    },
    restgroupname: {
      handler (val, oldVal) {
        this.validateRestGroupForm()
      }
    },
    restgroupdescription: {
      handler (val, oldVal) {
        this.validateRestGroupForm()
      }
    },
    restgroupassociation: {
      handler (val, oldVal) {
        this.validateRestGroupForm()
      }
    },
    addrestgroup: {
      handler (val, oldVal) {
        if (val.restaurant_group_id) {
          this.getAllRestGroups()
          this.RestGroupFormReset()
          this.closeRestGroupFormButton()
        }
      }
    },
    updaterestgroup: {
      handler (val, oldVal) {
        if (val.restaurant_group_id) {
          this.getAllRestGroups()
          this.RestGroupFormReset()
          this.closeRestGroupFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('restgroups', {
      getAllRestGroups: 'getAll',
      getRestGroup: 'getById',
      updateRestGroup: 'update',
      addRestGroup: 'add'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    ...mapActions('operators', {
      getAllOperators: 'getAll'
    }),
    ...mapActions('brands', {
      getAllBrands: 'getAll'
    }),
    RestGroupFormReset (event) {
      this.formtitle = 'Add new Rest Group'
      this.restgroupoperator = ''
      this.restgroupoperatorname = ''
      this.restgroupbrand = ''
      this.restgroupbrandname = ''
      this.restgroupname = ''
      this.restgroupdescription = ''
      this.restgroupassociation = ''
      this.editingrestgroup = ''
      this.deleterestgroup = false
    },
    closeRestGroupFormButton (event) {
      this.RestGroupFormReset()
      this.restgroupform = false
    },
    addRestGroupButton (event) {
      this.clearAlert()
      this.RestGroupFormReset()
      if (this.restgroupform && this.editRestGroupData === '') {
        this.closeRestGroupFormButton()
        return
      }
      this.editRestGroupData = ''
      this.restgroupform = true
    },
    setOperator (value) {
      if (value !== null) {
        this.restgroupoperator = value.code
        return
      }
      this.restgroupoperator = ''
    },
    setBrand (value) {
      if (value !== null) {
        this.restgroupbrand = value.code
        return
      }
      this.restgroupbrand = ''
    },
    editRestGroupButton (id) {
      this.clearAlert()
      this.getRestGroup(id)
      this.restgroupform = true
      this.formtitle = 'Edit Rest Group'
    },
    validateRestGroupForm (event) {
      this.msg = {
        errors: {
          operator_id: false,
          brand_id: false,
          name: false,
          description: false,
          association: false
        }
      }

      if (this.restgroupoperator === '') {
        this.msg.errors.operator_id = true
        return false
      }
      if (this.restgroupbrand === '') {
        this.msg.errors.brand_id = true
        return false
      }
      if (this.restgroupname === '') {
        this.msg.errors.name = true
        return false
      }
      if (this.restgroupdescription === '') {
        this.msg.errors.description = true
        return false
      }
      if (this.restgroupassociation === '') {
        this.msg.errors.association = true
        return false
      }
    },
    saveRestGroupFormButton (event) {
      this.clearAlert()
      if (this.validateRestGroupForm() === false) {
        return
      }

      if (this.editingrestgroup) {
        const { editingrestgroup, restgroupoperator, restgroupbrand, restgroupname, restgroupdescription, restgroupassociation, deleterestgroup } = this
        this.updateRestGroup({ editingrestgroup, restgroupoperator, restgroupbrand, restgroupname, restgroupdescription, restgroupassociation, deleterestgroup })
      } else {
        const { restgroupoperator, restgroupbrand, restgroupname, restgroupdescription, restgroupassociation } = this
        this.addRestGroup({ restgroupoperator, restgroupbrand, restgroupname, restgroupdescription, restgroupassociation })
      }
    }
  }
}
</script>
