import { operatorsService } from '../_services'

const state = {
  all: {},
  activeoperator: {},
  editoperator: {},
  addoperator: {},
  updateoperator: {},
  idsNamesSelect: []
}

const actions = {
  getAll ({ dispatch, commit }) {
    const stateName = 'all'
    commit('request', { stateName: stateName })

    operatorsService.getAll()
      .then(
        operators => {
          commit('getAllSuccess', operators.data)
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getById ({ dispatch, commit }, id) {
    const stateName = 'activeoperator'
    commit('request', { stateName: stateName })

    operatorsService.getById(id)
      .then(
        operator => commit('getByIdSuccess', operator.data),
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  add ({ dispatch, commit }, name, description) {
    const stateName = 'addoperator'
    commit('request', { stateName: stateName })

    operatorsService.add(name, description)
      .then(
        operator => {
          commit('addSuccess', operator.data)
          dispatch('alert/success', 'Added Operator', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  update ({ dispatch, commit }, operatorId, name, description) {
    const stateName = 'updateoperator'
    commit('request', { stateName: stateName })

    operatorsService.update(operatorId, name, description)
      .then(
        operator => {
          commit('updateSuccess', operator.data)
          dispatch('alert/success', 'Edited Operator', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  getAllSuccess (state, operators) {
    state.all = { operators }
    const selectData = []
    if (operators) {
      Array.from(operators).forEach(operator => {
        selectData.push({ code: operator.operator_id, label: operator.name })
      })
    }
    state.idsNamesSelect = selectData
  },
  getByIdSuccess (state, operator) {
    state.activeoperator = operator
  },
  addSuccess (state, operator) {
    state.addoperator = operator
  },
  updateSuccess (state, operator) {
    state.updateoperator = operator
  }
}

export const operators = {
  namespaced: true,
  state,
  actions,
  mutations
}
