<template>
  <div class="component-container brands-operated">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 middle-divider">
          <h1 class="mb-3">How many brands do your restaurants operate under?</h1>
          <p>Help us understand how your restaurant operation is organized.</p>
        </div>
        <div class="col-md-6">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <button type="button"
                  class="btn btn-lg btn-block px-3 text-left mb-3"
                  :class="{ 'btn-success' : getBrandsOperated() === 1, 'btn-primary' : getBrandsOperated() !== 1 }"
                  v-on:click="setBrandsOperated(1)">
                  <mdb-icon v-if="getBrandsOperated() === 1" far icon="check-square" size='lg' />
                  <mdb-icon v-else far icon="square" size='lg' /> One Brand
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button type="button"
                  class="btn btn-lg btn-block px-3 text-left mb-3"
                  :class="{ 'btn-success' : getBrandsOperated() === 2, 'btn-primary' : getBrandsOperated() !== 2 }"
                  v-on:click="setBrandsOperated(2)">
                  <mdb-icon v-if="getBrandsOperated() === 2" far icon="check-square" size='lg' />
                  <mdb-icon v-else far icon="square" size='lg' /> Multiple Brands
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button type="button"
                  class="btn btn-lg btn-block px-3 text-left mb-3"
                  :class="{ 'btn-success' : getBrandsOperated() === 3, 'btn-primary' : getBrandsOperated() !== 3 }"
                  v-on:click="setBrandsOperated(3)">
                  <mdb-icon v-if="getBrandsOperated() === 3" far icon="check-square" size='lg' />
                  <mdb-icon v-else far icon="square" size='lg' /> Prefer not to answer at this time.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 text-left">
          <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.goToPrevComponent()"><mdb-icon icon="arrow-alt-circle-left" size='lg' /> Go Back</button>
        </div>
        <div class="col-md-6 text-right" v-if="getBrandsOperated() !== 0">
           <button v-if="getBrandsOperated() === 3 " type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.restaurantsComponent()">Next Question <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
           <button v-if="getBrandsOperated() !== 3 " type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.brandsComponent()">Next Question <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mdbIcon } from 'mdbvue'
export default {
  name: 'brandsOperated',
  components: { mdbIcon },
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {}
  },
  watch: {},
  methods: {
    setBrandsOperated (value) {
      if (value === this.getBrandsOperated()) {
        value = 0
      }
      const data = {
        objectName: 'setup',
        property: 'brandsOperated',
        value: value
      }
      this.$parent.updateStateObject(data)
    },
    getBrandsOperated () {
      return this.$parent.setup.brandsOperated
    }
  },
  computed: {}
}
</script>
