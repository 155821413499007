<template>
    <div class="menusetup-page page">
        <div class="menu-setup-header">
           <h6>Menu Setup</h6>
        </div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'restaurantAdmin' }" v-on:click="restaurantAdminComponent">1. Restaurant Admin</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'menus', 'disabled' : !activeRestaurant }" v-on:click="menusComponent">2. Menus</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'items', 'disabled' : Object.keys(menu).length === 0 || !activeRestaurant || Object.keys(section).length === 0 }" v-on:click="itemsComponent">3. Items</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'modifiers', 'disabled' : !activeRestaurant }" v-on:click="modifiersComponent">4. Modifiers</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'applicationOfModifiers', 'disabled' : !activeRestaurant || Object.keys(modifier).length === 0 }" v-on:click="applicationOfModifiersComponent">5. Application of Modifiers</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ 'active' : activeNav === 'editor', 'disabled' : !activeRestaurant || Object.keys(modifier).length === 0 }" v-on:click="editorComponent">Menu Editor</a>
          </li>
        </ul>
        <component v-bind:is="component" :componentProps="childComponentProps" />
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <hr>
              <a class="btn btn-sm btn-primary" v-if="!showDevOptions" v-on:click="toggleDevOptions(true)">Show Dev Options</a>
              <a class="btn btn-sm btn-danger" v-if="showDevOptions" v-on:click="toggleDevOptions(false)">Close Dev Options</a>
              <hr>
            </div>
          </div>
          <div class="row" v-if="showDevOptions">
            <div class="col">
              <p>Load Test Data <button class="btn btn-primary" v-on:click="loadTestData('single')">Single Restaurant</button> <button class="btn btn-primary" v-on:click="loadTestData('multiple')">Multiple Restaurants</button> <button class="btn btn-danger" v-on:click="loadTestData('reset')">Reset Data</button></p>
              <p>Data Store</p>
              <pre>
                {{menussetup}}
              </pre>
            </div>
          </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
  .menusetup-child-component {
    margin-top: 1rem;
  }
</style>
<script>
import { mapActions, mapState } from 'vuex'
import restaurantAdmin from './child-components/restaurantAdmin'
import menus from './child-components/menus'
import items from './child-components/items'
import modifiers from './child-components/modifiers'
import applicationOfModifiers from './child-components/applicationOfModifiers'
import importData from './child-components/importData'
import editor from './child-components/editor'

export default {
  name: 'MenuSetupPage',
  components: { restaurantAdmin, menus, items, modifiers, applicationOfModifiers },
  data () {
    return {
      showDevOptions: false,
      component: restaurantAdmin,
      childComponentProps: {},
      activeNav: 'restaurantAdmin',
      msg: {
        errors: {}
      }
    }
  },
  methods: {
    toggleDevOptions (show) {
      if (show === true) {
        this.showDevOptions = true
        return
      }
      this.showDevOptions = false
    },
    loadTestData (type) {
      this.importTestData(type)
      this.showDevOptions = false
    },
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    ...mapActions('menussetup', {
      importTestData: 'importTestData',
      setDisplay: 'setDisplay',
      setActiveRestaurant: 'setActiveRestaurant',
      setLocations: 'setLocations',
      setRestOperationType: 'setRestOperationType',
      updateBrand: 'updateBrand',
      addRestaurant: 'addRestaurant',
      deleteRestaurant: 'deleteRestaurant',
      addMenu: 'addMenu',
      deleteMenu: 'deleteMenu',
      setActiveMenu: 'setActiveMenu',
      addMenuSection: 'addMenuSection',
      deleteMenuSection: 'deleteMenuSection',
      setActiveMenuSection: 'setActiveMenuSection',
      moveMenuSection: 'moveMenuSection',
      addMenuItem: 'addMenuItem',
      deleteMenuItem: 'deleteMenuItem',
      sortMenuItems: 'sortMenuItems',
      setActiveModifierSet: 'setActiveModifierSet',
      addModifierSet: 'addModifierSet',
      deleteModifierSet: 'deleteModifierSet',
      setActiveModifier: 'setActiveModifier',
      addModifier: 'addModifier',
      deleteModifier: 'deleteModifier',
      addItemImportDataToStore: 'addItemImportDataToStore',
      updateImportData: 'updateImportData'
    }),
    restaurantAdminComponent (event) {
      this.clearAlert()
      this.component = restaurantAdmin
      this.activeNav = 'restaurantAdmin'
    },
    menusComponent (event) {
      this.clearAlert()
      this.component = menus
      this.activeNav = 'menus'
    },
    itemsComponent (event) {
      this.clearAlert()
      this.component = items
      this.activeNav = 'items'
    },
    modifiersComponent (event) {
      this.clearAlert()
      this.component = modifiers
      this.activeNav = 'modifiers'
    },
    applicationOfModifiersComponent (event) {
      this.clearAlert()
      this.component = applicationOfModifiers
      this.activeNav = 'applicationOfModifiers'
    },
    importDataComponent (component) {
      this.clearAlert()
      this.childComponentProps = { component }
      this.component = importData
    },
    editorComponent (component) {
      this.clearAlert()
      this.childComponentProps = { component }
      this.component = editor
      this.activeNav = 'editor'
    }
  },
  computed: {
    ...mapState({
      menussetup: state => state.menussetup,
      activeRestaurant: state => state.menussetup.activeRestaurant,
      items: state => state.menussetup.item,
      menu: state => state.menussetup.menu,
      section: state => state.menussetup.menu_section,
      modifier: state => state.menussetup.modifier,
      activeModifier: state => state.menussetup.activeModifier,
      modifierSet: state => state.menussetup.modifier_set
    })
  }
}
</script>
