import { authHeader, apiUrl, handleResponse } from '../_helpers'

export const menuitemsService = {
  getAll,
  getById,
  add,
  update,
  getUnitsOfMeasure
}

function getAll (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu/item',
      data: {
        action: 'getAll',
        restGroupId: data.restGroupId,
        restId: data.restId,
        menuId: data.menuId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getUnitsOfMeasure (restGroupId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu/item',
      data: {
        action: 'getUnitsOfMeasure',
        restGroupId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getById (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu/item',
      data: {
        action: 'getMenuItem',
        id: data.id,
        restGroupId: data.restGroupId,
        restId: data.restId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function add (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu/item',
      data: {
        action: 'addMenuItem',
        restGroupId: data.restGroupId,
        restId: data.restId,
        menuId: data.menuId,
        itemTypeId: data.itemTypeId,
        itemId: data.itemId,
        association: data.association,
        name: data.name,
        description: data.description,
        ticketName: data.ticketName,
        chit: data.chit,
        baseQty: data.baseQty,
        price: data.price,
        priceDescription: data.priceDescription,
        pricingModelId: data.pricingModelId,
        unitOfMeasureId: data.unitOfMeasureId,
        restSalesTaxId: data.restSalesTaxId,
        calories: data.calories,
        isActive: data.isActive,
        isMeasured: data.isMeasured
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function update (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu/item',
      data: {
        action: 'updateMenuItem',
        restGroupId: data.restGroupId,
        restId: data.restId,
        menuId: data.menuId,
        itemId: data.itemId,
        association: data.association,
        name: data.name,
        description: data.description,
        ticketName: data.ticketName,
        chit: data.chit,
        baseQty: data.baseQty,
        price: data.price,
        priceDescription: data.priceDescription,
        pricingModelId: data.pricingModelId,
        itemUnitId: data.itemUnitId,
        unitOfMeasureId: data.unitOfMeasureId,
        restSalesTaxId: data.restSalesTaxId,
        calories: data.calories,
        isActive: data.isActive,
        isMeasured: data.isMeasured,
        editingitem: data.editingitem,
        deleteMenuItem: data.deleteMenuItem
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}
