import { authHeader, apiUrl, handleResponse } from '../_helpers'

export const addendumsService = {
  getAll,
  getById,
  add,
  update
}

function getAll (restGroupId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'addendum',
      data: {
        action: 'getAll',
        restGroupId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'addendum',
      data: {
        action: 'getAddendum',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function add (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'addendum',
      data: {
        action: 'addAddendum',
        restGroupId: data.restGroupId,
        label: data.label,
        lineText: data.lineText
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function update (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'addendum',
      data: {
        action: 'updateAddendum',
        addendumId: data.editingaddendum,
        restGroupId: data.restGroupId,
        label: data.label,
        lineText: data.lineText,
        deleteAddendum: data.deleteAddendum
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}
