<template>
    <div class="row modifier-spec-sets" id="modifier-spec-sets-list">
      <div class="col-6">
        <h6>Modifier Spec Sets <button v-on:click="addModifierSpecSetButton"  class="btn btn-sm btn-primary btn-sm">Add +</button></h6>
        <div class="modifierspecset-form" v-if="modifierspecsetform">
            <p>{{formtitle}}</p>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 mb-3">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" id="name" placeholder="" v-model="name">
                        <div class="input-error" v-if="msg.errors.name">
                            Valid modifier spec set name is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mb-3">
                        <label for="description">Description</label>
                        <input type="text" class="form-control" id="description" placeholder="" v-model="description">
                        <div class="input-error" v-if="msg.errors.description">
                            Valid modifier spec set Description is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mb-3">
                        <label for="displayOrder">Display Order</label>
                        <input type="text" class="form-control" id="displayOrder" placeholder="" v-model="displayOrder">
                        <div class="input-error" v-if="msg.errors.displayOrder">
                            Valid modifier spec set displayOrder is required.
                        </div>
                    </div>
                </div>
                <div class="row" v-if="editingmodifierspecset">
                  <div class="col-sm-12 mb-3 deletecontrol text-right">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="deleteModifierSpecSet" id="deleteModifierSpecSet">
                      <label class="form-check-label" for="deleteModifierSpecSet">
                        Delete this Modifier Spec Set?
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mb-3 text-left form-controls">
                        <button v-on:click="saveModifierSpecSetFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                        <button v-on:click="closeModifierSpecSetFormButton" class="btn btn-sm btn-danger">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="'specsets' in groupmodifierspecsets" class="specsets-list">
            <table class="table table-striped">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Display Order</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Edit</th>
                </tr>
              </thead>
              <tbody>
                    <tr v-for="specset in groupmodifierspecsets.specsets"
                        :key="specset.modifier_spec_set_id"
                        :class="specset.modifier_spec_set_id">
                      <td>{{specset.modifier_spec_set_id}}</td>
                      <td>{{specset.display_order}}</td>
                      <td>{{specset.name}}</td>
                      <td>{{specset.description}}</td>
                      <td>
                        <p><button v-on:click="editModifierSetSpecsButton(specset.modifier_spec_set_id)" class="btn btn-block btn-sm btn-primary">Specs</button></p>
                        <p><button v-on:click="editModifierSpecSetButton(specset.modifier_spec_set_id)" class="btn btn-block btn-sm btn-primary">Details</button></p>
                      </td>
                    </tr>
              </tbody>
            </table>
        </div>
        <div v-else>
            No spec sets available.
        </div>
      </div>
      <div class="col-6" v-if="editingmodifierspecsetspecs">
        <ModifierSpecs v-bind:modifierSpecSetId="editingmodifierspecsetspecs" />
      </div>
    </div>
</template>
<style lang="scss" scoped>
  .specsets-list {
    .green-border {
      border: 3px solid green;
    }
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'
import ModifierSpecs from './ModifierSpecs'

export default {
  name: 'ModifierSpecSets',
  components: {
    ModifierSpecs
  },
  data () {
    return {
      formtitle: 'Add new Modifier Spec Set',
      formsavetext: 'Save',
      modifierspecsetform: false,
      restGroupId: this.$route.params.id,
      name: '',
      description: '',
      displayOrder: 0,
      editingmodifierspecset: '',
      editingmodifierspecsetspecs: false,
      deleteModifierSpecSet: false,
      msg: {
        errors: {
          name: false,
          description: false,
          displayOrder: false
        }
      }
    }
  },
  computed: {
    ...mapState({
      groupmodifierspecsets: state => state.modifiers.allspecsets,
      activespecset: state => state.modifiers.activespecset,
      addspecset: state => state.modifiers.addspecset,
      updatespecset: state => state.modifiers.updatespecset
    })
  },
  created () {
    this.getSpecSets(this.restGroupId)
  },
  watch: {
    activespecset: {
      handler (val, oldVal) {
        this.name = val.name
        this.description = val.description
        this.displayOrder = val.display_order
        this.editingmodifierspecset = val.modifier_spec_set_id
      }
    },
    name: {
      handler (val, oldVal) {
        this.validateModifierSpecSetForm()
      }
    },
    description: {
      handler (val, oldVal) {
        this.validateModifierSpecSetForm()
      }
    },
    displayOrder: {
      handler (val, oldVal) {
        this.validateModifierSpecSetForm()
      }
    },
    addspecset: {
      handler (val, oldVal) {
        if (val.modifier_spec_set_id) {
          this.getSpecSets(this.restGroupId)
          this.ModifierSpecSetFormReset()
          this.closeModifierSpecSetFormButton()
        }
      }
    },
    updatespecset: {
      handler (val, oldVal) {
        if (val.modifier_spec_set_id) {
          this.getSpecSets(this.restGroupId)
          this.ModifierSpecSetFormReset()
          this.closeModifierSpecSetFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('modifiers', {
      getSpecSets: 'getAllSpecSets',
      getSpecSet: 'getSpecSetById',
      updateSpecSet: 'updateSpecSet',
      addSpecSet: 'addSpecSet'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    scrollToTop (elementId = null) {
      var top = 0
      if (elementId) {
        var element = document.getElementById(elementId)
        top = element.offsetTop
      }
      window.scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    },
    ModifierSpecSetFormReset (event) {
      this.formtitle = 'Add new Modifier Spec Set'
      this.name = ''
      this.description = ''
      this.editingmodifierspecset = ''
      this.displayOrder = 0
      this.deleteModifierSpecSet = false
    },
    closeModifierSpecSetFormButton (event) {
      this.modifierspecsetform = false
    },
    addModifierSpecSetButton (event) {
      this.clearAlert()
      this.ModifierSpecSetFormReset()
      if (this.modifierspecsetform) {
        this.closeModifierSpecSetFormButton()
        return
      }
      this.modifierspecsetform = true
    },
    editModifierSetSpecsButton (setId) {
      this.ModifierSpecSetFormReset()
      this.clearAlert()
      this.closeModifierSpecSetFormButton()
      this.editingmodifierspecsetspecs = setId
      this.scrollToTop()
    },
    editModifierSpecSetButton (id) {
      this.clearAlert()
      this.getSpecSet(id)
      this.modifierspecsetform = true
      this.formtitle = 'Edit Modifier Spec Set'
      this.scrollToTop('modifier-spec-sets-list')
    },
    validateModifierSpecSetForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    saveModifierSpecSetFormButton (event) {
      this.clearAlert()
      if (this.validateModifierSpecSetForm() === false) {
        return
      }

      if (this.editingmodifierspecset) {
        const {
          restGroupId,
          editingmodifierspecset,
          name,
          description,
          displayOrder,
          deleteModifierSpecSet
        } = this
        this.updateSpecSet({
          editingmodifierspecset,
          name,
          description,
          restGroupId,
          displayOrder,
          deleteModifierSpecSet
        })
      } else {
        const {
          name,
          description,
          restGroupId,
          displayOrder
        } = this
        this.addSpecSet({
          name,
          description,
          displayOrder,
          restGroupId
        })
      }
    }
  }
}
</script>
