<template>
<div class="property-values-page">
  <h6>"{{activepropertytype.name}}" values <button v-on:click="addPropertyTypeValueButton"  class="btn btn-sm btn-primary btn-sm">Add +</button></h6>
  <div class="property-form" v-if="propertytypevalueform">
      <p>{{formtitle}}</p>
      <div class="container-fluid">
          <div class="row">
              <div class="col-sm-12 mb-3">
                  <label for="name">Property</label>
                  <input type="text" class="form-control" id="property" placeholder="" v-model="property">
                  <div class="input-error" v-if="msg.errors.property">
                      Valid property is required.
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12 mb-3">
                  <label for="description">Description</label>
                  <input type="text" class="form-control" id="description" placeholder="" v-model="description">
                  <div class="input-error" v-if="msg.errors.description">
                      Valid property Description is required.
                  </div>
              </div>
          </div>
          <div class="row" v-if="editingpropertytypevalue">
            <div class="col-sm-12 mb-3 deletecontrol text-right">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="deletepropertytypevalue" id="deletepropertytypevalue">
                <label class="form-check-label" for="deletepropertyvalue">
                  Delete this Property Value?
                </label>
              </div>
            </div>
          </div>
          <div class="row">
              <div class="col-sm-12 mb-3 text-left form-controls">
                  <button v-on:click="savePropertyTypeValueFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                  <button v-on:click="closePropertyTypeValueFormButton" class="btn btn-sm btn-danger">Close</button>
              </div>
          </div>
      </div>
  </div>
  <div v-if="'values' in propertytypevalues" class="properties-type-value-list">
      <table class="table table-striped">
        <thead class="thead-dark">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Property</th>
            <th scope="col">Description</th>
            <th scope="col">Edit</th>
          </tr>
        </thead>
        <tbody>
              <tr v-for="value in propertytypevalues.values" :key="value.id">
                <td>{{value.property_value_id}}</td>
                <td>{{value.property}}</td>
                <td>{{value.description}}</td>
                <td>
                  <button v-on:click="editPropertyTypeValueButton(value.property_value_id)" class="btn btn-sm btn-primary">Edit</button>
                </td>
              </tr>
        </tbody>
      </table>
  </div>
  <div v-else>
    No property values available.
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'PropertyValues',
  props: {
    editpropertytypevalues: {
      required: true
    }
  },
  data () {
    return {
      formtitle: 'Add new Property Value',
      formsavetext: 'Save',
      propertytypevalueform: false,
      property: '',
      description: '',
      editingpropertytypevalue: '',
      deletepropertytypevalue: false,
      msg: {
        errors: {
          property: false,
          description: false
        }
      }
    }
  },
  computed: {
    ...mapState({
      propertytypevalues: state => state.properties.allvalues,
      activepropertytypevalue: state => state.properties.activevalue,
      addpropertytypevalue: state => state.properties.addvalue,
      updatepropertytypevalue: state => state.properties.updatevalue,
      activepropertytype: state => state.properties.activetype
    })
  },
  created () {
    this.getPropertyTypeValues(this.editpropertytypevalues)
    this.getPropertyType(this.editpropertytypevalues)
  },
  watch: {
    activepropertytypevalue: {
      handler (val, oldVal) {
        this.property = val.property
        this.description = val.description
        this.editingpropertytypevalue = val.property_value_id
      }
    },
    property: {
      handler (val, oldVal) {
        this.validatePropertyTypeValueForm()
      }
    },
    description: {
      handler (val, oldVal) {
        this.validatePropertyTypeValueForm()
      }
    },
    addpropertytypevalue: {
      handler (val, oldVal) {
        if (val.property_value_id) {
          this.getPropertyTypeValues(this.editpropertytypevalues)
          this.PropertyTypeValueFormReset()
          this.closePropertyTypeValueFormButton()
        }
      }
    },
    updatepropertytypevalue: {
      handler (val, oldVal) {
        if (val.property_value_id) {
          this.getPropertyTypeValues(this.editpropertytypevalues)
          this.PropertyTypeValueFormReset()
          this.closePropertyTypeValueFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('properties', {
      getPropertyTypeValues: 'getAllTypeValues',
      getPropertyTypeValue: 'getValueById',
      updatePropertyTypeValue: 'updateValue',
      addPropertyTypeValue: 'addValue',
      getPropertyType: 'getTypeById'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    PropertyTypeValueFormReset (event) {
      this.formtitle = 'Add new Property Type Value'
      this.property = ''
      this.description = ''
      this.editingpropertytypevalue = ''
      this.editingpropertytypevalues = ''
      this.deletepropertytypevalue = false
    },
    closePropertyTypeValueFormButton (event) {
      this.propertytypevalueform = false
    },
    addPropertyTypeValueButton (event) {
      this.clearAlert()
      this.PropertyTypeValueFormReset()
      if (this.propertytypevalueform) {
        this.closePropertyTypeValueFormButton()
        return
      }
      this.propertytypevalueform = true
    },
    editPropertyTypeValueButton (id) {
      this.clearAlert()
      this.getPropertyTypeValue(id)
      this.propertytypevalueform = true
      this.formtitle = 'Edit Property Type Value'
    },
    validatePropertyTypeValueForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    savePropertyTypeValueFormButton (event) {
      this.clearAlert()
      if (this.validatePropertyTypeValueForm() === false) {
        return
      }

      if (this.editingpropertytypevalue) {
        const {
          editingpropertytypevalue,
          editpropertytypevalues,
          property,
          description,
          deletepropertytypevalue
        } = this
        this.updatePropertyTypeValue({
          editingpropertytypevalue,
          editpropertytypevalues,
          property,
          description,
          deletepropertytypevalue
        })
      } else {
        const {
          editpropertytypevalues,
          property,
          description
        } = this
        this.addPropertyTypeValue({
          editpropertytypevalues,
          property,
          description
        })
      }
    }
  }
}
</script>
