<template>
    <div class="getSetupManager container" :class="{ 'page' : !fullScreen }">
        <main role="main" :key="renderer">
            <component v-bind:is="component" :componentProps="childComponentProps" />
        </main>
        <div class="container-fluid">
          <div class="row">
            <img class="bottom-logo" src="@/assets/logo.png">
            <div class="btn-group m-2" role="group" style="position: fixed; bottom: 0; right: 0;">
                <a class="btn btn-sm btn-primary" v-on:click="openModal('devOptions')"><mdb-icon icon="cog" size="lg" /></a>
                <a class="btn btn-sm btn-danger" v-if="fullScreen" v-on:click="setFullScreen(false)"><mdb-icon icon="expand-arrows-alt" size="lg" /></a>
                <a class="btn btn-sm btn-success" v-if="!fullScreen" v-on:click="setFullScreen(true)"><mdb-icon icon="expand-arrows-alt" size="lg" /></a>
            </div>
          </div>
        </div>
        <div class="modal fade" id="devOptions" tabindex="-1" aria-labelledby="createMenuLabel" aria-hidden="true">
          <div class="modal-dialog" style="max-width: 80vw;">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Dev Options</h5>
                <button type="button" class="btn btn-sm btn-secondary" v-on:click="closeModal('devOptions')">Close</button>
              </div>
              <div class="modal-body">
                <p>Load Test Data</p>
                <div class="btn-group" role="group">
                    <button class="btn btn-sm btn-primary" v-on:click="loadTestData('single')">Single Restaurant</button>
                    <button class="btn btn-sm btn-primary" v-on:click="loadTestData('multiple')">Multiple Restaurants</button>
                    <button class="btn btn-sm btn-danger" v-on:click="loadTestData('reset')">Reset Data</button>
                </div>
                <hr>
                <p>Bread Crumbs</p>
                <pre>
                  {{breadCrumbs}}
                </pre>
                <hr>
                <p>Data Store</p>
                <pre>
                    {{getsetupv1}}
                </pre>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-secondary" v-on:click="closeModal('devOptions')">Close</button>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { mdbIcon } from 'mdbvue'
import $ from 'jquery'
import landing from './components/landing'
import restaurantsOperated from './components/1-restaurantsOperated'
import holdingCompany from './components/2-holdingCompany'
import holdingCompanyName from './components/2a-holdingCompanyName'
import brandsOperated from './components/3a-brandsOperated'
import brands from './components/3b-brands'
import restaurants from './components/4-restaurants'
import singlemenu from './components/5-menu'
import menus from './components/5a-menus'
import sections from './components/6-sections'
import items from './components/7-items'
import complete from './components/8-complete'

export default {
  name: 'GetSetupManager',
  components: { landing, restaurantsOperated, holdingCompany, holdingCompanyName, brandsOperated, brands, restaurants, singlemenu, menus, sections, items, complete, mdbIcon },
  data () {
    return {
      component: landing,
      breadCrumbs: ['landing'],
      childComponentProps: {},
      activeNav: 'landing',
      renderer: 1
    }
  },
  methods: {
    loadTestData (type) {
      this.importTestData(type)
      this.renderer++
      this.closeModal('devOptions')
    },
    openModal (modalId) {
      $('#' + modalId).modal('show')
    },
    closeModal (modalId) {
      $('#' + modalId).modal('hide')
    },
    uuid () {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      )
    },
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    ...mapActions('getsetupv1', {
      setFullScreen: 'setFullScreen',
      importTestData: 'importTestData',
      addItemImportDataToStore: 'addItemImportDataToStore',
      updateState: 'updateState',
      updateStateObject: 'updateStateObject',
      deleteStateArrayItem: 'deleteStateArrayItem',
      deleteSetupStateArrayItem: 'deleteSetupStateArrayItem',
      updateStateArrayItem: 'updateStateArrayItem',
      updateSetupStateArrayItem: 'updateSetupStateArrayItem',
      moveToTrash: 'moveToTrash',
      restoreFromTrash: 'restoreFromTrash'
    }),
    goToPrevComponent (props) {
      let componentName
      const breadCrumbLength = this.breadCrumbs.length
      if (breadCrumbLength === 2) {
        componentName = this.breadCrumbs[0]
        this.breadCrumbs = ['landing']
      } else {
        componentName = this.breadCrumbs[breadCrumbLength - 2]
        this.breadCrumbs.pop()
      }
      this.clearAlert()
      this.childComponentProps = { props }
      this.component = componentName
      this.activeNav = componentName
    },
    landingComponent (event) {
      this.clearAlert()
      this.childComponentProps = { }
      this.component = landing
      this.breadCrumbs = ['landing']
      this.activeNav = 'landing'
      this.breadCrumbs.push('landing')
    },
    restaurantsOperatedComponent (event) {
      this.clearAlert()
      this.childComponentProps = { }
      this.component = restaurantsOperated
      this.activeNav = 'restaurantsOperated'
      this.breadCrumbs.push('restaurantsOperated')
    },
    holdingCompanyComponent (event) {
      this.clearAlert()
      this.childComponentProps = { }
      this.component = holdingCompany
      this.activeNav = 'holdingCompany'
      this.breadCrumbs.push('holdingCompany')
    },
    holdingCompanyNameComponent (event) {
      this.clearAlert()
      this.childComponentProps = { }
      this.component = holdingCompanyName
      this.activeNav = 'holdingCompanyName'
      this.breadCrumbs.push('holdingCompanyName')
    },
    brandsOperatedComponent (event) {
      this.clearAlert()
      this.childComponentProps = { }
      this.component = brandsOperated
      this.activeNav = 'brandsOperated'
      this.breadCrumbs.push('brandsOperated')
    },
    brandsComponent (event) {
      this.clearAlert()
      this.childComponentProps = { }
      this.component = brands
      this.activeNav = 'brands'
      this.breadCrumbs.push('brands')
    },
    restaurantsComponent (event) {
      this.clearAlert()
      this.childComponentProps = { }
      this.component = restaurants
      this.activeNav = 'restaurants'
      this.breadCrumbs.push('restaurants')
    },
    menusComponent (event) {
      this.clearAlert()
      this.childComponentProps = { }
      this.component = menus
      this.activeNav = 'menus'
      this.breadCrumbs.push('menus')
    },
    menuComponent (event) {
      this.clearAlert()
      this.childComponentProps = { }
      this.component = singlemenu
      this.activeNav = 'singlemenu'
      this.breadCrumbs.push('singlemenu')
    },
    sectionsComponent (event) {
      this.clearAlert()
      this.childComponentProps = { }
      this.component = sections
      this.activeNav = 'sections'
      this.breadCrumbs.push('sections')
    },
    itemsComponent (event) {
      this.clearAlert()
      this.childComponentProps = { }
      this.component = items
      this.activeNav = 'items'
      this.breadCrumbs.push('items')
    },
    completeComponent (event) {
      this.clearAlert()
      this.childComponentProps = { }
      this.component = complete
      this.activeNav = 'complete'
      this.breadCrumbs.push('complete')
    }
  },
  created () {
    this.setFullScreen(true)
  },
  destroyed () {
    this.setFullScreen(false)
  },
  computed: {
    ...mapState({
      getsetupv1: state => state.getsetupv1,
      fullScreen: state => state.getsetupv1.fullScreen,
      setup: state => state.getsetupv1.setup,
      selectedRestaurantId: state => state.getsetupv1.selectedRestaurantId,
      selectedMenuId: state => state.getsetupv1.selectedMenuId,
      selectedSectionId: state => state.getsetupv1.selectedSectionId
    })
  }
}
</script>
<style lang="scss">
.getSetupManager {
  main {
    .component-container {
      width: 100%;
      min-height: 80vh!important;
      align-items: center!important;
      display: flex!important;
    }
  }
  @media (min-width: 768px) {
    .h-md-100 { height: 100vh; }
    .h-md-50  { height: 50vh; }
  }
  .bg-grazeblue {
    background: #13294B;
  }
  .bottom-logo {
    max-width: 150px;
    margin: 1rem;
    height: auto;
    position: fixed;
    bottom: 0;
  }
  .middle-divider {
    border-right: 1px solid rgb(125,185,153);
    border-image: linear-gradient(to bottom, rgba(30,87,153,1) 0%,rgba(89,148,202,1) 62%,rgba(95,154,207,0.7) 68%,rgba(125,185,232,0) 100%);
    border-image-slice: 1;
  }
}
.fullpage {
  .bottom-logo {
    left: 0;
  }
}
</style>
