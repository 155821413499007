export function resetData () {
  const data = {
    operator: [],
    brand: [],
    restaurant_group: [],
    restaurant: [],
    restaurant_sales_tax: {},
    menu: [],
    menu_section: [],
    menu_subsection: [],
    item: [],
    menu_item: [],
    menu_section_item: [],
    modifier_set: [],
    modifier: [],
    modifier_set_member: {},
    item_modifier_set: {},
    item_modifier: {},
    menu_item_modifier_set: {},
    menu_item_modifier: {},
    restOperationType: false,
    importData: [],
    importType: '',
    sortItemsBy: 'name',
    sortItemsAsc: true,
    fullScreen: false,
    activeBrand: {},
    activeRestaurant: '',
    activeMenu: '',
    activeSection: '',
    activeItem: '',
    activeMenuSectionItem: '',
    selectedRestaurantId: '',
    selectedMenuId: '',
    selectedSectionId: '',
    editingRestaurant: false,
    restaurantsOperated: '',
    holdingCompany: '',
    brandsOperated: ''
  }
  return data
}
