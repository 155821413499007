<template>
  <div class="component-container sections">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 middle-divider">
          <h1 class="mb-3">Is your "{{$parent.setup.menuName}}" menu divided into sections?</h1>
          <ul>
            <li>Most menus are divided into sections like breakfast, lunch and dinner.</li>
            <li>Add those sections now and arrange them in the order that works for you.</li>
            <li>If your menu has no sections just click "next" to add your food items to the menu.</li>
          </ul>
        </div>
        <div class="col-md-6">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <mdb-input class="mt-0 mb-3" placeholder="Fill in the name of a section to add." v-model="newSectionName" ariaDescribedBy="button-addon2">
                  <mdb-btn icon="plus-circle" color="success" size="md" group slot="append" id="button-addon2" v-on:click="addSection()">Add</mdb-btn>
                </mdb-input>
              </div>
            </div>
            <div class="row mb-3" v-for="(section, sectionIndex) of availableSections($parent.selectedMenuId)" v-bind:key="sectionIndex">
              <div class="col">
                <div class="card" style="width: 100%">
                  <div class="card-body">
                    <h5 class="card-title">
                      <div class="btn-group-vertical mr-3" role="group" aria-label="sectionOrder">
                        <button type="button" class="btn btn-primary mb-0 mt-0 p-0" v-on:click="moveMenuSection(section.id, $parent.selectedMenuId, -1)" :disabled="section.displayOrder === 1"><b-icon-chevron-up></b-icon-chevron-up></button>
                        <button type="button" class="btn btn-primary mb-0 mt-0 p-0" v-on:click="moveMenuSection(section.id, $parent.selectedMenuId, 1)" :disabled="section.displayOrder === availableSections($parent.selectedMenuId).length"><b-icon-chevron-down></b-icon-chevron-down></button>
                      </div>
                      {{section.name}}
                    </h5>
                    <p class="card-text text-right">
                      <button class="btn btn-sm btn-primary" v-on:click="editSectionName(section)">Change Name</button>
                      <button class="btn btn-sm btn-danger" v-on:click="deleteSectionConfirm(sectionIndex, section)">Remove</button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 text-left">
          <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.goToPrevComponent()"><mdb-icon icon="arrow-alt-circle-left" size='lg' /> Go Back</button>        </div>
        <div class="col-md-6 text-right">
           <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.itemsComponent()">Next <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
        </div>
      </div>
    </div>
    <div class="modal fade" id="sectionNameEditor" tabindex="-1" aria-labelledby="sectionNameEditorLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Edit the section name below and click "Save" when you are done.</p>
            <div class="grey-text">
              <mdb-input label="Edit the name of this section." v-model="editingSectionName"  icon="edit" type="text" class="p-0" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger float-left" v-on:click="closeSectionEdit()">Cancel</button>
            <button type="button" class="btn btn-success" v-on:click="saveSection()">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="sectionNameDelete" tabindex="-1" aria-labelledby="sectionNameDeleteLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Are you sure you want to delete this section? This is not reversable.</p>
            <p>Section Name: <b>{{deleteSection.name}}</b></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger float-left" v-on:click="closeSectionDelete()">No, I want to keep this section.</button>
            <button type="button" class="btn btn-success" v-on:click="removeSection()">Yes, I'm sure.</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mdbInput, mdbIcon, mdbBtn } from 'mdbvue'
export default {
  name: 'sections',
  components: { mdbInput, mdbIcon, mdbBtn },
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
      newSectionName: '',
      editingSectionName: '',
      editingSectionId: '',
      deleteSection: '',
      deleteSectionIndex: ''
    }
  },
  watch: {},
  methods: {
    addSection () {
      if (this.newSectionName === '') {
        return
      }
      const section = {
        id: this.$parent.uuid(),
        restId: this.$parent.selectedRestaurantId,
        menuId: this.$parent.selectedMenuId,
        name: this.newSectionName,
        displayOrder: this.availableSections(this.$parent.selectedMenuId).length + 1
      }
      this.$parent.setup.sections.push(section)
      this.newSectionName = ''
    },
    availableSections (menuId) {
      return this.$parent.setup.sections
        .filter(s => s.menuId === menuId)
        .sort((a, b) => a.displayOrder - b.displayOrder)
    },
    moveMenuSection (sectionId, menuId, delta) {
      const sections = JSON.parse(JSON.stringify(this.availableSections(menuId)))
      const sectionLength = sections.length
      for (var index = 0; index < sectionLength; index++) {
        if (sections[index].id === sectionId) {
          break
        }
      }
      var newIndex = index + delta
      if (newIndex < 0 || newIndex === sectionLength) return

      var indexes = [index, newIndex].sort((a, b) => a - b)
      sections.splice(indexes[0], 2, sections[indexes[1]], sections[indexes[0]])
      var displayOrder = 1
      for (var sIndex = 0; sIndex < sectionLength; sIndex++) {
        if (sections[sIndex] !== undefined) {
          const data = {
            name: 'sections',
            keyName: 'id',
            keyValue: sections[sIndex].id,
            updateKey: 'displayOrder',
            updateKeyValue: displayOrder
          }
          this.$parent.updateSetupStateArrayItem(data)
          displayOrder = displayOrder + 1
        }
      }
    },
    editSectionName (section) {
      this.editingSectionName = section.name
      this.editingSectionId = section.id
      this.$parent.openModal('sectionNameEditor')
    },
    closeSectionEdit () {
      this.$parent.closeModal('sectionNameEditor')
      this.editingSectionName = ''
      this.editingSectionId = ''
    },
    saveSection () {
      const data = {
        name: 'sections',
        keyName: 'id',
        keyValue: this.editingSectionId,
        updateKey: 'name',
        updateKeyValue: this.editingSectionName
      }
      this.$parent.updateSetupStateArrayItem(data)
      this.closeSectionEdit()
    },
    removeSection () {
      const data = JSON.parse(JSON.stringify(this.deleteSection))
      const trashItem = {
        id: this.$parent.uuid(),
        location: ['setup', 'sections'],
        value: data
      }
      this.$parent.moveToTrash(trashItem)
      this.$delete(this.$parent.setup.sections, this.deleteSectionIndex)
      this.$parent.closeModal('sectionNameDelete')
    },
    deleteSectionConfirm (index, section) {
      this.deleteSection = section
      this.deleteSectionIndex = index
      this.$parent.openModal('sectionNameDelete')
    },
    closeSectionDelete () {
      this.deleteSection = ''
      this.deleteSectionIndex = ''
      this.$parent.closeModal('sectionNameDelete')
    },
    getSections () {
      return this.$parent.setup.sections
    }
  },
  computed: {}
}
</script>
