import { authHeader, apiUrl, handleResponse } from '../_helpers'

export const salesTaxRatesService = {
  getAll,
  getById,
  add,
  update
}

function getAll (restGroupId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'salestaxrate',
      data: {
        action: 'getAll',
        restGroupId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'salestaxrate',
      data: {
        action: 'getSalesTaxRate',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function add (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'salestaxrate',
      data: {
        action: 'addSalesTaxRate',
        restGroupId: data.restGroupId,
        name: data.name,
        description: data.description,
        taxRate: data.taxRate,
        city: data.city,
        subregion: data.subregion,
        province: data.province,
        postalCode: data.postalCode,
        country: data.country
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function update (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'salestaxrate',
      data: {
        action: 'updateSalesTaxRate',
        salesTaxRateId: data.editingsalestaxrate,
        restGroupId: data.restGroupId,
        name: data.name,
        description: data.description,
        taxRate: data.taxRate,
        city: data.city,
        subregion: data.subregion,
        province: data.province,
        postalCode: data.postalCode,
        country: data.country,
        deleteSalesTaxRate: data.deleteSalesTaxRate
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}
