<template>
    <div class="side-nav">
      <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>User Starting Points</span>
      </h6>
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link class="nav-link" to="/getsetupv1">Get Setup (Beginner)</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/ownerportalv1">Owner Portal (Savvy)</router-link>
        </li>
      </ul>
      <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>Admin</span>
      </h6>
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link class="nav-link" to="/">Restaurant Groups</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/operators">Operators</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/brands">Brands</router-link>
        </li>
      </ul>
      <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>Archive</span>
      </h6>
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link class="nav-link" to="/menusetupv2">Menu Setup v2</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/menusetup">Menu Setup</router-link>
        </li>
      </ul>
      <div class="admin-nav" v-if="account.user.type === 'admin'">
        <ul class="nav flex-column">
          <li class="nav-item">
            <router-link class="nav-link" to="/users">Users</router-link>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SideNav',
  computed: {
    ...mapState({
      account: state => state.account
    })
  }
}
</script>

<style scoped>
</style>
