<template>
    <div class="modifier-sets" id="modifier-sets-list">
        <h6>Modifier Sets <button v-on:click="addModifierSetButton"  class="btn btn-sm btn-primary btn-sm">Add +</button></h6>
        <div class="modifierset-form" v-if="modifiersetform">
            <p>{{formtitle}}</p>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 mb-3">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" id="name" placeholder="" v-model="modifiersetname">
                        <div class="input-error" v-if="msg.errors.modifiersetname">
                            Valid modifier set name is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mb-3">
                        <label for="description">Description</label>
                        <input type="text" class="form-control" id="description" placeholder="" v-model="modifiersetdescription">
                        <div class="input-error" v-if="msg.errors.modifiersetdescription">
                            Valid modifier set Description is required.
                        </div>
                    </div>
                </div>
                <div class="row" v-if="editingmodifierset">
                  <div class="col-sm-12 mb-3 deletecontrol text-right">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="deletemodifierset" id="deletemodifierset">
                      <label class="form-check-label" for="deletemodifierset">
                        Delete this Modifier Set?
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mb-3 text-left form-controls">
                        <button v-on:click="saveModifierSetFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                        <button v-on:click="closeModifierSetFormButton" class="btn btn-sm btn-danger">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="'sets' in groupmodifiersets" class="modifiersets-list">
            <table class="table table-striped">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Edit</th>
                </tr>
              </thead>
              <tbody>
                    <tr v-for="modifierset in groupmodifiersets.sets"
                        :key="modifierset.modifier_set_id"
                        :class="modifierset.modifier_set_id === editmodifiersetmembers ? 'green-border' : 'no-border'">
                      <td>{{modifierset.modifier_set_id}}</td>
                      <td>{{modifierset.name}}</td>
                      <td>{{modifierset.description}}</td>
                      <td>
                        <p><button v-on:click="editModifierSetMembersButton(modifierset.modifier_set_id)" class="btn btn-block btn-sm btn-primary">Members</button></p>
                        <p><button v-on:click="editModifierSetButton(modifierset.modifier_set_id)" class="btn btn-block btn-sm btn-primary">Details</button></p>
                      </td>
                    </tr>
              </tbody>
            </table>
        </div>
        <div v-else>
          No modifier sets available.
        </div>
    </div>
</template>
<style lang="scss" scoped>
  .modifiersets-list {
    .green-border {
      border: 3px solid green;
    }
  }
</style>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ModifierSets',
  data () {
    return {
      formtitle: 'Add new Modifier Set',
      formsavetext: 'Save',
      modifiersetform: false,
      restgroupid: this.$route.params.id,
      modifiersetname: '',
      modifiersetdescription: '',
      editingmodifierset: '',
      editingmodifiersetmembers: '',
      deletemodifierset: false,
      msg: {
        errors: {
          modifiersetname: false,
          modifiersetdescription: false
        }
      }
    }
  },
  computed: {
    ...mapState({
      groupmodifiersets: state => state.modifiers.allsets,
      activemodifierset: state => state.modifiers.activemodifierset,
      addmodifierset: state => state.modifiers.addmodifierset,
      updatemodifierset: state => state.modifiers.updatemodifierset,
      editmodifiersetmembers: state => state.modifiers.editmodifiersetmembers
    })
  },
  created () {
    this.getModifierSets(this.restgroupid)
  },
  watch: {
    activemodifierset: {
      handler (val, oldVal) {
        this.modifiersetname = val.name
        this.modifiersetdescription = val.description
        this.editingmodifierset = val.modifier_set_id
      }
    },
    modifiersetname: {
      handler (val, oldVal) {
        this.validateModifierSetForm()
      }
    },
    modifiersetdescription: {
      handler (val, oldVal) {
        this.validateModifierSetForm()
      }
    },
    addmodifierset: {
      handler (val, oldVal) {
        if (val.modifier_set_id) {
          this.getModifierSets(this.restgroupid)
          this.ModifierSetFormReset()
          this.closeModifierSetFormButton()
        }
      }
    },
    updatemodifierset: {
      handler (val, oldVal) {
        if (val.modifier_set_id) {
          this.getModifierSets(this.restgroupid)
          this.ModifierSetFormReset()
          this.closeModifierSetFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('modifiers', {
      getModifierSets: 'getAllSets',
      getModifierSet: 'getSetById',
      updateModifierSet: 'updateSet',
      addModifierSet: 'addSet',
      editModifierSetMembers: 'editModifierSetMembers'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    scrollToTop (elementId = null) {
      var top = 0
      if (elementId) {
        var element = document.getElementById(elementId)
        top = element.offsetTop
      }
      window.scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
      })
    },
    ModifierSetFormReset (event) {
      this.formtitle = 'Add new Modifier Set'
      this.modifiersetname = ''
      this.modifiersetdescription = ''
      this.editingmodifierset = ''
      this.deletemodifierset = false
    },
    closeModifierSetFormButton (event) {
      this.modifiersetform = false
    },
    addModifierSetButton (event) {
      this.clearAlert()
      this.ModifierSetFormReset()
      if (this.modifiersetform) {
        this.closeModifierSetFormButton()
        return
      }
      this.modifiersetform = true
    },
    editModifierSetMembersButton (setId) {
      this.ModifierSetFormReset()
      this.clearAlert()
      this.closeModifierSetFormButton()
      const data = { setId: setId, action: 'view' }
      this.editModifierSetMembers(data)
      this.scrollToTop()
    },
    editModifierSetButton (id) {
      this.clearAlert()
      this.getModifierSet(id)
      this.modifiersetform = true
      this.formtitle = 'Edit Modifier Set'
      this.scrollToTop('modifier-sets-list')
    },
    validateModifierSetForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    saveModifierSetFormButton (event) {
      this.clearAlert()
      if (this.validateModifierSetForm() === false) {
        return
      }

      if (this.editingmodifierset) {
        const {
          restgroupid,
          editingmodifierset,
          modifiersetname,
          modifiersetdescription,
          deletemodifierset
        } = this
        this.updateModifierSet({
          editingmodifierset,
          modifiersetname,
          modifiersetdescription,
          restgroupid,
          deletemodifierset
        })
      } else {
        const {
          modifiersetname,
          modifiersetdescription,
          restgroupid
        } = this
        this.addModifierSet({
          modifiersetname,
          modifiersetdescription,
          restgroupid
        })
      }
    }
  }
}
</script>
