<template>
  <div class="component-container restaurants">
    <div class="container-fluid">
      <div class="row" v-if="$parent.setup.restaurantsOperated === 1">
        <div class="col-md-6 middle-divider">
          <h1 class="mb-3">What is the name of your restaurant?</h1>
        </div>
        <div class="col-md-6">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                 <div class="grey-text">
                    <mdb-input label="Fill in the name of your restaurant." v-model="$parent.setup.restaurantName" icon="edit" type="text" class="p-0" />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="$parent.setup.restaurantsOperated === 2">
        <div class="col-md-6 middle-divider">
          <h1 class="mb-3">What are the names of your restaurants?</h1>
          <ul>
            <li>Fill in the name of one of your restaurants and click <mdb-btn v-if="getBrands().length > 1" icon="plus-circle" color="success" size="sm" group slot="append" id="button-addon2" v-on:click="assignRestaurantBrand()">Add</mdb-btn><mdb-btn v-else icon="plus-circle" color="success" size="sm" group slot="append" id="button-addon2" v-on:click="addRestaurant()">Add</mdb-btn></li>
            <li>You can start with one (recommended) and add more later or add them all now.</li>
          </ul>
        </div>
        <div class="col-md-6">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <mdb-input class="mt-0 mb-3" placeholder="Fill in the name of a restaurant." v-model="newRestaurantName" ariaDescribedBy="button-addon2">
                  <mdb-btn v-if="getBrands().length > 1" icon="plus-circle" color="success" size="md" group slot="append" id="button-addon2" v-on:click="assignRestaurantBrand()">Add</mdb-btn>
                  <mdb-btn v-else icon="plus-circle" color="success" size="md" group slot="append" id="button-addon2" v-on:click="addRestaurant()">Add</mdb-btn>
                </mdb-input>
              </div>
            </div>
            <div class="row mb-3" v-for="(restaurant, restIndex) of getRestaurants()" v-bind:key="restIndex">
              <div class="col">
                <div class="card" style="width: 100%">
                  <div class="card-body">
                    <h5 class="card-title">{{restaurant.name}}</h5>
                    <p class="card-text" v-if="restaurant.brandId.length">Brand: {{getBrandNameById(restaurant.brandId)}}</p>
                    <p class="card-text text-right">
                      <button v-if="restaurant.brandId.length && getBrands().length > 1" class="btn btn-sm btn-primary" v-on:click="editRestaurantBrand(restaurant)">Change Brand</button>
                      <button v-if="restaurant.brandId.length === 0 && getBrands().length > 1" class="btn btn-sm btn-primary" v-on:click="editRestaurantBrand(restaurant)">Assign Brand</button>
                      <button class="btn btn-sm btn-primary" v-on:click="editRestaurantName(restaurant)">Change Name</button>
                      <button class="btn btn-sm btn-danger" v-on:click="deleteRestConfirm(restIndex, restaurant)">Remove</button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 text-left">
          <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.goToPrevComponent()"><mdb-icon icon="arrow-alt-circle-left" size='lg' /> Go Back</button>
        </div>
        <div class="col-md-6 text-right" v-if="$parent.setup.restaurantsOperated === 1 && $parent.setup.restaurantName">
           <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="singleRestaurant()">Next Question <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
        </div>
        <div class="col-md-6 text-right" v-if="getRestaurants().length && $parent.setup.restaurantsOperated === 2">
           <button type="button" class="btn btn-primary btn-lg px-3" v-on:click="$parent.menusComponent()">Next Question <mdb-icon icon="arrow-alt-circle-right" size='lg' /></button>
        </div>
      </div>
    </div>
    <div class="modal fade" id="brandEditor" tabindex="-1" aria-labelledby="brandEditorLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p><b>You have multiple brands available. Do you want to assign the <b>{{newRestaurantName}}</b> restaurant to one of your brands at this time?</b></p>
            <button type="button"
              class="btn btn-lg y-3 px-3"
              :class="{ 'btn-success' : assignBrandNow === 1, 'btn-primary' : assignBrandNow !== 1 }"
              v-on:click="assignBrand(1)">
              <mdb-icon v-if="assignBrandNow === 1" far icon="check-square" size='lg' />
              <mdb-icon v-else far icon="square" size='lg' /> Yes
            </button>
            <button type="button"
              class="btn btn-lg y-3 px-3"
              :class="{ 'btn-success' : assignBrandNow === 2, 'btn-primary' : assignBrandNow !== 2 }"
              v-on:click="assignBrand(2)">
              <mdb-icon v-if="assignBrandNow === 2" far icon="check-square" size='lg' />
              <mdb-icon v-else far icon="square" size='lg' /> No I'll do this later.
            </button>
            <div class="form-row align-items-center mt-3 mb-2" v-if="assignBrandNow === 1">
              <div class="col">
                <label>Which one of your Brands should <b>{{newRestaurantName}}</b> be assigned too? (You can change this later)</label>
                <select class="form-control" :value="selectedBrandId" @change="brandSelectOnChange($event)">
                  <option value="">-- Select a Brand --</option>
                  <option v-for="(brand, brandIndex) of getBrands()" v-bind:key="brandIndex" :value="brand.id">
                    {{brand.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="assignBrandNow === 2" >
            <p>Not a problem! You can assign this restaurant to one of your brands later if you choose. Click "Save" to add <b>{{newRestaurantName}}</b> to your restaurant list.</p>
            <button type="button" class="btn btn-success" v-on:click="addRestaurant()">Save</button>
          </div>
          <div class="modal-footer" v-if="assignBrandNow === 1 && selectedBrandId" >
            <p>Good job! You have selected a brand. Click "Save" to add <b>{{newRestaurantName}}</b> to your restaurant list.</p>
            <button type="button" class="btn btn-success" v-on:click="addRestaurant()">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="restNameEditor" tabindex="-1" aria-labelledby="restNameEditorLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Edit the restaurant name below and click "Save" when you are done.</p>
            <div class="grey-text">
              <mdb-input label="Edit the name of this restaurant." v-model="editingRestName"  icon="edit" type="text" class="p-0" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger float-left" v-on:click="closeRestaurantNameEdit()">Cancel</button>
            <button type="button" class="btn btn-success" v-on:click="saveRestaurantName()">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="restBrandEditor" tabindex="-1" aria-labelledby="restBrandEditorLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-row align-items-center mt-3 mb-2">
              <div class="col">
                <label>Change which brand <b>{{editingRestName}}</b> is assigned too. Use the drop down below to select one. Click "Save" when done.</label>
                <select class="form-control" :value="selectedBrandId" @change="brandSelectOnChange($event)">
                  <option value="">-- No Brand Assignment --</option>
                  <option v-for="(brand, brandIndex) of getBrands()" v-bind:key="brandIndex" :value="brand.id">
                    {{brand.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger float-left" v-on:click="closeRestaurantBrandEdit()">Cancel</button>
            <button type="button" class="btn btn-success" v-on:click="saveRestaurantBrand()">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="restNameDelete" tabindex="-1" aria-labelledby="restNameDeleteLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Are you sure you want to delete this restaurant? This is not reversable.</p>
            <p>Restaurant Name: <b>{{deleteRest.name}}</b></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger float-left" v-on:click="closeRestDelete()">No, I want to keep this restaurant.</button>
            <button type="button" class="btn btn-success" v-on:click="removeRestaurant()">Yes, I'm sure.</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mdbInput, mdbIcon, mdbBtn } from 'mdbvue'
export default {
  name: 'restaurants',
  components: { mdbInput, mdbIcon, mdbBtn },
  props: {
    componentProps: {
      required: true
    }
  },
  data () {
    return {
      newRestaurantName: '',
      selectedBrandId: '',
      assignBrandNow: 0,
      editingRestName: '',
      editingRestId: '',
      deleteRest: '',
      deleteRestIndex: ''
    }
  },
  watch: {},
  methods: {
    assignRestaurantBrand () {
      this.assignBrandNow = 0
      this.selectedBrandId = ''
      if (this.newRestaurantName === '') {
        return
      }
      this.$parent.openModal('brandEditor')
    },
    singleRestaurant () {
      const selectedRestaurantId = this.$parent.selectedRestaurantId
      const restName = this.$parent.setup.restaurantName
      if (selectedRestaurantId) {
        const data = {
          name: 'restaurants',
          keyName: 'id',
          keyValue: selectedRestaurantId,
          updateKey: 'name',
          updateKeyValue: restName
        }
        this.$parent.updateSetupStateArrayItem(data)
      } else {
        const restId = this.$parent.uuid()
        const restaurant = {
          id: restId,
          name: restName,
          brandId: ''
        }
        this.$parent.updateState({ name: 'selectedRestaurantId', value: restId })
        this.saveRestaurant(restaurant)
      }
      this.$parent.menuComponent()
    },
    addRestaurant () {
      let brandId = ''
      if (this.newRestaurantName === '') {
        return
      }
      const brands = this.getBrands()
      if (brands.length === 1) {
        brandId = brands[0].id
      }
      if (this.selectedBrandId) {
        brandId = this.selectedBrandId
      }
      const restaurant = {
        id: this.$parent.uuid(),
        name: this.newRestaurantName,
        brandId: brandId
      }
      this.saveRestaurant(restaurant)
    },
    editRestaurantName (restaurant) {
      this.editingRestName = restaurant.name
      this.editingRestId = restaurant.id
      this.$parent.openModal('restNameEditor')
    },
    closeRestaurantNameEdit () {
      this.$parent.closeModal('restNameEditor')
      this.editingRestName = ''
      this.editingRestId = ''
    },
    saveRestaurantName () {
      const data = {
        name: 'restaurants',
        keyName: 'id',
        keyValue: this.editingRestId,
        updateKey: 'name',
        updateKeyValue: this.editingRestName
      }
      this.$parent.updateSetupStateArrayItem(data)
      this.closeRestaurantNameEdit()
    },
    editRestaurantBrand (restaurant) {
      this.editingRestName = restaurant.name
      this.editingRestId = restaurant.id
      this.selectedBrandId = restaurant.brandId
      this.$parent.openModal('restBrandEditor')
    },
    closeRestaurantBrandEdit () {
      this.$parent.closeModal('restBrandEditor')
      this.editingRestName = ''
      this.editingRestId = ''
      this.selectedBrandId = ''
    },
    saveRestaurantBrand () {
      const data = {
        name: 'restaurants',
        keyName: 'id',
        keyValue: this.editingRestId,
        updateKey: 'brandId',
        updateKeyValue: this.selectedBrandId
      }
      this.$parent.updateSetupStateArrayItem(data)
      this.closeRestaurantBrandEdit()
    },
    assignBrand (value) {
      if (value === 2) {
        this.selectedBrandId = ''
      }
      if (value === this.assignBrandNow) {
        this.assignBrandNow = ''
        return
      }
      this.assignBrandNow = value
    },
    saveRestaurant (restaurant) {
      this.$parent.setup.restaurants.push(restaurant)
      this.newRestaurantName = ''
      this.assignBrandNow = 0
      this.selectedBrandId = ''
      this.$parent.closeModal('brandEditor')
    },
    removeRestaurant () {
      const data = JSON.parse(JSON.stringify(this.deleteRest))
      const trashItem = {
        id: this.$parent.uuid(),
        location: ['setup', 'restaurants'],
        value: data
      }
      this.$parent.moveToTrash(trashItem)
      this.$delete(this.$parent.setup.restaurants, this.deleteRestIndex)
      this.$parent.closeModal('restNameDelete')
    },
    deleteRestConfirm (index, restaurant) {
      this.deleteRest = restaurant
      this.deleteRestIndex = index
      this.$parent.openModal('restNameDelete')
    },
    closeRestDelete () {
      this.deleteRest = ''
      this.deleteRestIndex = ''
      this.$parent.closeModal('restNameDelete')
    },
    getRestaurants () {
      return this.$parent.setup.restaurants
    },
    setTheBrand (value) {
      this.setTheBrandNow = value
    },
    getBrands () {
      return this.$parent.setup.brands
    },
    getBrandNameById (brandId) {
      const brand = this.$parent.setup.brands.filter(b => b.id === brandId)
      if (brand.length === 1) {
        return brand[0].name
      }
    },
    brandSelectOnChange (event) {
      this.selectedBrandId = event.target.value
    }
  },
  computed: {}
}
</script>
