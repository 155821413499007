// import { menusSetupServicev2 } from '../_services'
import { singleRestaurant } from './testData/singleRestaurantv2.js'
import { multipleRestaurants } from './testData/multipleRestaurantsv2.js'
import { resetData } from './testData/resetDatav2.js'

const state = {
  operator: [],
  brand: [],
  restaurant_group: [],
  restaurant: [],
  restaurant_sales_tax: {},
  menu: [],
  menu_section: [],
  menu_subsection: [],
  item: [],
  menu_item: [],
  menu_section_item: [],
  modifier_set: [],
  modifier: [],
  modifier_set_member: {},
  item_modifier_set: {},
  item_modifier: {},
  menu_item_modifier_set: {},
  menu_item_modifier: {},
  restOperationType: false,
  importData: [],
  importType: '',
  sortItemsBy: 'name',
  sortItemsAsc: true,
  fullScreen: false,
  activeBrand: {},
  activeRestaurant: '',
  activeMenu: '',
  activeSection: '',
  activeItem: '',
  activeMenuSectionItem: '',
  selectedRestaurantId: '',
  selectedMenuId: '',
  selectedSectionId: '',
  editingRestaurant: false,
  restaurantsOperated: '',
  holdingCompany: '',
  brandsOperated: ''
}

const actions = {
  updateState ({ commit }, data) {
    const stateName = 'updateState'
    commit('request', { stateName: stateName })
    commit(stateName, data)
  },
  updateStateArrayItem ({ commit }, data) {
    const stateName = 'updateStateArrayItem'
    commit('request', { stateName: stateName })
    commit(stateName, data)
  },
  deleteStateArrayItem ({ commit }, data) {
    const stateName = 'deleteStateArrayItem'
    commit('request', { stateName: stateName })
    commit(stateName, data)
  },
  setFullScreen ({ commit }, mode) {
    const stateName = 'setFullScreen'
    commit('request', { stateName: stateName })
    commit(stateName, mode)
  },
  importTestData ({ commit }, type) {
    const stateName = 'importTestData'
    commit('request', { stateName: stateName })
    var data = {}
    if (type === 'single') {
      data = singleRestaurant()
    }
    if (type === 'multiple') {
      data = multipleRestaurants()
    }
    if (type === 'reset') {
      data = resetData()
    }
    commit(stateName, data)
  },
  addItemImportDataToStore ({ commit }, items) {
    const stateName = 'addItemImportDataToStore'
    commit('request', { stateName: stateName })
    commit(stateName, items)
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  updateState (state, data) {
    const value = data.value
    const stateName = data.name
    state[stateName] = value
  },
  deleteStateArrayItem (state, data) {
    let items
    const stateName = data.name
    const keyName = data.keyName
    const keyValue = data.keyValue
    items = state[stateName].length
    for (var i = 0; i < items; i++) {
      if (state[stateName][i][keyName] === keyValue) {
        state[stateName].splice(i, 1)
        items = state[stateName].length
        i = -1
      }
    }
  },
  updateStateArrayItem (state, data) {
    const stateName = data.name
    const keyName = data.keyName
    const keyValue = data.keyValue
    const updateKey = data.updateKey
    const updateKeyValue = data.updateKeyValue
    const items = state[stateName].length
    for (var i = 0; i < items; i++) {
      if (state[stateName][i][keyName] === keyValue) {
        if (updateKey === 'element') {
          state[stateName][i] = updateKeyValue
        } else {
          state[stateName][i][updateKey] = updateKeyValue
        }
      }
    }
  },
  setFullScreen (state, mode) {
    state.fullScreen = mode
  },
  importTestData (state, data) {
    Object.keys(data).forEach(function (key) {
      // console.table('Key : ' + key + ', Value : ' + data[key])
      state[key] = data[key]
    })
    state.importTestData = false
  }
}

export const menussetupv2 = {
  namespaced: true,
  state,
  actions,
  mutations
}
