<template>
    <div class="menusetup-child-component importData">
      <div class="child-component-header mb-4">
          <div class="container-fluid">
            <div class="row">
              <div class="col-2">
                <h5>Items</h5>
                <ul>
                  <li v-for="(item, key) in items" :key="key + '-' + item.itemId">
                    {{ item.name }}
                  </li>
                </ul>
                <h5>Sections</h5>
                <ul>
                  <li v-for="(section, key) in menuSections" :key="key + '-' + section.menuSectionId">
                    {{ section.name }}
                  </li>
                </ul>
              </div>
              <div class="col-10">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-2">
                      <div class="form-group">
                        <div
                          class="btn-group-vertical buttons"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button class="btn btn-secondary" @click="add">Add</button>
                          <button class="btn btn-secondary" @click="replace">Replace</button>
                        </div>

                        <div class="form-check">
                          <input
                            id="disabled"
                            type="checkbox"
                            v-model="enabled"
                            class="form-check-input"
                          />
                          <label class="form-check-label" for="disabled">DnD enabled</label>
                        </div>
                      </div>
                    </div>

                    <div class="col-6">
                      <h3>Draggable {{ draggingInfo }}</h3>

                      <draggable
                        :list="list"
                        :disabled="!enabled"
                        class="list-group"
                        ghost-class="ghost"
                        :move="checkMove"
                        @start="dragging = true"
                        @end="dragging = false"
                      >
                        <div
                          class="list-group-item"
                          v-for="element in list"
                          :key="element.name"
                        >
                          {{ element.name }}
                        </div>
                      </draggable>
                    </div>

                    <pre>
                      {{ rawData(list) }}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
</template>
<style lang="scss" scoped>
  .buttons {
    margin-top: 35px;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
</style>
<script>

import draggable from 'vuedraggable'
import { mapState } from 'vuex'
let id = 1
export default {
  name: 'editor',
  display: 'Editor',
  order: 0,
  components: {
    draggable
  },
  props: {
    componentProps: {
      required: true
    }
  },
  watch: {},
  data () {
    return {
      enabled: true,
      list: [
        { name: 'John', id: 0 },
        { name: 'Joao', id: 1 },
        { name: 'Jean', id: 2 }
      ],
      dragging: false
    }
  },
  methods: {
    add () {
      this.list.push({ name: 'Juan ' + id, id: id++ })
    },
    replace () {
      this.list = [{ name: 'Edgard', id: id++ }]
    },
    checkMove (e) {
      window.console.log('Future index: ' + e.draggedContext.futureIndex)
    },
    rawData (value) {
      return JSON.stringify(value, null, 2)
    }
  },
  computed: {
    draggingInfo () {
      return this.dragging ? 'under drag' : ''
    },
    ...mapState({
      restaurants: state => state.menussetup.restaurant,
      items: state => state.menussetup.item,
      menus: state => state.menussetup.menu,
      menuSections: state => state.menussetup.menu_section
    })
  }
}
</script>
