import { authHeader, apiUrl, handleResponse } from '../_helpers'

export const menuSectionsService = {
  getAll,
  getActive,
  getById,
  add,
  update
}

function getAll (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu/section',
      data: {
        action: 'getAll',
        restGroupId: data.restGroupId,
        restId: data.restId,
        menuId: data.menuId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getActive (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu/section',
      data: {
        action: 'getActive',
        restGroupId: data.restGroupId,
        restId: data.restId,
        menuId: data.menuId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu/section',
      data: {
        action: 'getMenuSection',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function add (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu/section',
      data: {
        action: 'addMenuSection',
        menuId: data.menuId,
        name: data.name,
        description: data.description,
        displayOrder: data.displayOrder,
        isActive: data.isActive,
        isTemporary: data.isTemporary,
        hideName: data.hideName
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function update (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu/section',
      data: {
        action: 'updateMenuSection',
        sectionId: data.sectionId,
        menuId: data.menuId,
        name: data.name,
        description: data.description,
        displayOrder: data.displayOrder,
        isActive: data.isActive,
        isTemporary: data.isTemporary,
        hideName: data.hideName,
        deleteMenuSection: data.deleteMenuSection
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}
