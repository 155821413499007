<template>
    <div class="menusetup-child-component application-of-modifiers">
        <div class="child-component-header">
           <h6>Application of Modifiers</h6>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              This could be combined with the 4th step, but I think that it is involved enough to merit breaking out separately.  Essentially, Joe would associate with Modifiers/Modifier_Sets apply to which Items.  After this, he would apply to Menu_Items.  The tables that would be populated here are:
              Item_Modifier_Set
              Item_Modifier
              Menu_Item_Modifier_Set
              Menu__Item_Modifier
            </div>
          </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'applicationOfModifiers',
  data () {
    return {
    }
  }
}
</script>
