<template>
    <div class="menusetup-child-component menus">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="jumbotron p-4">
                <h1 class="display-6">Menus <a class="btn btn-primary btn-sm" v-on:click="openModal('createmenu')">Create a Menu</a></h1>
                <p>Create a menu to organize your food offerings into sections.</p>
                <hr class="my-4">
                <div class="container-fluid">
                  <div v-for="menus in menu" :key="menus.menuId">
                    <div class="form-row align-items-center mb-2" v-if="!activeMenu || activeMenu === menus.menuId">
                        <div class="col-auto">
                          <button class="btn btn-sm btn-primary" v-if="!activeMenu || onlyOneMenu()" v-on:click="showMenuSections(menus.menuId)">Add Sections</button>
                          <button class="btn btn-sm btn-success" v-if="activeMenu === menus.menuId && onlyOneMenu() === false" v-on:click="closeMenuSections()">Done</button>
                        </div>
                        <div class="col">
                          <input type="text" class="form-control" placeholder="Menu Name" v-model="menus.name">
                        </div>
                        <div class="col">
                          <input type="text" class="form-control" placeholder="Menu Description" v-model="menus.description">
                        </div>
                        <div class="col-auto">
                          <button class='btn btn-sm btn-danger' v-on:click="deleteMenu(menus.menuId)"> x Remove</button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal fade" id="createmenu" tabindex="-1" aria-labelledby="createmenuLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Create a Menu</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-row align-items-center mb-2">
                    <label>Restaurant</label>
                    <select class="form-control" v-model="createNewMenu.restId" :disabled="restSelect() === false">
                      <option disabled value="" v-if="restSelect()">Select a restaurant</option>
                      <option :value="restaurants.restId" v-for="restaurants in restaurant" :key="restaurants.restId">
                        <span v-if="restaurants.name.length">{{restaurants.name}}</span>
                        <span v-else>{{restaurants.restId}}</span>
                      </option>
                    </select>
                  </div>
                  <div class="form-row align-items-center mb-2">
                    <div class="col">
                      <label>Menu Name</label>
                      <input type="text" class="form-control" placeholder="Menu Name" v-model="createNewMenu.name">
                    </div>
                  </div>
                  <div class="form-row align-items-center mb-2">
                    <div class="col">
                      <label>Menu Description</label>
                      <input type="text" class="form-control" placeholder="Menu Description" v-model="createNewMenu.description">
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" v-on:click="closeModal('createmenu')">Close</button>
                  <button type="button" class="btn btn-primary" v-on:click="addMenu()">Create this Menu</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="(activeMenu && activeMenu !== 0) || onlyOneMenu()">
            <div class="col">
              <div class="jumbotron p-4">
                <h4 class="display-6">Sections
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-primary btn-sm" role="button" v-on:click="addMenuSection(activeMenu)">Add New Section</button>
                    <button type="button" class="btn btn-sm btn-danger" v-if="onlyOneMenu() === false" v-on:click="closeMenuSections()">Close</button>
                  </div>
                </h4>
                <hr class="my-4">
                <div class="container-fluid">
                  <div v-for="(item, key) in menuSection" :key="key + '-' + item.menuSectionId">
                    <div class="form-row align-items-center mb-2" v-if="item.menuId === activeMenu">
                        <div class="col-auto">
                          <div class="btn-group" role="group" aria-label="editingMenu">
                            <button type="button" class="btn btn-primary" v-on:click="moveMenuSection(item.menuSectionId, -1)" :disabled="firstSectionDisplayed === key">Up</button>
                            <button type="button" class="btn btn-primary" v-on:click="moveMenuSection(item.menuSectionId, 1)" :disabled="lastSectionDisplayed === key">Down</button>
                          </div>
                        </div>
                        <div class="col">
                          <input type="text" class="form-control" placeholder="Section Name" v-model="item.name">
                        </div>
                        <div class="col">
                          <input type="text" class="form-control" placeholder="Section Description" v-model="item.description">
                        </div>
                        <div class="col-auto">
                          <button class='btn btn-sm btn-danger' v-on:click="deleteMenuSection(item.menuSectionId)"> x Remove</button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="lead text-center">
                <a class="btn btn-primary btn-lg" href="#" role="button" v-on:click="itemsComponent" v-if="Object.keys(menu).length !== 0">Next: Add Items</a>
              </p>
            </div>
          </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
  .requiredField {
    color: red;
  }
</style>
<script>
import { mapState } from 'vuex'
import $ from 'jquery'
export default {
  name: 'menus',
  data () {
    return {
      firstSectionDisplayed: null,
      lastSectionDisplayed: null,
      activeMenuSections: {},
      createNewMenu: {
        name: '',
        description: '',
        restId: ''
      }
    }
  },
  watch: {
    activeMenu: {
      handler (val, oldVal) {
        this.menuSections()
      }
    }
  },
  methods: {
    menuSections () {
      this.activeMenuSections = {}
      this.firstSectionDisplayed = null
      this.lastSectionDisplayed = null
      const storeRestData = JSON.parse(JSON.stringify(this.menuSection))
      for (const [index, val] of storeRestData.entries()) {
        if (val.menuId === this.activeMenu) {
          this.activeMenuSections[index] = val
          if (this.firstSectionDisplayed === null) {
            this.firstSectionDisplayed = index
          }
          this.lastSectionDisplayed = index
        }
      }
    },
    onlyOneMenu () {
      const storeRestData = JSON.parse(JSON.stringify(this.menu))
      if (storeRestData.length > 1) {
        return false
      }
      const latestEntry = storeRestData.slice(-1).pop()
      if (latestEntry !== undefined) {
        this.$parent.setActiveMenu(latestEntry.menuId)
      }
    },
    openModal (modalId) {
      this.resetCreateNewMenu()
      $('#' + modalId).modal('show')
    },
    closeModal (modalId) {
      $('#' + modalId).modal('hide')
      this.resetCreateNewMenu()
    },
    resetCreateNewMenu () {
      this.createNewMenu.name = ''
      this.createNewMenu.description = ''
      this.createNewMenu.restId = ''
    },
    restSelect () {
      const storeRestData = JSON.parse(JSON.stringify(this.restaurant))
      if (storeRestData.length > 1) {
        return true
      }

      if (storeRestData.length === 1) {
        this.createNewMenu.restId = storeRestData[0].restId
      }
      return false
    },
    editRestaurantMenus (restId) {
      this.$parent.setActiveRestaurant(restId)
      this.$parent.setActiveMenu(0)
    },
    showMenuSections (menuId) {
      this.$parent.setActiveMenu(menuId)
    },
    closeMenuSections () {
      this.$parent.setActiveMenu(0)
    },
    addMenu () {
      let nextId
      const storeRestData = JSON.parse(JSON.stringify(this.menu))
      const latestEntry = storeRestData.slice(-1).pop()
      nextId = 1
      if (latestEntry !== undefined) {
        nextId = latestEntry.menuId + 1
      }
      this.buildMenuData(nextId)
    },
    buildMenuData (menuId) {
      const restId = this.createNewMenu.restId
      const name = this.createNewMenu.name
      const description = this.createNewMenu.description
      const menuData = {
        menuId: menuId,
        restId: restId,
        name: name,
        description: description
      }
      const data = { menuData: menuData }
      this.$parent.addMenu(data)
      this.$parent.setActiveMenu(0)
      this.resetCreateNewMenu()
      this.closeModal('createmenu')
    },
    deleteMenu (menuId) {
      this.$parent.deleteMenu(menuId)
      this.$parent.setActiveMenu(0)
    },
    buildMenuSectionData (menuSectionId, menuId) {
      const menuSectionData = {
        menuSectionId: menuSectionId,
        menuId: menuId,
        name: '',
        description: '',
        display_order: ''
      }
      const data = { menuSectionData: menuSectionData }
      this.$parent.addMenuSection(data)
      this.menuSections()
    },
    addMenuSection (menuId) {
      if (this.activeMenu !== menuId) {
        this.$parent.setActiveMenu(menuId)
      }

      // Grab the next Id
      let nextId
      nextId = 0
      const storeRestData = JSON.parse(JSON.stringify(this.menuSection))
      for (var section in storeRestData) {
        if (nextId <= storeRestData[section].menuSectionId) {
          nextId = storeRestData[section].menuSectionId
        }
      }
      nextId = nextId + 1
      this.buildMenuSectionData(nextId, menuId)
    },
    moveMenuSection (menuSectionId, delta) {
      const data = {
        menuSectionId: menuSectionId,
        delta: delta
      }
      this.$parent.moveMenuSection(data)
      this.menuSections()
    },
    deleteMenuSection (menuSectionId) {
      this.$parent.deleteMenuSection(menuSectionId)
      this.menuSections()
    },
    itemsComponent () {
      this.$parent.itemsComponent()
    }
  },
  created () {},
  computed: {
    ...mapState({
      restaurant: state => state.menussetup.restaurant,
      activeRestaurant: state => state.menussetup.activeRestaurant,
      brand: state => state.menussetup.brand,
      restOperationType: state => state.menussetup.restOperationType,
      menu: state => state.menussetup.menu,
      items: state => state.menussetup.item,
      activeMenu: state => state.menussetup.activeMenu,
      menuSection: state => state.menussetup.menu_section,
      activeMenuSection: state => state.menussetup.activeMenuSection
    })
  }
}
</script>
