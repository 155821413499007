import { authHeader, apiUrl, apiClientSiteUrl, handleResponse } from '../_helpers'

export const menusService = {
  getAll,
  getTypes,
  getById,
  add,
  update,
  menusubitemmodifiersets,
  displayMenu,
  transactionalMenu,
  menuitemimages,
  menuaddendums
}

function getAll (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu',
      data: {
        action: 'getAll',
        restGroupId: data.restGroupId,
        restId: data.restId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getTypes (restGroupId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu',
      data: {
        action: 'getMenuTypes',
        restGroupId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu',
      data: {
        action: 'getMenu',
        addendums: 1,
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function add (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu',
      data: {
        action: 'addMenu',
        restId: data.restId,
        menuTypeId: data.menuTypeId,
        name: data.name,
        description: data.description,
        isActive: data.isActive,
        isTemporary: data.isTemporary
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function update (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'menu',
      data: {
        action: 'updateMenu',
        menuId: data.editingmenu,
        restId: data.restId,
        menuTypeId: data.menuTypeId,
        name: data.name,
        description: data.description,
        isActive: data.isActive,
        isTemporary: data.isTemporary,
        deleteMenu: data.deleteMenu
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function displayMenu (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      rest_group_name: data.csmRestGroupName,
      rest_name: data.csmRestName,
      menu_name: data.csmMenuName
    })
  }

  return fetch(apiClientSiteUrl('/menu/display'), requestOptions).then(handleResponse)
}

function transactionalMenu (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      rest_group_name: data.csmRestGroupName,
      rest_name: data.csmRestName,
      menu_name: data.csmMenuName
    })
  }

  return fetch(apiClientSiteUrl('/menu/transactional'), requestOptions).then(handleResponse)
}

function menuaddendums (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      rest_group_name: data.csmRestGroupName,
      rest_name: data.csmRestName,
      menu_name: data.csmMenuName
    })
  }

  return fetch(apiClientSiteUrl('/menu/alladdendums'), requestOptions).then(handleResponse)
}

function menuitemimages (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      rest_group_name: data.csmRestGroupName,
      rest_name: data.csmRestName,
      menu_name: data.csmMenuName
    })
  }

  return fetch(apiClientSiteUrl('/menu/itemimages'), requestOptions).then(handleResponse)
}

function menusubitemmodifiersets (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      rest_group_name: data.csmRestGroupName,
      rest_name: data.csmRestName,
      menu_name: data.csmMenuName
    })
  }

  return fetch(apiClientSiteUrl('/menu/subitemmodifiersets'), requestOptions).then(handleResponse)
}
