<template>
    <div class="page salestaxrates-page">
      <h6 class="page-title">Sales Tax Rates <button v-on:click="addSalesTaxRateButton" class="btn btn-sm btn-primary">Add +</button></h6>
      <div class="salestaxrate-form" v-if="salestaxrateform">
          <p>{{formtitle}}</p>
          <div class="container-fluid">
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="taxRate">Tax Rate</label>
                      <input type="text" class="form-control" id="taxRate" placeholder="" v-model="taxRate">
                      <div class="input-error" v-if="msg.errors.taxRate">
                          Valid tax rate is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="name">Name</label>
                      <input type="text" class="form-control" id="name" placeholder="" v-model="name">
                      <div class="input-error" v-if="msg.errors.name">
                          Valid name is required.
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="description">Description</label>
                      <input type="text" class="form-control" id="description" placeholder="" v-model="description">
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="city">City</label>
                      <input type="text" class="form-control" id="city" placeholder="" v-model="city">
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="subregion">County</label>
                      <input type="text" class="form-control" id="subregion" placeholder="" v-model="subregion">
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="province">Province</label>
                      <input type="text" class="form-control" id="province" placeholder="" v-model="province">
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="postalCode">Postal Code</label>
                      <input type="text" class="form-control" id="postalCode" placeholder="" v-model="postalCode">
                  </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3">
                      <label for="country">Country</label>
                      <input type="text" class="form-control" id="country" placeholder="" v-model="country">
                  </div>
              </div>
              <div class="row" v-if="editingsalestaxrate">
                <div class="col-sm-12 col-md-6 mb-3 deletecontrol text-right">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="deleteSalesTaxRate" id="deleteSalesTaxRate">
                    <label class="form-check-label" for="deleteSalesTaxRate">
                      Delete this Tax Rate?
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                  <div class="col-sm-12 col-md-6 mb-3 text-left form-controls">
                      <button v-on:click="saveSalesTaxRateFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                      <button v-on:click="closeSalesTaxRateFormButton" class="btn btn-sm btn-danger">Close</button>
                  </div>
              </div>
          </div>
      </div>
      <div v-if="'salestaxrates' in groupsalestaxrates" class="salestaxrates-list">
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Tax Rate</th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">City</th>
                <th scope="col">County</th>
                <th scope="col">Province</th>
                <th scope="col">Postal Code</th>
                <th scope="col">Country</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>
                  <tr v-for="salestaxrate in groupsalestaxrates.salestaxrates" :key="salestaxrate.id">
                    <td>{{salestaxrate.sales_tax_rate_id}}</td>
                    <td>{{salestaxrate.tax_rate}}</td>
                    <td>{{salestaxrate.name}}</td>
                    <td>{{salestaxrate.description}}</td>
                    <td>{{salestaxrate.city}}</td>
                    <td>{{salestaxrate.subregion}}</td>
                    <td>{{salestaxrate.province}}</td>
                    <td>{{salestaxrate.postal_code}}</td>
                    <td>{{salestaxrate.country}}</td>
                    <td>
                      <button v-on:click="editSalesTaxRateButton(salestaxrate.sales_tax_rate_id)" class="btn btn-sm btn-primary">Edit</button>
                    </td>
                  </tr>
            </tbody>
          </table>
      </div>
      <div v-else>
        No sales tax rates available.
      </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'SalesTaxRates',
  data () {
    return {
      formtitle: 'Add new SalesTaxRate',
      formsavetext: 'Save',
      salestaxrateform: false,
      restGroupId: this.$route.params.id,
      taxRate: '',
      name: '',
      description: '',
      city: '',
      subregion: '',
      province: '',
      postalCode: '',
      country: '',
      editingsalestaxrate: '',
      deleteSalesTaxRate: false,
      msg: {
        errors: {
          taxRate: false,
          name: false
        }
      }
    }
  },
  computed: {
    ...mapState({
      groupsalestaxrates: state => state.salestaxrates.all,
      activesalestaxrate: state => state.salestaxrates.activesalestaxrate,
      addsalestaxrate: state => state.salestaxrates.addsalestaxrate,
      updatesalestaxrate: state => state.salestaxrates.updatesalestaxrate
    })
  },
  created () {
    this.getSalesTaxRates(this.restGroupId)
  },
  watch: {
    activesalestaxrate: {
      handler (val, oldVal) {
        this.taxRate = val.tax_rate
        this.name = val.name
        this.description = val.description
        this.city = val.city
        this.subregion = val.subregion
        this.province = val.province
        this.postalCode = val.postal_code
        this.country = val.country
        this.editingsalestaxrate = val.sales_tax_rate_id
      }
    },
    name: {
      handler (val, oldVal) {
        this.validateSalesTaxRateForm()
      }
    },
    taxRate: {
      handler (val, oldVal) {
        this.validateSalesTaxRateForm()
      }
    },
    addsalestaxrate: {
      handler (val, oldVal) {
        if (val.sales_tax_rate_id) {
          this.getSalesTaxRates(this.restGroupId)
          this.SalesTaxRateFormReset()
          this.closeSalesTaxRateFormButton()
        }
      }
    },
    updatesalestaxrate: {
      handler (val, oldVal) {
        if (val.sales_tax_rate_id) {
          this.getSalesTaxRates(this.restGroupId)
          this.SalesTaxRateFormReset()
          this.closeSalesTaxRateFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('salestaxrates', {
      getSalesTaxRates: 'getAll',
      getSalesTaxRate: 'getById',
      updateSalesTaxRate: 'update',
      addSalesTaxRate: 'add'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    SalesTaxRateFormReset (event) {
      this.formtitle = 'Add new Sales Tax Rate'
      this.name = ''
      this.description = ''
      this.taxRate = ''
      this.city = ''
      this.subregion = ''
      this.province = ''
      this.postalCode = ''
      this.country = ''
      this.editingsalestaxrate = ''
      this.deleteSalesTaxRate = false
    },
    closeSalesTaxRateFormButton (event) {
      this.salestaxrateform = false
    },
    addSalesTaxRateButton (event) {
      this.clearAlert()
      this.SalesTaxRateFormReset()
      if (this.salestaxrateform && this.editSalesTaxRateData === '') {
        this.closeSalesTaxRateFormButton()
        return
      }
      this.editSalesTaxRateData = ''
      this.salestaxrateform = true
    },
    editSalesTaxRateButton (id) {
      this.clearAlert()
      this.getSalesTaxRate(id)
      this.editingsalestaxrate = id
      this.salestaxrateform = true
      this.formtitle = 'Edit Sales Tax Rate'
    },
    validateSalesTaxRateForm (event) {
      for (var i in this.msg.errors) {
        if (Object.hasOwnProperty.call(this.msg.errors, i)) {
          if (this[i] === '') {
            this.msg.errors[i] = true
            return false
          }
          this.msg.errors[i] = false
        }
      }
    },
    saveSalesTaxRateFormButton (event) {
      this.clearAlert()
      if (this.validateSalesTaxRateForm() === false) {
        return
      }

      if (this.editingsalestaxrate) {
        const {
          editingsalestaxrate,
          restGroupId,
          name,
          description,
          taxRate,
          city,
          subregion,
          province,
          postalCode,
          country,
          deleteSalesTaxRate
        } = this
        this.updateSalesTaxRate({
          editingsalestaxrate,
          restGroupId,
          name,
          description,
          taxRate,
          city,
          subregion,
          province,
          postalCode,
          country,
          deleteSalesTaxRate
        })
      } else {
        const {
          restGroupId,
          name,
          description,
          taxRate,
          city,
          subregion,
          province,
          postalCode,
          country
        } = this
        this.addSalesTaxRate({
          restGroupId,
          name,
          description,
          taxRate,
          city,
          subregion,
          province,
          postalCode,
          country
        })
      }
    }
  }
}
</script>
