<template>
    <div class="top-nav">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <router-link to="/" class="navbar-brand">
            <img src="@/assets/squarelogo.png" alt=""> Graze Club Menus
        </router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
            <li class="nav-item active" style="display: none;">
                <router-link to="/" class="nav-link">Home <span class="sr-only">(current)</span></router-link>
            </li>
            </ul>
            <ul class="navbar-nav ml-auto" v-if="account.status.loggedIn">
                <li class="nav-item">
                   <span class="welcome-text">Welcome, {{ account.user.name }}!</span>
                   <router-link to="/login" class="btn btn-sm btn-primary">Logout</router-link>
                </li>
            </ul>
        </div>
        </nav>
    </div>
</template>
<style lang="scss" scoped>
    .top-nav {
        .navbar-brand {
            img {
                max-height: 2rem;
            }
        }
    }
</style>
<script>
import { mapState } from 'vuex'
export default {
  name: 'TopNav',
  computed: {
    ...mapState({
      account: state => state.account
    })
  }
}
</script>

<style scoped>
    .welcome-text {
        color: white;
        padding-right: 2rem;
    }
</style>
