import { authHeader, apiUrl, handleResponse } from '../_helpers'

export const menuaddendumsservice = {
  getAll,
  getById,
  add,
  update
}

function getAll (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'addendum/menu',
      data: {
        action: 'getAll',
        restGroupId: data.restGroupId,
        addendumType: data.addendumType,
        addendumTypeId: data.addendumTypeId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getById (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'addendum/menu',
      data: {
        action: 'getAddendum',
        restGroupId: data.restGroupId,
        addendumType: data.addendumType,
        addendumTypeId: data.addendumTypeId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function add (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'addendum/menu',
      data: {
        action: 'addAddendum',
        restGroupId: data.restGroupId,
        label: data.label,
        lineText: data.lineText,
        addendumType: data.addendumType,
        addendumTypeId: data.addendumTypeId,
        addendumId: data.addendumId,
        displayOrder: data.displayOrder,
        association: data.association,
        isHeader: data.isHeader,
        isFooter: data.isFooter,
        printOnly: data.printOnly
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function update (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'addendum/menu',
      data: {
        action: 'updateAddendum',
        addendumId: data.addendumId,
        restGroupId: data.restGroupId,
        label: data.label,
        lineText: data.lineText,
        addendumType: data.addendumType,
        addendumTypeId: data.addendumTypeId,
        menuAddendumId: data.menuAddendumId,
        displayOrder: data.displayOrder,
        association: data.association,
        isHeader: data.isHeader,
        isFooter: data.isFooter,
        printOnly: data.printOnly,
        deleteAddendum: data.deleteAddendum
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}
