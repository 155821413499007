export function authHeader () {
  const user = JSON.parse(localStorage.getItem('user'))

  if (user && user.access_token) {
    return {
      Authorization: 'Bearer ' + user.access_token,
      'Content-Type': 'application/json'
      //  'Content-Type': 'application/x-www-form-urlencoded'
    }
  } else {
    return {}
  }
}

export function authHeaderFilePost () {
  const user = JSON.parse(localStorage.getItem('user'))

  if (user && user.access_token) {
    return {
      Authorization: 'Bearer ' + user.access_token
    }
  } else {
    return {}
  }
}
