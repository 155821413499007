import { restaurantsService } from '../_services'

const state = {
  all: {},
  idsNamesSelect: [],
  activerestaurant: {},
  addrestaurant: {},
  updaterestaurant: {},
  restsalestaxrates: {},
  addresttaxrate: {},
  updateresttaxrate: {},
  activeresttaxrate: {},
  idnameselectresttaxrates: []
}

const actions = {
  getAll ({ commit }) {
    const stateName = 'all'
    commit('request', { stateName: stateName })

    restaurantsService.getAll()
      .then(
        restaurants => commit('getAllSuccess', restaurants.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getById ({ commit }, id) {
    const stateName = 'activerestaurant'
    commit('request', { stateName: stateName })

    restaurantsService.getById(id)
      .then(
        restaurant => commit('getByIdSuccess', restaurant.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  add ({ dispatch, commit }, restGroupId, name, description, address1, address2, city, province, postalCode, country) {
    const stateName = 'addrestaurant'
    commit('request', { stateName: stateName })

    restaurantsService.add(restGroupId, name, description, address1, address2, city, province, postalCode, country)
      .then(
        restaurant => {
          commit('addSuccess', restaurant.data)
          dispatch('alert/success', 'Added Restaurant.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  update ({ dispatch, commit }, restId, restGroupId, name, description, address1, address2, city, province, postalCode, country) {
    const stateName = 'updaterestaurant'
    commit('request', { stateName: stateName })

    restaurantsService.update(restId, restGroupId, name, description, address1, address2, city, province, postalCode, country)
      .then(
        restaurant => {
          commit('updateSuccess', restaurant.data)
          dispatch('alert/success', 'Edited restaurant.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getRestSalesTaxRates ({ commit }, restGroupId, restId) {
    const stateName = 'restsalestaxrates'
    commit('request', { stateName: stateName })

    restaurantsService.getRestSalesTaxRates(restGroupId, restId)
      .then(
        restsalestaxrates => commit('getSalesTaxRatesSuccess', restsalestaxrates),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getRestSalesTaxRate ({ commit }, id) {
    const stateName = 'activeresttaxrate'
    commit('request', { stateName: stateName })

    restaurantsService.getRestSalesTaxRate(id)
      .then(
        restaurant => commit('getTaxRateSuccess', restaurant.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  addRestSalesTaxRate ({ dispatch, commit }, restId, salesTaxRateId, association, isDefault) {
    const stateName = 'addresttaxrate'
    commit('request', { stateName: stateName })

    restaurantsService.addRestSalesTaxRate(restId, salesTaxRateId, association, isDefault)
      .then(
        restaurant => {
          commit('addTaxRateSuccess', restaurant.data)
          dispatch('alert/success', 'Added Restaurant.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  updateRestSalesTaxRate ({ dispatch, commit }, editingresttaxrate, restId, salesTaxRateId, association, isDefault, deleteRestTaxRate) {
    const stateName = 'updateresttaxrate'
    commit('request', { stateName: stateName })

    restaurantsService.updateRestSalesTaxRate(editingresttaxrate, restId, salesTaxRateId, association, isDefault, deleteRestTaxRate)
      .then(
        restaurant => {
          commit('updateTaxRateSuccess', restaurant.data)
          dispatch('alert/success', 'Edited restaurant.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  getAllSuccess (state, restaurants) {
    state.all = { restaurants }
    const selectData = []
    Array.from(restaurants).forEach(restaurant => {
      selectData.push({ code: restaurant.restaurant_id, label: restaurant.name })
    })
    state.idsNamesSelect = selectData
  },
  getByIdSuccess (state, restaurant) {
    state.activerestaurant = restaurant
  },
  addSuccess (state, restaurant) {
    state.addrestaurant = restaurant
  },
  updateSuccess (state, restaurant) {
    state.updaterestaurant = restaurant
  },
  getSalesTaxRatesSuccess (state, taxrates) {
    const selectData = []
    if ('data' in taxrates) {
      const taxratedata = taxrates.data
      state.restsalestaxrates = { taxratedata }
      Array.from(taxrates.data).forEach(taxrate => {
        selectData.push({ code: taxrate.restaurant_sales_tax_id, label: taxrate.tax_rate + ' (' + taxrate.name + ')' })
      })
    }
    state.idnameselectresttaxrates = selectData
  },
  getTaxRateSuccess (state, taxrate) {
    state.activeresttaxrate = taxrate
  },
  addTaxRateSuccess (state, taxrate) {
    state.addresttaxrate = taxrate
  },
  updateTaxRateSuccess (state, taxrate) {
    state.updateresttaxrate = taxrate
  }
}

export const restaurants = {
  namespaced: true,
  state,
  actions,
  mutations
}
