import { authHeader, apiUrl, handleResponse } from '../_helpers'

export const modifierService = {
  getAll,
  getById,
  add,
  update,
  getAllSets,
  getSetById,
  addSet,
  updateSet,
  getAllSetMembers,
  getSetMember,
  addSetMember,
  updateSetMember,
  getAllSpecSets,
  getSpecSetById,
  addSpecSet,
  updateSpecSet,
  getAllSpecs,
  getSpecById,
  addSpec,
  updateSpec
}

function getAll (restGroupId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'getAll',
        restGroupId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'getModifier',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function add (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'addModifier',
        restGroupId: data.restgroupid,
        name: data.modifiername,
        description: data.modifierdescription
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function update (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'updateModifier',
        modifier_id: data.editingmodifier,
        restGroupId: data.restgroupid,
        name: data.modifiername,
        description: data.modifierdescription,
        deleteModifier: data.deletemodifier
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getAllSets (restGroupId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'getAllSets',
        restGroupId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getSetById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'getModifierSet',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function addSet (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'addModifierSet',
        restGroupId: data.restgroupid,
        name: data.modifiersetname,
        description: data.modifiersetdescription
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function updateSet (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'updateModifierSet',
        modifier_set_id: data.editingmodifierset,
        restGroupId: data.restgroupid,
        name: data.modifiersetname,
        description: data.modifiersetdescription,
        deleteModifierSet: data.deletemodifierset
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getAllSetMembers (modifierSetId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'getAllSetMembers',
        modifierSetId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getSetMember (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'getModifierSetMember',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function addSetMember (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'addModifierSetMember',
        modifierSetId: data.editmodifiersetmembers,
        modifierId: data.modifierid,
        association: data.modifiersetmemberassociation
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function updateSetMember (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'updateModifierSetMember',
        modifier_set_member_id: data.editingmodifiersetmember,
        modifierSetId: data.modifiersetid,
        modifierId: data.modifierid,
        association: data.modifiersetmemberassociation,
        deleteModifierSetMember: data.deletemodifiersetmember
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getAllSpecSets (restGroupId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'getAllModifierSpecSets',
        restGroupId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getSpecSetById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'getModifierSpecSet',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function addSpecSet (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'addModifierSpecSet',
        restGroupId: data.restGroupId,
        name: data.name,
        description: data.description,
        displayOrder: data.displayOrder
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function updateSpecSet (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'updateModifierSpecSet',
        modifier_spec_set_id: data.editingmodifierspecset,
        restGroupId: data.restGroupId,
        name: data.name,
        description: data.description,
        displayOrder: data.displayOrder,
        deleteModifierSpecSet: data.deleteModifierSpecSet
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getAllSpecs (modifierSpecSetId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'getAllModifierSpecs',
        modifierSpecSetId
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function getSpecById (id) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'getModifierSpec',
        id
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function addSpec (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'addModifierSpec',
        modifierSpecSetId: data.modifierSpecSetId,
        name: data.name,
        description: data.description,
        displayOrder: data.displayOrder,
        isDefault: data.isDefault,
        isMeasured: data.isMeasured
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}

function updateSpec (data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      endpoint: 'modifier',
      data: {
        action: 'updateModifierSpec',
        modifierSpecId: data.editingmodifierspec,
        modifierSpecSetId: data.modifierSpecSetId,
        name: data.name,
        description: data.description,
        displayOrder: data.displayOrder,
        isDefault: data.isDefault,
        isMeasured: data.isMeasured,
        deleteModifierSpec: data.deleteModifierSpec
      }
    })
  }

  return fetch(apiUrl(), requestOptions).then(handleResponse)
}
