<template>
    <div class="menusetup-child-component items">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="jumbotron p-4">
                <h1 class="display-6">Items <a v-on:click="openModal('createitem')" class="btn btn-primary btn-sm" href="#" role="button">Add Item</a> <a class="btn btn-primary btn-sm" href="#" role="button" v-on:click="importDataComponent()">Import Items</a></h1>
                <p>Create items and assign them to your specific Restaurants, Menus and Menu Sections</p>
                <hr class="my-4">
                <div class="container-fluid">
                  <div class="row" v-if="Object.keys(items).length !== 0">
                    <table class="table table-sm">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col"><a href="#" v-on:click="sortBy('menuId')" :class="{ active : sortKey === 'menuId' }">Menu</a></th>
                          <th scope="col"><a href="#" v-on:click="sortBy('menuSectionId')" :class="{ active : sortKey === 'menuSectionId' }">Section</a></th>
                          <th scope="col"><a href="#" v-on:click="sortBy('name')" :class="{ active : sortKey === 'name' }">Name</a></th>
                          <th scope="col"><a href="#" v-on:click="sortBy('description')" :class="{ active : sortKey === 'description' }">Description</a></th>
                          <th scope="col"><a href="#" v-on:click="sortBy('price')" :class="{ active : sortKey === 'price' }">Price</a></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, key) in itemData" :key="key + '-' + item.itemId">
                          <td>{{getMenuName(item.menuId)}}</td>
                          <td>{{getSectionName(item.menuSectionId)}}</td>
                          <td>{{item.name}}</td>
                          <td>{{item.description}}</td>
                          <td>{{item.price}}</td>
                          <td>
                            <button class='btn btn-sm btn-primary' v-on:click="editItem(item)">Edit</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="lead text-center">
                <a class="btn btn-primary btn-lg" href="#" role="button" v-on:click="modifiersComponent">Next: Item Modifiers</a>
              </p>
            </div>
          </div>
        </div>
        <div class="modal fade" id="createitem" tabindex="-1" aria-labelledby="createitemLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Create an Item</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-row align-items-center mb-2">
                  <div class="col">
                    <label>Menu</label>
                    <select class="form-control" v-model="createNewItem.menuId" :disabled="menuSelect() === false" v-on:change="menuSectionSelect">
                      <option disabled value="" v-if="menuSelect()">Select a menu</option>
                      <option :value="menu.menuId" v-for="menu in menus" :key="menu.menuId">
                        <span v-if="menu.name.length">{{menu.name}}</span>
                        <span v-else>{{menu.menuId}}</span>
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-row align-items-center mb-2"  v-if="menuSectionSelect()">
                  <div class="col">
                    <label>Menu Section</label>
                    <select class="form-control" v-model="createNewItem.menuSectionId">
                      <option value="">Select a Menu section</option>
                      <option :value="menuSection.menuSectionId" v-for="menuSection in menuSectionSelect()" :key="menuSection.menuSectionId">
                        <span v-if="menuSection.name.length">{{menuSection.name}}</span>
                        <span v-else>{{menuSection.menuSectionId}}</span>
                      </option>
                    </select>
                  </div>
                </div>
                <div v-else class="form-row align-items-center mb-2">
                  <div class="col">
                    <label>Menu Section - Select a menu above.</label>
                  </div>
                </div>
                <div class="form-row align-items-center mb-2">
                  <div class="col">
                    <label>Item Name</label>
                    <input type="text" class="form-control" placeholder="Item Name" v-model="createNewItem.name">
                  </div>
                </div>
                <div class="form-row align-items-center mb-2">
                  <div class="col">
                    <label>Item Description</label>
                    <input type="text" class="form-control" placeholder="Item Description" v-model="createNewItem.description">
                  </div>
                </div>
                <div class="form-row align-items-center mb-2">
                  <div class="col">
                    <label>Item Price</label>
                    <input type="text" class="form-control" placeholder="Item Price" v-model="createNewItem.price">
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" v-on:click="closeModal('createitem')">Close</button>
                <button type="button" class="btn btn-primary" v-on:click="addMenuItem()">Create this Item</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="edititem" tabindex="-1" aria-labelledby="edititemLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Edit Item</h5>
                <button type="button" class="close" v-on:click="closeModal('edititem')" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" v-if="editingItem">
                <div class="form-row align-items-center mb-2">
                  <div class="col">
                    <label>Menu</label>
                    <select class="form-control" v-model="editingItem.menuId" :disabled="editItemMenuSelect() === false" v-on:change="editItemMenuSectionSelect">
                      <option disabled value="" v-if="editItemMenuSelect()">Select a menu</option>
                      <option :value="menu.menuId" v-for="menu in menus" :key="menu.menuId">
                        <span v-if="menu.name.length">{{menu.name}}</span>
                        <span v-else>{{menu.menuId}}</span>
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-row align-items-center mb-2"  v-if="editItemMenuSectionSelect()">
                  <div class="col">
                    <label>Menu Section</label>
                    <select class="form-control" v-model="editingItem.menuSectionId">
                      <option value="">Select a Menu section</option>
                      <option :value="menuSection.menuSectionId" v-for="menuSection in editItemMenuSectionSelect()" :key="menuSection.menuSectionId">
                        <span v-if="menuSection.name.length">{{menuSection.name}}</span>
                        <span v-else>{{menuSection.menuSectionId}}</span>
                      </option>
                    </select>
                  </div>
                </div>
                <div v-else class="form-row align-items-center mb-2">
                  <div class="col">
                    <label>Menu Section - Select a menu above.</label>
                  </div>
                </div>
                <div class="form-row align-items-center mb-2">
                  <div class="col">
                    <label>Item Name</label>
                    <input type="text" class="form-control" placeholder="Item Name" v-model="editingItem.name">
                  </div>
                </div>
                <div class="form-row align-items-center mb-2">
                  <div class="col">
                    <label>Item Description</label>
                    <input type="text" class="form-control" placeholder="Item Description" v-model="editingItem.description">
                  </div>
                </div>
                <div class="form-row align-items-center mb-2">
                  <div class="col">
                    <label>Item Price</label>
                    <input type="text" class="form-control" placeholder="Item Price" v-model="editingItem.price">
                  </div>
                </div>
                <div class="form-row align-items-center mb-2">
                  <div class="col">
                    <label>Item Image</label>
                    <div class="input-group mb-3">
                      <div class="custom-file">
                        <input type="file" class="custom-file-input">
                        <label class="custom-file-label" for="inputGroupFile01">Choose File</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger float-left" v-on:click="deleteMenuItem(editingItem.itemId)">Delete</button>
                <button type="button" class="btn btn-secondary" v-on:click="closeModal('edititem')">Close</button>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import $ from 'jquery'
export default {
  name: 'items',
  data () {
    return {
      sortKey: 'name',
      reverse: false,
      editingItem: null,
      itemData: {},
      createNewItem: {
        name: '',
        description: '',
        price: '',
        menuId: '',
        menuSectionId: '',
        display_order: '',
        image: false
      }
    }
  },
  watch: {
    sortItemsAsc: {
      handler (val, oldVal) {
        this.loadMenuItems()
      }
    },
    sortItemsBy: {
      handler (val, oldVal) {
        this.loadMenuItems()
      }
    }
  },
  methods: {
    sortBy (sortKey) {
      if (this.sortKey === sortKey) {
        if (!this.reverse) {
          this.reverse = true
        } else {
          this.reverse = false
        }
      }
      this.sortKey = sortKey
      const data = {
        sortItemsBy: this.sortKey,
        sortItemsAsc: this.reverse
      }
      this.$parent.sortMenuItems(data)
    },
    openModal (modalId) {
      this.resetCreateNewItem()
      $('#' + modalId).modal('show')
    },
    closeModal (modalId) {
      $('#' + modalId).modal('hide')
      this.resetCreateNewItem()
    },
    resetCreateNewItem () {
      this.createNewItem.name = ''
      this.createNewItem.description = ''
      this.createNewItem.menuId = ''
      this.createNewItem.menuSectionId = ''
      this.createNewItem.price = ''
    },
    getMenuName (menuId) {
      var menuName = null
      const menuState = this.$store.state.menussetup.menu
      const storeRestData = JSON.parse(JSON.stringify(menuState))
      for (var index in storeRestData) {
        if (storeRestData[index].menuId === menuId) {
          menuName = storeRestData[index].name
        }
      }
      return menuName
    },
    getSectionName (sectionId) {
      var sectionName = null
      const menuSectionState = this.$store.state.menussetup.menu_section
      const storeRestData = JSON.parse(JSON.stringify(menuSectionState))
      for (var index in storeRestData) {
        if (storeRestData[index].menuSectionId === sectionId) {
          sectionName = storeRestData[index].name
        }
      }
      return sectionName
    },
    menuSelect () {
      const storeRestData = JSON.parse(JSON.stringify(this.menus))
      if (storeRestData.length > 1) {
        return true
      }

      if (storeRestData.length === 1) {
        this.createNewItem.menuId = storeRestData[0].menuId
        this.menuSectionSelect()
        return
      }
      return false
    },
    menuSectionSelect () {
      const sections = this.getMenuSections(this.createNewItem.menuId)
      if (sections.length === undefined) {
        return false
      }
      if (sections.length === 1) {
        this.createNewItem.menuSectionId = sections[0].menuSectionId
      }
      return sections
    },
    editItemMenuSelect () {
      const storeRestData = JSON.parse(JSON.stringify(this.menus))
      if (storeRestData.length > 1) {
        return true
      }

      if (storeRestData.length === 1) {
        this.editingItem.menuId = storeRestData[0].menuId
        this.editItemMenuSectionSelect()
        return
      }
      return false
    },
    editItemMenuSectionSelect () {
      const sections = this.getMenuSections(this.editingItem.menuId)
      if (sections.length === undefined) {
        return false
      }
      if (sections.length === 1) {
        this.editingItem.menuSectionId = sections[0].menuSectionId
      }
      return sections
    },
    onlyOneRestaurant () {
      const storeRestData = JSON.parse(JSON.stringify(this.restaurant))
      if (storeRestData.length > 1) {
        return false
      }
    },
    buildMenuItemData (itemId) {
      const name = this.createNewItem.name
      const description = this.createNewItem.description
      const price = this.createNewItem.price
      const menuId = this.createNewItem.menuId
      const menuSectionId = this.createNewItem.menuSectionId
      const displayOrder = this.createNewItem.display_order
      const image = this.createNewItem.image
      const menuItemData = {
        itemId: itemId,
        name: name,
        description: description,
        price: price,
        menuId: menuId,
        menuSectionId: menuSectionId,
        display_order: displayOrder,
        image: image
      }
      const data = { menuItemData: menuItemData }
      this.$parent.addMenuItem(data)
      this.closeModal('createitem')
    },
    addMenuItem () {
      // Grab the next Id
      let nextId
      nextId = 0
      const storeRestData = JSON.parse(JSON.stringify(this.items))
      for (var item in storeRestData) {
        if (nextId <= storeRestData[item].itemId) {
          nextId = storeRestData[item].itemId
        }
      }
      nextId = nextId + 1
      this.buildMenuItemData(nextId)
    },
    loadMenuItems () {
      this.itemData = this.getSortedMenuItems()
    },
    editItem (item) {
      this.editingItem = item
      this.openModal('edititem')
    },
    deleteMenuItem (menuItemId) {
      this.$parent.deleteMenuItem(menuItemId)
      this.closeModal('edititem')
    },
    modifiersComponent () {
      this.$parent.modifiersComponent()
    },
    importDataComponent () {
      this.$parent.importDataComponent('item')
    }
  },
  created () {
    this.loadMenuItems()
  },
  computed: {
    ...mapState({
      restaurant: state => state.menussetup.restaurant,
      activeRestaurant: state => state.menussetup.activeRestaurant,
      brand: state => state.menussetup.brand,
      restOperationType: state => state.menussetup.restOperationType,
      menus: state => state.menussetup.menu,
      activeMenu: state => state.menussetup.activeMenu,
      menuSections: state => state.menussetup.menu_section,
      activeMenuSection: state => state.menussetup.activeMenuSection,
      items: state => state.menussetup.item,
      sortItemsBy: state => state.menussetup.sortItemsBy,
      sortItemsAsc: state => state.menussetup.sortItemsAsc
    }),
    ...mapGetters('menussetup', {
      getMenuSections: 'getMenuSections',
      getSortedMenuItems: 'getSortedMenuItems'
    })
  }
}
</script>
