<template>
    <div class="operators-page page">
        <h6 class="page-title">Manage Operators <button v-on:click="addOperatorButton" class="btn btn-sm btn-primary">Add +</button></h6>
        <div class="operator-form" v-if="operatorform">
            <p>{{formtitle}}</p>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 col-md-6 mb-3">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" id="name" placeholder="" v-model="operatorname">
                        <div class="input-error" v-if="msg.errors.name">
                            Valid operator name is required.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 mb-3">
                        <label for="description">Description</label>
                        <input type="text" class="form-control" id="description" placeholder="" v-model="operatordescription">
                        <div class="input-error" v-if="msg.errors.description">
                            Valid operator Description is required.
                        </div>
                    </div>
                </div>
                <div class="row" v-if="editingoperator">
                  <div class="col-sm-12 col-md-6 mb-3 deletecontrol text-right">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="deleteoperator" id="deleteOperator">
                      <label class="form-check-label" for="deleteOperator">
                        Delete this Operator?
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 mb-3 text-left form-controls">
                        <button v-on:click="saveOperatorFormButton" class="btn btn-sm btn-primary">{{formsavetext}}</button>
                        <button v-on:click="closeOperatorFormButton" class="btn btn-sm btn-danger">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="operators.operators" class="operators-list">
            <table class="table table-striped">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Actions</th>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody>
                    <tr v-for="operator in operators.operators" :key="operator.name">
                      <td>
                        <button v-on:click="editOperatorButton(operator.operator_id)" class="btn btn-sm btn-primary">Edit</button>
                      </td>
                      <td>{{operator.operator_id}}</td>
                      <td>{{operator.name}}</td>
                      <td>{{operator.description}}</td>
                    </tr>
              </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      formtitle: 'Add new Operator',
      formsavetext: 'Save',
      operatorform: false,
      operatorname: '',
      operatordescription: '',
      editingoperator: '',
      deleteoperator: false,
      msg: { errors: { name: false, description: false } }
    }
  },
  computed: {
    ...mapState({
      operators: state => state.operators.all,
      activeoperator: state => state.operators.activeoperator,
      addoperator: state => state.operators.addoperator,
      updateoperator: state => state.operators.updateoperator
    })
  },
  created () {
    this.getAllOperators()
  },
  watch: {
    activeoperator: {
      handler (val, oldVal) {
        this.operatorname = val.name
        this.operatordescription = val.description
        this.editingoperator = val.operator_id
      }
    },
    operatorname: {
      handler (val, oldVal) {
        this.validateOperatorForm()
      }
    },
    operatordescription: {
      handler (val, oldVal) {
        this.validateOperatorForm()
      }
    },
    addoperator: {
      handler (val, oldVal) {
        if (val.operator_id) {
          this.getAllOperators()
          this.OperatorFormReset()
          this.closeOperatorFormButton()
        }
      }
    },
    updateoperator: {
      handler (val, oldVal) {
        if (val.operator_id) {
          this.getAllOperators()
          this.OperatorFormReset()
          this.closeOperatorFormButton()
        }
      }
    }
  },
  methods: {
    ...mapActions('operators', {
      getAllOperators: 'getAll',
      getOperator: 'getById',
      updateOperator: 'update',
      addOperator: 'add'
    }),
    ...mapActions('alert', {
      clearAlert: 'clear'
    }),
    OperatorFormReset (event) {
      this.formtitle = 'Add new Operator'
      this.operatorname = ''
      this.operatordescription = ''
      this.editingoperator = ''
      this.deleteoperator = false
    },
    closeOperatorFormButton (event) {
      this.operatorform = false
    },
    addOperatorButton (event) {
      this.clearAlert()
      this.OperatorFormReset()
      if (this.operatorform && this.editOperatorData === '') {
        this.closeOperatorFormButton()
        return
      }
      this.editOperatorData = ''
      this.operatorform = true
    },
    editOperatorButton (id) {
      this.clearAlert()
      this.getOperator(id)
      this.operatorform = true
      this.formtitle = 'Edit Operator'
    },
    validateOperatorForm (event) {
      this.msg.errors.name = false
      this.msg.errors.description = false

      if (this.operatorname === '') {
        this.msg.errors.name = true
        return false
      }

      if (this.operatordescription === '') {
        this.msg.errors.description = true
        return false
      }
    },
    saveOperatorFormButton (event) {
      this.clearAlert()
      if (this.validateOperatorForm() === false) {
        return
      }

      if (this.editingoperator) {
        const { editingoperator, operatorname, operatordescription, deleteoperator } = this
        this.updateOperator({ editingoperator, operatorname, operatordescription, deleteoperator })
      } else {
        const { operatorname, operatordescription } = this
        this.addOperator({ operatorname, operatordescription })
      }
    }
  }
}
</script>
