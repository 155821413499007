import { itemsService } from '../_services'

const state = {
  all: {},
  itemtypes: [],
  activeitem: {},
  additem: {},
  updateitem: {},
  idsNamesSelect: []
}

const actions = {
  getAll ({ commit }, restGroupId) {
    const stateName = 'all'
    commit('request', { stateName: stateName })

    itemsService.getAll(restGroupId)
      .then(
        items => commit('getAllSuccess', items.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getTypes ({ commit }, restGroupId) {
    const stateName = 'itemtypes'
    commit('request', { stateName: stateName })

    itemsService.getTypes(restGroupId)
      .then(
        types => commit('getTypesSuccess', types.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  getById ({ commit }, id) {
    const stateName = 'activeitem'
    commit('request', { stateName: stateName })

    itemsService.getById(id)
      .then(
        item => commit('getByIdSuccess', item.data),
        error => commit('failure', { stateName: stateName, error: error })
      )
  },
  add ({ dispatch, commit }, restGroupId, name, description, address1, address2, city, province, postalCode, country) {
    const stateName = 'additem'
    commit('request', { stateName: stateName })

    itemsService.add(restGroupId, name, description, address1, address2, city, province, postalCode, country)
      .then(
        item => {
          commit('addSuccess', item.data)
          dispatch('alert/success', 'Added Restaurant.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  update ({ dispatch, commit }, restId, restGroupId, name, description, address1, address2, city, province, postalCode, country) {
    const stateName = 'updateitem'
    commit('request', { stateName: stateName })

    itemsService.update(restId, restGroupId, name, description, address1, address2, city, province, postalCode, country)
      .then(
        item => {
          commit('updateSuccess', item.data)
          dispatch('alert/success', 'Edited item.', { root: true })
        },
        error => {
          commit('failure', { stateName: stateName, error: error })
          dispatch('alert/error', error, { root: true })
        }
      )
  }
}

const mutations = {
  request (state, payload) {
    const stateName = payload.stateName
    state[stateName] = {}
  },
  failure (state, payload) {
    const stateName = payload.stateName
    const error = payload.error
    state[stateName] = { error }
  },
  getAllSuccess (state, items) {
    state.all = { items }
    const selectData = []
    if (items) {
      Array.from(items).forEach(item => {
        selectData.push({ code: item.item_id, label: item.item_name })
      })
    }
    state.idsNamesSelect = selectData
  },
  getTypesSuccess (state, types) {
    const selectData = []
    if (types) {
      Array.from(types).forEach(type => {
        selectData.push({ code: type.property_value_id, label: type.property })
      })
    }
    state.itemtypes = selectData
  },
  getByIdSuccess (state, item) {
    state.activeitem = item
  },
  addSuccess (state, item) {
    state.additem = item
  },
  updateSuccess (state, item) {
    state.updateitem = item
  }
}

export const items = {
  namespaced: true,
  state,
  actions,
  mutations
}
